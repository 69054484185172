import {
    SAVE_ORDER_REQUEST, SAVE_ORDER_SUCCESS, SAVE_ORDER_FAILURE,
    CALCULATE_ORDER_REQUEST, CALCULATE_ORDER_SUCCESS, CALCULATE_ORDER_FAILURE,
    CHARGEBACK_PAYMENT_REQUEST, CHARGEBACK_PAYMENT_SUCCESS, CHARGEBACK_PAYMENT_FAILURE,
    CHARGEBACK_REVERSAL_REQUEST, CHARGEBACK_REVERSAL_SUCCESS, CHARGEBACK_REVERSAL_FAILURE,
    CLEAR_ORDER, SET_PROCESSED_ORDER, CLEAR_ACTION_RESULT
} from '../actions/payments/orderManagement';

export default function orderManagement(state = {
    isSaving: false,
    order: null,
    calculatedOrder: null,
    processedOrder: null,
    actionResult: null,
    errorMessage: '',
}, { type, payload }) {
    switch (type) {
        case CLEAR_ORDER:
            return Object.assign({}, state, {
                isSaving: false,
                order: null,
                calculatedOrder: null,
                processedOrder: null,
                actionResult: null,
                errorMessage: '',
            });
        case CLEAR_ACTION_RESULT:
            return Object.assign({}, state, {
                actionResult: null
            });
        case CALCULATE_ORDER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                calculatedOrder: null,
                actionResult: { type: CALCULATE_ORDER_REQUEST, result: null, token: payload.actionToken },
            });
        case CALCULATE_ORDER_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                calculatedOrder: payload.calculatedOrder,
                actionResult: { type: CALCULATE_ORDER_REQUEST, result: CALCULATE_ORDER_SUCCESS, token: payload.actionToken }
            });
        case CALCULATE_ORDER_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                calculatedOrder: null,
                actionResult: { type: CALCULATE_ORDER_REQUEST, result: CALCULATE_ORDER_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case SAVE_ORDER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                order: payload.order,
                processedOrder: null,
                actionResult: { type: SAVE_ORDER_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_ORDER_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_ORDER_REQUEST, result: SAVE_ORDER_SUCCESS, token: payload.actionToken } ,
                processedOrder: payload.processedOrder
            });
        case SAVE_ORDER_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_ORDER_REQUEST, result: SAVE_ORDER_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case CHARGEBACK_PAYMENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                order: payload.order,
                processedOrder: null,
                actionResult: { type: CHARGEBACK_PAYMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case CHARGEBACK_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: CHARGEBACK_PAYMENT_REQUEST, result: CHARGEBACK_PAYMENT_SUCCESS, token: payload.actionToken },
                processedOrder: payload.processedOrder
            });
        case CHARGEBACK_PAYMENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                errorMessage: payload.error,
                actionResult: { type: CHARGEBACK_PAYMENT_REQUEST, result: CHARGEBACK_PAYMENT_FAILURE, token: payload.actionToken },
            });
        case CHARGEBACK_REVERSAL_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                processedOrder: null,
                actionResult: { type: CHARGEBACK_REVERSAL_REQUEST, result: null, token: payload.actionToken },
            });
        case CHARGEBACK_REVERSAL_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: CHARGEBACK_REVERSAL_REQUEST, result: CHARGEBACK_REVERSAL_SUCCESS, token: payload.actionToken },
                processedOrder: payload.processedOrder
            });
        case CHARGEBACK_REVERSAL_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                errorMessage: payload.error,
                actionResult: { type: CHARGEBACK_REVERSAL_REQUEST, result: CHARGEBACK_REVERSAL_FAILURE, token: payload.actionToken },
            });
        case SET_PROCESSED_ORDER:
            return Object.assign({}, state, {
                actionResult: { type: SET_PROCESSED_ORDER, result: SET_PROCESSED_ORDER, token: payload.actionToken },
                processedOrder: payload.processedOrder
            });
        default:
            return state;
    }
}
