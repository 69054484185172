import { CellPlugin } from '@react-page/editor';
import MultiSearch, {
    TAX_TYPE_LABEL, SELECT_TAX_TYPE_OPTION_LABEL, SEARCH_BY_LABEL, USE_CUSTOM_API, SELECT_SEARCH_BY_OPTION_LABEL, REFERENCE_NUMBER_LABEL,
    REFERENCE_NUMBER_PLACEHOLDER, MARGIN, PADDING, SUBMIT_BUTTON_BACKGROUND_COLOR, SUBMIT_BUTTON_FONT_COLOR, SEARCH_LABEL, SUBMIT_BUTTON_FONT_SIZE, SUBMIT_BUTTON_FONT_STYLE,
    SUBMIT_BUTTON_FONT_WEIGHT, RESET_LABEL, RESET_BUTTON_BACKGROUND_COLOR, RESET_BUTTON_FONT_COLOR, RESET_BUTTON_FONT_SIZE, RESET_BUTTON_FONT_STYLE, RESET_BUTTON_FONT_WEIGHT,
    RESET_BUTTON_BORDER_COLOR, HIDE_TAX_TYPE, HIDE_SEARCH_BY,USE_TYPE_AHEAD, TAX_TYPE_KEY
} from './components/MultiSearch';

type Data = {
    title: string,
    taxType: string,
    taxTypeKey: string,
    hideTaxType: boolean,
    taxTypeDynamicOptions: Field[],
    selectTaxTypeOption: string,
    searchBy: string,
    useCustomAPI: boolean,
    useTypeAhead: boolean,
    hideSearchBy: boolean,
    searchByDynamicOptions: Field[],
    selectSearchByOption: string,
    referenceNumber: string,
    referenceNumberPlaceholder: string,
    resetLabel: string,
    searchLabel: string,
    margin: string,
    padding: string,
    resetButtonBackgroundColor: string,
    resetButtonFontColor: string,
    resetButtonFontSize: string,
    resetButtonFontStyle: string,
    resetButtonFontWeight: string,
    resetButtonBorderColor: string,
    submitButtonBackgroundColor: string,
    submitButtonFontColor: string,
    submitButtonFontSize: string,
    submitButtonFontStyle: string,
    submitButtonFontWeight: string,
}

export type Field = {
    key: string;
    value?: string;
  };

const MultiSearchPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <MultiSearch
            taxTypeLabel={data.taxType}
            hideTaxType={data.hideTaxType}
            taxTypeKey={data.taxTypeKey}
            taxTypeDynamicOptions={data.taxTypeDynamicOptions}
            selectTaxTypeOptionLabel={data.selectTaxTypeOption}
            searchByLabel={data.searchBy}
            hideSearchBy={data.hideSearchBy}
            useCustomAPI={data.useCustomAPI}
            useTypeAhead={data.useTypeAhead}
            searchByDynamicOptions={data.searchByDynamicOptions}
            selectSearchByOptionLabel={data.selectSearchByOption}
            referenceNumberLabel={data.referenceNumber}
            referenceNumberPlaceholder={data.referenceNumberPlaceholder}
            resetLabel={data.resetLabel}
            searchLabel={data.searchLabel}
            margin={data.margin}
            padding={data.padding}
            resetButtonBackgroundColor={data.resetButtonBackgroundColor}
            resetButtonFontColor={data.resetButtonFontColor}
            resetButtonFontSize={data.resetButtonFontSize}
            resetButtonFontStyle={data.resetButtonFontStyle}
            resetButtonFontWeight={data.resetButtonFontWeight}
            resetButtonBorderColor={data.resetButtonBorderColor}
            submitButtonBackgroundColor={data.submitButtonBackgroundColor}
            submitButtonFontColor={data.submitButtonFontColor}
            submitButtonFontSize={data.submitButtonFontSize}
            submitButtonFontStyle={data.submitButtonFontStyle}
            submitButtonFontWeight={data.submitButtonFontWeight}
        />
    ),
    id: 'multiSearchPlugin',
    title: 'Multi Search',
    description: 'Multi search accounts',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        taxType: {
                            type: 'string',
                            default: TAX_TYPE_LABEL,
                        },
                        hideTaxType: {
                            type: 'boolean',
                            default: HIDE_TAX_TYPE
                        },
                        selectTaxTypeOption: {
                            type: 'string',
                            default: SELECT_TAX_TYPE_OPTION_LABEL,
                        },
                        searchBy: {
                            type: 'string',
                            default: SEARCH_BY_LABEL,
                        },
                        hideSearchBy: {
                            type: 'boolean',
                            default: HIDE_SEARCH_BY,
                        },
                        selectSearchByOption: {
                            type: 'string',
                            default: SELECT_SEARCH_BY_OPTION_LABEL,
                        },
                        referenceNumber: {
                            type: 'string',
                            default: REFERENCE_NUMBER_LABEL,
                        },
                        referenceNumberPlaceholder: {
                            type: 'string',
                            default: REFERENCE_NUMBER_PLACEHOLDER,
                        },
                        resetLabel: {
                            type: 'string',
                            default: RESET_LABEL,
                        },
                        searchLabel: {
                            type: 'string',
                            default: SEARCH_LABEL,
                        },
                        
                    },
                    required: ['taxType', 'searchBy', 'referenceNumber', 'searchByDynamicOptions'],
                },
            },
        },
        {
            title: 'St Charles Rules',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        useCustomAPI: {
                            type: 'boolean',
                            default: USE_CUSTOM_API,
                        },
                        useTypeAhead: {
                            type: 'boolean',
                            default: USE_TYPE_AHEAD,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Dynamic options',
            controls:{
                type:'autoform',
                schema:{
                    properties:{
                        taxTypeKey: {
                            type: 'string',
                            default: TAX_TYPE_KEY,
                        },
                        taxTypeDynamicOptions: {
                            type: 'array',
                            items: {
                                type: 'object',
                                properties: {
                                    key: {
                                        type: 'string',
                                      },
                                      value: {
                                        type: 'string',
                                      },
                                }
                            },
                        },
                        searchByDynamicOptions: {
                            type: 'array',
                            items: {
                                type: 'object',
                                properties: {
                                    key: {
                                        type: 'string',
                                      },
                                      value: {
                                        type: 'string',
                                      },
                                      maxLength: {
                                        type: 'integer',
                                      }
                                } as {}
                            },
                        },
                    }
                }
            }
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        resetButtonBackgroundColor: {
                            type: 'string',
                            default: RESET_BUTTON_BACKGROUND_COLOR,
                        },
                        resetButtonFontColor: {
                            type: 'string',
                            default: RESET_BUTTON_FONT_COLOR,
                        },
                        resetButtonFontSize: {
                            type: 'string',
                            default: RESET_BUTTON_FONT_SIZE,
                        },
                        resetButtonFontStyle: {
                            type: 'string',
                            default: RESET_BUTTON_FONT_STYLE,
                        },
                        resetButtonFontWeight: {
                            type: 'string',
                            default: RESET_BUTTON_FONT_WEIGHT,
                        },
                        resetButtonBorderColor: {
                            type: 'string',
                            default: RESET_BUTTON_BORDER_COLOR,
                        },
                        submitButtonBackgroundColor: {
                            type: 'string',
                            default: SUBMIT_BUTTON_BACKGROUND_COLOR,
                        },
                        submitButtonFontColor: {
                            type: 'string',
                            default: SUBMIT_BUTTON_FONT_COLOR,
                        },
                        submitButtonFontSize: {
                            type: 'string',
                            default: SUBMIT_BUTTON_FONT_SIZE,
                        },
                        submitButtonFontStyle: {
                            type: 'string',
                            default: SUBMIT_BUTTON_FONT_STYLE,
                        },
                        submitButtonFontWeight: {
                            type: 'string',
                            default: SUBMIT_BUTTON_FONT_WEIGHT,
                        },
                    },
                    required: [],
                },
            },
        },
    ],
};

export default MultiSearchPlugin;