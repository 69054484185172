import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, Button, InputGroup, ButtonToolbar, Card, Spinner } from 'react-bootstrap';
import { Routes } from "../../../../../routes";
import { Link, Redirect, Route } from "react-router-dom";
import { faAngry } from "@fortawesome/free-solid-svg-icons";
import { IAppState, IActionResult } from '../../../../../redux/storeTypes';
import PageSectionContainer from "../../../../../components/layout/PageSectionContainer";
import { WebPage, WebApplication, WebPageEnum } from "../../../../../models/PaymentChannelWebApplication";
import { Content } from "../../../../../models/CMS";
import Table from '../../../../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../../../../components/Formatters';
import { getWebPageAction } from '../../../../../redux/actions/clients/paymentChannelWebApplications';
import FormHeader from "../../../../clients/components/forms/FormHeader";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

export interface ISiteEditorEditor {
    webPage: WebPage
    isFetching: boolean,
    isSaving: boolean,
    webApplication: WebApplication,
    actionResult: IActionResult,
    cmsActionResult: IActionResult,
    content: Content
}

const SiteEditorSection = ({ isFetching, isSaving, webApplication, actionResult, webPage, cmsActionResult, content }: ISiteEditorEditor) => {
    const [redirect, setRedirect] = useState<string>("");
    const dispatch = useDispatch();
    const actionToken = "SiteEditorSection";

    let data: Array<WebPage> = [];

    if (webApplication !== null && webApplication !== undefined && webApplication.webPages !== null && webApplication.webPages !== undefined) {
        data = webApplication.webPages;
    }

    const actionsFormatter = (cell: any, row: any) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-client-dropdown">Manage</Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        onSelect={() => {
                            dispatch(getWebPageAction(row.msbId, actionToken));
                            setRedirect(Routes.WebPaymentChannelPageEditor.path);
                        }}
                    >
                        Edit Page
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }


    const cellRouteFormatter = (cell: any, webPage: WebPage) => {
        return (
            <a tabIndex={0} onClick={() => {
                dispatch(getWebPageAction(webPage.msbId!, actionToken));
                setRedirect(Routes.WebPaymentChannelPageEditor.path);
            }}>{getWebPageName(webPage.webPageType)}</a>
        );
    }

    const getWebPageName = (webPageEnum: WebPageEnum) => {
        if (webPageEnum === WebPageEnum.Account) {
            return "Account";
        } else if (webPageEnum === WebPageEnum.Category) {
            return "Category";
        } else if (webPageEnum === WebPageEnum.ItemDetail) {
            return "Item Detail";
        } else if (webPageEnum === WebPageEnum.Landing) {
            return "Landing";
        } else if (webPageEnum === WebPageEnum.Login) {
            return "Login";
        } else if (webPageEnum === WebPageEnum.Payment) {
            return "Payment";
        } else if (webPageEnum === WebPageEnum.Receipt) {
            return "Receipt";
        } else if (webPageEnum === WebPageEnum.ShoppingCart) {
            return "Shopping Cart";
        }else {
            return WebPageEnum[webPageEnum]
        }
    }

    const cellAuthorFormatter = (cell: any, row: any) => {
        return (<span>{cell}</span>);
    }

    const cellRevisionsFormatter = (cell: any, row: any) => {
        return (<span>{cell}</span>);
    }

    const cellSiteStatusFormatter = (cell: any, row: any) => {
        return (
            <>
                {cell ?
                    <span style={{ display: 'flex', alignItems: 'center' }} > <FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: '#52C41A' }} />Published</span >
                    :
                    <span style={{ display: 'flex', alignItems: 'center' }} > <FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: '#FA8C16' }} />Draft</span >
                }
            </>
        );
        // TODO: Add color dots to indicate page status
        // Unpublished: #D9D9D9
        // Draft: #FA8C16
        // Published: #52C41A
    }

    const columns = [
        {
            dataField: 'webPageType',
            text: 'Title',
            editable: false,
            formatter: cellRouteFormatter
        },/*{
            dataField: 'author',
            text: 'Author',
            formatter: cellAuthorFormatter,
            editable: false
        },*/ 
        {
            dataField: 'updatedAt',
            text: 'Date',
            formatter: DateFormatter,
            editable: false
        }, {
            dataField: 'revision',
            text: 'Revision',
            formatter: cellRevisionsFormatter,
            editable: false
        },{
            dataField: 'isPublished',
            text: 'Status',
            formatter: cellSiteStatusFormatter,
            editable: false
        },{
            dataField: 'id',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
        }
    ];

    const renderSpinner = (isSaving:boolean) => {
        if (isSaving) {
            return(<Spinner as="span" animation="border" size="sm" className={"button-spinner"} />);
        } else {
            return(<></>);
        }
    }
    
    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <FormHeader title="Site editor" description="Below are the options to select that give you control over the client’s website design and settings." />
                <Row className="mb-0">
                    <Col>
                        <div className="manage-card mb-4">
                            <Card>
                                <Card.Header>
                                    <h2>Manage Web Pages</h2>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Table
                                            keyField="msbId"
                                            data={data}
                                            columns={columns}
                                        />
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="web-settings-footer">
                            <Button variant="outline-secondary">Preview</Button>
                            <Button variant="outline-secondary" type="submit" disabled={isSaving}>Save Draft</Button>
                            {renderSpinner(isSaving)}
                            <Button variant="primary" disabled={isSaving}>Submit for Approval</Button>
                        </div>
                    </Col>
                </Row>         
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        webPage: state.paymentChannelWebApplication.webPage,
        webApplication: state.paymentChannelWebApplication.webApplication,
        isFetching: state.paymentChannelWebApplication.isFetching,
        isSaving: state.paymentChannelWebApplication.isSaving,
        actionResult: state.paymentChannelWebApplication.actionResult,
        cmsActionResult: state.cms.actionResult,
        content: state.cms.content
    };
};
export default connect(mapStateToProps)(SiteEditorSection);