import { CellPlugin } from '@react-page/editor';
import { ScriptElementKindModifier } from 'typescript';
import ItemSearch, { ACCOUNT_ID_LABEL,ADDRESS_LABEL,AMOUNT_DUE_LABEL,ACTIONS_LABEL,OWNERS_LABEL, HEADER_BACKGROUND_COLOR,PAYMENT_DATE_LABEL,PAYMENT_METHOD_LABEL,PAYMENT_OPTION_LABEL, HEADER_PADDING } from './components/ItemSearch';

// use a type here, not an interface
type Data = {
    title: string;
    accountId: string,
    owners: string,
    address: string,
    amountDue: string,
    action: any,
    paymentDate: string,
    paymentOption: string,
    paymentMethod: string,
    headerBackgroundColor: string
}

const ItemSearchPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <ItemSearch
            accountIdLabel={data.accountId}
            ownersLabel={data.owners}
            addressLabel={data.address}
            amountDueLabel={data.amountDue}
            actionLabel={data.action}
            accountId={'R0350590'}
            owners={'Larry G Pederson & Cheryl Risch Pederson'}
            address={'1923 Chesapeake Ln Highlands Ranch, CO 80126 '}
            amountDue={'$3,622.62'}
            action={''}
            paymentDateLabel={data.paymentDate}
            paymentMethodLabel={data.paymentMethod}
            paymentOptionLabel={data.paymentOption}
            headerBackgroundColor={data.headerBackgroundColor}
        />
    ),
    id: 'itemSearchPlugin',
    title: 'Item Search',
    description: 'Item Search',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        accountId: {
                            type: 'string',
                            default: ACCOUNT_ID_LABEL,
                        },
                        owners: {
                            type: 'string',
                            default: OWNERS_LABEL,
                        },
                        address: {
                            type: 'string',
                            default: ADDRESS_LABEL,
                        },
                        amountDue: {
                            type: 'string',
                            default: AMOUNT_DUE_LABEL,
                        },
                        action: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: ['accountId', 'owners', 'address', 'amountDue'],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Modal Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        paymentDate: {
                            type: 'string',
                            default: PAYMENT_DATE_LABEL,
                        },
                        paymentOption: {
                            type: 'string',
                            default: PAYMENT_OPTION_LABEL,
                        },
                        paymentMethod: {
                            type: 'string',
                            default: PAYMENT_METHOD_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default ItemSearchPlugin;