import {
    GET_NOTIFICATION_TYPES_REQUEST, GET_NOTIFICATION_TYPES_SUCCESS, GET_NOTIFICATION_TYPES_FAILURE,
    GET_PAYMENT_CHANNEL_NOTIFICATIONS_REQUEST, GET_PAYMENT_CHANNEL_NOTIFICATIONS_SUCCESS, GET_PAYMENT_CHANNEL_NOTIFICATIONS_FAILURE,
    SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS, SAVE_USER_NOTIFICATION_SETTINGS_FAILURE,
    SET_NOTIFICATIONS, DELETE_NOTIFICATIONS, CLEAR_NOTIFICATIONS
} from '../actions/account/notificationSettings';

export default function account(state = {
    isFetching: false,
    isFetchingNotifications: false,
    isSaving: false,
    notificationTypes: null,
    emailPaymentChannelNotifications: null,
    paymentChannelNotifications: null,
    deletePaymentChannelNotifications: null,
    actionResult: null,
    userNotifications: null,
    orderTransaction: null,
    notifications: null,
}, { type, payload }) {
    switch (type) {  
        case GET_NOTIFICATION_TYPES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                notificationTypes: null,
                actionResult: { type: GET_NOTIFICATION_TYPES_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_NOTIFICATION_TYPES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_NOTIFICATION_TYPES_REQUEST, result: GET_NOTIFICATION_TYPES_SUCCESS, token: payload.actionToken } ,
                notificationTypes: payload.notificationTypes
            });
        case GET_NOTIFICATION_TYPES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_NOTIFICATION_TYPES_REQUEST, result: GET_NOTIFICATION_TYPES_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });    
        case GET_PAYMENT_CHANNEL_NOTIFICATIONS_REQUEST:
            return Object.assign({}, state, {
                isFetchingNotifications: true,
                emailPaymentChannelNotifications: null,
                actionResult: { type: GET_PAYMENT_CHANNEL_NOTIFICATIONS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_PAYMENT_CHANNEL_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                isFetchingNotifications: false,                
                actionResult: { type: GET_PAYMENT_CHANNEL_NOTIFICATIONS_REQUEST, result: GET_PAYMENT_CHANNEL_NOTIFICATIONS_SUCCESS, token: payload.actionToken } ,
                emailPaymentChannelNotifications: payload.paymentChannelNotifications
            });
        case GET_PAYMENT_CHANNEL_NOTIFICATIONS_FAILURE:
            return Object.assign({}, state, {
                isFetchingNotifications: false,
                actionResult: { type: GET_PAYMENT_CHANNEL_NOTIFICATIONS_REQUEST, result: GET_PAYMENT_CHANNEL_NOTIFICATIONS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_USER_NOTIFICATION_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS:
           return Object.assign({}, state, {
            isSaving: false,
                actionResult: { type: SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, result: SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS, token: payload.actionToken },
                userNotificationSettings: payload.userNotificationSettings
            });
        case SAVE_USER_NOTIFICATION_SETTINGS_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, result: SAVE_USER_NOTIFICATION_SETTINGS_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });    
        case SET_NOTIFICATIONS:
            return Object.assign({}, state, {
                paymentChannelNotifications: payload.paymentChannelNotifications
            }); 
        case DELETE_NOTIFICATIONS:
            return Object.assign({}, state, {
                deletePaymentChannelNotifications: payload.deletePaymentChannelNotifications
            }); 
        case CLEAR_NOTIFICATIONS:
            return Object.assign({}, state, {
                paymentChannelNotifications: null,
                deletePaymentChannelNotifications: null
            });       
        default:
            return state;
    }

}