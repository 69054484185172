import axios from 'axios';
import { MerchantProcessor as Merchant, PaymentChannel, Terminal } from '../../../models/Client';
import { Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';
import store from '../../store';
import { sendErrorToastAction, sendSuccessToastAction } from '../toast';

export const ADD_TERMINAL_REQUEST = 'ADD_TERMINAL_REQUEST';
export const ADD_TERMINAL_SUCCESS = 'ADD_TERMINAL_SUCCESS';
export const ADD_TERMINAL_FAILURE = 'ADD_TERMINAL_FAILURE';

export const SAVE_TERMINAL_REQUEST = 'SAVE_TERMINAL_REQUEST';
export const SAVE_TERMINAL_SUCCESS = 'SAVE_TERMINAL_SUCCESS';
export const SAVE_TERMINAL_FAILURE = 'SAVE_TERMINAL_FAILURE';

export const DELETE_TERMINAL_REQUEST = 'DELETE_TERMINAL_REQUEST';
export const DELETE_TERMINAL_SUCCESS = 'DELETE_TERMINAL_SUCCESS';
export const DELETE_TERMINAL_FAILURE = 'DELETE_TERMINAL_FAILURE';

export const UPDATE_TERMINAL_REQUEST = 'UPDATE_TERMINAL_REQUEST';
export const UPDATE_TERMINAL_SUCCESS = 'UPDATE_TERMINAL_SUCCESS';
export const UPDATE_TERMINAL_FAILURE = 'UPDATE_TERMINAL_FAILURE';

export const addTerminalAction = (terminal: Terminal, merchantProcessorId: string, activationCode: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_TERMINAL_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `MSB_Client/api/v1/Terminals/addAndBoardForMerchant?merchantProcessorId=${merchantProcessorId}&activationCode=${activationCode}`

    axios.post(_url, terminal)
        .then(function (response) {
            terminal = response.data;
            dispatch({
                type: ADD_TERMINAL_SUCCESS,
                payload: {
                    terminal,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: ADD_TERMINAL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveTerminalAction = (terminal: Terminal, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_TERMINAL_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/Terminals/${terminal.msbId}`

    axios.put(_url, terminal)
        .then(function (response) {
            terminal = response.data;
            dispatch({
                type: SAVE_TERMINAL_SUCCESS,
                payload: {
                    terminal,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_TERMINAL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });    
};

export const deleteTerminalAction = (terminalMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_TERMINAL_REQUEST,
        payload: {
            terminalMsbId,
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Terminals/" + terminalMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_TERMINAL_SUCCESS,
                payload: {
                    terminalMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_TERMINAL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });    
};

export const printTerminalAction = (text: any, vantivCredential: any) => (dispatch: Dispatch<any>) => {
    let _url = "https://triposcert.vantiv.com/api/v1/receipt"

    let _vantivCredential = {
        acceptorId: "4445024837542",
        accountId: "1050661",
        accountToken: "818DD22C91C9974BE3FFDC2E4EE1B735558478CD64CE122E2FD3B96FC74AD9E840E0B701"
    }

    if (store.getState().webAppSettings.environment === "Production") {
        _vantivCredential = vantivCredential;
        _url = "https://tripos.vantiv.com/api/v1/receipt";
    }

    const config = {
        headers: {
            "tp-application-id": "8708",
            "tp-application-name": "triPOS.Vantiv.Gila",
            "tp-application-version": "1.0.0",
            "tp-express-acceptor-id": `${_vantivCredential?.acceptorId}`,
            "tp-express-account-id": `${_vantivCredential?.accountId}`,
            "tp-express-account-token": `${_vantivCredential?.accountToken}`,
            "tp-request-id": `${uuidv4()}`,
            "Content-Type": "application/json"
        }
    };

    var instance = axios.create();
    delete instance.defaults.headers.common;

    instance.post(_url, text, config)
        .then(function (response) {
            dispatch(sendSuccessToastAction("TriPOS Terminal Print successful"));
        })
        .catch(function (error) {
            dispatch(sendErrorToastAction("TriPOS Terminal Print failed : " + error?.response?.data?.exceptionMessage));
        });
};

export const updateTerminalAction = (terminal: Terminal, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_TERMINAL_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/TerminalOnBoarding/${terminal.msbId}/updateIdleMessage?idleMessage=${terminal.idleMessage}`;

    axios.patch(_url)
        .then(function (response) {
            terminal = response.data;
            dispatch({
                type: UPDATE_TERMINAL_SUCCESS,
                payload: {
                    terminal,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: UPDATE_TERMINAL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};