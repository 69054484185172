import { Dispatch } from 'react';
import axios from 'axios';

import { Order, ProcessedOrder } from '../../../models/Payment';
import store from "../../../redux/store";

export const CALCULATE_ORDER_REQUEST = 'CALCULATE_ORDER_REQUEST';
export const CALCULATE_ORDER_SUCCESS = 'CALCULATE_ORDER_SUCCESS';
export const CALCULATE_ORDER_FAILURE = 'CALCULATE_ORDER_FAILURE';

export const CHARGEBACK_PAYMENT_REQUEST = 'CHARGEBACK_PAYMENT_REQUEST';
export const CHARGEBACK_PAYMENT_SUCCESS = 'CHARGEBACK_PAYMENT_SUCCESS';
export const CHARGEBACK_PAYMENT_FAILURE = 'CHARGEBACK_PAYMENT_FAILURE';

export const CHARGEBACK_REVERSAL_REQUEST = 'CHARGEBACK_REVERSAL_REQUEST';
export const CHARGEBACK_REVERSAL_SUCCESS = 'CHARGEBACK_REVERSAL_SUCCESS';
export const CHARGEBACK_REVERSAL_FAILURE = 'CHARGEBACK_REVERSAL_FAILURE';

export const SAVE_ORDER_REQUEST = 'SAVE_ORDER_REQUEST';
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
export const SAVE_ORDER_FAILURE = 'SAVE_ORDER_FAILURE';

export const SET_PROCESSED_ORDER = 'SET_PROCESSED_ORDER';

export const CLEAR_ORDER = 'CLEAR_ORDER';
export const CLEAR_ACTION_RESULT = 'CLEAR_ACTION_RESULT';

export const clearOrderAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_ORDER
    });
};

export const clearActionResult = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_ACTION_RESULT
    });
};

export const calculateOrderAction = (order: Order, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CALCULATE_ORDER_REQUEST,
        payload: {
            order,
            actionToken
        }
    });

    let _url = "/MSB_Order/api/v1/ProcessOrder/IncrementFee";
    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;
    let { type, clientMsbId, departmentMsbId, paymentChannelMsbId, paymentChannelName, ...rest } = order;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.post(_url, rest, config)
        .then(function (response) {
            dispatch({
                type: CALCULATE_ORDER_SUCCESS,
                payload: {
                    calculatedOrder: response.data.requestObject,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'We could not calculate the fee to be assessed. Please select a payment type and re-enter the payment details.';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            console.error(errorMessage);
            dispatch({
                type: CALCULATE_ORDER_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });

};

export const processOrderAction = (order: Order, actionToken: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_ORDER_REQUEST,
        payload: {
            order,
            actionToken
        }
    });

    //let remoteIpAddress: any = await axios.get("https://get-ip-only.herokuapp.com");

    let _url = "/MSB_Order/api/v1/ProcessOrder/ProcessOrder";
    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;
    let { type, clientMsbId, departmentMsbId, paymentChannelMsbId, paymentChannelName, ...rest } = order;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId,
        }
    };

    axios.post(_url, rest, config)
        .then(function (response) {
            dispatch({
                type: SAVE_ORDER_SUCCESS,
                payload: {
                    processedOrder: response.data,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = '';
            if (error.response) {
                if (error.response.data?.processorType == "VantivExpress") {
                    errorMessage = error.response.data.processorMessage;
                } else if (error.response.data?.processorType == "ElectronicCheck") {
                    errorMessage = error.response.data.processorMessage;
                } else if (error.response.data?.processorType == "VantivTriPos") {
                    if (error.response.data.processorData?._hasErrors) {
                        errorMessage = error.response.data.processorData._errors[0].exceptionMessage;
                    } else {
                        errorMessage = error.response.data.processorMessage;
                    }
                } else {
                    errorMessage = error.response.data?.message || error.response.data?.processorMessage ;
                }
            }
            dispatch({
                type: SAVE_ORDER_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
    
    };

export const processChargebackAction = (order: Order ,actionToken?: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: CHARGEBACK_PAYMENT_REQUEST,
        payload: {
            order,
            actionToken
        }
    });

    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            ClientId: order.clientMsbId,
            DepartmentId: order.departmentMsbId,
            PaymentChannelId: order.paymentChannelMsbId
        }
    };

    let _url = "/MSB_Order/api/v1/ProcessOrder/CreateChargeback"

    axios.post(_url, order, config)
        .then(function (response) {
            const modifiedProcessedOrder = {processorData: response.data}
            dispatch({
                type: CHARGEBACK_PAYMENT_SUCCESS,
                payload: {
                    processedOrder: modifiedProcessedOrder,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: CHARGEBACK_PAYMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};


export const processChargebackReversalAction = (chargebackReversalDetails: any ,actionToken?: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: CHARGEBACK_REVERSAL_REQUEST,
        payload: {
            chargebackReversalDetails,
            actionToken
        }
    });

    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            ClientId: chargebackReversalDetails.transactionDetails.clientId,
            DepartmentId: chargebackReversalDetails.transactionDetails.departmentId,
            PaymentChannelId: chargebackReversalDetails.transactionDetails.paymentChannelId
        }
    };

    let _url = "/MSB_Order/api/v1/ProcessOrder/ChargebackReversal";

    const reversalBody = {transactionId: chargebackReversalDetails.transactionId, postedAt: chargebackReversalDetails.postedAt }

    axios.put(_url, reversalBody, config)
        .then(function (response) {
            const modifiedProcessedOrder = {processorData: response.data}
            dispatch({
                type: CHARGEBACK_REVERSAL_SUCCESS,
                payload: {
                    processedOrder: modifiedProcessedOrder,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: CHARGEBACK_REVERSAL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};


export const setProcessOrderAction = (processedOrder: any, actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_PROCESSED_ORDER,
        payload: {
            processedOrder: processedOrder,
            actionToken: actionToken
        }
    });

};


