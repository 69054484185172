export const ClientManagementTabsHeaders = [
    {
        title: 'Details',
        key: 'manage_details',
    },
    {
        title: 'Addresses',
        key: 'manage_addresses',
    },
    {
        title: 'Contacts',
        key: 'manage_contacts',
    },
    {
        title: 'Merchants',
        key: 'manage_merchants',
    },
    {
        title: 'Departments',
        key: 'manage_departments',
    },   
    {
        title: 'Users',
        key: 'manage_users',
    },   
    {
        title: 'Constituents',
        key: 'manage_constituents',
    },   
    {
        title: 'Agreement',
        key: 'manage_agreement',
    },   
    {
        title: 'Approval Checklist',
        key: 'manage_checklist',
    },   
]