import Table from '../../../../components/Table';
import { CurrencyFormatter } from '../../../../components/Formatters';
import { MsbReportItem } from '../../../../models/Reports';

const DetailsSummary = ({ creditCardPayments, eCheckPayments, chargebacks, chargebackReversals, refunds }: {
    creditCardPayments: Array<MsbReportItem>,
    eCheckPayments: Array<MsbReportItem>,
    chargebacks: Array<MsbReportItem>,
    chargebackReversals: Array<MsbReportItem>,
    refunds: Array<MsbReportItem>,
}) => {
    const columns = [
        {
            dataField: 'paymentsName',
            text: 'Payments',
            editable: false
        },
        {
            dataField: 'totalCount',
            text: 'Transactions',
            editable: false
        }, 
        {
            dataField: 'amount',
            text: 'Invoice total',
            editable: false,
            formatter: CurrencyFormatter
        }, 
        {
            dataField: 'convenienceFee',
            text: 'Convenience fee total',
            editable: false,
            formatter: CurrencyFormatter
        }, 
        {
            dataField: 'totalAmount',
            text: 'Total amount',
            editable: false,
            formatter: CurrencyFormatter
        }
    ];

    function getRow(items: Array<MsbReportItem>) {
        return {
            totalCount: items.reduce((acc, curr) => acc + curr.totalCount, 0),
            amount: items.reduce((acc, curr) => acc + curr.amount, 0),
            convenienceFee: items.reduce((acc, curr) => acc + curr.convenienceFee, 0),
            totalAmount: items.reduce((acc, curr) => acc + curr.totalAmount, 0)
        };
    }

    const ccRow = getRow(creditCardPayments);
    const eCheckRow = getRow(eCheckPayments);
    const subTotalRow = {
        totalCount: ccRow.totalCount + eCheckRow.totalCount,
        amount: ccRow.amount + eCheckRow.amount,
        convenienceFee: ccRow.convenienceFee + eCheckRow.convenienceFee,
        totalAmount: ccRow.totalAmount + eCheckRow.totalAmount,
    };

    const cbRow = getRow(chargebacks);
    const cbrRow = getRow(chargebackReversals);
    const rfdRow = getRow(refunds);

    const totalNetRow = {
        totalCount: subTotalRow.totalCount + (cbRow.totalCount + cbrRow.totalCount +  rfdRow.totalCount),
        amount: subTotalRow.amount + (cbRow.amount + cbrRow.amount +  rfdRow.amount),
        convenienceFee: subTotalRow.convenienceFee + (cbRow.convenienceFee + cbrRow.convenienceFee +  rfdRow.convenienceFee),
        totalAmount: subTotalRow.totalAmount + (cbRow.totalAmount + cbrRow.totalAmount +  rfdRow.totalAmount)
    };

    const rowStyle = (_row: any, rowIndex: number) => {
        return [2, 6].includes(rowIndex) ? { fontWeight: "bold", backgroundColor: "#FAFAFA" } : [1, 5].includes(rowIndex) ? { borderBottom: "1px solid #D9D9D9"} : {};
      };

    return (
        <Table
            keyField="paymentChannelName"
            columns={columns}
            data={[
                { paymentsName: "Credit Card", ...ccRow },
                { paymentsName: "eCheck", ...eCheckRow },
                { paymentsName: "Subtotal", ...subTotalRow },
                { paymentsName: "Chargebacks", ...cbRow },
                { paymentsName: "Chargeback Reversals", ...cbrRow },
                { paymentsName: "Refunds", ...rfdRow },
                { paymentsName: "Total net", ...totalNetRow },
            ]}
            rowStyle={ rowStyle }
        />
    );
};

export default DetailsSummary;