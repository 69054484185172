import React, { useEffect, useState } from "react";
import { ButtonToolbar, Button, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import Table from '../../../../components/Table';
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../redux/storeTypes';
import {
    ADD_CLIENT_CONTACT_REQUEST, ADD_CLIENT_CONTACT_SUCCESS, ADD_CLIENT_CONTACT_FAILURE,
    SAVE_CLIENT_CONTACT_REQUEST, SAVE_CLIENT_CONTACT_SUCCESS, SAVE_CLIENT_CONTACT_FAILURE,
    DELETE_CLIENT_CONTACT_REQUEST, DELETE_CLIENT_CONTACT_SUCCESS, DELETE_CLIENT_CONTACT_FAILURE
}
    from '../../../../redux/actions/clients/contacts';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { IClientManagementSectionProps } from '../ClientManagement';
import { Contact, ContactTypeEnum } from '../../../../models/Client';
import { deleteClientContactAction } from '../../../../redux/actions/clients/contacts';

import { Routes } from '../../../../routes'
import { Redirect } from 'react-router-dom';

import ClientContactForm from "../forms/ClientContactForm";
import FormHeader from '../forms/FormHeader';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import PermissionModule, { Permissions } from '../../../../components/usePermissions';
import _ from "lodash";

const ClientContactsSection = ({ isFetching, isSaving, client, actionResult }: IClientManagementSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<boolean>(false);
    const [contact, setContact] = useState<Contact>();
    const [showContactForm, setShowContactForm] = useState(false);

    let contactAddActionToken = "ClientContactAdd";
    let contactSaveActionToken = "ClientContactSave";
    let contactDeleteActionToken = "ClientContactDelete";

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_CLIENT_CONTACT_REQUEST && actionResult.token === contactAddActionToken) {
                if (actionResult.result === ADD_CLIENT_CONTACT_SUCCESS) {
                    setShowContactForm(false)
                    dispatch(sendSuccessToastAction("Client contact has been successfully added."));
                } else if (actionResult.result === ADD_CLIENT_CONTACT_FAILURE) {
                    dispatch(sendErrorToastAction("Client contact could not be added. Reason(s) : " + actionResult.message));
                }
            }

            if (actionResult.type === SAVE_CLIENT_CONTACT_REQUEST && actionResult.token === contactSaveActionToken) {
                if (actionResult.result === SAVE_CLIENT_CONTACT_SUCCESS) {
                    setShowContactForm(false)
                    dispatch(sendSuccessToastAction("Client contact has been successfully updated."));
                } else if (actionResult.result === SAVE_CLIENT_CONTACT_FAILURE) {
                    dispatch(sendErrorToastAction("Client contact could not be updated. Reason(s) : " + actionResult.message));
                }
            }

            if (actionResult.type === DELETE_CLIENT_CONTACT_REQUEST && actionResult.token === contactDeleteActionToken) {
                if (actionResult.result === DELETE_CLIENT_CONTACT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Client contact has been successfully deleted."));
                } else if (actionResult.result === DELETE_CLIENT_CONTACT_FAILURE) {
                    dispatch(sendErrorToastAction("Client contact could not be deleted."));
                }
            }
        }
    }, [actionResult]);  

    const contactTypeFormatter = (cell: any, contact: Contact) => {
        return _.startCase(_.camelCase(cell));
    }

    const contactNameFormatter = (_cell: any, contact: Contact) => {
        return contact.firstName + " " + contact.lastName;
    }

    const actionsFormatter = (cell: any, contact: Contact) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-contact-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item onSelect={() => { setContact(contact); setShowContactForm(true); }}>
                            Edit Contact
                        </Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => dispatch(deleteClientContactAction(contact.msbId!, contactDeleteActionToken))}>
                            Delete Contact
                        </Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    function doToolbar() {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => { setContact(undefined); setShowContactForm(true) }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Contact
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        }, {
            dataField: 'contactReferenceName',
            text: 'Contact reference name',
            editable: false
        }, {
            dataField: 'contactType',
            text: 'Contact type',
            editable: false,
            formatter: contactTypeFormatter
        }, {
            dataField: 'firstName',
            text: 'Name',
            editable: false,
            formatter: contactNameFormatter
        }, {
            dataField: 'msbId',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
        }
    ];

    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                <Modal size="lg" show={showContactForm} onHide={() => setShowContactForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <h2>{contact ? "Edit Contact" : "Add Contact" }</h2>
                        <ClientContactForm
                            isSaving={isSaving}
                            isFetching={isFetching}
                            client={client}
                            contact={contact}
                            actionToken={contact ? contactSaveActionToken : contactAddActionToken}
                            formActionProps={{
                                disabled: isSaving || isFetching,
                                showSpinner: isFetching,
                                hasSubmit: true,
                                isEditMode: contact ? true : false,
                                onCancel: () => setShowContactForm(false),
                                customProps: {
                                    submitButtonDisplay: contact ? "Save" : "Add"
                                }
                            }}
                        />
                    </Modal.Body>
                </Modal>

                <FormHeader title="Client Contacts" description="Please enter the client's contacts." />

                <div className="manage-card">
                  <ToolkitProvider keyField="msbId" data={client.clientContacts} columns={columns} search>
                    {
                        (props: any) => (
                            <PageSectionContainer title={`${client.businessName} Contacts`} toolbar={doToolbar()}>
                                <>
                                    <Table 
                                      keyField="msbId" 
                                      columns={columns} 
                                      data={client.clientContacts.length} 
                                      {...props.baseProps}
                                    />
                                </>
                            </PageSectionContainer>
                        )
                    }
                    </ToolkitProvider>
                </div>

                <div className="manage-footer">
                  <Button type="button" variant="link" onClick={() => setRedirect(true)} style={{ float: "right" }}>Cancel</Button>
                </div>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(ClientContactsSection);