import { Row, Col, Form, ButtonToolbar, Button, Modal, ListGroup } from "react-bootstrap"
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useState } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface IItemSearch {
    accountIdLabel: string,
    ownersLabel: string,
    addressLabel: string,
    amountDueLabel: string,
    actionLabel: string,
    accountId: string,
    owners: string,
    address: string,
    amountDue: string,
    action: any,
    paymentDateLabel: string,
    paymentOptionLabel:string,
    paymentMethodLabel: string,
    headerBackgroundColor: string
}

export const ACCOUNT_ID_LABEL = 'Account ID';
export const OWNERS_LABEL = 'Owners';
export const ADDRESS_LABEL = 'Address';
export const AMOUNT_DUE_LABEL = 'Amount Due';
export const ACTIONS_LABEL = 'Actions';
export const PAYMENT_DATE_LABEL = 'Select Future Payment Date';
export const PAYMENT_OPTION_LABEL = 'Select Payment Option';
export const PAYMENT_METHOD_LABEL = 'Select Payment Method';
export const HEADER_BACKGROUND_COLOR = '#FAFAFA';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const ItemSearch = ({ accountIdLabel, ownersLabel, addressLabel, amountDueLabel, actionLabel, accountId, owners, address, amountDue, action,
    paymentDateLabel, paymentOptionLabel, paymentMethodLabel, headerBackgroundColor }: IItemSearch) => {

    const [scheduleModal, showScheduleModal] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>([]);

    if (!accountIdLabel) {
        accountIdLabel = ACCOUNT_ID_LABEL;
    }

    if (!ownersLabel) {
        ownersLabel = OWNERS_LABEL;
    }

    if (!addressLabel) {
        addressLabel = ADDRESS_LABEL;
    }

    if (!amountDueLabel) {
        amountDueLabel = AMOUNT_DUE_LABEL;
    }

    if (!actionLabel) {
        actionLabel = ACTIONS_LABEL;
    }

    if (!paymentDateLabel) {
        paymentDateLabel = PAYMENT_DATE_LABEL;
    }

    if (!paymentOptionLabel) {
        paymentOptionLabel = PAYMENT_OPTION_LABEL;
    }

    if (!paymentMethodLabel) {
        paymentMethodLabel = PAYMENT_METHOD_LABEL;
    }


    const SearchFilter = (props: any) => {
        let input: any;
        const handleSubmit = (event: any) => {
            event.preventDefault();
            props.onSearch(input.value);
        };
        const handleReset = () => {
            props.onSearch(input.value = '');
        }
        return (
            <Form onSubmit={handleSubmit} className="table-search mb-4">
                <Row>
                    <Col xl={9} lg={9} md={12} sm={12}>
                        <Form.Group>
                            <Form.Control ref={(filterValue: any) => input = filterValue} type="text" placeholder="Search for Account ID, Parcel Number, Owner Name, Situs Address" />
                        </Form.Group>
                    </Col>
                    <Col xl={3} lg={3} md={12} sm={12}>
                        <ButtonToolbar>
                            <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                            <Button onClick={handleSubmit}>Search</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>
            </Form>
        );
    };

    const data = [{ accountId: accountId, owners: owners, address: address, amountDue: amountDue }]


    const scheduleFormatter = (cell: any, row: any) => {
        return (
            <span><a onClick={() => { showScheduleModal(true); setRowData(row) }}>Schedule</a></span>
        )
    }

    const columns = [{
        dataField: 'accountId',
        text: accountIdLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'owners',
        text: ownersLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'address',
        text: addressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'amountDue',
        text: amountDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'id',
        text: actionLabel,
        formatter: scheduleFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const renderpPaymentSchedule = () => {
        return (
            <Form id='schedulePayments' className="mt-0">
                <ListGroup variant="flush">
                    <ListGroup.Item className="p-0">
                        <div>
                            <h2>{`Add ${rowData.accountId} Payment Schedule`}</h2>
                            <p className="mb-4">Below are the details for this account.</p>
                            <div className="notification_details_description">
                                <h3>{accountIdLabel}</h3>
                                <span>{rowData.accountId}</span>
                            </div>
                            <div className="notification_details_description">
                                <h3>{ownersLabel}</h3>
                                <span>{rowData.owners}</span>
                            </div>
                            <div className="notification_details_description">
                                <h3>{addressLabel}</h3>
                                <span>{rowData.address}</span>
                            </div>
                            <div className="notification_details_description">
                                <h3>{amountDueLabel}</h3>
                                <span>{rowData.amountDue}</span>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <hr />
                    <ListGroup.Item className="p-0">
                        <div className="details_title">
                            <h2>Payment Schedule</h2>
                            <p>Please select the future payment date, payment method and payment option.</p>
                        </div>
                        <div>
                            <Form.Group as={Row} controlId="paymentDate" className="mb-3">
                                <Form.Label column>{paymentDateLabel}</Form.Label>
                                <Col>
                                    <Form.Control type="Date" required />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="paymentOption" className="mb-3">
                                <Form.Label column>{paymentOptionLabel}</Form.Label>
                                <Col>
                                    <Form.Control as="select" required>
                                        <option>First Half</option>
                                        <option>Full Payment</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="paymentType" className="mb-3">
                                <Form.Label column>{paymentMethodLabel}</Form.Label>
                                <Col>
                                    <Form.Control as="select" required>
                                        <option>Credit Card</option>
                                        <option>eCheck</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <p style={{ color: '#141414' }}><strong>Returned payments will be charged a $20 fee. Late payments will incur late interest of 1% per month.</strong></p>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Form>
        )
    }

    return (
        <>
            <Modal show={scheduleModal} onHide={() => showScheduleModal(false)} backdrop="static">
                <Modal.Header closeButton />
                <Modal.Body>
                    {renderpPaymentSchedule()}
                </Modal.Body>
            </Modal>
            <Row>
                <Col>
                    <ToolkitProvider keyField="id" data={data} columns={columns} search>
                        {
                            (props: any) => (
                                <>
                                    <Row>
                                        <Col>
                                            <SearchFilter {...props.searchProps} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                          <PerfectScrollbar>
                                            <BootstrapTable
                                                paginationTotalRenderer={''}
                                                pagination={paginationFactory({
                                                    page: 1,
                                                    sizePerPage: 10,
                                                    totalSize: data.length,
                                                    alwaysShowAllBtns: true,
                                                    withFirstAndLast: true,
                                                    hideSizePerPage: false,
                                                    firstPageText: '\u27E8\u27E8',
                                                    prePageText: '\u27E8',
                                                    nextPageText: '\u27E9',
                                                    lastPageText: '\u27E9\u27E9',
                                                    nextPageTitle: 'Next page',
                                                    prePageTitle: 'Previous page',
                                                    firstPageTitle: 'First page',
                                                    lastPageTitle: 'Last page',
                                                    showTotal: true,
                                                })}
                                                {...props.baseProps}
                                            />
                                          </PerfectScrollbar>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </ToolkitProvider>
                </Col>
            </Row>
        </>
    )
}

export default ItemSearch;