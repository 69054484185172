import { Row, Col, Button, Modal, Form } from "react-bootstrap"
import Table from '../../../../../../components/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

interface IForsythShoppingCart {
    title: string,
    subTitle: string,
    accountNumberLabel: string,
    billYearLabel: string,
    ownerLabel: string,
    billNumberLabel: string,
    situsAddressLabel: string,
    dueDateLabel: string,
    totalDueLabel: string,
    amountLabel: string,
    actionsLabel: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string
}

export const TITLE = 'Shopping Cart';
export const SUBTITLE = ''
export const BILL_YEAR_LABEL = 'Bill Year';
export const OWNER_LABEL = 'Owner'
export const TAX_YEAR_LABEL = 'Tax Year';
export const ACCOUNT_NUMBER_LABEL = 'Account number';
export const BILL_NUMBER_LABEL = 'Bill Number';
export const SITUS_ADDRESS_LABEL = 'Situs Address';
export const DUE_DATE_LABEL = 'Due Date';
export const TOTAL_DUE_LABEL = 'Total Due';
export const AMOUNT_LABEL = 'Amount'
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';
export const CART_BUTTON_FONT_COLOR = '#414141';
export const CART_BUTTON_FONT_SIZE = '.875rem';
export const CART_BUTTON_FONT_STYLE = 'normal';
export const CART_BUTTON_FONT_WEIGHT = 'normal';
export const CART_BUTTON_BORDER_COLOR = '#D9D9D9';
export const CART_BUTTON_BACKGROUND_COLOR = '#FFFFFF';
export const CART_ICON_COLOR = '#414141';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

const ForsythShoppingCart = ({ title, subTitle, accountNumberLabel, billYearLabel, ownerLabel, billNumberLabel, situsAddressLabel, totalDueLabel, amountLabel, dueDateLabel, actionsLabel,
    margin, padding, headerBackgroundColor, headerPadding, cartButtonBackgroundColor, cartButtonFontColor, cartButtonFontSize, cartButtonFontStyle, cartButtonFontWeight, cartButtonBorderColor, cartIconColor }: IForsythShoppingCart) => {

    if (!title) { title = TITLE }
    if (!subTitle) { subTitle = SUBTITLE }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!billYearLabel) { billYearLabel = BILL_YEAR_LABEL }
    if (!billNumberLabel) { billNumberLabel = BILL_NUMBER_LABEL }
    if (!ownerLabel) { ownerLabel = OWNER_LABEL }
    if (!situsAddressLabel) { situsAddressLabel = SITUS_ADDRESS_LABEL }
    if (!totalDueLabel) { totalDueLabel = TOTAL_DUE_LABEL }
    if (!dueDateLabel) { dueDateLabel = DUE_DATE_LABEL }
    if (!amountLabel) { amountLabel = AMOUNT_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }
    if (!cartButtonBackgroundColor) { cartButtonBackgroundColor = CART_BUTTON_BACKGROUND_COLOR }
    if (!cartButtonFontColor) { cartButtonFontColor = CART_BUTTON_FONT_COLOR }
    if (!cartButtonFontSize) { cartButtonFontSize = CART_BUTTON_FONT_SIZE }
    if (!cartButtonFontStyle) { cartButtonFontStyle = CART_BUTTON_FONT_STYLE }
    if (!cartButtonFontWeight) { cartButtonFontWeight = CART_BUTTON_FONT_WEIGHT }
    if (!cartButtonBorderColor) { cartButtonBorderColor = CART_BUTTON_BORDER_COLOR }
    if (!cartIconColor) { cartIconColor = CART_ICON_COLOR }


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const [showModal, setShowModal] = useState<boolean>(false);

    const initialColumns = [{
        dataField: 'parcelID',
        text: accountNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'billNumber',
        text: billNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'taxYear',
        text: billYearLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: false,
    }, {
        dataField: 'owner',
        text: ownerLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'situsAddress',
        text: situsAddressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    },{
        dataField: 'totalDue',
        text: totalDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    },{
        dataField: 'dueDate',
        text: dueDateLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    },{
        dataField: 'amount',
        text: amountLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const renderTable = () => {
        return (
            <div style={{marginTop: '12px'}}>
            <Table
                keyField="msbId"
                data={[]}
                columns={initialColumns}
            />
            </div>);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                <Button className="brand-btn-primary" style={{
                            marginTop: '29px',
                            backgroundColor: checkStyleForDefault(cartButtonBackgroundColor, CART_BUTTON_BACKGROUND_COLOR),
                            borderColor: checkStyleForDefault(cartButtonBorderColor, CART_BUTTON_BORDER_COLOR),
                            color: checkStyleForDefault(cartButtonFontColor, CART_BUTTON_FONT_COLOR),
                            fontSize: checkStyleForDefault(cartButtonFontSize, CART_BUTTON_FONT_SIZE),
                            fontStyle: checkStyleForDefault(cartButtonFontStyle, CART_BUTTON_FONT_STYLE),
                            fontWeight: checkStyleForDefault3(cartButtonFontWeight)
                        }} onClick={() => setShowModal(true)}>
                            <FontAwesomeIcon icon={faCartPlus} style={{ color: checkStyleForDefault(cartIconColor, CART_ICON_COLOR) }} className="btn-icon" />{`View Cart (count)`}
                        </Button>
                </Col>
            </Row>
            <Modal show={showModal} size={'xl'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form.Group>
                        {renderTable()}
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default ForsythShoppingCart;