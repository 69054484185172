import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Col, Row, Form, Button, Dropdown, ButtonToolbar, Modal } from 'react-bootstrap';
import { IAppState, IActionResult } from '../../../redux/storeTypes';
import PageHeader from '../../../components/layout/PageHeader';
import PageSectionContainer from '../../../components/layout/PageSectionContainer';
import Table from '../../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../../components/Formatters';
import { IPageableResults } from "../../../models/PageableResults";
import { Routes } from "../../../routes";
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Role } from '../../../models/Role';
import { sendSuccessToastAction, sendErrorToastAction } from '../../../redux/actions/toast';
import FormHeaderConfirmation from '../../../components/FormHeaderConfirmation';
import icoWarning from "../../../assets/img/icons/ico-warning-outline.svg";
import { Crumb } from "../../../models/Crumb";
import { getRolesAction, getRoleAction, cancelAction, deleteAction, getModulePermissions,
    DELETE_ROLE_REQUEST,DELETE_ROLE_SUCCESS,DELETE_ROLE_FAILURE } from '../../../redux/actions/roles';
import { UserTypeEnum } from '../../../models/User';

interface ClientRoleProps {
    roles: Array<Role>,
    isFetching: boolean,
    role: Role,
    actionResult?: IActionResult
}

const ClientRoles = ({ roles, isFetching, role, actionResult }: ClientRoleProps) => {
    let actionToken = "ClientRoles";
    const [redirect, setRedirect] = useState<string>("");
    const [deleteRole, setDeleteRole] = useState();
    const [showModal, setModal] = useState(false);
    const dispatch = useDispatch();
    let data: Array<any> = [];
    //let page: number = 1;
    //let totalRecords: number = 0;

    if (roles) {
        data = roles;
        //totalRecords = roles.length;
    }

    useEffect(() => {
        dispatch(getRolesAction(UserTypeEnum.Client, actionToken));
    }, []);

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === DELETE_ROLE_REQUEST) {
            if (actionResult.result === DELETE_ROLE_SUCCESS) {
                dispatch(sendSuccessToastAction("Role has been successfully deleted."));
            } else if (actionResult.result === DELETE_ROLE_FAILURE) {
                dispatch(sendErrorToastAction("Role could not be deleted."));
            }
        }
    }, [actionResult]);


    const actionsOperations = (row: any, operation: any) => {
        if (operation === "Delete") {
            dispatch(deleteAction(row.msbId, "delete"));
        }
        else if (operation === "Edit") {
            dispatch(getRoleAction(row.msbId, actionToken));
            dispatch(getModulePermissions(UserTypeEnum.Client, "clientPermissions"));
            setRedirect(Routes.EditClientRoles.path);
        }
    }

    const dropDownOptions = (cell: any, row: any) => {
        return (
            <Dropdown className="req-action-dropdown">
                <Dropdown.Toggle className="approvalAction" variant="link">
                    Manage
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => actionsOperations(row, "Edit")}>Edit Role</Dropdown.Item>
                    <Dropdown.Item onSelect={() => actionsOperations(row, "Delete")}>Delete Role</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        )
    }

    const columns = [{
        dataField: 'name',
        text: 'Name',
        editable: false,
        sort: true
    }, {
        dataField: 'updatedAt',
        text: 'Last updated',
        editable: false,
        formatter: DateFormatter
    }, {
        dataField: 'id',
        text: 'Actions',
        editable: false,
        formatter: dropDownOptions,
    }
    ];

    const addRoleAction = () => {
        return (
            <ButtonToolbar>
                <Button 
                    onClick={() => {
                        dispatch(cancelAction());
                        dispatch(getModulePermissions(UserTypeEnum.Client, "clientPermissions"));
                        setRedirect(Routes.AddClientRoles.path)
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Role
                </Button>
            </ButtonToolbar>
        )
    }


    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Client Roles", Routes.ClientRoles.path));
        return (
            <>
                <PageHeader title="Client Roles" crumbs={crumbs} />

                {/* <Modal show={showModal} className="modal-confirmation">
                    <Modal.Body>
                        <FormHeaderConfirmation iconImg={icoWarning} title="Delete role" />
                        <div className="confirmation-body">
                            <p>Are you sure you want to delete this role?</p>
                            <Form.Group className="confirmation-footer">
                                <Button variant="outline-secondary" onClick={() => setModal(false)}>
                                    Cancel
                                </Button>
                                <Button onClick={(e) => deleteRoleAction(e)}>
                                    Confirm
                                </Button>
                            </Form.Group>
                        </div>
                    </Modal.Body>
                </Modal> */}

                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer title="Manage Roles" toolbar={addRoleAction()}>
                                <Table
                                    keyField="id"
                                    data={data}
                                    columns={columns}                             
                                     />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        roles: state.roles.roles,
        isFetching: state.roles.isFetching,
        actionResult: state.roles.actionResult
    };
};

export default connect(mapStateToProps)(ClientRoles);