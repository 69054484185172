import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { BusinessTypeEnum, Client, MerchantProcessor as Merchant } from "../../../../models/Client";
import { IAppState, IActionResult } from '../../../../redux/storeTypes';
import { Routes } from '../../../../routes';
import MerchantTerminalsSubSection from "./MerchantTerminalsSubSection";
import FormHeader from "../forms/FormHeader";

const MerchantTerminalsSection = ({ isSaving, isFetching, client, merchant, actionResult }:
    { isSaving: boolean, isFetching: boolean, client: Client, merchant: Merchant, actionResult: IActionResult }) => {
    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                {merchant.businessType === BusinessTypeEnum.Retail ?
                    <>
                        <MerchantTerminalsSubSection isSaving={isSaving} isFetching={isFetching} client={client} merchant={merchant} actionResult={actionResult} />
                        {/*<hr />
                        <div className="manage-footer">
                            <Button type="button" variant="link" onClick={() => setRedirect(true)} style={{ float: "right" }}>Cancel</Button>
                        </div>*/}
                    </>
                    :
                    <>
                        <FormHeader title="Terminals" description="Terminals are only supported for retail merchants." />
                    </>
                }
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        client: state.clients.client,
        merchant: state.clients.merchant,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(MerchantTerminalsSection);