import React, { useState } from "react";
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import MerchantManagement from './components/MerchantManagement';
import { Container, Row, Col, Spinner, Breadcrumb } from 'react-bootstrap';
import { IAppState } from '../../redux/storeTypes';
import { Client, MerchantProcessor } from '../../models/Client';
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { Routes } from "../../routes";
//import { resetStoreMerchantAction } from "../../redux/actions/clients/merchants";
import { Crumb } from "../../models/Crumb";
import { resetStoreMerchantAction } from "../../redux/actions/clients/merchants";

export interface IMerchantProps {
    client: Client,
    merchant: MerchantProcessor,
    isFetching: boolean,
    isSaving: boolean
}

const Merchant = ({ client, merchant, isFetching, isSaving }: IMerchantProps) => {
    if (merchant) {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path));
        crumbs.push(new Crumb(client.businessName, Routes.Client.path, resetStoreMerchantAction()));
        crumbs.push(new Crumb(merchant.merchantIdentifier, Routes.MerchantEdit.path));

        return (
            <>
                <PageHeader crumbs={crumbs} title={"Manage Merchant"} />

                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <MerchantManagement client={client} merchant={merchant} isFetching={isFetching} isSaving={isSaving} />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path));

        return (
            <>
                <PageHeader crumbs={crumbs} title={"Manage Merchant"} />
                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Spinner animation="border" className="mb-1" />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client,
        merchant: state.clients.merchant,
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving
    };
};

export default connect(mapStateToProps)(Merchant);
