import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, Button, InputGroup, FormControl, FormLabel, Card, Spinner } from 'react-bootstrap';
import { Routes } from "../../../../../routes";
import { faAngry, faPlus } from "@fortawesome/pro-light-svg-icons";
import { IAppState, IActionResult } from '../../../../../redux/storeTypes';
import { ChromePicker } from "react-color";
import { WebApplication, WebPage, OpenLinkEnum, SiteSettings, SiteSetingsNavigationLinks, SiteStyles } from '../../../../../models/PaymentChannelWebApplication';
import FormHeader from '../../../../clients/components/forms/FormHeader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    GET_WEB_APPLICATION_REQUEST, GET_WEB_APPLICATION_SUCCESS, GET_WEB_APPLICATION_FAILURE,
    SAVE_WEB_APPLICATION_REQUEST, SAVE_WEB_APPLICATION_SUCCESS, SAVE_WEB_APPLICATION_FAILURE
} from '../../../../../redux/actions/clients/paymentChannelWebApplications';
import { sendErrorToastAction, sendSuccessToastAction, sendWarningToastAction } from '../../../../../redux/actions/toast';
import { Client } from '../../../../../models/Client';
import { Content, Revision, StatusEnum } from "../../../../../models/CMS";
import { getContentAction, saveContentAction} from "../../../../../redux/actions/cms";
import { SAVE_CMS_CONTENT_FAILURE, SAVE_CMS_CONTENT_REQUEST, SAVE_CMS_CONTENT_SUCCESS } from "../../../../../redux/actions/cms";
import { saveWebPageAction, getWebPageAction, saveWebApplicationAction } from "../../../../../redux/actions/clients/paymentChannelWebApplications";
import FormActions, { IFormActionProps } from '../../../../../pages/clients/components/forms/FormActions';
import { ColorLensOutlined } from "@material-ui/icons";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

export interface ISiteStyleProps {
    isFetching: boolean,
    isSaving: boolean,
    actionResult?: IActionResult,
    webApplication: WebApplication,
    formActionProps?: IFormActionProps,
    cmsActionResult: IActionResult,
    content: Content,
    client: Client
}

const SiteStyleSection = ({ isFetching, isSaving, actionResult, webApplication, formActionProps, cmsActionResult, content, client }: ISiteStyleProps) => {
    const [siteStyles, setSiteStyles] = useState<SiteStyles>();
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [paymentChannelMSBId, setPaymentChannelMsbId] = useState("");
    const actionToken = "SiteStyleSection";
    const [validated, setValidated] = useState(false);
    const [navigationLinks, setnavigationLinks] = useState("");
    const [linkLabelRows, setlinkLabelRows] = useState([new SiteSetingsNavigationLinks()]);

    const [displayHeaderBackgroundColorPicker, setDisplayHeaderBackgroundColorPicker] = useState(false);
    const [displayNavigationLinksColorPicker, setDisplayNavigationLinksColorPicker] = useState(false);
    const [displayBrandingColorPicker, setDisplayBrandingColorPicker] = useState(false);
    const [displayTextColorPicker, setDisplayTextColorPicker] = useState(false);
    const [displayTextLinksColorPicker, setDisplayTextLinksColorPicker] = useState(false);
    const [displayButtonsColorPicker, setDisplayButtonsColorPicker] = useState(false);

    const [headerbackgroundColor, setHeaderbackgroundCol] = useState("#22194D");
    const [navigationlinksColor, setNavigationlinksCol] = useState("#22194D");
    const [brandingColor, setBrandingColor] = useState("#22194D");
    const [textColor, setTextColor] = useState("#22194D");
    const [textlinksColor, setTextlinksColor] = useState("#22194D");
    const [buttonsColor, setButtonsColor] = useState("#22194D");

    const [siteStyleLogoSrc, setsiteStyleLogoSrc] = useState("");

    React.useEffect(() => {
        /*
        if (webApplication.siteStyleContentId !== null && webApplication.siteStyleContentId !== undefined) {

            // console.log("webApplication.siteStyleContentId");
            // console.log(webApplication.siteStyleContentId);
            dispatch(getConentAction(webApplication.siteStyleContentId, actionToken));
        */
    }, []);

    useEffect(() => {
        if (cmsActionResult && cmsActionResult.result) {
            if (cmsActionResult.type === SAVE_CMS_CONTENT_REQUEST && cmsActionResult.token === actionToken) {
                if (cmsActionResult.result === SAVE_CMS_CONTENT_SUCCESS) {
                    //dispatch(sendSuccessToastAction("SiteStyles has been successfully saved."));
                    webApplication.siteStyleContentId = content.msbId!;
                    dispatch(saveWebApplicationAction(webApplication, actionToken));
                } else if (cmsActionResult.result === SAVE_CMS_CONTENT_FAILURE) {
                    dispatch(sendErrorToastAction("Site styles could not be updated."));
                }
            }
        }
    }, [cmsActionResult]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === SAVE_WEB_APPLICATION_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_WEB_APPLICATION_SUCCESS) {
                    dispatch(sendSuccessToastAction("Site styles have been successfully saved."));
                    //webApplication.siteStyleContentId = content.msbId;
                    //dispatch(saveWebApplicationAction(webApplication, actionToken));
                } else if (actionResult.result === SAVE_WEB_APPLICATION_FAILURE) {
                    dispatch(sendErrorToastAction("Site styles could not be updated."));
                }
            }
        }
    }, [actionResult]);

    React.useEffect(() => {
        if (content != null) {
            if (webApplication !== null && webApplication.siteStyleContentId !== undefined && webApplication.siteStyleContentId !== null) {
                let _siteStyles: SiteStyles = new SiteStyles();
                for (let x = 0; x < content.revisions.length; x++) {
                    if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                        let revision = content.revisions[x];
                        _siteStyles = JSON.parse(revision.value) as SiteStyles;
                        setSiteStyles(_siteStyles);
                        break;
                    }
                }

                // console.log("content-sitestyles");
                // console.log(content);
                // console.log("_siteStyles");
                // console.log(_siteStyles);
            
                setHeaderbackgroundCol(_siteStyles.colorHeaderBackground);
                setNavigationlinksCol(_siteStyles.colorNavigationLinks);
                setBrandingColor(_siteStyles.colorBranding);
                setTextColor(_siteStyles.colorText);
                setTextlinksColor(_siteStyles.colorTextLinks);
                setButtonsColor(_siteStyles.colorButtons);
                setsiteStyleLogoSrc(_siteStyles.LogoFile);        
            }
        }
    }, [content]);

    const handleClickHeaderBackground = () => {
        if (displayHeaderBackgroundColorPicker)
            setDisplayHeaderBackgroundColorPicker(false);
        else
            setDisplayHeaderBackgroundColorPicker(true);
    }

    const handleClickNavigationLink = () => {
        if (displayNavigationLinksColorPicker)
            setDisplayNavigationLinksColorPicker(false);
        else
            setDisplayNavigationLinksColorPicker(true);
    }

    const handleClickBranding = () => {
        if (displayBrandingColorPicker)
            setDisplayBrandingColorPicker(false);
        else
            setDisplayBrandingColorPicker(true);
    }

    const handleClickText = () => {
        if (displayTextColorPicker)
            setDisplayTextColorPicker(false);
        else
            setDisplayTextColorPicker(true);
    }

    const handleClickTextLinks = () => {
        if (displayTextLinksColorPicker)
            setDisplayTextLinksColorPicker(false);
        else
            setDisplayTextLinksColorPicker(true);
    }
    
    const handleClickButtons = () => {
        if (displayButtonsColorPicker)
            setDisplayButtonsColorPicker(false);
        else
            setDisplayButtonsColorPicker(true);
    }    
  
    const handleChangeHeaderbackgroundColor = (color: any) => {
        setHeaderbackgroundCol(color.hex);
    };
    const handleChangeNavigationlinksColor = (color: any) => {
        setNavigationlinksCol(color.hex);
    };
    const handleChangeBrandingColor = (color: any) => {
        setBrandingColor(color.hex);
    };
    const handleChangeTextColor = (color: any) => {
        setTextColor(color.hex);
    };
    const handleChangeTextlinksColor = (color: any) => {
        setTextlinksColor(color.hex);
    };
    const handleChangeButtonsColor = (color: any) => {
        setButtonsColor(color.hex);
    };

    const handleSubmitSiteStyle = (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            let _siteStyles: SiteStyles = new SiteStyles();
            if (_siteStyles !== null) {
                _siteStyles = Object.assign({}, _siteStyles!!);

                _siteStyles.colorHeaderBackground = headerbackgroundColor;
                _siteStyles.colorNavigationLinks = navigationlinksColor;
                _siteStyles.colorBranding = brandingColor;
                _siteStyles.colorText = textColor;
                _siteStyles.colorTextLinks = textlinksColor;
                _siteStyles.colorButtons = buttonsColor;
                _siteStyles.LogoFile = siteStyleLogoSrc;

               //console.log("SiteStyle-HandleSave");

               if (webApplication !== null)// && webApplication.webPages !== null) {
               {
                   if (webApplication.siteStyleContentId === null || webApplication.siteStyleContentId === undefined) {
                       content = new Content();
                       content.title = "siteStyles";
                       //Set Client ID and Group Id
                       content.groupId = webApplication.msbId!; //GroupId
                       content.clientId = client.msbId!;
                       //content.clientId = webApplication.                       
                   }

                   let revision: Revision | null = null;
                   for (let x = 0; x < content.revisions.length; x++) {
                       if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                           revision = content.revisions[x];
                           break;
                       }
                   }

                   let strSiteStyles = JSON.stringify(_siteStyles);
                   if (revision === null) {
                       revision = new Revision();
                       revision.statusEnum = StatusEnum.Draft;
                       revision.value = strSiteStyles;
                       content.revisions.push(revision);
                   }
                   else {
                       revision.value = strSiteStyles;
                   }
                   
                   dispatch(saveContentAction(content, actionToken));
               }
            }
        }

        setValidated(true);
    };

    const siteStyleLogoFileChange = (event: any) => {
        let imageFile = event.target.files[0];
        //dispatch(saveSiteStyleLogoAction(currentUser.msbId!!, imageFile, actionToken));
    };

    const onImageChange = (evt:any) => {
        if(evt.target.files && evt.target.files[0])
        {
            let img = evt.target.files[0];
            setsiteStyleLogoSrc(URL.createObjectURL(img));
        }

        // console.log("rendersiteStyleLogoImage");
        // console.log(siteStyleLogoSrc);
    };

    const handleClickTest = (color:any) => {
        if (displayHeaderBackgroundColorPicker)
        setDisplayHeaderBackgroundColorPicker(false);
    else
        setDisplayHeaderBackgroundColorPicker(true);
      };
    
    const  handleCloseChromePicker = () => {
        setDisplayHeaderBackgroundColorPicker(false);
        setDisplayNavigationLinksColorPicker(false);
        setDisplayBrandingColorPicker(false);
        setDisplayTextColorPicker(false);
        setDisplayTextLinksColorPicker(false);
        setDisplayButtonsColorPicker(false);
      };

      const  handleCloseTest1 = () => {
        setDisplayNavigationLinksColorPicker(false);
      };

    const renderSpinner = (isSaving:boolean) => {
        if (isSaving) {
            return(<Spinner as="span" animation="border" size="sm" className={"button-spinner"} />);
        } else {
            return(<></>);
        }
    }

    return (
        <>
            <FormHeader title="Site style" description="Below are the options to select that give you control over the client's website design and settings." />
            <Form noValidate validated={validated} onSubmit={handleSubmitSiteStyle}>
                <Row>
                    <Col>
                        <div className="manage-card mb-4">
                            <div className="color-palettes">
                                <Card>
                                    <Card.Header>
                                        <h2>Color Palettes</h2>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Form.Group as={Col} lg={3} md={6} sm={12}>
                                                <FormLabel>Header background</FormLabel>
                                                <InputGroup>
                                                    <div className="chrome-picker-container">
                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                            <Button className="color-box" variant="outline-secondary" defaultValue={siteStyles?.colorHeaderBackground} disabled style={{ background: headerbackgroundColor, borderColor: headerbackgroundColor }}></Button>

                                                            <Button variant="outline-secondary" className="color-button" onClick={ handleClickHeaderBackground } disabled={isSaving}>Select Color</Button>

                                                            { displayHeaderBackgroundColorPicker ? <div className="chrome-picker-wrapper">
                                                            <div className="chrome-picker-overlay" onClick={ handleCloseChromePicker }/>
                                                                <ChromePicker color={headerbackgroundColor} onChangeComplete={handleChangeHeaderbackgroundColor}/>
                                                            </div> : null }
                                                        </ReactPlaceholder>    
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>   
                                                
                                            <Form.Group as={Col} lg={3} md={6} sm={12}>
                                                <FormLabel>Navigation links</FormLabel>
                                                <InputGroup>
                                                    <div className="chrome-picker-container">
                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                            <Button className="color-box" variant="outline-secondary" disabled style={{ background: navigationlinksColor, borderColor: navigationlinksColor }}></Button>

                                                            <Button variant="outline-secondary" className="color-button" onClick={ handleClickNavigationLink } disabled={isSaving}>Select Color</Button>

                                                            { displayNavigationLinksColorPicker ? <div className="chrome-picker-wrapper">
                                                            <div className="chrome-picker-overlay" onClick={ handleCloseChromePicker }/>
                                                                <ChromePicker color={navigationlinksColor} onChangeComplete={handleChangeNavigationlinksColor}/>
                                                            </div> : null }
                                                        </ReactPlaceholder>    
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>  

                                            <Form.Group as={Col} lg={3} md={6} sm={12}>
                                                <FormLabel>Branding</FormLabel>
                                                <InputGroup>
                                                    <div className="chrome-picker-container">
                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                            <Button className="color-box" variant="outline-secondary" disabled style={{ background: brandingColor, borderColor: brandingColor }}></Button>

                                                            <Button variant="outline-secondary" className="color-button" onClick={ handleClickBranding } disabled={isSaving}>Select Color</Button>

                                                            { displayBrandingColorPicker ? <div className="chrome-picker-wrapper">
                                                            <div className="chrome-picker-overlay" onClick={ handleCloseChromePicker }/>
                                                                <ChromePicker color={brandingColor} onChangeComplete={handleChangeBrandingColor}/>
                                                            </div> : null }
                                                        </ReactPlaceholder>    
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>

                                        <Row>
                                            <Form.Group as={Col} lg={3} md={6} sm={12}>
                                                <FormLabel>Text</FormLabel>
                                                <InputGroup>
                                                    <div className="chrome-picker-container">
                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                            <Button className="color-box" variant="outline-secondary" disabled style={{ background: textColor, borderColor: textColor }}></Button>

                                                            <Button variant="outline-secondary" className="color-button" onClick={ handleClickText } disabled={isSaving}>Select Color</Button>

                                                            { displayTextColorPicker ? <div className="chrome-picker-wrapper">
                                                            <div className="chrome-picker-overlay" onClick={ handleCloseChromePicker }/>
                                                                <ChromePicker color={textColor} onChangeComplete={handleChangeTextColor}/>
                                                            </div> : null }
                                                        </ReactPlaceholder>    
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>

                                            <Form.Group as={Col} lg={3} md={6} sm={12}>
                                                <FormLabel>Text links</FormLabel>
                                                <InputGroup>
                                                    <div className="chrome-picker-container">
                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>    
                                                            <Button className="color-box" variant="outline-secondary" disabled style={{ background: textlinksColor, borderColor: textlinksColor }}></Button>

                                                            <Button variant="outline-secondary" className="color-button" onClick={ handleClickTextLinks } disabled={isSaving}>Select Color</Button>

                                                            { displayTextLinksColorPicker ? <div className="chrome-picker-wrapper">
                                                            <div className="chrome-picker-overlay" onClick={ handleCloseChromePicker }/>
                                                                <ChromePicker color={textlinksColor} onChangeComplete={handleChangeTextlinksColor}/>
                                                            </div> : null }
                                                        </ReactPlaceholder>    
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>

                                        <Row>
                                            <Form.Group as={Col} lg={3} md={6} sm={12}>
                                                <FormLabel>Buttons</FormLabel>
                                                <InputGroup>
                                                    <div className="chrome-picker-container">
                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                            <Button className="color-box" variant="outline-secondary" disabled style={{ background: buttonsColor, borderColor: buttonsColor }}></Button>

                                                            <Button variant="outline-secondary" className="color-button" onClick={ handleClickButtons } disabled={isSaving}>Select Color</Button>

                                                            { displayButtonsColorPicker ? <div className="chrome-picker-wrapper">
                                                            <div className="chrome-picker-overlay" onClick={ handleCloseChromePicker }/>
                                                                <ChromePicker color={buttonsColor} onChangeComplete={handleChangeButtonsColor}/>
                                                            </div> : null }
                                                        </ReactPlaceholder>    
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <div className="manage-card mb-4">
                            <div className="logo-upload">
                                <Card>
                                    <Card.Header>
                                        <h2>Logo upload</h2>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={4} md={6} sm={12}>
                                                <h3>Logo preview</h3>
                                                <Card>   
                                                    <Card.Body>
                                                        <Form.Group controlId="siteStyleLogoPreview" className="container-site-style-logo-preview">
                                                            <Form.Label></Form.Label>
                                                            <img src={siteStyleLogoSrc} className="siteStyle-logo" />
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Card>
                                            </Col> 
                                            <Col lg={8} md={6} sm={12}>   
                                                <Form.Group controlId="siteStyleLogoLogo" className="container-site-style-logo-upload">
                                                <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                    <Form.File custom>
                                                        <label htmlFor="filePicker" className="btn-file-picker">
                                                        <FontAwesomeIcon icon={faPlus} size="lg" />
                                                        Upload</label>
                                                        <Form.Control type="file" title="Upload" onChange={(e)=>onImageChange(e)} style={{visibility:"hidden"}} disabled={isSaving}/>
                                                    </Form.File>
                                                </ReactPlaceholder>    
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="web-settings-footer">
                            <Button variant="outline-secondary">Preview</Button>
                            <Button variant="outline-secondary" type="submit" disabled={isSaving}>Save Draft</Button>
                            {renderSpinner(isSaving)}
                            <Button variant="primary" disabled={isSaving}>Submit for Approval</Button>
                        </div>
                    </Col>
                </Row>

                {/* <FormActions {...formActionProps} /> */}
            </Form>
        </>
    );
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.paymentChannelWebApplication.isFetching,
        isSaving: state.paymentChannelWebApplication.isSaving,
        actionResult: state.paymentChannelWebApplication.actionResult,
        webApplication: state.paymentChannelWebApplication.webApplication,
        //webPage: state.paymentChannelWebApplication.webPage,
        cmsActionResult: state.cms.actionResult,
        content: state.cms.content,
        client: state.clients.client
    };
};
export default connect(mapStateToProps)(SiteStyleSection);