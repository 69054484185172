import React from "react";
import { Card } from 'react-bootstrap';

type Props = {
    title?: string,
    toolbar?:
    | React.ReactChild
    | React.ReactChild[];
    children?:
    | React.ReactChild
    | React.ReactChild[];
};

export default ({ title, toolbar, children } :Props) => {

    function renderHeader() {
        if (title != null || toolbar != null) {
            return (
                <Card.Header>
                    <h2>{title}</h2>
                    {toolbar}
                </Card.Header>
                )
        } else {
            return (<></>);
        }
    }

    return (
        <>
            <Card>
                { renderHeader()}
                <Card.Body>
                    {children}
                </Card.Body>
            </Card>
        </>
    );
};
