import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Nav, Row, Form, Button, Tab, Modal } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import Security from './internalRoleTabs/Security';
import InternalUsers from './internalRoleTabs/InternalUsers';
import Clients from './internalRoleTabs/Clients';
import Transactions from './internalRoleTabs/Transactions';
import ExternalUsers from './internalRoleTabs/ExternalUsers';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import { IActionResult, IAppState } from '../../../../redux/storeTypes';
import { Role,ModulePermission } from '../../../../models/Role'
import { UserTypeEnum } from '../../../../models/User'
import { Routes } from "../../../../routes";
import { Redirect } from 'react-router-dom';
import { sendSuccessToastAction, sendErrorToastAction } from '../../../../redux/actions/toast';
import { saveRole, SAVE_ROLE_REQUEST, SAVE_ROLE_SUCCESS, SAVE_ROLE_FAILURE } from '../../../../redux/actions/roles';
import RequiredIcon from '../../../../components/RequiredIcon';
import ReactPlaceholder from 'react-placeholder';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { InternalRoles } from "./internalRoleTabs/InternalRoleHeaders";

export interface IRoleProps {
    isFetching?: boolean;
    isSaving?: boolean;
    modulePermissions?:any; 
    role: Role;
    actionResult?: IActionResult
}

const RoleTabsContainer = ({ isFetching, isSaving, role, modulePermissions, actionResult }: IRoleProps) => {

    let actionToken = "RoleTabsContainer";
    const [redirect, setRedirect] = useState<string>("");
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [showModal, setModal] = useState(false);
    const ref = useRef(null);
    
    const scroll = (scrollOffset: any) => {

        const node: any = ref.current!;
        node.scrollLeft += scrollOffset;

    };
    const handlemodal = () => {
        setRedirect(Routes.NavientRoles.path);
    }

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() !== false) {
            if (role === null) {
                role = new Role();
            }
            role.userTypeEnum = UserTypeEnum.Navient;
            role.name = form.elements.name.value;
            role.description = form.elements.description.value;
            dispatch(saveRole(role, actionToken));
        }

        setValidated(true);
    };

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_ROLE_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_ROLE_SUCCESS) {
                dispatch(sendSuccessToastAction("Role was successfully saved."));
                setRedirect(Routes.NavientRoles.path);
            } else if (actionResult.result === SAVE_ROLE_FAILURE) {
                dispatch(sendErrorToastAction("Role could not be saved."));
            }
        }
    }, [actionResult]);

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <Container fluid className="container-table-search">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Form id='navientRoleSubmit' noValidate validated={validated} onSubmit={handleSubmit} className="table-search">
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12}>
                                            <Form.Group controlId="name">
                                                <Form.Label><RequiredIcon />Role name</Form.Label>
                                                <div className="d-flex flex-column w-100">
                                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                                        <Form.Control required type="text" placeholder="Enter role name" defaultValue={role ? role.name : ''} />
                                                    </ReactPlaceholder>
                                                    <Form.Control.Feedback type="invalid">Please enter a role name.</Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12}>
                                            <Form.Group controlId="description">
                                                <Form.Label><RequiredIcon />Role description</Form.Label>
                                                <div className="d-flex flex-column w-100">
                                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                                        <Form.Control required type="text" placeholder="Enter role description" defaultValue={role ? role.description : ''} />
                                                    </ReactPlaceholder>
                                                    <Form.Control.Feedback type="invalid">Please enter a role description.</Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Tab.Container id="navientRoles" defaultActiveKey="navientInternalUsersRole">
                                    <Row>
                                        <Col>

                                          {/* BEGIN: Tabs */}
                                          <div className="tab-nav d-flex px-0">
                                              <Button variant="link" onClick={() => scroll(-300)} className="btn-scroll-left">
                                                <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                                              </Button>
                                              <div className="scrollbarContainer" ref={ref}>
                                                <Nav className="flex-row flex-nowrap">
                                                  {Object.entries(InternalRoles).map(
                                                    ([index, value]: any) => {
                                                      return (
                                                        <Nav.Item>
                                                          <Nav.Link className="roleTabWidth" eventKey={value.key}>{value.title}</Nav.Link>
                                                        </Nav.Item>
                                                      )
                                                    }
                                                  )}
                                                </Nav>
                                              </div>
                                              <Button variant="link" onClick={() => scroll(300)} className="btn-scroll-right">
                                                <FontAwesomeIcon icon={faChevronRight} size="xs" />
                                              </Button>
                                          </div>
                                          {/* END: Tabs */}
 
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="navientInternalUsersRole">
                                                    <InternalUsers />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="navientClientsRole">
                                                    <Clients />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="navientTransactionsRole">
                                                    <Transactions />
                                                </Tab.Pane>
                                                <div className="manage-footer">
                                                    <Form.Group controlId="navientRoleSubmit" >
                                                        <Button form='navientRoleSubmit' type="submit">
                                                            Save
                                                        </Button>
                                                        <Button form='roleCancel' className="roleFooterCancel" variant="link" onClick={handlemodal}>
                                                            Cancel
                                                        </Button>
                                                    </Form.Group>
                                                </div>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>

                <Modal show={showModal}>
                    <Modal.Dialog className="roleCancelDialog">
                        <Modal.Body>
                            <Form.Group controlId="roleCancelTitle">
                                <Form.Label>Cancel adding role</Form.Label>
                            </Form.Group>
                            <Form.Group controlId="roleCancelMessage">
                                <Form.Label>Are you sure you want to cancel adding this new role?</Form.Label>
                            </Form.Group>
                            <Form.Group className="roleDialogButtons">
                                <Button variant="outline-secondary" onClick={() => setModal(false)}>
                                    Cancel
                                </Button>
                                <Button className="roleCancelConfirm"
                                    onClick={() => { setRedirect(Routes.NavientRoles.path) }}
                                >
                                    Confirm
                                </Button>
                            </Form.Group>

                        </Modal.Body>
                    </Modal.Dialog>
                </Modal>
            </>
        )
    }
}


const mapStateToProps = (state: IAppState) => {
    return {
        modulePermissions: state.roles.modulePermissions,
        role: state.roles.role,
        isFetching: state.roles.isFetching,
        isSaving: state.roles.isSaving,
        actionResult: state.roles.actionResult
    };
};

export default connect(mapStateToProps)(RoleTabsContainer);