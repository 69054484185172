import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
//import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Dropdown, Spinner } from 'react-bootstrap';
import moment from 'moment';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ClientName, DepartmentName } from '../models/Client';
import { TransactionDetails } from '../models/Payment';

interface ITableProps {
    //remote?: boolean;
    keyField: any;
    data: any;
    columns: any;
    expandRow?: any;
    onTableChange?: any;
    selectRow?: any;
    rowStyle?:any;
    defaultSorted?:any;
    sizePerPage?: number;
    page?: number, 
    totalSize?: number,
    remote?: boolean,
}

const Table = ({ keyField, data, columns,  expandRow, selectRow, onTableChange, rowStyle, defaultSorted, sizePerPage, page, totalSize, remote }: ITableProps) => {
    if (!data) {
        data = [];
    }

    var options:any = {
        page: page?page:1,
        totalSize: totalSize?totalSize:data.length, 
        sizePerPage: sizePerPage?sizePerPage:10, 
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        hideSizePerPage: false,
        firstPageText: '\u27E8\u27E8', // First
        prePageText: '\u27E8', // Previous
        nextPageText: '\u27E9', // Next
        lastPageText: '\u27E9\u27E9', // Last
        nextPageTitle: 'Next page',
        prePageTitle: 'Previous page',
        firstPageTitle: 'First page',
        lastPageTitle: 'Last page',
        showTotal: true,
        sizePerPageList: [ {text: '5', value: 5}, {text: '10', value: 10}, {text: '25', value: 25}, {text: '50', value: 50} ]
        // hidePageListOnlyOnePage: true, // TODO: This is not working
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
      }:any) => (
        <Dropdown>
        <Dropdown.Toggle variant="outline-secondary">{currSizePerPage}</Dropdown.Toggle>
        <Dropdown.Menu className={"dropdown-menu-sm"}>
          {
            options.map((option: { page: any; text: {} | null | undefined; }, index:number) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <Dropdown.Item
                    key={ index }
                    onClick={ () => onSizePerPageChange(option.page) }
                    className="dropdown-item-sm"
                >
                { option.text }
              </Dropdown.Item>
              );
            })
          }
          </Dropdown.Menu>
        </Dropdown>
      );
    
      const paginationTotalRenderer = (from: number, to: number, size: number) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing { from } to { to } of { size } results
        </span>
    );
    
    options.sizePerPageRenderer = sizePerPageRenderer;
    options.paginationTotalRenderer = paginationTotalRenderer;

    return (
        <PerfectScrollbar>
            <div style={{paddingBottom:"25px",display:"block", minHeight:"255px"}}>
            <BootstrapTable
                headerClasses=""
                remote={remote || (remote == undefined && page ? true : false)}
                keyField={keyField}
                bordered={true}
                expandRow={expandRow}
                data={data}
                selectRow={selectRow}
                columns={columns}
                onTableChange={onTableChange}
                pagination={paginationFactory(options)}
                rowStyle={rowStyle?rowStyle:undefined}
                //cellEdit={cellEditFactory({ mode: 'click' })}
                defaultSorted={defaultSorted?defaultSorted:undefined}
                // wrapperClasses="table-responsive"
            />
            </div>
        </PerfectScrollbar>
    )
}


export default Table