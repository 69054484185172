import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PageSectionContainer from "../../components/layout/PageSectionContainer";
import { paymentsFormSchema } from './components/AdvancedSearchColumns';
import Report from './components/Report'


const DepositsReport = () => {
    const dispatch = useDispatch();
    const reportTitle = 'Deposits Report'
    const reportType = 'Deposits'
    const transactionType = 0;

    useEffect(() => {

    }, []);

    return (
        <>
        <PageSectionContainer title={`Deposits report under construction.`} ></PageSectionContainer>
        {/*
           <Report
                reportType={reportType}
                reportTitle={reportTitle}
                transactionType={transactionType}
                formSchema={paymentsFormSchema}/>
        */}
        </>
    );
};

export default DepositsReport;
