import axios from 'axios';
import { Dispatch } from 'react';
import _ from 'lodash';

export const GET_NOTIFICATION_TYPES_REQUEST = 'GET_NOTIFICATION_TYPES_REQUEST';
export const GET_NOTIFICATION_TYPES_SUCCESS = "GET_NOTIFICATION_TYPES_SUCCESS";
export const GET_NOTIFICATION_TYPES_FAILURE = 'GET_NOTIFICATION_TYPES_FAILURE';

export const GET_PAYMENT_CHANNEL_NOTIFICATIONS_REQUEST = 'GET_PAYMENT_CHANNEL_NOTIFICATIONS_REQUEST';
export const GET_PAYMENT_CHANNEL_NOTIFICATIONS_SUCCESS = 'GET_PAYMENT_CHANNEL_NOTIFICATIONS_SUCCESS';
export const GET_PAYMENT_CHANNEL_NOTIFICATIONS_FAILURE = 'GET_PAYMENT_CHANNEL_NOTIFICATIONS_FAILURE';

export const SAVE_USER_NOTIFICATION_SETTINGS_REQUEST = 'SAVE_USER_NOTIFICATION_SETTINGS_REQUEST';
export const SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS = "SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS";
export const SAVE_USER_NOTIFICATION_SETTINGS_FAILURE = 'SAVE_USER_NOTIFICATION_SETTINGS_FAILURE';

export const DELETE_NOTIFICATION_SETTINGS_REQUEST = 'DELETE_NOTIFICATION_SETTINGS_REQUEST';
export const DELETE_NOTIFICATION_SETTINGS_SUCCESS = 'DELETE_NOTIFICATION_SETTINGS_SUCCESS'; 
export const DELETE_NOTIFICATION_SETTINGS_FAILURE = 'DELETE_NOTIFICATION_SETTINGS_FAILURE';

export const DELETE_USER_NOTIFICATION_REQUEST = 'DELETE_USER_NOTIFICATION_REQUEST';
export const DELETE_USER_NOTIFICATION_SUCCESS = 'DELETE_USER_NOTIFICATION_SUCCESS'; 
export const DELETE_USER_NOTIFICATION_FAILURE = 'DELETE_USER_NOTIFICATION_FAILURE';

export const CREATE_NOTIFICATION_REQUEST = "CREATE_NOTIFICATION_REQUEST";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAILURE = "CREATE_NOTIFICATION_FAILURE";

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS'

export const getNotificationTypesAction = (actionToken:string, msbId?: string) => (dispatch:Dispatch<any>) => {    
    dispatch({
        type: GET_NOTIFICATION_TYPES_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url =  "/MSB_Notification/api/v1/EmailNotification";

    axios.get(_url)
        .then(function (response) {
            let notificationTypes  = response.data;
            dispatch({
                type: GET_NOTIFICATION_TYPES_SUCCESS,
                payload: {
                    notificationTypes,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_NOTIFICATION_TYPES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getPaymentChannelNotificationsAction = (actionToken:string) => (dispatch:Dispatch<any>) => {    
    dispatch({
        type: GET_PAYMENT_CHANNEL_NOTIFICATIONS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Notification/api/v1/EmailNotificationPaymentChannels";

    axios.get(_url)
        .then(function (response) {
            let paymentChannelNotifications  = response.data;
                dispatch({
                type: GET_PAYMENT_CHANNEL_NOTIFICATIONS_SUCCESS,
                payload: {
                    paymentChannelNotifications,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_PAYMENT_CHANNEL_NOTIFICATIONS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const savePaymentChannelNotificationSequentially = (arrayObject?: any, bulkAddRelationships?:any, deletePaymentChannelNotifications?: any, actionToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_USER_NOTIFICATION_SETTINGS_REQUEST,
        payload: { actionToken }
    });

    const _url = "/MSB_Notification/api/v1/EmailNotificationPaymentChannels";

    for (const data of arrayObject) {
        try {
            const response = await axios.post(_url, data);
        } catch (error: any) {
            console.error('Error:', error.response ? error.response.data : error.message);
        }
    }

    for (const [guid, entries] of Object.entries(bulkAddRelationships) as any) {
        const url = `/MSB_Notification/api/v1/EmailNotification/${guid}/bulkAddRecipientRelationships?shouldDelete=false`;
        const body = entries[0];
        try {
            const response = await axios.post(url, [body]);
        } catch (error:any) {
            console.error(`Failed for ${guid}:`, error.message);
        }
    }

    const transformedObject = _.mapValues(deletePaymentChannelNotifications, value => [value]);
    for (const [guid, entries] of Object.entries(transformedObject) as any) {
        const url = `/MSB_Notification/api/v1/EmailNotification/${guid}/bulkAddRecipientRelationships?shouldDelete=true`;
        const body = entries[0];
        try {
            const response = await axios.post(url, [body]);
        } catch (error:any) {
            console.error(`Failed for ${guid}:`, error.message);
        }
    }

    dispatch({
        type: SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS,
        payload: { actionToken }
    });
};

export const setNotifications = (notifications: any, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_NOTIFICATIONS,
        payload: {
            paymentChannelNotifications: notifications,
            actionToken
        }
    });
};

export const deleteNotifications = (notifications: any, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_NOTIFICATIONS,
        payload: {
            deletePaymentChannelNotifications: notifications,
            actionToken
        }
    });
};

export const clearNotifications = (actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_NOTIFICATIONS,
        payload: {
            actionToken
        }
    });
};