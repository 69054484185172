import { Dispatch } from 'react';

export const POST_CLIENT_REQUEST = 'POST_CLIENT_REQUEST';
export const POST_CLIENT_SUCCESS = 'POST_CLIENT_SUCCESS';
export const POST_CLIENT_FAILURE = 'POST_CLIENT_FAILURE';

export const PREVIOUS_STEP = 'CLIENT_ONBOARDING_PREVIOUS_STEP';
export const NEXT_STEP = 'CLIENT_ONBOARDING_NEXT_STEP';
export const CANCEL_STEP = 'CLIENT_ONBOARDING_CANCEL_STEP';
export const DEPARTMENT_CANCEL_STEP = 'DEPARTMENT_CANCEL_STEP';

export const CLIENT_DETAILS_STEP = 'CLIENT_DETAILS_STEP';
export const CLIENT_ADDRESS_STEP = 'CLIENT_ADDRESS_STEP';
export const CLIENT_CONTACT_STEP = 'CLIENT_CONTACT_STEP';
export const DEPARTMENT_DETAILS_STEP = 'DEPARTMENT_DETAILS_STEP';
export const DEPARTMENT_ADDRESS_STEP = 'DEPARTMENT_ADDRESS_STEP';
export const DEPARTMENT_CONTACT_STEP = 'DEPARTMENT_CONTACT_STEP';
export const DEPARTMENT_SETTLEMENT_STEP = 'DEPARTMENT_SETTLEMENT_STEP';
export const DEPARTMENT_ACCOUNTING_STEP = 'DEPARTMENT_ACCOUNTING_STEP';
export const MERCHANT_ID_STEP = 'MERCHANT_ID_STEP';
export const CHECKLIST_STEP = 'CHECKLIST_STEP';
export const COMPLETE_STEP = 'COMPLETE_STEP';
export const SET_STEP = 'SET_STEP';

export const ONBOARDING_STEPS = [CLIENT_DETAILS_STEP, CLIENT_ADDRESS_STEP,CLIENT_CONTACT_STEP,DEPARTMENT_DETAILS_STEP,DEPARTMENT_ADDRESS_STEP,DEPARTMENT_CONTACT_STEP,DEPARTMENT_SETTLEMENT_STEP,DEPARTMENT_ACCOUNTING_STEP,MERCHANT_ID_STEP,COMPLETE_STEP]
export const DEPT_ONBOARDING_STEPS = [DEPARTMENT_DETAILS_STEP, DEPARTMENT_ADDRESS_STEP, DEPARTMENT_CONTACT_STEP,DEPARTMENT_SETTLEMENT_STEP, DEPARTMENT_ACCOUNTING_STEP, MERCHANT_ID_STEP, COMPLETE_STEP];

export enum OnboardingTypeEnum {
    Client = 1,
    Department = 2,
}


export const setStepAction = (step:string, onboardingTypeEnum:OnboardingTypeEnum) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_STEP,
        payload: {step, onboardingTypeEnum}
    });
};

export const nextStepAction = (onboardingTypeEnum:OnboardingTypeEnum) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: NEXT_STEP,
        payload: {onboardingTypeEnum}
    });
};

export const previousStepAction = (onboardingTypeEnum:OnboardingTypeEnum) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: PREVIOUS_STEP,
        payload: {onboardingTypeEnum}
    });
};

export const cancelAction = () => (dispatch: Dispatch<any>) => {    
    dispatch({
        type: CANCEL_STEP,
    });
};

export const cancelDeptAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DEPARTMENT_CANCEL_STEP,
    });
};