import { CellPlugin } from '@react-page/editor';
import InvoicePayment, { ITEMS_HEADER_LABEL, ITEM_REFERENCE_LABEL, ITEM_NAME_LABEL, ITEM_AMOUNT_LABEL, FULL_STORY_MASK, ALLOW_LESSER_AMOUNT, ALLOW_GREATER_AMOUNT, MINIMUM_AMOUNT } from './components/InvoicePayment';

type Data = {
    title: string,
    itemsHeaderLabel: string,
    itemReference: string,
    itemName: string,
    itemAmount: string,
    showItemName: boolean,
    fullStoryMask: boolean,
    allowLesserAmount: boolean,
    allowGreaterAmount: boolean,
    minimumAmount: string,
}

const InvoicePaymentPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <InvoicePayment
            itemsHeaderLabel={data.itemsHeaderLabel}
            itemReferenceLabel={data.itemReference}
            itemNameLabel={data.itemName}
            itemAmountLabel={data.itemAmount}
            showItemNameOption={data.showItemName}
            fullStoryMask={data.fullStoryMask}
            allowLesserAmount={data.allowLesserAmount}
            allowGreaterAmount={data.allowGreaterAmount}
            minimumAmount={data.minimumAmount}
        />
    ),
    id: 'invoicePaymentPlugin',
    title: 'Invoice Payment',
    description: 'Collects the invoice for payment.',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        itemsHeaderLabel: {
                            type: 'string',
                            default: ITEMS_HEADER_LABEL,
                        },
                        itemReference: {
                            type: 'string',
                            default: ITEM_REFERENCE_LABEL,
                        },
                        itemName: {
                            type: 'string',
                            default: ITEM_NAME_LABEL,
                        },
                        itemAmount: {
                            type: 'string',
                            default: ITEM_AMOUNT_LABEL,
                        },
                        showItemName: {
                            type: 'boolean',
                            default: false,
                        },
                        fullStoryMask:{
                            type: 'boolean',
                            default: FULL_STORY_MASK
                        },
                        allowLesserAmount: {
                            type: 'boolean',
                            default: ALLOW_LESSER_AMOUNT
                        },
                        allowGreaterAmount: {
                            type: 'boolean',
                            default: ALLOW_GREATER_AMOUNT
                        },
                        minimumAmount: {
                            type: 'string',
                            default: MINIMUM_AMOUNT
                        }
                    },
                    required: ['itemReference', 'itemAmount'],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                    },
                    required: [],
                },
            },
        },
    ],
};

export default InvoicePaymentPlugin;