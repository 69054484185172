import React from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import TransactionDetails from './TransactionDetails';
import RelatedTransaction from './RelatedTransaction';
import AdditionalDetails from './AdditionalDetails';
import Summary from './Summary';
import PageSectionContainer from "../../../components/layout/PageSectionContainer";
import { TransactionDetails as TransactionDetailsModel } from '../../../models/Payment';
import { ClientName, DepartmentName, PaymentChannelName } from "../../../models/Client";
import { refundPaymentRules } from "../RefundPayment";
import { chargebackReversalRules } from "../ChargebackReversals";

interface IViewDetails {
    details: TransactionDetailsModel;
    transactionType: string;
    clientNames: Array<ClientName>;
    departmentNames: Array<DepartmentName>;
    paymentChannelNames: Array<PaymentChannelName>;
}

const ViewDetails = ({ details, transactionType, clientNames, departmentNames, paymentChannelNames }: IViewDetails) => {

    return (
        <>
            <Container fluid className="container-payment-details">
                <Row>
                    <Col xl={8} lg={8} md={12} sm={12}>
                        <Row>
                            <Col>
                                <PageSectionContainer>
                                    <TransactionDetails details={details} transactionType={transactionType} clientNames={clientNames} departmentNames={departmentNames} paymentChannelNames={paymentChannelNames}/>
                                </PageSectionContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PageSectionContainer>
                                    <RelatedTransaction details={details} transactionType={transactionType} clientNames={clientNames} departmentNames={departmentNames} paymentChannelNames={paymentChannelNames}/>
                                </PageSectionContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PageSectionContainer>
                                    <AdditionalDetails details={details} action={transactionType} />
                                </PageSectionContainer>
                            </Col>
                        </Row>
                    </Col>
                    {refundPaymentRules(details,transactionType) && chargebackReversalRules(details,transactionType) &&
                        <Col lg={{ span: 4, order: 'last' }} md={{ span: 12, order: 'first' }} xs={{ span: 12, order: 'first' }} className="d-print-none">
                            <PageSectionContainer>
                                <Summary details={details} action={transactionType} />
                            </PageSectionContainer>
                        </Col>
                    }
                </Row>
            </Container>

        </>
    );
};

export default ViewDetails;
