import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-regular-svg-icons';
interface RequiredIconProps {
    visible?:boolean
}

const RequiredIcon = ({ visible }:RequiredIconProps) => {
    if (visible === undefined) {
        visible = true;
    }
    if (visible) {
        return (
            <FontAwesomeIcon icon={faAsterisk} style={{ color: "#F5222D", marginRight: "4px", marginTop: "-0.125rem", width: "0.5rem" }} size="sm"  />
        );
    } else {
        return (
            <></>
        )
    }
};

export default RequiredIcon;