import moment from "moment";
import { useState } from "react";
import { Form, Button, Table, ListGroup } from "react-bootstrap"
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import gilaSettings from "../../../../../../redux/reducers/gilaSettings";
import { DateOnlyFormatter } from '../../../../../../components/Formatters';
import { TransactionDetails } from "../../../../../../models/Payment";

export interface IPaymentReference {
    paymentSummaryLabel: string,
    subtotalLabel: string,
    convenienceFeeLabel: string,
    totalAmountLabel: string,
    requireConvenienceFeeAcknowledgment: boolean,
    convenienceFeeAcknowledgmentLabel: string,
    termsLabel: string,
    termsUrl: string,
    submitButtonLabel: string,
    cancelButtonLabel: string,
    submitButtonBackgroundColor: string,
    submitButtonFontColor: string,
    submitButtonFontSize: string,
    submitButtonFontStyle: string,
    submitButtonFontWeight: string
}

export const PAYMENT_SUMMARY_LABEL = 'Payment Summary';
export const SUBTOTAL_LABEL = 'Subtotal';
export const CONVENIENCE_FEE_LABEL = 'Convenience fee';
export const TOTAL_AMOUNT_LABEL = 'Total amount';
export const CONVENIENCE_FEE_ACKNOWLEDGMENT_LABEL = 'I agree to accept the Convenience Fee charges';
export const TERMS_URL = 'https://payments.msbpay.navient.com/Payment_Terms_and_Conditions.pdf';
export const TERMS_LABEL = 'I agree to the Terms and Conditions';
export const SUBMIT_BUTTON_LABEL = 'Submit payment';
export const CANCEL_BUTTON_LABEL = 'Cancel payment';
export const SUBMIT_BUTTON_FONT_COLOR = '#FFFFFF';
export const SUBMIT_BUTTON_FONT_SIZE = '.875rem';
export const SUBMIT_BUTTON_FONT_STYLE = 'normal';
export const SUBMIT_BUTTON_FONT_WEIGHT = 'normal';
export const SUBMIT_BUTTON_BACKGROUND_COLOR = '#0057b6';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const checkStyleForDefault = (style:string, defaultStyle:string) => {
    return style && style !== ''?style:defaultStyle;
}
  
const checkStyleForDefault2 = (style:number|undefined, defaultStyle:number) => {
    return style?style:defaultStyle;
}
  
const checkStyleForDefault3 = (defaultStyle:string) => {
    return defaultStyle as any;
}

const PaymentSummary = ({paymentSummaryLabel, subtotalLabel, convenienceFeeLabel, totalAmountLabel, requireConvenienceFeeAcknowledgment, convenienceFeeAcknowledgmentLabel, termsUrl, termsLabel, submitButtonLabel, cancelButtonLabel,
    submitButtonBackgroundColor, submitButtonFontColor, submitButtonFontSize, submitButtonFontStyle, submitButtonFontWeight
}: IPaymentReference) => {
    let actionToken = "PaymentSummary";
    const dispatch = useDispatch();
    const [validated, setValidated] = useState<boolean>(false);
    const [subtotalAmount, setSubtotalAmount] = useState<number>(0.00);
    const [convenienceFeeAmount, setConvenienceFeeAmount] = useState<number>(0.00);
    const [totalAmount, setTotalAmount] = useState<number>(0.00);

    if (!paymentSummaryLabel) {
        paymentSummaryLabel = PAYMENT_SUMMARY_LABEL;
    }

    if (!subtotalLabel) {
        subtotalLabel = SUBTOTAL_LABEL;
    }

    if (!convenienceFeeLabel) {
        convenienceFeeLabel = CONVENIENCE_FEE_LABEL;
    }

    if (!totalAmountLabel) {
        totalAmountLabel = TOTAL_AMOUNT_LABEL;
    }

    if (!convenienceFeeAcknowledgmentLabel) {
        convenienceFeeAcknowledgmentLabel = CONVENIENCE_FEE_ACKNOWLEDGMENT_LABEL;
    }

    if (!termsLabel) {
        termsLabel = TERMS_LABEL;
    }

    if (!termsUrl) {
        termsUrl = TERMS_URL;
    }

    if (!submitButtonLabel) {
        submitButtonLabel = SUBMIT_BUTTON_LABEL;
    }

    if (!cancelButtonLabel) {
        cancelButtonLabel = CANCEL_BUTTON_LABEL;
    }

    if (!submitButtonBackgroundColor) {
        submitButtonBackgroundColor = SUBMIT_BUTTON_BACKGROUND_COLOR;
    }

    if (!submitButtonFontColor) {
        submitButtonFontColor = SUBMIT_BUTTON_FONT_COLOR;
    }

    if (!submitButtonFontSize) {
        submitButtonFontSize = SUBMIT_BUTTON_FONT_SIZE;
    }

    if (!submitButtonFontStyle) {
        submitButtonFontStyle = SUBMIT_BUTTON_FONT_STYLE;
    }

    if (!submitButtonFontWeight) {
        submitButtonFontWeight = SUBMIT_BUTTON_FONT_WEIGHT;
    }

    return (
        <>
        <div className="container-payment-details">
            <div className="container-transaction-summary">
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        {paymentSummaryLabel != ''?<h2>{paymentSummaryLabel}</h2>:<></>}
                        <div className="summaryDetail">
                            <h3>{subtotalLabel}</h3>
                            <span>{formatter.format(subtotalAmount)}</span>
                        </div>
                        <div className="summaryDetail">
                            <h3>{convenienceFeeLabel}</h3>
                            <span>{formatter.format(convenienceFeeAmount)}</span>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div className="summaryDetail">
                            <h3>{totalAmountLabel}</h3>
                            <span className="fw-bold">{formatter.format(totalAmount)}</span>
                        </div>
                    </ListGroup.Item>

                </ListGroup>
            </div>            
        </div>
        <div style={{ marginTop: "48px", marginBottom: "48px" }}>
            {requireConvenienceFeeAcknowledgment?
                <Form.Check style={{ marginBottom: "10px" }} label={convenienceFeeAcknowledgmentLabel} />
                :<></>
            }
            <Form.Check className="mb-0" label={<a href={termsUrl} target="_blank">{termsLabel}</a>} />

            <div className="form-check" style={{ marginTop: "10px" }}>
              <input type="checkbox" className="form-check-input" id="ACHTransactionsAgreement" />
              <label className="form-check-label" htmlFor="ACHTransactionsAgreement">
                By checking here, I authorize Municipal Services Bureau to initiate a single ACH electronic debit to my account ending in lastFourOnCard in the amount of {formatter.format(totalAmount)} on transactionDate. I agree that ACH transactions I authorize comply with all applicable laws. Once payment is authorized, there cannot be any changes or corrections. I understand that I may call during normal business hours if I have any questions. It is recommended that you <a onClick={() => window.print()} href="#">print</a> a copy of this authorization and maintain it for your records.
              </label>
            </div>
        </div>
        <Button className="btn-primary w-100" type="submit"
             style={{
                backgroundColor:checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                borderColor:checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                color:checkStyleForDefault(submitButtonFontColor, SUBMIT_BUTTON_FONT_COLOR),
                fontSize:checkStyleForDefault(submitButtonFontSize, SUBMIT_BUTTON_FONT_SIZE),
                fontStyle:checkStyleForDefault(submitButtonFontStyle, SUBMIT_BUTTON_FONT_STYLE),
                fontWeight:checkStyleForDefault3(submitButtonFontWeight)
              }}
        >{submitButtonLabel}</Button>
        <Link className="btn btn-link" style={{ marginTop: "8px", display: "block", textAlign: "center" }} to={""}>{cancelButtonLabel}</Link>
      </>
    )
}

export default PaymentSummary;