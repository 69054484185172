import ConstituentsForm from '../forms/ConstituentsForm';
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Col, Row, Form, Button, Dropdown, ButtonToolbar, Modal } from 'react-bootstrap';
import { IAppState } from '../../../../redux/storeTypes';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import { DateFormatter } from '../../../../components/Formatters';
import { IPageableResults } from "../../../../models/PageableResults";
import { Routes } from "../../../../routes";
import { Redirect } from 'react-router-dom';
import { sendWarningToastAction } from '../../../../redux/actions/toast';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { User } from '../../../../models/User';
import FormHeader from '../forms/FormHeader';
import Table from '../../../../components/Table';

interface IManageConstituentProps {
    currentPage: IPageableResults<User>,
    isFetching: boolean,
    user: User
}

const ManageConstituentsSection = ({ user, currentPage, isFetching }: IManageConstituentProps) => {
    const [redirect, setRedirect] = useState<string>("");
    const [showForm, setShowForm] = useState(false);
    const [deleteConstituent, setDeleteConstituent] = useState();
    const [showModal, setModal] = useState(false);
    const dispatch = useDispatch();
    let data: Array<any> = [];
    let page: number = 1;
    let totalRecords: number = 0;

    if (currentPage != null) {
        data = currentPage.data;
        totalRecords = currentPage.totalRecords;
    }

    useEffect(() => {
        //dispatch(getClientConstituentAction(UserTypeEnum.Constituent));        
    }, []); 


    const actionsOperations = (row: any, operation: any) => {
        if (operation === "Delete") {
            setDeleteConstituent(row.id);
            setModal(true)
        }
        /* TODO - Edit Constituent
        else if (operation === "Edit") {
            dispatch(setConstituentDetailsAction(row));
            setRedirect(Routes.EditClientConstituent.path);
        }
        */
    }

    const dropDownOptions = (cell: any, row: any) => {
        return (
            <Dropdown className="req-action-dropdown">
                <Dropdown.Toggle className="approvalAction" variant="link">
                    Manage
          </Dropdown.Toggle>
                <Dropdown.Menu>
                    {/* <Dropdown.Item onSelect={() => actionsOperations(row, "Edit")}>Edit</Dropdown.Item> */}
                    <Dropdown.Item onSelect={() => actionsOperations(row, "Delete")}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        )
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            editable: false,
            sort: false,
            hidden: true
        }, {
            dataField: 'firstName',
            text: 'First name',
            editable: false,
            sort: true
        }, {
            dataField: 'lastName',
            text: 'Last name',
            editable: false,
            sort: true
        }, {
            dataField: 'email',
            text: 'Email address',
            editable: false
        }, {
            dataField: 'updatedDate',
            text: 'Last updated',
            formatter: DateFormatter,
            editable: false
        }, {
            dataField: 'actions',
            text: 'Actions',
            editable: false,
            formatter: dropDownOptions,
        }
    ];

    const deleteConstituentAction = (event: any) => {
        event.preventDefault();
        setModal(false);
        dispatch(sendWarningToastAction("Constituent has been successfully deleted"))
    }

    const SearchFilter = (props: any) => {
        let input: any;
        const handleSubmit = (event: any) => {
            event.preventDefault();
            props.onSearch(input.value);
        };
        const handleReset = () => {
            props.onSearch(input.value = '');
        }
        return (
          <>
            <div className="manage-search">
              <Form onSubmit={handleSubmit} className="table-search">
                <Row>
                  <Col xl={9} lg={12} md={12} sm={12}>
                    <Form.Group>                            
                    <Form.Label>Constituent</Form.Label>
                        <Form.Control ref={(filterValue: any) => input = filterValue} type="text" placeholder="Enter Constituent's name" />
                    </Form.Group>
                  </Col>
                  <Col xl={3} lg={12} md={12} sm={12}>
                    <ButtonToolbar>
                      <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                      <Button onClick={handleSubmit}>Search</Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        );
    };

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <ConstituentsForm showForm={showForm} setShowForm={setShowForm} />

                <Modal show={showModal}>
                  <Modal.Header>
                    <Modal.Title>Delete Constituent</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <Form.Group controlId="deleteClientContituent">
                          <Form.Label>Delete Constituent</Form.Label>
                      </Form.Group>
                      <Form.Group controlId="deleteClientContituent">
                          <Form.Label>Are you sure you want to delete this constituent?</Form.Label>
                      </Form.Group>
                      <Form.Group className="clientConstituentDialogButtons">
                          <Button variant="outline-secondary" onClick={() => setModal(false)}>
                              Cancel
                              </Button>
                          <Button className="clientConstituentDeleteConfirm"
                              onClick={(e) => deleteConstituentAction(e)}
                          >
                              Confirm
                              </Button>
                      </Form.Group>
                  </Modal.Body>
                </Modal>

                <FormHeader title="Client Constituents" description="Below are the client's constituents." />

                <div className="manage-card">
                  <ToolkitProvider keyField="id" data={data} columns={columns} search>
                      {
                          (props: any) => (
                              <PageSectionContainer title="Manage Constituents" >
                                  <>
                                      <SearchFilter {...props.searchProps} />

                                      <Table 
                                        keyField="msbId" 
                                        columns={columns} 
                                        data={totalRecords}
                                        {...props.baseProps} 
                                      />
                                  </>
                              </PageSectionContainer>
                          )
                      }
                  </ToolkitProvider>
                </div>

                <div className="manage-footer">
                  <Button type="button" variant="link" onClick={() => setRedirect(Routes.Clients.path)} style={{ float: "right" }}>Cancel</Button>
                </div>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.users.user,
        currentPage: state.users.currentPage,
        isFetching: state.users.isFetching
    };
};

export default connect(mapStateToProps)(ManageConstituentsSection);