import { combineReducers } from 'redux';
import auth from './auth';
import clientOnboarding from './clientOnboarding';
import deptOnboarding from './deptOnboarding';
import document from './document';
import clients from './clients';
import cms from './cms';
import emails from './emails';
import webAppSettings from './config';
import historyApprovals from './historyApprovals';
import users from './users';
import roles from './roles';
import clientRoles from './clientRole'
import paymentChannelWebApplication from './paymentChannelWebApplication';
import pendingApprovals from './pendingApprovals';
import orderManagement from './orderManagement';
import orderTransactions from './orderTransactions';
import itemManagement from './itemManagement';
import paymentTransactions from './paymentTransactions';
import eMafTransactions from './eMafTransactions';
import reports from './reports';
import gilaSettings from './gilaSettings';
import accountSettings from './account';
import toast from './toast';
import tylerEagleIntegration from './tylerEagleIntegration';
import wallets from './wallets'

////Note: Place reducers in alphabetical order!
const rootReducer = combineReducers(
    {
        ////TODO - refactor approvals to approvalsPending and approvalsHistory
        accountSettings,
        auth,
        clientOnboarding,
        clientRoles,
        clients,
        cms,
        emails,
        webAppSettings,
        deptOnboarding,
        document,
        eMafTransactions,
        gilaSettings,
        historyApprovals,
        reports,
        roles,
        users,
        paymentChannelWebApplication,
        paymentTransactions,
        pendingApprovals,
        orderManagement,
        orderTransactions,
        itemManagement,
        tylerEagleIntegration,
        toast,
        wallets
    });

export default rootReducer;
