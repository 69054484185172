import { Row, Col } from "react-bootstrap"
import Table from '../../../../../../components/Table';

interface ILarimerSearchResults {
    citationNumberLabel: string,
    fullNameLabel: string,
    issueDateLabel: string,
    amountDueLabel: string,
    statusLabel: string,
    actionsLabel: string,
    addToCartLabel: string,
    addToCartIconColor: string,
    inCartLabel: string,
    inCartIconColor: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
}

export const CITATION_NUMBER_LABEL = 'Citation number';
export const FULL_NAME_LABEL = 'Full Name';
export const ISSUE_DATE_LABEL = 'Issue Date';
export const AMOUNT_DUE_LABEL = 'Amount';
export const STATUS_LABEL = 'Status';
export const ACTIONS_LABEL = 'Actions';
export const ADD_TO_CART_LABEL = 'Add To Cart';
export const ADD_TO_CART_ICON_COLOR = '#007bff';
export const IN_CART_ICON_COLOR = '#28a745';
export const IN_CART_LABEL = 'In Cart';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const LarimerSearchResults = ({ citationNumberLabel, fullNameLabel, issueDateLabel, amountDueLabel, statusLabel, actionsLabel,
    addToCartLabel, addToCartIconColor, inCartLabel,inCartIconColor,durationOfPendingHours, observesWeekends,
    margin, padding, headerBackgroundColor, headerPadding }: ILarimerSearchResults) => {

    if (!citationNumberLabel) { citationNumberLabel = CITATION_NUMBER_LABEL }
    if (!fullNameLabel) { fullNameLabel = FULL_NAME_LABEL }
    if (!issueDateLabel) { issueDateLabel = ISSUE_DATE_LABEL }
    if (!amountDueLabel) { amountDueLabel = AMOUNT_DUE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!addToCartLabel) { addToCartLabel = ADD_TO_CART_LABEL }
    if (!addToCartIconColor) { addToCartIconColor = ADD_TO_CART_ICON_COLOR }
    if (!inCartLabel) { inCartLabel = IN_CART_LABEL }
    if (!inCartIconColor) { inCartIconColor = IN_CART_ICON_COLOR }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const initialColumns = [{
        dataField: 'citationNumber',
        text: citationNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'fullName',
        text: fullNameLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'issueDate',
        text: issueDateLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true
    }, {
        dataField: 'amountDue',
        text: amountDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,

    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const renderTable = () => {
        return (
            <Table
                keyField="msbId"
                data={[]}
                columns={initialColumns}
            />);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </div>

    )
}

export default LarimerSearchResults;