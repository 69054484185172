import {
    GET_HISTORY_APPROVALS_REQUEST,GET_HISTORY_APPROVALS_SUCCESS,GET_HISTORY_APPROVALS_FAILURE
} from '../actions/historyApprovals';

export default function historyApprovals(state = {
    isFetching: false,
    errorMessage: '',
    currentPage: null
}, { type, payload }) {
    switch (type) {
        case GET_HISTORY_APPROVALS_REQUEST:
            return Object.assign({}, state, {
                currentPage: null,
                isFetching: true
            });
        case GET_HISTORY_APPROVALS_SUCCESS:
            return Object.assign({}, state, {
                currentPage: payload.results,
                isFetching: false
            });
        case GET_HISTORY_APPROVALS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                isFetching: false
            });
            
        default:
            return state;
    }
}


