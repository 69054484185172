import axios from "axios";
import { Address, Client, Contact, MerchantProcessor as Merchant, SubMerchantAcceptedCardTypeEnum } from '../../../models/Client';
import { Dispatch } from "react";

export const ADD_MERCHANT_REQUEST = 'ADD_MERCHANT_REQUEST';
export const ADD_MERCHANT_SUCCESS = 'ADD_MERCHANT_SUCCESS';
export const ADD_MERCHANT_FAILURE = 'ADD_MERCHANT_FAILURE';

export const SAVE_MERCHANT_REQUEST = 'SAVE_MERCHANT_REQUEST';
export const SAVE_MERCHANT_SUCCESS = 'SAVE_MERCHANT_SUCCESS';
export const SAVE_MERCHANT_FAILURE = 'SAVE_MERCHANT_FAILURE';

export const DELETE_MERCHANT_REQUEST = 'DELETE_MERCHANT_REQUEST';
export const DELETE_MERCHANT_SUCCESS = 'DELETE_MERCHANT_SUCCESS';
export const DELETE_MERCHANT_FAILURE = 'DELETE_MERCHANT_FAILURE';

export const SET_STORE_MERCHANT_REQUEST = 'SET_STORE_MERCHANT_REQUEST';
export const SET_STORE_MERCHANT_SUCCESS = 'SET_STORE_MERCHANT_SUCCESS';

export const GET_MERCHANT_REQUEST = 'GET_MERCHANT_REQUEST';
export const GET_MERCHANT_SUCCESS = 'GET_MERCHANT_SUCCESS';
export const GET_MERCHANT_FAILURE = 'GET_MERCHANT_FAILURE';

export const RESET_STORE_MERCHANT_REQUEST = 'RESET_STORE_MERCHANT_REQUEST';
export const RESET_STORE_MERCHANT_SUCCESS = 'RESET_STORE_MERCHANT_SUCCESS';

export const ONBOARD_MERCHANT_REQUEST = 'ONBOARD_MERCHANT_REQUEST';
export const ONBOARD_MERCHANT_SUCCESS = 'ONBOARD_MERCHANT_SUCCESS';
export const ONBOARD_MERCHANT_FAILURE = 'ONBOARD_MERCHANT_FAILURE';

export const TOGGLE_OMNITOKEN_MERCHANT_REQUEST = 'TOGGLE_OMNITOKEN_MERCHANT_REQUEST';
export const TOGGLE_OMNITOKEN_MERCHANT_SUCCESS = 'TOGGLE_OMNITOKEN_MERCHANT_SUCCESS';
export const TOGGLE_OMNITOKEN_MERCHANT_FAILURE = 'TOGGLE_OMNITOKEN_MERCHANT_FAILURE';

export const UPDATE_MERCHANT_REQUEST = 'UPDATE_MERCHANT_REQUEST';
export const UPDATE_MERCHANT_SUCCESS = 'UPDATE_MERCHANT_SUCCESS';
export const UPDATE_MERCHANT_FAILURE = 'UPDATE_MERCHANT_FAILURE';

export const addMerchantAction = (clientMsbId: string, merchant: Merchant, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/MerchantProcessors/addToClient/" + clientMsbId;
    let client = new Client();

    axios.post(_url, merchant)
        .then(function (response) {
            client = response.data;
            dispatch({
                type: ADD_MERCHANT_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: ADD_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveMerchantAction = (merchant: Merchant, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/MerchantProcessors/" + merchant.msbId;

    axios.put(_url, merchant)
        .then(function (response) {
            merchant = response.data;
            dispatch({
                type: SAVE_MERCHANT_SUCCESS,
                payload: {
                    merchant,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const deleteMerchantAction = (merchantMsbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/MerchantProcessors/" + merchantMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_MERCHANT_SUCCESS,
                payload: {
                    merchantMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const getMerchantAction = (msbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url =  "/MSB_Client/api/v1/MerchantProcessors/" + msbId;
    let merchant = new Merchant();

    axios.get(_url)
        .then(function (response) {
            merchant = response.data;
            dispatch({
                type: GET_MERCHANT_SUCCESS,
                payload: {
                    merchant,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const setStoreMerchantAction = (merchant: Merchant, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: SET_STORE_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: SET_STORE_MERCHANT_SUCCESS,
        payload: {
            merchant,
            actionToken
        }
    });
};

export const resetStoreMerchantAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: RESET_STORE_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: RESET_STORE_MERCHANT_SUCCESS,
        payload: {
            actionToken
        }
    });
};

export const onboardMerchantAction = (msbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ONBOARD_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/MerchantOnBoarding/${msbId}/onBoardProcessor`;
    let merchant = new Merchant();

    axios.post(_url)
        .then(function (response) {
            merchant = response.data;
            dispatch({
                type: ONBOARD_MERCHANT_SUCCESS,
                payload: {
                    merchant,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: ONBOARD_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const toggleOmnitokenMerchantAction = (msbId: string, enableOmnitoken: boolean, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: TOGGLE_OMNITOKEN_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/MerchantOnBoarding/${msbId}/toggleOmniTokenEnable?isOmniTokenEnabled=${enableOmnitoken}`;
    let merchant = new Merchant();

    axios.put(_url)
        .then(function (response) {
            merchant = response.data;
            dispatch({
                type: TOGGLE_OMNITOKEN_MERCHANT_SUCCESS,
                payload: {
                    merchant,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: TOGGLE_OMNITOKEN_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const updateMerchantAddressAction = (msbId: string, address: Address, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/MerchantOnBoarding/${msbId}/updateAddress`;
    let merchant = new Merchant();

    axios.put(_url, address)
        .then(function (response) {
            merchant = response.data;
            dispatch({
                type: UPDATE_MERCHANT_SUCCESS,
                payload: {
                    merchant,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: UPDATE_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const updateMerchantContactAction = (msbId: string, contact: Contact, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/MerchantOnBoarding/${msbId}/updateContact`;
    let merchant = new Merchant();

    axios.put(_url, contact)
        .then(function (response) {
            merchant = response.data;
            dispatch({
                type: UPDATE_MERCHANT_SUCCESS,
                payload: {
                    merchant,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: UPDATE_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const updateAcceptedCardTypeAction = (msbId: string, acceptedCardType: Array<SubMerchantAcceptedCardTypeEnum>, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/MerchantOnBoarding/${msbId}/modifyAcceptedCards`;
    let merchant = new Merchant();

    axios.put(_url, acceptedCardType)
        .then(function (response) {
            merchant = response.data;
            dispatch({
                type: UPDATE_MERCHANT_SUCCESS,
                payload: {
                    merchant,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: UPDATE_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const updateMerchantSubaccountAction = (msbId: string, subaccount: any, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/MerchantOnBoarding/${msbId}/modifyExpressSubAccount`;
    let merchant = new Merchant();

    axios.put(_url, subaccount)
        .then(function (response) {
            merchant = response.data;
            dispatch({
                type: UPDATE_MERCHANT_SUCCESS,
                payload: {
                    merchant,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: UPDATE_MERCHANT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};