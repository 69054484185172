import { SortDirection } from "./Client";

export interface IPageableResults<T> {
    page: number;
    totalRecords: number;
    total: number;
    sortBy: string;
    sortDirection: SortDirection;
    data: Array<T>;
}

export class PageableResults<T> implements IPageableResults<T> {
    page: number = 0;
    totalRecords: number = 0;
    total: number = 0;
    sortBy: string= '';
    sortDirection: SortDirection = SortDirection.unknown;
    data: Array<T> = new Array<T>();
}