import React, { useEffect, useState } from 'react';
import { ButtonToolbar, Button, ListGroup, Modal, Container } from 'react-bootstrap';
import { ClientNameFormatter, DepartmentNameFormatter, PaymentChannelFormatter, CurrencyFormatter, DateFormatter, ConvenienceFeeFormatter } from '../../../components/Formatters';
import { TransactionDetails as TransactionDetailsModel, PaymentTypeEnum } from '../../../models/Payment';
import { Client, ClientName, DepartmentName, PaymentChannelName } from '../../../models/Client';
import { transformEmailData } from './TransformEmailData';
import { IActionResult, IAppState } from '../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import { setProcessOrderAction, SET_PROCESSED_ORDER } from '../../../redux/actions/payments/orderManagement';
import { getClientAction } from '../../../redux/actions/clients/clients';
import { User, UserTypeEnum } from '../../../models/User';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../redux/actions/toast';
import { SEND_EMAIL_FAILURE, SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS } from '../../../redux/actions/emails';
import Receipt from '../../receipts/Receipt';

interface ITransactionDetails {
    details: TransactionDetailsModel;
    transactionType: string;
    clientNames: Array<ClientName>;
    departmentNames: Array<DepartmentName>;
    paymentChannelNames: Array<PaymentChannelName>;
    clientDetails?: Client;
    actionResult?: IActionResult,
    emailActionResult?: IActionResult,
    user?: User,
}

const TransactionDetails = ({ details, transactionType, clientNames, departmentNames, paymentChannelNames, clientDetails, user, actionResult, emailActionResult }: ITransactionDetails) => {
    const [showModal, setModal] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === SET_PROCESSED_ORDER && actionResult.token === 'PrintReceipt') {
                setModal(true);
            }
        }
    }, [actionResult]);

    useEffect(() => {
        if (emailActionResult && emailActionResult.result) {
            if (emailActionResult.type === SEND_EMAIL_REQUEST && emailActionResult.token === "EmailReceipt") {
                if (emailActionResult.result === SEND_EMAIL_SUCCESS) {
                    dispatch(sendSuccessToastAction("Email successfully sent."));
                } else if (emailActionResult.result === SEND_EMAIL_FAILURE) {
                    dispatch(sendErrorToastAction("Email failed to send. Reason(s) : " + emailActionResult.message));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailActionResult]);

    const translatePaymentType = (paymentTypeEnum?: PaymentTypeEnum) => {
        if (paymentTypeEnum) {
            switch (paymentTypeEnum) {
                case PaymentTypeEnum.CreditCardManual: {
                    return "Credit Card Manual";
                } case PaymentTypeEnum.CreditCardTerminal: {
                    return "Credit Card Terminal";
                } case PaymentTypeEnum.ECheck: {
                    return "eCheck";
                } case PaymentTypeEnum.PayPal: {
                    return "PayPal";
                }
                default: {
                    return "Unknown";
                }
            }
        }
    }

    const printReceiptData = () => {
        if (user?.userTypeEnum === UserTypeEnum.Navient) {
            dispatch(getClientAction(details?.clientId!, 'PrintReceipt'));
        }
        const processedData = transformEmailData(details, clientDetails);
        dispatch(setProcessOrderAction(processedData, 'PrintReceipt'))
    }

    return (
            <>
                <div className="container-transaction-details">
                    <div className="transactionTitle">
                        <h2>{`${details.orderIdentifier} Details`}</h2>
                        <p>{`Below are the ${transactionType} payment details`}</p>
                    </div>
                    <div className="transactionList">
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <div className="transactionDetail">
                                    <h3>Initiated by</h3>
                                    <span>{details.initiatedBy}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Client name</h3>
                                    {ClientNameFormatter(details.clientId, clientNames)}
                                </div>
                                <div className="transactionDetail">
                                    <h3>Department name</h3>
                                    {DepartmentNameFormatter(details.departmentId, departmentNames)}
                                </div>
                                <div className="transactionDetail">
                                    <h3>Payment channel name</h3>
                                    {PaymentChannelFormatter(details.paymentChannelId, paymentChannelNames)}
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="transactionDetail">
                                    <h3>Subtotal</h3>
                                    <span style={{ maxWidth: "150px" }}>{CurrencyFormatter(details.amount)}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Convenience fee</h3>
                                    <span style={{ maxWidth: "150px" }}>{ConvenienceFeeFormatter(details.convenienceFee,details)}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Total amount</h3>
                                    <span style={{ maxWidth: "150px" }}>{CurrencyFormatter(details.totalAmount)}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Original amount</h3>
                                    <span style={{ maxWidth: "150px" }}>{CurrencyFormatter(details.originalAmount)}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Remaining balance</h3>
                                    <span style={{ maxWidth: "150px" }}>{CurrencyFormatter(details.remainingBalance)}</span>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="transactionDetail">
                                    <h3>Transaction ID</h3>
                                    <span>{details.orderIdentifier}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Transaction date</h3>
                                    <span>{DateFormatter(details.createdAt)}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Transaction type</h3>
                                    <span>{details.transactionType}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Transaction state</h3>
                                    <span>{details.orderStatus}</span>
                                </div>
                                {details.lastFourOnCard && !details.orderPayment?.accountNumberLastFour &&
                                    <div className="transactionDetail">
                                        <h3>Last four digits of credit card</h3>
                                        <span>{details.lastFourOnCard}</span>
                                    </div>
                                }
                                {details.orderPayment?.accountNumberLastFour &&
                                    <div className="transactionDetail">
                                        <h3>Last four digits of bank account</h3>
                                        <span>{details.orderPayment.accountNumberLastFour}</span>
                                    </div>
                                }
                                {details.orderStatus === "Declined" ?
                                    <div className="transactionDetail">
                                        <h3>Declined reason</h3>
                                        <span>{details.processorMessage}</span>
                                    </div>
                                    : <></>
                                }
                                <div className="transactionDetail">
                                    <h3 className="transactionLabel">Item reference</h3>
                                    <span>{(Array.isArray(details.orderLines) && details.orderLines.length > 0) ? details.orderLines[0].itemReferenceNumber : ""}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3 className="transactionLabel">Item name</h3>
                                    <span>{(Array.isArray(details.orderLines) && details.orderLines.length > 0) ? details.orderLines[0].itemName : ""}</span>
                                </div>
                            </ListGroup.Item>

                            {details && details.orderPayment ?
                                <ListGroup.Item>
                                    <div className="transactionDetail">
                                        <h3>Payment date</h3>
                                        <span>{(details.orderSummary) ? DateFormatter(details.orderSummary.createdAt) : ""}</span>
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Payment type</h3>
                                        <span>{(details.orderSummary?.laneId) ? translatePaymentType(PaymentTypeEnum.CreditCardTerminal) : ((details.orderSummary?.paymentMethodOnFileReference) ? ((details.orderSummary?.internalCardType === "ElectronicCheck") ? "Bank Account On File" : "Card On File") : translatePaymentType(details.orderPayment.paymentType))}</span>
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Payment account</h3>
                                        <span>{details.paymentAccount}</span>
                                    </div>
                                    {details.orderPayment.cardLogo &&
                                        <div className="transactionDetail">
                                            <h3>Card type</h3>
                                            <span>{details.orderPayment.cardLogo + ((details.orderSummary?.isDebitCardTransaction) ? " Debit" : " Credit")}</span>
                                        </div>
                                    }
                                    {details.orderSummary?.internalCardType &&
                                        <div className="transactionDetail">
                                            <h3>Fee Assessed Card Type</h3>
                                            <span>{details.orderSummary?.internalCardType.replace(/([a-z])([A-Z])/g, '$1 $2')}</span>
                                        </div>
                                    }
                                </ListGroup.Item>
                                :
                                <></>
                            }

                            <ListGroup.Item>
                                <div className="transactionDetail">
                                    <h3>Customer name</h3>
                                    <span>{details.nameOnCard}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Customer phone number </h3>
                                    <span>{details.phone}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Customer email address </h3>
                                    <span>{details.email}</span>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="transactionDetail">
                                    <h3>Address line 1</h3>
                                    <span>{details.addressLine1}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Address line 2</h3>
                                    <span>{details.addressLine2}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>City</h3>
                                    <span>{details.city}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>State</h3>
                                    <span>{details.state}</span>
                                </div>
                                <div className="transactionDetail">
                                    <h3>Zip code</h3>
                                    <span>{details.zip}</span>
                                </div>
                            </ListGroup.Item>
                            {details.orderStatus != 'Declined' &&
                                <>
                                    {details.orderIdentifier.indexOf("ADN") < 0 &&
                                        <ListGroup.Item className="d-print-none">
                                            <ButtonToolbar style={{ float: "right" }}>
                                                <Button variant="outline-secondary" onClick={printReceiptData}>Print Details</Button>
                                                <Button variant="outline-secondary" onClick={printReceiptData}>Email Details</Button>
                                            </ButtonToolbar>
                                        </ListGroup.Item>

                                    }
                                </>
                            }
                        </ListGroup>
                    </div>
                </div>

                <Modal show={showModal} onHide={() => setModal(false)} size="xl">
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <>
                            <Container fluid>
                                <Receipt printReceipt={true} />
                            </Container>
                        </>
                    </Modal.Body>
                </Modal>
            </>
        );
}

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.auth.currentUser,
        clientDetails: state.clients.client,
        actionResult: state.orderManagement.actionResult,
        emailActionResult: state.emails.actionResult
    };
};

export default connect(mapStateToProps)(TransactionDetails);



