import React from "react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import _ from "lodash";
import moment from "moment";
import { ConvenienceFeeFormatter, CurrencyAmountFormatter } from "../../../components/Formatters";
import { defaultColumns } from "../../../models/Payment";
import { defaultCSVTableHeaders, pdfCalculatedTransactionColumnsList, pdfTransactionTableColumnsList, customizedCSVTableHeaders } from "./ExportTableColumns";

export const PdfExport = (data: Array<any>, fileName: any, clientNames?: any, departmentNames?: any, paymentChannelNames?: any, searchCriteria?: any) => {
    return new Promise(resolve => {
        const doc = new jsPDF({ orientation: 'landscape', compress: true });
        const formattedGroupByDataSet = getNameFormatters(data, clientNames, departmentNames, paymentChannelNames, true);
        const dateRange = dateCalculations(searchCriteria);
        setTimeout(() => {
        {
            Object.entries(formattedGroupByDataSet).map(([key, value]: any) => {
                doc.text(`Payment Transactions: ${value.groupedBy}`, 14, 20)

                autoTable(doc, {
                    columns: pdfTransactionTableColumnsList,
                    body: pdfTransactionsTableRows(value.transactions),
                    startY: 25,
                    theme: 'grid',
                    styles: { cellPadding: 1, fontSize: 8 },
                    rowPageBreak: 'auto',
                    bodyStyles: { valign: 'top' },
                })
                autoTable(doc, {
                    theme: 'plain',
                    styles: { cellPadding: 0.2, fontSize: 8 },
                    rowPageBreak: 'auto',
                    headStyles: { halign: 'center' },
                    columnStyles: {
                        transactionType: { halign: 'center' },
                        totalTransactions: { halign: 'center' },
                        amount: { halign: 'center' },
                        convenienceFee: { halign: 'center' },
                        totalAmount: { halign: 'center' }
                    },
                    body: totalTransactionsCalculation(value.transactions),
                    columns: pdfCalculatedTransactionColumnsList,
                    didParseCell: function (cellData: any) {
                        switch (cellData?.column?.dataKey) {
                            case 'amount': case 'convenienceFee': case 'totalAmount':
                                if (typeof (cellData.cell.raw) == 'number')
                                    cellData.cell.text[0] = CurrencyAmountFormatter.format(cellData.cell.raw)
                                break;
                        }
                    },
                })
                if (!!--formattedGroupByDataSet.length) {
                    doc.addPage();
                }
            })
        }
            doc.save(`${fileName} Report_${dateRange}`);
            resolve('resolved');
        }, 1000)

    });
};

export const CsvExport = (data: Array<any>, fileName: any, clientNames?: any, departmentNames?: any, paymentChannelNames?: any, searchCriteria?: any, groupByCsv?: boolean) => {
    return new Promise(resolve => {
        const formattedData = !groupByCsv ?
            formattedCSVDetails(data, clientNames, departmentNames, paymentChannelNames) :
            getNameFormatters(data, clientNames, departmentNames, paymentChannelNames, true, true).flat(3);

        const reportCmdKeys = _.filter(formattedData, obj => _.some(_.keys(obj), key => _.startsWith(key, "reportClientMetaDataFields") && !_.isEmpty(obj[key]))).length;
        const reportCmdHeaders = reportCmdKeys == 0 ? []: [`Client MetaData Fields`]
        const dateRange = dateCalculations(searchCriteria);
        const options = {
            filename: `${fileName} Report_${dateRange}`,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Payment Transactions',
            useTextFile: false,
            useBom: true,
        };

        const headers = reportCmdHeaders?.length > 0 ? [...defaultCSVTableHeaders,...reportCmdHeaders] : defaultCSVTableHeaders
        const csvExporter = new ExportToCsv(groupByCsv ? options : { ...options, headers: headers });
        setTimeout(() => {
            csvExporter.generateCsv(formattedData);
            resolve('resolved');
        }, 1000)
    });
};


const pdfTransactionsTableRows = (bodyDetails: any) => {
    const rowDetails = bodyDetails.map((details: any) => {
        return {
            'transactionId': details.orderIdentifier,
            'createdAt': moment.utc(details.createdAt).local().format('MM-DD-YYYY h:mm a'),
            'paymentChannelName': details.paymentChannelName,
            'transactionReference': details.orderLines.map((item: any) => { return item.itemReferenceNumber }).join(","),
            'customerName': details.nameOnCard,
            'transactionType': details.transactionType,
            'amount': CurrencyAmountFormatter.format(details.amount),
            'convenienceFee': ConvenienceFeeFormatter(details.convenienceFee,details,true),
            'totalAmount': CurrencyAmountFormatter.format(details.totalAmount),
            'cardType': details?.orderPayment?.cardLogo,
            'feeAssessedCardType': details.orderSummary.internalCardType.replace(/([a-z])([A-Z])/g, '$1 $2'),
            'lastFourOnCard': details.lastFourOnCard,
            'reason': details?.reason || (details?.orderStatus == 'Declined'? details?.processorMessage : '')
        }
    })
    return rowDetails
}

const totalTransactionsCalculation = (transactionDetails: any, isCSV?: boolean): any => {
    const transactionTypeCalculation = _(transactionDetails)
        .groupBy('transactionType')
        .map((details: any, key) => {
            return {
                'transactionType': key,
                'totalTransactions': _.countBy(details, 'transactionType')[key],
                'amount': _.sumBy(details, 'amount'),
                'convenienceFee': _.sumBy(details, function (fees: any) {
                    if (fees?.convenienceFeeIsClientAbsorbed === false || fees?.orderSummary?.convenienceFeeIsClientAbsorbed === false) {
                        return fees.convenienceFee
                    } else {
                        return 0
                    }
                }),
                'totalAmount': _.sumBy(details, 'totalAmount'),
            }
        }).value();

    const multipleTransactionTypes = transactionTypeCalculation.reduce(function (previousValue, currentValue): any {
        return {
            transactionType: 'Net Payments',
            totalTransactions: previousValue.totalTransactions + currentValue.totalTransactions,
            amount: previousValue.amount + currentValue.amount,
            convenienceFee: previousValue.convenienceFee + currentValue.convenienceFee,
            totalAmount: previousValue.totalAmount + currentValue.totalAmount
        }
    })

    const singleTransactionType = transactionTypeCalculation.map((object: any) => { return { ...object, transactionType: 'Net Payments' } });
    const netPaymentsCalculation = transactionTypeCalculation.length > 1 ? [multipleTransactionTypes] : singleTransactionType;
    const calculatedTotal = [...transactionTypeCalculation, ...netPaymentsCalculation];

    const formattedCSVTotals = isCSV && calculatedTotal.map((value: any, index: any) => {
        return [[
            `${index === 0 ? 'Totals' : ''}`, '', '', '',
            value.transactionType,
            value.totalTransactions,
            removeCommas(CurrencyAmountFormatter.format(value.amount)),
            removeCommas(CurrencyAmountFormatter.format(value.convenienceFee)),
            removeCommas(CurrencyAmountFormatter.format(value.totalAmount)), '', '', '', '']]
    })

    return (!isCSV ? calculatedTotal : formattedCSVTotals)
}


const formattedCSVDetails = (data?: any, clientNames?: any, departmentNames?: any, paymentChannelNames?: any) => {

    const getClientDepartmentNames = getNameFormatters(data, clientNames, departmentNames, paymentChannelNames, false)
    const formattedCsv = getClientDepartmentNames.map((details: any) => {
        return {
            clientName: details?.clientName || '',
            departmentName: details?.departmentName || '',
            paymentChannelName: details?.paymentChannelName || '',
            initiatedBy: details?.initiatedBy || '',
            orderIdentifier: details?.orderIdentifier || '',
            lastFourOnCard: details?.lastFourOnCard || '',
            itemReference: details?.orderLines[0]?.itemReferenceNumber || '',
            itemName: details?.orderLines[0]?.itemName || '',
            reason: details?.reason || (details?.orderStatus == 'Declined'? details?.processorMessage : ''),
            createdAt: moment.utc(details?.createdAt).local().format('MM-DD-YYYY h:mm a') || '',
            transactionType: details?.transactionType || '',
            amount: CurrencyAmountFormatter.format(details?.amount) || '',
            convenienceFee: ConvenienceFeeFormatter(details?.convenienceFee,details,true) || '',
            totalAmount: CurrencyAmountFormatter.format(details?.totalAmount) || '',
            originalAmount: CurrencyAmountFormatter.format(details?.originalAmount) || '',
            remainingBalance: CurrencyAmountFormatter.format(details?.remainingBalance) || '',
            nameOnCard: details?.nameOnCard || '',
            cardType: details.orderPayment?.cardLogo || '',
            feeAssessedCardType: details.orderSummary?.internalCardType.replace(/([a-z])([A-Z])/g, '$1 $2') || '',
            phone: details?.phone || '',
            email: details?.email || '',
            addressLine1: details?.addressLine1 || '',
            addressLine2: details?.addressLine2 || '',
            city: details?.city || '',
            state: details?.state || '',
            zip: details?.zip || '',
            reportClientMetaDataFields : _.isEmpty(details?.clientMetadata) ? '' : JSON.stringify(details?.clientMetadata) || ''
        }
    })

    const calculatedCSVColumns = totalTransactionsCalculation(data).map((details: any) => {
        const defaultSet = new defaultColumns();
        return {
            ...defaultSet,
            createdAt: details.transactionType,
            transactionType: details.totalTransactions,
            amount: CurrencyAmountFormatter.format(details.amount),
            convenienceFee: ConvenienceFeeFormatter(details.convenienceFee,details,true),
            totalAmount: CurrencyAmountFormatter.format(details.totalAmount),
            reportClientMetaDataFields: ''
        }
    })
    return [...formattedCsv, ...calculatedCSVColumns]
}


const getNameFormatters = (data: any, clientNames: any, departmentNames: any, paymentChannelNames: any, groupBy: boolean, isCsv?: boolean) => {

    const nameFormatters = data.map((items: any) => {
        const clientName = clientNames.find((cn: any) => cn.msbId === items.clientId);
        const departmentName = departmentNames.find((dn: any) => dn.msbId === items.departmentId);
        const paymentChannelName = paymentChannelNames.find((dn: any) => dn.msbId === items.paymentChannelId);
        return {
            ...items,
            clientName: clientName?.businessName || items.clientId,
            departmentName: departmentName?.name || items.departmentId,
            paymentChannelName: paymentChannelName?.name || items.paymentChannelId
        }
    })

    const groupByFormat =
        _.chain(nameFormatters)
            .groupBy((item) => `${item.clientName} - ${item.departmentName} - ${moment.utc(item.createdAt).local().format('MM-DD-YYYY')}`)
            .map((value: any, key): any => (!isCsv ? { groupedBy: key, transactions: value } :
                [
                    [[[key]]],
                    [[[customizedCSVTableHeaders]]],
                    value.map((transactionDetail: any) => {
                        return [[[
                            removeCommas(transactionDetail?.orderIdentifier) || '',
                            removeCommas(moment.utc(transactionDetail?.createdAt).local().format('MM-DD-YYYY h:mm a')) || '',
                            removeCommas(transactionDetail?.paymentChannelName) || '',
                            transactionDetail.orderLines.map((item: any) => { return item?.itemReferenceNumber }).join(";") || '',
                            removeCommas(transactionDetail?.nameOnCard) || '',
                            removeCommas(transactionDetail?.transactionType) || '',
                            removeCommas(CurrencyAmountFormatter.format(transactionDetail?.amount)) || '',
                            removeCommas(ConvenienceFeeFormatter(transactionDetail.convenienceFee,transactionDetail,true)) || '',
                            removeCommas(CurrencyAmountFormatter.format(transactionDetail?.totalAmount)) || '',
                            removeCommas(transactionDetail?.orderPayment?.cardLogo) || '',
                            removeCommas(transactionDetail?.orderSummary?.internalCardType.replace(/([a-z])([A-Z])/g, '$1 $2')) || '',
                            removeCommas(transactionDetail?.lastFourOnCard) || '',
                            removeCommas(transactionDetail?.reason) || removeCommas(transactionDetail?.orderStatus == 'Declined'? transactionDetail?.processorMessage : '')]]]
                    }),
                    [totalTransactionsCalculation(value, true)]
                ])).value().reverse();

    return groupBy ? groupByFormat : nameFormatters
}


export const dateCalculations = (searchCriteria: any) => {
    return _.toString(_.split(searchCriteria, '<->')
        .map((formattedDates: any) => { return moment.utc(formattedDates).local().format('MM-DD-YYYY') })).replace(/,/g, '_');
}

export const removeCommas = (prop: any) => {
    if (prop)
        return prop?.toString()?.replace(/,/g, '')
}

