import * as React from "react";
import { useEffect, useState } from "react";
import { Form, Col, Row, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faDollarSign, faPercent, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ConvenienceFee, Department, PaymentTypeEnum,} from "../../../../../models/Client";
import FormActions, { IFormActionProps } from "../FormActions";
import { saveConvenienceFeesAction } from "../../../../../redux/actions/clients/departments";
import { useDispatch } from "react-redux";
import CurrencyInput from "../../../../../components/currency/CurrencyInput";
import { sendErrorToastAction } from "../../../../../redux/actions/toast";

export interface IConvenienceFeesFormProps {
    isSaving: boolean,
    isFetching: boolean,
    department: Department,
    formActionProps?: IFormActionProps,
    actionToken: string
}

const ConvenienceFeesForm = ({ isSaving, isFetching, department, formActionProps, actionToken }: IConvenienceFeesFormProps) => {
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const maxValue = 2147483647;
    const [convenienceFees, setConvenienceFees] = useState<Array<ConvenienceFee>>(department.convenienceFees!);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() !== false) {
            dispatch(saveConvenienceFeesAction(convenienceFees, actionToken, department?.msbId));
        } else {
            dispatch(sendErrorToastAction("Convenience fees could not be updated."));
        }
        setValidated(true);
    };

    const getConvenienceFeesForPaymentTypeEnum = (paymentTypeEnum: PaymentTypeEnum) => {
        let _convenienceFees = Array<ConvenienceFee>();

        if (convenienceFees) {
            _convenienceFees = convenienceFees.filter(_ => _.paymentType === paymentTypeEnum);
        }

        if (_convenienceFees.length === 0) {
            let newConvenienceFee = new ConvenienceFee();
            newConvenienceFee.paymentType = paymentTypeEnum;
            newConvenienceFee.minimumAmount = 0;
            newConvenienceFee.maximumAmount = maxValue;
            newConvenienceFee.percentage = 0.0;
            newConvenienceFee.flatFee = 0.0;
            newConvenienceFee.minimumConvenienceFeeAmount = 0.0;
            newConvenienceFee.maximumConvenienceFeeAmount = maxValue;
            _convenienceFees.push(newConvenienceFee);
            setConvenienceFees([...convenienceFees, newConvenienceFee]);
        }

        return _convenienceFees;
    }

    const deleteConvenienceFeeRow = (convenienceFee: ConvenienceFee, paymentTypeEnum: PaymentTypeEnum, index: number) => {
        var _convenienceFees = getConvenienceFeesForPaymentTypeEnum(paymentTypeEnum);
        if (index == 0) {
            _convenienceFees[index + 1].minimumAmount = 0;
        }
        if (index > 0) {
            _convenienceFees[index + 1].minimumAmount = _convenienceFees[index - 1].maximumAmount + 0.01;
        }

        index = convenienceFees.indexOf(convenienceFee);
        convenienceFees.splice(index, 1);
        setConvenienceFees([...convenienceFees]);
    }

    const addConvenienceFeeRow = (convenienceFee: ConvenienceFee, paymentTypeEnum: PaymentTypeEnum, index: number) => {
        var _convenienceFees = getConvenienceFeesForPaymentTypeEnum(paymentTypeEnum);
        _convenienceFees[index].minimumAmount = maxValue + 0.01;
        let minValue = (index > 0) ? _convenienceFees[index - 1].maximumAmount + 0.01 : 0;

        let newConvenienceFee = new ConvenienceFee();
        newConvenienceFee.paymentType = paymentTypeEnum;
        newConvenienceFee.minimumAmount = minValue;
        newConvenienceFee.maximumAmount = maxValue;
        newConvenienceFee.percentage = 0.0;
        newConvenienceFee.flatFee = 0.0;
        newConvenienceFee.minimumConvenienceFeeAmount = 0.0;
        newConvenienceFee.maximumConvenienceFeeAmount = maxValue;

        index = convenienceFees.indexOf(convenienceFee);
        setConvenienceFees([...convenienceFees.slice(0, index), newConvenienceFee, ...convenienceFees.slice(index)]);
    }

    const handleMinAmountOnChange = (value: string|undefined, convenienceFee: ConvenienceFee) => {
        if (value) {
            value = value.replace("$ ", "");
            if (!isNaN(Number(value))) {
                convenienceFee.minimumAmount = Number(value);
                setConvenienceFees([...convenienceFees]);
            }
        }
    }

    const handleMaxAmountOnChange = (value: string | undefined, convenienceFee: ConvenienceFee) => {
        if (value) {
            value = value.replace("$ ","");
            if (!isNaN(Number(value))) {
                convenienceFee.maximumAmount = Number(value);
                var _convenienceFees = getConvenienceFeesForPaymentTypeEnum(convenienceFee.paymentType);
                let index = _convenienceFees.indexOf(convenienceFee);
                if (_convenienceFees.length > index + 1) {
                    _convenienceFees[index + 1].minimumAmount = convenienceFee.maximumAmount + 0.01;
                }
                if (index > 1 && _convenienceFees.length < index + 1) {
                    _convenienceFees[index].minimumAmount = _convenienceFees[index - 1].maximumAmount + 0.01;
                }
                setConvenienceFees([...convenienceFees]);
            }
        }
    }

    const handlePercentageOnChange = (value: string|undefined, convenienceFee: ConvenienceFee) => {
        convenienceFee.percentage = 0;
        if (value) {
            value = value.replace("% ","");
            if (!isNaN(Number(value))) {
                convenienceFee.percentage = Number(value).toFixed(4);
            }
        }
        setConvenienceFees([...convenienceFees]);
    }

    const handleFlatFeeOnChange = (value: string|undefined, convenienceFee: ConvenienceFee) => {
        convenienceFee.flatFee = 0;  
            if (value) {
                value = value.replace("$ ","");
                if (!isNaN(Number(value))) {
                    convenienceFee.flatFee = Number(value);
                }
            }
        setConvenienceFees([...convenienceFees]);
    }

    const handleMinimumFeeAmountOnChange = (value: string|undefined, convenienceFee: ConvenienceFee) => {
        convenienceFee.minimumConvenienceFeeAmount = 0;
        if (value) {
            value = value.replace("$ ","");
            if (!isNaN(Number(value))) {
                convenienceFee.minimumConvenienceFeeAmount = Number(value);
            } 
        }
        setConvenienceFees([...convenienceFees]);
    }

    const handleMaximumFeeAmountOnChange = (value: string|undefined, convenienceFee: ConvenienceFee) => {
        convenienceFee.maximumConvenienceFeeAmount = maxValue;
        if (value) {
            value = value.replace("$ ","");
            if (!isNaN(Number(value))) {
                convenienceFee.maximumConvenienceFeeAmount = Number(value);  
            }
        }
        setConvenienceFees([...convenienceFees]);
    }

    const minimumAmountClassNames = (convenienceFee: ConvenienceFee) => {
        if (convenienceFee.minimumAmount && convenienceFee.minimumAmount >= convenienceFee.maximumAmount) {
            return "form-control is-invalid";
        }
        return "form-control";
    }

    const maximumAmountClassNames = (convenienceFee: ConvenienceFee) => {
        if (convenienceFee.maximumAmount && convenienceFee.maximumAmount <= convenienceFee.minimumAmount) {
            return "form-control is-invalid";
        }
        return "form-control";
    }

    const minimumFeeAmountClassNames = (convenienceFee: ConvenienceFee) => {
        if (convenienceFee.minimumConvenienceFeeAmount && convenienceFee.minimumConvenienceFeeAmount >= convenienceFee.maximumConvenienceFeeAmount) {
            return "form-control is-invalid";
        }
        return "form-control";
    }

    const maximumFeeAmountClassNames = (convenienceFee: ConvenienceFee) => {
        if (convenienceFee.maximumConvenienceFeeAmount && convenienceFee.maximumConvenienceFeeAmount <= convenienceFee.minimumConvenienceFeeAmount) {
            return "form-control is-invalid";
        }
        return "form-control";
    }

    const minimumAmountFeedback = (convenienceFee: ConvenienceFee) => {
        if (!convenienceFee.minimumAmount) {
            return ("Please enter the minimum amount.");
        } else if (convenienceFee.minimumAmount >= convenienceFee.maximumAmount) {
            return ("Minimum amount must be less than the maximum amount");
        } 
        return ("");
    }

    const maximumAmountFeedback = (convenienceFee: ConvenienceFee) => {        
        if (!convenienceFee.maximumAmount) {
            return ("Please enter the maximum amount.");
        } else if (convenienceFee.minimumAmount >= convenienceFee.maximumAmount) {
            return ("Maximum amount must be greater than the minimum amount.");
        } 
        return ("");
    }

    const minimumFeeAmountFeedback = (convenienceFee: ConvenienceFee) => {
        if (!convenienceFee.minimumConvenienceFeeAmount) {
            return ("Please enter the minimum fee amount.");
        } else if (convenienceFee.minimumConvenienceFeeAmount >= convenienceFee.maximumConvenienceFeeAmount) {
            return ("Minimum fee amount must be less than the maximum fee amount");
        } 
        return ("");
    }

    const maximumFeeAmountFeedback = (convenienceFee: ConvenienceFee) => {
        if (!convenienceFee.maximumConvenienceFeeAmount) {
            return ("Please enter the maximum fee amount.");
        } else if (convenienceFee.minimumConvenienceFeeAmount >= convenienceFee.maximumConvenienceFeeAmount) {
            return ("Maximum fee amount must be greater than the minimum fee amount.");
        } 
        return ("");
    }

    const convenienceFeeCard = (paymentTypeEnum: PaymentTypeEnum, convenienceFees: Array<ConvenienceFee>) => (
        <Card.Body>
            {
                convenienceFees.map((convenienceFee, index) => (
                    <Row key={convenienceFee.minimumAmount + index}>
                    <Col>
                        <div className="conv-fees-grid">
                            <Form.Group>
                                <Form.Label>Minimum amount</Form.Label>
                                <CurrencyInput
                                    disabled={true}
                                    required
                                    className={minimumAmountClassNames(convenienceFee)}
                                    id={`minimumAmount_${convenienceFee.paymentType}`}
                                    name={`minimumAmount_${convenienceFee.paymentType}`}
                                    placeholder="$ 0.00"
                                    maxLength={10}
                                    decimalsLimit={2}
                                    prefix="$ "
                                    defaultValue={convenienceFee.minimumAmount}
                                    onValueChange={(value, name) => handleMinAmountOnChange(value, convenienceFee)}
                                />
                                <Form.Control.Feedback type="invalid">{minimumAmountFeedback(convenienceFee)}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Maximum amount</Form.Label>
                                <CurrencyInput
                                    required
                                    className={maximumAmountClassNames(convenienceFee)}
                                    id={`maximumAmount_${convenienceFee.paymentType}`}
                                    name={`maximumAmount_${convenienceFee.paymentType}`}
                                    placeholder="$ 0.00"
                                    maxLength={10}
                                    decimalsLimit={2}
                                    prefix="$ "
                                    defaultValue={convenienceFee.maximumAmount}
                                    onValueChange={(value, name) => handleMaxAmountOnChange(value, convenienceFee)}
                                />
                                <Form.Control.Feedback type="invalid">{maximumAmountFeedback(convenienceFee)}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Percentage</Form.Label>
                                <CurrencyInput
                                    required
                                    className="form-control"
                                    id={`percentage_${convenienceFee.paymentType}`}
                                    name={`percentage_${convenienceFee.paymentType}`}
                                    placeholder="% 0.0000"
                                    maxLength={7}
                                    decimalsLimit={4}
                                    prefix="% "
                                    defaultValue={convenienceFee.percentage}
                                    onValueChange={(value, name) => handlePercentageOnChange(value, convenienceFee)}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Flat fee</Form.Label>
                                <CurrencyInput
                                    required
                                    className="form-control"
                                    id={`signatureThreshold_${convenienceFee.paymentType}`}
                                    name={`signatureThreshold_${convenienceFee.paymentType}`}
                                    placeholder="$ 0.00"
                                    maxLength={10}
                                    decimalsLimit={2}
                                    prefix="$ "
                                    defaultValue={convenienceFee.flatFee}
                                    onValueChange={(value, name) => handleFlatFeeOnChange(value, convenienceFee)}
                                />
                                {/*
                                <Form.Control.Feedback type="invalid">Please enter a flat fee amount</Form.Control.Feedback>
                                */}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Minimum fee amount</Form.Label>
                                <CurrencyInput
                                    required
                                    className={minimumFeeAmountClassNames(convenienceFee)}
                                    id={`minimumFee_${convenienceFee.paymentType}`}
                                    name={`minimumFee_${convenienceFee.paymentType}`}
                                    placeholder="$ 0.00"
                                    maxLength={10}
                                    decimalsLimit={2}
                                    prefix="$ "
                                    defaultValue={convenienceFee.minimumConvenienceFeeAmount}
                                    onValueChange={(value, name) => handleMinimumFeeAmountOnChange(value, convenienceFee)}
                                />
                                <Form.Control.Feedback type="invalid">{minimumFeeAmountFeedback(convenienceFee)}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Maximum fee amount</Form.Label>
                                <CurrencyInput
                                    required
                                    className={maximumFeeAmountClassNames(convenienceFee)}
                                    id={`maximumFee_${convenienceFee.paymentType}`}
                                    name={`maximumFee_${convenienceFee.paymentType}`}
                                    placeholder="$ 0.00"
                                    maxLength={10}
                                    decimalsLimit={2}
                                    prefix="$ "
                                    defaultValue={convenienceFee.maximumConvenienceFeeAmount}
                                    onValueChange={(value, name) => handleMaximumFeeAmountOnChange(value, convenienceFee)}
                                />
                                <Form.Control.Feedback type="invalid">{maximumFeeAmountFeedback(convenienceFee)}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                            <div className="buttons">
                                <Button variant="primary" onClick={(e) => addConvenienceFeeRow(convenienceFee, paymentTypeEnum, index)}>
                                <FontAwesomeIcon icon={faPlus} size="sm" style={{ marginRight: "0"}} />
                                </Button>
                                {index < convenienceFees.length - 1 && (
                                        <Button variant="outline-secondary" onClick={(e) => deleteConvenienceFeeRow(convenienceFee, paymentTypeEnum, index)} style={{ marginLeft: "8px" }}>
                                    <FontAwesomeIcon icon={faTrash} size="sm" />
                                    </Button>
                                )}
                            </div>
                            </Form.Group>                                                   
                        </div>
                    </Col>
                </Row>
            ))
        }        
        </Card.Body>
    );

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit} key={convenienceFees.length}>
                <Card className="cf-card">
                    <Card.Header>AMEX Credit</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.AmericanExpressCredit, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.AmericanExpressCredit))}
                </Card>
                <Card className="cf-card">
                    <Card.Header>Discover Credit</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.DiscoverCredit, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.DiscoverCredit))}
                </Card>
                <Card className="cf-card">
                    <Card.Header>Mastercard Credit</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.MastercardCredit, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.MastercardCredit))}
                </Card>
                <Card className="cf-card">
                    <Card.Header>Visa Credit</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.VisaCredit, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.VisaCredit))}
                </Card>

                <hr />

                <Card className="cf-card">
                    <Card.Header>AMEX Debit</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.AmericanExpressDebit, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.AmericanExpressDebit))}
                </Card>
                <Card className="cf-card">
                    <Card.Header>Discover Debit</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.DiscoverDebit, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.DiscoverDebit))}
                </Card>
                <Card className="cf-card">
                    <Card.Header>Mastercard Debit</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.MastercardDebit, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.MastercardDebit))}
                </Card>
                <Card className="cf-card">
                    <Card.Header>Visa Debit</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.VisaDebit, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.VisaDebit))}
                </Card>

                <hr />

                <Card className="cf-card">
                    <Card.Header>Mastercard Debit PIN</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.MastercardDebitPIN, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.MastercardDebitPIN))}
                </Card>
                <Card className="cf-card">
                    <Card.Header>Visa Debit PIN</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.VisaDebitPIN, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.VisaDebitPIN))}
                </Card>

                <hr />

                <Card className="cf-card mb-0">
                    <Card.Header>eCheck</Card.Header>
                    {convenienceFeeCard(PaymentTypeEnum.eCheck, getConvenienceFeesForPaymentTypeEnum(PaymentTypeEnum.eCheck))}
                </Card>

                <div className="manage-footer">
                <FormActions
                    disabled={isSaving || isFetching}
                    showSpinner={isSaving}
                    {...formActionProps} />
                </div>
            </Form>
        </>
    );
};

export default ConvenienceFeesForm;