import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {Row, Col, Tab, Tabs, Nav, Spinner, Dropdown, ButtonToolbar, Button, NavDropdown, Container } from 'react-bootstrap';
import Table from '../../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../../components/Formatters';
import { IAppState, IActionResult } from '../../../redux/storeTypes';
import { WebApplication, WebPage } from '../../../models/PaymentChannelWebApplication';
import { Client, Department } from '../../../models/Client';
import PageHeader from '../../../components/layout/PageHeader';
import PageSectionContainer from '../../../components/layout/PageSectionContainer';
import { Routes } from "../../../routes";
import { getWebPageAction, resetWebApplicationStoreAction } from '../../../redux/actions/clients/paymentChannelWebApplications';
import { Redirect } from 'react-router-dom';
import SiteSettingsSection from "./components/sections/SiteSettingsSection";
import SiteStyleSection from "./components/sections/SiteStyleSection";
import SiteEditorSection from "./components/sections/SiteEditorSection";
import { getContentAction, saveContentAction} from "../../../redux/actions/cms";
import { Crumb } from "../../../models/Crumb";
import { resetClientsStoreAction } from "../../../redux/actions/clients/clients";
import { resetStoreDepartmentAction } from "../../../redux/actions/clients/departments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignCenter, faCog, faDollarSign, faBrowser, faEye } from "@fortawesome/pro-regular-svg-icons";

export interface IWebPaymentChannelProps {
    isFetching: boolean,
    client: Client,
    department: Department,
    webApplication: WebApplication,
    actionResult: IActionResult,
}

const WebPaymentChannel = ({ isFetching, client, department, webApplication, actionResult}: IWebPaymentChannelProps) => {
    const [redirect, setRedirect] = useState<string>("");
    const dispatch = useDispatch();
    const actionToken = "WebPaymentChannel";

    /*
    React.useEffect(() => {
        if (webApplication.siteSettingsContentId !== null && webApplication.siteSettingsContentId !== undefined) {
            dispatch(getContentAction(webApplication.siteSettingsContentId, actionToken));
        }
    }, []);
    */

    const setActionOperations = () => {
        setRedirect(Routes.Clients.path);
    }

    function doToolbar() {
        return (
            <ButtonToolbar>
                <Button onClick={() => { setActionOperations()}}>
                    View Payment Channel
                </Button>
            </ButtonToolbar>
        )
    }

    const handleSiteSettingClick = () => {
        /*
        if (webApplication.siteSettingsContentId !== null && webApplication.siteSettingsContentId !== undefined) {
            dispatch(getContentAction(webApplication.siteSettingsContentId, actionToken));
        }
        */
    }

    const handleSiteStylesClick = () => {
        if (webApplication.siteStyleContentId !== null && webApplication.siteStyleContentId !== undefined) {
            dispatch(getContentAction(webApplication.siteStyleContentId, actionToken));
        }
    }

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path, resetClientsStoreAction(actionToken)));
        crumbs.push(new Crumb(client.businessName, Routes.Client.path, resetStoreDepartmentAction()));
        crumbs.push(new Crumb(department.name, Routes.DepartmentEdit.path, resetWebApplicationStoreAction(actionToken)));
        crumbs.push(new Crumb(`${department.name} Web Application`, Routes.WebPaymentChannel.path));

        return (
            <>               
                <PageHeader title={`${department.name} Web Application`} crumbs={crumbs} />
                <Container fluid className="web-settings">
                    <PageSectionContainer title="Web Application" toolbar={doToolbar()} >
                        <Tab.Container defaultActiveKey="section_sitesettings">
                            <Row>
                                <Col xl={2} md={12} sm={12}>
                                    <Nav className="flex-column tab-nav-left">
                                        <Nav.Item>
                                            <Nav.Link eventKey="section_sitesettings" onClick={handleSiteSettingClick}>
                                                <FontAwesomeIcon icon={faCog} className="me-2" />Site settings
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="section_sitestyle" onClick={handleSiteStylesClick}>
                                                <FontAwesomeIcon icon={faEye} className="me-2" />Site style
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="section_siteEditor">
                                                <FontAwesomeIcon icon={faBrowser} className="me-2" />Site editor
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <NavDropdown.Divider/>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xl={10} md={12} sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="section_sitesettings" data-lazyload>
                                            <div className="site-settings">
                                                <SiteSettingsSection />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="section_sitestyle" data-lazyload>
                                            <div className="site-style">
                                                <SiteStyleSection />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="section_siteEditor" data-lazyload>
                                            <div className="site-editor">
                                                <SiteEditorSection/>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="section_clientReceipts" data-lazyload>                                                
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </PageSectionContainer>
                </Container>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client,
        department: state.clients.department,
        webApplication: state.paymentChannelWebApplication.webApplication,
        isFetching: state.paymentChannelWebApplication.isFetching,
        actionResult: state.paymentChannelWebApplication.actionResult
    };
};

export default connect(mapStateToProps)(WebPaymentChannel);
