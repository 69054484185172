import axios from 'axios';
import { Content, Revision } from '../../models/CMS';
import { Dispatch } from 'react';
import store from "../store";

export const CLEAR_CMS_CONTENT_REQUEST = 'CLEAR_CMS_CONTENT_REQUEST';

export const GET_CMS_CONTENT_REQUEST = 'GET_CMS_CONTENT_REQUEST';
export const GET_CMS_CONTENT_SUCCESS = 'GET_CMS_CONTENT_SUCCESS';
export const GET_CMS_CONTENT_FAILURE = 'GET_CMS_CONTENT_FAILURE';

export const GET_CMS_REVISION_REQUEST = 'GET_CMS_REVISION_REQUEST';
export const GET_CMS_REVISION_SUCCESS = 'GET_CMS_REVISION_SUCCESS';
export const GET_CMS_REVISION_FAILURE = 'GET_CMS_REVISION_FAILURE';

export const SAVE_CMS_REVISION_REQUEST = 'SAVE_CMS_REVISION_REQUEST';
export const SAVE_CMS_REVISION_SUCCESS = 'SAVE_CMS_REVISION_SUCCESS';
export const SAVE_CMS_REVISION_FAILURE = 'SAVE_CMS_REVISION_FAILURE';

export const SAVE_CMS_CONTENT_REQUEST = 'SAVE_CMS_CONTENT_REQUEST';
export const SAVE_CMS_CONTENT_SUCCESS = 'SAVE_CMS_CONTENT_SUCCESS';
export const SAVE_CMS_CONTENT_FAILURE = 'SAVE_CMS_CONTENT_FAILURE';

export const clearContentAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: CLEAR_CMS_CONTENT_REQUEST,
        payload: {
            actionToken
        }
    });
}

export const getContentAction = (msbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_CMS_CONTENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_CMS/api/v1/Contents/" + msbId;

    axios.get(_url)
        .then(function (response) {
            let content = response.data;
            dispatch({
                type: GET_CMS_CONTENT_SUCCESS,
                payload: {
                    content,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CMS_CONTENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const saveContentAction = (content:Content, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CMS_CONTENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_CMS/api/v1/Contents"
    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    if (content.msbId === undefined) {
        axios.post(_url, content, config)
            .then(function (response) {
                content = response.data;

                dispatch({
                    type: SAVE_CMS_CONTENT_SUCCESS,
                    payload: {
                        content,
                        actionToken
                    }
                });

            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_CMS_CONTENT_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    } else {
        _url = _url + "/" + content.msbId;

        axios.put(_url, content, config)
            .then(function (response) {
                content = response.data;
                dispatch({
                    type: SAVE_CMS_CONTENT_SUCCESS,
                    payload: {
                        content,
                        actionToken
                    }
                });

            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_CMS_CONTENT_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    }
    
};

export const getRevisionAction = (msbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_CMS_REVISION_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_CMS/api/v1/Revisions/" + msbId;

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    axios.get(_url, config)
        .then(function (response) {
            let revision = response.data;
            dispatch({
                type: GET_CMS_REVISION_SUCCESS,
                payload: {
                    revision,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CMS_REVISION_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const saveRevisionAction = (revision:Revision, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CMS_REVISION_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_CMS/api/v1/Revisions"
    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    if (revision.msbId === undefined) {
        axios.post(_url, revision, config)
            .then(function (response) {
                revision = response.data;

                dispatch({
                    type: SAVE_CMS_REVISION_SUCCESS,
                    payload: {
                        revision,
                        actionToken
                    }
                });

            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_CMS_REVISION_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    } else {
        _url = _url + "/" + revision.msbId;

        axios.put(_url, revision, config)
            .then(function (response) {
                revision = response.data;
                dispatch({
                    type: SAVE_CMS_REVISION_SUCCESS,
                    payload: {
                        revision,
                        actionToken
                    }
                });

            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_CMS_REVISION_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    }
    
};