import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../redux/storeTypes';
import { saveUserAction } from '../../../../redux/actions/users';
import { Col, Row, Form, Modal } from 'react-bootstrap';
import { sendSuccessToastAction, sendWarningToastAction } from '../../../../redux/actions/toast';
import RequiredIcon from '../../../../components/RequiredIcon';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Routes } from "../../../../routes";
import { User } from "../../../../models/User";
import FormActions from './FormActions';
import { saveClientConstituentAction } from "../../../../redux/actions/clients/constituents";

interface IAddConstituentProps {
    showForm: boolean;
    setShowForm: (value: boolean) => void;
}

const ConstituentsForm = ({ showForm, setShowForm }: IAddConstituentProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [validated, setValidated] = useState(false);
/*
    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            const user = new User();
            user.email = form.elements.emailAddress.value;
            user.firstName = form.elements.firstName.value;
            user.lastName = form.elements.lastName.value;
            //dispatch(saveClientConstituentAction(user, 'saveUser'));
            dispatch(sendSuccessToastAction("User access has been successfully updated"))            
        }
        else {
            dispatch(sendWarningToastAction("Please Check Constituents Details"));
        }
        setValidated(true);

    };
*/
    return (
        <Modal show={showForm} size="lg" onHide={() => { setShowForm(false); }}>
            <Modal.Body>
                <>
                    <Form id='clientUserSubmit' noValidate validated={validated}>
                        <Row >
                            <Form.Group as={Col} controlId="firstName" className="mb-4">
                                <Form.Label><RequiredIcon />First name</Form.Label>
                                <Form.Control required type="input" placeholder="First name" />
                                <Form.Control.Feedback type="invalid">First name required.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastName" className="mb-4">
                                <Form.Label><RequiredIcon />Last Name</Form.Label>
                                <Form.Control required type="input" placeholder="Last name" />
                                <Form.Control.Feedback type="invalid">Last name required.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row >
                            <Form.Group as={Col} controlId="emailAddress" className="mb-4">
                                <Form.Label><RequiredIcon />Email</Form.Label>
                                <Form.Control required type="email" placeholder="name@example.com" />
                                <Form.Control.Feedback type="invalid">Enter Valid Email.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastUpdated" className="mb-4">
                                <Form.Label><RequiredIcon />Last Updated</Form.Label>
                                <Form.Control required type="input" placeholder="(123) 456-7890" />
                            </Form.Group>
                        </Row>
                        <Row >
                            <Form.Group as={Col} controlId="actions" className="mb-4">
                                <Form.Label><RequiredIcon />Actions</Form.Label>
                            </Form.Group>
                        </Row>
                        <FormActions onCancel={() => { setShowForm(false); }} />
                    </Form>
                </>
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(ConstituentsForm);