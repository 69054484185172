import { Row, Col } from "react-bootstrap"
import { useEffect, useState } from "react";
import Table from '../../../../../../components/Table';

interface INericSearchResults {
    taxTypeLabel: string,
    hideTaxType: boolean,
    billNumberLabel: string,
    owner1Label: string,
    owner2Label: string,
    parcelLocationLabel: string,
    balanceDueLabel: string,
    statusLabel: string,
    paymentOptionsLabel: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    margin: string,
    padding: string,
    headerBackgroundColor : string,
    headerPadding: string,
}

export const TAX_TYPE_LABEL = 'Tax type';
export const HIDE_TAX_TYPE = true;
export const BILL_NUMBER_LABEL = 'NERIC Bill Number';
export const OWNER1_LABEL = 'Owner 1';
export const OWNER2_LABEL = 'Owner 2';
export const PARCEL_LOCATION_LABEL = 'Parcel Location'
export const BALANCE_DUE_LABEL = 'Balance Due';
export const STATUS_LABEL = 'Status';
export const PAYMENT_OPTIONS_LABEL = 'Payment Options';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const NericSearchResults = ({ taxTypeLabel, hideTaxType, billNumberLabel, owner1Label, owner2Label, parcelLocationLabel, balanceDueLabel, statusLabel,
    paymentOptionsLabel, durationOfPendingHours, observesWeekends, margin, padding, headerBackgroundColor ,headerPadding }: INericSearchResults) => {


    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!billNumberLabel) { billNumberLabel = BILL_NUMBER_LABEL }
    if (!owner1Label) { owner1Label = OWNER1_LABEL }
    if (!owner2Label) { owner2Label = OWNER2_LABEL }
    if (!parcelLocationLabel) { parcelLocationLabel = PARCEL_LOCATION_LABEL}
    if (!balanceDueLabel) { balanceDueLabel = BALANCE_DUE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!paymentOptionsLabel) { paymentOptionsLabel = PAYMENT_OPTIONS_LABEL }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }
    
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const initialColumns = [{
        dataField: 'taxType',
        text: taxTypeLabel,
        hidden: true,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'billNumber',
        text: billNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'owner',
        text: owner1Label,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
     
    }, {
        dataField: 'InCareOfName',
        text: owner2Label,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
     
    }, {
        dataField: 'parcelLocation',
        text: parcelLocationLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'balanceDue',
        text: balanceDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,

    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'id',
        text: paymentOptionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const [columns, setColumns] = useState<any>(initialColumns);
   

    useEffect(() => {
        const newColumns = columns.map((column:any) =>{
            if(column.dataField !== 'taxType') return column;
            return{
                ...column,
                hidden: hideTaxType
            }
        })
        setColumns(newColumns)
    }, [hideTaxType]);

    const renderTable = () => {
            return (
                <Table
                    keyField="msbId"
                    data={[]}
                    columns={columns}
                />);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
            <hr />
            <Row style={{textAlign: 'center'}}>
                <span >Place Holder For Payment Options</span>
            </Row>
        </div>

    )
}

export default NericSearchResults;