import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { PaymentChannel, SupplementalFieldType, SupplementalField } from '../../../../../models/Client';
import "react-placeholder/lib/reactPlaceholder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Table from '../../../../../components/Table';
import { IActionResult, IAppState } from "../../../../../redux/storeTypes";
import RequiredIcon from "../../../../../components/RequiredIcon";
import { addChannelSupplementalFieldAction, deleteChannelSupplementalFieldAction, getPaymentChannelAction } from "../../../../../redux/actions/clients/paymentChannels";

export interface IDepartmentSupplementalFieldFormProps {
    paymentChannelMsbId: any,
    paymentChannel: PaymentChannel,
    isFetching: boolean,
    isSaving: boolean,
    onClose: any
}

const DepartmentSupplementalFieldsForm = ({ paymentChannelMsbId, paymentChannel, isFetching, isSaving, onClose }: IDepartmentSupplementalFieldFormProps) => {
    const actionToken = "SupplementalFields";
    const dispatch = useDispatch();
    const [currentSupplementalFieldType, setCurrentSupplementalFieldType] = useState<any>(SupplementalFieldType.TextBox);
    const [currentSupplementalFieldIdentifier, setCurrentSupplementalFieldIdentifier] = useState<any>('');
    const [currentSupplementalFieldLabel, setCurrentSupplementalFieldLabel] = useState<any>('');
    const [currentSupplementalFieldRequired, setCurrentSupplementalFieldRequired] = useState<boolean>(false);
    const [supplementalFields, setSupplementalFields] = useState<Array<SupplementalField>|undefined>(undefined);
    const [error, setError] = useState("");

    useEffect(() => {
        if (!supplementalFields) {
            dispatch(getPaymentChannelAction(paymentChannelMsbId, actionToken));
        }
    }, [supplementalFields])


    useEffect(() => {
        if (paymentChannel) {
            setSupplementalFields(paymentChannel.channelSupplementalFields);
        }
    }, [paymentChannel])

    const addSupplementalField = (e: any) => {
        e.preventDefault();
        let isTypeValid = currentSupplementalFieldType !== '';
        let isLabelValid = currentSupplementalFieldLabel !== '';
        let isIdentifierValid = currentSupplementalFieldIdentifier !== '';

        let isLabelDuplicate = supplementalFields?.some(sf => { return sf.fieldLabel == currentSupplementalFieldLabel });
        if (isLabelDuplicate) {
            setError('Please use a different Label name')
        }

        let isIdentifierDuplicate = supplementalFields?.some(sf => { return sf.fieldIdentifier.replace("cmd_", "") == currentSupplementalFieldIdentifier });
        if (isIdentifierDuplicate) {
            setError('Please use a different Identifier name')
        }

        if (isTypeValid && isIdentifierValid && isLabelValid && !isIdentifierDuplicate && !isLabelDuplicate) {
            setError('')
            let _supplementalField = new SupplementalField();
            _supplementalField.supplementalFieldType = currentSupplementalFieldType;
            _supplementalField.fieldLabel = currentSupplementalFieldLabel;
            _supplementalField.fieldIdentifier = "cmd_" + currentSupplementalFieldIdentifier;
            _supplementalField.fieldIsRequired = currentSupplementalFieldRequired;
        
            dispatch(addChannelSupplementalFieldAction(paymentChannel.msbId, _supplementalField, actionToken));

            setCurrentSupplementalFieldLabel("");
            setCurrentSupplementalFieldIdentifier("");
            setCurrentSupplementalFieldRequired(false);
        }
    }

    const typeFormatter = (cell: any, field: any) => {
        return (
            <span>{field.supplementalFieldType}</span>
        );
    }

    const identifierFormatter = (cell: any, field: any) => {
        return (
            <span>{field.fieldIdentifier.replace("cmd_", "")}</span>
        );
    }

    const typeRequiredFormatter = (cell: any, field: any) => {
        return (
            <span>{cell ? 'Yes' : 'No'}</span>
        );
    }

    const deleteSupplementalField = (cell: string, field: any) => {
        return (
            <Button variant="outline-secondary" className="btn-outline-secondary--small" onClick={() => dispatch(deleteChannelSupplementalFieldAction(field.msbId, paymentChannelMsbId, actionToken))}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        );
    }

    return (
        <>
            <Form onSubmit={addSupplementalField}>
                <Row>
                    <Col xs={3}>
                        <Form.Group controlId="supplementalFieldType">
                            <Form.Label><RequiredIcon />Type</Form.Label>
                            <Form.Control required as="select" value={currentSupplementalFieldType} onChange={(e) => setCurrentSupplementalFieldType(e.target.value)}>
                                {/* <option value="" disabled hidden>Select</option> */}
                                <option value={SupplementalFieldType.TextBox}>Textbox</option>
                                {/* <option value={SupplementalFieldType.Date}>Date</option> */}
                                {/* <option value={SupplementalFieldType.List}>List</option>
                                <option value={SupplementalFieldType.Radio}>Radio</option>
                                <option value={SupplementalFieldType.Checkbox}>CheckBox</option> */}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group controlId="supplementalFieldIdentifier">
                            <Form.Label><RequiredIcon />Identifier</Form.Label>
                            <Form.Control required type="input" placeholder="Identifier" maxLength={20} value={currentSupplementalFieldIdentifier} onChange={(e) => setCurrentSupplementalFieldIdentifier(e.target.value)} disabled={isSaving} />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group controlId="supplementalFieldLabel">
                            <Form.Label><RequiredIcon />Label</Form.Label>
                            <Form.Control required type="input" placeholder="Label" maxLength={20} value={currentSupplementalFieldLabel} onChange={(e) => setCurrentSupplementalFieldLabel(e.target.value)} disabled={isSaving} />
                        </Form.Group>
                    </Col>
                    <Col xs={1}>
                        <Form.Group controlId="supplementalFieldRequired">
                            <Form.Label>&nbsp;</Form.Label>
                            <Form.Check type="checkbox" label="Required" checked={currentSupplementalFieldRequired} onChange={(e) => setCurrentSupplementalFieldRequired(e.target.checked)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>&nbsp;</Form.Label>
                            <div style={{ float: 'right' }}>
                                <Button variant="primary" type="submit">
                                    <FontAwesomeIcon icon={faPlus} size="sm" style={{ marginRight: "0" }} />
                                </Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                {error != '' &&
                    <Row>
                        <div className="invalid-feedback" style={{ display: "block", marginBottom: "20px" }}>{error}</div>
                    </Row>
                }
                <Row>
                    <Col>
                        {
                            supplementalFields && supplementalFields.length > 0 ?
                            <Table
                                keyField="supplementalId"
                                columns={[
                                    {
                                        dataField: 'supplementalFieldType',
                                        text: 'Type',
                                        editable: false,
                                        formatter: typeFormatter
                                    }, {
                                        dataField: 'fieldIdentifier',
                                        text: 'Identifier',
                                        editable: false,
                                        formatter: identifierFormatter
                                    }, {
                                        dataField: 'fieldLabel',
                                        text: 'Label',
                                        editable: false,
                                    }, {
                                        dataField: 'fieldIsRequired',
                                        text: 'Required',
                                        editable: false,
                                        formatter: typeRequiredFormatter

                                    }, {
                                        dataField: 'supplementalId',
                                        text: 'Actions',
                                        editable: false,
                                        formatter: deleteSupplementalField,
                                        headerAlign: 'center'
                                    }
                                ]}
                                data={supplementalFields}
                            />
                            :
                            <h2 className="text-center"><i>Supplemental Fields are empty</i></h2>
                        }
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        paymentChannel: state.clients.paymentChannel,
    };
};


export default connect(mapStateToProps)(DepartmentSupplementalFieldsForm);
