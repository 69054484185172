import * as React from "react";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { IAppState, IActionResult } from '../../../../redux/storeTypes';
import { Client, Department } from "../../../../models/Client";
import { Routes } from "../../../../routes";
import StepHeader from "../addClientSteps/StepHeader";
import ConvenienceFeesForm from "../forms/department/ConvenienceFeesForm";
import { sendErrorToastAction, sendSuccessToastAction } from "../../../../redux/actions/toast";
import { SAVE_CONVENIENCE_FEES_REQUEST, SAVE_CONVENIENCE_FEES_SUCCESS, SAVE_CONVENIENCE_FEES_FAILURE } from "../../../../redux/actions/clients/departments";

interface IDepartmentFeesSectionProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    department: Department,
    actionResult: IActionResult
}

const DepartmentFeesSection = ({ isSaving, isFetching, client, department, actionResult }: IDepartmentFeesSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const actionToken = "DepartmentFeesSection";

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_CONVENIENCE_FEES_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_CONVENIENCE_FEES_SUCCESS) {
                dispatch(sendSuccessToastAction("Convenience fees were successfully saved."));
            } else if (actionResult.result === SAVE_CONVENIENCE_FEES_FAILURE) {
                dispatch(sendErrorToastAction("Convenience fees could not be saved."));
            }
        }
    }, [actionResult]);

    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                <StepHeader
                    title="Convenience fees"
                    description="Below are the assigned convenience fees for this department’s payment channels"
                />
      
                <ConvenienceFeesForm
                    key={actionResult.result}
                    isSaving={isSaving}
                    isFetching={isFetching}
                    actionToken={actionToken}
                    department={department}
                    formActionProps={{
                        hasSubmit: true,
                        customProps: {
                            submitButtonDisplay: "Save"
                        }
                    }} />
                
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        client: state.clients.client,
        department: state.clients.department,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(DepartmentFeesSection);




