import axios, { ResponseType } from 'axios';
import { Dispatch } from 'react';

import store from "../store";

export const CLEAR_DOCUMENT = "CLEAR_DOCUMENT";

export const CREATE_DOCUMENT_REQUEST = "CREATE_DOCUMENT_REQUEST";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_FAILURE = "CREATE_DOCUMENT_FAILURE";

export const createPDF = (contentUrl: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CREATE_DOCUMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    const url = "/MSB_Document/api/v1/Pdf";
    const data = { url: contentUrl };
    const responseType: ResponseType = 'blob';
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            MsbBearer: `${msbBearerToken}`,
            Accept: 'application/pdf'            
        },
        responseType
    };
    
    axios.post(url, data, config)
        .then(function (response) {
            dispatch({
                type: CREATE_DOCUMENT_SUCCESS,
                payload: {
                    blobObject: new Blob([response.data]),
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: CREATE_DOCUMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
};

export const createCSV = (content_url: string, downloadType : number, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CREATE_DOCUMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    const url = "/MSB_Document/api/v1/Csv";

    //if (content_url.)
    const data = { url: content_url, reportDownloadType: downloadType };

    const responseType: ResponseType = 'text';
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${store.getState().webAppSettings.bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            Accept: 'application/csv'
        },
        responseType
    };
    
    axios.post(url, data, config)
        .then(function (response) {
            dispatch({
                type: CREATE_DOCUMENT_SUCCESS,
                payload: {
                    blobObject: new Blob([response.data]),
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: CREATE_DOCUMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
};

const handleFileDownloadClick = (fileView: any) => {
    if (fileView.fileName !== "") {
        const downloadUrl = window.URL.createObjectURL(new Blob([fileView.fileData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.target = "_blank";
        link.setAttribute('download', fileView.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export const createExcel = (fromDate: string, endDate: string, actionToken: string, downloadType : number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CREATE_DOCUMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    const url = `/MSB_Document/api/v1/Excel/?fromDate=${fromDate}&endDate=${endDate}&reportDownloadType=${downloadType}`;
    const responseType: ResponseType = 'blob';
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    let excelFileName = "ReconciliationReport_" + `${fromDate}-${endDate}` + ".xlsx";

   
    let results: any = [];
    axios.request({ url: url, method: 'POST', responseType: 'blob' })
    .then(function (response) {
        results = response.data;
        handleFileDownloadClick({ fileData: results, fileName: excelFileName });
        dispatch({
            type: CREATE_DOCUMENT_SUCCESS,
            payload: {
                results,
                actionToken
            }
        });

    })
    .catch(function (error) {
        dispatch({
            type: CREATE_DOCUMENT_FAILURE,
            payload: {
                error,
                actionToken
            }
        });
    })
    .then(function () {
    });
};

export const createWorldpayTransactionsReportExcel = (fromDate: string, endDate: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CREATE_DOCUMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    const excelFileName = "WorldpayTransactionsReport_" + `${fromDate}-${endDate}` + ".xlsx";

    const url = "/MSB_Document/api/v1/Excel/WorldpayTransactionReport";

    const data = {
        fromDate: `${fromDate}T00:00:00.000`,
        toDate: `${endDate}T23:59:59.999`
    };

    const responseType: ResponseType = 'blob';

    const config = {
        headers: {
            Authorization: `Bearer ${store.getState().webAppSettings.bearerToken}`,
            Accept: '*/*',
        },
        responseType
    };

    let results: any = [];
    axios.post(url, data, config)
        .then(function (response) {
            results = response.data;
            handleFileDownloadClick({ fileData: results, fileName: excelFileName });
            dispatch({
                type: CREATE_DOCUMENT_SUCCESS,
                payload: {
                    results,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: CREATE_DOCUMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};


export const createInvoiceTransactionsReportExcel = (fromDate: string, endDate: string, clientId: string, departmentId: string, clientName: string, departmentName: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CREATE_DOCUMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    const excelFileName = clientName + "_" + departmentName + "_PaymentProcessingClientReport_" + `${fromDate}-${endDate}` + ".xlsx";

    const url = "/MSB_Document/api/v1/Excel/InvoiceReport";

    const data = {
        fromDate: `${fromDate}T00:00:00.000`,
        toDate: `${endDate}T23:59:59.999`,
        clientId: clientId,
        departmentId: departmentId 
    };

    const responseType: ResponseType = 'blob';

    const config = {
        headers: {
            Authorization: `Bearer ${store.getState().webAppSettings.bearerToken}`,
            Accept: '*/*',
        },
        responseType
    };

    let results: any = [];
    axios.post(url, data, config)
        .then(function (response) {
            results = response.data;
            handleFileDownloadClick({ fileData: results, fileName: excelFileName });
            dispatch({
                type: CREATE_DOCUMENT_SUCCESS,
                payload: {
                    results,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: CREATE_DOCUMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const createInvoiceReportExcelAllClients = (fromDate: string, endDate: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CREATE_DOCUMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    const url = "/MSB_Document/api/v1/Excel/InvoiceReport";

    const data = {
        fromDate: `${fromDate}T00:00:00.000`,
        toDate: `${endDate}T23:59:59.999`,
        filePath: `Finance Reports\\OnDemand`
    };

    const config = {
        headers: {
            Authorization: `Bearer ${store.getState().webAppSettings.bearerToken}`,
            Accept: '*/*',
        }
    };

    let results: any = [];
    axios.post(url, data, config)
        .then(function (response) {
            results = response.data;
            //handleFileDownloadClick({ fileData: results, fileName: excelFileName });
            dispatch({
                type: CREATE_DOCUMENT_SUCCESS,
                payload: {
                    results,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: CREATE_DOCUMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};