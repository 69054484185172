import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Client, ClientStatusEnum } from "../../../../models/Client";
import { IActionResult, IAppState } from "../../../../redux/storeTypes";
import ApprovalChecklistForm from "../forms/ApprovalChecklistForm";
import FormHeader from "../forms/FormHeader";
import { faInfoCircle, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendSuccessToastAction, sendErrorToastAction } from "../../../../redux/actions/toast";
import { SAVE_APPROVAL_CHECKLIST_FAILURE, SAVE_APPROVAL_CHECKLIST_REQUEST, SAVE_APPROVAL_CHECKLIST_SUCCESS } from "../../../../redux/actions/clients/approvalChecklist";
import Approval, { ApprovalTypeEnum } from "../../../../models/Approval";
import { getRejectedApprovalAction } from "../../../../redux/actions/pendingApprovals";

interface ChecklistProps {
    client: Client,
    actionResult: IActionResult,
    approval: Approval
}

const ApprovalChecklistSection = ({ client, actionResult, approval }: ChecklistProps) => {
    const dispatch = useDispatch();
    const actionToken = "ApprovalChecklist";

    useEffect(() => {
        /*
        if (actionResult && actionResult.result) {
            if (actionResult.type === SAVE_APPROVAL_CHECKLIST_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_APPROVAL_CHECKLIST_SUCCESS) {
                    if (client.approvalChecklist!.isApprovalSubmitted) {
                        dispatch(sendSuccessToastAction("Client has been submitted for approval."));
                    } else {
                        dispatch(sendSuccessToastAction("Approval checklist has been saved."));
                    }
                } else if (actionResult.result === SAVE_APPROVAL_CHECKLIST_FAILURE) {
                    dispatch(sendErrorToastAction("Approval checklist could not be saved."));
                }
            } 
        }
        */
    }, [actionResult]);

    useEffect(() => {
        /*
        if (client.onBoardingStatus === ClientStatusEnum.ApprovalRejected) {
            dispatch(getRejectedApprovalAction(client.msbId!, ApprovalTypeEnum.SetupApproval, actionToken))
        }
        */
    }, []);  

    const renderApprovalComments = () => {
        if (approval !== null) {
            return(<><br /><span>{approval.comments}</span></>);
        } else {
            return(<></>);
        }
    }

    const RenderAlert = () => {
        /*
        if (client.onBoardingStatus === ClientStatusEnum.ApprovalPending) {
            return (
              <Alert variant={'info'}>
                <div className="alert-container">
                  <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                  <h2>Waiting for approval</h2>
                </div>
              </Alert>
            );
        } else if (client.onBoardingStatus === ClientStatusEnum.Active) {
            return (           
                <Alert variant={'success'}>
                  <div className="alert-container">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                    <h2>Approval checklist has been approved</h2>
                  </div>
              </Alert>
            );
        } else if (client.onBoardingStatus === ClientStatusEnum.ApprovalRejected) {
            return (
              <Alert variant={'danger'}>
                  <div className="alert-container">
                    <FontAwesomeIcon icon={faTimesCircle} size="2x" />
                    <h2>Approval checklist rejected. Please verify everything is completed and resubmit.</h2>
                    {renderApprovalComments()}
                  </div>
              </Alert>
            );
        }
        */
        return (<></>)
    }
    
    return (
        <section className='aprovalChecklistWrapper'>
            {RenderAlert()}
            <FormHeader title="Client Approval Checklist" description="Please confirm that all items listed below have been completed. You will not be able to proceed to the approval process if any of the items below have not been completed." />
            <ApprovalChecklistForm client={client} actionToken={actionToken}/>
        </section>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client,
        approval: state.pendingApprovals.approval,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(ApprovalChecklistSection);