import { GET_ITEMS_REQUEST, GET_ITEMS_SUCCESS, GET_ITEMS_FAILURE, DEL_ITEMS_REQUEST, DEL_ITEMS_SUCCESS, DEL_ITEMS_FAILURE, ADD_ITEMS_REQUEST, ADD_ITEMS_SUCCESS, ADD_ITEMS_FAILURE } from '../actions/payments/itemManagement';

export default function itemManagement (state = {
    isFetching: false,
    items: [],
    actionResult: null,
    errorMessage: ''
}, { type, payload }) {
   
    switch (type) {
        case GET_ITEMS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_ITEMS_REQUEST, token: payload.actionToken, result: null },
            });
        case GET_ITEMS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                items: payload.items,
                actionResult: { type: GET_ITEMS_REQUEST, token: payload.actionToken, result: GET_ITEMS_SUCCESS }
            });
        case GET_ITEMS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                items: [],
                errorMessage: payload.error,
                actionResult: { type: GET_ITEMS_REQUEST, token: payload.actionToken, result: GET_ITEMS_FAILURE }
            });
        case DEL_ITEMS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: DEL_ITEMS_REQUEST, token: payload.actionToken, result: null },
            });
        case DEL_ITEMS_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: DEL_ITEMS_REQUEST, token: payload.actionToken, result: DEL_ITEMS_SUCCESS }
            });
        case DEL_ITEMS_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                errorMessage: payload.error,
                actionResult: { type: DEL_ITEMS_REQUEST, token: payload.actionToken, result: DEL_ITEMS_FAILURE }
            });
        case ADD_ITEMS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_ITEMS_REQUEST, token: payload.actionToken, result: null },
            });
        case ADD_ITEMS_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_ITEMS_REQUEST, token: payload.actionToken, result: ADD_ITEMS_SUCCESS }
            });
        case ADD_ITEMS_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                errorMessage: payload.error,
                actionResult: { type: ADD_ITEMS_REQUEST, token: payload.actionToken, result: ADD_ITEMS_FAILURE }
            });
        default:
            return state;
    }
}
