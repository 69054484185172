import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import RequiredIcon from '../../../../../components/RequiredIcon';
import { Client, Department, DebitFeeMethodEnum, FeeBurdenMethodEnum, FundingMethodEnum } from '../../../../../models/Client';
import FormActions, { IFormActionProps } from '../FormActions';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveDepartmentAction } from "../../../../../redux/actions/clients/departments";
import CurrencyInput from "../../../../../components/currency/CurrencyInput";

export interface IDepartmentAccountingFormProps {
    isSaving: boolean,
    isFetching: boolean,
    department: Department,
    client: Client,
    actionToken: string,
    formActionProps?: IFormActionProps,
}

const DepartmentAccountingForm = ({ isSaving, isFetching, client, department, actionToken, formActionProps }: IDepartmentAccountingFormProps) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            let _department = Object.assign({}, department);
            _department.bankFundingDescriptor = form.elements.bankFundingDescriptor.value;
            _department.achDebitDescriptor = form.elements.achDebitDescriptor.value;
            _department.debitFeeMethod = form.elements.debitFeeMethodEnum.value;
            _department.feeBurdenMethod = form.elements.feeBurdenMethodEnum.value;
            _department.fundingMethod = form.elements.fundingMethodEnum.value;
            _department.chargebackFee = form.elements.chargebackFee.value.replace('$ ', '');
            _department.nsfFee = form.elements.nsfFee.value.replace('$ ', '');

            delete _department.onBoardingStatus;
            delete _department.clientBankAccounts;
            delete _department.convenienceFees;
            delete _department.departmentAddresses;
            delete _department.departmentContacts;
            delete _department.departmentsPrinters;
            delete _department.paymentChannels;
            delete _department.receipts;

            dispatch(saveDepartmentAction(_department, actionToken));
        }
        setValidated(true);
    };

    const handleNoSpaceChange = (e:any) => {
        if (e.currentTarget.value.includes(" ")) {
          e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
        }
      };

    const handleNoSpaceKeyDown = (e:any) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const nsfValue = (department:Department) => {
        if (department && department.nsfFee > 0) {
            return department.nsfFee;
        } else {
            return;
        }
    }

    const chargebackFeeValue = (department:Department) => {
        if (department && department.chargebackFee > 0) {
            return department.chargebackFee;
        } else {
            return;
        }
    }

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="bankFundingDescriptor">
                        <Form.Label><RequiredIcon />Bank funding descriptor</Form.Label>
                        <Form.Control required type="input" disabled={isSaving} maxLength={16} pattern="([a-zA-z0-9\s])*$" defaultValue={department?.bankFundingDescriptor} onKeyDown={handleNoSpaceKeyDown} onChange={handleNoSpaceChange}  />
                        <span className="helper-text">Maximum of 16 characters</span>
                        <Form.Control.Feedback type="invalid">Bank funding descriptor required.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="achDebitDescriptor">
                        <Form.Label><RequiredIcon />eCheck debit descriptor</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required disabled={isSaving} maxLength={16} type='text' pattern="([a-zA-z0-9\s])*$"  defaultValue={department?.achDebitDescriptor} onKeyDown={handleNoSpaceKeyDown} onChange={handleNoSpaceChange} placeholder="Enter eCheck debit descriptor"  />
                        </ReactPlaceholder>
                        <span className="helper-text">Maximum of 16 characters</span>
                        <Form.Control.Feedback type="invalid">Please enter eCheck debit descriptor</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="debitFeeMethodEnum">
                        <Form.Label><RequiredIcon />Debit fee method</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required as="select" disabled={isSaving} defaultValue={department?.debitFeeMethod}>
                                <option value="">Select</option>
                                <option value={DebitFeeMethodEnum.DirectDebit}>Direct debit</option>
                                <option value={DebitFeeMethodEnum.BillOut}>Bill out</option>
                                <option value={DebitFeeMethodEnum.Net}>Net</option>
                            </Form.Control>
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select the Debit fee method</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="feeBurdenMethodEnum">
                        <Form.Label><RequiredIcon />Fee burden method</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required as="select" disabled={isSaving} defaultValue={department?.feeBurdenMethod}>
                                <option value="">Select</option>
                                <option value={FeeBurdenMethodEnum.ConvenienceFee}>Convenience fee</option>
                                <option value={FeeBurdenMethodEnum.ClientAbsorbed}>Client absorbed</option>
                            </Form.Control>
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select the Fee burden method</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="fundingMethodEnum">
                        <Form.Label><RequiredIcon />Funding method</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required as="select" disabled={isSaving} defaultValue={department?.fundingMethod}>
                                <option value="">Select</option>
                                <option value={FundingMethodEnum.SameDay}>Same day</option>
                                <option value={FundingMethodEnum.NextDay}>Next day</option>
                                <option value={FundingMethodEnum.TwoDay}>Two day</option>
                                <option value={FundingMethodEnum.Other}>Other</option>
                            </Form.Control>
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select the Funding method</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="chargebackFee">
                        <Form.Label><RequiredIcon />Chargeback fee</Form.Label>
                             <CurrencyInput
                                required
                                className="form-control"
                                disabled={isSaving}
                                id="chargebackFee"
                                name="chargebackFee"
                                placeholder="$ 0.00"
                                maxLength={10}
                                decimalsLimit={2}
                                prefix="$ "
                                defaultValue={chargebackFeeValue(department)}
                                />
                        <Form.Control.Feedback type="invalid">Please enter the Chargeback fee</Form.Control.Feedback>                    
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="nsfFee">
                        <Form.Label><RequiredIcon />NSF fee</Form.Label>
                             <CurrencyInput
                                required
                                className="form-control"
                                disabled={isSaving}
                                id="nsfFee"
                                name="nsfFee"
                                placeholder="$ 0.00"
                                maxLength={10}
                                decimalsLimit={2}
                                prefix="$ "
                                defaultValue={nsfValue(department)}
                                />
                            <Form.Control.Feedback type="invalid">Please enter the NSF fee</Form.Control.Feedback>        
                    </Form.Group>
                  </Col>
                </Row>

                <div className="manage-footer">
                  <FormActions
                      disabled={isSaving || isFetching}
                      showSpinner={isSaving}
                      {...formActionProps} />
                </div>
            </Form>
        </>
    );


};

export default DepartmentAccountingForm;