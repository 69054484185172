import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Table from "../../../components/Table";
import PageSectionContainer from "../../../components/layout/PageSectionContainer";
import Switch from "react-switch";
import { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { deleteNotifications, setNotifications } from "../../../redux/actions/account/notificationSettings";
import { IAppState } from "../../../redux/storeTypes";
import _ from "lodash";
import { User } from "../../../models/User";

export interface IPaymentChannelNotifications {
    data: any,
    departmentName: any,
    emailPaymentChannelNotifications: any,
    paymentChannelNotifications?: any,
    deletePaymentChannelNotifications?: any,
    currentUser?: User,
}

const PaymentChannelTable = ({ data, departmentName, emailPaymentChannelNotifications, paymentChannelNotifications, deletePaymentChannelNotifications, currentUser }: IPaymentChannelNotifications) => {
    const dispatch = useDispatch();
    const switchColumnFormatter = (cell: any, row: any, rowIndex: number) => {
        return (
            <>
                {renderSwitch(row?.msbId, 'voids', 'Voids')}
                {renderSwitch(row?.msbId, 'refunds', 'Refunds')}
                {renderSwitch(row?.msbId, 'chargebacks', 'Chargebacks')}
            </>
        )
    };

    const [rerenderKey, setRerenderKey] = useState(0);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(data.slice(0, 10));
    const [sizePerPage, setSizePerPage] = useState(10);

    const [notificationSwitches, setNotificationSwitches] = useState(
        data.map((channel: any) => ({
            msbId: channel?.msbId,
            name: channel?.name,
            voids: channel?.voids || false,
            refunds: channel?.refunds || false,
            chargebacks: channel?.chargebacks || false,
            lastChanged: ''
        }))
    );

    useEffect(() => {
        if (paymentChannelNotifications) {
            const filteredArray = _.unionBy(paymentChannelNotifications, notificationSwitches, 'msbId');
            const resultArray = _.isEmpty(filteredArray) ? notificationSwitches : filteredArray;
            setPage(page);
            setNotificationSwitches(resultArray);
            setRerenderKey(prevKey => prevKey + 1);
        }
    }, [paymentChannelNotifications]);

    const columns = [
        {
            dataField: 'msbId',
            text: 'msbId',
            sort: false,
            hidden: true,
            editable: false
        }, {
            dataField: 'name',
            sort: true,
            text: 'Payment Channel name',
            editable: false,
        }, {
            dataField: '',
            isDummyField: true,
            text: 'Notifications (Email)',
            editable: false,
            formatter: switchColumnFormatter,
        },
    ];

    const toggleNotifications = (msbId: string, switchType: string) => {
        setNotificationSwitches((prevSwitches: any) => {
            const updatedSwitches = prevSwitches.map((channel: any) =>
                channel.msbId === msbId
                    ? { ...channel, [switchType]: !channel[switchType], lastChanged: switchType }
                    : channel
            );
            const mergedArray = _.unionBy(updatedSwitches, paymentChannelNotifications, 'msbId') as any;
            const deletePaymentChannelIds = _.flatMap(deletePaymentChannelNotifications, ({ paymentChannelIds }) =>
                Array.isArray(paymentChannelIds) ? paymentChannelIds : [paymentChannelIds]
            );

            const transactionTypeMap = {
                voids: ['Void', 'Reversal'],
                refunds: ['Refund', 'Return'],
                chargebacks: ['Chargeback']
            } as any

            const relevantTransactionTypes = transactionTypeMap[switchType] || [];
            const markedForDeletion = _(emailPaymentChannelNotifications)
                .filter(item =>
                    _.some(item.emailRecipients, { emailAddress: currentUser?.email }) &&
                    item.paymentChannelId === msbId &&
                    relevantTransactionTypes.includes(item.transactionType)
                )
                .groupBy(item => item.notificationMsbId)
                .mapValues(group => {
                    const currentIds = _.map(group, 'paymentChannelId');
                    const toggleIsOn = updatedSwitches.find((channel: any) => channel.msbId === msbId)[switchType];

                    const updatedIds = toggleIsOn
                        ? _.difference(deletePaymentChannelIds, currentIds)
                        : _.union(deletePaymentChannelIds, currentIds);

                    return { emailAddress: currentUser?.email, paymentChannelIds: _.compact(updatedIds) };
                })
                .value();
            mergedArray.page = page
            
            const deletePaymentChannels = {...deletePaymentChannelNotifications, ...markedForDeletion}

            dispatch(deleteNotifications(deletePaymentChannels, 'delete notifications'));
            dispatch(setNotifications(mergedArray, 'notifications'));

            return updatedSwitches;
        });
        setRerenderKey(prevKey => prevKey + 1);
    };
    
    const renderSwitch = (msbId: string, switchType: string, label: string) => {
        return (
            <>
                <Switch
                    key={switchType}
                    onChange={() => toggleNotifications(msbId, switchType)}
                    checked={notificationSwitches.find((channel: any) => channel.msbId === msbId)[switchType]}
                    onColor={'#0057B6'}
                    offColor={'#BEBEBE'}
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={16}
                    width={28}
                    activeBoxShadow={'0 0 0 1px #0057B6'}
                />
                <span className="roleType toggle-label">{label}</span>
            </>
        )
    };

    const defaultSorted = [{
        dataField: 'name',
        order: 'asc'
    }];

    const handleTableChange = useCallback((type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        setPage(page);
        setRecords(data.slice(currentIndex, currentIndex + sizePerPage));
        setSizePerPage(sizePerPage);
    }, []);

    return (
        <div className="manage-card">
            <ToolkitProvider key={`table-${rerenderKey}`} keyField="msbId" data={data} columns={columns} search>
                {
                    (props: any) => (
                        <PageSectionContainer title={`${departmentName} Payment Channels`} toolbar={''}>
                            <Table
                                keyField='msbId'
                                data={records}
                                remote={true}
                                page={page}
                                totalSize={data.length}
                                sizePerPage={sizePerPage}
                                onTableChange={handleTableChange}
                                columns={columns}
                                defaultSorted={defaultSorted}
                            />
                        </PageSectionContainer>
                    )
                }
            </ToolkitProvider>
        </div>
    );
};


const mapStateToProps = (state: IAppState) => {
    return {
        currentUser: state.auth.currentUser,
        paymentChannelNotifications: state.accountSettings.paymentChannelNotifications,
        deletePaymentChannelNotifications: state.accountSettings.deletePaymentChannelNotifications
    };
};

export default connect(mapStateToProps)(PaymentChannelTable);