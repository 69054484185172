import { Row, Col } from "react-bootstrap"
import { useState } from "react";
import Table from '../../../../../../components/Table';

interface IPikeSearchResults {
    nameLabel: string,
    accountNumberLabel: string,
    taxYearLabel: string,
    propertyTypeLabel: string,
    addressLabel: string,
    dueDateLabel: string,
    balanceDueLabel: string,
    statusLabel: string,
    viewDetailsLabel: string,
    delinquentLabel: string,
    delinquentBackgroundColor: string,
    delinquentTextColor: string,
    delinquentTextAlign: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
}

export const NAME_LABEL = 'Name';
export const ACCOUNT_NUMBER_LABEL = 'Account Number';
export const TAX_YEAR_LABEL = 'Tax Year';
export const PROPERTY_TYPE_LABEL = 'Property Type';
export const ADDRESS_LABEL = 'Address';
export const BALANCE_DUE_LABEL = 'Balance Due';
export const STATUS_LABEL = 'Unpaid';
export const DUE_DATE_LABEL = 'Due Date';
export const VIEW_DETAILS_LABEL = 'View Details';
export const DELINQUENT_LABEL = 'All the following records must be paid';
export const DELINQUENT_BACKGROUND_COLOR = '#f0f0f0';
export const DELINQUENT_TEXT_ALIGN = 'center';
export const DELINQUENT_TEXT_COLOR = 'black';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const PikeSearchResults = ({ nameLabel, accountNumberLabel, taxYearLabel, propertyTypeLabel, statusLabel,
    addressLabel, balanceDueLabel, dueDateLabel, viewDetailsLabel,delinquentLabel, delinquentBackgroundColor, 
    delinquentTextColor, delinquentTextAlign, durationOfPendingHours, observesWeekends, margin, padding, 
    headerBackgroundColor, headerPadding }: IPikeSearchResults) => {

    if (!nameLabel) { nameLabel = NAME_LABEL }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!taxYearLabel) { taxYearLabel = TAX_YEAR_LABEL }
    if (!propertyTypeLabel) { propertyTypeLabel = PROPERTY_TYPE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL }
    if (!balanceDueLabel) { balanceDueLabel = BALANCE_DUE_LABEL }
    if (!dueDateLabel) { dueDateLabel = DUE_DATE_LABEL }
    if (!viewDetailsLabel) { viewDetailsLabel = VIEW_DETAILS_LABEL }
    if (!delinquentLabel) { delinquentLabel = DELINQUENT_LABEL }
    if (!delinquentBackgroundColor) { delinquentBackgroundColor = DELINQUENT_BACKGROUND_COLOR }
    if (!delinquentTextAlign) { delinquentTextAlign = DELINQUENT_TEXT_ALIGN }
    if (!delinquentTextColor) { delinquentTextColor = DELINQUENT_TEXT_COLOR}
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const initialColumns = [{
        dataField: 'Name',
        text: nameLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'Account',
        text: accountNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'TaxYear',
        text: taxYearLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'TaxType',
        text: propertyTypeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    },{
        dataField: 'Address1',
        text: addressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'TotalDue',
        text: balanceDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'DueDate',
        text: dueDateLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'Status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'action',
        text: viewDetailsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];


    const [columns, setColumns] = useState<any>(initialColumns);

    const renderTable = () => {
        return (
            <Table
                keyField="msbId"
                data={[]}
                columns={columns}
            />);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
            <hr />
            <Row style={{ textAlign: 'center' }}>
                <span >Place Holder For Payment Options</span>
            </Row>
        </div>

    )
}

export default PikeSearchResults;