import React from "react";
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { Client } from '../../models/Client';
import { IAppState } from '../../redux/storeTypes';
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Crumb } from "../../models/Crumb";
import { Routes } from "../../routes";
import { resetClientsStoreAction } from "../../redux/actions/clients/clients";
import DepartmentDetailsStep from "./components/addClientSteps/DepartmentDetailsStep";

export interface IDepartmentAddProps {
    client: Client
}

const DepartmentAdd = ({ client }: IDepartmentAddProps) => {
    if (client) {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path, resetClientsStoreAction("DepartmentAdd")));
        crumbs.push(new Crumb(client.businessName, Routes.Client.path));
        crumbs.push(new Crumb("Add Department", Routes.DepartmentAdd.path));
        return (
            <>
                <PageHeader title={"Add Department"} crumbs={crumbs} />
                <Container fluid>
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <DepartmentDetailsStep />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>              
            </>
        );
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path, resetClientsStoreAction("DepartmentAdd")));
        crumbs.push(new Crumb("Add Department", Routes.DepartmentAdd.path));
        return (
            <>
                <PageHeader title={"Add Department"} />
                <Container>
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <DepartmentDetailsStep />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>                
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client
    };
};

export default connect(mapStateToProps)(DepartmentAdd);