import { Row, Col } from "react-bootstrap"
import Table from '../../../../../../components/Table';

interface IStCharlesSearchResults {
    ownerLabel: string,
    taxYearLabel: string,
    billNumberLabel: string,
    taxTypeLabel: string,
    addressLabel: string,
    statusLabel: string,
    paidDateLabel: string,
    statusFilterLabel: string,
    taxTypeFilterLabel: string,
    taxYearFilterLabel: string,
    actionsLabel: string,
    disableCart: boolean,
    disableCartDateRanges: string,
    delinquentLabel: string,
    delinquentBackgroundColor: string,
    delinquentTextColor: string,
    delinquentTextAlign: string,
    firstNameLabel: string,
    lastNameLabel: string,
    emailLabel: string,
    phoneNumberLabel: string,
    newAddressLabel: string,
    cityLabel: string,
    stateLabel: string,
    zipLabel: string,
    checkboxDisclaimer: string,
    addressChangeDisclaimer: string,
    firstNameRequired: boolean,
    lastNameRequired: boolean,
    emailRequired: boolean,
    phoneNumberRequired: boolean,
    newAddressRequired: boolean,
    cityRequired: boolean,
    stateRequired: boolean,
    zipCodeRequired: boolean,
    checkboxDisclaimerRequired: boolean,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
}

export const OWNER_LABEL = 'Owner Name';
export const TAX_YEAR_LABEL = 'Year';
export const BILL_NUMBER_LABEL = 'Account/PIN';
export const ADDRESS_LABEL = 'Description';
export const TAX_TYPE_LABEL = 'Type';
export const STATUS_LABEL = 'Paid';
export const PAID_DATE_LABEL = 'Paid Date';
export const ACTIONS_LABEL = 'Actions';
export const DISABLE_CART = false;
export const DELINQUENT_LABEL = 'All the following records must be paid';
export const DELINQUENT_BACKGROUND_COLOR = '#f0f0f0';
export const DELINQUENT_TEXT_ALIGN = 'center';
export const DELINQUENT_TEXT_COLOR = 'black';
export const STATUS_FILTER_LABEL = 'Status';
export const TAX_TYPE_FILTER_LABEL = 'Type';
export const TAX_YEAR_FILTER_LABEL = 'Years';
export const FIRST_NAME_LABEL = 'First Name';
export const LAST_NAME_LABEL = 'Last Name';
export const EMAIL_LABEL = 'Email';
export const PHONE_NUMBER_LABEL = 'Phone Number';
export const NEW_ADDRESS_LABEL = 'New Address';
export const CITY_LABEL = 'City';
export const STATE_LABEL = 'State';
export const ZIP_CODE_LABEL = 'Zip';
export const CHECK_BOX_DISCLAIMER = 'I certify under penalty of perjury under the laws of the State of Missouri that I am the tax payer of record for this tax bill and the foregoing submission is true and correct.'
export const ADDRESS_CHANGE_DISCLAIMER = 'The following is a web-based request feature to update your mailing address for your respective bill.'
export const FIRST_NAME_REQUIRED = false;
export const LAST_NAME_REQUIRED = false;
export const EMAIL_REQUIRED = false;
export const PHONE_NUMBER_REQUIRED = false;
export const NEW_ADDRESS_REQUIRED = false;
export const CITY_REQUIRED = false;
export const STATE_REQUIRED = false;
export const ZIP_CODE_REQUIRED = false;
export const CHECK_BOX_DISCLAIMER_REQUIRED = false;
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const StCharlesSearchResults = ({ ownerLabel, taxYearLabel, billNumberLabel, taxTypeLabel, addressLabel, statusLabel, paidDateLabel, statusFilterLabel, taxTypeFilterLabel, addressChangeDisclaimer,
    taxYearFilterLabel, actionsLabel, disableCart, delinquentLabel, delinquentBackgroundColor, delinquentTextAlign, delinquentTextColor, firstNameLabel, lastNameLabel, emailLabel, phoneNumberLabel, newAddressLabel, cityLabel, stateLabel, zipLabel, checkboxDisclaimer,
    firstNameRequired, lastNameRequired, emailRequired, phoneNumberRequired, newAddressRequired, cityRequired, stateRequired, zipCodeRequired, checkboxDisclaimerRequired,
    margin, padding, headerBackgroundColor, headerPadding }: IStCharlesSearchResults) => {


    if (!ownerLabel) { taxTypeLabel = OWNER_LABEL }
    if (!taxYearLabel) { taxTypeLabel = TAX_YEAR_LABEL }
    if (!billNumberLabel) { billNumberLabel = BILL_NUMBER_LABEL }
    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!paidDateLabel) { paidDateLabel = PAID_DATE_LABEL }
    if (!statusFilterLabel) { statusFilterLabel = STATUS_FILTER_LABEL }
    if (!taxTypeFilterLabel) { taxTypeFilterLabel = TAX_TYPE_FILTER_LABEL }
    if (!taxYearFilterLabel) { taxYearFilterLabel = TAX_YEAR_FILTER_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!disableCart) { disableCart = DISABLE_CART }
    if (!delinquentLabel) { delinquentLabel = DELINQUENT_LABEL }
    if (!delinquentBackgroundColor) { delinquentBackgroundColor = DELINQUENT_BACKGROUND_COLOR }
    if (!delinquentTextAlign) { delinquentTextAlign = DELINQUENT_TEXT_ALIGN }
    if (!delinquentTextColor) { delinquentTextColor = DELINQUENT_TEXT_COLOR}
    if (!firstNameLabel) { firstNameLabel = FIRST_NAME_LABEL }
    if (!lastNameLabel) { lastNameLabel = LAST_NAME_LABEL }
    if (!emailLabel) { emailLabel = EMAIL_LABEL }
    if (!phoneNumberLabel) { phoneNumberLabel = PHONE_NUMBER_LABEL }
    if (!newAddressLabel) { newAddressLabel = NEW_ADDRESS_LABEL }
    if (!cityLabel) { cityLabel = CITY_LABEL }
    if (!stateLabel) { stateLabel = STATE_LABEL }
    if (!zipLabel) { zipLabel = ZIP_CODE_LABEL }
    if (!checkboxDisclaimer) { checkboxDisclaimer = CHECK_BOX_DISCLAIMER }
    if (!addressChangeDisclaimer) { addressChangeDisclaimer = ADDRESS_CHANGE_DISCLAIMER }
    if (!firstNameRequired) { firstNameRequired = FIRST_NAME_REQUIRED }
    if (!lastNameRequired) { lastNameRequired = LAST_NAME_REQUIRED }
    if (!emailRequired) { emailRequired = EMAIL_REQUIRED }
    if (!phoneNumberRequired) { phoneNumberRequired = PHONE_NUMBER_REQUIRED }
    if (!newAddressRequired) { newAddressRequired = NEW_ADDRESS_REQUIRED }
    if (!cityRequired) { cityRequired = CITY_REQUIRED }
    if (!stateRequired) { stateRequired = STATE_REQUIRED }
    if (!zipCodeRequired) { zipCodeRequired = ZIP_CODE_REQUIRED }
    if (!checkboxDisclaimerRequired) { checkboxDisclaimerRequired = CHECK_BOX_DISCLAIMER_REQUIRED }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const initialColumns = [{
        dataField: 'owner',
        text: ownerLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'taxYear',
        text: taxYearLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'billNumber',
        text: billNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'address',
        text: addressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'taxType',
        text: taxTypeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'paidDate',
        text: paidDateLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const filterLabels = [{ label: statusFilterLabel }, { label: taxTypeFilterLabel }, { label: taxYearFilterLabel }]

    const renderTable = () => {
        return (
            <Table
                keyField="msbId"
                data={[]}
                columns={initialColumns}
            />);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <div style={{ display: 'flex' }}>
                <Row style={{ marginLeft: '10px', flex: '1 1 0' }}>
                    {filterLabels.map((filters) => (
                        <div>
                            <h3 style={{ fontWeight: 'bold' }}>{filters?.label}</h3>
                            <label style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <input type='checkbox' style={{ marginRight: '10px' }} />Option 1</label>
                        </div>
                    ))}
                </Row>
                <Row style={{ flex: '4 1 0' }}>
                    <Col>
                        {renderTable()}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default StCharlesSearchResults;