import axios from 'axios';
import { Client, ClientBanking, ClientStatusEnum, ClientStatusRequest } from '../../../models/Client';
import { Dispatch } from 'react';
import store from "../../../redux/store";
import { IPageableResults, PageableResults } from "../../../models/PageableResults"

export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';

export const SAVE_CLIENT_REQUEST = 'SAVE_CLIENT_REQUEST';
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS';
export const SAVE_CLIENT_FAILURE = 'SAVE_CLIENT_FAILURE';

export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';

export const GET_CLIENT_NAMES_REQUEST = 'GET_CLIENT_NAMES_REQUEST';
export const GET_CLIENT_NAMES_SUCCESS = 'GET_CLIENT_NAMES_SUCCESS';
export const GET_CLIENT_NAMES_FAILURE = 'GET_CLIENT_NAMES_FAILURE';

export const GET_CLIENT_TIMEZONE_REQUEST = 'GET_CLIENT_TIMEZONE_REQUEST';
export const GET_CLIENT_TIMEZONE_SUCCESS = 'GET_CLIENT_TIMEZONE_SUCCESS';
export const GET_CLIENT_TIMEZONE_FAILURE = 'GET_CLIENT_TIMEZONE_FAILURE';

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';

export const GET_ALL_CLIENTS_REQUEST = 'GET_ALL_CLIENTS_REQUEST';
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE';

export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE';

export const SAVE_CLIENT_STATUS_REQUEST = 'SAVE_CLIENT_STATUS_REQUEST';
export const SAVE_CLIENT_STATUS_SUCCESS = 'SAVE_CLIENT_STATUS_SUCCESS';
export const SAVE_CLIENT_STATUS_FAILURE = 'SAVE_CLIENT_STATUS_FAILURE';

export const RESET_STORE_REQUEST = 'RESET_STORE_REQUEST';
export const RESET_STORE_SUCCESS = 'RESET_STORE_SUCCESS';

export const SET_CLIENT_BANKING_DETAILS = 'SET_CLIENT_BANKING_DETAILS';
export const RESET_CLIENT_BANKING_DETAILS = 'RESET_CLIENT_BANKING_DETAILS';

export const SAVE_CLIENT_BANKING_REQUEST = 'SAVE_CLIENT_BANKING_REQUEST';
export const SAVE_CLIENT_BANKING_SUCCESS = 'SAVE_CLIENT_BANKING_SUCCESS';
export const SAVE_CLIENT_BANKING_FAILURE = 'SAVE_CLIENT_BANKING_FAILURE';

export const DELETE_CLIENT_BANKING_REQUEST = 'DELETE_CLIENT_BANKING_REQUEST';
export const DELETE_CLIENT_BANKING_SUCCESS = 'DELETE_CLIENT_BANKING_SUCCESS';
export const DELETE_CLIENT_BANKING_FAILURE = 'DELETE_CLIENT_BANKING_FAILURE';

export const addClientAction = (client: Client, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_CLIENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Clients"

    axios.post(_url, client)
        .then(function (response) {
            client = response.data;
            dispatch({
                type: ADD_CLIENT_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: ADD_CLIENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveClientAction = (client: Client, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CLIENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Clients/" + client.msbId;

    axios.put(_url, client)
        .then(function (response) {
            client = response.data;

            dispatch({
                type: SAVE_CLIENT_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_CLIENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const deleteClientAction = (msbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_CLIENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Clients/" + msbId;

    axios.delete(_url)
        .then(function (response) {
            let client = response.data;
            dispatch({
                type: DELETE_CLIENT_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_CLIENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const getClientNamesAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_CLIENT_NAMES_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;

    let _url = baseApiUrl + "/MSB_Client/api/v1/Clients/Names";

    axios.get(_url)
        .then(function (response) {
            let clientNames = response.data;
            dispatch({
                type: GET_CLIENT_NAMES_SUCCESS,
                payload: {
                    clientNames,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CLIENT_NAMES_SUCCESS,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getClientTimezoneAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_CLIENT_TIMEZONE_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;

    let _url = baseApiUrl + "/MSB_Client/api/v1/Clients/getClientsWithTimeZones";

    axios.get(_url)
        .then(function (response) {
            let clientTimezones = response.data;
            dispatch({
                type: GET_CLIENT_TIMEZONE_SUCCESS,
                payload: {
                    clientTimezones,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CLIENT_TIMEZONE_SUCCESS,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getAllClientsAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_ALL_CLIENTS_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Client/api/v1/Clients";

    axios.get(_url)
        .then(function (response) {
            let clients = response.data;
            dispatch({
                type: GET_ALL_CLIENTS_SUCCESS,
                payload: {
                    clients,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_ALL_CLIENTS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
}

export const getClientsAction = (page:number, pageSize:number, searchBusinessName?:string, searchStatusEnum?:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_CLIENTS_REQUEST,
        payload: {
            actionToken
        }
    });

    let results: IPageableResults<Client> = new PageableResults<Client>();

    results.totalRecords = 0;
    results.data = new Array<Client>();
   
    let _url = `/MSB_Client/api/v1/Clients`;

    axios.get(_url)
        .then(function (response) {
            results.data = response?.data;
            results.totalRecords = response?.data?.length;
            dispatch({
                type: GET_CLIENTS_SUCCESS,
                payload: {
                    results,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CLIENTS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getClientAction = (msbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_CLIENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Clients/" + msbId;
    let client = new Client();

    axios.get(_url)
        .then(function (response) {
            client = response.data;
            dispatch({
                type: GET_CLIENT_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CLIENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const saveClientStatusAction = (client:Client, clientStatusEnum:ClientStatusEnum, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CLIENT_STATUS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Clients/Status/" + client.msbId

    let clientStatusRequest = new ClientStatusRequest();
    clientStatusRequest.clientMsbId = client.msbId!;
    //clientStatusRequest.clientStatusEnum = clientStatusEnum;

    axios.put(_url, clientStatusRequest)
        .then(function (response) {
            client = response.data;
            dispatch({
                type: SAVE_CLIENT_STATUS_SUCCESS,
                payload: {
                    clientStatusEnum,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_CLIENT_STATUS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
    
};

export const setClientBankingDetails = (clientBanking: ClientBanking,actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_CLIENT_BANKING_DETAILS,
        payload: {
            clientBanking: clientBanking,
            actionToken: actionToken
        }
    });

}

export const resetClientBankingDetails = (actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: RESET_CLIENT_BANKING_DETAILS,
        payload: {
            actionToken: actionToken
        }
    });

}

export const saveClientBankingAction = (clientBanking:ClientBanking, actionToken?:string) => (dispatch: Dispatch<any>) => {
    console.log(actionToken)
    dispatch({
        type: SAVE_CLIENT_BANKING_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/ClientBankAccounts"
    
    if (clientBanking.msbId === undefined) {
        axios.post(_url, clientBanking)
            .then(function (response) {
                clientBanking = response.data;

                dispatch({
                    type: SAVE_CLIENT_BANKING_SUCCESS,
                    payload: {
                        actionToken
                    }
                });

            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_CLIENT_BANKING_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    } else {
        _url = _url + "/" + clientBanking.msbId;

        axios.put(_url, clientBanking)
            .then(function (response) {
                clientBanking = response.data;
                dispatch({
                    type: SAVE_CLIENT_BANKING_SUCCESS,
                    payload: {
                        actionToken
                    }
                });

            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_CLIENT_BANKING_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    }
};

export const deleteClientBankingAction = (msbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: DELETE_CLIENT_BANKING_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/ClientBankAccounts/" + msbId;

    axios.delete(_url)
        .then(function (response) {
            let client = response.data;
            dispatch({
                type: DELETE_CLIENT_BANKING_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            console.log('hello delete')
            dispatch({
                type: DELETE_CLIENT_BANKING_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};


export const resetClientsStoreAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: RESET_STORE_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: RESET_STORE_SUCCESS,
        payload: {
            actionToken
        }
    });
};