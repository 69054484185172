import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IActionResult, IAppState } from '../../../redux/storeTypes';
import { Dropdown as BootstrapDropdown, Spinner } from 'react-bootstrap';
import Table from '../../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../../components/Formatters';
import { Modal, Form, Button, Image } from 'react-bootstrap';
import RequiredIcon from '../../../components/RequiredIcon';
// import Dropdown from 'react-dropdown';
import { IPageableResults, PageableResults } from "../../../models/PageableResults";
import { approveApprovalAction, APPROVE_PENDING_APPROVAL_FAILURE, APPROVE_PENDING_APPROVAL_REQUEST, APPROVE_PENDING_APPROVAL_SUCCESS, getPendingApprovalsAction, rejectApprovalAction, REJECT_PENDING_APPROVAL_FAILURE, REJECT_PENDING_APPROVAL_REQUEST, REJECT_PENDING_APPROVAL_SUCCESS } from '../../../redux/actions/pendingApprovals';
import { sendErrorToastAction, sendSuccessToastAction, sendWarningToastAction } from '../../../redux/actions/toast';
import { Routes } from "../../../routes";
import { Redirect } from 'react-router-dom';
import 'react-dropdown/style.css';
import Approval, { ApprovalRejectedRequest, ApprovalTypeEnum } from '../../../models/Approval';
import FormHeaderConfirmation from '../../../components/FormHeaderConfirmation';
import icoError from "../../../assets/img/icons/ico-error-outline.svg";

interface PendingProps {
    currentPage: IPageableResults<Approval>,
    isFetching: boolean,
    isSaving: boolean,
    actionResult: IActionResult
}

const Pending = ({ currentPage, isFetching, isSaving, actionResult }: PendingProps) => {
    const actionToken = "PendingApprovals";
    const [redirect, setRedirect] = useState<string>("");
    const [showModal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [validated, setValidated] = useState(false);
    const [approval, setApproval] = useState<Approval>();
    const dispatch = useDispatch();
    
    const [sizePerPage, setSizePerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(1);
    const [data, setData] = useState<Array<any>>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);

    useEffect(() => {
        if (currentPage != null) {
            setData(currentPage.data);
            setPage(currentPage.page);
            setTotalRecords(currentPage.totalRecords);
        }
    }, [currentPage]);

    useEffect(() => {
        dispatch(getPendingApprovalsAction(page, sizePerPage, actionToken));
    }, []);

    const onTableChange = (type: string, { page, sizePerPage }: any) => {
        setSizePerPage(sizePerPage);
        setPage(page);
        dispatch(getPendingApprovalsAction(page, sizePerPage, actionToken));
    }

    const dropDownOptions = (cell: any, row: any) => {
        return (
            <BootstrapDropdown className="req-action-dropdown">
                <BootstrapDropdown.Toggle className="approvalAction" variant="link">
                    Manage
                </BootstrapDropdown.Toggle>
                <BootstrapDropdown.Menu>
                    <BootstrapDropdown.Item onSelect={() => actionsOperations(row, "Approve")}>Approve</BootstrapDropdown.Item>
                    <BootstrapDropdown.Item onSelect={() => actionsOperations(row, "Deny")}>Deny</BootstrapDropdown.Item>
                </BootstrapDropdown.Menu>
            </BootstrapDropdown>
        )
    }

    const actionsOperations = (approval: Approval, operation: any) => {
        if (operation === "Approve") {
            dispatch(approveApprovalAction(approval.msbId, actionToken));
        }
        else {
            setApproval(approval);
            setModalTitle(approval.referenceName);
            setModal(true);
        }
    }

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === APPROVE_PENDING_APPROVAL_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === APPROVE_PENDING_APPROVAL_SUCCESS) {
                    dispatch(sendSuccessToastAction("Approval successfully approved."));
                } else if (actionResult.result === APPROVE_PENDING_APPROVAL_FAILURE) {
                    dispatch(sendErrorToastAction("Approval could not be approved."));
                }
            } else if (actionResult.type === REJECT_PENDING_APPROVAL_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === REJECT_PENDING_APPROVAL_SUCCESS) {
                    dispatch(sendSuccessToastAction("Approval successfully denied."));
                    setModal(false);
                } else if (actionResult.result === REJECT_PENDING_APPROVAL_FAILURE) {
                    dispatch(sendErrorToastAction("Approval could not be denied."));
                }
            }
        }
    }, [actionResult]);  

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() !== false) {
            let approvalRejectedRequest = new ApprovalRejectedRequest();
            approvalRejectedRequest.approvalMsbId = approval!.msbId;
            approvalRejectedRequest.comments = form.elements.denyReason.value;
            dispatch(rejectApprovalAction(approvalRejectedRequest, actionToken));
        }
        setValidated(true);
    };

    const ApprovalTypeEnumFormatter = (cell: any, approval: Approval) => {
        var value = "";
        if (approval.approvalTypeEnum == ApprovalTypeEnum.SetupApproval) {
            value = "Client Setup";
        } else if (approval.approvalTypeEnum == ApprovalTypeEnum.ClientBankAccount) {
            value = "Client Bank Account"
        }

        return (
            <>{value}</>
        )
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        },
            {
            dataField: 'approvalTypeEnum',
            text: 'Type',
            editable: false,
            formatter: ApprovalTypeEnumFormatter
        },
        {
            dataField: 'referenceName',
            text: 'Request',
            editable: false,
        },
        {
            dataField: 'createdByUser',
            text: 'Submitted by',
            editable: false
        },
        {
            dataField: 'createdAt',
            text: 'Submitted date',
            editable: false,
            formatter: DateFormatter
        },
        {
            dataField: 'id',
            text: 'Actions',
            editable: false,
            formatter: dropDownOptions
        }
    ];

    // Custom Pagination total
    const customTotal = (from: number, to: number, size: number) => (
      <span className="react-bootstrap-table-pagination-total">
          Showing { from } to { to } of { size } Results
      </span>
    );

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                {isFetching ?
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner> :
                    <div>
                        {data ?
                            <Table
                              keyField='msbId'
                              data={data}
                              columns={columns}
                              onTableChange={onTableChange}
                              defaultSorted={[ { dataField: 'createdAt', order: 'desc'}]}
                              sizePerPage={sizePerPage}
                              page={page}
                              totalSize={totalRecords}
                            /> : null}
                        <Modal show={showModal} className="modal-confirmation">
                            <Modal.Body>
                                <FormHeaderConfirmation iconImg={icoError} title={modalTitle} />

                                <div className="confirmation-body">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group controlId="denyReason">
                                            <Form.Label><RequiredIcon />Denied reason</Form.Label>
                                            <Form.Control required as="textarea" rows={3} placeholder="Please enter a denied reason" />
                                            <Form.Control.Feedback type="invalid">Please enter a denied reason.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="apprvlReqDenyAction" className="form-footer">
                                            <Button variant="outline-secondary" onClick={() => setModal(false)}>
                                                Cancel
                                            </Button>
                                            <Button type="submit">
                                                Deny
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                }
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        currentPage: state.pendingApprovals.currentPage,
        isFetching: state.pendingApprovals.isFetching,
        isSaving: state.pendingApprovals.isSaving,
        actionResult: state.pendingApprovals.actionResult,
    };
};

export default connect(mapStateToProps)(Pending);