

import { Modal } from "react-bootstrap";
import FormHeaderConfirmation from "../../../components/FormHeaderConfirmation";
import { PaymentTypeEnum } from "../../../models/Payment";
import icoWarning from "../../../assets/img/icons/ico-warning-outline.svg";

interface IOrderConfirmationModal {
    paymentType: PaymentTypeEnum|undefined,
    show:boolean,
    setShow:any
}

const OrderConfirmationModal = ({ paymentType, show, setShow }: IOrderConfirmationModal) => {

    return (
        <Modal show={show} onHide={() => setShow(false)} backdrop="static" className="modal-confirmation">
            <Modal.Body>
               <FormHeaderConfirmation iconImg={icoWarning} title="Order submission in progress" />
                <div className="confirmation-body">
                    {paymentType && paymentType === PaymentTypeEnum.CreditCardTerminal?
                    <p>
                        Please have the customer insert or swipe their credit card into the terminal and
                        accept the payment terms.
                    </p>
                  :
                  <></>
                  }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default OrderConfirmationModal;