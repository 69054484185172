import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DateFormatter } from '../../../../components/Formatters';

// use a type here, not an interface
type Data = {
  label: string,
  tag: string,
  value: string
}

const TransactionDateTimePropertyPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <Row>
        <Col><strong>{data.label}</strong></Col>
        <Col>{DateFormatter(data.value)}</Col>
      </Row>
  ),
  id: 'transactionDateTimePropertyPlugin',
  title: 'Transaction Date and Time Property',
  description: 'Transaction Date and Time Property.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        label: {
          type: 'string',
          default: 'Transaction Date/Time',
            },
        tag: {
            type: 'string',
            default: 'Transaction Date/Time',
            readOnly: true
        },
      },
      required: ['label'],
    },
  },
};

export default TransactionDateTimePropertyPlugin;