import { CellPlugin } from '@react-page/editor';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import RequiredIcon from '../../../../../components/RequiredIcon';

type Option = {
    value: string,
    label: string,
}

type Data = {
    name: string,
    label: string,
    required: boolean,
    options: Option[],
    inline: boolean,
    margin: string,
    padding: string,
    fullStoryMask: boolean,
}

const LABEL = 'Option';
const NAME = 'option';
const REQUIRED = false;
const MARGIN = '0px';
const PADDING = '0px';
const FULL_STORY_MASK = false;

const CheckboxPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => {
        const [state, setState] = useState({});
        const [selected, setSelected] = useState(false);
        const handleChange = (e: any) => {
            e.persist();
            setState(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
        }
        useEffect(() => {
            let _selected = false;
            Object.keys(state).map(function (key, index) {
                if (state[key as keyof typeof state]) _selected = true;
            });
            if (selected != _selected) setSelected(_selected);
        }, [state]);
        return (
            <Form.Group style={{ margin: data.margin, padding: data.padding, alignItems: 'flex-end' }} className={data.fullStoryMask ? "fs-mask" : ""}>
                <Form.Label>{(data.required) ? <RequiredIcon /> : ''}{data.label}</Form.Label>
                {data.options && data.options.map((option, index) => {
                    return (
                        <Form.Check id={`cmd_${data.name}-${option.value}`} key={index} type={'checkbox'} inline={data.inline} name={option.value} value={state[option.value as keyof typeof state] ? 'yes' : 'no'} label={option.label} onClick={handleChange} required={data.required && !selected} />
                    );
                })}
                <Form.Control hidden={true} required={data.required && !selected} />
                <Form.Control.Feedback type="invalid">Please choose a {data.label}.</Form.Control.Feedback>
            </Form.Group>
        );
    },
    id: 'CheckboxPlugin',
    title: 'Checkbox',
    description: 'Add a checkbox component and define its options.',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        label: {
                            type: 'string',
                            default: LABEL,
                        },
                        name: {
                            type: 'string',
                            default: NAME,
                        },
                        required: {
                            type: 'boolean',
                            default: REQUIRED,
                        },
                        fullStoryMask:{
                            type: 'boolean',
                            default: FULL_STORY_MASK
                        },
                        options: {
                            type: 'array',
                            items: {
                                type: 'object',
                                default: {
                                    value: 'option-1',
                                    label: 'Option 1',
                                },
                                properties: {
                                    value: {
                                        type: 'string',
                                    },
                                    label: {
                                        type: 'string',
                                    },
                                },
                                required: [],
                            },
                        },
                        inline: {
                            type: 'boolean',
                            default: true,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                    },
                    required: [],
                },
            },
        },
    ]
};

export default CheckboxPlugin;