import { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ReportType } from '../../../models/Reports';
import { User } from '../../../models/User';
import { isTablePreference, setReportColumns, updateTableConfigurationAction } from '../../../redux/actions/reports/report';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

interface ITableConfiguration {
    columns: any;
    reportType: ReportType;
    closeModal: () => void;
    currentUser: User;
    isSaving: boolean;
}

const TableConfiguration = ({ columns, reportType, currentUser, closeModal, isSaving }: ITableConfiguration) => {
    const dispatch = useDispatch();
    const [orderedColumns, setOrderedColumns] = useState(columns);

    useEffect(() => {
        if (columns) {
            const sortedColumns = [...columns].sort((a: any, b: any) => (b.default ? 1 : 0) - (a.default ? 1 : 0));
            setOrderedColumns(sortedColumns);
        }
    }, [columns]);

    const handleCheckboxChange = (index: number) => {
        const newColumns = [...orderedColumns];
        newColumns[index].default = !newColumns[index].default;
        const sortedColumns = newColumns.sort((a, b) => (b.default ? 1 : 0) - (a.default ? 1 : 0));
        setOrderedColumns(sortedColumns);
        dispatch(setReportColumns(reportType, sortedColumns));
        dispatch(isTablePreference(reportType, true))
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedColumns = Array.from(orderedColumns);
        const [removed] = reorderedColumns.splice(result.source.index, 1) as any;
        reorderedColumns.splice(result.destination.index, 0, removed);

        const destinationIndex = result.destination.index;
        const destinationColumn = reorderedColumns[destinationIndex - 1] as any;

        const firstCheckedIndex = reorderedColumns.findIndex((col: any) => col.default);

        removed.default = removed.default
            ? (destinationColumn && !destinationColumn.default ? false : true)
            : (destinationColumn && destinationColumn.default ? true : (firstCheckedIndex !== -1 && destinationIndex > firstCheckedIndex ? false : removed.default));

        const sortedColumns = reorderedColumns.sort((a: any, b: any) => (b.default ? 1 : 0) - (a.default ? 1 : 0));
        setOrderedColumns(sortedColumns);
        dispatch(setReportColumns(reportType, sortedColumns));
        dispatch(isTablePreference(reportType, true))
    };


    const handleSaveOnClick = () => {
        const selectedColumns = {
            pageName: reportType,
            columns: _.chain(orderedColumns)
                .filter(item => item.default)
                .map((item) => ({
                    propertyName: item.dataField,
                    columnName: item.text
                }))
                .value()
        };
        dispatch(updateTableConfigurationAction(currentUser?.msbId, selectedColumns, 'userPreference'));
    }

    return (
        <div>
            <Row style={{ marginBottom: '24px' }}>
                <h2 style={{ marginBottom: '4px' }}>Configure report table columns</h2>
                <p>Customize your report table by checking or unchecking columns below and rearranging them with drag and drop</p>
            </Row>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="columns">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {orderedColumns.map((column: any, index: number) => {
                                if (column?.configurable) {
                                    const draggableId = `${column.dataField}-${index}`;
                                    return (
                                        <Draggable
                                            key={draggableId}
                                            draggableId={draggableId}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="mb-2 p-2 border"
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        backgroundColor: '#f8f9fa',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={column.dataField}
                                                        label={column.text}
                                                        checked={column.default}
                                                        onChange={() => handleCheckboxChange(index)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                }
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className="manage-footer">
                <Form.Group className="form-footer justify-content-between">
                    <Button type="button" onClick={handleSaveOnClick} disabled={isSaving}>
                        Save
                    </Button>
                    <Button variant="link" onClick={closeModal}>
                        Cancel
                    </Button>
                </Form.Group>
            </div>
        </div>
    );
};

export default TableConfiguration;
