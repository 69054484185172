import React, { useState, useEffect, useRef } from "react";
import { Tab, Nav, Form, Button } from 'react-bootstrap';

import { Client } from '../../../models/Client';
import { IActionResult } from '../../../redux/storeTypes';
import ClientDepartmentsSection from "./manageClientSections/ClientDepartmentsSection";

import ClientDetailsSection from './manageClientSections/ClientDetailsSection';
import DepartmentAccountingSection from './manageClientSections/DepartmentAccountingSection';
import ClientAddressesSection from './manageClientSections/ClientAddressesSection';
import ClientContactsSection from './manageClientSections/ClientContactsSection';
import ClientMerchantsSection from './manageClientSections/ClientMerchantsSection';
import ClientAgreementSection from './manageClientSections/ClientAgreementSection';
import ApprovalChecklistSection from "./manageClientSections/ApprovalChecklistSection";
import ManageUsersSection from "./manageClientSections/ManageUsersSection";
import ManageConstituentsSection from "./manageClientSections/ManageConstituentsSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { ClientManagementTabsHeaders } from "./manageClientSections/ClientManagementTabHeaders";

export interface IClientManagementSectionProps {
    client: Client,
    isFetching: boolean,
    isSaving: boolean,
    actionResult: IActionResult
}

export interface IClientManagementProps {
    client: Client
}

const ClientManagement = ({ client }: IClientManagementProps) => {
  const ref = useRef(null);
  
  const scroll = (scrollOffset: any) => {
    const node: any = ref.current!;
    node.scrollLeft += scrollOffset;
  };

  return (
    <Tab.Container defaultActiveKey="manage_details">

        {/* BEGIN: Tabs */}
        <div className="tab-nav d-flex px-0">
          <Button variant="link" onClick={() => scroll(-300)} className="btn-scroll-left">
            <FontAwesomeIcon icon={faChevronLeft} size="xs" />
          </Button>
          <div className="scrollbarContainer" ref={ref}>
            <Nav className="flex-row flex-nowrap">
              {Object.entries(ClientManagementTabsHeaders).map(
                ([index, value]: any) => {
                  return (
                    <Nav.Item>
                      <Nav.Link className="tabWidth" eventKey={value.key}>{value.title}</Nav.Link>
                    </Nav.Item>
                  )
                }
              )}
            </Nav>
          </div>
          <Button variant="link" onClick={() => scroll(300)} className="btn-scroll-right">
            <FontAwesomeIcon icon={faChevronRight} size="xs" />
          </Button>
        </div>
        {/* END: Tabs */}

        <Tab.Content>
            <Tab.Pane eventKey="manage_details">
                <ClientDetailsSection client={client} />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_addresses">
                <ClientAddressesSection client={client} />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_contacts">
                <ClientContactsSection client={client} />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_merchants">
                <ClientMerchantsSection client={client} />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_departments">
                <ClientDepartmentsSection client={client} />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_users">
                <ManageUsersSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_constituents">
                <ManageConstituentsSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_agreement">
                {/*
                <ClientAgreementSection client={client} />
                */}
            </Tab.Pane>
            <Tab.Pane eventKey="manage_checklist">
                <ApprovalChecklistSection />
            </Tab.Pane>
        </Tab.Content>
    </Tab.Container>
  )
}

export default ClientManagement;