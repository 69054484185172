import {
    NEXT_STEP, PREVIOUS_STEP, CANCEL_STEP,
    ONBOARDING_STEPS,
    DEPT_ONBOARDING_STEPS,
    CLIENT_DETAILS_STEP, SET_STEP, OnboardingTypeEnum
} from '../actions/clients/clientOnboarding'

export default function clientOnboarding(state = {
    errorMessage: '',
    nextStep: CLIENT_DETAILS_STEP,
    loadingInit: true,
}, { type, payload }) {
    let stepIndex = -1;
    let nextStep = '';

    switch (type) {
        case NEXT_STEP:
            if (true) {
                let steps = ONBOARDING_STEPS;
                if (payload.onboardingTypeEnum === OnboardingTypeEnum.Department) {
                    steps = DEPT_ONBOARDING_STEPS;
                }

                stepIndex = steps.indexOf(state.nextStep);

                if (stepIndex < steps.length - 1) {
                    stepIndex = stepIndex + 1;
                    nextStep = steps[stepIndex];
                }

                return Object.assign({}, state, {
                    nextStep: nextStep,
                });
            }
        case PREVIOUS_STEP:
            if (true) {
                console.log('PREVIOUS_STEP');
                let steps = ONBOARDING_STEPS;
                if (payload.onboardingTypeEnum === OnboardingTypeEnum.Department) {
                    steps = DEPT_ONBOARDING_STEPS;
                }

                stepIndex = steps.indexOf(state.nextStep);

                if (stepIndex >= 1) {
                    stepIndex = stepIndex - 1;
                    nextStep = steps[stepIndex];
                }

                return Object.assign({}, state, {
                    nextStep: nextStep,
                });
            }
        case CANCEL_STEP:
            return Object.assign({}, state, {
                errorMessage: '',
                nextStep: CLIENT_DETAILS_STEP,
                loadingInit: true,
            });
        case SET_STEP:
            return Object.assign({}, state, {
                errorMessage: '',
                nextStep: payload.step,
                loadingInit: true,
            });
        default:
            return state;
    }
}
