import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { SAVE_DEPARTMENT_FAILURE, SAVE_DEPARTMENT_REQUEST, SAVE_DEPARTMENT_SUCCESS } from "../../../../redux/actions/clients/departments";
import { IAppState } from '../../../../redux/storeTypes';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { IClientManagementSectionProps } from '../ClientManagement';
import { Routes } from '../../../../routes'
import { Redirect } from 'react-router-dom';
import Merchants from "../../Merchants";

const ClientMerchantsSection = ({ isFetching, isSaving, client, actionResult }: IClientManagementSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<boolean>(false);

    let actionToken = "ClientMerchantsStep";

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_DEPARTMENT_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_DEPARTMENT_SUCCESS) {
                dispatch(sendSuccessToastAction("Client details were successfully updated."));
            } else if (actionResult.result === SAVE_DEPARTMENT_FAILURE) {
                dispatch(sendErrorToastAction("Client details could not be updated."));
            }
        }
    }, [actionResult]);

    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />; //route to departments path
    } else {
        return (
            <Merchants
                mode={""}
                client={client}
                actionResult={actionResult}
                formActionProps={{
                    disabled: isSaving,
                    showSpinner: isFetching,
                    hasSubmit: true,
                    onCancel: () => setRedirect(true),
                    customProps: {
                        submitButtonDisplay: "Save"
                    }
                }}
            />
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(ClientMerchantsSection);
