import axios from 'axios';
import { Dispatch } from 'react';

import { FundingMethodEnum, DebitFeeMethodEnum } from '../../../models/Client';
import { MsbReportItem, ReportType, WorldpayTransaction } from '../../../models/Reports';
import store from "../../store";
import { GET_ORDER_TRANSACTION_FAILURE } from '../orderTransactions';
import moment from 'moment';
import { timeZonesMap } from '../../../components/Formatters';

export const GET_DYNAMIC_QUERY_REPORT_REQUEST = 'GET_DYNAMIC_QUERY_REPORT_REQUEST';
export const GET_DYNAMIC_QUERY_REPORT_SUCCESS = 'GET_DYNAMIC_QUERY_REPORT_SUCCESS';
export const GET_DYNAMIC_QUERY_REPORT_FAILURE = 'GET_DYNAMIC_QUERY_REPORT_FAILURE';

export const GET_DYNAMIC_QUERY_REPORT_PAYMENTS_REQUEST = 'GET_DYNAMIC_QUERY_REPORT_PAYMENTS_REQUEST';
export const GET_DYNAMIC_QUERY_REPORT_PAYMENTS_SUCCESS = 'GET_DYNAMIC_QUERY_REPORT_PAYMENTS_SUCCESS';
export const GET_DYNAMIC_QUERY_REPORT_PAYMENTS_FAILURE = 'GET_DYNAMIC_QUERY_REPORT_PAYMENTS_FAILURE';

export const GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_REQUEST = 'GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_REQUEST';
export const GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_SUCCESS = 'GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_SUCCESS';
export const GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_FAILURE = 'GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_FAILURE';

export const GET_ORDER_TRX_DETAILS_REQUEST = 'GET_ORDER_TRX_DETAILS_REQUEST';
export const GET_ORDER_TRX_DETAILS_SUCCESS = 'GET_ORDER_TRX_DETAILS_SUCCESS';
export const GET_ORDER_TRX_DETAILS_FAILURE = 'GET_ORDER_TRX_DETAILS_FAILURE';

export const GET_WORLDPAY_TRANSACTIONS_REQUEST = 'GET_WORLDPAY_TRANSACTIONS_REQUEST';
export const GET_WORLDPAY_TRANSACTIONS_SUCCESS = 'GET_WORLDPAY_TRANSACTIONS_SUCCESS';
export const GET_WORLDPAY_TRANSACTIONS_FAILURE = 'GET_WORLDPAY_TRANSACTIONS_FAILURE';

export const SET_REPORT_COLUMNS = 'SET_REPORT_COLUMNS';
export const SET_FUNDING_DETAILS_DATA = 'SET_FUNDING_DETAILS_DATA';
export const SET_RECON_DETAILS_DATA = 'SET_RECON_DETAILS_DATA';
export const SET_RECON_TRANSACTIONS_DATA = 'SET_RECON_TRANSACTIONS_DATA';
export const SET_WORLDPAY_TRANSACTION_DETAILS = 'SET_WORLDPAY_TRANSACTION_DETAILS';

export const UPDATE_USER_TABLE_PREFERENCES_REQUEST = 'UPDATE_USER_TABLE_PREFERENCES_REQUEST';
export const UPDATE_USER_TABLE_PREFERENCES_SUCCESS = 'UPDATE_USER_TABLE_PREFERENCES_SUCCESS';
export const UPDATE_USER_TABLE_PREFERENCES_FAILURE = 'UPDATE_USER_TABLE_PREFERENCES_FAILURE';

export const GET_USER_TABLE_PREFERENCES_REQUEST = 'GET_USER_TABLE_PREFERENCES_REQUEST';
export const GET_USER_TABLE_PREFERENCES_SUCCESS = 'GET_USER_TABLE_PREFERENCES_SUCCESS';
export const GET_USER_TABLE_PREFERENCES_FAILURE = 'GET_USER_TABLE_PREFERENCES_FAILURE';

export const GET_FUNDING_REPORT_REQUEST = 'GET_FUNDING_REPORT_REQUEST';
export const GET_FUNDING_REPORT_SUCCESS = 'GET_FUNDING_REPORT_SUCCESS';
export const GET_FUNDING_REPORT_FAILURE = 'GET_FUNDING_REPORT_FAILURE';

export const RESET_REPORT_STORE_REQUEST = 'RESET_REPORT_STORE_REQUEST';
export const RESET_REPORT_STORE_SUCCESS = 'RESET_REPORT_STORE_SUCCESS';

export const IS_TABLE_PREFERENCE = 'IS_TABLE_PREFERENCE';

export const setReportColumns = (reportType: ReportType, columns: Array<any>) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_REPORT_COLUMNS,
        payload: {
            reportType,
            columns
        }
    });
}

export const isTablePreference = (reportType: ReportType, updated: boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: IS_TABLE_PREFERENCE,
        payload: {
            reportType,
            updated
        }
    });
}

export const setFundingDetailsData = (data: MsbReportItem, startDate: string, endDate: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_FUNDING_DETAILS_DATA,
        payload: {
            data,
            transactionDate: { startDate, endDate }
        }
    });
}

export const getDynamicQueryReport = (
    fromDate: string,
    endDate: string,
    fundingType: FundingMethodEnum | undefined,
    debitFeeMethod: DebitFeeMethodEnum | undefined,
    state: string,
    departmentIds: Array<string>,
    actionToken: string,
    clientIds?: Array<string>,
    isFunding?: boolean,
) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_DYNAMIC_QUERY_REPORT_REQUEST,
        payload: {
            searchState: { startDate: fromDate, endDate, fundingType, debitFeeMethod, state, departmentIds },
            actionToken
        }
    });
    const modifiedUrl = isFunding ? "/MSB_Report/api/v1/ReportTransactionDetails/dynamicQuery" : "/MSB_Report/api/v1/report/dynamicQuery";
    const url = store.getState().webAppSettings.baseApiUrl + modifiedUrl;
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${store.getState().webAppSettings.bearerToken}`,
            MsbBearer: `${msbBearerToken}`
        },
        params: {
            "transactionDate:from": `${fromDate}T00:00:00.000<->${endDate}T23:59:59.999`,
            "isArchived": "false",
            ...(isFunding && { "orderStatus": 2 })
        }
    };

    axios.get(url, config)
        .then(function (response) {
            let summaryAllTransactions = response.data || [];
            if (!!fundingType) {
                summaryAllTransactions = summaryAllTransactions.filter((item: { fundingMethodEnum: number }) => item.fundingMethodEnum == fundingType);
            }
            if (!!debitFeeMethod) {
                summaryAllTransactions = summaryAllTransactions.filter((item: { debitFeeMethodEnum: number }) => item.debitFeeMethodEnum == debitFeeMethod);
            }
            if (!!state) {
                summaryAllTransactions = summaryAllTransactions.filter((item: { state: string }) => item.state == state);
            }
            if ((clientIds || []).length > 0) {
                summaryAllTransactions = summaryAllTransactions.filter((item: { clientId: string }) => (clientIds || []).includes(item.clientId));
            }
            if (departmentIds.length > 0) {
                summaryAllTransactions = summaryAllTransactions.filter((item: { departmentId: string }) => departmentIds.includes(item.departmentId));
            }

            dispatch({
                type: GET_DYNAMIC_QUERY_REPORT_SUCCESS,
                payload: {
                    summaryAllTransactions,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_DYNAMIC_QUERY_REPORT_FAILURE,
                payload: {
                    error: error,
                    actionToken
                }
            });
        });
};


export const getFundingReport = (
    fromDate: string,
    endDate: string,
    departmentIds: Array<string>,
    actionToken: string,
) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_FUNDING_REPORT_REQUEST,
        payload: {
            actionToken
        }
    });
    const Url = "/MSB_Report/api/v1/ReportTransactionDetails/dynamicQuery";
    const url = store.getState().webAppSettings.baseApiUrl + Url;
    let msbBearerToken = store.getState().auth.msbBearerToken;


    const config = {
        headers: {
            Authorization: `Bearer ${store.getState().webAppSettings.bearerToken}`,
            MsbBearer: `${msbBearerToken}`
        },
        params: {
            "transactionDate:from": `${fromDate}T00:00:00.000<->${endDate}T23:59:59.999`,
            "isArchived": "false",
            "orderStatus": 2
        }
    };

    axios.get(url, config)
        .then(function (response) {
            let fundingReportTransactions = response.data || [];
            if (departmentIds.length > 0) {
                fundingReportTransactions = fundingReportTransactions.filter((item: { departmentId: string }) => departmentIds.includes(item.departmentId));
            }

            dispatch({
                type: GET_FUNDING_REPORT_SUCCESS,
                payload: {
                    fundingReportTransactions,
                    actionToken
                }
            });
        })
        .catch(function (error: any) {
            console.log(error)
            dispatch({
                type: GET_FUNDING_REPORT_FAILURE,
                payload: {
                    error: error,
                    actionToken
                }
            });
        });
};


export const getDynamicQueryReportPayments = (createdAt: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_DYNAMIC_QUERY_REPORT_PAYMENTS_REQUEST,
        payload: {
            actionToken
        }
    });

    let url = `${store.getState().webAppSettings.baseApiUrl}/MSB_Report/api/v1/report/dynamicQuery?TransactionDate:from=${createdAt}&isArchived=false`;
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            MsbBearer: `${msbBearerToken}`
        }
    };

    axios.get(url, config)
        .then(function (response) {
            dispatch({
                type: GET_DYNAMIC_QUERY_REPORT_PAYMENTS_SUCCESS,
                payload: {
                    summaryAllTransactions: response.data,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_DYNAMIC_QUERY_REPORT_PAYMENTS_FAILURE,
                payload: {
                    error: error,
                    actionToken
                }
            });
        });
};

export const getDynamicQueryReportDeclinedPayments = (createdAt: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_REQUEST,
        payload: {
            actionToken
        }
    });

    let url = `${store.getState().webAppSettings.baseApiUrl}/MSB_Report/api/v1/ReportDeclinedPayments/dynamicQuery?TransactionDate:from=${createdAt}&isArchived=false`;
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            MsbBearer: `${msbBearerToken}`
        }
    };

    axios.get(url, config)
        .then(function (response) {
            dispatch({
                type: GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_SUCCESS,
                payload: {
                    summaryDeclinedTransactions: response.data,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_FAILURE,
                payload: {
                    error: error,
                    actionToken
                }
            });
        });
};


export const getFundingReportResults = (orderIdentifiers: any, date: any, clientTimezones: any, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_ORDER_TRX_DETAILS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Order/api/v1/OrderSummaries/fundingReportLookup";
    const targetDate = moment(date, 'MM-DD-YYYY').startOf('day'); // Ensure targetDate is at start of day

    const timezoneLookup = clientTimezones.reduce((acc: any, { clientId, timeZone }: any) => {
        acc[clientId] = timeZonesMap[timeZone] || moment.tz.guess();
        return acc;
    }, {});

    axios.post(_url, orderIdentifiers)
        .then(function (response) {
            const filtered = response.data || [];
            const transactions = filtered.filter((item: any) => {
                const clientTimezone = timezoneLookup[item.clientId] || moment.tz.guess();
                const transactionDate = item?.createdAt && moment.utc(item.createdAt).tz(clientTimezone).startOf('day');

                return transactionDate && transactionDate.format("YYYY-MM-DD") === targetDate.format("YYYY-MM-DD");
            });

            dispatch({
                type: GET_ORDER_TRX_DETAILS_SUCCESS,
                payload: {
                    transactions,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_ORDER_TRX_DETAILS_FAILURE,
                payload: {
                    error: error,
                    actionToken
                }
            });
        });
}


export const getWorldpayTransactions = (
    fromDate: string,
    endDate: string,
    actionToken: string,
    merchantNumber?: string,
) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_WORLDPAY_TRANSACTIONS_REQUEST,
        payload: {
            actionToken
        }
    });

    const url = store.getState().webAppSettings.baseApiUrl + "/MSB_Vantiv/api/v1/TransactionQueryItems/dynamicQuery";

    const config = {
        headers: {
            Authorization: `Bearer ${store.getState().webAppSettings.bearerToken}`
        },
        params: {
            "ExpressTransactionDateTime:from": `${fromDate}T00:00:00.000<->${endDate}T23:59:59.999`,
            "AcceptorID": merchantNumber
        }
    };

    if (!merchantNumber) {
        delete config.params['AcceptorID'];
    }

    axios.get(url, config)
        .then(function (response) {
            const transactions = response.data || [];
            dispatch({
                type: GET_WORLDPAY_TRANSACTIONS_SUCCESS,
                payload: {
                    transactions,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_WORLDPAY_TRANSACTIONS_FAILURE,
                payload: {
                    error: error,
                    actionToken
                }
            });
        });
};


export const getTableConfigurationAction = (userId: any, reportType: any, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_USER_TABLE_PREFERENCES_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_User/api/v1/UserPreferences/${userId}/getTableConfiguration?pageName=${reportType}`;
    axios.get(_url)
        .then(function (response) {
            let tablePreferences = response.data;
            dispatch({
                type: GET_USER_TABLE_PREFERENCES_SUCCESS,
                payload: {
                    tablePreferences,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_USER_TABLE_PREFERENCES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};

export const updateTableConfigurationAction = (userId: any, selectedColumns: any, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_USER_TABLE_PREFERENCES_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_User/api/v1/UserPreferences/${userId}/addOrUpdateTableConfiguration`;

    axios.put(_url, selectedColumns)
        .then(function (response) {
            dispatch({
                type: UPDATE_USER_TABLE_PREFERENCES_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: UPDATE_USER_TABLE_PREFERENCES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};

export const resetReportStoreAction = (actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: RESET_REPORT_STORE_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: RESET_REPORT_STORE_SUCCESS,
        payload: {
            actionToken
        }
    });
};
