import { UserTypeEnum } from "./User";

export class Roles  {
    role?: Role;
}

export class Role {
    msbId?: string;
    userTypeEnum: UserTypeEnum = UserTypeEnum.Unknown;
    name: string = "";
    description: string ="";
    selected?: boolean;
    roleModulePermissions?: Array<ModulePermission> = new Array<ModulePermission>();
}
export class ModulePermission {
    name : string = "";
    description: string = "" ;
    tag: string = "";
    crudPermission: string = "";
    userTypeEnum: UserTypeEnum = UserTypeEnum.Unknown;
}
