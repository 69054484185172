import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import RequiredIcon from "../../../components/RequiredIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import store from "../../../redux/store";
import axios from "axios";

interface IECheckPayment {
    isFetching: boolean,
    isSaving: boolean,
    isVisible: boolean
}

const ECheckPayment = ({ isFetching, isSaving, isVisible }: IECheckPayment) => {
    const [showAccountNumber, setShowAccountNumber] = useState(false);
    const [bankName, setBankName] = useState<string>('');
    const [touched, setTouched] = useState<boolean>(false);

    const handleOnlyNumeric = (e: any) => {
        if (e.key === " " || isNaN(e.key)) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (!isVisible) {
            setBankName('');
            setTouched(false);
        }
    }, [isVisible]);

    const getBankName = (routingNumber: string) => {
        axios
            .get(`${store.getState().webAppSettings.baseApiUrl}/MSB_ECheck/api/v1/ECheckRoutingNumber/${routingNumber}`)
            .then(response => {
                if (response.status === 200 && response.data.routingNumber === routingNumber) {
                    setBankName(response.data.custName);
                } else {
                    setBankName("");
                }
            })
            .catch(error => { console.log('Failed to lookup bank routing number', error) });
    };

    const handleRoutingNumberOnChange = (e: any) => {
        const val = e.target.value;
        if (val.length === 9) {
            getBankName(val);
        } else {
            setBankName('');
        }
        setTouched(true);
    }

    if (isVisible) {
        return (
            <>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="eCheckAccountHolderName">
                            <Form.Label><RequiredIcon />Name on account</Form.Label>
                            <Form.Control type="input" required pattern="^([a-zA-z\s'-])*$" disabled={isSaving} maxLength={100} placeholder="Enter Name on account" />
                            <Form.Control.Feedback type="invalid">Please enter a valid Cardholder's name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="eCheckRoutingNumber">
                            <Form.Label><RequiredIcon />Bank Routing number</Form.Label>
                            <Form.Control type="input" pattern="^\d*$" minLength={9} maxLength={9} placeholder="Enter Bank Routing number" required disabled={isSaving} onKeyPress={(e: any) => handleOnlyNumeric(e)} onChange={(e: any) => handleRoutingNumberOnChange(e)} isInvalid={!bankName && touched} className="fs-mask" />
                            <Form.Control.Feedback type="invalid">Please enter a valid Routing number</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="eCheckAccountNumber">
                            <Form.Label><RequiredIcon />Bank Account number</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control className="password fs-mask" pattern="^\d*$" maxLength={17} type={showAccountNumber ? "text" : "password"} placeholder="Enter Bank Account number" required disabled={isSaving} onKeyPress={(e: any) => handleOnlyNumeric(e)} />
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setShowAccountNumber(!showAccountNumber)}
                                    >
                                        <FontAwesomeIcon icon={showAccountNumber ? faEyeSlash : faEye} size="sm" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">Please enter an Account number.</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="eCheckBankName">
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control type="input" disabled={true} value={bankName} />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    } else {
        return (<></>)
    }
}

export default ECheckPayment;