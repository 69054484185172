import React, { useState } from "react";
import { Col, Row, Form } from 'react-bootstrap';
import { Client, MerchantProcessor as Merchant} from '../../../../../models/Client';
import { toggleOmnitokenMerchantAction } from '../../../../../redux/actions/clients/merchants';
import { useDispatch } from "react-redux";
import FormActions, { IFormActionProps } from '../FormActions';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import Switch from "react-switch";

export interface IMerchantVASFormProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    merchant: Merchant,
    actionToken?: any,
    formActionProps?: IFormActionProps
}

const MerchantVASForm = ({ isSaving, isFetching, client, merchant, formActionProps,actionToken }: IMerchantVASFormProps) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(merchant.vantivConfiguration.isOmniTokenEnabled);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() !== false) {
            dispatch(toggleOmnitokenMerchantAction(merchant.msbId!, isActive, actionToken));
        }
        setValidated(true);
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <div className="toggle-switch">
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Switch
                                onChange={e => setIsActive(e)}
                                checked={isActive}
                                onColor={'#0057B6'}
                                offColor={'#BEBEBE'}
                                handleDiameter={12}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={16}
                                width={28}
                                disabled={isSaving}
                                activeBoxShadow={'0 0 0 1px #0057B6'}
                            />
                        </ReactPlaceholder>
                        <span className="toggle-label">Enable Omnitoken</span>
                    </div>
                </Col>
            </Row>
            <div className="manage-footer">
              <FormActions
                  disabled={isSaving || isFetching}
                  showSpinner={isSaving}
                  {...formActionProps} />
            </div>
        </Form>
    );
};

export default MerchantVASForm;