import { CellPlugin } from '@react-page/editor';
import StCharlesShoppingCart, { TITLE, SUBTITLE, NAME_LABEL, PIN_LABEL, TAX_YEAR_LABEL,BILL_NUMBER_LABEL,TAX_BILL_ID_LABEL,
    REV_OBJ_ID_LABEL,AMOUNT_LABEL,ACTIONS_LABEL,HEADER_BACKGROUND_COLOR,HEADER_PADDING,MARGIN,PADDING,  CART_BUTTON_BACKGROUND_COLOR, CART_BUTTON_FONT_COLOR, CART_BUTTON_FONT_SIZE, 
    CART_BUTTON_FONT_STYLE, CART_BUTTON_FONT_WEIGHT, CART_BUTTON_BORDER_COLOR, CART_ICON_COLOR
} from './components/StCharlesShoppingCart';

type Data = {
    title: string,
    subTitle: string,
    name: string,
    pin: string,
    taxYear: string,
    billNumber: string,
    taxBillId: string,
    revObjId: string,
    amount: string,
    actions: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string
}

const StCharlesShoppingCartPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <StCharlesShoppingCart
            title = {data.title}
            subTitle={data.subTitle}
            nameLabel={data.name}
            pinLabel={data.pin}
            taxYearLabel={data.taxYear}
            billNumberLabel={data.billNumber}
            taxBillIdLabel={data.taxBillId}
            revObjIdLabel={data.revObjId}
            amountLabel={data.amount}
            actionsLabel={data.actions}
            margin={data.margin}
            padding={data.padding}
            cartButtonBackgroundColor={data.cartButtonBackgroundColor}
            cartButtonFontColor={data.cartButtonFontColor}
            cartButtonFontSize={data.cartButtonFontSize}
            cartButtonFontStyle={data.cartButtonFontStyle}
            cartButtonFontWeight={data.cartButtonFontWeight}
            cartButtonBorderColor={data.cartButtonBorderColor}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
            cartIconColor={data.cartIconColor}
        />
    ),
    id: 'stCharlesShoppingCartPlugin',
    title: 'StCharles Shopping Cart ',
    description: 'Adds items to shopping cart',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        title: {
                            type: 'string',
                            default: TITLE,
                        },
                        subTitle: {
                            type: 'string',
                            default: SUBTITLE,
                        },
                        name: {
                            type: 'string',
                            default: NAME_LABEL,
                        },
                        pin: {
                            type: 'string',
                            default: PIN_LABEL,
                        },
                        taxYear: {
                            type: 'string',
                            default: TAX_YEAR_LABEL,
                        },
                        billNumber: {
                            type: 'string',
                            default: BILL_NUMBER_LABEL,
                        },
                        taxBillId: {
                            type: 'string',
                            default: TAX_BILL_ID_LABEL,
                        },
                        revObjId: {
                            type: 'string',
                            default: REV_OBJ_ID_LABEL,
                        },
                        amount: {
                            type: 'string',
                            default: AMOUNT_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        },
                        cartButtonBackgroundColor: {
                            type: 'string',
                            default: CART_BUTTON_BACKGROUND_COLOR,
                        },
                        cartButtonFontColor: {
                            type: 'string',
                            default: CART_BUTTON_FONT_COLOR,
                        },
                        cartButtonFontSize: {
                            type: 'string',
                            default: CART_BUTTON_FONT_SIZE,
                        },
                        cartButtonFontStyle: {
                            type: 'string',
                            default: CART_BUTTON_FONT_STYLE,
                        },
                        cartButtonFontWeight: {
                            type: 'string',
                            default: CART_BUTTON_FONT_WEIGHT,
                        },
                        cartButtonBorderColor: {
                            type: 'string',
                            default: CART_BUTTON_BORDER_COLOR,
                        },
                        cartIconColor: {
                            type: 'string',
                            default: CART_ICON_COLOR
                        }
                        
                    },
                    required: [],
                },
            },
        },
    ],
};

export default StCharlesShoppingCartPlugin;