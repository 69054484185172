import { Dropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';


const BasicTableWithProps = (props: any,data:any,expandRow?:any, page?: number,totalSize?: number, sizePerPage?: number) => {

    var options:any = {
        page: page?page:1,
        totalSize: totalSize?totalSize:data.length, 
        sizePerPage: sizePerPage?sizePerPage:10, 
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        hideSizePerPage: false,
        firstPageText: '\u27E8\u27E8', // First
        prePageText: '\u27E8', // Previous
        nextPageText: '\u27E9', // Next
        lastPageText: '\u27E9\u27E9', // Last
        nextPageTitle: 'Next page',
        prePageTitle: 'Previous page',
        firstPageTitle: 'First page',
        lastPageTitle: 'Last page',
        showTotal: true,
       
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
      }:any) => (
        <Dropdown>
        <Dropdown.Toggle variant="outline-secondary">{currSizePerPage}</Dropdown.Toggle>
        <Dropdown.Menu className={"dropdown-menu-sm"}>
          {
            options.map((option: { page: any; text: {} | null | undefined; }, index:number) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <Dropdown.Item
                    key={ index }
                    onClick={ () => onSizePerPageChange(option.page) }
                    className="dropdown-item-sm"
                >
                { option.text }
              </Dropdown.Item>
              );
            })
          }
          </Dropdown.Menu>
        </Dropdown>
      );
    
      const paginationTotalRenderer = (from: number, to: number, size: number) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing { from } to { to } of { size } results
        </span>
    );
    
    options.sizePerPageRenderer = sizePerPageRenderer;
    options.paginationTotalRenderer = paginationTotalRenderer;

    return (
        <BootstrapTable
            expandRow={expandRow}
            filter={ filterFactory() }
            pagination={paginationFactory(options)}
            {...props}
        />)

}

export default BasicTableWithProps