import { Dispatch } from 'react';
import uuid from 'react-uuid';
import { Toast } from '../../models/Toast';

export const SEND_TOAST = 'SEND_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const SUCESS = 'SUCCESS';
export const WARNING = 'WARNING';
export const ERROR = 'ERROR';
export const INFO = 'INFO';

export const sendToastAction = (message:string, level:string) => (dispatch: Dispatch<any>) => {
    let toast = new Toast();
    toast.id = uuid();
    toast.message = message;
    toast.level = level;

    dispatch({
        type: SEND_TOAST,
        payload: {
            toast
        }
    });
};

export const sendSuccessToastAction = (message: string) => (dispatch: Dispatch<any>) => {
    let toast = new Toast();
    toast.id = uuid();
    toast.message = message;
    toast.level = SUCESS;

    dispatch({
        type: SEND_TOAST,
        payload: {
            toast
        }
    });
};

export const sendErrorToastAction = (message: string) => (dispatch: Dispatch<any>) => {
    let toast = new Toast();
    toast.id = uuid();
    toast.message = message;
    toast.level = ERROR;

    dispatch({
        type: SEND_TOAST,
        payload: {
            toast
        }
    });
};

export const sendInfoToastAction = (message: string) => (dispatch: Dispatch<any>) => {
    let toast = new Toast();
    toast.id = uuid();
    toast.message = message;
    toast.level = INFO;

    dispatch({
        type: SEND_TOAST,
        payload: {
            toast
        }
    });
};

export const sendWarningToastAction = (message: string) => (dispatch: Dispatch<any>) => {
    let toast = new Toast();
    toast.id = uuid();
    toast.message = message;
    toast.level = WARNING;

    dispatch({
        type: SEND_TOAST,
        payload: {
            toast
        }
    });
};

export const removeToastAction = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: REMOVE_TOAST,
        payload: {
            id
        }
    });
};