import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
  label: string,
  tag: string,
  showLabel: boolean,
  value: any
  justifyContent: string,
  textAlign: string,
}

const getValue = (value: any) => {

  if (value) {
    return value;
  } else {
    var address = {} as any;
    address.addressLine1 = "123 Main St.";
    address.addressLine2 = "";
    address.city = "Indianapolis";
    address.state = "IN";
    address.zipCode = "12345";
    return address;
  }
}

const JUSTIFY_CONTENT = 'left';
const TEXT_ALIGN = 'left';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
  return style && style !== ''?style:defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle:string) => {
  return defaultStyle as any;
}

const DepartmentAddressPropertyPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <Row>
        {data.showLabel?
          <Col><strong>{data.label}</strong></Col>
          :
          <></>
        }
        <Col>
          <span style={{ 
              display: 'flex',
              justifyContent: checkStyleForDefault(data.justifyContent, JUSTIFY_CONTENT),
              textAlign:checkStyleForDefault3(data.textAlign)
            }}>
            {getValue(data.value).addressLine1}<br />
            {getValue(data.value).city}, {getValue(data.value).state} {getValue(data.value).zip}
          </span>
        </Col>
      </Row>
  ),
  id: 'departmentAddressPropertyPlugin',
  title: 'Department Address Property',
  description: 'Department Address Property.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        label: {
          type: 'string',
          default: 'Department Address',
            },
        tag: {
            type: 'string',
            default: 'Department Address',
            readOnly: true
        },
        showLabel: {
          type: 'boolean',
          default: true
        },
        textAlign: {
          type: 'string',
          default: TEXT_ALIGN,
        },
        justifyContent: {
          type: 'string',
          default: JUSTIFY_CONTENT,
        },
      },
      required: ['label'],
    },
  }
};

export default DepartmentAddressPropertyPlugin;