import * as React from "react";
import {useEffect, useState} from "react";
import { connect, useDispatch } from "react-redux";

import { Redirect } from "react-router-dom";
import type { Value } from '@react-page/editor';
import Editor, { CellPlugin } from '@react-page/editor';
import image from '@react-page/plugins-image';

import '@react-page/editor/lib/index.css';
import { clearContentAction, getContentAction, saveContentAction, SAVE_CMS_CONTENT_FAILURE, SAVE_CMS_CONTENT_REQUEST, SAVE_CMS_CONTENT_SUCCESS } from "../../redux/actions/cms";
import { Content, Revision, StatusEnum } from "../../models/CMS";
import { sendErrorToastAction, sendSuccessToastAction } from "../../redux/actions/toast";
import { Department, Receipt, ReceiptTypeEnum } from "../../models/Client";
import { clearReceiptToastActionResult, saveDepartmentAction, saveDepartmentReceiptAction, SAVE_DEPARTMENT_RECEIPT_FAILURE, SAVE_DEPARTMENT_RECEIPT_REQUEST, SAVE_DEPARTMENT_RECEIPT_SUCCESS } from "../../redux/actions/clients/departments";

import { Routes } from "../../routes";
//import CardPlugin from "../paymentChannels/web/components/plugins/CardPlugin";
import { Card, Form } from "react-bootstrap";
import EditorPageHeader from "../paymentChannels/web/components/EditorPageHeader";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import ReceiptContainerPlugin from "./components/plugins/ReceiptContainerPlugin";
import MerchantIdPropertyPlugin from "./components/plugins/MerchantIdPropertyPlugin";
import TransactionDatePropertyPlugin from "./components/plugins/TransactionDatePropertyPlugin";
import TransactionIdPropertyPlugin from "./components/plugins/TransactionIdPropertyPlugin";
import AccountNumberPropertyPlugin from "./components/plugins/AccountNumberPropertyPlugin";
import AmountPropertyPlugin from "./components/plugins/AmountPropertyPlugin";
import ApprovalCodePropertyPlugin from "./components/plugins/ApprovalCodePropertyPlugin";
import BillingZipPropertyPlugin from "./components/plugins/BillingZipPropertyPlugin";
import CardLogoPropertyPlugin from "./components/plugins/CardLogoPropertyPlugin";
import ConvenienceFeePropertyPlugin from "./components/plugins/ConvenienceFeePropertyPlugin";
import DisclaimerPropertyPlugin from "./components/plugins/DisclaimerPropertyPlugin";
import ReferenceNumberPropertyPlugin from "./components/plugins/ReferenceNumberPropertyPlugin";
import ResponseCodePropertyPlugin from "./components/plugins/ResponseCodePropertyPlugin";
import SignaturePropertyPlugin from "./components/plugins/SignaturePropertyPlugin";
import TotalAmountPropertyPlugin from "./components/plugins/TotalAmountPropertyPlugin";
import TransactionTypePropertyPlugin from "./components/plugins/TransactionTypePropertyPlugin";
import NameOnCardPropertyPlugin from "./components/plugins/NameOnCardPropertyPlugin";
import './receipts.css';
import CheckingAccountNumberPropertyPlugin from "./components/plugins/CheckingAccountNumberPropertyPlugin";
import ClientNamePropertyPlugin from "./components/plugins/ClientNamePropertyPlugin";
import ClientMetadataPropertyPlugin from "./components/plugins/ClientMetadataPropertyPlugin";
import DepartmentNamePropertyPlugin from "./components/plugins/DepartmentNamePropertyPlugin";
import OrderCapturedByEmployeePropertyPlugin from "./components/plugins/OrderCapturedByEmployeePropertyPlugin";
import PaymentChannelNamePropertyPlugin from "./components/plugins/PaymentChannelNamePropertyPlugin";
import PhoneNumberPropertyPlugin from "./components/plugins/PhoneNumberPropertyPlugin";
import RemainingBalanceAmountPropertyPlugin from "./components/plugins/RemainingBalanceAmountPropertyPlugin";
import TransactionDateTimePropertyPlugin from "./components/plugins/TransactionDateTimePropertyPlugin";
import PaymentTypePropertyPlugin from "./components/plugins/PaymentTypePropertyPlugin";
import TransactionTimePropertyPlugin from "./components/plugins/TransactionTimePropertyPlugin";
import ReceiptImagePlugin from "./components/plugins/ReceiptImagePlugin";
import DepartmentAddressPropertyPlugin from "./components/plugins/DepartmentAddressPropertyPlugin";
import TerminalIdentifierPropertyPlugin from "./components/plugins/TerminalIdentifierPropertyPlugin";
import EmailPropertyPlugin from "./components/plugins/EmailPropertyPlugin";
import EntryPropertyPlugin from "./components/plugins/EntryPropertyPlugin";
import CryptogramPropertyPlugin from "./components/plugins/CryptogramPropertyPlugin";
import PinVerifiedPropertyPlugin from "./components/plugins/PinVerifiedPropertyPlugin";
import OrderLinesPropertyPlugin from "./components/plugins/OrderLinesPropertyPlugin";
import HorizontalRulePlugin from "../paymentChannels/web/components/plugins/HorizontalRulePlugin";
import SignatureDisclaimerPropertyPlugin from "./components/plugins/SignatureDisclaimerPropertyPlugin";
import CustomerNamePropertyPlugin from "./components/plugins/CustomerNamePropertyPlugin";
//import CustomerAddressPropertyPlugin from "./components/plugins/CustomerAddressPropertyPlugin";
import LinkPropertyPlugin from "./components/plugins/LinkPropertyPlugin";
import PlainTextPlugin from "./components/plugins/PlainTextPlugin";
import SpacerPlugin from "./components/plugins/SpacerPlugin";
import TylerEagleRedirectPlugin from "./components/plugins/TylerEagleRedirectPlugin";
import ButtonPlugin from "./components/plugins/ButtonPlugin";
import ScheduledPaymentDatePropertyPlugin from "./components/plugins/ScheduledPaymentDatePropertyPlugin";
import ScheduledPaymentMethodPropertyPlugin from "./components/plugins/ScheduledPaymentMethodPropertyPlugin";
import ScheduledPaymentPortionPropertyPlugin from "./components/plugins/ScheduledPaymentPortionPropertyPlugin";
import ItemReferencePropertyPlugin from "./components/plugins/ItemReferencePropertyPlugin";
import PMoFNotificationPlugin from "./components/plugins/PMoFNotificationPlugin";
import PMoFAuthorizationPlugin from "./components/plugins/PMoFAuthorizationPlugin";
import PMoFConfirmationPlugin from "./components/plugins/PMoFConfirmationPlugin";
import ReasonPropertyPlugin from "./components/plugins/ReasonPropertyPlugin";

export interface IReceiptEditor {
    isFetching: boolean,
    isSaving: boolean,
    department: Department,
    receipt: Receipt,
    actionResult: IActionResult,
    cmsActionResult: IActionResult,
    content: Content | null,
    clearToast?: boolean
}

const ReceiptEditor = ({ isFetching, isSaving, department, receipt, content, actionResult, cmsActionResult, clearToast }: IReceiptEditor) => {
    const [value, setValue] = useState<Value>();
    const [validated, setValidated] = useState<boolean>(false);
    const [editorReady, setEditorReady] = useState<boolean>(false);
    const [cellPlugins, setCellPlugins] = useState<Array<CellPlugin>>(Array<CellPlugin>());
    const [redirect, setRedirect] = useState<string>("");

    const dispatch = useDispatch();
    const actionToken = "ReceiptEditor";

    let receiptContainerPlugin = ReceiptContainerPlugin as CellPlugin<unknown, unknown>;

    let accountNumberPropertyPlugin = AccountNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let amountPropertyPlugin = AmountPropertyPlugin as CellPlugin<unknown, unknown>;
    let approvalCodePropertyPlugin = ApprovalCodePropertyPlugin as CellPlugin<unknown, unknown>;
    let billingZipPropertyPlugin = BillingZipPropertyPlugin as CellPlugin<unknown, unknown>;
    let cardLogoPropertyPlugin = CardLogoPropertyPlugin as CellPlugin<unknown, unknown>;
    let checkingAccountNumberPropertyPlugin = CheckingAccountNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let clientNamePlugin = ClientNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let clientMetadataPropertyPlugin = ClientMetadataPropertyPlugin as CellPlugin<unknown, unknown>;
    let convenienceFeePropertyPlugin = ConvenienceFeePropertyPlugin as CellPlugin<unknown, unknown>;
    let cryptogramPropertyPlugin = CryptogramPropertyPlugin as CellPlugin<unknown, unknown>;
    let departmentAddressPropertyPlugin = DepartmentAddressPropertyPlugin as CellPlugin<unknown, unknown>;
    let departmentNamePropertyPlugin = DepartmentNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let disclaimerPropertyPlugin = DisclaimerPropertyPlugin as CellPlugin<unknown, unknown>;
    let emailPropertyPlugin = EmailPropertyPlugin as CellPlugin<unknown, unknown>;
    let entryPropertyPlugin = EntryPropertyPlugin as CellPlugin<unknown, unknown>;
    let horizontalRulePlugin = HorizontalRulePlugin as CellPlugin<unknown, unknown>;
    let merchantIdPropertyPlugin = MerchantIdPropertyPlugin as CellPlugin<unknown, unknown>;
    let nameOnCardPropertyPlugin = NameOnCardPropertyPlugin as CellPlugin<unknown, unknown>;
    let orderCapturedByEmployeePropertyPlugin = OrderCapturedByEmployeePropertyPlugin as CellPlugin<unknown, unknown>;
    let orderLinesPropertyPlugin = OrderLinesPropertyPlugin  as CellPlugin<unknown, unknown>;
    let paymentChannelNamePropertyPlugin = PaymentChannelNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let paymentTypePropertyPlugin = PaymentTypePropertyPlugin as CellPlugin<unknown, unknown>;
    let pinVerifiedPropertyPlugin = PinVerifiedPropertyPlugin as CellPlugin<unknown, unknown>;
    let phoneNumberPropertyPlugin = PhoneNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let imagePlugin = image as CellPlugin<unknown, unknown>;
    let referenceNumberPropertyPlugin = ReferenceNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let remainingBalanceAmountPropertyPlugin = RemainingBalanceAmountPropertyPlugin as CellPlugin<unknown, unknown>;
    let responseCodePropertyPlugin = ResponseCodePropertyPlugin as CellPlugin<unknown, unknown>;
    let signatureDisclaimerPropertyPlugin = SignatureDisclaimerPropertyPlugin as CellPlugin<unknown, unknown>; 
    let signaturePropertyPlugin = SignaturePropertyPlugin as CellPlugin<unknown,unknown>;
    let spacerPlugin = SpacerPlugin as CellPlugin<unknown,unknown>;
    let terminalIdentifierPropertyPlugin = TerminalIdentifierPropertyPlugin as CellPlugin<unknown,unknown>;
    let totalAmountPropertyPlugin = TotalAmountPropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionDatePropertyPlugin = TransactionDatePropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionDateTimePropertyPlugin = TransactionDateTimePropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionIdPropertyPlugin = TransactionIdPropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionTimePropertyPlugin = TransactionTimePropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionTypePropertyPlugin = TransactionTypePropertyPlugin as CellPlugin<unknown, unknown>;
    let tylerEagleRedirectPlugin = TylerEagleRedirectPlugin as CellPlugin<unknown, unknown>;
    let customerNamePropertyPlugin = CustomerNamePropertyPlugin as CellPlugin<unknown, unknown>;
    //let customerAddressPropertyPlugin = CustomerAddressPropertyPlugin as CellPlugin<unknown, unknown>;
    
    let linkPropertyPlugin = LinkPropertyPlugin as CellPlugin<unknown, unknown>;
    let plainTextPlugin = PlainTextPlugin as CellPlugin<unknown, unknown>;
    let buttonPlugin = ButtonPlugin as CellPlugin<unknown, unknown>;
    let scheduledPaymentDatePropertyPlugin = ScheduledPaymentDatePropertyPlugin as CellPlugin<unknown, unknown>;
    let scheduledPaymentMethodPropertyPlugin = ScheduledPaymentMethodPropertyPlugin as CellPlugin<unknown, unknown>;
    let scheduledPaymentPortionPropertyPlugin = ScheduledPaymentPortionPropertyPlugin as CellPlugin<unknown, unknown>;
    let itemReferencePropertyPlugin = ItemReferencePropertyPlugin as CellPlugin<unknown, unknown>;

    let pMoFNotificationPlugin = PMoFNotificationPlugin as CellPlugin<unknown, unknown>;
    let pMoFAuthorizationPlugin = PMoFAuthorizationPlugin as CellPlugin<unknown, unknown>;
    let pMoFConfirmationPlugin = PMoFConfirmationPlugin as CellPlugin<unknown, unknown>;

    let reasonPropertyPlugin = ReasonPropertyPlugin as CellPlugin<unknown, unknown>;
    
    useEffect(() => {
        let componentMounted = true;

        return () => {
            componentMounted = false;
        }
    }, []);   

    useEffect(() => {
        if (receipt) {
            receiptContainerPlugin.cellPlugins = new Array<CellPlugin>();

            cellPlugins.push(receiptContainerPlugin);
            const _cellPlugins = [...cellPlugins];

            _cellPlugins.push(spacerPlugin);
            _cellPlugins.push(plainTextPlugin);
            _cellPlugins.push(imagePlugin);

            if (receipt.receiptType === ReceiptTypeEnum.CardNotPresent || receipt.receiptType === ReceiptTypeEnum.CardPresent || receipt.receiptType === ReceiptTypeEnum.eCheck) { 
                _cellPlugins.push(clientNamePlugin);
                _cellPlugins.push(departmentNamePropertyPlugin);
                _cellPlugins.push(paymentTypePropertyPlugin);
                _cellPlugins.push(totalAmountPropertyPlugin);
                _cellPlugins.push(reasonPropertyPlugin);

                _cellPlugins.push(accountNumberPropertyPlugin);  
                _cellPlugins.push(amountPropertyPlugin);
                _cellPlugins.push(billingZipPropertyPlugin);
                _cellPlugins.push(buttonPlugin);
                _cellPlugins.push(cardLogoPropertyPlugin);
                _cellPlugins.push(clientMetadataPropertyPlugin);
                _cellPlugins.push(convenienceFeePropertyPlugin);
                _cellPlugins.push(cryptogramPropertyPlugin);
                _cellPlugins.push(customerNamePropertyPlugin);
                _cellPlugins.push(departmentAddressPropertyPlugin);
                
                _cellPlugins.push(disclaimerPropertyPlugin);
                _cellPlugins.push(emailPropertyPlugin);
                _cellPlugins.push(entryPropertyPlugin);
                _cellPlugins.push(horizontalRulePlugin);
                _cellPlugins.push(linkPropertyPlugin);
                _cellPlugins.push(itemReferencePropertyPlugin);
                _cellPlugins.push(merchantIdPropertyPlugin);
                _cellPlugins.push(orderCapturedByEmployeePropertyPlugin);
                _cellPlugins.push(orderLinesPropertyPlugin);
                _cellPlugins.push(paymentChannelNamePropertyPlugin);
                
                _cellPlugins.push(phoneNumberPropertyPlugin);
                _cellPlugins.push(pinVerifiedPropertyPlugin);
                _cellPlugins.push(referenceNumberPropertyPlugin);
                _cellPlugins.push(remainingBalanceAmountPropertyPlugin);
                _cellPlugins.push(responseCodePropertyPlugin);
                _cellPlugins.push(signatureDisclaimerPropertyPlugin);
                _cellPlugins.push(signaturePropertyPlugin);
                _cellPlugins.push(terminalIdentifierPropertyPlugin);
                
                _cellPlugins.push(transactionDatePropertyPlugin);
                _cellPlugins.push(transactionDateTimePropertyPlugin);
                _cellPlugins.push(transactionIdPropertyPlugin);
                _cellPlugins.push(transactionTimePropertyPlugin);
                _cellPlugins.push(transactionTypePropertyPlugin);
                _cellPlugins.push(tylerEagleRedirectPlugin);
            }

            if (receipt.receiptType === ReceiptTypeEnum.CardNotPresent) {
                _cellPlugins.push(clientNamePlugin);
                _cellPlugins.push(departmentNamePropertyPlugin);
                _cellPlugins.push(paymentTypePropertyPlugin);
                _cellPlugins.push(totalAmountPropertyPlugin);

                _cellPlugins.push(approvalCodePropertyPlugin);

            } else if (receipt.receiptType === ReceiptTypeEnum.CardPresent) {
                _cellPlugins.push(clientNamePlugin);
                _cellPlugins.push(departmentNamePropertyPlugin);
                _cellPlugins.push(paymentTypePropertyPlugin);
                _cellPlugins.push(totalAmountPropertyPlugin);

                _cellPlugins.push(approvalCodePropertyPlugin);
                _cellPlugins.push(nameOnCardPropertyPlugin);

            } else if (receipt.receiptType === ReceiptTypeEnum.eCheck) {
                _cellPlugins.push(clientNamePlugin);
                _cellPlugins.push(departmentNamePropertyPlugin);
                _cellPlugins.push(paymentTypePropertyPlugin);
                _cellPlugins.push(totalAmountPropertyPlugin);

                _cellPlugins.push(checkingAccountNumberPropertyPlugin);
            } else if (receipt.receiptType === ReceiptTypeEnum.CardNotPresentReminder) {
                _cellPlugins.push(clientNamePlugin);
                _cellPlugins.push(departmentNamePropertyPlugin);
                _cellPlugins.push(paymentTypePropertyPlugin);
                _cellPlugins.push(totalAmountPropertyPlugin);

                _cellPlugins.push(scheduledPaymentDatePropertyPlugin);
                _cellPlugins.push(scheduledPaymentMethodPropertyPlugin);
                _cellPlugins.push(scheduledPaymentPortionPropertyPlugin);
                _cellPlugins.push(itemReferencePropertyPlugin);
            } else if (receipt.receiptType === ReceiptTypeEnum.eCheckReminder) {
                _cellPlugins.push(clientNamePlugin);
                _cellPlugins.push(departmentNamePropertyPlugin);
                _cellPlugins.push(paymentTypePropertyPlugin);
                _cellPlugins.push(totalAmountPropertyPlugin);

                _cellPlugins.push(scheduledPaymentDatePropertyPlugin);
                _cellPlugins.push(scheduledPaymentMethodPropertyPlugin);
                _cellPlugins.push(scheduledPaymentPortionPropertyPlugin);
                _cellPlugins.push(itemReferencePropertyPlugin);
            } else if (receipt.receiptType === ReceiptTypeEnum.PaymentDeclined) {
                _cellPlugins.push(clientNamePlugin);
                _cellPlugins.push(departmentNamePropertyPlugin);
                _cellPlugins.push(paymentTypePropertyPlugin);
                _cellPlugins.push(totalAmountPropertyPlugin);

                //_cellPlugins.push(scheduledPaymentDatePropertyPlugin);
                //_cellPlugins.push(scheduledPaymentMethodPropertyPlugin);
                //_cellPlugins.push(scheduledPaymentPortionPropertyPlugin);
                _cellPlugins.push(transactionDatePropertyPlugin);
                _cellPlugins.push(referenceNumberPropertyPlugin);
                _cellPlugins.push(itemReferencePropertyPlugin);
            } else if (receipt.receiptType === ReceiptTypeEnum.PaymentFailed) {
                _cellPlugins.push(clientNamePlugin);
                _cellPlugins.push(departmentNamePropertyPlugin);
                _cellPlugins.push(paymentTypePropertyPlugin);
                _cellPlugins.push(totalAmountPropertyPlugin);

                _cellPlugins.push(scheduledPaymentDatePropertyPlugin);
                //_cellPlugins.push(scheduledPaymentMethodPropertyPlugin);
                //_cellPlugins.push(scheduledPaymentPortionPropertyPlugin);
                _cellPlugins.push(transactionDatePropertyPlugin);
                _cellPlugins.push(itemReferencePropertyPlugin);
            } else if (receipt.receiptType === ReceiptTypeEnum.Notification) {
                _cellPlugins.push(pMoFNotificationPlugin);                
            } else if (receipt.receiptType === ReceiptTypeEnum.Authorization) {
                _cellPlugins.push(pMoFAuthorizationPlugin);
            } else if (receipt.receiptType === ReceiptTypeEnum.Confirmation) {
                _cellPlugins.push(pMoFConfirmationPlugin);
            }

            receiptContainerPlugin.cellPlugins = _cellPlugins;
            setCellPlugins(_cellPlugins);

            if (receipt.contentId !== '00000000-0000-0000-0000-000000000000') {
                dispatch(getContentAction(receipt.contentId, actionToken));
            }

            setEditorReady(true);
        }
    }, [receipt]);   

    useEffect(() => {
        if (cmsActionResult && cmsActionResult.result) {
            if (cmsActionResult.type === SAVE_CMS_CONTENT_REQUEST && cmsActionResult.token === actionToken) {
                if (cmsActionResult.result === SAVE_CMS_CONTENT_SUCCESS) {
                    if (receipt.contentId === '00000000-0000-0000-0000-000000000000') {
                        receipt.contentId = content!.msbId!;
                        dispatch(saveDepartmentReceiptAction(receipt, actionToken));
                    }
                    else { dispatch(sendSuccessToastAction("Layout has been successfully saved.")) }
                } else if (cmsActionResult.result === SAVE_CMS_CONTENT_FAILURE) {
                    dispatch(sendErrorToastAction("Layout could not be updated."));
                }
            }
        }
    }, [cmsActionResult]);  

    useEffect(() => {
        if (!clearToast && actionResult && actionResult.result) {
            if (actionResult.type === SAVE_DEPARTMENT_RECEIPT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_DEPARTMENT_RECEIPT_SUCCESS) {
                     dispatch(sendSuccessToastAction("Layout has been successfully saved.")); 
                } else if (cmsActionResult.result === SAVE_DEPARTMENT_RECEIPT_FAILURE) {
                    dispatch(sendErrorToastAction("Layout could not be updated."));
                }
            }
        }
    }, [actionResult, clearToast]); 

    useEffect(() => {
        if (content && receipt) {
            if (receipt.contentId === content.msbId!) {
                for (let x = 0; x < content.revisions.length;x++) {
                    if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                        let revision = content.revisions[x];
                        let value = JSON.parse(revision.value);
                        setValue(value);
                        break;
                    }
                }
            }
        }
    }, [receipt, content]);   

    const handleContentChange = (value: Value) => {
        setValue(value);
    }

    const handleSave = () => {       
        if (!content || receipt.contentId !== content.msbId!) {
            content = new Content();
            content.clientId = department.msbId!;
            content.title = "receipt_" + receipt.receiptType;
        }

        let revision: Revision | null = null;

        for (let x = 0; x < content.revisions.length;x++) {
            if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                revision = content.revisions[x];
                break;
            }
        }

        if (revision === null) {
            revision = new Revision();
            revision.statusEnum = StatusEnum.Draft;
            content.revisions.push(revision);
        }

        revision.value = JSON.stringify(value);
        dispatch(saveContentAction(content, actionToken));
    } 

    const handleClose = (event: any) => {
       dispatch(clearContentAction(actionToken));
       dispatch(clearReceiptToastActionResult(actionToken));
       setCellPlugins(new Array<CellPlugin>());
       setEditorReady(false);
       setRedirect(Routes.DepartmentEdit.path);
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() !== false) {

        }

        setValidated(true);
    };

    const renderEditor = () => {
        if (editorReady) {
            return (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div id="receiptSectionContainer">
                        <Editor cellPlugins={cellPlugins} value={value} onChange={handleContentChange} />
                    </div>
                </Form>
            )
        } else {
            return (<></>)
        }
    }

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <EditorPageHeader onSave={handleSave} onClose={handleClose} />
                <Card style={{marginTop:"24px"}}>
                    {renderEditor()}
                </Card>
            </>
        );
      }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        department: state.clients.department,
        receipt: state.clients.receipt,
        actionResult: state.clients.actionResult,
        cmsActionResult: state.cms.actionResult,
        content: state.cms.content,
        clearToast: state.clients.clearToast
    };
};

export default connect(mapStateToProps)(ReceiptEditor);
