import React, { useEffect, useState } from "react";
import { ButtonToolbar, Button, Dropdown, Modal } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import Table from '../../../../components/Table';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { Routes } from '../../../../routes'
import { Redirect } from 'react-router-dom';
import { IAppState, IActionResult } from '../../../../redux/storeTypes';
import { Department, ContactTypeEnum, Client, Contact } from "../../../../models/Client";
import FormHeader from '../forms/FormHeader';
import DepartmentContactForm from "../forms/department/DepartmentContactsForm";
import PermissionModule, { Permissions } from '../../../../components/usePermissions';
import _ from "lodash";
import {
    deleteDepartmentContactAction,
    ADD_DEPARTMENT_CONTACT_REQUEST, ADD_DEPARTMENT_CONTACT_SUCCESS, ADD_DEPARTMENT_CONTACT_FAILURE,
    SAVE_DEPARTMENT_CONTACT_REQUEST, SAVE_DEPARTMENT_CONTACT_SUCCESS, SAVE_DEPARTMENT_CONTACT_FAILURE,
    DELETE_DEPARTMENT_CONTACT_REQUEST, DELETE_DEPARTMENT_CONTACT_SUCCESS, DELETE_DEPARTMENT_CONTACT_FAILURE
}
    from '../../../../redux/actions/clients/contacts';

interface IDepartmentContactsProps {
    isSaving: boolean,
    isFetching: boolean,
    department: Department,
    client: Client,
    actionResult: IActionResult
}

const DepartmentContactsSection = ({ isSaving, isFetching, client, department, actionResult }: IDepartmentContactsProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<boolean>(false);
    const [contact, setContact] = useState<Contact>();
    const [showContactForm, setShowContactForm] = useState<boolean>(false);
    const [modalTitle, setModalTitle] =  useState<string>(""); 

    let actionToken = "DepartmentContactsSection";

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_DEPARTMENT_CONTACT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === ADD_DEPARTMENT_CONTACT_SUCCESS) {
                    setShowContactForm(false)
                    dispatch(sendSuccessToastAction("Department contact has been successfully added."));
                    //dispatch(getDepartmentAction(department.msbId!, actionToken));
                } else if (actionResult.result === ADD_DEPARTMENT_CONTACT_FAILURE) {
                    dispatch(sendErrorToastAction("Department contact could not be added. Reason(s) : " + actionResult.message));
                }
            }

            if (actionResult.type === SAVE_DEPARTMENT_CONTACT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_DEPARTMENT_CONTACT_SUCCESS) {
                    setShowContactForm(false)
                    dispatch(sendSuccessToastAction("Department contact has been successfully updated."));
                    //dispatch(getDepartmentAction(department.msbId!, actionToken));
                } else if (actionResult.result === SAVE_DEPARTMENT_CONTACT_FAILURE) {
                    dispatch(sendErrorToastAction("Department contact could not be updated. Reason(s) : " + actionResult.message));
                }
            }

            if (actionResult.type === DELETE_DEPARTMENT_CONTACT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === DELETE_DEPARTMENT_CONTACT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Department contact has been successfully deleted."));
                    //dispatch(getDepartmentAction(department.msbId!, actionToken));
                } else if (actionResult.result === DELETE_DEPARTMENT_CONTACT_FAILURE) {
                    dispatch(sendErrorToastAction("Department contact could not be deleted."));
                }
            }
        }
    }, [actionResult]);

    const contactTypeFormatter = (cell: any, contact: Contact) => {
        return _.startCase(_.camelCase(cell));
    }

    const contactNameFormatter = (_cell: any, contact: Contact) => {
        return contact.firstName + " " + contact.lastName;
    }

    const actionsFormatter = (cell: any, contact: Contact) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-contact-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item onSelect={() => { setModalTitle("Edit Contact"); setContact(contact); setShowContactForm(true); }}>
                            Edit Contact
                        </Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => dispatch(deleteDepartmentContactAction(contact.msbId!, actionToken))}>
                            Delete Contact
                        </Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    function doToolbar() {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => { setModalTitle("Add Contact"); setContact(undefined); setShowContactForm(true) }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Contact
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        }, {
            dataField: 'contactReferenceName',
            text: 'Contact reference name',
            editable: false
        }, {
            dataField: 'contactType',
            text: 'Contact type',
            editable: false,
            formatter: contactTypeFormatter
        }, {
            dataField: 'firstName',
            text: 'Name',
            editable: false,
            formatter: contactNameFormatter
        }, {
            dataField: 'msbId',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
        }
    ];

    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                <Modal size="lg" show={showContactForm} onHide={() => setShowContactForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <h2>{modalTitle}</h2>
                        <DepartmentContactForm
                        isSaving={isSaving}
                        isFetching={isFetching}
                        client={client}
                        department={department}
                        contact={contact}
                        actionToken={actionToken}
                        formActionProps={{
                            hasSubmit: true,
                            isEditMode: contact ? true : false,
                            onCancel: () => setShowContactForm(false),
                            customProps: {
                                submitButtonDisplay: contact ? "Save" : "Add"
                            }
                        }}
                    />
                    </Modal.Body>
                </Modal>

                <FormHeader title="Department Contacts" description="Please enter the department's contacts." />

                <div className="manage-card">
                    <PageSectionContainer title="Manage Contacts" toolbar={doToolbar()}>
                        <Table 
                            keyField="msbId" 
                            columns={columns}
                            data={department.departmentContacts}
                        />
                    </PageSectionContainer>
                </div>

                <div className="manage-footer">
                    <Button type="button" variant="link" onClick={() => setRedirect(true)} style={{ float: "right" }}>Cancel</Button>
                </div>

            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.clients.isSaving,
        isFetching: state.clients.isFetching,
        client: state.clients.client,
        department: state.clients.department,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(DepartmentContactsSection);