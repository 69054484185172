import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState,IActionResult } from '../../../../redux/storeTypes';
import { Client, Department } from "../../../../models/Client";
import { SAVE_DEPARTMENT_REQUEST, SAVE_DEPARTMENT_SUCCESS, SAVE_DEPARTMENT_FAILURE } from '../../../../redux/actions/clients/departments'
import FormHeader from '../forms/FormHeader';
import DepartmentDetailsForm from '../forms/department/DepartmentDetailsForm';
import { Redirect } from 'react-router-dom';
import { Routes } from '../../../../routes'
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { GilaSettings } from "../../../../models/GilaSettings";
import DepartmentAccountingForm from "../forms/department/DepartmentAccountingForm";
import { OnboardingTypeEnum, previousStepAction } from "../../../../redux/actions/clients/clientOnboarding";

 interface IDepartmentAccountingSectionProps {
    isSaving: boolean,
    isFetching: boolean,
    gilaSettings: GilaSettings,
    client: Client,
    department: Department,
    actionResult: IActionResult
}

const DepartmentAccountingSection = ({ isSaving, isFetching, gilaSettings, client, department, actionResult }: IDepartmentAccountingSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const actionToken = "DepartmentDetailsStep";

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_DEPARTMENT_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_DEPARTMENT_SUCCESS) {
                dispatch(sendSuccessToastAction("Accounting information was successfully updated."));
            } else if (actionResult.result === SAVE_DEPARTMENT_FAILURE) {
                dispatch(sendErrorToastAction("Accounting information could not be updated."));
            }
        }
    }, [actionResult]);
    
    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
    return (
        <>
            <FormHeader title="Accounting" description="Please enter the department's accounting information." />
            <DepartmentAccountingForm
                    isSaving={isSaving}
                    isFetching={isFetching}
                    department={department}
                    client={client}
                    actionToken={actionToken}
                    formActionProps={{
                        disabled: isSaving || isFetching,
                        showSpinner: isSaving,
                        hasSubmit: true,
                        isEditMode: department ? true : false,
                        onCancel: () => setRedirect(true),
                        customProps: {
                            submitButtonDisplay: "Save"
                        }
                    }}
                />
        </>
    );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        gilaSettings: state.gilaSettings.gilaSettings,
        client: state.clients.client,
        department: state.clients.department,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(DepartmentAccountingSection);