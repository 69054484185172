import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CellPlugin } from '@react-page/editor';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { sendErrorToastAction } from '../../../../../redux/actions/toast';
//import { OrderLine } from '../../../../../models/Order';
//import { addOrderLineAction } from '../../../../../redux/actions/orderManagement';
//import { v4 as uuidv4 } from 'uuid';

type Item = {
    reference: string,
    name: string,
    description: string,
    amount: string,
}

type Data = {
    titleLabel: string,
    subTitleLabel: string,
    descriptionLabel: string,
    amountLabel: string,
    quantityLabel: string,
    items: Item[],
    pricing: string,
    margin: string,
    padding: string,
    fullStoryMask: boolean
}

const MARGIN = '0px';
const PADDING = '0px';
const FULL_STORY_MASK = false;

const CatalogPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => {
        const dispatch = useDispatch();
        const [selected, setSelected] = useState<any>();
        const [amount, setAmount] = useState('0.00');
        const [quantity, setQuantity] = useState<number>(1);
        useEffect(() => {
            if (selected && selected >= 0) {
                setAmount(data.items[selected].amount);
            } else {
                setAmount('0.00');
            }
            setQuantity(1);
        }, [selected]);
        const handleAddItem = (e: any) => {
            if (!amount.trim() || quantity == 0) {
                dispatch(sendErrorToastAction(`Item not added to order - missing info.`));
                return;
            } else {
                /*
                let orderLine = new OrderLine();

                orderLine.itemId = uuidv4();
                orderLine.itemReferenceNumber = (selected) ? data.items[selected].reference : "";
                orderLine.itemName = (selected) ? data.items[selected].name : "";
                orderLine.quantity = quantity;
                orderLine.unitPrice = Number(amount!);

                dispatch(addOrderLineAction(orderLine, "CatalogPayment"));
                */
                setSelected("");
            }
        }
        return (
            <Row style={{ margin: data.margin, padding: data.padding, alignItems: 'flex-end' }} className={data.fullStoryMask ? "fs-mask" : ""}>
                <h3>
                    {data.titleLabel}
                    <em><small className="text-muted"> {data.subTitleLabel} </small></em>
                </h3>
                <Col md="6" sm="12">
                    <Form.Group controlId="description">
                        <Form.Label>{data.descriptionLabel}</Form.Label>
                        <Form.Control as='select' onChange={(e: any) => setSelected(e.target.value)} value={selected}>
                            <option value={""}>{`Select item`}</option>
                            {data.items && data.items.map((item, index) => { return (<option value={index} key={index}>{item.name} : {item.description}</option>); })}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="amount">
                        <Form.Label>{data.amountLabel}</Form.Label>
                        <Form.Control type="input" readOnly={data.pricing !== 'variable' || !selected} value={amount} onChange={(e: any) => e.target.value.match(/^\d{0,}(\.\d{0,2})?$/) ? setAmount(e.target.value) : null} />
                    </Form.Group>
                </Col>
                {data.pricing === 'unit' ?
                    <Col>
                        <Form.Group controlId="quantity">
                            <Form.Label>{data.quantityLabel}</Form.Label>
                            <Form.Control type="input" step={'any'} min={1} max={999} value={quantity} onChange={(e: any) => e.target.value.match(/^\d{0,3}?$/) ? setQuantity(e.target.value) : null} readOnly={!selected} />
                        </Form.Group>
                    </Col>
                    :
                    ""
                }
                <Col>
                    <Button className="brand-btn-primary" disabled={!selected} onClick={handleAddItem}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> {'Add item'}
                    </Button>
                </Col>
            </Row>
        );
    },
    id: 'CatalogPlugin',
    title: 'Catalog',
    description: 'Add a catalog component and define its items.',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        titleLabel: {
                            type: 'string',
                            default: 'Title',
                        },
                        subTitleLabel: {
                            type: 'string',
                            default: 'Subtitle',
                        },
                        descriptionLabel: {
                            type: 'string',
                            default: 'Name/Description',
                        },
                        amountLabel: {
                            type: 'string',
                            default: 'Amount',
                        },
                        quantityLabel: {
                            type: 'string',
                            default: 'Quantity',
                        },
                        fullStoryMask:{
                            type: 'boolean',
                            default: FULL_STORY_MASK
                        },
                        pricing: {
                            type: 'string',
                            enum: ['fixed', 'variable', 'unit'],
                            default: 'fixed',
                        },
                        items: {
                            type: 'array',
                            items: {
                                type: 'object',
                                default: {
                                    name: 'Apples',
                                    description: 'Granny Smiths',
                                    reference: '123456',
                                    amount: '2.17',
                                },
                                properties: {
                                    name: {
                                        type: 'string',
                                    },
                                    description: {
                                        type: 'string',
                                    },
                                    reference: {
                                        type: 'string',
                                    },
                                    amount: {
                                        type: 'string',
                                    },
                                },
                                required: [],
                            },
                        },
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                    },
                    required: [],
                },
            },
        },
    ]
};

export default CatalogPlugin;