import { CellPlugin } from '@react-page/editor';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import RequiredIcon from "../../../../components/RequiredIcon";

type Data = {
}

const PMoFAuthorizationPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => {
        const [agreed, setAgreed] = useState(false);
        return (
            <div>
                <Row>
                    <Col>
                        <Form.Group style={{ textAlign: "center", margin: "10px" }}>
                            <Form.Text style={{ fontSize: "24px", fontWeight: "bold" }}>{"Payment Method on File Authorization"}</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId={`pmof_${"customer_name"}`} style={{ margin: "5px" }}>
                            <Form.Label>{"Customer Name"}</Form.Label>
                            <Form.Control readOnly={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId={`pmof_${"email_address"}`} style={{ margin: "5px" }}>
                            <Form.Label>{"Email Address"}</Form.Label>
                            <Form.Control readOnly={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId={`pmof_${"payment_method"}`} style={{ margin: "5px" }}>
                            <Form.Label>{"Payment Method"}</Form.Label>
                            <Form.Control readOnly={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId={`pmof_${"last_four"}`} style={{ margin: "5px" }}>
                            <Form.Label>{"Last 4 of Account"}</Form.Label>
                            <Form.Control readOnly={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group style={{ textAlign: "center", margin: "10px" }}>
                            <Form.Text style={{ fontSize: "18px", fontWeight: "bold" }}><br />{"Authorization for Storing and using Payment Method on File"}</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group style={{ textAlign: "justify", margin: "10px" }}>
                            <Form.Text style={{ fontSize: "18px", fontWeight: "bold" }}>{"I authorize the merchant and its service providers to securely store and use my payment details (payment method on file) for future transactions."}</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group style={{ textAlign: "justify", margin: "10px" }}>
                            <Form.Text style={{ fontSize: "16px", fontWeight: "bold" }}>{"Please read and acknowledge the following terms:"}</Form.Text><br />
                            <Form.Text style={{ fontSize: "14px" }}>&bull; {"Payment Application: The payments you authorize using the payment method on file will be applied only to the merchant for the agreed-upon amount."}<br /></Form.Text>
                            <Form.Text style={{ fontSize: "14px" }}>&bull; {"Responsibility for Fees: You acknowledge and agree that you are solely responsible, and the merchant or its service providers are not liable, for any overdraft fees, insufficient funds charges, or other service fees imposed by your bank or card issuer."}<br /></Form.Text>
                            <Form.Text style={{ fontSize: "14px" }}>&bull; {"Authorization Cancellation: The merchant may cancel this authorization and remove your payment method without prior notice if any debit is rejected and returned for any reason."}<br /></Form.Text>
                            <Form.Text style={{ fontSize: "14px" }}>&bull; {"Removal of Payment Method: If you wish to remove your payment method on file, you can contact the merchant and request its removal."}<br /></Form.Text>
                            <Form.Text style={{ fontSize: "14px" }}>&bull; {"Notification of Changes: The merchant or its service providers will notify you in writing by mail or electronically via email or your online account regarding any changes to this authorization if required by applicable law, payment card network rules, or NACHA."}<br /></Form.Text>
                            <Form.Text style={{ fontSize: "14px" }}>&bull; {"Contacting the Merchant: If you have any questions regarding this authorization or any transaction related to it, you can contact the merchant during normal business hours."}<br /></Form.Text>
                            <Form.Text style={{ fontSize: "14px" }}>&bull; {" continuing to use your payment method on file, you signify your agreement to these terms and conditions. If you have any concerns or require clarification, please reach out to the merchant during normal business hours."}<br /></Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId={`pmof_${"agreement"}`} style={{ margin: "10px" }}>
                            <Form.Label><RequiredIcon />{"Agreement"}</Form.Label>
                            <Form.Check type={"checkbox"}>
                                <Form.Check.Input type={"checkbox"} defaultChecked={agreed} required={true} onClick={(e: any) => setAgreed(e.target.checked)} />
                                <Form.Check.Label>I have read and agree to the payment method on file terms.</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group style={{ textAlign: "center", margin: "5px" }}>
                            <Button type="submit" disabled={!agreed}>Submit</Button>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group style={{ textAlign: "center", margin: "5px" }}>
                            <Form.Text style={{ fontSize: "12px" }}><a href="https://msbgovserv.com/">Cancel</a></Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group style={{ textAlign: "center", margin: "10px" }}>
                            <Form.Text style={{ fontSize: "12px", color: "silver" }}>Powered by MSB Payment Processing</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        );
    },
    id: 'formPMoFAuthorizationPlugin',
    title: 'PMoF Authorization',
    description: 'PMof Authorization',
    version: 1,
    controls: {
        type: 'autoform',
        schema: {
            properties: {
            },
            required: [],
        },
    },
};

export default PMoFAuthorizationPlugin;