import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { User}  from '../../../../models/User';
import { IAppState } from '../../../../redux/storeTypes';
import { Image } from 'react-bootstrap';
import { getNotificationTypesAction } from '../../../../redux/actions/account/notificationSettings';
import { NotificationType, UserNotificationSetting } from '../../../../models/AccountSettings';
import  Avatar  from '../../../../assets/img/Avatar.png';

interface AdGroupProps {
    currentUser: User;
    avatar: Blob;
    notificationTypes: Array<NotificationType>,
}

const AdGroup = ({ currentUser, avatar, notificationTypes }: AdGroupProps) => {
    const [avatarImageSrc, setAvatarImageSrc] = useState("");
    const dispatch = useDispatch();
    let actionToken = "NotificationSettings";

    useEffect(() => {
        if (avatar !== null) {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(avatar);
            setAvatarImageSrc(imageUrl);
        }
    }, [avatar]);

    useEffect(() => {
        if(notificationTypes === null){
            //dispatch(getNotificationTypesAction(actionToken));
        }
    },[]);

    const imageSrc = (base64:string) => {
        if (base64) {
            if (base64.startsWith('data')) {
                return base64;
            } else {
                return `data:image/jpeg;base64, ${base64}`;
            }
        }
      };

    const renderAvatarImage = () => {
        if (currentUser.avatar) {
            return (
                <Image roundedCircle className="ad-avatar" alt="User avatar" src={imageSrc(currentUser.avatar.imageBase64String)} />
            )
        } else {
            return (
                <Image roundedCircle className="ad-avatar" src={Avatar} alt="User avatar" />
            )
        }
    };

    return (
        <div className="adGroup">
            <div className="userAvatar">
                {renderAvatarImage()}
            </div>
            <div className="userDetails">
                <h1>Greetings, {currentUser.firstName} {currentUser.lastName}!</h1>
                <span>{currentUser.title}</span>
            </div>
        </div>
    )
}

const mapStateToProps = (state:IAppState)  => {
    return {
        currentUser: state.auth.currentUser,
        avatar: state.auth.avatar,
        notificationTypes: state.accountSettings.notificationTypes
    };
};

export default connect(mapStateToProps)(AdGroup);