import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState,IActionResult } from '../../../../redux/storeTypes';
import { Client, MerchantProcessor as Merchant } from "../../../../models/Client";
import { TOGGLE_OMNITOKEN_MERCHANT_REQUEST, TOGGLE_OMNITOKEN_MERCHANT_SUCCESS, TOGGLE_OMNITOKEN_MERCHANT_FAILURE } from '../../../../redux/actions/clients/merchants'
import FormHeader from '../forms/FormHeader';
import { Redirect } from 'react-router-dom';
import { Routes } from '../../../../routes'
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import MerchantVASForm from "../forms/merchant/MerchantVASForm";

 interface IMerchantVASSectionProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    merchant: Merchant,
    actionResult: IActionResult
}

const MerchantVASSection = ({ isSaving, isFetching, client, merchant, actionResult }: IMerchantVASSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);

    const merchantSaveActionToken = "MerchantVAS";

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === TOGGLE_OMNITOKEN_MERCHANT_REQUEST && actionResult.token === merchantSaveActionToken) {
            if (actionResult.result === TOGGLE_OMNITOKEN_MERCHANT_SUCCESS) {
                setRedirect(true);
                dispatch(sendSuccessToastAction("Merchant value added services were successfully updated."));
            } else if (actionResult.result === TOGGLE_OMNITOKEN_MERCHANT_FAILURE) {
                dispatch(sendErrorToastAction("Merchant value added services could not be updated. Reason(s) : " + actionResult.message));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);
    
    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
    return (
        <>
            <FormHeader title="Merchant Value Added Services" description="Please enter the merchant's details." />
                <MerchantVASForm
                    isSaving={isSaving}
                    isFetching={isFetching}
                    client={client}
                    merchant={merchant}
                    actionToken={merchantSaveActionToken}
                    formActionProps={{
                        disabled: isSaving || isFetching,
                        showSpinner: isSaving,
                        hasSubmit: true,
                        isEditMode: false,
                        //onCancel: () => setRedirect(true),
                        customProps: {
                            submitButtonDisplay: "Save"
                        }
                    }} 
                />
        </>
    );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        client: state.clients.client,
        merchant: state.clients.merchant,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(MerchantVASSection);