import {    
GET_ORDER_TRANSACTION_REQUEST, GET_ORDER_TRANSACTION_SUCCESS, GET_ORDER_TRANSACTION_FAILURE,
SAVE_ORDER_TRANSACTION_COMMENT_REQUEST, SAVE_ORDER_TRANSACTION_COMMENT_SUCCESS, SAVE_ORDER_TRANSACTION_COMMENT_FAILURE,
SAVE_ORDER_TRANSACTION_DOCUMENTATION_REQUEST, SAVE_ORDER_TRANSACTION_DOCUMENTATION_SUCCESS, SAVE_ORDER_TRANSACTION_DOCUMENTATION_FAILURE,
UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_REQUEST, UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_SUCCESS, UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_FAILURE,
CHARGEBACK_REVERSAL_REQUEST, CHARGEBACK_REVERSAL_SUCCESS, CHARGEBACK_REVERSAL_FAILURE} from '../actions/orderTransactions';

export default function orderTransactions(state = {
    isFetching: false,
    isSvaing: false,
    errorMessage: '',
    currentPage: null,
    orderTransaction: null,
    actionResult: null
}, { type, payload }) {
    switch (type) {
        //GET_ORDER_TRANSACTION_COMMENT_FILES_REQUEST
        case GET_ORDER_TRANSACTION_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                orderTransaction: null,
                actionResult: { type: GET_ORDER_TRANSACTION_REQUEST, result: null, token: payload.actionToken },                
            });
        case GET_ORDER_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,                
                actionResult: { type: GET_ORDER_TRANSACTION_REQUEST, result: GET_ORDER_TRANSACTION_SUCCESS, token: payload.actionToken },
                orderTransaction: payload.orderTransaction
            });
        case GET_ORDER_TRANSACTION_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_ORDER_TRANSACTION_REQUEST, result: GET_ORDER_TRANSACTION_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        //saveOrderTransactionCommentRequest    
        case SAVE_ORDER_TRANSACTION_COMMENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                orderTransaction: null,
                actionResult: { type: SAVE_ORDER_TRANSACTION_COMMENT_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_ORDER_TRANSACTION_COMMENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,                
                actionResult: { type: SAVE_ORDER_TRANSACTION_COMMENT_REQUEST, result: SAVE_ORDER_TRANSACTION_COMMENT_SUCCESS, token: payload.actionToken } ,
                orderTransaction: payload.orderTransaction
            });
        case SAVE_ORDER_TRANSACTION_COMMENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: SAVE_ORDER_TRANSACTION_COMMENT_REQUEST, result: SAVE_ORDER_TRANSACTION_COMMENT_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        //saveOrderTransactionDocumentaitionRequest    
        case SAVE_ORDER_TRANSACTION_DOCUMENTATION_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                orderTransaction: null,
                actionResult: { type: SAVE_ORDER_TRANSACTION_DOCUMENTATION_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_ORDER_TRANSACTION_DOCUMENTATION_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,                
                actionResult: { type: SAVE_ORDER_TRANSACTION_DOCUMENTATION_REQUEST, result: SAVE_ORDER_TRANSACTION_DOCUMENTATION_SUCCESS, token: payload.actionToken } ,
                orderTransaction: payload.orderTransaction
            });
        case SAVE_ORDER_TRANSACTION_DOCUMENTATION_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: SAVE_ORDER_TRANSACTION_DOCUMENTATION_REQUEST, result: SAVE_ORDER_TRANSACTION_DOCUMENTATION_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
            //updateOrderTransactionChargeBackStatusRequest    
        case UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                orderTransaction: null,
                actionResult: { type: UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,                
                actionResult: { type: UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_REQUEST, result: UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_SUCCESS, token: payload.actionToken } ,
                orderTransaction: payload.orderTransaction
            });
        case UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_REQUEST, result: UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        //chargeback reversal
        case CHARGEBACK_REVERSAL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                orderTransaction: null,
                actionResult: { type: CHARGEBACK_REVERSAL_REQUEST, result: null, token: payload.actionToken } ,
            });
        case CHARGEBACK_REVERSAL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,                
                actionResult: { type: CHARGEBACK_REVERSAL_REQUEST, result: CHARGEBACK_REVERSAL_SUCCESS, token: payload.actionToken } ,
                orderTransaction: payload.orderTransaction
            });
        case CHARGEBACK_REVERSAL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: CHARGEBACK_REVERSAL_REQUEST, result: CHARGEBACK_REVERSAL_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });                  
        default:
            return state;
    }
}