import React, { useEffect, useState, useRef } from "react";

import { Col, Row, Container, Spinner, Button, Form } from 'react-bootstrap';
import PageHeader from '../../../components/layout/PageHeader';
import PageSectionContainer from '../../../components/layout/PageSectionContainer';
import AdvancedSearch from './fundByTimeZoneAdvanceSearch';
// @ts-ignore
import filterFactory from 'react-bootstrap-table2-filter';
import { Crumb } from "../../../models/Crumb";
import { connect, useDispatch } from "react-redux";
import { SearchList, TransactionTypeEnum } from "../../../models/Payment";
import { IActionResult, IAppState } from "../../../redux/storeTypes";
import { IPageableResults } from "../../../models/PageableResults";
import { ClientName, ClientTimezone, DepartmentName, PaymentChannelName } from "../../../models/Client";
import { getClientNamesAction, getClientTimezoneAction } from "../../../redux/actions/clients/clients";
import { getDepartmentNamesAction } from "../../../redux/actions/clients/departments";
import { CentralTimeZoneDateCellFormatter, ClientNameCellFormatter, ClientTimeZoneCellFormatter, ClientTimeZoneDateCellFormatter, CurrencyFormatter, DepartmentNameCellFormatter, PaymentChannelNameCellFormatter, timeZonesMap } from "../../../components/Formatters";
import { ReportType } from "../../../models/Reports";
import { GET_TRANSACTIONS_DETAIL_REQUEST, GET_TRANSACTIONS_DETAIL_SUCCESS } from "../../../redux/actions/payments/paymentTransactions";
import { Routes } from "../../../routes";
import { Redirect } from 'react-router-dom';
import { User } from "../../../models/User";
import { setProcessOrderAction } from "../../../redux/actions/payments/orderManagement";
import { getAllPaymentChannelNamesAction } from "../../../redux/actions/clients/paymentChannels";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import moment from "moment";
import BasicTableWithProps from "../../../components/TableProviderKit";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CSVCentralTimeZoneDateCellFormatter, CSVClientNameCellFormatter, CSVClientTimeZoneCellFormatter, CSVClientTimeZoneDateCellFormatter, CSVDepartmentNameCellFormatter, CSVPaymentChannelNameCellFormatter } from "../../../components/CSVFormatters";
import { dateCalculations } from "../../payments/components/Export";


interface IReportProps {
    formSchema: any;
    reportTitle: string;
    reportColumns: any;
    rowStyle?: any;
    documentContentUrl?: string;
    isFetching: boolean,
    isFetchingNames: boolean,
    currentPage: IPageableResults<any>,
    transactionType: TransactionTypeEnum,
    reportType: ReportType,
    clientNames: Array<ClientName>,
    clientTimeZones: Array<ClientTimezone>,
    departmentNames: Array<DepartmentName>,
    paymentChannelNames: Array<PaymentChannelName>,
    searchFields: SearchList,
    actionResult: IActionResult,
    breadCrumbRoute: string,
    searchSummary?: boolean,
    currentUser: User;
    isSaving: boolean,
}


const Report = ({ formSchema, reportTitle, reportColumns, rowStyle, isFetching, isFetchingNames, currentPage,
    transactionType, reportType, clientNames, clientTimeZones, departmentNames, paymentChannelNames, searchFields,
    actionResult, breadCrumbRoute }: IReportProps) => {

    const dispatch = useDispatch();
    const actionToken = "Report";

    const [data, setData] = useState(Array<any>(''));
    const [searchReady, setSearchReady] = useState<boolean>(false);
    const [tableReady, setTableReady] = useState<boolean>(false);
    const [waitingForDownload, setWaitingForDownload] = useState<boolean>(false);
    const [columns, setColumns] = useState();
    const [redirect, setRedirect] = useState<string>("");
    const buttonRef = useRef<any>(null)

    const doToolbar = () => {
        if (!isFetching && data && data.length > 0 && data[0] != '') {
            return (
                <div className="flexContainer">
                    <div>
                        <Button variant="outline-secondary" onClick={() => buttonRef.current.click()} disabled={waitingForDownload}>
                            {waitingForDownload && <Spinner animation="border" />}
                            CSV Details
                        </Button>
                    </div>
                </div>
            )
        }
        else {
            return (<></>);
        }
    }

    const ExportCSV = (props: any) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <div className="flexContainer">
                <div>
                    <Button hidden={true} ref={buttonRef} variant="outline-secondary" onClick={handleClick} disabled={waitingForDownload}>
                        {waitingForDownload && <Spinner animation="border" />}
                        CSV Details
                    </Button>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (!clientNames) {
            dispatch(getClientNamesAction(actionToken));
        }
    }, [clientNames]);

    useEffect(() => {
        if (!clientTimeZones) {
            dispatch(getClientTimezoneAction(actionToken));
        }
    }, [clientTimeZones]);

    useEffect(() => {
        if (!departmentNames) {
            dispatch(getDepartmentNamesAction(actionToken));
        }
    }, [departmentNames]);

    useEffect(() => {
        if (!paymentChannelNames) {
            dispatch(getAllPaymentChannelNamesAction(actionToken));
        }
    }, [paymentChannelNames]);

    useEffect(() => {
        if (clientNames && departmentNames && paymentChannelNames && reportColumns && reportColumns.length > 0) {
            const columnsToUpdate = [
                { dataField: 'eCheckPayment.clientId', addedFormatter: ClientNameCellFormatter, formatExtraData: clientNames, csvFormatter: CSVClientNameCellFormatter },
                { dataField: 'clientTimezoneText', addedFormatter: ClientTimeZoneCellFormatter, formatExtraData: clientTimeZones, csvFormatter: CSVClientTimeZoneCellFormatter },
                { dataField: 'clientTimeZone', addedFormatter: ClientTimeZoneDateCellFormatter, formatExtraData: clientTimeZones, csvFormatter: CSVClientTimeZoneDateCellFormatter },
                { dataField: 'centralTimeZone', addedFormatter: CentralTimeZoneDateCellFormatter, formatExtraData: null, csvFormatter: CSVCentralTimeZoneDateCellFormatter },
                { dataField: 'eCheckPayment.departmentId', addedFormatter: DepartmentNameCellFormatter, formatExtraData: departmentNames, csvFormatter: CSVDepartmentNameCellFormatter },
                { dataField: 'eCheckPayment.paymentChannelId', addedFormatter: PaymentChannelNameCellFormatter, formatExtraData: paymentChannelNames, csvFormatter: CSVPaymentChannelNameCellFormatter }
            ]
            Promise.all(columnsToUpdate.map(({ dataField, addedFormatter, formatExtraData, csvFormatter }) => {
                const column = reportColumns.find((column: any) => column.dataField == dataField);
                if (column) {
                    column.formatter = addedFormatter;
                    column.formatExtraData = formatExtraData || null;
                    column.csvFormatter = csvFormatter
                }
                return Promise.resolve();
            })).then(() => {
                setSearchReady(true);
                setColumns(reportColumns)
            });
        }
    }, [clientNames, clientTimeZones, departmentNames, paymentChannelNames, reportColumns]);

    const formatData = (data: any) => {
        return data.filter((item: any) => {
            const transactionTimeZone = clientTimeZones.find(timezones => timezones.clientId == item?.eCheckPayment?.clientId)?.timeZone;
            const startEndRanges = deriveUtcStartEndTimes(transactionTimeZone)
            const createdAt = moment.utc(item?.createdAt);
            return transactionTimeZone && createdAt.isBetween(startEndRanges.start, startEndRanges.end);
        })
    }

    const deriveUtcStartEndTimes = (timezone: any) => {
        const enhancedTransactionTimeZone = timeZonesMap[timezone];

        const utcOffset = moment.tz(enhancedTransactionTimeZone).format('Z');

        const startOfDay = moment.tz(searchFields?.startDate, enhancedTransactionTimeZone).startOf('day');
        const endOfDay = moment.tz(searchFields?.endDate, enhancedTransactionTimeZone).endOf('day');

        return { start: startOfDay, end: endOfDay }
    }


    useEffect(() => {
        if (currentPage) {
            setData(currentPage.data);
            setTableReady(true);
        }
    }, [currentPage]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === GET_TRANSACTIONS_DETAIL_REQUEST && actionResult.result === GET_TRANSACTIONS_DETAIL_SUCCESS) {
                dispatch(setProcessOrderAction(null, 'viewDetails'))
                setRedirect(Routes.ViewDetails.path);
            }
        }
    }, [actionResult]);

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb(`${reportTitle}`, ``));

    const renderTable = () => {
        if (searchReady) {
            if (!data || isFetching || isFetchingNames) {
                return (<Spinner animation="border" />);
            } else if (!isFetching && data[0] == '') {
                return (
                    <>
                        <span>Please perform a search to find the record you are looking for.</span>
                    </>
                );
            } else if (!isFetching && data && data.length === 0) {
                return (
                    <>
                        <h2 className="fw-bold">No records were found</h2>
                        <span>Please do another search to find the record you are looking for.</span>
                    </>
                );
            } else if (tableReady) {
                const dateRange = dateCalculations(searchFields?.createdAt);
                return (
                    <PerfectScrollbar>
                        <div style={{ paddingBottom: "25px", display: "block", minHeight: "255px" }}>
                            <ToolkitProvider keyField="msbId" data={formatData(data)} columns={reportColumns} exportCSV={{ fileName: `ACH Timezone Report_${dateRange}.csv`, onlyExportFiltered: true, exportAll: false }}>
                                {
                                    (props: any) => (
                                        <div>
                                            <ExportCSV {...props.csvProps}>Export CSV!!</ExportCSV>
                                            <BasicTableWithProps
                                                rowStyle={rowStyle}
                                                keyField="msbId"
                                                columns={columns}
                                                data={formatData(data)}
                                                filter={filterFactory()}
                                                {...props.baseProps}
                                            />
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        </div>
                    </PerfectScrollbar>
                );
            }
        }
        return (<></>);
    };


    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb(`${reportTitle}`, breadCrumbRoute));
        return (
            <>
                <PageHeader title={reportTitle} crumbs={crumbs} />

                <Container fluid className="container-table-search">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <AdvancedSearch formSchema={formSchema} transactionType={transactionType} reportType={reportType} isFetching={isFetching} searchReady={searchReady} />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col>
                            <PageSectionContainer title={`Manage ${reportTitle}`} toolbar={doToolbar()}>
                                {renderTable()}
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};


const mapStateToProps = (state: IAppState) => {
    return {
        currentPage: state.paymentTransactions.currentPage,
        searchFields: state.paymentTransactions.searchFields,
        isFetching: state.paymentTransactions.isFetching,
        clientNames: state.clients.clientNames,
        clientTimeZones: state.clients.clientTimezones,
        departmentNames: state.clients.departmentNames,
        paymentChannelNames: state.clients.paymentChannelNames,
        isFetchingNames: state.clients.isFetching,
        actionResult: state.paymentTransactions.actionResult,
        currentUser: state.auth.currentUser,
        isSaving: state.auth.isSaving
    };
};

export default connect(mapStateToProps)(Report);
