import { DebitFeeMethodEnum, FundingMethodEnum, PaymentChannelTypeEnum } from "./Client";
import { PaymentTypeEnum, TransactionTypeEnum } from "./Payment";

export class EMafTransaction {
    batchId: string = "";
    batchType: string = "";
    cashAdvanceAmount: number = 0;
    cashAdvanceCount: number = 0;
    chainName: string = "";
    merchantName: string = "";
    merchantNumber: string = "";
    netAmount: number = 0;
    netCount: number = 0;
    paymentChannelName: string = "";
    processDate: string = "";
    returnReversalAmount: number = 0;
    returnReversalCount: number = 0;
    saleAmount: number = 0;
    saleCount: number = 0;
    storeNumber: string = "";
}

export class EMafTransactionDetails {
    amount: number = 0;
    acquirerRefNumber: string = "";
    authCode: string = "";
    avsResponseCode: string = "";
    cardAccountNumber: string = "";
    cardType: string = "";
    entryType: number = 0;
    merchantName: string = "";
    merchantNumber: string = "";
    processDate: string = "";
    transactionDateTime: string = "";
    transactionType: number = 0;
}


export class MsbReportItem {
    amount: number = 0;
    clientName: string = "";
    convenienceFee: number = 0;
    debitFeeMethodEnum: DebitFeeMethodEnum = DebitFeeMethodEnum.DirectDebit;
    departmentId: string = "";
    departmentName: string = "";
    fundingMethodEnum: FundingMethodEnum = FundingMethodEnum.NextDay;
    paymentChannelId: string = "";
    paymentChannelName: string = "";
    paymentChannelTypeEnum: PaymentChannelTypeEnum = PaymentChannelTypeEnum.Select;
    paymentType: PaymentTypeEnum = PaymentTypeEnum.Unknown;
    transactionType: TransactionTypeEnum = TransactionTypeEnum.Unknown;
    state: string = "";
    totalAmount: number = 0;
    totalCount: number = 0;
    transactionDate: string = "";
    transactionItems: Array<MsbReportItem> = [];
    transactionTypeName: string = "";
}

export class MsbOrderItem {
    amount: number = 0;
    convenienceFee: number = 0;
    createdAt: string = "";
    departmentId: string = "";
    lastFourOnCard: number = 0;
    msbId: string = "";
    nameOnCard: string = "";
    paymentChannelId: string = "";
    paymentType: string = "";
    transactionType: string = "";
    totalAmount: number = 0;
    transactionIdentifier: string = "";
    orderPayment: any = {};
    paymentChannelName: any;
    vantivTransactionReferenceNumber: string = "";
}

export class ReportSummaryDeclinedPayments {
    clientId: string = "";
    departmentId: string = "";
    paymentChannelId: string = "";
    transactionDate: string = "";
    visa: number = 0;
    masterCard: number = 0;
    americanExpress: number = 0;
    discover: number = 0;
}

export class BarChartPaymentSeries {
    name: string = "";
    data: Array<number> = new Array<number>();
}

export class BarChartPaymentOptions {
    options: string = "";
    bar: string = "";
    colors: string = "";
    dataLabels: string = "";
    chart: string = "";
    xaxis: string = "";
}

export class BarChartPaymentOptions2 {
    options: string = "";
}

export enum ReportType {
    Transactions = 'Transactions',
    Chargeback = 'Chargeback',
    Pending = 'Pending',
    Payment = 'Payment',
    Declined = 'Declined',
    Exception = 'Exception',
    Hardware = 'Hardware',
    Refund = 'Refund',
    UserActivity = 'UserActivity',
    Void = 'Void',
    Authorization = 'Authorization',
    ConvenienceFee = 'ConvenienceFee',
    NoConvenienceFee = 'NoConvenienceFee',
    FeeAudit = 'FeeAudit',
    ComplianceExceptions = 'ComplianceExceptions',
    ClientBanking = 'ClientBanking',
    FinanceACH = 'FinanceACH',
    PMoF = 'PMoF'
}

export class WorldpayTransaction {
    acceptorID: string = "";
    transactionID: string = "";
    transactionAmount: number = 0;
    transactionStatus: string = "";
    transactionStatusCode: number = 0;
    transactionType: string = "";
    expressTransactionDate: string = "";
    expressTransactionDateTime: string = "";
    terminalID: string = "";
    terminalType: string = "";
    referenceNumber: string = "";
    cardNumberMasked: string = "";
    cardLogo: string = "";
    cardType: string = "";
    hostBatchID: string = "";
    cardInputCode: string = "";
}

export class WorldpaySummary {
    clientName: string = "";
    departmentName: string = "";
    merchantType: string = "";
    merchantNumber: string = "";
    transactionDate: string = "";
    transactionDateTime: string = "";
    salesCount: number = 0;
    salesAmount: number = 0;
    creditsCount: number = 0;
    creditsAmount: number = 0;
    netCount: number = 0;
    netAmount: number = 0;
    transactionItems: Array<WorldpayTransaction> = [];
}

export enum ReportDownloadType{
    Summary = 0, 
    Detail = 1,
    Both = 2,
    WithChannelLevelDetail = 3,
    WithoutChannelLevelDetail = 4
}