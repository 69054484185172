import { Dispatch } from 'react';
import axios from 'axios';
import { TylerEagleCancel, TylerEagleRequest } from "../../models/TylerEagleIntegration";
import store from '../store';
import { PaymentType, ProcessedOrder } from '../../models/Payment';

export const SET_TYLER_EAGLE_REQUEST = 'SET_TYLER_EAGLE_REQUEST';
export const POST_TYLER_EAGLE_CANCEL_REQUEST = 'POST_TYLER_EAGLE_CANCEL_REQUEST';
export const POST_TYLER_EAGLE_CANCEL_SUCCESS = 'POST_TYLER_EAGLE_CANCEL_SUCCESS';
export const POST_TYLER_EAGLE_CANCEL_FAILURE = 'POST_TYLER_EAGLE_CANCEL_FAILURE';

export const setTylerEagleRequestAction = (tylerEagleRequest:TylerEagleRequest) => (dispatch: Dispatch<any>) => {
    
    dispatch({
        type: SET_TYLER_EAGLE_REQUEST,
        payload: {
            tylerEagleRequest
        }
    });
};

export const postTylerEagleCancel = (actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: POST_TYLER_EAGLE_CANCEL_REQUEST,
        payload: {
            actionToken
        }
    });

    let tylerEagleCancel = new TylerEagleCancel();

    dispatch({
        type: POST_TYLER_EAGLE_CANCEL_SUCCESS,
        payload: {
            actionToken,
            tylerEagleCancel
        }
    });
};

export const setTylerEagleResponseAction = () => (dispatch: Dispatch<any>) => {
    let bearerToken = store.getState().webAppSettings.bearerToken;
    let tylerEagleRequest = store.getState().tylerEagleIntegration.tylerEagleRequest as unknown as TylerEagleRequest;
    let po = store.getState().orderManagement.processedOrder! as ProcessedOrder;
    if (tylerEagleRequest?.postbackURL) {
        const xmlString = `<?xml version="1.0" encoding="utf-8" ?><PaymentResponse><ClientKey>${tylerEagleRequest?.clientKey}</ClientKey><TransactionID>${tylerEagleRequest?.transactionID}</TransactionID><Amount>${po?.processorData?.receipt?.amount}</Amount><ReferenceNumber>${po?.processorData?.receipt?.orderId}</ReferenceNumber><PayorToken></PayorToken><PayorName>${tylerEagleRequest?.payorName}</PayorName><TenderDescription>${(po?.processorData?.receipt?.paymentType === PaymentType.ECheck) ? "eCheck" : po?.processorData?.receipt?.cardLogo} xxxxxxxxxxxx${po?.processorData?.receipt?.lastFourOnCard}</TenderDescription></PaymentResponse>`

        const config = {
            headers: {
                Authorization: `Bearer ${bearerToken}`
            }
        };

        axios.post(`${window.location.protocol}//${window.location.host}/TylerEagle/Response`, { "url": tylerEagleRequest.postbackURL, "xmlString": xmlString }, config)
            .then(function (response) {
                console.log("Tyler-Eagle Post-Back SUCCESSFUL!")
            })
            .catch(function (error) {
                console.log("Tyler-Eagle Post-Back FAILED!!!")
            });
    } else {
        console.log("Tyler-Eagle Post-Back URL not specified!!!")
    }
};