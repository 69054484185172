import React, { useEffect,useState } from "react";
import { connect, useDispatch } from "react-redux";
import { allPaymentsFormSchema } from './components/AdvancedSearchColumns';
import Report, { getContentUrl } from './components/Report'
import { DateFormatter, CurrencyFormatter, NameOnCardCellFormatter, TransactionReferenceCellFormatter, ConvenienceFeeFormatter } from '../../components/Formatters';
import { resetPaymentStoreAction, transactionDetailAction } from '../../redux/actions/payments/paymentTransactions';
import { IPaymentReportProps} from './Chargebacks'
import { OrderStatus } from "../../models/Payment";
import { ReportType } from "../../models/Reports";
import { setReportColumns } from "../../redux/actions/reports/report";
import { IAppState } from "../../redux/storeTypes";
import { Routes } from "../../routes";

const TransactionsReport = ({ reportColumns }: IPaymentReportProps) => {
    const dispatch = useDispatch();
    const actionToken = "ReportDetails";
    const reportTitle = 'All Transactions Report';
    const reportType = ReportType.Transactions;
    const reportRoute = Routes.TransactionsReport.path;
    const transactionType = 0;

    const viewDetailsFormatter = (cell:any,row:any) => {
        const breadCrumbDetails = { reportTitle,reportRoute }
        return(
            <span><a onClick={() =>{ dispatch(transactionDetailAction(row.msbId, actionToken, breadCrumbDetails))}}>View Details</a></span>
        )
    }

    useEffect(() => {
        if (reportColumns && !reportColumns.has(reportType)) {
            const columns = [
                {
                    dataField: 'msbId',
                    text: 'ID',
                    editable: false,
                    sort: false,
                    hidden: true,
                    configurable: false
                }, {
                    dataField: 'clientId',
                    text: 'Client',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'departmentId',
                    text: 'Department',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'paymentChannelId',
                    text: 'Payment Channel',
                    editable: false,
                    sort: true,
                    default: false,
                    configurable: true
                }, {
                    dataField: 'initiatedBy',
                    text: 'Initiated by',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'orderIdentifier',
                    text: 'Transaction ID',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'transactionType',
                    text: 'Transaction type',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'lastFourOnCard',
                    text: 'Last four on card/account',
                    editable: false,
                    sort: true,
                    default: false,
                    configurable: true
                }, {
                    dataField: 'createdAt',
                    text: 'Transaction date',
                    editable: false,
                    sort: true,
                    default: true,
                    formatter: DateFormatter,
                    configurable: true
                }, {
                    dataField: 'itemReferenceNumber',
                    text: 'Item reference',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                },{
                    dataField: 'itemName',
                    text: 'Item name',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'reason',
                    text: 'Reason',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                },  {
                    dataField: 'amount',
                    text: 'Subtotal',
                    editable: false,
                    sort: true,
                    formatter: CurrencyFormatter,
                    configurable: true
                }, {
                    dataField: 'convenienceFee',
                    text: 'Convenience fee',
                    editable: false,
                    sort: true,
                    formatter: ConvenienceFeeFormatter,
                    configurable: true
                }, {
                    dataField: 'totalAmount',
                    text: 'Total amount',
                    editable: false,
                    sort: true,
                    default: true,
                    formatter: CurrencyFormatter,
                    configurable: true
                }, {
                    dataField: 'originalAmount',
                    text: 'Original amount',
                    editable: false,
                    sort: true,
                    formatter: CurrencyFormatter,
                    configurable: true
                }, {
                    dataField: 'remainingBalance',
                    text: 'Remaining balance',
                    editable: false,
                    sort: true,
                    formatter: CurrencyFormatter,
                    configurable: true
                }, {
                    dataField: 'nameOnCard',
                    text: 'Customer name',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'orderPayment.cardLogo',
                    text: 'Card type',
                    editable: false,
                    sort: true,
                    default: false,
                    configurable: true
                }, {
                    dataField: 'phone',
                    text: 'Customer phone',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'email',
                    text: 'Customer email',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'addressLine1',
                    text: 'Address line 1',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'addressLine2',
                    text: 'Address line 2',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'city',
                    text: 'City',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'state',
                    text: 'State',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'zip',
                    text: 'Zip code',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: '',
                    isDummyField: true,
                    text: 'Actions',
                    editable: false,
                    formatter: viewDetailsFormatter,
                    configurable: false
                }
            ];
            dispatch(setReportColumns(reportType, columns));
        }
    }, []);

    const rowStyle = (row: any) => {
        const style = { backgroundColor: '' };
        if (row.transactionType == 'Chargeback') {
            style.backgroundColor = '#E6F7FD';
        } else if (row.transactionType == 'Void') {
            style.backgroundColor = '#FEFFE6';
        } else if (row.transactionType == 'Reversal') {
            style.backgroundColor = '#FEFFE6';
        } else if (row.transactionType == 'Refund') {
            style.backgroundColor = '#FFF2E8';
        } else if (row.transactionType == 'Return') {
            style.backgroundColor = '#FFF2E8';
        }
        return style;
    };

    return (
        <>
            <Report 
                reportTitle={reportTitle}
                reportType={reportType}
                breadCrumbRoute={reportRoute}
                transactionType={transactionType}
                rowStyle={rowStyle}
                formSchema={allPaymentsFormSchema}
                documentContentUrl={getContentUrl(OrderStatus.Success, undefined)} 
                searchSummary={true}
            />
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        reportColumns: state.reports.reportColumns
    };
};

export default connect(mapStateToProps)(TransactionsReport);

