import { useEffect } from "react";
import { Row, Col, Form, Table } from "react-bootstrap"
import { connect, useDispatch } from "react-redux";
import { TylerEagleRequest } from "../../../models/TylerEagleIntegration";
import { calculateOrderAction } from "../../../redux/actions/payments/orderManagement";
import { IActionResult, IAppState } from "../../../redux/storeTypes";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

//import { addOrderLineAction, ADD_ORDER_LINE, calculateOrderAction } from "../../../../../../redux/actions/orderManagement";


interface ITylerEaglePaymentReference {
    actionResult: IActionResult,
    tylerEagleRequest: TylerEagleRequest
}

export const ITEM_REFERENCE_LABEL = 'Item Reference';
export const DESCRIPTION_LABEL = 'Description';
export const AMOUNT_LABEL = 'Amount';
export const HEADER_BACKGROUND_COLOR = '#FAFAFA';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
    return style && style !== ''?style:defaultStyle;
}

const TylerEaglePaymentReference = ({ actionResult, tylerEagleRequest }: ITylerEaglePaymentReference) => {
    const actionToken = "TylerEaglePaymentReference";
    const dispatch = useDispatch();
    
    var itemReferenceLabel = ITEM_REFERENCE_LABEL;
    var descriptionLabel = DESCRIPTION_LABEL;
    var amountLabel = AMOUNT_LABEL;

    var itemReference = tylerEagleRequest.transactionID; 
    var description = tylerEagleRequest.description;
    var amount = tylerEagleRequest.amount; 
    
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <>
        <Row>
            <Col>
              <PerfectScrollbar>
                <Table responsive bordered={true} className="table-payment-method">
                        <thead>
                            <tr>
                                <th style={{backgroundColor:HEADER_BACKGROUND_COLOR}}>{itemReferenceLabel}</th>
                                <th style={{backgroundColor:HEADER_BACKGROUND_COLOR}}>{descriptionLabel}</th>
                                <th style={{backgroundColor:HEADER_BACKGROUND_COLOR}}>{amountLabel}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{itemReference}</td>
                                <td>{description}</td>
                                <td>{formatter.format(parseFloat(amount))}</td>
                            </tr>
                        </tbody>
                </Table>
              </PerfectScrollbar>
            </Col>
        </Row>
      </>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        actionResult: state.orderManagement.actionResult,
        errorMessage: state.orderManagement.errorMessage,
        tylerEagleRequest: state.tylerEagleIntegration.tylerEagleRequest,
    };
};

export default connect(mapStateToProps)(TylerEaglePaymentReference);