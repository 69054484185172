import * as React from "react";
import { Row, Col, Form } from "react-bootstrap"
import InputMask from "react-input-mask";
import RequiredIcon from '../../../../../../components/RequiredIcon';
import { StateDropdown } from "../../../../../../components/StateDropdown";
import { useState } from "react";

export interface IAccountDetails {
    nameRequired: boolean,
    nameLabel: string,
    namePlaceholder: string,
    addressLine1Label: string,
    addressLine1Placeholder: string,
    addressLine2Label: string,
    addressLine2Placeholder: string,
    countryLabel: string,
    cityLabel: string,
    cityPlaceholder: string,
    stateLabel: string,
    addressRequired: boolean,
    zipCodeLabel: string,
    zipCodePlaceholder: string,
    zipCodeRequired: boolean,
    phoneNumberLabel: string,
    phoneNumberRequired: boolean,
    emailAddressLabel: string,
    emailAddressRequired: boolean,
    fullStoryMask: boolean,
    margin: string,
    padding: string
}

export const NAME_LABEL = 'Name';
export const NAME_PLACEHOLDER = 'Enter full name';
export const NAME_REQUIRED = false;
export const ADDRESS_LINE_1_LABEL = 'Address line 1';
export const ADDRESS_LINE_1_PLACEHOLDER = 'Enter address line 1';
export const ADDRESS_LINE_2_LABEL = 'Address line 2';
export const ADDRESS_LINE_2_PLACEHOLDER = 'Enter address line 2';
export const COUNTRY_LABEL = 'Country';
export const CITY_LABEL = 'City';
export const CITY_PLACEHOLDER = 'Enter city';
export const STATE_LABEL = 'State/Province/Territory';
export const ADDRESS_REQUIRED = false;
export const ZIP_CODE_LABEL = 'Zip code';
export const ZIP_CODE_PLACEHOLDER = 'Enter zip code';
export const ZIP_CODE_REQUIRED = false;
export const PHONE_NUMBER_LABEL = 'Phone number';
export const PHONE_NUMBER_REQUIRED = false;
export const EMAIL_ADDRESS_LABEL = 'Email address';
export const EMAIL_ADDRESS_REQUIRED = false;
export const MARGIN = '0px';
export const PADDING = '0px';
export const FULL_STORY_MASK = false;

const AccountDetails = ({ nameRequired, nameLabel, namePlaceholder, addressLine1Label, addressLine1Placeholder, 
    addressLine2Label, addressLine2Placeholder, countryLabel, cityLabel, cityPlaceholder, 
    stateLabel, addressRequired, zipCodeLabel, zipCodePlaceholder, zipCodeRequired, phoneNumberLabel, 
    phoneNumberRequired, emailAddressLabel, emailAddressRequired, fullStoryMask, margin, padding }: IAccountDetails) => {

    nameRequired = nameRequired || NAME_REQUIRED;
    nameLabel = nameLabel || NAME_LABEL;
    namePlaceholder = namePlaceholder || NAME_PLACEHOLDER
    addressLine1Label = addressLine1Label || ADDRESS_LINE_1_LABEL;
    addressLine1Placeholder = addressLine1Placeholder || ADDRESS_LINE_1_PLACEHOLDER;
    addressLine2Label = addressLine2Label || ADDRESS_LINE_2_LABEL;
    addressLine2Placeholder = addressLine2Placeholder || ADDRESS_LINE_2_PLACEHOLDER;
    countryLabel = countryLabel || COUNTRY_LABEL;
    cityLabel = cityLabel || CITY_LABEL;
    cityPlaceholder = cityPlaceholder || CITY_PLACEHOLDER;
    stateLabel = stateLabel || STATE_LABEL;
    addressRequired = addressRequired || ADDRESS_REQUIRED;
    zipCodeLabel = zipCodeLabel || ZIP_CODE_LABEL;
    zipCodePlaceholder = zipCodePlaceholder || ZIP_CODE_PLACEHOLDER;
    zipCodeRequired = zipCodeRequired || ZIP_CODE_REQUIRED;
    phoneNumberLabel = phoneNumberLabel || PHONE_NUMBER_LABEL;
    phoneNumberRequired = phoneNumberRequired || PHONE_NUMBER_REQUIRED;
    emailAddressLabel = emailAddressLabel || EMAIL_ADDRESS_LABEL;
    emailAddressRequired = emailAddressRequired || EMAIL_ADDRESS_REQUIRED;
    fullStoryMask = fullStoryMask || FULL_STORY_MASK;

    const [country, setCountry] = useState<string>('United States');

    return (
        <div style={{ margin: margin, padding: padding }} className={fullStoryMask ? "fs-mask" : ""}>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="nameOnCard">
                        <Form.Label>{(nameRequired) ? <RequiredIcon /> : ''}{nameLabel}</Form.Label>
                        <Form.Control maxLength={50} type="input" placeholder={namePlaceholder} required={nameRequired} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="addressLine1">
                        <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{addressLine1Label}</Form.Label>
                        <Form.Control maxLength={40} type="input" placeholder={addressLine1Placeholder} required={addressRequired} />
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="addressLine2">
                        <Form.Label>{addressLine2Label}</Form.Label>
                        <Form.Control maxLength={40} type="input" placeholder={addressLine2Placeholder} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="country">
                        <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{countryLabel}</Form.Label>
                        <Form.Control as="select" value={country} onChange={(e) => { setCountry(e.target.value) }} required={addressRequired}>
                            <option>United States</option>
                            <option>Mexico</option>
                            <option>Canada</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="city">
                        <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{cityLabel}</Form.Label>
                        <Form.Control maxLength={28} type="input" placeholder={cityPlaceholder} required={addressRequired} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={4} sm={12}>
                    <Form.Group controlId="state">
                        <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{stateLabel}</Form.Label>
                        <StateDropdown country={country} required={addressRequired}></StateDropdown>
                    </Form.Group>
                </Col>
                <Col md={2} sm={12}>
                    <Form.Group controlId="zipCode">
                        <Form.Label>{(zipCodeRequired) ? <RequiredIcon /> : ''}{zipCodeLabel}</Form.Label>
                        <Form.Control type="input" placeholder={zipCodePlaceholder} required={zipCodeRequired} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="phoneNumber">
                        <Form.Label>{(phoneNumberRequired) ? <RequiredIcon /> : ''}{phoneNumberLabel}</Form.Label>
                        <InputMask type="input" id="phoneNumber" pattern="[0-9\-\(\)\s]{14}" name="phoneNumber" className="form-control" mask="(999) 999-9999" placeholder="(999) 999-9999" required={phoneNumberRequired} />
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="emailAddress">
                        <Form.Label>{(emailAddressRequired) ? <RequiredIcon /> : ''}{emailAddressLabel}</Form.Label>
                        <Form.Control type="email" placeholder="email@address.com" maxLength={100} required={emailAddressRequired} />
                        <Form.Control.Feedback type="invalid">{emailAddressRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    )
}

export default AccountDetails;