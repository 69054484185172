import { Row, Col, Form, Button } from "react-bootstrap"
import { useState } from "react";
import RequiredIcon from "../../../../../../components/RequiredIcon";
import { Field } from "../MultiSearchPlugin";

interface IAccountMultiSearch {
    taxTypeLabel: string,
    hideTaxType: boolean,
    taxTypeKey: string,
    taxTypeDynamicOptions: Field[],
    selectTaxTypeOptionLabel: string,
    searchByLabel: string,
    hideSearchBy: boolean,
    searchByDynamicOptions: Field[],
    selectSearchByOptionLabel: string,
    referenceNumberLabel: string,
    referenceNumberPlaceholder: string,
    searchLabel: string,
    useCustomAPI: boolean,
    useTypeAhead: boolean,
    resetLabel: string,
    margin: string,
    padding: string,
    resetButtonBackgroundColor: string,
    resetButtonFontColor: string,
    resetButtonFontSize: string,
    resetButtonFontStyle: string,
    resetButtonFontWeight: string,
    resetButtonBorderColor: string,
    submitButtonBackgroundColor: string,
    submitButtonFontColor: string,
    submitButtonFontSize: string,
    submitButtonFontStyle: string,
    submitButtonFontWeight: string
}

export const TAX_TYPE_LABEL = 'Tax type';
export const HIDE_TAX_TYPE = false;
export const TAX_TYPE_KEY = 'ItemType';
export const SELECT_TAX_TYPE_OPTION_LABEL = 'Select Tax type';
export const HIDE_SEARCH_BY = false;
export const USE_CUSTOM_API = false;
export const USE_TYPE_AHEAD = false;
export const SEARCH_BY_LABEL = 'Search by';
export const SELECT_SEARCH_BY_OPTION_LABEL = 'Select Search by';
export const REFERENCE_NUMBER_LABEL = 'Reference number';
export const REFERENCE_NUMBER_PLACEHOLDER = 'Enter reference number';
export const RESET_LABEL = 'Reset';
export const SEARCH_LABEL = 'Search';
export const MARGIN = '0px';
export const PADDING = '0px';
export const RESET_BUTTON_FONT_COLOR = '#414141';
export const RESET_BUTTON_FONT_SIZE = '.875rem';
export const RESET_BUTTON_FONT_STYLE = 'normal';
export const RESET_BUTTON_FONT_WEIGHT = 'normal';
export const RESET_BUTTON_BORDER_COLOR = '#D9D9D9';
export const RESET_BUTTON_BACKGROUND_COLOR = '#FFFFFF';
export const SUBMIT_BUTTON_FONT_COLOR = '#FFFFFF';
export const SUBMIT_BUTTON_FONT_SIZE = '.875rem';
export const SUBMIT_BUTTON_FONT_STYLE = 'normal';
export const SUBMIT_BUTTON_FONT_WEIGHT = 'normal';
export const SUBMIT_BUTTON_BACKGROUND_COLOR = '#0057b6';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

const MultiSearch = ({ taxTypeLabel, hideTaxType, taxTypeKey, taxTypeDynamicOptions, selectTaxTypeOptionLabel, searchByLabel, useCustomAPI, useTypeAhead, hideSearchBy, searchByDynamicOptions, selectSearchByOptionLabel, referenceNumberLabel, referenceNumberPlaceholder, searchLabel, resetLabel,
    margin, padding, resetButtonBackgroundColor, resetButtonFontColor, resetButtonFontSize, resetButtonFontStyle, resetButtonFontWeight, resetButtonBorderColor,
    submitButtonBackgroundColor, submitButtonFontColor, submitButtonFontSize, submitButtonFontStyle, submitButtonFontWeight }: IAccountMultiSearch) => {

    const [taxType, setTaxType] = useState<string>('');
    const [accountSearchBy, setAccountSearchBy] = useState<string>('');
    const [referenceNumber, setReferenceNumber] = useState<string>('');

    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!hideTaxType) { hideTaxType = HIDE_TAX_TYPE }
    if (!taxTypeKey) { taxTypeKey = TAX_TYPE_KEY }
    if (!selectTaxTypeOptionLabel) { selectTaxTypeOptionLabel = SELECT_TAX_TYPE_OPTION_LABEL }
    if (!searchByLabel) { searchByLabel = SEARCH_BY_LABEL }
    if (!hideSearchBy) { hideSearchBy = HIDE_SEARCH_BY }
    if (!useCustomAPI) { useCustomAPI = USE_CUSTOM_API }
    if (!selectSearchByOptionLabel) { selectSearchByOptionLabel = SELECT_SEARCH_BY_OPTION_LABEL }
    if (!referenceNumberLabel) { referenceNumberLabel = REFERENCE_NUMBER_LABEL }
    if (!referenceNumberPlaceholder) { referenceNumberPlaceholder = REFERENCE_NUMBER_PLACEHOLDER }
    if (!resetButtonBackgroundColor) { resetButtonBackgroundColor = RESET_BUTTON_BACKGROUND_COLOR }
    if (!resetButtonFontColor) { resetButtonFontColor = RESET_BUTTON_FONT_COLOR }
    if (!resetButtonFontSize) { resetButtonFontSize = RESET_BUTTON_FONT_SIZE }
    if (!resetButtonFontStyle) { resetButtonFontStyle = RESET_BUTTON_FONT_STYLE }
    if (!resetButtonFontWeight) { resetButtonFontWeight = RESET_BUTTON_FONT_WEIGHT }
    if (!resetButtonBorderColor) { resetButtonBorderColor = RESET_BUTTON_BORDER_COLOR }
    if (!submitButtonBackgroundColor) { submitButtonBackgroundColor = SUBMIT_BUTTON_BACKGROUND_COLOR }
    if (!submitButtonFontColor) { submitButtonFontColor = SUBMIT_BUTTON_FONT_COLOR }
    if (!submitButtonFontSize) { submitButtonFontSize = SUBMIT_BUTTON_FONT_SIZE }
    if (!submitButtonFontStyle) { submitButtonFontStyle = SUBMIT_BUTTON_FONT_STYLE }
    if (!submitButtonFontWeight) { submitButtonFontWeight = SUBMIT_BUTTON_FONT_WEIGHT }
    if (!searchLabel) { searchLabel = SEARCH_LABEL }
    if (!resetLabel) { resetLabel = RESET_LABEL }

    const handleTaxTypeChange = (e: any) => {
        setTaxType(e.target.value);
        setAccountSearchBy('');
        setReferenceNumber('')
    }

    const handleAccountSearchByChange = (e: any) => {
        setAccountSearchBy(e.target.value);
    }


    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                {!hideTaxType &&
                    <Col>
                        <Form.Group controlId="taxType">
                            <Form.Label><RequiredIcon />{taxTypeLabel}</Form.Label>
                            <Form.Control as='select' required value={taxType} onChange={handleTaxTypeChange}>
                                <option value=''>{selectTaxTypeOptionLabel}</option>
                                {
                                    taxTypeDynamicOptions && taxTypeDynamicOptions.map((field, index) => <option key={field.key} value={field.key}>{field.value}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                }
                {!hideSearchBy &&
                    <Col>
                        <Form.Group controlId="searchBy">
                            <Form.Label><RequiredIcon />{searchByLabel}</Form.Label>
                            <Form.Control as='select' required value={accountSearchBy} onChange={handleAccountSearchByChange} disabled={taxType === '' && !hideTaxType}>
                                <option value=''>{SELECT_SEARCH_BY_OPTION_LABEL}</option>
                                {
                                    searchByDynamicOptions && searchByDynamicOptions.map((field, index) => <option key={field.key} value={field.key}>{field.value}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                }
                <Col>
                    <Form.Group controlId="Reference number">
                        <Form.Label><RequiredIcon />{referenceNumberLabel}</Form.Label>
                        <Form.Control type="input" required placeholder={referenceNumberPlaceholder} value={referenceNumber} onChange={(e) => setReferenceNumber(e.target.value)} disabled={accountSearchBy === '' && !hideSearchBy} />
                    </Form.Group>
                </Col>
                <Col>
                    <Button className="brand-btn-primary" style={{
                        marginTop: '29px',
                        backgroundColor: checkStyleForDefault(resetButtonBackgroundColor, RESET_BUTTON_BACKGROUND_COLOR),
                        borderColor: checkStyleForDefault(resetButtonBorderColor, RESET_BUTTON_BORDER_COLOR),
                        color: checkStyleForDefault(resetButtonFontColor, RESET_BUTTON_FONT_COLOR),
                        fontSize: checkStyleForDefault(resetButtonFontSize, RESET_BUTTON_FONT_SIZE),
                        fontStyle: checkStyleForDefault(resetButtonFontStyle, RESET_BUTTON_FONT_STYLE),
                        fontWeight: checkStyleForDefault3(resetButtonFontWeight)
                    }} >
                        {resetLabel}
                    </Button>
                    <Button className="brand-btn-primary" style={{
                        marginTop: '29px',
                        marginLeft: '8px',
                        backgroundColor: checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                        borderColor: checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                        color: checkStyleForDefault(submitButtonFontColor, SUBMIT_BUTTON_FONT_COLOR),
                        fontSize: checkStyleForDefault(submitButtonFontSize, SUBMIT_BUTTON_FONT_SIZE),
                        fontStyle: checkStyleForDefault(submitButtonFontStyle, SUBMIT_BUTTON_FONT_STYLE),
                        fontWeight: checkStyleForDefault3(submitButtonFontWeight)
                    }} >
                        {searchLabel}
                    </Button>
                </Col>
            </Row>
        </div>

    )
}

export default MultiSearch;