import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../redux/storeTypes';
import { clientBanking } from './components/AdvancedSearchColumns';
import Report from './components/Report'
import { Routes } from "../../routes";
import { IPaymentReportProps } from './Chargebacks';
import { ReportType } from "../../models/Reports";
import { setReportColumns } from "../../redux/actions/reports/report";
import { Dropdown } from "react-bootstrap";
import { setClientBankingDetails } from "../../redux/actions/clients/clients";
import { DateFormatter } from '../../components/Formatters';
import { AccountDDATypeEnum } from "../../models/Client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import PermissionModule, { Permissions } from '../../components/usePermissions';


export const deleteActionToken = "DeleteClientBanking"

const ClientBanking = ({ reportColumns }: IPaymentReportProps) => {
    const dispatch = useDispatch();
    const actionToken = "ClientBanking";

    const reportType = ReportType.ClientBanking;
    const reportTitle = 'Client Banking';
    const reportRoute = Routes.ClientBanking.path;
    const transactionType = 0;

    const manageFormatter = (cell: any, row: any) => {

        return (
            <Dropdown>
                <Dropdown.Toggle disabled={row?.status === 'ApprovalPending'} variant="link" id="manage-client-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission={[
                            Permissions.ClientBankingRead,
                            Permissions.ClientBankingUpdate
                        ]}
                    >
                        <Dropdown.Item
                            onSelect={() => { dispatch(setClientBankingDetails(row, actionToken)) }}
                        >
                            Edit Record
                        </Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ClientBankingDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => { dispatch(setClientBankingDetails(row, deleteActionToken)) }}>
                            Delete Record
                        </Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const accountTypeFormatter = (cell: any, row: any) => {
        return (
            <span>{AccountDDATypeEnum[cell]}</span>
        );
    }

    const lastFourFormatter = (cell: any, row: any) => {
        const masked = cell?.slice(cell?.length - 4)!

        return (<span>{masked}</span>)
    }

    const statusFormatter = (cell: any, row: any) => {

        let color: string = "#F5222D";
        if (cell === 'ApprovalPending') { color = "#FADB14"; cell = 'Pending' }
        else if( cell === 'ApprovalRejected') { cell = 'Rejected'}
        else if (cell === 'Active') { color = "#52C41A" }
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: `${color}` }} />{cell}</span>
        )
    }

    useEffect(() => {
        if (reportColumns && !reportColumns.has(reportType)) {
            const columns = [
                {
                    dataField: 'msbId',
                    text: 'ID',
                    editable: false,
                    sort: false,
                    hidden: true,
                    configurable: false
                }, {
                    dataField: 'clientMsbId',
                    text: 'Client',
                    editable: false,
                    default: true,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'departmentMsbId',
                    text: 'Department',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'accountDDATypeEnum',
                    text: 'Account type',
                    editable: false,
                    sort: false,
                    formatter: accountTypeFormatter,
                    configurable: true
                }, {
                    dataField: 'bankAccountNumber',
                    text: 'Account number',
                    editable: false,
                    default: true,
                    sort: false,
                    formatter: lastFourFormatter,
                    configurable: true
                }, {
                    dataField: 'bankRoutingNumber',
                    text: 'Routing Number',
                    editable: false,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'additionalDetails',
                    text: 'Additional Details',
                    editable: false,
                    sort: false,
                    default: false,
                    configurable: true
                }, {
                    dataField: 'updatedAt',
                    text: 'Last Updated',
                    editable: false,
                    sort: true,
                    default: true,
                    formatter: DateFormatter,
                    configurable: true
                }, {
                    dataField: 'status',
                    text: 'Status',
                    editable: false,
                    sort: false,
                    default: true,
                    formatter: statusFormatter,
                    configurable: true
                }, {
                    dataField: 'dummy',
                    isDummyField: true,
                    text: 'Actions',
                    editable: false,
                    formatter: manageFormatter,
                    configurable: false
                }
            ];
            dispatch(setReportColumns(reportType, columns));
        }
    }, []);

    return (
        <>
            <Report
                reportTitle={reportTitle}
                reportType={reportType}
                breadCrumbRoute={reportRoute}
                transactionType={transactionType}
                formSchema={clientBanking}
            />
        </>
    );

};

const mapStateToProps = (state: IAppState) => {
    return {
        reportColumns: state.reports.reportColumns
    };
};

export default connect(mapStateToProps)(ClientBanking);
