import React, { useEffect, useState } from "react";
import { ButtonToolbar, Button, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import Table from '../../../../components/Table';
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../redux/storeTypes';

import {
    ADD_CLIENT_ADDRESS_REQUEST, ADD_CLIENT_ADDRESS_SUCCESS, ADD_CLIENT_ADDRESS_FAILURE,
    SAVE_CLIENT_ADDRESS_REQUEST, SAVE_CLIENT_ADDRESS_SUCCESS, SAVE_CLIENT_ADDRESS_FAILURE,
    DELETE_CLIENT_ADDRESS_REQUEST, DELETE_CLIENT_ADDRESS_SUCCESS, DELETE_CLIENT_ADDRESS_FAILURE
} from '../../../../redux/actions/clients/address';

import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { IClientManagementSectionProps } from '../ClientManagement';
import { Address, AddressTypeEnum } from '../../../../models/Client';
import { deleteClientAddressAction } from '../../../../redux/actions/clients/address';

import { Routes } from '../../../../routes'
import { Redirect } from 'react-router-dom';

import ClientAddressForm from "../forms/ClientAddressForm";
import FormHeader from '../forms/FormHeader';
import PermissionModule, { Permissions } from '../../../../components/usePermissions';
import _ from "lodash";

const ClientAddressesSection = ({ isFetching, isSaving, client, actionResult }: IClientManagementSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<boolean>(false);
    const [address, setAddress] = useState<Address>();
    const [showAddressForm, setShowAddressForm] = useState(false);

    const addressAddActionToken = "ClientAddressAdd";
    const addressSaveActionToken = "ClientAddressSave";
    const addressDeleteActionToken = "ClientAddressDelete";

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_CLIENT_ADDRESS_REQUEST && actionResult.token === addressAddActionToken) {
                if (actionResult.result === ADD_CLIENT_ADDRESS_SUCCESS) {
                    setShowAddressForm(false);
                    dispatch(sendSuccessToastAction("Client Address was successfully added."));
                } else if (actionResult.result === ADD_CLIENT_ADDRESS_FAILURE) {
                    dispatch(sendErrorToastAction("Client Address could not be added. Reason(s) : " + actionResult.message));
                }
            }

            if (actionResult.type === SAVE_CLIENT_ADDRESS_REQUEST && actionResult.token === addressSaveActionToken) {
                if (actionResult.result === SAVE_CLIENT_ADDRESS_SUCCESS) {
                    setShowAddressForm(false);
                    dispatch(sendSuccessToastAction("Client address has been successfully updated."));
                } else if (actionResult.result === SAVE_CLIENT_ADDRESS_FAILURE) {
                    dispatch(sendErrorToastAction("Client address could not be updated. Reason(s) : " + actionResult.message));
                }
            }

            if (actionResult.type === DELETE_CLIENT_ADDRESS_REQUEST && actionResult.token === addressDeleteActionToken) {
                if (actionResult.result === DELETE_CLIENT_ADDRESS_SUCCESS) {
                    dispatch(sendSuccessToastAction("Client address has been successfully deleted."));
                } else if (actionResult.result === DELETE_CLIENT_ADDRESS_FAILURE) {
                    dispatch(sendErrorToastAction("Client address could not be deleted."));
                }
            }
        }
    }, [actionResult]);  

    const addressTypeFormatter = (cell: any, address: Address) => {
        return _.startCase(_.camelCase(cell));
    }

    const actionsFormatter = (cell: any, address: Address) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-address-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item onSelect={() => { setAddress(address); setShowAddressForm(true); }}>
                            Edit Address
                        </Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => dispatch(deleteClientAddressAction(address.msbId!, addressDeleteActionToken))}>
                            Delete Address
                        </Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    function doToolbar() {
        return (
            <PermissionModule
                permission=
                {[
                   Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => { setAddress(undefined); setShowAddressForm(true); }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Address
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        },
        {
            dataField: 'addressReferenceName',
            text: 'Address reference name',
            editable: false
        }, {
            dataField: 'addressType',
            text: 'Address type',
            editable: false,
            formatter: addressTypeFormatter
        }, {
            dataField: 'msbId',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
        }
    ];

    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                <Modal size="lg" show={showAddressForm} onHide={() => setShowAddressForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <h2>{address ? "Edit Address" : "Add Address"}</h2>
                        <ClientAddressForm
                            isSaving={isSaving}
                            isFetching={isFetching}
                            client={client}
                            address={address}
                            actionToken={address ? addressSaveActionToken : addressAddActionToken}
                            formActionProps={{
                                showSpinner: isFetching,
                                hasSubmit: true,
                                isEditMode: address ? true : false,
                                onCancel: () => setShowAddressForm(false),
                                customProps: {
                                    submitButtonDisplay: address? "Save" : "Add"
                                }
                            }}
                        />
                    </Modal.Body>
                </Modal>

                <FormHeader title="Client Addresses" description="Please enter the client's addresses." />

                <div className="manage-card">
                  <PageSectionContainer title="Manage Addresses" toolbar={doToolbar()}>
                      <Table 
                        keyField="msbId" 
                        columns={columns} 
                        data={client.clientAddresses} 
                      />
                  </PageSectionContainer>
                </div>

                <div className="manage-footer">
                  <Button type="button" variant="link" onClick={() => setRedirect(true)} style={{ float: "right" }}>Cancel</Button>
                </div>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(ClientAddressesSection);