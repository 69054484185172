import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import RequiredIcon from "../../../../components/RequiredIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import FormHeader from "../../../clients/components/forms/FormHeader";
import { AccountDDATypeEnum, Client, ClientBanking, ClientName, DepartmentName } from "../../../../models/Client";
import { IAppState } from "../../../../redux/storeTypes";
import { connect, useDispatch } from "react-redux";
import { User } from "../../../../models/User";
import { saveClientBankingAction } from "../../../../redux/actions/clients/clients";
import axios from "axios";
import store from "../../../../redux/store";
import PermissionModule, { Permissions } from '../../../../components/usePermissions';

interface IClientBanking {
    isFetching?: boolean,
    isSaving?: boolean,
    isVisible?: boolean,
    clientBanking?: ClientBanking,
    clients?: Array<Client>;
    currentUser: User;
    closeModal?: any;
}
export const updatingClientBankingToken = "UpdatingClientBanking"

const ClientBankingModal = ({ clientBanking, clients, currentUser, isSaving, closeModal }: IClientBanking) => {

    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const actionToken = "ClientBanking";

    const [showAccountNumber, setShowAccountNumber] = useState(false);
    const [clientList, setClientList] = useState<any>([]);
    const [departmentList, setDepartmentList] = useState<any>([]);

    const [selectedClient, setSelectedClient] = useState<string>('');
    const [selectedDepartment, setSelectedDepartment] = useState<string>('');
    const [detailId, setDetailId] = useState<any>('');

    const [bankName, setBankName] = useState<string>('');
    const [touched, setTouched] = useState<boolean>(false);

    useEffect(() => {
        if (clients && currentUser) {
            const clientDropdown = clients?.filter(clientDetails => currentUser?.userClients.some(userClient => clientDetails.msbId === userClient.clientMSBId));
            setClientList(clientDropdown);
        }
    }, [clients, currentUser])

    useEffect(() => {
        if (clientBanking) {
            setSelectedClient(clientBanking.clientMsbId!);
            const departmentDropdown = clients?.map(clientDetails => { if (clientDetails.msbId == clientBanking.clientMsbId!) { return clientDetails.departments } }).filter(Boolean)!;
            setDepartmentList(departmentDropdown[0]);
            setSelectedDepartment(clientBanking.departmentMsbId)
            const detailId = departmentDropdown[0]?.map((departmentDetails: any) => { if (departmentDetails.msbId == clientBanking.departmentMsbId) { return departmentDetails?.bankFundingDescriptor } }).filter(Boolean)!;
            setDetailId(detailId);
        }

    }, [clientBanking])


    const handleOnlyNumeric = (e: any) => {
        if (e.key === " " || isNaN(e.key)) {
            e.preventDefault();
        }
    };

    const handleClientSelection = (event: any) => {
        setSelectedClient(event.target.value)
        const departmentDropdown = clients?.map(clientDetails => { if (clientDetails.msbId == event.target.value) { return clientDetails.departments } }).filter(Boolean)!;
        setDepartmentList(departmentDropdown[0]);
        setSelectedDepartment('');
        setDetailId('');
    }

    const handleDepartmentSelection = (event: any) => {
        setSelectedDepartment(event.target.value);
        const detailId = departmentList?.map((departmentDetails: any) => { if (departmentDetails.msbId == event.target.value) { return departmentDetails?.bankFundingDescriptor } }).filter(Boolean)!;
        setDetailId(detailId);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() !== false && bankName) {
            let _clientBanking: ClientBanking = Object.assign({}, clientBanking!!);

            _clientBanking.msbId = clientBanking?.msbId!!;
            _clientBanking.clientMsbId = form.elements.client.value;
            _clientBanking.departmentMsbId = form.elements.department.value;
            _clientBanking.accountDDATypeEnum = form.elements.accountType.value;
            _clientBanking.bankAccountNumber = form.elements.bankAccountNumber.value;
            _clientBanking.bankRoutingNumber = form.elements.bankRoutingNumber.value;
            _clientBanking.detailId = form.elements.detailId.value;
            _clientBanking.additionalDetails = form.elements.additionalDetails.value;
            dispatch(saveClientBankingAction(_clientBanking, _clientBanking.msbId ? updatingClientBankingToken : actionToken));
        }
        setValidated(true);
    };
    
    useEffect(() => {
        if (clientBanking) {
            let val = clientBanking?.bankRoutingNumber;
            if (val && val.length === 9) {
                getBankName(val);
                setTouched(true);
            }
        }
    }, [clientBanking])

    const getBankName = (routingNumber: string) => {
        axios
            .get(`${store.getState().webAppSettings.baseApiUrl}/MSB_ECheck/api/v1/ECheckRoutingNumber/${routingNumber}`)
            .then(response => {
                if (response.status === 200 && response.data.routingNumber === routingNumber) setBankName(response.data.custName);
            })
            .catch(error => { console.log('Failed to lookup bank routing number', error) });
    };

    const handleRoutingNumberOnBlur = (e: any) => {
        const val = e.target.value;
        if (val.length === 9) {
            getBankName(val);
        } else {
            setBankName('');
        }
        setTouched(true);
    }

    const submitButtons = () => {
        return (
            <Form.Row className="form-footer">
                <Button type="submit" disabled={isSaving}>Save</Button>
                <Button type="button" variant="link" style={{ marginLeft: 'auto' }} onClick={closeModal} disabled={isSaving}>Cancel</Button>
            </Form.Row>
        )
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <FormHeader title={clientBanking ? "Edit Record" : "Add Record"} description="Please enter the client finance details below" />
            <Row>
                <Col md={6}>
                    <Form.Group controlId="client">
                        <Form.Label><RequiredIcon />Client</Form.Label>
                        <Form.Control as="select" type="input" required disabled={isSaving} value={selectedClient} onChange={handleClientSelection}>
                            <option key={"client_default"} value="" selected disabled hidden>Select Client</option>
                            {clientList.length > 1 &&
                                clientList.map((client: any, index: any) => (
                                    <option key={`client_${client.msbId}`} value={client.msbId}>{client.businessName}</option>
                                ))
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select a Client</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="department">
                        <Form.Label><RequiredIcon />Department</Form.Label>
                        <Form.Control as="select" type="input" required disabled={isSaving || selectedClient === ''} value={selectedDepartment} onChange={handleDepartmentSelection}>
                            <option key={"client_default"} value="" selected disabled hidden>Select Department</option>
                            {departmentList &&
                                departmentList?.map((department: any, index: any) => (
                                    <option key={`client_${department.msbId}`} value={department.msbId}>{department.dbaName}</option>
                                ))
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select a Department</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="accountType">
                        <Form.Label><RequiredIcon />Account type</Form.Label>
                        <Form.Control required as="select" defaultValue={clientBanking?.accountDDATypeEnum}>
                            <option value="" selected disabled hidden>Select Account type</option>
                            <option value={AccountDDATypeEnum.Checking}>Checking</option>
                            <option value={AccountDDATypeEnum.Savings}>Savings</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select Account type</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="bankAccountNumber">
                        <Form.Label><RequiredIcon />Account number</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control className="password" pattern="^\d*$" maxLength={17} type={showAccountNumber ? "text" : "password"} placeholder="Enter Account number" required disabled={isSaving} onKeyPress={(e: any) => handleOnlyNumeric(e)} defaultValue={clientBanking?.bankAccountNumber} />
                            <InputGroup.Append>
                                <InputGroup.Text onClick={() => setShowAccountNumber(!showAccountNumber)}
                                >
                                    <FontAwesomeIcon icon={showAccountNumber ? faEyeSlash : faEye} size="sm" />
                                </InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control.Feedback type="invalid">Please enter an Account number.</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="bankRoutingNumber">
                        <Form.Label><RequiredIcon />Routing number</Form.Label>
                        <Form.Control type="input" pattern="^\d*$" minLength={9} maxLength={9} placeholder="Enter Routing number" required disabled={isSaving} onKeyPress={(e: any) => handleOnlyNumeric(e)} defaultValue={clientBanking?.bankRoutingNumber} onChange={(e: any) => handleRoutingNumberOnBlur(e)} isInvalid={!bankName && touched} />
                        <Form.Control.Feedback type="invalid">Please enter a valid Routing number</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="eCheckBankName">
                        <Form.Label><RequiredIcon />Bank Name</Form.Label>
                        <Form.Control type="input" readOnly value={bankName} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="detailId">
                        <Form.Label>Detail ID</Form.Label>
                        <Form.Control type="input" disabled={true} value={detailId} />
                        <Form.Control.Feedback type="invalid">Please enter Detail ID</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Form.Group controlId="additionalDetails" >
                        <Form.Label><RequiredIcon />Additional details</Form.Label>
                        <Form.Control required as="textarea" rows={3} placeholder='Enter additional details' defaultValue={clientBanking?.additionalDetails} />
                        <Form.Control.Feedback type="invalid">Please enter additional details</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            {clientBanking ?
                <PermissionModule
                    permission=
                    {[
                        Permissions.ClientBankingUpdate
                    ]}
                >
                    {submitButtons()}
                </PermissionModule>
                :
                <PermissionModule
                    permission=
                    {[
                        Permissions.ClientBankingCreate
                    ]}
                >
                    {submitButtons()}
                </PermissionModule>
            }
        </Form>
    )

}

const mapStateToProps = (state: IAppState) => {
    return {
        currentUser: state.auth.currentUser,
        clients: state.clients.currentPage?.data,
        isSaving: state.clients.isSaving,
        clientBanking: state.clients.clientBanking
    };
};

export default connect(mapStateToProps)(ClientBankingModal);

