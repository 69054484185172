import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Client, ClientStatusEnum } from '../../../../models/Client';
import { IAppState } from '../../../../redux/storeTypes';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { ADD_CLIENT_REQUEST, ADD_CLIENT_SUCCESS, ADD_CLIENT_FAILURE } from '../../../../redux/actions/clients/clients';
import { cancelAction, nextStepAction, OnboardingTypeEnum } from '../../../../redux/actions/clients/clientOnboarding';
import { Routes } from '../../../../routes';
import ClientDetailsForm from "../forms/ClientDetailsForm";
import { StepProps } from '../StepContainer';
import FormHeader from '../forms/FormHeader';

const ClientDetailsStep = ({ client, isFetching, isSaving, actionResult }: StepProps) => {
    let actionToken = "ClientDetailsStep";
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<boolean>(false);

    const clientAddActionToken = "ClientAdd";

    if (client === null) {
        client = new Client();
    }

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === ADD_CLIENT_REQUEST && actionResult.token === clientAddActionToken) {
            if (actionResult.result === ADD_CLIENT_SUCCESS) {
                //dispatch(nextStepAction(OnboardingTypeEnum.Client));
                //dispatch(saveClientStatusAction(client, ClientStatusEnum.OnboardingWizardClientDetails));
                dispatch(sendSuccessToastAction("Client was successfully added."));
                setRedirect(true);
            } else if (actionResult.result === ADD_CLIENT_FAILURE) {
                dispatch(sendErrorToastAction("Client could not be added."));
            }
        }
    }, [actionResult]);

    if (redirect) {
        dispatch(cancelAction());
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                <FormHeader title="Client Details" description="Please enter in the Client's details below to begin the setup process." />
                <ClientDetailsForm
                    isFetching={isFetching}
                    isSaving={isSaving}
                    client={client}
                    actionToken={clientAddActionToken}
                    formActionProps={{
                        showSpinner: isFetching,
                        hasSubmit: true,
                        isEditMode: client ? true : false,
                        onCancel: () => setRedirect(true),
                        customProps: {
                            submitButtonDisplay: "Add"
                        }
                    }}
                />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.clients.isSaving,
        isFetching: state.clients.isFetching,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(ClientDetailsStep);