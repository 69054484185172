import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../../redux/storeTypes';
import { Col, Row, Form } from 'react-bootstrap';
import { sendSuccessToastAction, sendWarningToastAction } from '../../../../../redux/actions/toast';
import RequiredIcon from '../../../../../components/RequiredIcon';
// @ts-ignore
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Routes } from "../../../../../routes";
import { Redirect } from 'react-router-dom';
import { LabelValue, User } from "../../../../../models/User";
import { Client } from "../../../../../models/Client";
import FormHeader from '../../../../clients/components/forms/FormHeader';
import ReactPlaceholder from 'react-placeholder';
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";

interface IUserDetails {
    client?: any;
    isFetching?: boolean;
    isSaving?: boolean;
    user?: User;
}

const UserDetails = ({ isSaving, isFetching, client, user }: IUserDetails) => {

    const actionToken = "ClientUserDetails"
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [validated, setValidated] = useState(false);
    const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);

    let availableDepartments: Array<LabelValue> = [];
    availableDepartments = client.departments.map((department: any) => new LabelValue(department.name, department.msbId!!));


    useEffect(() => {
        if (user != null && user.userDepartments.length) {
            let _selectedDepartments: Array<string> = [];
            user.userDepartments.forEach(x => _selectedDepartments.push(x.departmentMSBId));
            setSelectedDepartments(_selectedDepartments);
        }
    }, [user]);

    const dualListChange = (event: Array<string>) => {
        setSelectedDepartments(event);
        document.forms[0]["selectedDepartments"].value = event;
    }


    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <FormHeader title="Details" description="Enter the user's details below." />
                <Row >
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="emailAddress">
                            <Form.Label><RequiredIcon />Email address</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="email" placeholder="email@address.com" defaultValue={user ? user.email : ''} disabled={isSaving || user ? user?.email != '' : false} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="phoneNumber" >
                            <Form.Label><RequiredIcon />Phone number</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <InputMask
                                    required type="input"
                                    id="phoneNumber"
                                    pattern="[0-9\-\(\)\s]{14}"
                                    name="phoneNumber"
                                    placeholder="(123) 456-7890"
                                    className="form-control"
                                    mask="(999) 999-9999"
                                    defaultValue={user ? user.phoneNumber : ''}
                                    disabled={isSaving} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a phone number.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row >
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="firstName" >
                            <Form.Label><RequiredIcon />First name</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>

                                <Form.Control required type="input" pattern="^([a-zA-z\s'-])*$" maxLength={50} placeholder="First name" defaultValue={user ? user.firstName : ''} disabled={isSaving} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="lastName">
                            <Form.Label><RequiredIcon />Last Name</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="input" pattern="^([a-zA-z\s'-])*$" maxLength={50} placeholder="Last name" defaultValue={user ? user.lastName : ''} disabled={isSaving} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row >
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="Departments">
                            <Form.Label><RequiredIcon />Departments</Form.Label>
                        </Form.Group>
                        <input type="hidden" id="selectedDepartments" name="selectedDepartments" value={selectedDepartments} />
                        <ReactPlaceholder type='text' rows={6} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <DualListBox
                                disabled={isSaving}
                                selected={selectedDepartments}
                                options={availableDepartments}
                                onChange={dualListChange}
                                className="transfer"
                                showHeaderLabels={true}
                                lang={{
                                    availableHeader: 'Available departments',
                                    selectedHeader: 'User\'s departments'
                                }}
                                icons={{
                                    moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                                    moveAllLeft: [
                                        <FontAwesomeIcon icon={faChevronLeft} key={0} />,
                                        <FontAwesomeIcon icon={faChevronLeft} key={1} />,
                                    ],
                                    moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                                    moveAllRight: [
                                        <FontAwesomeIcon icon={faChevronRight} key={0} />,
                                        <FontAwesomeIcon icon={faChevronRight} key={1} />,
                                    ],
                                }}
                            />
                        </ReactPlaceholder>
                    </Col>
                </Row>
            </>
        );
    }
};
const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.users.isFetching,
        isSaving: state.users.isSaving,
        user: state.users.user,
        client: state.clients.client,

    };
};

export default connect(mapStateToProps)(UserDetails);