import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Routes } from "../../../../../routes";
import { ButtonToolbar, Button, Dropdown, Modal, Col, Row, Form, InputGroup, FormControl, DropdownButton, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import PageSectionContainer from '../../../../../components/layout/PageSectionContainer';
import { IActionResult, IAppState } from '../../../../../redux/storeTypes';
import {
    GET_WEB_APPLICATION_REQUEST, GET_WEB_APPLICATION_SUCCESS, GET_WEB_APPLICATION_FAILURE,
    SAVE_WEB_APPLICATION_REQUEST, SAVE_WEB_APPLICATION_SUCCESS, SAVE_WEB_APPLICATION_FAILURE
} from '../../../../../redux/actions/clients/paymentChannelWebApplications';
import { sendErrorToastAction, sendSuccessToastAction, sendWarningToastAction } from '../../../../../redux/actions/toast';
import { ContactTypeEnum, PaymentChannelTypeEnum, Department, PaymentChannel, Client, WebChannelTypeEnum } from '../../../../../models/Client';
import { Redirect } from 'react-router-dom';
import FormHeader from '../../../../clients/components/forms/FormHeader';
import { WebApplication, WebPage, OpenLinkEnum, SiteSettings, SiteSetingsNavigationLinks, WebApplicationEnum } from '../../../../../models/PaymentChannelWebApplication';
import Table from '../../../../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../../../../components/Formatters';
import PageHeader from '../../../../../components/layout/PageHeader';
import { Content, Revision, StatusEnum } from "../../../../../models/CMS";
import { getContentAction, saveContentAction} from "../../../../../redux/actions/cms";
import { SAVE_CMS_CONTENT_FAILURE, SAVE_CMS_CONTENT_REQUEST, SAVE_CMS_CONTENT_SUCCESS } from "../../../../../redux/actions/cms";
import { saveWebPageAction, getWebPageAction, saveWebApplicationAction } from "../../../../../redux/actions/clients/paymentChannelWebApplications";
import FormActions, { IFormActionProps } from '../../../../../pages/clients/components/forms/FormActions';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import FormHeaderConfirmation from "../../../../../components/FormHeaderConfirmation";
import icoWarning from "../../../../../assets/img/icons/ico-warning-outline.svg";
import Switch from "react-switch";
import RequiredIcon from "../../../../../components/RequiredIcon";

export interface ISiteSettingsSectionProps {
    isFetching: boolean,
    isSaving: boolean,
    actionResult?: IActionResult,
    webApplication: WebApplication,
    formActionProps?: IFormActionProps,
    cmsActionResult: IActionResult,
    content: Content,
    client: Client
}

const SiteSettingsSection = ({ isFetching, isSaving, actionResult, webApplication, formActionProps, cmsActionResult, content, client }: ISiteSettingsSectionProps) => {
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const dispatch = useDispatch();
    const actionToken = "SiteSettingsSection";
    const [validated, setValidated] = useState(false);
    const [linkLabelRows, setlinkLabelRows] = useState([new SiteSetingsNavigationLinks()]);
    const [showModal, setModal] = useState(false); 
    const [deleteNavigationLinkIndex, setDeleteNavigationLinkIndex] = useState(0);
    const [settings, setSettings] = useState({ itemsHaveInstallments: false, itemNumberOfInstallments: '' })

    /*
    useEffect(() => {
        if (cmsActionResult && cmsActionResult.result) {
            if (cmsActionResult.type === SAVE_CMS_CONTENT_REQUEST && cmsActionResult.token === actionToken) {
                if (cmsActionResult.result === SAVE_CMS_CONTENT_SUCCESS) {
                    webApplication.siteSettingsContentId = content.msbId!;
                    webApplication.webApplicationType = WebApplicationEnum[WebChannelTypeEnum[webApplication.paymentChannel.webChannelType]] as keyof typeof WebApplicationEnum;
                    dispatch(saveWebApplicationAction(webApplication, actionToken));
                } else if (cmsActionResult.result === SAVE_CMS_CONTENT_FAILURE) {
                    dispatch(sendErrorToastAction("Site settings could not be updated."));
                }
            }
        }
    }, [cmsActionResult]);
    */

    useEffect(() => {
        setSettings({itemsHaveInstallments: webApplication?.settings?.itemsHaveInstallments!, itemNumberOfInstallments: webApplication?.settings?.itemNumberOfInstallments!} );
    }, [])

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === SAVE_WEB_APPLICATION_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_WEB_APPLICATION_SUCCESS) {
                    dispatch(sendSuccessToastAction("Site settings has been successfully saved."));
                } else if (actionResult.result === SAVE_WEB_APPLICATION_FAILURE) {
                    dispatch(sendErrorToastAction("Site settings could not be updated."));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    /*
    React.useEffect(() => {
        if (content && webApplication) {
            if (content.msbId === webApplication.siteSettingsContentId) {
                let _siteSettingLinks: SiteSettings = new SiteSettings();
                for (let x = 0; x < content.revisions.length; x++) {
                    if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                        let revision = content.revisions[x];
                        _siteSettingLinks = JSON.parse(revision.value) as SiteSettings;
                        setSiteSettings(_siteSettingLinks);
                        break;
                    }
                }

                if (_siteSettingLinks !== undefined && _siteSettingLinks !== null) {
                    if (_siteSettingLinks.navigationLinks !== null) {
                        setlinkLabelRows(_siteSettingLinks.navigationLinks);
                    }
                }
            }
        }
    }, [content, webApplication]);
    */

    const handleSubmitSiteSettings = (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            webApplication.subdomain = form.elements.subdomain?.value;
            webApplication.externalReferenceId = form.elements.externalReferenceId?.value;
            webApplication.greenListedInlineFrameUrl = form.elements.greenListedInlineFrameUrl?.value;
            webApplication.settings = settings.itemsHaveInstallments ?
                { itemsHaveInstallments: settings.itemsHaveInstallments, itemNumberOfInstallments: parseInt(settings.itemNumberOfInstallments) }
                :
                { itemsHaveInstallments: settings.itemsHaveInstallments }
            /*
            let _siteSettings: SiteSettings = new SiteSettings();
            if (_siteSettings !== null) {
                _siteSettings = Object.assign({}, _siteSettings!!);

                _siteSettings.siteUrlSubDomainName = form.elements.subdomain?.value;
                _siteSettings.siteUrlClientDefinedDNSName = form.elements.clientDefinedDNSUrl?.value;
                _siteSettings.siteUrlPostPaymentRedirectUrl = form.elements.postPaymentRedirectUrl?.value;

                let _navigationLinks: SiteSetingsNavigationLinks = new SiteSetingsNavigationLinks();
                if (_navigationLinks !== null) {
                    _navigationLinks = Object.assign({}, _navigationLinks!!);

                    if (linkLabelRows != null && linkLabelRows?.length > 0) {
                        _siteSettings.navigationLinks = linkLabelRows;
                    }
                    else {
                        _navigationLinks.navigationLinkLabel = "link label";
                        _navigationLinks.navigationLinkUrl = "www.example.com";
                        _navigationLinks.navigationLinkOpenLink = OpenLinkEnum.ExistingTab;
                    }
                }

                if (webApplication)
                {
                    if (!content || webApplication.siteSettingsContentId !== content.msbId || content.title !== "siteSettings") {
                        content = new Content();
                        content.title = "siteSettings";
                        content.groupId = webApplication.msbId!;
                        content.clientId = client.msbId!;
                    }

                    let revision: Revision | null = null;
                    for (let x = 0; x < content.revisions.length; x++) {
                        if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                            revision = content.revisions[x];
                            break;
                        }
                    }

                    let strSiteSettings = JSON.stringify(_siteSettings);
                    if (revision === null) {
                        revision = new Revision();
                        revision.statusEnum = StatusEnum.Draft;
                        revision.value = strSiteSettings;
                        content.revisions.push(revision);
                    }
                    else {
                        revision.value = strSiteSettings;
                    }

                    // REMOVE use of CMS to store Site-Settings as content.
                    //dispatch(saveContentAction(content, actionToken));
                }
            }
            //webApplication.siteSettingsContentId = content.msbId!;
            */
            webApplication.webApplicationType = WebApplicationEnum[WebChannelTypeEnum[webApplication.paymentChannel.webChannelType]] as keyof typeof WebApplicationEnum;
            dispatch(saveWebApplicationAction(webApplication, actionToken));
        }

        setValidated(true);
    };

    const addLinkLabelRow= (e: any, index: number) => {
        if (index < 4) {
            linkLabelRows.splice(index + 1, 0, new SiteSetingsNavigationLinks());
            setlinkLabelRows(new Array<SiteSetingsNavigationLinks>().concat(linkLabelRows));
        }
    }

    const deleteLinkLabelRow = (e: any, index: number) => {
        setModal(true);
        setDeleteNavigationLinkIndex(index);
    }

    const handleInputChange = (e:any, index:any) => {
        var list = [...linkLabelRows];
        
        if(e.target.name === "navigationLinkLabel"){
            list[index].navigationLinkLabel = e.target.value;
        }else if(e.target.name === "navigationLinkUrl"){
            list[index].navigationLinkUrl = e.target.value;
        } else if(e.target.name === "navigationLinkOpenLink"){
            list[index].navigationLinkOpenLink = e.target.value;
        }
        setlinkLabelRows(list);
    };

    const siteSettingNavLinkConfirmAction = (event: any, index: number) => {
        setModal(false);
        const navLinkvalues = [...linkLabelRows]
        navLinkvalues.splice(index, 1);
        setlinkLabelRows(navLinkvalues);
    }

    const renderSpinner = (isSaving:boolean) => {
        if (isSaving) {
            return(<Spinner as="span" animation="border" size="sm" className={"button-spinner"} />);
        } else {
            return(<></>);
        }
    }

    const showKey = (ids: string) => {
        if (ids) {
            ids = ids?.replace(/-/g, "");
            for (var buffer = [], c = 0; c < ids.length; c += 2)
                buffer.push(parseInt(ids.substr(c, 2), 16));
            let binary = "";
            let bytes = new Uint8Array(buffer);
            let len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary).replace(/[-+=\/]/g, "");
        } else {
            return "";
        }
    }
   
    const navigationLinkTableView = (linkLabelRows: SiteSetingsNavigationLinks[]) => (
        <>
            <Modal show={showModal} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Delete navigation link" />
                    <div className="confirmation-body">
                        <p>Are you sure you want to delete this navigation link?</p>
                        <Form.Group className="confirmation-footer">
                            <Button variant="outline-secondary" onClick={() => setModal(false)}>
                                Cancel
                            </Button>
                            <Button onClick={(e) => siteSettingNavLinkConfirmAction(e, deleteNavigationLinkIndex)}>
                                Confirm
                            </Button>
                        </Form.Group>
                    </div>        
                </Modal.Body>
            </Modal>
            {
                linkLabelRows?.map((_row, idx) => (
                    <Row>
                        <Form.Group as={Col} lg={3} md={6} sm={6}>
                            <Form.Label>Link label</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                <Form.Control
                                    type="input"
                                    defaultValue={linkLabelRows[idx]?.navigationLinkLabel}
                                    placeholder="Enter Link label"
                                    onChange={e => handleInputChange(e, idx)}
                                    disabled={isSaving}
                                />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Navigation Link label required.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} lg={3} md={6} sm={6}>
                            <Form.Label>Link URL</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>
                                    https://
                                </InputGroup.Text>
                                <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>    
                                    <FormControl
                                        type="input"
                                        placeholder="www.example.com"
                                        defaultValue={linkLabelRows[idx]?.navigationLinkUrl}
                                        onChange={e => handleInputChange(e, idx)}
                                        disabled={isSaving}
                                    />
                                </ReactPlaceholder>
                                <Form.Control.Feedback type="invalid">Navigation link URL required.</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        
                        <Form.Group as={Col} lg={3} md={6} sm={6}>
                            <Form.Label>Open link</Form.Label>
                            <Form.Control required as="select" defaultValue={linkLabelRows[idx]?.navigationLinkOpenLink} onChange={e => handleInputChange(e, idx)} >
                                <option value={OpenLinkEnum.NewTab}>New Tab</option>
                                <option value={OpenLinkEnum.ExistingTab}>Existing Tab</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} lg={3} md={6} sm={6}>
                            <Form.Label>&nbsp;</Form.Label>
                            <div className="buttons">
                                {/* <Button variant="primary" onClick={(e) => addLinkLabelRow(e, idx)}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button> */}
                                {
                                    idx <= linkLabelRows?.length - 1 && (
                                        <Button variant="outline-secondary" onClick={(e) => deleteLinkLabelRow(e, idx)} >
                                            <FontAwesomeIcon icon={faTrash} size="sm" />
                                        </Button>
                                    )
                                }

                            </div>
                        </Form.Group>
                    </Row>
                )
              )
            }
        </>
    );
    return (
        <>
            <FormHeader title="Site settings" description="Below are the options to select that give you control over the client's website design and settings." />
            <Form noValidate validated={validated} onSubmit={handleSubmitSiteSettings}>
                <Row>
                    <Col>
                        <div className="manage-card mb-4">
                            <div className="site-urls">
                                <Card>
                                    <Card.Header>
                                        <h2>Site URLs</h2>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Form.Group as={Col} controlId="subdomain">
                                                <Form.Label>Subdomain</Form.Label>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text>https://payments.msbpay.navient.com/</InputGroup.Text>
                                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                            <FormControl required defaultValue={webApplication?.subdomain } aria-describedby="basic-addon3" placeholder="subdomain" disabled={isSaving} />
                                                    </ReactPlaceholder>
                                                    <Form.Control.Feedback type="invalid"> Site subdomain required.</Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} controlId="externalReferenceId">
                                                <Form.Label>External reference ID</Form.Label>
                                                <Col>
                                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                        <FormControl required defaultValue={ webApplication?.externalReferenceId } aria-describedby="basic-addon3" placeholder="Enter external reference ID" disabled={isSaving} />
                                                    </ReactPlaceholder>
                                                </Col>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            {/*
                                            <Form.Group as={Col} md={6} sm={12} controlId="clientDefinedDNSUrl">
                                                <Form.Label>Client defined DNS</Form.Label>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            https://
                                                    </InputGroup.Text>
                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                            <FormControl defaultValue={siteSettings?.siteUrlClientDefinedDNSName } aria-describedby="basic-addon3" placeholder="www.example.com" disabled={isSaving}/>
                                                        </ReactPlaceholder>
                                                        <Form.Control.Feedback type="invalid">Client defined DNS required.</Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Col} md={6} sm={12} controlId="postPaymentRedirectUrl">
                                                <Form.Label>Post-payment redirect URL</Form.Label>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            https://
                                                    </InputGroup.Text>
                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                            <FormControl defaultValue={ siteSettings?.siteUrlPostPaymentRedirectUrl } aria-describedby="basic-addon3" placeholder="www.example.com" disabled={isSaving}/>
                                                        </ReactPlaceholder>
                                                        <Form.Control.Feedback type="invalid">Post-payment redirect URL required.</Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            */}
                                            <Form.Group as={Col} md={6} sm={12} controlId="greenListedInlineFrameUrl">
                                                <Form.Label>Allow iFrame exception URL</Form.Label>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            https://
                                                        </InputGroup.Text>
                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                                            <FormControl defaultValue={webApplication?.greenListedInlineFrameUrl} aria-describedby="basic-addon3" placeholder="www.example.com" disabled={isSaving} />
                                                        </ReactPlaceholder>
                                                        <Form.Control.Feedback type="invalid">Allow iFrame exception URL required.</Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md={4} sm={12}>
                                                <Form.Label>Hosted Payment Page Key</Form.Label>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            {showKey(webApplication?.msbId + webApplication?.paymentChannelMsbId)}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Col} md={4} sm={12}>
                                                <Form.Label>Hosted Payment Status API Key</Form.Label>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            {showKey(client?.msbId + client?.departments?.find(d => d?.paymentChannels?.find(p => p?.msbId === webApplication?.paymentChannelMsbId))?.msbId!)}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Col} md={4} sm={12}>
                                                <Form.Label>Payment Channel ID</Form.Label>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            {webApplication?.paymentChannelMsbId}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="manage-card mb-4">
                                                    <div>
                                                        <Card>
                                                            <Card.Header>
                                                                <h2>Settings</h2>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <div>
                                                                    <div className="toggle-switch">
                                                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                                                            <Switch
                                                                                onChange={e => setSettings({ itemsHaveInstallments: e, itemNumberOfInstallments: '' })}
                                                                                checked={settings?.itemsHaveInstallments}
                                                                                onColor={'#0057B6'}
                                                                                offColor={'#BEBEBE'}
                                                                                handleDiameter={12}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                height={16}
                                                                                width={28}
                                                                                disabled={isSaving}
                                                                                activeBoxShadow={'0 0 0 1px #0057B6'}
                                                                            />
                                                                        </ReactPlaceholder>
                                                                        <span className="toggle-label">Has Installments</span>
                                                                    </div>
                                                                    {settings?.itemsHaveInstallments &&
                                                                        <Form.Group as={Row} className="mb-3" controlId="installmentNumber">
                                                                            <Form.Label column sm="3">
                                                                                <RequiredIcon />Number Of Installments
                                                                            </Form.Label>
                                                                            <Col sm="4">
                                                                                <Form.Control required type="input" placeholder="Enter Installments"
                                                                                    value={settings?.itemNumberOfInstallments}
                                                                                    onChange={(e) => setSettings({ itemsHaveInstallments: true, itemNumberOfInstallments: e.target.value })}
                                                                                    disabled={isSaving} />
                                                                            </Col>
                                                                            <Form.Control.Feedback type="invalid">Please enter number of installments</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    }
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/*
                <Row>
                    <Col>
                        <div className="manage-card mb-4">
                            <div className="navigation-links">
                                <Card>
                                    <Card.Header>
                                        <h2>Navigation links</h2>
                                    </Card.Header>
                                    <Card.Body>
                                        <p>You have the ability to add up to four (4) navigation links to the client's website.</p>
                                        {navigationLinkTableView(linkLabelRows)}

                                        <Button variant="outline-secondary" className="mt-3 w-100" onClick={(e) => addLinkLabelRow(e, linkLabelRows?.length)} >
                                            <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Link
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>
                */}
                <Row>
                    <Col>
                        <div className="web-settings-footer">
                            <Button variant="outline-secondary">Preview</Button>
                            <Button variant="outline-secondary" type="submit" disabled={isSaving}>Save Draft</Button>
                            {renderSpinner(isSaving)}
                            {/*<Button variant="primary" disabled={isSaving}>Submit for Approval</Button>*/}
                        </div>
                    </Col>
                </Row>

                {/* <FormActions {...formActionProps} /> */}
            </Form>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.paymentChannelWebApplication.isFetching,
        isSaving: state.paymentChannelWebApplication.isSaving,
        actionResult: state.paymentChannelWebApplication.actionResult,
        webApplication: state.paymentChannelWebApplication.webApplication,
        cmsActionResult: state.cms.actionResult,
        content: state.cms.content,
        client: state.clients.client
    };
};
export default connect(mapStateToProps)(SiteSettingsSection);