import { CellPlugin } from '@react-page/editor';
import React from 'react';
import './../../../../../scss/nexus/cms/website-header.scss';

import IconAmex from '../../../../../assets/img/icons/AMEX-Color.svg';
import IconDiscover from '../../../../../assets/img/icons/Discover-Color.svg';
import IconEcheck from '../../../../../assets/img/icons/eCheck-Color.svg';
import IconJcb from '../../../../../assets/img/icons/JCB-Color.svg';
import IconMastercard from '../../../../../assets/img/icons/Mastercard-Color.svg';
import IconVisa from '../../../../../assets/img/icons/Visa-Color.svg';

// use a type here, not an interface
type Data = {
  text: string,
  displayVisa: string,
  displayMastercard: string,
  displayDiscover: string,
  displayAmex: string,
  displayEcheck: string,
  displayJcb: string,
}

const DISPLAY_VISA = 'block';
const DISPLAY_MASTERCARD = 'block';
const DISPLAY_DISCOVER = 'block';
const DISPLAY_AMEX = 'block';
const DISPLAY_ECHECK = 'none';
const DISPLAY_JCB = 'none';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
  return style && style !== ''?style:defaultStyle;
}

const PaymentLogosPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <div className="msb-portal__payment-icons mx-auto">
          <img src={IconVisa} alt="icon" style={{ display:checkStyleForDefault(data.displayVisa, DISPLAY_VISA) }} />
          <img src={IconMastercard} alt="icon" style={{ display:checkStyleForDefault(data.displayMastercard, DISPLAY_MASTERCARD) }} />
          <img src={IconDiscover} alt="icon" style={{ display:checkStyleForDefault(data.displayDiscover, DISPLAY_DISCOVER) }} />
          <img src={IconAmex} alt="icon" style={{ display:checkStyleForDefault(data.displayAmex, DISPLAY_AMEX) }} />
          <img src={IconEcheck} alt="icon" style={{ display:checkStyleForDefault(data.displayEcheck, DISPLAY_ECHECK) }} />
          <img src={IconJcb} alt="icon" style={{ display:checkStyleForDefault(data.displayJcb, DISPLAY_JCB) }} />
        </div>
  ),
  id: 'paymentLogosPlugin',
  title: 'Payment Method Logos',
  description: 'Display the accepted payment method logos.',
  version: 1,
  controls: [
    {
      title: 'Icons',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            displayVisa: {
              type: 'string',
              default: DISPLAY_VISA,
            },
            displayMastercard: {
              type: 'string',
              default: DISPLAY_MASTERCARD,
            },
            displayDiscover: {
              type: 'string',
              default: DISPLAY_DISCOVER,
            },
            displayAmex: {
              type: 'string',
              default: DISPLAY_AMEX,
            },
            displayEcheck: {
              type: 'string',
              default: DISPLAY_ECHECK,
            },
            displayJcb: {
              type: 'string',
              default: DISPLAY_JCB,
            },
          },
          required: [],
        },
      },
    },
  ]
};

export default PaymentLogosPlugin;