import { Row, Col } from "react-bootstrap"
import Table from '../../../../../../components/Table';

interface IForsythSearchResults {
    accountNumberLabel: string,
    billNumberLabel: string,
    billYearLabel: string,
    ownerLabel: string,
    situsAddressLabel: string,
    billStatusLabel: string,
    totalDueLabel: string,
    dueDateLabel: string,
    paymentOptionsLabel: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    overlayText: string,
    overlayBackground: string,
    overlayTextColor: string,
    overlayIconColor: string,
    margin: string,
    padding: string,
    headerBackgroundColor : string,
    headerPadding: string,
}

export const ACCOUNT_NUMBER_LABEL = 'Account Number';
export const BILL_NUMBER_LABEL = 'Bill Number';
export const BILL_YEAR_LABEL = 'Bill Year';
export const OWNER_LABEL = 'Owner';
export const SITUS_ADDRESS_LABEL = 'Situs Address'
export const TOTAL_DUE_LABEL = 'Total Due';
export const DUE_DATE_LABEL = 'Due Date'
export const BILL_STATUS_LABEL = 'Status'
export const PAYMENT_OPTIONS_LABEL = 'Payment Options';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const OVERLAY_TEXT = 'There are no available online payment options on this parcel at this time. Please contact Forsyth County Tax Administration, NC at 336-703-2300.'; 
export const OVERLAY_BACKGROUND = '#fef1b5'; 
export const OVERLAY_TEXT_COLOR = '#1e1e1e';
export const OVERLAY_ICON_COLOR = 'orange';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const ForsythSearchResults = ({ accountNumberLabel, billNumberLabel, billYearLabel, ownerLabel, situsAddressLabel, billStatusLabel,
    totalDueLabel, dueDateLabel, paymentOptionsLabel, durationOfPendingHours, observesWeekends, overlayText, overlayBackground, overlayTextColor,
    overlayIconColor, margin, padding, headerBackgroundColor ,headerPadding }: IForsythSearchResults) => {


    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!billYearLabel) { billYearLabel = BILL_YEAR_LABEL }
    if (!billNumberLabel) { billNumberLabel = BILL_NUMBER_LABEL }
    if (!ownerLabel) { ownerLabel = OWNER_LABEL }
    if (!situsAddressLabel) { situsAddressLabel = SITUS_ADDRESS_LABEL }
    if (!billStatusLabel) { billStatusLabel = BILL_STATUS_LABEL }
    if (!totalDueLabel) { totalDueLabel = TOTAL_DUE_LABEL }
    if (!dueDateLabel) { dueDateLabel = DUE_DATE_LABEL }
    if (!paymentOptionsLabel) { paymentOptionsLabel = PAYMENT_OPTIONS_LABEL }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!overlayText) { overlayText = OVERLAY_TEXT }
    if (!overlayBackground) { overlayBackground = OVERLAY_BACKGROUND }
    if (!overlayTextColor) { overlayTextColor = OVERLAY_TEXT_COLOR }
    if (!overlayIconColor) { overlayIconColor = OVERLAY_ICON_COLOR }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }
    
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const initialColumns = [{
        dataField: 'accountNumber',
        text: accountNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'billNumber',
        text: billNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'billYear',
        text: billYearLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'owner',
        text: ownerLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'situsAddress',
        text: situsAddressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'totalDue',
        text: totalDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,
        sort: true,
    }, {
        dataField: 'dueDate',
        text: dueDateLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'billStatus',
        text: billStatusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    },{
        dataField: 'id',
        text: paymentOptionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

   
    const renderTable = () => {
            return (
                <Table
                    keyField="msbId"
                    data={[]}
                    columns={initialColumns}
                />);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </div>

    )
}

export default ForsythSearchResults;