import axios from "axios";
import { Client, ConvenienceFee, Department, DepartmentStatusEnum, DepartmentStatusRequest, MerchantProcessor, Receipt } from '../../../models/Client';
import { Dispatch } from "react";
import store from "../../../redux/store";

export const ADD_DEPARTMENT_REQUEST = 'ADD_DEPARTMENT_REQUEST';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const ADD_DEPARTMENT_FAILURE = 'ADD_DEPARTMENT_FAILURE';

export const SAVE_DEPARTMENT_REQUEST = 'SAVE_DEPARTMENT_REQUEST';
export const SAVE_DEPARTMENT_SUCCESS = 'SAVE_DEPARTMENT_SUCCESS';
export const SAVE_DEPARTMENT_FAILURE = 'SAVE_DEPARTMENT_FAILURE';

export const DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAILURE = 'DELETE_DEPARTMENT_FAILURE';

export const GET_DEPARTMENT_REQUEST = 'GET_DEPARTMENT_REQUEST';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_FAILURE = 'GET_DEPARTMENT_FAILURE';

export const SET_STORE_DEPARTMENT_REQUEST = 'SET_DEPARTMENT_REQUEST';
export const SET_STORE_DEPARTMENT_SUCCESS = 'SET_DEPARTMENT_SUCCESS';

export const SET_DEPARTMENT_RECEIPT_REQUEST = 'SET_DEPARTMENT_RECEIPT_REQUEST';
export const SET_DEPARTMENT_RECEIPT_SUCCESS = 'SET_DEPARTMENT_RECEIPT_SUCCESS';

export const SAVE_DEPARTMENT_RECEIPT_REQUEST = 'SAVE_DEPARTMENT_RECEIPT_REQUEST';
export const SAVE_DEPARTMENT_RECEIPT_SUCCESS = 'SAVE_DEPARTMENT_RECEIPT_SUCCESS';
export const SAVE_DEPARTMENT_RECEIPT_FAILURE = 'SAVE_DEPARTMENT_RECEIPT_FAILURE';

export const GET_DEPARTMENT_NAMES_REQUEST = 'GET_DEPARTMENT_NAMES_REQUEST';
export const GET_DEPARTMENT_NAMES_SUCCESS = 'GET_DEPARTMENT_NAMES_SUCCESS';
export const GET_DEPARTMENT_NAMES_FAILURE = 'GET_DEPARTMENT_NAMES_FAILURE';

export const SAVE_DEPT_MERCHANT_REQUEST = 'SAVE_DEPT_MERCHANT_REQUEST';
export const SAVE_DEPT_MERCHANT_SUCCESS = 'SAVE_DEPT_MERCHANT_SUCCESS';
export const SAVE_DEPT_MERCHANT_FAILURE = 'SAVE_DEPT_MERCHANT_FAILURE';

export const SAVE_CONVENIENCE_FEES_REQUEST = 'SAVE_CONVENIENCE_FEES_REQUEST';
export const SAVE_CONVENIENCE_FEES_SUCCESS = 'SAVE_CONVENIENCE_FEES_SUCCESS';
export const SAVE_CONVENIENCE_FEES_FAILURE = 'SAVE_CONVENIENCE_FEES_FAILURE';

export const SAVE_DEPARTMENT_STATUS_REQUEST = 'SAVE_DEPARTMENT_STATUS_REQUEST';
export const SAVE_DEPARTMENT_STATUS_SUCCESS = 'SAVE_DEPARTMENT_STATUS_SUCCESS';
export const SAVE_DEPARTMENT_STATUS_FAILURE = 'SAVE_DEPARTMENT_STATUS_FAILURE';

export const RESET_STORE_DEPARTMENT_REQUEST = 'RESET_STORE_DEPARTMENT_REQUEST';
export const RESET_STORE_DEPARTMENT_SUCCESS = 'RESET_STORE_DEPARTMENT_SUCCESS';

export const CLEAR_RECEIPT_TOAST_ACTION_REQUEST ='CLEAR_RECEIPT_TOAST_ACTION_REQUEST';

export const addDepartmentAction = (clientMsbId: string, department: Department, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_DEPARTMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/Clients/${clientMsbId}/department`;
    let client = new Client();

    axios.post(_url, department)
        .then(function (response) {
            client = response.data;
            dispatch({
                type: ADD_DEPARTMENT_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: ADD_DEPARTMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveDepartmentAction = (department: Department, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_DEPARTMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Departments/" + department.msbId;

    axios.put(_url, department)
        .then(function (response) {
            department = response.data;
            dispatch({
                type: SAVE_DEPARTMENT_SUCCESS,
                payload: {
                    department,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_DEPARTMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const deleteDepartmentAction = (departmentMsbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_DEPARTMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Departments/" + departmentMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_DEPARTMENT_SUCCESS,
                payload: {
                    departmentMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_DEPARTMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const getDepartmentNamesAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_DEPARTMENT_NAMES_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Departments/Names";

    axios.get(_url)
        .then(function (response) {
            let departmentNames = response.data;
            dispatch({
                type: GET_DEPARTMENT_NAMES_SUCCESS,
                payload: {
                    departmentNames,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_DEPARTMENT_NAMES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getDepartmentAction = (msbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_DEPARTMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url =  "/MSB_Client/api/v1/Departments/" + msbId;
    let department = new Department();

    axios.get(_url)
        .then(function (response) {
            department = response.data;
            dispatch({
                type: GET_DEPARTMENT_SUCCESS,
                payload: {
                    department,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_DEPARTMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const saveDeptMerchantIdAction = (merchantProcessor: MerchantProcessor, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_DEPT_MERCHANT_REQUEST,
        payload: {
            actionToken
        }
    });
};

export const saveConvenienceFeesAction = (convenienceFees: Array<ConvenienceFee>, actionToken: string, msbId?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CONVENIENCE_FEES_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/Departments/${msbId}/convenienceFees`;
    let department = new Department();

    axios.post(_url, convenienceFees)
        .then(function (response) {
            department = response.data;
            dispatch({
                type: SAVE_CONVENIENCE_FEES_SUCCESS,
                payload: {
                    department,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_CONVENIENCE_FEES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveDepartmentReceiptAction = (receipt: Receipt, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_DEPARTMENT_RECEIPT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/Receipts/${receipt.msbId}/updateContentId?contentId=${receipt.contentId}`;

    axios.put(_url, receipt)
        .then(function (response) {
            receipt = response.data;
            dispatch({
                type: SAVE_DEPARTMENT_RECEIPT_SUCCESS,
                payload: {
                    receipt,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_DEPARTMENT_RECEIPT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const setDepartmentReceiptAction = (receipt:Receipt, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: SET_DEPARTMENT_RECEIPT_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: SET_DEPARTMENT_RECEIPT_SUCCESS,
        payload: {
            receipt,
            actionToken
        }
    });
};

export const setStoreDepartmentAction = (department:Department, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: SET_STORE_DEPARTMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: SET_STORE_DEPARTMENT_SUCCESS,
        payload: {
            department,
            actionToken
        }
    });
};

export const resetStoreDepartmentAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: RESET_STORE_DEPARTMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: RESET_STORE_DEPARTMENT_SUCCESS,
        payload: {
            actionToken
        }
    });
};

export const saveDepartmentStatusAction = (department:Department, departmentStatusEnum:DepartmentStatusEnum, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_DEPARTMENT_STATUS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Departments/Status/" + department.msbId

    let departmentStatusRequest = new DepartmentStatusRequest();
    departmentStatusRequest.departmentMsbId = department.msbId!;
    //departmentStatusRequest.departmentStatusEnum = departmentStatusEnum;

    axios.put(_url, departmentStatusRequest)
        .then(function (response) {
            dispatch({
                type: SAVE_DEPARTMENT_STATUS_SUCCESS,
                payload: {
                    departmentStatusEnum,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_DEPARTMENT_STATUS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
    
};

export const clearReceiptToastActionResult = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: CLEAR_RECEIPT_TOAST_ACTION_REQUEST,
        payload: {
            actionToken
        }
    });
}