import axios from 'axios';
import { Agreement } from '../../../models/Client';
import { Dispatch } from 'react';
import store from "../../../redux/store";

export const SAVE_AGREEMENT_REQUEST = 'SAVE_AGREEMENT_REQUEST';
export const SAVE_AGREEMENT_SUCCESS = 'SAVE_AGREEMENT_SUCCESS';
export const SAVE_AGREEMENT_FAILURE = 'SAVE_AGREEMENT_FAILURE';

export const saveAgreementAction = (agreement: Agreement, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_AGREEMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Agreements"

    if (agreement.msbId === undefined) {
        axios.post(_url, agreement)
            .then(function (response) {
                agreement = response.data;
                dispatch({
                    type: SAVE_AGREEMENT_SUCCESS,
                    payload: {
                        agreement,
                        actionToken
                    }
                });

            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_AGREEMENT_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    } else {
        _url = _url + "/" + agreement.msbId;

        console.log("agreement put");
        console.log(agreement);

        axios.put(_url, agreement)
            .then(function (response) {
                agreement = response.data;
                dispatch({
                    type: SAVE_AGREEMENT_SUCCESS,
                    payload: {
                        agreement,
                        actionToken
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_AGREEMENT_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    }
};
