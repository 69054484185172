import React, { useEffect, useState } from "react";
import { ButtonToolbar, Button, Dropdown, Modal } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import Table from '../../../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../../../components/Formatters';
import {
    SAVE_TERMINAL_REQUEST, SAVE_TERMINAL_SUCCESS, SAVE_TERMINAL_FAILURE,
    DELETE_TERMINAL_REQUEST, DELETE_TERMINAL_SUCCESS, DELETE_TERMINAL_FAILURE,
    ADD_TERMINAL_REQUEST, ADD_TERMINAL_SUCCESS, ADD_TERMINAL_FAILURE,
    UPDATE_TERMINAL_REQUEST, UPDATE_TERMINAL_SUCCESS, UPDATE_TERMINAL_FAILURE
} from '../../../../redux/actions/clients/terminals';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { IMerchantManagementnProps } from '../MerchantManagement';
import { deleteTerminalAction } from '../../../../redux/actions/clients/terminals';

import { ViewTerminalForm, ManageTerminalForm } from "../forms/merchant/MerchantTerminalForms";
import FormHeader from "../forms/FormHeader";
import { Terminal } from "../../../../models/Client";
import PermissionModule, { Permissions } from '../../../../components/usePermissions';

const MerchantTerminalsSubSection = ({ isSaving, isFetching, merchant, actionResult }: IMerchantManagementnProps) => {
    const dispatch = useDispatch();
    const [terminalMsbId, setTerminalMsbId] = useState("");
    const [viewTerminalForm, showViewTerminalForm] = useState(false);
    const [manageTerminalForm, showManageTerminalForm] = useState(false);

    let terminalSaveActionToken = "TerminalSaveActionToken";
    let terminalDeleteActionToken = "TerminalDeleteActionToken";

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_TERMINAL_REQUEST && actionResult.token === terminalSaveActionToken) {
                if (actionResult.result === ADD_TERMINAL_SUCCESS) {
                    showManageTerminalForm(false);
                    dispatch(sendSuccessToastAction("Terminal successfully added."));
                } else if (actionResult.result === ADD_TERMINAL_FAILURE) {
                    dispatch(sendErrorToastAction(`Terminal could not be added. ${actionResult.message}`));
                }
            }

            if (actionResult.type === SAVE_TERMINAL_REQUEST && actionResult.token === terminalSaveActionToken) {
                if (actionResult.result === SAVE_TERMINAL_SUCCESS) {
                    showManageTerminalForm(false);
                    dispatch(sendSuccessToastAction("Terminal successfully updated."));
                } else if (actionResult.result === SAVE_TERMINAL_FAILURE) {
                    dispatch(sendErrorToastAction(`Terminal could not be update. ${actionResult.message}`));
                }
            }

            if (actionResult.type === UPDATE_TERMINAL_REQUEST && actionResult.token === terminalSaveActionToken) {
                if (actionResult.result === UPDATE_TERMINAL_SUCCESS) {
                    showManageTerminalForm(false);
                    dispatch(sendSuccessToastAction("Terminal successfully updated."));
                } else if (actionResult.result === UPDATE_TERMINAL_FAILURE) {
                    dispatch(sendErrorToastAction(`Terminal could not be update. ${actionResult.message}`));
                }
            }

            if (actionResult.type === DELETE_TERMINAL_REQUEST && actionResult.token === terminalDeleteActionToken) {
                if (actionResult.result === DELETE_TERMINAL_SUCCESS) {
                    dispatch(sendSuccessToastAction("Terminal successfully deleted."));
                } else if (actionResult.result === DELETE_TERMINAL_FAILURE) {
                    dispatch(sendErrorToastAction(`Terminal could not be deleted. ${actionResult.message}`));
                }
            }
        }
    }, [actionResult]);

    const cellStatusFormatter = (cell: string, terminal: Terminal) => {
        let value:string = terminal.status || "Created";
        let color:string = "#414141";

        if (value === "Created") {
            color = "#FA8C16";
        } else if (value === "Open") {
            color = "#52C41A";
        } else if (value === "Closed") {
            color = "#f5222d";
        } 

        return (
            <span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{color:`${color}`}} />{value}</span>
        );
    }

    const actionsFormatter = (cell: any, _row: any) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-contact-dropdown">Manage</Dropdown.Toggle>

                <Dropdown.Menu>
                    {/*
                    <Dropdown.Item onSelect={() => {
                        setTerminalMsbId(cell);
                        showViewTerminalForm(true);
                    }}>
                        View Terminal
                    </Dropdown.Item>
                    */}
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item onSelect={() => {
                            setTerminalMsbId(cell);
                            showManageTerminalForm(true);
                        }}>
                            Edit Terminal
                        </Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => {
                            dispatch(deleteTerminalAction(cell, terminalDeleteActionToken))
                        }}>
                            Delete Terminal
                        </Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    function doToolbar() {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => {
                        setTerminalMsbId("");
                        showManageTerminalForm(true)
                    }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" />
                        Add Terminal
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Terminal name',
            editable: false
        }, {
            dataField: 'statusEnum',
            text: 'Status',
            editable: false,
            formatter: cellStatusFormatter
        }, {
            dataField: 'statuslastReceived',
            text: 'Status last received',
            editable: false,
            formatter: DateFormatter,
        }, {
            dataField: 'msbId',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
        }
    ];

    return (
        <>
            <Modal show={viewTerminalForm} onHide={() => showViewTerminalForm(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <h2 className="mb-4">View Terminal</h2>
                    <ViewTerminalForm
                        isSaving={isSaving}
                        merchant={merchant!}
                        terminalMsbId={terminalMsbId}
                        formActionProps={{
                            showSpinner: isFetching,
                            hasSubmit: true,
                            onEdit: () => {
                                showViewTerminalForm(false);
                                showManageTerminalForm(true);
                            },
                            customProps: {
                                submitButtonDisplay: "Close"
                            }
                        }}
                        postSubmit={() => showViewTerminalForm(false)}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={manageTerminalForm} onHide={() => showManageTerminalForm(false)}>
                <Modal.Header closeButton />
                <Modal.Body className="add-entry">
                  <h2>{terminalMsbId ? "Edit Terminal" : "Add Terminal"}</h2>
                  <p>Enter the Terminal details below.</p>
                  <ManageTerminalForm
                      isSaving={isSaving}
                      merchant={merchant!}
                      terminalMsbId={terminalMsbId}
                      actionToken={terminalSaveActionToken}
                      formActionProps={{
                          showSpinner: isFetching,
                          hasSubmit: true,
                          isEditMode: terminalMsbId ? true : false,
                          //onCancel: () => showManageTerminalForm(false),
                          customProps: {
                              submitButtonDisplay: terminalMsbId ? "Save" : "Add"
                          }
                      }}
                      postSubmit={() => showManageTerminalForm(false)}
                  />
                </Modal.Body>
            </Modal>

            <FormHeader title="Terminals" description="Available Terminals are listed below." />

            <div className="manage-card">
              <PageSectionContainer title="Manage Terminals" toolbar={doToolbar()}>
                  <Table 
                    keyField="id" 
                    columns={columns} 
                    data={merchant!.terminals || []}
                  />
              </PageSectionContainer>
            </div>
        </>
    );
};

export default MerchantTerminalsSubSection;