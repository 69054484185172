import axios from 'axios';
import { Dispatch } from 'react';
import { AccessPermissionType, TokenizeBankAccount, TokenizeCard } from '../../models/Wallets';

export const CLEAR_REQUEST = 'CLEAR_REQUEST';

export const GET_CARD_REQUEST = 'GET_CARD_REQUEST';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const GET_CARD_FAILURE = 'GET_CARD_FAILURE';

export const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const ADD_CARD_FAILURE = 'ADD_CARD_FAILURE';

export const SAVE_CARD_REQUEST = 'SAVE_CARD_REQUEST';
export const SAVE_CARD_SUCCESS = 'SAVE_CARD_SUCCESS';
export const SAVE_CARD_FAILURE = 'SAVE_CARD_FAILURE';

export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';

export const UNLINK_CARD_REQUEST = 'UNLINK_CARD_REQUEST';
export const UNLINK_CARD_SUCCESS = 'UNLINK_CARD_SUCCESS';
export const UNLINK_CARD_FAILURE = 'UNLINK_CARD_FAILURE';

export const LINK_CARD_REQUEST = 'LINK_CARD_REQUEST';
export const LINK_CARD_SUCCESS = 'LINK_CARD_SUCCESS';
export const LINK_CARD_FAILURE = 'LINK_CARD_FAILURE';

export const CANCEL_CARD_REQUEST = 'CANCEL_CARD_REQUEST';
export const CANCEL_CARD_SUCCESS = 'CANCEL_CARD_SUCCESS';
export const CANCEL_CARD_FAILURE = 'CANCEL_CARD_FAILURE';

export const UPDATE_ADDRESS_CARD_REQUEST = 'UPDATE_ADDRESS_CARD_REQUEST';
export const UPDATE_ADDRESS_CARD_SUCCESS = 'UPDATE_ADDRESS_CARD_SUCCESS';
export const UPDATE_ADDRESS_CARD_FAILURE = 'UPDATE_ADDRESS_CARD_FAILURE';

export const UPDATE_REISSUE_CARD_REQUEST = 'UPDATE_REISSUE_CARD_REQUEST';
export const UPDATE_REISSUE_CARD_SUCCESS = 'UPDATE_REISSUE_CARD_SUCCESS';
export const UPDATE_REISSUE_CARD_FAILURE = 'UPDATE_REISSUE_CARD_FAILURE';

export const GET_BANKACCOUNT_REQUEST = 'GET_BANKACCOUNT_REQUEST';
export const GET_BANKACCOUNT_SUCCESS = 'GET_BANKACCOUNT_SUCCESS';
export const GET_BANKACCOUNT_FAILURE = 'GET_BANKACCOUNT_FAILURE';

export const ADD_BANKACCOUNT_REQUEST = 'ADD_BANKACCOUNT_REQUEST';
export const ADD_BANKACCOUNT_SUCCESS = 'ADD_BANKACCOUNT_SUCCESS';
export const ADD_BANKACCOUNT_FAILURE = 'ADD_BANKACCOUNT_FAILURE';

export const SAVE_BANKACCOUNT_REQUEST = 'SAVE_BANKACCOUNT_REQUEST';
export const SAVE_BANKACCOUNT_SUCCESS = 'SAVE_BANKACCOUNT_SUCCESS';
export const SAVE_BANKACCOUNT_FAILURE = 'SAVE_BANKACCOUNT_FAILURE';

export const DELETE_BANKACCOUNT_REQUEST = 'DELETE_BANKACCOUNT_REQUEST';
export const DELETE_BANKACCOUNT_SUCCESS = 'DELETE_BANKACCOUNT_SUCCESS';
export const DELETE_BANKACCOUNT_FAILURE = 'DELETE_BANKACCOUNT_FAILURE';

export const UNLINK_BANKACCOUNT_REQUEST = 'UNLINK_BANKACCOUNT_REQUEST';
export const UNLINK_BANKACCOUNT_SUCCESS = 'UNLINK_BANKACCOUNT_SUCCESS';
export const UNLINK_BANKACCOUNT_FAILURE = 'UNLINK_BANKACCOUNT_FAILURE';

export const LINK_BANKACCOUNT_REQUEST = 'LINK_BANKACCOUNT_REQUEST';
export const LINK_BANKACCOUNT_SUCCESS = 'LINK_BANKACCOUNT_SUCCESS';
export const LINK_BANKACCOUNT_FAILURE = 'LINK_BANKACCOUNT_FAILURE';

export const CANCEL_BANKACCOUNT_REQUEST = 'CANCEL_BANKACCOUNT_REQUEST';
export const CANCEL_BANKACCOUNT_SUCCESS = 'CANCEL_BANKACCOUNT_SUCCESS';
export const CANCEL_BANKACCOUNT_FAILURE = 'CANCEL_BANKACCOUNT_FAILURE';

export const UPDATE_ADDRESS_BANKACCOUNT_REQUEST = 'UPDATE_ADDRESS_BANKACCOUNT_REQUEST';
export const UPDATE_ADDRESS_BANKACCOUNT_SUCCESS = 'UPDATE_ADDRESS_BANKACCOUNT_SUCCESS';
export const UPDATE_ADDRESS_BANKACCOUNT_FAILURE = 'UPDATE_ADDRESS_BANKACCOUNT_FAILURE';

export const RESEND_AGREEMENT_REQUEST = 'RESEND_AGREEMENT_REQUEST';
export const RESEND_AGREEMENT_SUCCESS = 'RESEND_AGREEMENT_SUCCESS';
export const RESEND_AGREEMENT_FAILURE = 'RESEND_AGREEMENT_FAILURE';

export const GET_WALLET_REQUEST = 'GET_WALLET_REQUEST';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const GET_WALLET_FAILURE = 'GET_WALLET_FAILURE';

export const ADD_WALLET_REQUEST = 'ADD_WALLET_REQUEST';
export const ADD_WALLET_SUCCESS = 'ADD_WALLET_SUCCESS';
export const ADD_WALLET_FAILURE = 'ADD_WALLET_FAILURE';

export const SAVE_WALLET_REQUEST = 'SAVE_WALLET_REQUEST';
export const SAVE_WALLET_SUCCESS = 'SAVE_WALLET_SUCCESS';
export const SAVE_WALLET_FAILURE = 'SAVE_WALLET_FAILURE';

export const DELETE_WALLET_REQUEST = 'DELETE_WALLET_REQUEST';
export const DELETE_WALLET_SUCCESS = 'DELETE_WALLET_SUCCESS';
export const DELETE_WALLET_FAILURE = 'DELETE_WALLET_FAILURE';

export const clearAction = (actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_REQUEST,
        payload: {
            actionToken
        }
    });
};

export const getCardAction = (paymentChannelId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/cardsAuthorizedForPaymentChannel?paymentChannelId=${paymentChannelId}`

    axios.get(_url)
        .then(function (response) {
            let cards = response.data;
            dispatch({
                type: GET_CARD_SUCCESS,
                payload: {
                    cards,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: GET_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const addCardAction = (card: TokenizeCard, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Wallet/api/v1/Cards/tokenizeWithZeroDollarAuthorization"

    axios.post(_url, card)
        .then(function (response) {
            dispatch({
                type: ADD_CARD_SUCCESS,
                payload: {
                    actionToken
                }
            });

        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: ADD_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const saveCardAction = (card: TokenizeCard, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${card.msbId}`;

    axios.put(_url, card)
        .then(function (response) {
            dispatch({
                type: SAVE_CARD_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: SAVE_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const deleteCardAction = (msbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${msbId}`

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_CARD_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: DELETE_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const getBankAccountAction = (paymentChannelId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_BANKACCOUNT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/BankAccounts/bankAccountsAuthorizedForPaymentChannel?paymentChannelId=${paymentChannelId}`

    axios.get(_url)
        .then(function (response) {
            let bankAccounts = response.data;
            dispatch({
                type: GET_BANKACCOUNT_SUCCESS,
                payload: {
                    bankAccounts,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: GET_BANKACCOUNT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const addBankAccountAction = (bankAccount: TokenizeBankAccount, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_BANKACCOUNT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Wallet/api/v1/BankAccounts/addBankAccount"

    axios.post(_url, bankAccount)
        .then(function (response) {
            dispatch({
                type: ADD_BANKACCOUNT_SUCCESS,
                payload: {
                    actionToken
                }
            });

        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: ADD_BANKACCOUNT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const saveBankAccountAction = (bankAccount: TokenizeBankAccount, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_BANKACCOUNT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/BankAccounts/${bankAccount.msbId}`;

    axios.put(_url, bankAccount)
        .then(function (response) {
            dispatch({
                type: SAVE_BANKACCOUNT_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: SAVE_BANKACCOUNT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const deleteBankAccountAction = (msbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_BANKACCOUNT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/BankAccounts/${msbId}`

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_BANKACCOUNT_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: DELETE_BANKACCOUNT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const getWalletAction = (paymentChannelId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_WALLET_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/cardsAuthorizedForPaymentChannel?paymentChannelId=${paymentChannelId}`

    axios.get(_url)
        .then(function (response) {
            let wallets = response.data;
            dispatch({
                type: GET_WALLET_SUCCESS,
                payload: {
                    wallets,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: GET_WALLET_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const addWalletAction = (card: TokenizeCard, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_WALLET_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Wallet/api/v1/Cards/tokenizeCard"

    axios.post(_url, card)
        .then(function (response) {
            dispatch({
                type: ADD_WALLET_SUCCESS,
                payload: {
                    actionToken
                }
            });

        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: ADD_WALLET_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const saveWalletAction = (card: TokenizeCard, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_WALLET_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${card.msbId}`;

    axios.put(_url, card)
        .then(function (response) {
            dispatch({
                type: SAVE_WALLET_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: SAVE_WALLET_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const deleteWalletAction = (msbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_WALLET_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${msbId}`

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_WALLET_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: DELETE_WALLET_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const unlinkCardAction = (msbId: string, paymentChannelId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UNLINK_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${msbId}/revokeAccessApproval?paymentChannelId=${paymentChannelId}`

    axios.patch(_url)
        .then(function (response) {
            dispatch({
                type: UNLINK_CARD_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: UNLINK_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const unlinkBankAccountAction = (msbId: string, paymentChannelId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UNLINK_BANKACCOUNT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/BankAccounts/${msbId}/revokeAccessApproval?paymentChannelId=${paymentChannelId}`

    axios.patch(_url)
        .then(function (response) {
            dispatch({
                type: UNLINK_BANKACCOUNT_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: UNLINK_BANKACCOUNT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const linkCardAction = (id: string, paymentChannelId: string, permissionType: AccessPermissionType, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: LINK_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${id}/channelAccessRequest?paymentChannelId=${paymentChannelId}&permissionType=${permissionType}`

    axios.patch(_url)
        .then(function (response) {
            dispatch({
                type: LINK_CARD_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: LINK_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const linkBankAccountAction = (id: string, paymentChannelId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: LINK_BANKACCOUNT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/BankAccounts/${id}/channelAccessRequest?paymentChannelId=${paymentChannelId}`

    axios.patch(_url)
        .then(function (response) {
            dispatch({
                type: LINK_BANKACCOUNT_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: LINK_BANKACCOUNT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const cancelCardAction = (msbId: string, paymentChannelId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CANCEL_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${msbId}/cancelAccessApproval?paymentChannelId=${paymentChannelId}`

    axios.patch(_url)
        .then(function (response) {
            dispatch({
                type: CANCEL_CARD_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: CANCEL_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const cancelBankAccountAction = (msbId: string, paymentChannelId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CANCEL_BANKACCOUNT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/BankAccounts/${msbId}/cancelAccessApproval?paymentChannelId=${paymentChannelId}`

    axios.patch(_url)
        .then(function (response) {
            dispatch({
                type: CANCEL_BANKACCOUNT_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: CANCEL_BANKACCOUNT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const updateAddressCardAction = (msbId: string, card: any, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_ADDRESS_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${msbId}/updateCardBillInformation`

    axios.put(_url, card)
        .then(function (response) {
            dispatch({
                type: UPDATE_ADDRESS_CARD_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: UPDATE_ADDRESS_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const updateAddresBankAccountAction = (msbId: string, account: any, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_ADDRESS_BANKACCOUNT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/BankAccounts/${msbId}/updateBankAccountBillInformation`

    axios.put(_url, account)
        .then(function (response) {
            dispatch({
                type: UPDATE_ADDRESS_BANKACCOUNT_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: UPDATE_ADDRESS_BANKACCOUNT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const updateReissueCardAction = (msbId: string, card: TokenizeCard, lastFourOnCard: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_REISSUE_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${msbId}/tokenizeAndReplaceCardOnFile?previousLastFour=${lastFourOnCard}`

    axios.post(_url, card)
        .then(function (response) {
            dispatch({
                type: UPDATE_REISSUE_CARD_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: UPDATE_REISSUE_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const resendAgreementAction = (msbId: string, paymentChannelId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: RESEND_AGREEMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Wallet/api/v1/Cards/${msbId}/reissueAgreementEmail?paymentChannelId=${paymentChannelId}`

    axios.get(_url)
        .then(function (response) {
            dispatch({
                type: RESEND_AGREEMENT_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: RESEND_AGREEMENT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};