import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CurrencyFormatter } from '../../../../components/Formatters';

// use a type here, not an interface
type Data = {
  label: string,
  value: string
}

const getValue = (value: string) => {
  if (value && value.toString().length > 0) {
    return value;
  } else {
    return "10.00";
  }
}

const RemainingBalanceAmountPropertyPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <Row>
        <Col><strong>{data.label}</strong></Col>
        <Col>{CurrencyFormatter(getValue(data.value))}</Col>
      </Row>
  ),
  id: 'remainingBalanceAmountPropertyPlugin',
  title: 'Remaining Balance Amount Property',
  description: 'Remaining Balance Amount Property.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        label: {
          type: 'string',
          default: 'Remaining Balance Amount',
        },
      },
      required: ['label'],
    },
  },
};

export default RemainingBalanceAmountPropertyPlugin;