import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, ListGroup, Spinner } from "react-bootstrap";
import { CurrencyFormatter } from "../../../components/Formatters";
import { Department, FeeBurdenMethodEnum } from "../../../models/Client";
import { Order } from "../../../models/Payment";
import { faPlus, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import PageSectionContainer from "../../../components/layout/PageSectionContainer";
import { useState } from "react";
import ErrorCard from "./ErrorCard";

interface IPaymentSummary {
    isFetching: boolean,
    isSaving: boolean,
    order: Order,
    department: Department|undefined,
    orderCalculationError: boolean,
    handleCancelPayment: any,
    errorMessage: string
}

const PaymentSummary = ({ isFetching, isSaving, order, department, orderCalculationError, handleCancelPayment, errorMessage }: IPaymentSummary) => {
    const renderConvenienceFeeRow = () => {
        if (department && department.feeBurdenMethod === FeeBurdenMethodEnum.ConvenienceFee) {
            return (
                <div className="summaryDetail">
                    <h3>Convenience fee</h3>
                    {isSaving?
                        <Spinner animation="border" size="sm" style={{ margin: 0, textAlign:"end" }} />
                        :
                        <>{CurrencyFormatter(order.convenienceFee.toFixed(2))}</>
                    }
                </div>
            );
        }

        return (<></>);
    }

    return (
        <PageSectionContainer>
            <div className="container-transaction-summary">
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <h2>Payment summary</h2>
                        <div className="summaryDetail">
                            <h3>Subtotal</h3>
                            {CurrencyFormatter(order.amount.toFixed(2))}
                        </div>
                        {renderConvenienceFeeRow()}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div className="summaryDetail">
                            <h3>Total amount</h3>
                            {CurrencyFormatter(order.totalAmount.toFixed(2))}
                        </div>
                        <div className="actionBox">
                            <Form.Group className="summaryButtons">
                                <Button type="submit" disabled={orderCalculationError || isSaving}>Submit payment</Button>
                                <Button variant="link" onClick={handleCancelPayment}>Cancel payment</Button>
                                <ErrorCard orderCalculationError={orderCalculationError} errorMessage={errorMessage} />
                            </Form.Group>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </PageSectionContainer>
    );
}

export default PaymentSummary;