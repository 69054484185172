import React, { useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import RequiredIcon from '../../../../components/RequiredIcon';
import { ContactTypeEnum, Contact, Client } from '../../../../models/Client';
import { addClientContactAction, saveClientContactAction } from '../../../../redux/actions/clients/contacts';
import FormActions, { IFormActionProps } from './FormActions';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

export interface IClientContactFormProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    contact?: Contact,
    actionToken: string,
    formActionProps?: IFormActionProps
}

const ClientContactForm = ({ isSaving, isFetching, client, contact, actionToken, formActionProps }: IClientContactFormProps) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() !== false) {
            let _contact: Contact = Object.assign({}, contact!!);
            _contact.contactReferenceName = form.elements.contactReferenceName.value;
            _contact.contactType = ContactTypeEnum[form.elements.contactTypeEnum.value] as keyof typeof ContactTypeEnum;
            _contact.firstName = form.elements.firstName.value;
            _contact.lastName = form.elements.lastName.value;
            _contact.phoneNumber = form.elements.phoneNumber.value.replace(/\D/g, "");
            _contact.faxNumber = form.elements.faxNumber.value.replace(/\D/g, "");
            _contact.emailAddress = form.elements.emailAddress.value

            if (formActionProps?.customProps.submitButtonDisplay == 'Add') {
                dispatch(addClientContactAction(client.msbId!, _contact, actionToken));
            } else {
                dispatch(saveClientContactAction(_contact, actionToken));
            }
        }

        setValidated(true);
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="contactReferenceName">
                        <Form.Label><RequiredIcon />Contact reference name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={40} defaultValue={contact?.contactReferenceName} placeholder="Enter Contact reference name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a Contact reference name.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="contactTypeEnum">
                        <Form.Label><RequiredIcon />Contact type</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required as="select" defaultValue={ContactTypeEnum[contact?.contactType as keyof typeof ContactTypeEnum]}>
                                <option value={ContactTypeEnum.CustomerService}>Customer Service</option>
                                <option value={ContactTypeEnum.TerminalDeployment}>Terminal Deployment</option>
                                <option value={ContactTypeEnum.Primary}>Primary</option>
                            </Form.Control>
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select a Contact type.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="firstName">
                        <Form.Label><RequiredIcon />First name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={40} defaultValue={contact?.firstName}  placeholder="Enter First name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a First name.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group  controlId="lastName">
                        <Form.Label><RequiredIcon />Last name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={40} defaultValue={contact?.lastName}  placeholder="Enter Last name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a Last name.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="phoneNumber">
                        <Form.Label><RequiredIcon />Phone number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <InputMask required type="input" id="phoneNumber" pattern="[0-9\-\(\)\s]{14}" name="phoneNumber" className="form-control" mask="(999) 999-9999" defaultValue={contact?.phoneNumber}  placeholder="(999) 999-9999" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a Phone number.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="emailAddress">
                        <Form.Label><RequiredIcon />Email address</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="email" placeholder="email@address.com" maxLength={100} defaultValue={contact?.emailAddress} />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter an Email address.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="faxNumber">
                        <Form.Label>Fax number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <InputMask id="faxNumber" name="faxNumber" className="form-control" type="input" pattern="[0-9\-\(\)\s]{14}" mask="(999) 999-9999"  defaultValue={contact?.faxNumber}  placeholder="(999) 999-9999" />
                        </ReactPlaceholder>
                    </Form.Group>
                  </Col>
                </Row>

                <FormActions
                    disabled={isSaving || isFetching}
                    showSpinner={isSaving}
                    {...formActionProps} />
            </Form>
        </>
    );
};

export default ClientContactForm;
