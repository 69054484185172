import moment from 'moment';
import { ClientName, ClientTimezone, DepartmentName, PaymentChannelName } from '../models/Client';
import { TransactionDetails } from '../models/Payment';

export const timeZonesMap: any = {
    "Eastern Standard Time": "America/New_York",
    "Central Standard Time": "America/Chicago",
    "Mountain Standard Time": "America/Denver",
    "Pacific Standard Time": "America/Los_Angeles"
}

export const TransactionReferenceFormatter = (transactionDetail: TransactionDetails) => {
    if (transactionDetail) {
        if (transactionDetail.transactionType === "Sale" || transactionDetail.transactionType === "Void" || transactionDetail.transactionType === "Return" || transactionDetail.transactionType === "Chargeback" || transactionDetail.transactionType === "Return"|| transactionDetail.transactionType === "Refund") {
            return (<span>{transactionDetail.vantivTransactionReferenceNumber}</span>)
        } else if (transactionDetail.orderSummary && transactionDetail.orderSummary.orderTransactions && transactionDetail.orderSummary.orderTransactions.length > 0) {
            return (<span>{transactionDetail.orderSummary.orderTransactions[0].vantivTransactionReferenceNumber}</span>)
        }
    }
    return (<></>)
}

export const EllipseTextCellFormatter = (text: string, row: any, rowIndex:number, length:number) => {
    return EllipseTextFormatter(text, length);
}

export const EllipseTextFormatter = (text: string, length:number) => {
    if (text) {
        return text.length > length? `${text.substr(0, length)}...`:text;
    }
    return (<></>)
}

export const TransactionReferenceCellFormatter = (cell: any, transactionDetail: TransactionDetails, rowIndex:number) => {
    return TransactionReferenceFormatter(transactionDetail);
}



export const NameOnCardFormatter = (transactionDetail: TransactionDetails) => {
    if (transactionDetail) {
        if (transactionDetail.transactionType === "Sale" || transactionDetail.transactionType === "Chargeback" || transactionDetail.transactionType === "Return" || transactionDetail.transactionType === "Void" || transactionDetail.transactionType === "Refund") {
            return (<span>{transactionDetail.nameOnCard}</span>)
        } else if (transactionDetail.orderSummary && transactionDetail.orderSummary.orderTransactions && transactionDetail.orderSummary.orderTransactions.length > 0) {
            return (<span>{transactionDetail.orderSummary.orderTransactions[0].nameOnCard}</span>)
        }
    }
    return (<></>)
}

export const NameOnCardCellFormatter = (cell: any, transactionDetail: TransactionDetails, rowIndex:number) => {
    return (NameOnCardFormatter(transactionDetail));
}

export const ClientNameFormatter = (clientMsbId:string, clientNames: Array<ClientName>) => {
    if (clientNames) {
        var clientName = clientNames.find(cn => cn.msbId === clientMsbId);
        if (clientName) {
            return (<span>{clientName.businessName}</span>);
        } 
    }
    return (<span>{clientMsbId}</span>);
}

export const ClientTimeZoneCellFormatter = (clientMsbId:string, row:any, rowIndex:number, timeZones: Array<ClientTimezone>) => {
    return (ClientTimeZoneFormatter(row, timeZones));
}

export const ClientTimeZoneFormatter = (row:any, timeZones: Array<ClientTimezone>) => {
    if (timeZones) {
        var clientTimezone = timeZones.find(tz=> tz.clientId === row?.eCheckPayment?.clientId);
        if (clientTimezone) {
            return (<span>{clientTimezone.timeZone}</span>);
        } 
    }
    return (<span>{'Unknown Timezone'}</span>);
}

export const ClientTimeZoneDateCellFormatter = (createdAt:string, row:any, rowIndex:number, timeZones: Array<ClientTimezone>) => {
    return (ClientTimeZoneDateFormatter(createdAt,row,timeZones));
}

export const ClientTimeZoneDateFormatter = (createdAt: string, row: any, timeZones: Array<ClientTimezone>) => {
    if (timeZones) {
        var clientTimezone = timeZones.find(tz => tz.clientId === row?.eCheckPayment?.clientId)!;
        const formattedTimeZone = timeZonesMap[clientTimezone?.timeZone] ? `${timeZonesMap[clientTimezone?.timeZone]}` : null
        if (formattedTimeZone) {
            return (<span>{moment.utc(row?.createdAt).tz(formattedTimeZone).format('MM-DD-YYYY h:mm a')}</span>);
        }
    }
    return (<span>{'Unknown Timezone'}</span>);
}

export const CentralTimeZoneDateCellFormatter = (cell:any, row:any) =>{
    return (<span>{moment.utc(row?.createdAt).tz('America/Chicago').format('MM-DD-YYYY h:mm a')}</span>);
}

export const ClientNameCellFormatter = (clientMsbId:string, row:any, rowIndex:number, clientNames: Array<ClientName>) => {
    return (ClientNameFormatter(clientMsbId, clientNames));
}

export const DepartmentNameFormatter = (departmentMsbId:string, departmentNames: Array<DepartmentName>) => {
    if (departmentNames) {
        var departmentName = departmentNames.find(cn => cn.msbId === departmentMsbId);
        if (departmentName) {
            return (<span>{departmentName.name}</span>);
        } 
    }
    return (<span>{departmentMsbId}</span>);
}

export const PaymentChannelNameCellFormatter = (paymentChannelMsbId:string, row:any, rowIndex:number, paymentChannelNames: Array<PaymentChannelName>) => {
    return (PaymentChannelFormatter(paymentChannelMsbId, paymentChannelNames));
}

export const PaymentChannelFormatter = (paymentChannelMsbId:string, paymentChannelNames: Array<PaymentChannelName>) => {
    if (paymentChannelNames) {
        var paymentChannelName = paymentChannelNames.find(cn => cn.msbId === paymentChannelMsbId);
        if (paymentChannelName) {
            return (<span>{paymentChannelName.name}</span>);
        } 
    }
    return (<span>{paymentChannelMsbId}</span>);
}

export const DepartmentNameCellFormatter = (departmentMsbId:string, row:any, rowIndex:number, departmentNames: Array<DepartmentName>) => {
    return (DepartmentNameFormatter(departmentMsbId, departmentNames));
}

export const DateFormatter = (date: string) => {
    if (date) {
        const dateString = moment.utc(date).local().format('MM-DD-YYYY h:mm a');
        return (
            <span>{dateString}</span>
        );
    }
    else { return (<span>{''}</span>) }
}

export const TimeZoneConverter = (cell:string,timeZone:string) => {
    const dateString = moment.utc(cell).tz(timeZone).format('MM-DD-YYYY h:mm a');
    return (
        <span>{dateString}</span>
    );
}

export const DateOnlyFormatter = (date:string) => {
    const dateString = moment.utc(date).local().format('MM-DD-YYYY');
    return (
        <span>{dateString}</span>
    );
}

export const TimeFormatter = (date:string) => {
    const dateString = moment.utc(date).local().format('h:mm:ss a');
    return (
        <span>{dateString}</span>
    );
}

export const CurrencyFormatter = (amount: any, isCSV?: any) => {
    if (!isNaN(amount)) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const formattedAmount = formatter.format(Number(amount));
        return (isCSV ? formattedAmount : <span style={{ color: amount < 0 ? '#F5222D' : "#414141" }}>{formattedAmount}</span>);
    }
    return (isCSV ? '' : <></>);
}

export const CurrencyAmountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const ConvenienceFeeFormatter = (cell: any, row: any, forExports?: boolean) => {
    const isClientAbsorbed = row?.convenienceFeeIsClientAbsorbed || row?.orderSummary?.convenienceFeeIsClientAbsorbed;

    return (isClientAbsorbed ? '$0.00' : forExports ? CurrencyAmountFormatter.format(cell) : CurrencyFormatter(cell))

}