import axios from 'axios';
import { Dispatch } from 'react';
import { Item } from '../../../models/Payment';

import store from "../../store";

export const GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILURE = 'GET_ITEMS_FAILURE';
export const DEL_ITEMS_REQUEST = 'DEL_ITEMS_REQUEST';
export const DEL_ITEMS_SUCCESS = 'DEL_ITEMS_SUCCESS';
export const DEL_ITEMS_FAILURE = 'DEL_ITEMS_FAILURE';
export const ADD_ITEMS_REQUEST = 'ADD_ITEMS_REQUEST';
export const ADD_ITEMS_SUCCESS = 'ADD_ITEMS_SUCCESS';
export const ADD_ITEMS_FAILURE = 'ADD_ITEMS_FAILURE';

export const getItemsAction = (clientMsbId: string, departmentMsbId: string, paymentChannelMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_ITEMS_REQUEST,
        payload: {
            actionToken
        }
    });

    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId
        }
    };

    let _url = '/MSB_Item/api/v1/Items/itemReferenceForClientAndDepartment?' + new URLSearchParams({clientId: clientMsbId, departmentId: departmentMsbId, externalItemReference: paymentChannelMsbId}).toString();

    axios.get(_url, config)
        .then(function (response) {
            let items = response.data;
            dispatch({
                type: GET_ITEMS_SUCCESS,
                payload: {
                    items,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_ITEMS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const removeItemsAction = (clientMsbId: string, departmentMsbId: string, id: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DEL_ITEMS_REQUEST,
        payload: {
            actionToken
        }
    });

    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId
        }
    };

    let _url = `/MSB_Item/api/v1/Items/${id}`;

    axios.delete(_url, config)
        .then(function (response) {
            let items = response.data;
            dispatch({
                type: DEL_ITEMS_SUCCESS,
                payload: {
                    items,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DEL_ITEMS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const addItemsAction = (clientMsbId: string, departmentMsbId: string, item: Item, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_ITEMS_REQUEST,
        payload: {
            actionToken
        }
    });

    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId
        }
    };

    let _url = `/MSB_Item/api/v1/Items`;

    axios.post(_url, item, config)
        .then(function (response) {
            let items = response.data;
            dispatch({
                type: ADD_ITEMS_SUCCESS,
                payload: {
                    items,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: ADD_ITEMS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};



