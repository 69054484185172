import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../../redux/storeTypes';
import { LabelValue } from '../../../../../models/User';
import { User } from '../../../../../models/User';
import { Col, Row, Form } from 'react-bootstrap';
import RequiredIcon from '../../../../../components/RequiredIcon';
// @ts-ignore
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { getAllClientsAction } from '../../../../../redux/actions/clients/clients';
import { Redirect } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder';
import { Client } from '../../../../../models/Client';
import FormHeader from '../../../../clients/components/forms/FormHeader';
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";

interface IUserDetailsProps {
    isFetching: boolean;
    isFetchingClients: boolean;
    isSaving: boolean;
    user: User;
    clients: Array<Client>;
}

const UserDetails = ({ isFetching, isFetchingClients, isSaving, user, clients }: IUserDetailsProps) => {
 
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [selectedClients, setSelectedClients] = useState<Array<string>>([]);


    let availableClients: Array<LabelValue> = [];
    let actionToken = "UserDetails";

    if (clients != null) {
        availableClients = clients.map(client => new LabelValue(client.businessName, client.msbId!!));
    }

    useEffect(() => {
        if (user != null && user.userClients.length) {
            let _selectedClients: Array<string> = [];
            user.userClients.forEach(x => _selectedClients.push(x.clientMSBId));
            setSelectedClients(_selectedClients);
        }
    }, [user]);

    useEffect(() => {
        dispatch(getAllClientsAction(actionToken));
    }, []);

    const dualListChange = (event: Array<string>) => {
        setSelectedClients(event);
        document.forms[0]["selectedClients"].value = event;
    }

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <FormHeader title="Details" description="Enter the user's details below." />

                <Row>
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="emailAddress">
                            <Form.Label><RequiredIcon />Email address</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="email" placeholder="email@address.com" defaultValue={user ? user.email : ''} disabled={isSaving || user? user?.email!='':false} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="phoneNumber">
                            <Form.Label><RequiredIcon />Phone number</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <InputMask
                                    required type="input"
                                    id="phoneNumber"
                                    pattern="[0-9\-\(\)\s]{14}"
                                    name="phoneNumber"
                                    placeholder="(999) 999-9999"
                                    className="form-control"
                                    mask="(999) 999-9999"
                                    defaultValue={user ? user.phoneNumber : ''}
                                    disabled={isSaving} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a phone number.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="firstName">
                            <Form.Label><RequiredIcon />First name</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="text" pattern="^([a-zA-z\s'-])*$" maxLength={50} placeholder="Enter first name" defaultValue={user ? user.firstName : ''} disabled={isSaving} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="lastName">
                            <Form.Label><RequiredIcon />Last name</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="text" pattern="^([a-zA-z\s'-])*$" maxLength={50} placeholder="Enter last name" defaultValue={user ? user.lastName : ''} disabled={isSaving} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="title">
                            <Form.Label><RequiredIcon />Title</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="text" placeholder="Enter title" maxLength={50} pattern="^[^,]*,?[^,]*$" defaultValue={user ? user.title : ''} disabled={isSaving} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a title.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={12} sm={12}>
                        <Form.Group controlId="Clients">
                            <Form.Label>Clients</Form.Label>
                        </Form.Group>
                        <input type="hidden" id="selectedClients" name="selectedClients" value={selectedClients} />
                        <ReactPlaceholder type='text' rows={6} ready={!isFetching && !isFetchingClients} delay={200} showLoadingAnimation={true}>
                            <DualListBox
                                disabled={isSaving}
                                selected={selectedClients}
                                options={availableClients}
                                onChange={dualListChange}
                                className="transfer"
                                showHeaderLabels={true}
                                lang={{
                                    availableHeader: 'Available clients',
                                    selectedHeader: 'User\'s clients'
                                }}
                                icons={{
                                    moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                                    moveAllLeft: [
                                        <FontAwesomeIcon icon={faChevronLeft} key={0} />,
                                        <FontAwesomeIcon icon={faChevronLeft} key={1} />,
                                    ],
                                    moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                                    moveAllRight: [
                                        <FontAwesomeIcon icon={faChevronRight} key={0} />,
                                        <FontAwesomeIcon icon={faChevronRight} key={1} />,
                                    ],
                                }}
                            />
                        </ReactPlaceholder>
                    </Col>
                </Row>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.users.isFetching,
        isSaving: state.users.isSaving,
        user: state.users.user,
        clients: state.clients.clients,
        isFetchingClients: state.clients.isFetching
    };
};

export default connect(mapStateToProps)(UserDetails);