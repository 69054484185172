import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";

interface IErrorCard {
    orderCalculationError?: boolean,
    errorMessage: string
}

const ErrorCard = ({ orderCalculationError, errorMessage }: IErrorCard) => {

    return (
        <>
            {(orderCalculationError || errorMessage != '') &&
                (<div className="alert alert-danger mb-0 mt-2 d-flex align-items-start" id="paymentErrorBox" style={{ overflow: 'scroll' }} role="alert">
                    <FontAwesomeIcon icon={faTimesCircle} size="sm" />{errorMessage != '' ? errorMessage : 'Calculation error'}
                </div>)
            }
        </>

    );
}

export default ErrorCard;