import * as React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Client as ClientModel } from '../../models/Client';
import { IAppState } from '../../redux/storeTypes';
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import ClientManagement from './components/ClientManagement';
import { Crumb } from "../../models/Crumb";
import { resetStoreDepartmentAction } from "../../redux/actions/clients/departments";
import { Routes } from "../../routes";
import { resetClientsStoreAction } from "../../redux/actions/clients/clients";

export interface IClientProps {
    client: ClientModel
}

const Client = ({ client }: IClientProps) => {
    if (client) {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path));
        crumbs.push(new Crumb(client.businessName, Routes.Client.path));

        return (
            <>
                <PageHeader title={"Manage Client"} crumbs={crumbs} />
                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <ClientManagement client={client} />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path));

        return (
            <>
                <PageHeader title={"Manage Client"} crumbs={crumbs} />
                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Spinner animation="border" className="mb-1" />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client
    };
};

export default connect(mapStateToProps)(Client);
