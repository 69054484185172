import {
    SET_CONFIG, GET_WEB_APP_SETTINGS_REQUEST, GET_WEB_APP_SETTINGS_SUCCESS, GET_WEB_APP_SETTINGS_FAILURE,
    GET_WSM_TOKEN_REQUEST, GET_WSM_TOKEN_SUCCESS, GET_WSM_TOKEN_FAILURE
} from '../actions/config';

export default function webAppSettings(state = {
    environment: '',
    baseApiUrl: '',
    bearerToken: '',
    siteKey: '',
    errorMessage: '',
    tokenError: '',
    wsmRefreshMinutes: '',
    clientApiMock: false,
}, { type, payload }) {
    switch (type) {
        case SET_CONFIG:
            return Object.assign({}, state, {
                environment: payload.environment,
                baseApiUrl: payload.baseApiUrl,
                clientApiMock: payload.clientApiMock
            });
        case GET_WEB_APP_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_WEB_APP_SETTINGS_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_WEB_APP_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                environment: payload.webAppSettings.environment,
                baseApiUrl: payload.webAppSettings.wsmBaseUrl,
                wsmRefreshMinutes: payload.webAppSettings.wsmRefreshMinutes,
                siteKey: payload.webAppSettings.siteKey,
                actionResult: { type: GET_WEB_APP_SETTINGS_REQUEST, result: GET_WEB_APP_SETTINGS_SUCCESS, token: payload.actionToken },
            });
        case GET_WEB_APP_SETTINGS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_WEB_APP_SETTINGS_REQUEST, result: GET_WEB_APP_SETTINGS_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case GET_WSM_TOKEN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_WSM_TOKEN_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_WSM_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                bearerToken: payload.token,
                actionResult: { type: GET_WSM_TOKEN_REQUEST, result: GET_WSM_TOKEN_SUCCESS, token: payload.actionToken },
            })
        case GET_WSM_TOKEN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_WSM_TOKEN_REQUEST, result: GET_WSM_TOKEN_FAILURE, token: payload.actionToken },
                errorMessage: payload.error,
                tokenError: payload.TOKEN_ERROR
            });
        default:
            return state;
    }
}


