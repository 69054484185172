import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState,IActionResult } from '../../../../redux/storeTypes';
import { Client, Department } from "../../../../models/Client";
import FormHeader from '../forms/FormHeader';
import DepartmentDetailsForm from '../forms/department/DepartmentDetailsForm';
import { Redirect } from 'react-router-dom';
import { Routes } from '../../../../routes';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { SAVE_DEPARTMENT_REQUEST, SAVE_DEPARTMENT_SUCCESS, SAVE_DEPARTMENT_FAILURE } from '../../../../redux/actions/clients/departments';

 interface IDepartmentDetailsSectionProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    department: Department,
    actionResult: IActionResult
}

const DepartmentDetailsSection = ({ isSaving, isFetching, client, department, actionResult }: IDepartmentDetailsSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);

    const departmentSaveActionToken = "DepartmentSave";

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_DEPARTMENT_REQUEST && actionResult.token === departmentSaveActionToken) {
            if (actionResult.result === SAVE_DEPARTMENT_SUCCESS) {
                dispatch(sendSuccessToastAction("Department details were successfully updated."));
            } else if (actionResult.result === SAVE_DEPARTMENT_FAILURE) {
                dispatch(sendErrorToastAction("Department details could not be updated."));
            }
        }
    }, [actionResult]);
    
    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                <FormHeader title="Department Details" description="Please enter the department's details." />
                    <DepartmentDetailsForm
                        isSaving={isSaving}
                        isFetching={isFetching}
                        client={client}
                        department={department}
                        actionToken={departmentSaveActionToken}
                        formActionProps={{
                            disabled: isSaving || isFetching,
                            showSpinner: isSaving,
                            hasSubmit: true,
                            onCancel: () => setRedirect(true),
                            customProps: {
                                submitButtonDisplay: "Save"
                            }
                        }} 
                />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        client: state.clients.client,
        department: state.clients.department,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(DepartmentDetailsSection);