import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Nav, Row, Form, Button, Tab, Modal } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import PageHeader from '../../../../components/layout/PageHeader';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import { IAppState, IActionResult } from '../../../../redux/storeTypes';
import { Role, ModulePermission } from '../../../../models/Role';
import ManagementComponents from './clientRoleTabs/ManagementTabs';
import { Routes } from "../../../../routes";
import { Redirect } from 'react-router-dom';
import { sendSuccessToastAction, sendErrorToastAction } from '../../../../redux/actions/toast';
import RequiredIcon from '../../../../components/RequiredIcon';
import { saveRole, SAVE_ROLE_REQUEST, SAVE_ROLE_SUCCESS, SAVE_ROLE_FAILURE } from '../../../../redux/actions/roles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ClientRoles } from './clientRoleTabs/RoleHeaders'
import { Crumb } from "../../../../models/Crumb";
import { UserTypeEnum } from '../../../../models/User'


export interface IRoleTabsContainer {
    modulePermissions: Array<ModulePermission>;
    role: Role;
    actionResult?: IActionResult
}

const RoleTabsContainer = ({ role, modulePermissions, actionResult }: IRoleTabsContainer) => {

    let actionToken = "ClientRoleTabsContainer";
    const ref = useRef(null);
    const [redirect, setRedirect] = useState<string>("");
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [showModal, setModal] = useState(false);

    const handlemodal = () => {
        setRedirect(Routes.ClientRoles.path);
    }

    const scroll = (scrollOffset: any) => {

        const node: any = ref.current!;
        node.scrollLeft += scrollOffset;

    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            if (role === null) {
                role = new Role();
            }

            role.userTypeEnum = UserTypeEnum.Client;
            role.name = form.elements.roleName.value;
            role.description = form.elements.roleDescription.value;
            dispatch(saveRole(role, actionToken));
        }

        setValidated(true);
    };

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_ROLE_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_ROLE_SUCCESS) {
                dispatch(sendSuccessToastAction("Role was successfully saved."));
                setRedirect(Routes.ClientRoles.path);
            } else if (actionResult.result === SAVE_ROLE_FAILURE) {
                dispatch(sendErrorToastAction("Role could not be saved."));
            }
        }
    }, [actionResult]);

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {

        return (
            <>
                <Container fluid className="container-table-search">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Form id='clientRoleSubmit' noValidate validated={validated} onSubmit={handleSubmit} className="table-search">
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12}>
                                            <Form.Group controlId="roleName">
                                                <Form.Label><RequiredIcon />Role name</Form.Label>
                                                <Form.Control required type="text" placeholder="Enter role name" defaultValue={role ? role.name : ''} />
                                                <Form.Control.Feedback type="invalid">Please enter a role name.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12}>
                                            <Form.Group controlId="roleDescription">
                                                <Form.Label><RequiredIcon />Role description</Form.Label>
                                                <Form.Control required type="text" placeholder="Enter role description" defaultValue={role ? role.description : ''} />
                                                <Form.Control.Feedback type="invalid">Please enter a role description.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Tab.Container id="navientRoles" defaultActiveKey="reportManagementRole">
                                    <Row>
                                        <Col>

                                            {/* BEGIN: Tabs */}
                                            <div className="tab-nav d-flex px-0">
                                                <Button variant="link" onClick={() => scroll(-300)} className="btn-scroll-left">
                                                    <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                                                </Button>
                                                <div className="scrollbarContainer" ref={ref}>
                                                    <Nav className="flex-row flex-nowrap">
                                                        {Object.entries(ClientRoles).map(
                                                            ([index, value]: any) => {
                                                                return (
                                                                    <Nav.Item>
                                                                        <Nav.Link className="roleTabWidth" eventKey={value.key}>{value.title}</Nav.Link>
                                                                    </Nav.Item>
                                                                )
                                                            }
                                                        )}
                                                    </Nav>
                                                </div>
                                                <Button variant="link" onClick={() => scroll(300)} className="btn-scroll-right">
                                                    <FontAwesomeIcon icon={faChevronRight} size="xs" />
                                                </Button>
                                            </div>
                                            {/* END: Tabs */}

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <Tab.Content>
                                
                                            
                                                <Tab.Pane eventKey="reportManagementRole">
                                                    <ManagementComponents.ReportManagement />
                                                </Tab.Pane>
                                            
                                                <Tab.Pane eventKey="transactionManagementRole">
                                                    <ManagementComponents.TransactionManagement />
                                                </Tab.Pane>
                                                
                                                <Tab.Pane eventKey="userManagementRole">
                                                    <ManagementComponents.UserManagement />
                                                </Tab.Pane>
                                                <div className="manage-footer">
                                                    <Form.Group controlId="clientRoleSubmit" >
                                                        <Button form='clientRoleSubmit' type="submit">Save</Button>
                                                        <Button form='roleCancel' className="roleFooterCancel" variant="link" onClick={handlemodal}>Cancel</Button>
                                                    </Form.Group>
                                                </div>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
                <Modal show={showModal}>
                    <Modal.Dialog className="roleCancelDialog">
                        <Modal.Body>
                            <Form.Group controlId="roleCancelTitle">
                                <Form.Label>Cancel adding role</Form.Label>
                            </Form.Group>
                            <Form.Group controlId="roleCancelMessage">
                                <Form.Label>Are you sure you want to cancel adding this new role?</Form.Label>
                            </Form.Group>
                            <Form.Group className="roleDialogButtons">
                                <Button variant="outline-secondary" onClick={() => setModal(false)}>
                                    Cancel
                                </Button>
                                <Button className="roleCancelConfirm"
                                    onClick={() => { setRedirect(Routes.ClientRoles.path) }}
                                >
                                    Confirm
                                </Button>
                            </Form.Group>
                        </Modal.Body>
                    </Modal.Dialog>
                </Modal>

            </>
        )
    }
}


const mapStateToProps = (state: IAppState) => {
    return {
        role: state.roles.role,
        modulePermissions: state.roles.modulePermissions,
        actionResult: state.roles.actionResult

    };
};

export default connect(mapStateToProps)(RoleTabsContainer);