import {
    CLEAR_REQUEST,

    GET_CARD_REQUEST, GET_CARD_SUCCESS, GET_CARD_FAILURE,
    ADD_CARD_REQUEST, ADD_CARD_SUCCESS, ADD_CARD_FAILURE,
    SAVE_CARD_REQUEST, SAVE_CARD_SUCCESS, SAVE_CARD_FAILURE,
    DELETE_CARD_REQUEST, DELETE_CARD_SUCCESS, DELETE_CARD_FAILURE,

    GET_BANKACCOUNT_REQUEST, GET_BANKACCOUNT_SUCCESS, GET_BANKACCOUNT_FAILURE,
    ADD_BANKACCOUNT_REQUEST, ADD_BANKACCOUNT_SUCCESS, ADD_BANKACCOUNT_FAILURE,
    SAVE_BANKACCOUNT_REQUEST, SAVE_BANKACCOUNT_SUCCESS, SAVE_BANKACCOUNT_FAILURE,
    DELETE_BANKACCOUNT_REQUEST, DELETE_BANKACCOUNT_SUCCESS, DELETE_BANKACCOUNT_FAILURE,

    UNLINK_CARD_REQUEST, UNLINK_CARD_SUCCESS, UNLINK_CARD_FAILURE,
    UNLINK_BANKACCOUNT_REQUEST, UNLINK_BANKACCOUNT_SUCCESS, UNLINK_BANKACCOUNT_FAILURE,

    LINK_CARD_REQUEST, LINK_CARD_SUCCESS, LINK_CARD_FAILURE,
    LINK_BANKACCOUNT_REQUEST, LINK_BANKACCOUNT_SUCCESS, LINK_BANKACCOUNT_FAILURE,

    CANCEL_CARD_REQUEST, CANCEL_CARD_SUCCESS, CANCEL_CARD_FAILURE,
    CANCEL_BANKACCOUNT_REQUEST, CANCEL_BANKACCOUNT_SUCCESS, CANCEL_BANKACCOUNT_FAILURE,

    UPDATE_ADDRESS_CARD_REQUEST, UPDATE_ADDRESS_CARD_SUCCESS, UPDATE_ADDRESS_CARD_FAILURE,
    UPDATE_ADDRESS_BANKACCOUNT_REQUEST, UPDATE_ADDRESS_BANKACCOUNT_SUCCESS, UPDATE_ADDRESS_BANKACCOUNT_FAILURE,

    UPDATE_REISSUE_CARD_REQUEST, UPDATE_REISSUE_CARD_SUCCESS, UPDATE_REISSUE_CARD_FAILURE,

    RESEND_AGREEMENT_REQUEST, RESEND_AGREEMENT_SUCCESS, RESEND_AGREEMENT_FAILURE,
} from '../actions/wallets';

export default function wallets(state = {
    isFetching: false,
    isSaving: false,
    errorMessage: '',
    cards: null,
    bankAccounts: null,
    actionResult: null,
}, { type, payload }) {

    switch (type) {
        case CLEAR_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: '',
                cards: null,
                bankAccount: null,
                actionResult: { type: CLEAR_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                cards: null,
                actionResult: { type: GET_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_CARD_SUCCESS:
            return Object.assign({}, state, {
                cards: payload.cards,
                actionResult: { type: GET_CARD_REQUEST, result: GET_CARD_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case GET_CARD_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: GET_CARD_REQUEST, result: GET_CARD_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case ADD_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: '',
                cards: null,
                actionResult: { type: ADD_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_CARD_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: ADD_CARD_REQUEST, result: ADD_CARD_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case ADD_CARD_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                actionResult: { type: ADD_CARD_REQUEST, result: ADD_CARD_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case SAVE_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                cards: null,
                actionResult: { type: SAVE_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_CARD_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: SAVE_CARD_REQUEST, result: SAVE_CARD_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case SAVE_CARD_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: SAVE_CARD_REQUEST, result: SAVE_CARD_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case DELETE_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                cards: null,
                actionResult: { type: DELETE_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_CARD_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: DELETE_CARD_REQUEST, result: DELETE_CARD_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case DELETE_CARD_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: DELETE_CARD_REQUEST, result: DELETE_CARD_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case UNLINK_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: UNLINK_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case UNLINK_CARD_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: UNLINK_CARD_REQUEST, result: UNLINK_CARD_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case UNLINK_CARD_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: UNLINK_CARD_REQUEST, result: UNLINK_CARD_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case LINK_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: LINK_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case LINK_CARD_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: LINK_CARD_REQUEST, result: LINK_CARD_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case LINK_CARD_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: LINK_CARD_REQUEST, result: LINK_CARD_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case CANCEL_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: '',
                actionResult: { type: CANCEL_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case CANCEL_CARD_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: CANCEL_CARD_REQUEST, result: CANCEL_CARD_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case CANCEL_CARD_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: CANCEL_CARD_REQUEST, result: CANCEL_CARD_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case UPDATE_ADDRESS_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: '',
                actionResult: { type: UPDATE_ADDRESS_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case UPDATE_ADDRESS_CARD_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: UPDATE_ADDRESS_CARD_REQUEST, result: UPDATE_ADDRESS_CARD_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case UPDATE_ADDRESS_CARD_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: UPDATE_ADDRESS_CARD_REQUEST, result: UPDATE_ADDRESS_CARD_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case UPDATE_REISSUE_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: '',
                actionResult: { type: UPDATE_REISSUE_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case UPDATE_REISSUE_CARD_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: UPDATE_REISSUE_CARD_REQUEST, result: UPDATE_REISSUE_CARD_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case UPDATE_REISSUE_CARD_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: UPDATE_REISSUE_CARD_REQUEST, result: UPDATE_REISSUE_CARD_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case GET_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                bankAccounts: null,
                actionResult: { type: GET_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_BANKACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                bankAccounts: payload.bankAccounts,
                actionResult: { type: GET_BANKACCOUNT_REQUEST, result: GET_BANKACCOUNT_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case GET_BANKACCOUNT_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: GET_BANKACCOUNT_REQUEST, result: GET_BANKACCOUNT_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case ADD_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                errorMessage: '',
                isFetching: true,
                actionResult: { type: ADD_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_BANKACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: ADD_BANKACCOUNT_REQUEST, result: ADD_BANKACCOUNT_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case ADD_BANKACCOUNT_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                actionResult: { type: ADD_BANKACCOUNT_REQUEST, result: ADD_BANKACCOUNT_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case SAVE_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                bankAccounts: null,
                actionResult: { type: SAVE_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_BANKACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: SAVE_BANKACCOUNT_REQUEST, result: SAVE_BANKACCOUNT_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case SAVE_BANKACCOUNT_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: SAVE_BANKACCOUNT_REQUEST, result: SAVE_BANKACCOUNT_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case DELETE_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                bankAccounts: null,
                actionResult: { type: DELETE_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_BANKACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: DELETE_BANKACCOUNT_REQUEST, result: DELETE_BANKACCOUNT_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case DELETE_BANKACCOUNT_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: DELETE_BANKACCOUNT_REQUEST, result: DELETE_BANKACCOUNT_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case UNLINK_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: UNLINK_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case UNLINK_BANKACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: UNLINK_BANKACCOUNT_REQUEST, result: UNLINK_BANKACCOUNT_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case UNLINK_BANKACCOUNT_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: UNLINK_BANKACCOUNT_REQUEST, result: UNLINK_BANKACCOUNT_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case LINK_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: LINK_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case LINK_BANKACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: LINK_BANKACCOUNT_REQUEST, result: LINK_BANKACCOUNT_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case LINK_BANKACCOUNT_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: LINK_BANKACCOUNT_REQUEST, result: LINK_BANKACCOUNT_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case CANCEL_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: CANCEL_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case CANCEL_BANKACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: CANCEL_BANKACCOUNT_REQUEST, result: CANCEL_BANKACCOUNT_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case CANCEL_BANKACCOUNT_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: CANCEL_BANKACCOUNT_REQUEST, result: CANCEL_BANKACCOUNT_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case UPDATE_ADDRESS_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: UPDATE_ADDRESS_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case UPDATE_ADDRESS_BANKACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: UPDATE_ADDRESS_BANKACCOUNT_REQUEST, result: UPDATE_ADDRESS_BANKACCOUNT_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case UPDATE_ADDRESS_BANKACCOUNT_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: UPDATE_ADDRESS_BANKACCOUNT_REQUEST, result: UPDATE_ADDRESS_BANKACCOUNT_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        case RESEND_AGREEMENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: RESEND_AGREEMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case RESEND_AGREEMENT_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: RESEND_AGREEMENT_REQUEST, result: RESEND_AGREEMENT_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case RESEND_AGREEMENT_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: RESEND_AGREEMENT_REQUEST, result: RESEND_AGREEMENT_FAILURE, token: payload.actionToken, message: payload.error },
                isFetching: false
            });
        default:
            return state;
    }
}
