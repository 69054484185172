export const InternalRoles = [
    {
        title: 'Internal User Management',
        key: 'navientInternalUsersRole',
    },
    {
        title: 'Client Management',
        key: 'navientClientsRole',
    },
    {
        title: 'Transaction Management',
        key: 'navientTransactionsRole',
    },   
]