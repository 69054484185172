export const DepartmentManagementTabsHeaders = [
    {
        title: 'Details',
        key: 'manage_details',
    },
    {
        title: 'Addresses',
        key: 'manage_addresses',
    },
    {
        title: 'Contacts',
        key: 'manage_contacts',
    },
    /*
    {
        title: 'Settlements',
        key: 'manage_settlements',
    },
    */
    {
        title: 'Accounting',
        key: 'manage_accounting',
    },   
    {
        title: 'Fees',
        key: 'manage_mids_fees',
    },   
    {
        title: 'Receipts',
        key: 'manage_receipts',
    },   
    {
        title: 'Payment Channels',
        key: 'manage_paymentchannels',
    },   
    {
        title: 'Printers',
        key: 'manage_devices',
    },   
    {
        title: 'Constituents',
        key: 'manage_constituents',
    },   
]