import React, { useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import RequiredIcon from '../../../../components/RequiredIcon';
import { Client, TimeZoneEnum } from '../../../../models/Client';
import { addClientAction, saveClientAction } from '../../../../redux/actions/clients/clients';
import FormActions, { IFormActionProps } from './FormActions';
import Switch from "react-switch";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

export interface IClientDetailsFormProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    actionToken: string,
    formActionProps?: IFormActionProps
}

const ClientDetailsForm = ({ isSaving, isFetching, client, actionToken, formActionProps }: IClientDetailsFormProps) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [observesDaylightSavings, setObservesDaylightSavings] = useState(client?.observesDaylightSavings);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            client.businessName = form.elements.businessName.value;
            client.businessEstablishedDate = form.elements.businessEstablishedDate.value;
            client.timeZoneInfo = form.elements.timeZoneEnum.value;
            client.observesDaylightSavings = observesDaylightSavings;

            if (formActionProps?.customProps.submitButtonDisplay == 'Add') {
                dispatch(addClientAction(client, actionToken));
            } else {
                dispatch(saveClientAction(client, actionToken));
            }
        }

        setValidated(true); 
    };

    const handleObservesDaylightSavings = (value: boolean) => {
        setObservesDaylightSavings(value);
    }

    function getDate(dateString:string):string {
        if (dateString !== null && dateString.length > 0) {
            var date = new Date(dateString);
            var month = (date.getMonth() + 1).toString();
            var day = date.getDate().toString();

            if ((date.getMonth() + 1) < 10) {
                month = "0" + month;
            }

            if (date.getDate() < 10) {
                day = "0" + day;
            }

            dateString =  date.getFullYear() + "-" + month + "-" + day;
            return dateString;
        }

        return "";
    }

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="businessName">
                        <Form.Label><RequiredIcon />Business name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={64} defaultValue={client?.businessName} disabled={isSaving} placeholder="Enter Business name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a Business name.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                    <Col lg={6} sm={12}>
                        {/*
                        <Form.Group controlId="legalBusinessName">
                            <Form.Label><RequiredIcon />Legal business name</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="input" maxLength={40} defaultValue={client?.legalBusinessName} disabled={isSaving} placeholder="Enter Legal business name" />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a Legal business name.</Form.Control.Feedback>
                        </Form.Group>
                        */}
                  </Col>
                </Row>
                {/*
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="dbaName">
                        <Form.Label><RequiredIcon />DBA name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={40} defaultValue={client?.dbaName} disabled={isSaving} placeholder="Enter DBA name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a DBA name.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="federalTaxId">
                        <Form.Label><RequiredIcon />Federal tax ID</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <InputMask required type="input" mask="99-9999999" className="form-control fs-mask" id="federalTaxId" defaultValue={client?.federalTaxId} disabled={isSaving} placeholder="99-9999999" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a valid Federal tax ID.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                */}
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="businessEstablishedDate">
                        <Form.Label><RequiredIcon />Business established date</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control type="date" required defaultValue={getDate(client?.businessEstablishedDate)} disabled={isSaving} />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select a Business established date.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                        {/*
                        <Form.Group controlId="businessWebsite">
                            <Form.Label><RequiredIcon />Business website</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control type="input" required maxLength={99} defaultValue={client?.businessWebsite} disabled={isSaving} placeholder="www.website.com" />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a Business website.</Form.Control.Feedback>
                        </Form.Group>
                        */}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="timeZoneEnum">
                        <Form.Label><RequiredIcon />Time zone</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required as="select" defaultValue={client?.timeZoneInfo} disabled={isSaving}>
                                <option value="">Select</option>
                                <option value={TimeZoneEnum.ET}>Eastern Standard Time</option>
                                <option value={TimeZoneEnum.CT}>Central Standard Time</option>
                                <option value={TimeZoneEnum.MT}>Mountain Standard Time</option>
                                <option value={TimeZoneEnum.PT}>Pacific Standard Time</option>
                            </Form.Control>
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select a Time zone.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                      <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                      <div className="toggle-switch">
                          <Switch
                              disabled={isSaving}
                              onChange={handleObservesDaylightSavings} 
                              checked={observesDaylightSavings} 
                              onColor={'#0057B6'} 
                              offColor={'#BEBEBE'}
                              handleDiameter={12}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={16}
                              width={28}
                              activeBoxShadow={'0 0 0 1px #0057B6'}                                
                          />
                          <span className="toggle-label">Observes daylight savings time</span>
                      </div>
                      </ReactPlaceholder>
                    </Col>
                </Row>

                <div className="manage-footer">
                  <FormActions 
                    disabled={isSaving}
                    showSpinner={isFetching}
                    {...formActionProps} />
                </div>
                
            </Form>
        </>
    );
};

export default ClientDetailsForm;
