import axios from 'axios';
import { AuthorizeNetCredentials, ConvenienceFee, Department, PaymentChannel, PayPalCredentials } from '../../../models/Client';
import { Dispatch } from 'react';

export const GET_PAYMENTCHANNEL_REQUEST = 'GET_PAYMENTCHANNEL_REQUEST';
export const GET_PAYMENTCHANNEL_SUCCESS = "GET_PAYMENTCHANNEL_SUCCESS";
export const GET_PAYMENTCHANNEL_FAILURE = 'GET_PAYMENTCHANNEL_FAILURE';

export const ADD_PAYMENTCHANNEL_REQUEST = 'ADD_PAYMENTCHANNEL_REQUEST';
export const ADD_PAYMENTCHANNEL_SUCCESS = "ADD_PAYMENTCHANNEL_SUCCESS";
export const ADD_PAYMENTCHANNEL_FAILURE = 'ADD_PAYMENTCHANNEL_FAILURE';

export const SAVE_PAYMENTCHANNEL_REQUEST = 'SAVE_PAYMENTCHANNEL_REQUEST';
export const SAVE_PAYMENTCHANNEL_SUCCESS = "SAVE_PAYMENTCHANNEL_SUCCESS";
export const SAVE_PAYMENTCHANNEL_FAILURE = 'SAVE_PAYMENTCHANNEL_FAILURE';

export const DELETE_PAYMENTCHANNEL_REQUEST = 'DELETE_PAYMENTCHANNEL_REQUEST';
export const DELETE_PAYMENTCHANNEL_SUCCESS = 'DELETE_PAYMENTCHANNEL_SUCCESS';
export const DELETE_PAYMENTCHANNEL_FAILURE = 'DELETE_PAYMENTCHANNEL_FAILURE';

export const GET_PAYMENTCHANNELNAMES_REQUEST = 'GET_PAYMENTCHANNELNAMES_REQUEST';
export const GET_PAYMENTCHANNELNAMES_SUCCESS = 'GET_PAYMENTCHANNELNAMES_SUCCESS';
export const GET_PAYMENTCHANNELNAMES_FAILURE = 'GET_PAYMENTCHANNELNAMES_FAILURE';

export const GET_ALL_PAYMENTCHANNELNAMES_REQUEST = 'GET_ALL_PAYMENTCHANNELNAMES_REQUEST';
export const GET_ALL_PAYMENTCHANNELNAMES_SUCCESS = 'GET_ALL_PAYMENTCHANNELNAMES_SUCCESS';
export const GET_ALL_PAYMENTCHANNELNAMES_FAILURE = 'GET_ALL_PAYMENTCHANNELNAMES_FAILURE';

export const SAVE_PAYMENT_CHANNEL_CONVENIENCE_FEES_REQUEST = 'SAVE_PAYMENT_CHANNEL_CONVENIENCE_FEES_REQUEST';
export const SAVE_PAYMENT_CHANNEL_CONVENIENCE_FEES_SUCCESS = "SAVE_PAYMENT_CHANNEL_CONVENIENCE_FEES_SUCCESS";
export const SAVE_PAYMENT_CHANNEL_CONVENIENCE_FEES_FAILURE = 'SAVE_PAYMENT_CHANNEL_CONVENIENCE_FEES_FAILURE';

export const GET_PAYPALCREDINFO_REQUEST = 'GET_PAYPALCREDINFO_REQUEST';
export const GET_PAYPALCREDINFO_SUCCESS = "GET_PAYPALCREDINFO_SUCCESS";
export const GET_PAYPALCREDINFO_FAILURE = 'GET_PAYPALCREDINFO_FAILURE';

export const ADD_PAYPALCREDINFO_REQUEST = 'ADD_PAYPALCREDINFO_REQUEST';
export const ADD_PAYPALCREDINFO_SUCCESS = "ADD_PAYPALCREDINFO_SUCCESS";
export const ADD_PAYPALCREDINFO_FAILURE = 'ADD_PAYPALCREDINFO_FAILURE';

export const SAVE_PAYPALCREDINFO_REQUEST = 'SAVE_PAYPALCREDINFO_REQUEST';
export const SAVE_PAYPALCREDINFO_SUCCESS = "SAVE_PAYPALCREDINFO_SUCCESS";
export const SAVE_PAYPALCREDINFO_FAILURE = 'SAVE_PAYPALCREDINFO_FAILURE';

export const DELETE_PAYPALCREDINFO_REQUEST = 'DELETE_PAYPALCREDINFO_REQUEST';
export const DELETE_PAYPALCREDINFO_SUCCESS = 'DELETE_PAYPALCREDINFO_SUCCESS';
export const DELETE_PAYPALCREDINFO_FAILURE = 'DELETE_PAYPALCREDINFO_FAILURE';

export const GET_AUTHORIZENETCREDINFO_REQUEST = 'GET_AUTHORIZENETCREDINFO_REQUEST';
export const GET_AUTHORIZENETCREDINFO_SUCCESS = "GET_AUTHORIZENETCREDINFO_SUCCESS";
export const GET_AUTHORIZENETCREDINFO_FAILURE = 'GET_AUTHORIZENETCREDINFO_FAILURE';

export const ADD_AUTHORIZENETCREDINFO_REQUEST = 'ADD_AUTHORIZENETCREDINFO_REQUEST';
export const ADD_AUTHORIZENETCREDINFO_SUCCESS = "ADD_AUTHORIZENETCREDINFO_SUCCESS";
export const ADD_AUTHORIZENETCREDINFO_FAILURE = 'ADD_AUTHORIZENETCREDINFO_FAILURE';

export const SAVE_AUTHORIZENETCREDINFO_REQUEST = 'SAVE_AUTHORIZENETCREDINFO_REQUEST';
export const SAVE_AUTHORIZENETCREDINFO_SUCCESS = "SAVE_AUTHORIZENETCREDINFO_SUCCESS";
export const SAVE_AUTHORIZENETCREDINFO_FAILURE = 'SAVE_AUTHORIZENETCREDINFO_FAILURE';

export const DELETE_AUTHORIZENETCREDINFO_REQUEST = 'DELETE_AUTHORIZENETCREDINFO_REQUEST';
export const DELETE_AUTHORIZENETCREDINFO_SUCCESS = 'DELETE_AUTHORIZENETCREDINFO_SUCCESS';
export const DELETE_AUTHORIZENETCREDINFO_FAILURE = 'DELETE_AUTHORIZENETCREDINFO_FAILURE';

export const getPaymentChannelAction = (paymentChannelMsbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_PAYMENTCHANNEL_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/PaymentChannels/" + paymentChannelMsbId;
    let paymentChannel = new PaymentChannel();

    axios.get(_url)
        .then(function (response) {
            paymentChannel = response.data;
            paymentChannel.creditCardPMoFEnabled = true;
            paymentChannel.eCheckPMoFEnabled = false;
            paymentChannel.terminalPMoFEnabled = true;
            dispatch({
                type: GET_PAYMENTCHANNEL_SUCCESS,
                payload: {
                    paymentChannel,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_PAYMENTCHANNEL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
};

export const getAllPaymentChannelNamesAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_ALL_PAYMENTCHANNELNAMES_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/PaymentChannels/names";

    axios.get(_url)
        .then(function (response) {
            let paymentChannelNames = response.data;
            dispatch({
                type: GET_ALL_PAYMENTCHANNELNAMES_SUCCESS,
                payload: {
                    paymentChannelNames,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_ALL_PAYMENTCHANNELNAMES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
};

export const addPaymentChannelAction = (departmentMsbId: any, paymentChannel: PaymentChannel, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_PAYMENTCHANNEL_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_Client/api/v1/Departments/${departmentMsbId}/paymentChannel`;
    let department = new Department();

    axios.post(_url, paymentChannel)
        .then(function (response) {
            department = response.data;
            dispatch({
                type: ADD_PAYMENTCHANNEL_SUCCESS,
                payload: {
                    department,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: ADD_PAYMENTCHANNEL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};

export const savePaymentChannelAction = (paymentChannel: PaymentChannel, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_PAYMENTCHANNEL_REQUEST,
        payload: { actionToken }
    });

    let _url = "/MSB_Client/api/v1/PaymentChannels/" + paymentChannel.msbId;

    axios.put(_url, paymentChannel)
        .then(function (response) {
            paymentChannel = response.data;
            dispatch({
                type: SAVE_PAYMENTCHANNEL_SUCCESS,
                payload: {
                    paymentChannel,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_PAYMENTCHANNEL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};

export const deletePaymentChannelAction = (paymentChannelMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_PAYMENTCHANNEL_REQUEST,
        payload: {
            paymentChannelMsbId,
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/PaymentChannels/" + paymentChannelMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_PAYMENTCHANNEL_SUCCESS,
                payload: {
                    paymentChannelMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_PAYMENTCHANNEL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });

};

export const getPaymentChannelNamesAction = (paymentChannelIds: Array<string>, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_PAYMENTCHANNELNAMES_REQUEST,
        payload: { actionToken }
    });

    let _url = "/MSB_Client/api/v1/PaymentChannels"
    const paymentChannelPromises = paymentChannelIds.map(paymentChannelId => axios.get(_url + "/" + paymentChannelId));
    Promise.all(paymentChannelPromises)
        .then(resultArray => {
            const paymentChannelMap: { [k: string]: string } = {};
            resultArray.forEach(result => {
                paymentChannelMap[result.data.msbId] = result.data.name;
                dispatch({
                    type: GET_PAYMENTCHANNELNAMES_SUCCESS,
                    payload: {
                        paymentChannelMap,
                        actionToken
                    }
                })
            });
        })
        .catch(err => {
            dispatch({
                type: GET_PAYMENTCHANNELNAMES_FAILURE,
                payload: {
                    err,
                    actionToken
                }
            });
        });
};

export const addChannelSupplementalFieldAction = (paymentChannelMsbId: any, channelSupplementalField: any, actionToken: string) => (dispatch: Dispatch<any>) => {
    let _url = `/MSB_Client/api/v1/ChannelSupplementalFields/addToPaymentChannel/${paymentChannelMsbId}?paymentChannelId=${paymentChannelMsbId}`;
    axios.post(_url, channelSupplementalField)
        .then(function (response) {
            dispatch(getPaymentChannelAction(paymentChannelMsbId, actionToken));
        })
        .catch(function (error) {
        });
};

export const deleteChannelSupplementalFieldAction = (msbId: string, paymentChannelMsbId: any, actionToken: string) => (dispatch: Dispatch<any>) => {
    let _url = `/MSB_Client/api/v1/ChannelSupplementalFields/${msbId}`;
    axios.delete(_url)
        .then(function (response) {
            dispatch(getPaymentChannelAction(paymentChannelMsbId, actionToken));
        })
        .catch(function (error) {
        });

};

export const getPayPalCredInfoAction = (msbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_PAYPALCREDINFO_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/PayPalCredentials/${msbId}`;
    var payPalCredential: PayPalCredentials;

    axios.get(_url)
        .then(function (response) {
            payPalCredential = response.data;
            dispatch({
                type: GET_PAYPALCREDINFO_SUCCESS,
                payload: {
                    payPalCredential,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_PAYPALCREDINFO_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
};

export const addPayPalCredInfoAction = (paymentChannelMsbId: any, payPalCredential: PayPalCredentials, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_PAYPALCREDINFO_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_Client/api/v1/PaymentChannels/${paymentChannelMsbId}/addPayPalCredentials`;
    let paymentChannel = new PaymentChannel();

    axios.post(_url, payPalCredential)
        .then(function (response) {
            paymentChannel = response.data;
            let processor = paymentChannel.processors.find(p => p.merchantProcessor?.processor === "PayPal");
            payPalCredential = processor?.merchantProcessor?.payPalCredential!;
            dispatch({
                type: ADD_PAYPALCREDINFO_SUCCESS,
                payload: {
                    payPalCredential,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: ADD_PAYPALCREDINFO_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};

export const savePayPalCredInfoAction = (msbId: any, payPalCredential: PayPalCredentials, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_PAYPALCREDINFO_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_Client/api/v1/PayPalCredentials/${msbId}`;

    axios.put(_url, payPalCredential)
        .then(function (response) {
            payPalCredential = response.data;
            dispatch({
                type: SAVE_PAYPALCREDINFO_SUCCESS,
                payload: {
                    payPalCredential,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_PAYPALCREDINFO_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};

export const deletePayPalCredInfoAction = (msbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_PAYPALCREDINFO_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/PayPalCredentials/${msbId}`;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_PAYPALCREDINFO_SUCCESS,
                payload: {
                    msbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_PAYPALCREDINFO_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const savePaymentChannelConvenienceFeesAction = (convenienceFees: Array<ConvenienceFee>, actionToken: string, msbId?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_PAYMENT_CHANNEL_CONVENIENCE_FEES_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/PaymentChannels/${msbId}/convenienceFees`;

    axios.post(_url, convenienceFees)
        .then(function (response) {
            dispatch({
                type: SAVE_PAYMENT_CHANNEL_CONVENIENCE_FEES_SUCCESS,
                payload: {
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_PAYMENT_CHANNEL_CONVENIENCE_FEES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const getAuthorizeNetCredInfoAction = (msbId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_AUTHORIZENETCREDINFO_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/AuthorizeDotNetCredentials/${msbId}`;
    var authorizeNetCredential: AuthorizeNetCredentials;

    axios.get(_url)
        .then(function (response) {
            authorizeNetCredential = response.data;
            dispatch({
                type: GET_AUTHORIZENETCREDINFO_SUCCESS,
                payload: {
                    authorizeNetCredential,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_AUTHORIZENETCREDINFO_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
};

export const addAuthorizeNetCredInfoAction = (paymentChannelMsbId: any, authorizeNetCredential: AuthorizeNetCredentials, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_AUTHORIZENETCREDINFO_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_Client/api/v1/PaymentChannels/${paymentChannelMsbId}/addAuthorizeDotNetCredentials`;
    let paymentChannel = new PaymentChannel();

    axios.post(_url, authorizeNetCredential)
        .then(function (response) {
            paymentChannel = response.data;
            let processor = paymentChannel.processors.find(p => p.merchantProcessor?.processor === "AuthorizeNet");
            authorizeNetCredential = processor?.merchantProcessor?.authorizeDotNetCredential!;
            dispatch({
                type: ADD_AUTHORIZENETCREDINFO_SUCCESS,
                payload: {
                    authorizeNetCredential,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: ADD_AUTHORIZENETCREDINFO_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};

export const saveAuthorizeNetCredInfoAction = (msbId: any, authorizeNetCredential: AuthorizeNetCredentials, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_AUTHORIZENETCREDINFO_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_Client/api/v1/AuthorizeDotNetCredentials/${msbId}`;

    axios.put(_url, authorizeNetCredential)
        .then(function (response) {
            authorizeNetCredential = response.data;
            dispatch({
                type: SAVE_AUTHORIZENETCREDINFO_SUCCESS,
                payload: {
                    authorizeNetCredential,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_AUTHORIZENETCREDINFO_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};

export const deleteAuthorizeNetCredInfoAction = (msbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_AUTHORIZENETCREDINFO_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Client/api/v1/AuthorizeDotNetCredentials/${msbId}`;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_AUTHORIZENETCREDINFO_SUCCESS,
                payload: {
                    msbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_AUTHORIZENETCREDINFO_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

