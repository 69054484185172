import * as React from "react";
import { Container } from 'react-bootstrap';
import PageHeader from '../../components/layout/PageHeader';
import { ReceiptTypeEnum } from "../../models/Client";
import { Crumb } from "../../models/Crumb";
import { Routes } from "../../routes";
import Receipt from "../receipts/Receipt";

const PointOfSaleReceipt = () => {
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Point of Sale Receipt", Routes.PointOfSaleReceipt.path));

    return (
        <>
            <PageHeader title="Point of Sale Receipt" crumbs={crumbs} />
            <Container fluid>
                <Receipt />
            </Container>
        </>
    )
};

export default PointOfSaleReceipt; 