import React  from "react";
import PageHeader from '../../../../components/layout/PageHeader';
import { Crumb } from "../../../../models/Crumb";
import { Routes } from "../../../../routes";
import UserTabsContainer from './UserTabsContainer';

const AddUser = () => {
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Navient Users", Routes.NavientUsers.path));
    crumbs.push(new Crumb("Add User", Routes.AddNavientUsers.path));
    
    return (
        <>
            <PageHeader title={"Add User"} crumbs={crumbs} />
            <UserTabsContainer />
        </>
    );
};

export default AddUser;
