import { Row, Col, Spinner, Card } from "react-bootstrap"
import { useEffect, useState } from "react";
import Table from '../../../../../../components/Table';

interface IItemShoppingCart {
    title: string,
    subTitle: string,
    taxTypeLabel: string,
    hideTaxType: boolean,
    selectedInstallmentsLabel: string,
    hideSelectedInstallments: boolean,
    paymentAmountLabel: string,
    staticPaymentAmount: boolean,
    accountNumberLabel: string,
    balanceDueLabel: string,
    actionsLabel: string,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
}

export const TITLE = 'Shopping Cart';
export const SUBTITLE = 'The First half payment is due date by 2/28/2022, Second half payment is due by 6/15/2022, and Full payment is due by 4/30/2022.'
export const TAX_TYPE_LABEL = 'Tax type';
export const HIDE_TAX_TYPE = false;
export const SELECTED_INSTALLMENTS_LABEL = 'Selected Payment Plan'
export const HIDE_SELECTED_INSTALLMENTS = false;
export const ACCOUNT_NUMBER_LABEL = 'Account number';
export const BALANCE_DUE_LABEL = 'Balance Due';
export const PAYMENT_AMOUNT_LABEL = 'Payment Amount';
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const ItemShoppingCart = ({ title, subTitle, taxTypeLabel, hideTaxType, selectedInstallmentsLabel, hideSelectedInstallments, accountNumberLabel, balanceDueLabel, actionsLabel,
    margin, padding, headerBackgroundColor, headerPadding, paymentAmountLabel }: IItemShoppingCart) => {

    if (!title) { title = TITLE }
    if (!subTitle) { subTitle = SUBTITLE }
    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!selectedInstallmentsLabel) { selectedInstallmentsLabel = SELECTED_INSTALLMENTS_LABEL }
    if (!hideSelectedInstallments) { hideSelectedInstallments = HIDE_SELECTED_INSTALLMENTS }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!paymentAmountLabel) { paymentAmountLabel = PAYMENT_AMOUNT_LABEL }
    if (!balanceDueLabel) { balanceDueLabel = BALANCE_DUE_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const orderLineFormatter = (cell: any, row: any) => {
        const selectedOrderLines = row?.selectedOrderLines;
         if (selectedOrderLines) {
            return selectedOrderLines.map((orderLine: any) => (
                (
                    <ul style={{paddingLeft:'1rem', marginBottom: '0.5rem'}}>
                        <li>
                        <h2>{orderLine?.id === 0 ? 'Full amount' : `${orderLine.name}`}</h2>
                        <p style={{ marginBottom: '0rem'}}>
                            { orderLine?.id === 0 ? `${formatter.format(orderLine.totalAmount)} Due on ${orderLine?.billDueDate}` 
                            :`${orderLine.label}`}
                            </p>
                        </li>
                    </ul>
                )
            ))
        }
    }

    const initalColumns = [{
        dataField: 'taxType',
        text: taxTypeLabel,
        hidden: true,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'accountNumber',
        text: accountNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'selectedInstallments',
        text: selectedInstallmentsLabel,
        hidden: true,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: false,
        formatter: orderLineFormatter,
    }, {
        dataField: 'paymentAmount',
        text: paymentAmountLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
        formatter: amountFormatter,
    }, {
        dataField: 'balanceDue',
        text: balanceDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,

    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const [columns, setColumns] = useState<any>(initalColumns);
    
    useEffect(() => {
        const newColumns = columns.map((column: any) => {
            if (column.dataField === 'taxType') {
                return { ...column, hidden: hideTaxType }
            }
            if (column.dataField === 'selectedInstallments') {
                return { ...column, hidden: hideSelectedInstallments }
            }
            return column
        })
        setColumns(newColumns)
    }, [hideTaxType, hideSelectedInstallments]);

    const renderTable = () => {
        return (
            <div style={{marginTop: '12px'}}>
            <Table
                keyField="msbId"
                data={[]}
                columns={columns}
            />
            </div>);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </div>

    )
}

export default ItemShoppingCart;