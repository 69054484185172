import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Table from '../../../../components/Table';
import { CurrencyFormatter } from '../../../../components/Formatters';
import { MsbReportItem } from '../../../../models/Reports';
import { PaymentTypeEnum, TransactionTypeEnum } from '../../../../models/Payment';
import { Routes } from '../../../../routes';
import { SET_RECON_TRANSACTIONS_DATA } from '../../../../redux/actions/reports/report';

const DetailsByTransactionType = ({ transactionType, paymentType, msbReportItems, setRedirect }: {
    transactionType: TransactionTypeEnum,
    paymentType?: PaymentTypeEnum,
    msbReportItems: Array<MsbReportItem>,
    setRedirect: any,
}) => {
    const dispatch = useDispatch();
    
    const [payChannelToItems, setPayChannelToItems] = useState<Map<string, Array<MsbReportItem>>>();

    useEffect(() => {
        if (msbReportItems) {
            let payChannelToItems = new Map<string, Array<MsbReportItem>>();
            msbReportItems.forEach((msbReportItem: MsbReportItem) => {
                payChannelToItems.set(
                    msbReportItem.paymentChannelId,
                    (payChannelToItems.get(msbReportItem.paymentChannelId) || []).concat(msbReportItem)
                );
            });
            setPayChannelToItems(payChannelToItems);
        }
    }, [msbReportItems]);

    const viewTransactionsFormatter = (payChanId: string, row: any) => {
        const transactionsData = {
            paymentChannelId: payChanId,
            paymentChannelName: row.paymentChannelName,
            transactionType: transactionType,
            paymentType: paymentType,
        };

        return(
            <span>
                <a 
                    onClick={() => {
                        dispatch({ type: SET_RECON_TRANSACTIONS_DATA, payload: transactionsData });
                        setRedirect(Routes.ReconciliationReportTransactions.path);
                    }}
                >
                    View Transactions
                </a>
            </span>
        );
    };

    const columns = [
        {
            dataField: 'paymentChannelName',
            text: 'Payment channel',
            editable: false
        }, 
        {
            dataField: 'totalCount',
            text: 'Transactions',
            editable: false
        }, 
        {
            dataField: 'amount',
            text: 'Invoice total',
            editable: false,
            formatter: CurrencyFormatter
        }, 
        {
            dataField: 'convenienceFee',
            text: 'Convenience fee total',
            editable: false,
            formatter: CurrencyFormatter
        }, 
        {
            dataField: 'totalAmount',
            text: 'Total amount',
            editable: false,
            formatter: CurrencyFormatter
        }, 
        {
            dataField: 'paymentChannelId',
            text: 'Actions',
            editable: false,
            formatter: viewTransactionsFormatter,
            formatExtraData: payChannelToItems
        }
    ];

    if (msbReportItems.length < 1) {
        return (
            <p className="mb-0">Currently there are no records to display.</p>
        );
    } else {
        return (
            <Table
                keyField="paymentChannelName"
                columns={columns}
                data={
                    Array.from(payChannelToItems || []).map(([payChanId, items]) => ({
                        clientName: items[0].clientName,
                        departmentName: items[0].departmentName,
                        paymentChannelName: items[0].paymentChannelName,
                        paymentChannelId: payChanId,
                        transactionType: items[0].transactionType,
                        paymentType: items[0].paymentType,
                        totalCount: items.reduce((acc, curr) => acc + curr.totalCount, 0),
                        amount: items.reduce((acc, curr) => acc + curr.amount, 0),
                        convenienceFee: items.reduce((acc, curr) => acc + curr.convenienceFee, 0),
                        totalAmount: items.reduce((acc, curr) => acc + curr.totalAmount, 0)
                    }))
                }
            />
        );
    }
};

export default DetailsByTransactionType;