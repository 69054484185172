import {
    CANCEL_STEP,DEPARTMENT_CANCEL_STEP
} from '../actions/clients/clientOnboarding';

import {
    ADD_CLIENT_REQUEST, ADD_CLIENT_SUCCESS, ADD_CLIENT_FAILURE,
    SAVE_CLIENT_REQUEST, SAVE_CLIENT_SUCCESS, SAVE_CLIENT_FAILURE,
    DELETE_CLIENT_REQUEST, DELETE_CLIENT_SUCCESS, DELETE_CLIENT_FAILURE,
    GET_CLIENT_NAMES_REQUEST, GET_CLIENT_NAMES_SUCCESS, GET_CLIENT_NAMES_FAILURE,
    GET_CLIENT_TIMEZONE_REQUEST, GET_CLIENT_TIMEZONE_SUCCESS, GET_CLIENT_TIMEZONE_FAILURE,
    GET_CLIENT_REQUEST, GET_CLIENT_SUCCESS, GET_CLIENT_FAILURE,
    GET_CLIENTS_REQUEST, GET_CLIENTS_SUCCESS, GET_CLIENTS_FAILURE,
    GET_ALL_CLIENTS_REQUEST, GET_ALL_CLIENTS_SUCCESS, GET_ALL_CLIENTS_FAILURE,
    SAVE_CLIENT_STATUS_REQUEST, SAVE_CLIENT_STATUS_SUCCESS, SAVE_CLIENT_STATUS_FAILURE,
    RESET_STORE_REQUEST, RESET_STORE_SUCCESS, SET_CLIENT_BANKING_DETAILS, RESET_CLIENT_BANKING_DETAILS,
    SAVE_CLIENT_BANKING_REQUEST, SAVE_CLIENT_BANKING_SUCCESS, SAVE_CLIENT_BANKING_FAILURE,
    DELETE_CLIENT_BANKING_REQUEST, DELETE_CLIENT_BANKING_SUCCESS, DELETE_CLIENT_BANKING_FAILURE
} from '../actions/clients/clients';

import {
    ADD_CLIENT_ADDRESS_REQUEST, ADD_CLIENT_ADDRESS_SUCCESS, ADD_CLIENT_ADDRESS_FAILURE,
    SAVE_CLIENT_ADDRESS_REQUEST, SAVE_CLIENT_ADDRESS_SUCCESS, SAVE_CLIENT_ADDRESS_FAILURE,
    DELETE_CLIENT_ADDRESS_REQUEST, DELETE_CLIENT_ADDRESS_SUCCESS, DELETE_CLIENT_ADDRESS_FAILURE,

    ADD_DEPARTMENT_ADDRESS_REQUEST, ADD_DEPARTMENT_ADDRESS_SUCCESS, ADD_DEPARTMENT_ADDRESS_FAILURE,
    SAVE_DEPARTMENT_ADDRESS_REQUEST, SAVE_DEPARTMENT_ADDRESS_SUCCESS, SAVE_DEPARTMENT_ADDRESS_FAILURE,
    DELETE_DEPARTMENT_ADDRESS_REQUEST, DELETE_DEPARTMENT_ADDRESS_SUCCESS, DELETE_DEPARTMENT_ADDRESS_FAILURE
} from '../actions/clients/address';

import {
    ADD_CLIENT_CONTACT_REQUEST, ADD_CLIENT_CONTACT_SUCCESS, ADD_CLIENT_CONTACT_FAILURE,
    SAVE_CLIENT_CONTACT_REQUEST, SAVE_CLIENT_CONTACT_SUCCESS, SAVE_CLIENT_CONTACT_FAILURE,
    DELETE_CLIENT_CONTACT_REQUEST, DELETE_CLIENT_CONTACT_SUCCESS, DELETE_CLIENT_CONTACT_FAILURE,

    ADD_DEPARTMENT_CONTACT_REQUEST, ADD_DEPARTMENT_CONTACT_SUCCESS, ADD_DEPARTMENT_CONTACT_FAILURE,
    SAVE_DEPARTMENT_CONTACT_REQUEST, SAVE_DEPARTMENT_CONTACT_SUCCESS, SAVE_DEPARTMENT_CONTACT_FAILURE,
    DELETE_DEPARTMENT_CONTACT_REQUEST, DELETE_DEPARTMENT_CONTACT_SUCCESS, DELETE_DEPARTMENT_CONTACT_FAILURE
} from '../actions/clients/contacts';

import {
    ADD_DEPARTMENT_REQUEST, ADD_DEPARTMENT_SUCCESS, ADD_DEPARTMENT_FAILURE,
    SAVE_DEPARTMENT_REQUEST, SAVE_DEPARTMENT_SUCCESS, SAVE_DEPARTMENT_FAILURE,
    DELETE_DEPARTMENT_REQUEST, DELETE_DEPARTMENT_SUCCESS, DELETE_DEPARTMENT_FAILURE,
    GET_DEPARTMENT_NAMES_REQUEST, GET_DEPARTMENT_NAMES_SUCCESS, GET_DEPARTMENT_NAMES_FAILURE,
    GET_DEPARTMENT_REQUEST, GET_DEPARTMENT_SUCCESS, GET_DEPARTMENT_FAILURE,
    SAVE_CONVENIENCE_FEES_REQUEST, SAVE_CONVENIENCE_FEES_SUCCESS, SAVE_CONVENIENCE_FEES_FAILURE,
    SAVE_DEPARTMENT_RECEIPT_REQUEST, SAVE_DEPARTMENT_RECEIPT_SUCCESS, SAVE_DEPARTMENT_RECEIPT_FAILURE,
    RESET_STORE_DEPARTMENT_REQUEST, RESET_STORE_DEPARTMENT_SUCCESS,
    SET_STORE_DEPARTMENT_REQUEST, SET_STORE_DEPARTMENT_SUCCESS,
    SET_DEPARTMENT_RECEIPT_REQUEST, SET_DEPARTMENT_RECEIPT_SUCCESS, CLEAR_RECEIPT_TOAST_ACTION_REQUEST
} from '../actions/clients/departments';

import {
    ADD_MERCHANT_REQUEST, ADD_MERCHANT_SUCCESS, ADD_MERCHANT_FAILURE,
    SAVE_MERCHANT_REQUEST, SAVE_MERCHANT_SUCCESS, SAVE_MERCHANT_FAILURE,
    DELETE_MERCHANT_REQUEST, DELETE_MERCHANT_SUCCESS, DELETE_MERCHANT_FAILURE,
    ONBOARD_MERCHANT_REQUEST, ONBOARD_MERCHANT_SUCCESS, ONBOARD_MERCHANT_FAILURE,
    TOGGLE_OMNITOKEN_MERCHANT_REQUEST, TOGGLE_OMNITOKEN_MERCHANT_SUCCESS, TOGGLE_OMNITOKEN_MERCHANT_FAILURE,
    GET_MERCHANT_REQUEST, GET_MERCHANT_SUCCESS, GET_MERCHANT_FAILURE,
    RESET_STORE_MERCHANT_REQUEST, RESET_STORE_MERCHANT_SUCCESS,
    SET_STORE_MERCHANT_REQUEST, SET_STORE_MERCHANT_SUCCESS,
    UPDATE_MERCHANT_REQUEST, UPDATE_MERCHANT_SUCCESS, UPDATE_MERCHANT_FAILURE,
} from '../actions/clients/merchants';

import {
    SAVE_PRINTER_REQUEST, SAVE_PRINTER_SUCCESS, SAVE_PRINTER_FAILURE,
    DELETE_PRINTER_REQUEST, DELETE_PRINTER_SUCCESS, DELETE_PRINTER_FAILURE
} from '../actions/clients/printers';

import {
    ADD_TERMINAL_REQUEST, ADD_TERMINAL_SUCCESS, ADD_TERMINAL_FAILURE,
    SAVE_TERMINAL_REQUEST, SAVE_TERMINAL_SUCCESS, SAVE_TERMINAL_FAILURE,
    DELETE_TERMINAL_REQUEST, DELETE_TERMINAL_SUCCESS, DELETE_TERMINAL_FAILURE,
    UPDATE_TERMINAL_REQUEST, UPDATE_TERMINAL_SUCCESS, UPDATE_TERMINAL_FAILURE
} from '../actions/clients/terminals';

import {
    GET_PAYMENTCHANNEL_REQUEST, GET_PAYMENTCHANNEL_SUCCESS, GET_PAYMENTCHANNEL_FAILURE,
    ADD_PAYMENTCHANNEL_REQUEST, ADD_PAYMENTCHANNEL_SUCCESS, ADD_PAYMENTCHANNEL_FAILURE,
    SAVE_PAYMENTCHANNEL_REQUEST, SAVE_PAYMENTCHANNEL_SUCCESS, SAVE_PAYMENTCHANNEL_FAILURE,
    DELETE_PAYMENTCHANNEL_REQUEST, DELETE_PAYMENTCHANNEL_SUCCESS, DELETE_PAYMENTCHANNEL_FAILURE,
    GET_PAYMENTCHANNELNAMES_REQUEST, GET_PAYMENTCHANNELNAMES_SUCCESS, GET_PAYMENTCHANNELNAMES_FAILURE,
    GET_ALL_PAYMENTCHANNELNAMES_REQUEST, GET_ALL_PAYMENTCHANNELNAMES_SUCCESS, GET_ALL_PAYMENTCHANNELNAMES_FAILURE
} from '../actions/clients/paymentChannels';

import {
    GET_PAYPALCREDINFO_REQUEST, GET_PAYPALCREDINFO_SUCCESS, GET_PAYPALCREDINFO_FAILURE,
    ADD_PAYPALCREDINFO_REQUEST, ADD_PAYPALCREDINFO_SUCCESS, ADD_PAYPALCREDINFO_FAILURE,
    SAVE_PAYPALCREDINFO_REQUEST, SAVE_PAYPALCREDINFO_SUCCESS, SAVE_PAYPALCREDINFO_FAILURE,
    DELETE_PAYPALCREDINFO_REQUEST, DELETE_PAYPALCREDINFO_SUCCESS, DELETE_PAYPALCREDINFO_FAILURE,
} from '../actions/clients/paymentChannels';

import {
    GET_AUTHORIZENETCREDINFO_REQUEST, GET_AUTHORIZENETCREDINFO_SUCCESS, GET_AUTHORIZENETCREDINFO_FAILURE,
    ADD_AUTHORIZENETCREDINFO_REQUEST, ADD_AUTHORIZENETCREDINFO_SUCCESS, ADD_AUTHORIZENETCREDINFO_FAILURE,
    SAVE_AUTHORIZENETCREDINFO_REQUEST, SAVE_AUTHORIZENETCREDINFO_SUCCESS, SAVE_AUTHORIZENETCREDINFO_FAILURE,
    DELETE_AUTHORIZENETCREDINFO_REQUEST, DELETE_AUTHORIZENETCREDINFO_SUCCESS, DELETE_AUTHORIZENETCREDINFO_FAILURE,
} from '../actions/clients/paymentChannels';

import {
    SAVE_APPROVAL_CHECKLIST_REQUEST, SAVE_APPROVAL_CHECKLIST_SUCCESS, SAVE_APPROVAL_CHECKLIST_FAILURE,
} from '../actions/clients/approvalChecklist';

import {
    SAVE_AGREEMENT_REQUEST, SAVE_AGREEMENT_SUCCESS, SAVE_AGREEMENT_FAILURE,
} from '../actions/clients/clientAgreement';

export default function clients(state = {
    isFetching: false,
    isSaving: false,
    errorMessage: '',
    currentPage: null,
    client: null,
    clientBanking: null,
    actionResult: null,
    department: null,
    merchant: null,
    clientNames: null,
    clientTimezones: null,
    departmentNames: null,
    paymentChannelNames: null,
    receipt: null,
    paymentChannelMap: {},
    paymentChannel: null,
    payPalCredInfo: null,
    authorizeNetCredInfo: null,
    clearToast: false,
}, { type, payload }) {
    switch (type) {
    // Client null when starting add client workflow
        case CANCEL_STEP:
            return Object.assign({}, state, {
                client: null,
                department: null
            });
    // Client
        case ADD_CLIENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_CLIENT_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_CLIENT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_CLIENT_REQUEST, result: ADD_CLIENT_SUCCESS, token: payload.actionToken },
                client: payload.client
            });
        case ADD_CLIENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_CLIENT_REQUEST, result: ADD_CLIENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case SAVE_CLIENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_CLIENT_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_CLIENT_SUCCESS:
            {
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_CLIENT_REQUEST, result: SAVE_CLIENT_SUCCESS, token: payload.actionToken },
                    client: payload.client
                });
            }
        case SAVE_CLIENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CLIENT_REQUEST, result: SAVE_CLIENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DELETE_CLIENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: DELETE_CLIENT_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_CLIENT_SUCCESS:
            {
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: DELETE_CLIENT_REQUEST, result: DELETE_CLIENT_SUCCESS, token: payload.actionToken },
                    client: payload.client
                });
            }
        case DELETE_CLIENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: DELETE_CLIENT_REQUEST, result: DELETE_CLIENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case GET_CLIENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_CLIENT_REQUEST, result: null, token: payload.actionToken },
                client: null,
                department: null
            });
        case GET_CLIENT_SUCCESS:
            return Object.assign({}, state, { 
                isFetching: false,
                actionResult: { type: GET_CLIENT_REQUEST, result: GET_CLIENT_SUCCESS, token: payload.actionToken } ,
                client: payload.client,
            });
        case GET_CLIENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_CLIENT_REQUEST, result: GET_CLIENT_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
    // Clients
        case GET_CLIENT_NAMES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_CLIENT_NAMES_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_CLIENT_NAMES_SUCCESS:
        return Object.assign({}, state, { 
            isFetching: false,
            actionResult: { type: GET_CLIENT_NAMES_REQUEST, result: GET_CLIENT_NAMES_SUCCESS, token: payload.actionToken } ,
            clientNames: payload.clientNames,
        });
        case GET_CLIENT_NAMES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_CLIENT_NAMES_REQUEST, result: GET_CLIENT_NAMES_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
        });
        case GET_CLIENT_TIMEZONE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_CLIENT_TIMEZONE_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_CLIENT_TIMEZONE_SUCCESS:
            return Object.assign({}, state, { 
                isFetching: false,
                actionResult: { type: GET_CLIENT_TIMEZONE_REQUEST, result: GET_CLIENT_NAMES_SUCCESS, token: payload.actionToken } ,
                clientTimezones: payload.clientTimezones,
            });
        case GET_ALL_CLIENTS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_ALL_CLIENTS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_ALL_CLIENTS_SUCCESS:
        return Object.assign({}, state, { 
            isFetching: false,
            actionResult: { type: GET_ALL_CLIENTS_REQUEST, result: GET_ALL_CLIENTS_SUCCESS, token: payload.actionToken } ,
            clients: payload.clients,
        });
        case GET_ALL_CLIENTS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_ALL_CLIENTS_REQUEST, result: GET_ALL_CLIENTS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
        });
        case GET_CLIENTS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_CLIENTS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_CLIENTS_SUCCESS:
            return Object.assign({}, state, { 
                isFetching: false,
                actionResult: { type: GET_CLIENTS_REQUEST, result: GET_CLIENTS_SUCCESS, token: payload.actionToken } ,
                currentPage: payload.results,
            });
        case GET_CLIENTS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_CLIENTS_REQUEST, result: GET_CLIENTS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_CLIENT_STATUS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_CLIENT_STATUS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_CLIENT_STATUS_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.statusEnum = payload.clientStatusEnum;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_CLIENT_STATUS_REQUEST, result: SAVE_CLIENT_STATUS_SUCCESS, token: payload.actionToken } ,
                    client: _client
                });
            }
        case SAVE_CLIENT_STATUS_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CLIENT_STATUS_REQUEST, result: SAVE_CLIENT_STATUS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
    // Client Banking
        case SET_CLIENT_BANKING_DETAILS:
            return Object.assign({}, state, {
                actionResult: { type: SET_CLIENT_BANKING_DETAILS, result: SET_CLIENT_BANKING_DETAILS, token: payload.actionToken },
                clientBanking: payload.clientBanking
            });
        case RESET_CLIENT_BANKING_DETAILS:
            return Object.assign({}, state, {
                actionResult: { type: RESET_CLIENT_BANKING_DETAILS, result: RESET_CLIENT_BANKING_DETAILS, token: payload.actionToken },
                clientBanking: null
            });
        case SAVE_CLIENT_BANKING_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_CLIENT_BANKING_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_CLIENT_BANKING_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CLIENT_BANKING_REQUEST, result: SAVE_CLIENT_BANKING_SUCCESS, token: payload.actionToken } ,
            });
        case SAVE_CLIENT_BANKING_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CLIENT_BANKING_REQUEST, result: SAVE_CLIENT_BANKING_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case DELETE_CLIENT_BANKING_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: DELETE_CLIENT_BANKING_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_CLIENT_BANKING_SUCCESS:
            return Object.assign({}, state, { 
                isSaving: false,
                actionResult: { type: DELETE_CLIENT_BANKING_REQUEST, result: DELETE_CLIENT_BANKING_SUCCESS, token: payload.actionToken } ,
            });
        case DELETE_CLIENT_BANKING_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: DELETE_CLIENT_BANKING_REQUEST, result: DELETE_CLIENT_BANKING_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });    
    // Client Addresses
        case ADD_CLIENT_ADDRESS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_CLIENT_ADDRESS_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_CLIENT_ADDRESS_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.clientAddresses = payload.client.clientAddresses;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_CLIENT_ADDRESS_REQUEST, result: ADD_CLIENT_ADDRESS_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case ADD_CLIENT_ADDRESS_FAILURE:
            {
                let message = JSON.parse(payload.error?.response.data).message;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_CLIENT_ADDRESS_REQUEST, result: ADD_CLIENT_ADDRESS_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case SAVE_CLIENT_ADDRESS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_CLIENT_ADDRESS_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_CLIENT_ADDRESS_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.clientAddresses = _client.clientAddresses.map(address => { if (address.msbId === payload.address.msbId) return payload.address; else return address; });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_CLIENT_ADDRESS_REQUEST, result: SAVE_CLIENT_ADDRESS_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case SAVE_CLIENT_ADDRESS_FAILURE:
            {
                let message = JSON.parse(payload.error?.response.data).message;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_CLIENT_ADDRESS_REQUEST, result: SAVE_CLIENT_ADDRESS_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case DELETE_CLIENT_ADDRESS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_CLIENT_ADDRESS_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_CLIENT_ADDRESS_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.clientAddresses = _client.clientAddresses.filter(address => address.msbId !== payload.addressMsbId);
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: DELETE_CLIENT_ADDRESS_REQUEST, result: DELETE_CLIENT_ADDRESS_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case DELETE_CLIENT_ADDRESS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_CLIENT_ADDRESS_REQUEST, result: DELETE_CLIENT_ADDRESS_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
    // Client Contacts
        case ADD_CLIENT_CONTACT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_CLIENT_CONTACT_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_CLIENT_CONTACT_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.clientContacts = payload.client.clientContacts;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_CLIENT_CONTACT_REQUEST, result: ADD_CLIENT_CONTACT_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case ADD_CLIENT_CONTACT_FAILURE:
            {
                let message = JSON.parse(payload.error?.response.data).message;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_CLIENT_CONTACT_REQUEST, result: ADD_CLIENT_CONTACT_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case SAVE_CLIENT_CONTACT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_CLIENT_CONTACT_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_CLIENT_CONTACT_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.clientContacts = _client.clientContacts.map(contact => { if (contact.msbId === payload.contact.msbId) return payload.contact; else return contact; });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_CLIENT_CONTACT_REQUEST, result: SAVE_CLIENT_CONTACT_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case SAVE_CLIENT_CONTACT_FAILURE:
            {
                let message = JSON.parse(payload.error?.response.data).message;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_CLIENT_CONTACT_REQUEST, result: SAVE_CLIENT_CONTACT_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case DELETE_CLIENT_CONTACT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_CLIENT_CONTACT_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_CLIENT_CONTACT_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.clientContacts = _client.clientContacts.filter(contact => contact.msbId !== payload.contactMsbId);
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: DELETE_CLIENT_CONTACT_REQUEST, result: DELETE_CLIENT_CONTACT_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case DELETE_CLIENT_CONTACT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_CLIENT_CONTACT_REQUEST, result: DELETE_CLIENT_CONTACT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
    // Departments
        case ADD_DEPARTMENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_DEPARTMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_DEPARTMENT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_DEPARTMENT_REQUEST, result: ADD_DEPARTMENT_SUCCESS, token: payload.actionToken },
                client: payload.client
            });
        case ADD_DEPARTMENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_DEPARTMENT_REQUEST, result: ADD_DEPARTMENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case SAVE_DEPARTMENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_DEPARTMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_DEPARTMENT_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                delete payload.department.onBoardingStatus;
                delete payload.department.clientBankAccounts;
                delete payload.department.convenienceFees;
                delete payload.department.departmentAddresses;
                delete payload.department.departmentContacts;
                delete payload.department.departmentsPrinters;
                delete payload.department.paymentChannels;
                delete payload.department.receipts;
                _department = Object.assign(_department, payload.department);
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_DEPARTMENT_REQUEST, result: SAVE_DEPARTMENT_SUCCESS, token: payload.actionToken },
                    department: _department
                });
            }
        case SAVE_DEPARTMENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_DEPARTMENT_REQUEST, result: SAVE_DEPARTMENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DELETE_DEPARTMENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: DELETE_DEPARTMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_DEPARTMENT_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.departments = _client.departments.filter(department => department.msbId !== payload.departmentMsbId);
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: DELETE_DEPARTMENT_REQUEST, result: DELETE_DEPARTMENT_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case DELETE_DEPARTMENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: DELETE_DEPARTMENT_REQUEST, result: DELETE_DEPARTMENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DEPARTMENT_CANCEL_STEP:
            return Object.assign({}, state, {
                department: null
            });
        case GET_DEPARTMENT_NAMES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_DEPARTMENT_NAMES_REQUEST, result: null, token: payload.actionToken },
                department: null
            });
        case GET_DEPARTMENT_NAMES_SUCCESS:
            return Object.assign({}, state, { 
                isFetching: false,
                actionResult: { type: GET_DEPARTMENT_NAMES_REQUEST, result: GET_DEPARTMENT_NAMES_SUCCESS, token: payload.actionToken } ,
                departmentNames: payload.departmentNames,
            });
        case GET_DEPARTMENT_NAMES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_DEPARTMENT_NAMES_REQUEST, result: GET_DEPARTMENT_NAMES_SUCCESS, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case GET_DEPARTMENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_DEPARTMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_DEPARTMENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_DEPARTMENT_REQUEST, result: GET_DEPARTMENT_SUCCESS, token: payload.actionToken },
                department: payload.department,
            });
        case GET_DEPARTMENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_DEPARTMENT_REQUEST, result: GET_DEPARTMENT_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_CONVENIENCE_FEES_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_CONVENIENCE_FEES_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_CONVENIENCE_FEES_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.convenienceFees = payload.department.convenienceFees;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_CONVENIENCE_FEES_REQUEST, result: SAVE_CONVENIENCE_FEES_SUCCESS, token: payload.actionToken },
                    department: _department,
                });
            }
        case SAVE_CONVENIENCE_FEES_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CONVENIENCE_FEES_REQUEST, result: SAVE_CONVENIENCE_FEES_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
    // Department Addresses
        case ADD_DEPARTMENT_ADDRESS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_DEPARTMENT_ADDRESS_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_DEPARTMENT_ADDRESS_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.departmentAddresses = payload.department.departmentAddresses;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_DEPARTMENT_ADDRESS_REQUEST, result: ADD_DEPARTMENT_ADDRESS_SUCCESS, token: payload.actionToken },
                    department: _department
                });
            }
        case ADD_DEPARTMENT_ADDRESS_FAILURE:
            {
                let message = JSON.parse(payload.error?.response.data).message;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_DEPARTMENT_ADDRESS_REQUEST, result: ADD_DEPARTMENT_ADDRESS_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case SAVE_DEPARTMENT_ADDRESS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_DEPARTMENT_ADDRESS_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_DEPARTMENT_ADDRESS_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.departmentAddresses = _department.departmentAddresses.map(address => { if (address.msbId === payload.address.msbId) return payload.address; else return address; });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_DEPARTMENT_ADDRESS_REQUEST, result: SAVE_DEPARTMENT_ADDRESS_SUCCESS, token: payload.actionToken },
                    department: _department
                });
            }
        case SAVE_DEPARTMENT_ADDRESS_FAILURE:
            {
                let message = JSON.parse(payload.error?.response.data).message;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_DEPARTMENT_ADDRESS_REQUEST, result: SAVE_DEPARTMENT_ADDRESS_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case DELETE_DEPARTMENT_ADDRESS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_DEPARTMENT_ADDRESS_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_DEPARTMENT_ADDRESS_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.departmentAddresses = _department.departmentAddresses.filter(address => address.msbId !== payload.addressMsbId);
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: DELETE_DEPARTMENT_ADDRESS_REQUEST, result: DELETE_DEPARTMENT_ADDRESS_SUCCESS, token: payload.actionToken },
                    department: _department
                });
            }
        case DELETE_DEPARTMENT_ADDRESS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_DEPARTMENT_ADDRESS_REQUEST, result: DELETE_DEPARTMENT_ADDRESS_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        // Department Contacts
        case ADD_DEPARTMENT_CONTACT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_DEPARTMENT_CONTACT_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_DEPARTMENT_CONTACT_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.departmentContacts = payload.department.departmentContacts;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_DEPARTMENT_CONTACT_REQUEST, result: ADD_DEPARTMENT_CONTACT_SUCCESS, token: payload.actionToken },
                    department: _department
                });
            }
        case ADD_DEPARTMENT_CONTACT_FAILURE:
            {
                let message = JSON.parse(payload.error?.response.data).message;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_DEPARTMENT_CONTACT_REQUEST, result: ADD_DEPARTMENT_CONTACT_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case SAVE_DEPARTMENT_CONTACT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_DEPARTMENT_CONTACT_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_DEPARTMENT_CONTACT_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.departmentContacts = _department.departmentContacts.map(contact => { if (contact.msbId === payload.contact.msbId) return payload.contact; else return contact; });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_DEPARTMENT_CONTACT_REQUEST, result: SAVE_DEPARTMENT_CONTACT_SUCCESS, token: payload.actionToken },
                    department: _department
                });
            }
        case SAVE_DEPARTMENT_CONTACT_FAILURE:
            {
                let message = JSON.parse(payload.error?.response.data).message;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_DEPARTMENT_CONTACT_REQUEST, result: SAVE_DEPARTMENT_CONTACT_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case DELETE_DEPARTMENT_CONTACT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_DEPARTMENT_CONTACT_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_DEPARTMENT_CONTACT_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.departmentContacts = _department.departmentContacts.filter(contact => contact.msbId !== payload.contactMsbId);
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: DELETE_DEPARTMENT_CONTACT_REQUEST, result: DELETE_DEPARTMENT_CONTACT_SUCCESS, token: payload.actionToken },
                    department: _department
                });
            }
        case DELETE_DEPARTMENT_CONTACT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_DEPARTMENT_CONTACT_REQUEST, result: DELETE_DEPARTMENT_CONTACT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
    // Merchants
        case ADD_MERCHANT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_MERCHANT_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_MERCHANT_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.merchantProcessors = payload.client.merchantProcessors;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_MERCHANT_REQUEST, result: ADD_MERCHANT_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case ADD_MERCHANT_FAILURE:
            {
                let message = payload.error?.response.data.map(error => { return `${error.errorMessage}` });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_MERCHANT_REQUEST, result: ADD_MERCHANT_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case SAVE_MERCHANT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_MERCHANT_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_MERCHANT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_MERCHANT_REQUEST, result: SAVE_MERCHANT_SUCCESS, token: payload.actionToken },
                merchant: payload.merchant
            });
        case SAVE_MERCHANT_FAILURE:
            {
                let message = payload.error.response.data.map(error => { return `${error.errorMessage}` });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_MERCHANT_REQUEST, result: SAVE_MERCHANT_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case ONBOARD_MERCHANT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ONBOARD_MERCHANT_REQUEST, result: null, token: payload.actionToken },
            });
        case ONBOARD_MERCHANT_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.merchantProcessors = _client.merchantProcessors.map(merchant => { if (merchant.msbId === payload.merchant.msbId) return payload.merchant; else return merchant; });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ONBOARD_MERCHANT_REQUEST, result: ONBOARD_MERCHANT_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case ONBOARD_MERCHANT_FAILURE:
            {
                let message = payload.error.response.data.errors.map(error => { return `${error.errorMessage} : ${error.target}` });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ONBOARD_MERCHANT_REQUEST, result: ONBOARD_MERCHANT_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case TOGGLE_OMNITOKEN_MERCHANT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: TOGGLE_OMNITOKEN_MERCHANT_REQUEST, result: null, token: payload.actionToken },
            });
        case TOGGLE_OMNITOKEN_MERCHANT_SUCCESS:
            {
                //let _client = Object.assign({}, state.client);
                //_client.merchantProcessors = _client.merchantProcessors.map(merchant => { if (merchant.msbId === payload.merchant.msbId) return payload.merchant; else return merchant; });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: TOGGLE_OMNITOKEN_MERCHANT_REQUEST, result: TOGGLE_OMNITOKEN_MERCHANT_SUCCESS, token: payload.actionToken },
                    //client: _client
                });
            }
        case TOGGLE_OMNITOKEN_MERCHANT_FAILURE:
            {
                let message = payload.error.response.data.message;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: TOGGLE_OMNITOKEN_MERCHANT_REQUEST, result: TOGGLE_OMNITOKEN_MERCHANT_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        case DELETE_MERCHANT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: DELETE_MERCHANT_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_MERCHANT_SUCCESS:
            {
                let _client = Object.assign({}, state.client);
                _client.merchantProcessors = _client.merchantProcessors.filter(merchant => merchant.msbId !== payload.merchantMsbId);
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: DELETE_MERCHANT_REQUEST, result: DELETE_MERCHANT_SUCCESS, token: payload.actionToken },
                    client: _client
                });
            }
        case DELETE_MERCHANT_FAILURE:
            {
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: DELETE_MERCHANT_REQUEST, result: DELETE_MERCHANT_FAILURE, token: payload.actionToken },
                    errorMessage: payload.error
                });
            }
        case GET_MERCHANT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_MERCHANT_REQUEST, result: null, token: payload.actionToken },
                merchant: null
            });
        case GET_MERCHANT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_MERCHANT_REQUEST, result: GET_MERCHANT_SUCCESS, token: payload.actionToken },
                merchant: payload.merchant,
            });
        case GET_MERCHANT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_MERCHANT_REQUEST, result: GET_MERCHANT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case SET_STORE_MERCHANT_REQUEST:
            return Object.assign({}, state, {
                actionResult: { type: SET_STORE_MERCHANT_REQUEST, result: null, token: payload.actionToken },
            });
        case SET_STORE_MERCHANT_SUCCESS:
            return Object.assign({}, state, {
                merchant: payload.merchant,
                actionResult: { type: SET_STORE_MERCHANT_REQUEST, result: SET_STORE_MERCHANT_SUCCESS, token: payload.actionToken },
            });
        case RESET_STORE_MERCHANT_REQUEST:
            return Object.assign({}, state, {
                actionResult: { type: RESET_STORE_MERCHANT_REQUEST, result: null, token: payload.actionToken },
            });
        case RESET_STORE_MERCHANT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                errorMessage: '',
                merchant: null,
                actionResult: { type: RESET_STORE_MERCHANT_REQUEST, result: RESET_STORE_MERCHANT_SUCCESS, token: payload.actionToken },
            });
        case UPDATE_MERCHANT_REQUEST:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                actionResult: { type: UPDATE_MERCHANT_REQUEST, result: null, token: payload.actionToken },
            });
        case UPDATE_MERCHANT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                actionResult: { type: UPDATE_MERCHANT_REQUEST, result: UPDATE_MERCHANT_SUCCESS, token: payload.actionToken },
                merchant: payload.merchant,
            });
        case UPDATE_MERCHANT_FAILURE:
            {
                let message = payload.error.response.data?.errors?.map(error => { return `${error.errorMessage}` }) || payload.error.response.data?.message;
                return Object.assign({}, state, {
                    isFetching: false,
                    isSaving: false,
                    actionResult: { type: UPDATE_MERCHANT_REQUEST, result: UPDATE_MERCHANT_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
    // Printers
        case SAVE_PRINTER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_PRINTER_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_PRINTER_SUCCESS:
            {
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: SAVE_PRINTER_REQUEST, result: SAVE_PRINTER_SUCCESS, token: payload.actionToken },
                    department: payload.department
                });
            }
        case SAVE_PRINTER_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_PRINTER_REQUEST, result: SAVE_PRINTER_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DELETE_PRINTER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_PRINTER_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_PRINTER_SUCCESS:
            {
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: DELETE_PRINTER_REQUEST, result: DELETE_PRINTER_SUCCESS, token: payload.actionToken },
                    department: payload.department
                });
            }
        case DELETE_PRINTER_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_PRINTER_REQUEST, result: DELETE_PRINTER_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });

    // Terminals
        case ADD_TERMINAL_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_TERMINAL_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_TERMINAL_SUCCESS:
            {
                let _merchant = Object.assign({}, state.merchant);
                _merchant.terminals.push(payload.terminal);
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_TERMINAL_REQUEST, result: ADD_TERMINAL_SUCCESS, token: payload.actionToken },
                    merchant: _merchant
                });
            }
        case ADD_TERMINAL_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_TERMINAL_REQUEST, result: ADD_TERMINAL_FAILURE, token: payload.actionToken, message: payload.error },
                errorMessage: payload.error
            });
        case SAVE_TERMINAL_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_TERMINAL_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_TERMINAL_SUCCESS:
            {
                let _merchant = Object.assign({}, state.merchant);
                _merchant.terminals = _merchant.terminals.map(terminal => { if (terminal.msbId === payload.terminal.msbId) return payload.terminal; else return terminal; });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_TERMINAL_REQUEST, result: SAVE_TERMINAL_SUCCESS, token: payload.actionToken },
                    merchant: _merchant
                });
            }
        case SAVE_TERMINAL_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_TERMINAL_REQUEST, result: SAVE_TERMINAL_FAILURE, token: payload.actionToken, message: payload.error },
                errorMessage: payload.error
            });
        case DELETE_TERMINAL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_TERMINAL_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_TERMINAL_SUCCESS:
            {
                let _merchant = Object.assign({}, state.merchant);
                _merchant.terminals = _merchant.terminals.filter(terminal => terminal.msbId !== payload.terminalMsbId);
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: DELETE_TERMINAL_REQUEST, result: DELETE_TERMINAL_SUCCESS, token: payload.actionToken },
                    merchant: _merchant
                });
            }
        case DELETE_TERMINAL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_TERMINAL_REQUEST, result: DELETE_TERMINAL_FAILURE, token: payload.actionToken, message: payload.error },
                errorMessage: payload.error
            });
        case UPDATE_TERMINAL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: UPDATE_TERMINAL_REQUEST, result: null, token: payload.actionToken },
            });
        case UPDATE_TERMINAL_SUCCESS:
            {
                let _merchant = Object.assign({}, state.merchant);
                _merchant.terminals = _merchant.terminals.filter(terminal => terminal.msbId !== payload.terminalMsbId);
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: UPDATE_TERMINAL_REQUEST, result: UPDATE_TERMINAL_SUCCESS, token: payload.actionToken },
                    merchant: _merchant
                });
            }
        case UPDATE_TERMINAL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: UPDATE_TERMINAL_REQUEST, result: UPDATE_TERMINAL_FAILURE, token: payload.actionToken, message: payload.error },
                errorMessage: payload.error
            });
    // Payment Channels
        case GET_ALL_PAYMENTCHANNELNAMES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_ALL_PAYMENTCHANNELNAMES_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_ALL_PAYMENTCHANNELNAMES_SUCCESS:
            let _paymentChannelNames = Object.entries(payload?.paymentChannelNames).map(([msbId, name]) => ({ msbId, name }));
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_ALL_PAYMENTCHANNELNAMES_REQUEST, result: GET_ALL_PAYMENTCHANNELNAMES_SUCCESS, token: payload.actionToken },
                paymentChannelNames: _paymentChannelNames,
            });
        case GET_ALL_PAYMENTCHANNELNAMES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_ALL_PAYMENTCHANNELNAMES_REQUEST, result: GET_ALL_PAYMENTCHANNELNAMES_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case GET_PAYMENTCHANNEL_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: GET_PAYMENTCHANNEL_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_PAYMENTCHANNEL_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: GET_PAYMENTCHANNEL_REQUEST, result: GET_PAYMENTCHANNEL_SUCCESS, token: payload.actionToken },
                paymentChannel: payload.paymentChannel
            });
        case GET_PAYMENTCHANNEL_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                paymentChannel: null,
                actionResult: { type: GET_PAYMENTCHANNEL_REQUEST, result: GET_PAYMENTCHANNEL_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case ADD_PAYMENTCHANNEL_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_PAYMENTCHANNEL_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_PAYMENTCHANNEL_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.paymentChannels = payload.department.paymentChannels;
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: ADD_PAYMENTCHANNEL_REQUEST, result: ADD_PAYMENTCHANNEL_SUCCESS, token: payload.actionToken },
                    department: _department
                });
            }
        case ADD_PAYMENTCHANNEL_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_PAYMENTCHANNEL_REQUEST, result: ADD_PAYMENTCHANNEL_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case SAVE_PAYMENTCHANNEL_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_PAYMENTCHANNEL_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_PAYMENTCHANNEL_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.paymentChannels = _department.paymentChannels.map(paymentChannel => { if (paymentChannel.msbId === payload.paymentChannel.msbId) return payload.paymentChannel; else return paymentChannel; });
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_PAYMENTCHANNEL_REQUEST, result: SAVE_PAYMENTCHANNEL_SUCCESS, token: payload.actionToken },
                    department: _department,
                    paymentChannel: payload.paymentChannel
                });
            }
        case SAVE_PAYMENTCHANNEL_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_PAYMENTCHANNEL_REQUEST, result: SAVE_PAYMENTCHANNEL_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DELETE_PAYMENTCHANNEL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_PAYMENTCHANNEL_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_PAYMENTCHANNEL_SUCCESS:
            {
                let _department = Object.assign({}, state.department);
                _department.paymentChannels = state.department.paymentChannels.filter(paymentChannel => paymentChannel.msbId !== payload.paymentChannelMsbId);
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: DELETE_PAYMENTCHANNEL_REQUEST, result: DELETE_PAYMENTCHANNEL_SUCCESS, token: payload.actionToken },
                    department: _department
                });
            }
        case DELETE_PAYMENTCHANNEL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_PAYMENTCHANNEL_REQUEST, result: DELETE_PAYMENTCHANNEL_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case GET_PAYMENTCHANNELNAMES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_PAYMENTCHANNELNAMES_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_PAYMENTCHANNELNAMES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_PAYMENTCHANNELNAMES_REQUEST, result: GET_PAYMENTCHANNELNAMES_SUCCESS, token: payload.actionToken },
                paymentChannelMap: payload.paymentChannelMap
            });
        case GET_PAYMENTCHANNELNAMES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_PAYMENTCHANNELNAMES_REQUEST, result: GET_PAYMENTCHANNELNAMES_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
    // PAYPAL CREDENTIAL INFORMATION
        case GET_PAYPALCREDINFO_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: GET_PAYPALCREDINFO_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_PAYPALCREDINFO_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: GET_PAYPALCREDINFO_REQUEST, result: GET_PAYPALCREDINFO_SUCCESS, token: payload.actionToken },
                payPalCredential: payload.payPalCredential
            });
        case GET_PAYPALCREDINFO_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                payPalCredential: null,
                actionResult: { type: GET_PAYPALCREDINFO_REQUEST, result: GET_PAYPALCREDINFO_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case ADD_PAYPALCREDINFO_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_PAYPALCREDINFO_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_PAYPALCREDINFO_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_PAYPALCREDINFO_REQUEST, result: ADD_PAYPALCREDINFO_SUCCESS, token: payload.actionToken },
                payPalCredential: payload.payPalCredential
            });
        case ADD_PAYPALCREDINFO_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_PAYPALCREDINFO_REQUEST, result: ADD_PAYPALCREDINFO_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case SAVE_PAYPALCREDINFO_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_PAYPALCREDINFO_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_PAYPALCREDINFO_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_PAYPALCREDINFO_REQUEST, result: SAVE_PAYPALCREDINFO_SUCCESS, token: payload.actionToken },
                payPalCredential: payload.payPalCredential
            });
        case SAVE_PAYPALCREDINFO_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_PAYPALCREDINFO_REQUEST, result: SAVE_PAYPALCREDINFO_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DELETE_PAYPALCREDINFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_PAYPALCREDINFO_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_PAYPALCREDINFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                payPalCredential: null,
                actionResult: { type: DELETE_PAYPALCREDINFO_REQUEST, result: DELETE_PAYPALCREDINFO_SUCCESS, token: payload.actionToken },
            });
        case DELETE_PAYPALCREDINFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_PAYPALCREDINFO_REQUEST, result: DELETE_PAYPALCREDINFO_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
    // AUTHORIZENET CREDENTIAL INFORMATION
        case GET_AUTHORIZENETCREDINFO_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: GET_AUTHORIZENETCREDINFO_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_AUTHORIZENETCREDINFO_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: GET_AUTHORIZENETCREDINFO_REQUEST, result: GET_AUTHORIZENETCREDINFO_SUCCESS, token: payload.actionToken },
                authorizeNetCredential: payload.authorizeNetCredential
            });
        case GET_AUTHORIZENETCREDINFO_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                authorizeNetCredential: null,
                actionResult: { type: GET_AUTHORIZENETCREDINFO_REQUEST, result: GET_AUTHORIZENETCREDINFO_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case ADD_AUTHORIZENETCREDINFO_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_AUTHORIZENETCREDINFO_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_AUTHORIZENETCREDINFO_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_AUTHORIZENETCREDINFO_REQUEST, result: ADD_AUTHORIZENETCREDINFO_SUCCESS, token: payload.actionToken },
                authorizeNetCredential: payload.authorizeNetCredential
            });
        case ADD_AUTHORIZENETCREDINFO_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_AUTHORIZENETCREDINFO_REQUEST, result: ADD_AUTHORIZENETCREDINFO_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case SAVE_AUTHORIZENETCREDINFO_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_AUTHORIZENETCREDINFO_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_AUTHORIZENETCREDINFO_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_AUTHORIZENETCREDINFO_REQUEST, result: SAVE_AUTHORIZENETCREDINFO_SUCCESS, token: payload.actionToken },
                authorizeNetCredential: payload.authorizeNetCredential
            });
        case SAVE_AUTHORIZENETCREDINFO_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_AUTHORIZENETCREDINFO_REQUEST, result: SAVE_AUTHORIZENETCREDINFO_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DELETE_AUTHORIZENETCREDINFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_AUTHORIZENETCREDINFO_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_AUTHORIZENETCREDINFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                authorizeNetCredential: null,
                actionResult: { type: DELETE_AUTHORIZENETCREDINFO_REQUEST, result: DELETE_AUTHORIZENETCREDINFO_SUCCESS, token: payload.actionToken },
            });
        case DELETE_AUTHORIZENETCREDINFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_AUTHORIZENETCREDINFO_REQUEST, result: DELETE_AUTHORIZENETCREDINFO_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        // Receipts
        case SAVE_DEPARTMENT_RECEIPT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: SAVE_DEPARTMENT_RECEIPT_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_DEPARTMENT_RECEIPT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: SAVE_DEPARTMENT_RECEIPT_REQUEST, result: SAVE_DEPARTMENT_RECEIPT_SUCCESS, token: payload.actionToken },
                receipt: payload.receipt
            });
        case SAVE_DEPARTMENT_RECEIPT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: SAVE_DEPARTMENT_RECEIPT_REQUEST, result: SAVE_DEPARTMENT_RECEIPT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case CLEAR_RECEIPT_TOAST_ACTION_REQUEST:
            return Object.assign({}, state, {
                clearToast: true
            });
        // ApprovalChecklist
        case SAVE_APPROVAL_CHECKLIST_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_APPROVAL_CHECKLIST_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_APPROVAL_CHECKLIST_SUCCESS:
            {
                const _client = Object.assign({}, state.client);
                _client.approvalChecklist = payload.approvalChecklist;

                if (_client.approvalChecklist.isApprovalSubmitted) {
                    _client.statusEnum = 2; //ClientStatusEnum.ApprovalPending;
                }

                return Object.assign({}, state, {
                    isSaving: false,
                    client: _client,
                    actionResult: { type: SAVE_APPROVAL_CHECKLIST_REQUEST, result: SAVE_APPROVAL_CHECKLIST_SUCCESS, token: payload.actionToken },
                });
            }
        case SAVE_APPROVAL_CHECKLIST_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_APPROVAL_CHECKLIST_REQUEST, result: SAVE_APPROVAL_CHECKLIST_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case RESET_STORE_REQUEST: 
            return Object.assign({}, state, {
                actionResult: { type: RESET_STORE_REQUEST, result: null, token: payload.actionToken },
            });
        case RESET_STORE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                errorMessage: '',
                currentPage: null,
                client: null,
                clientBanking: null,
                department: null,
                actionResult: { type: RESET_STORE_REQUEST, result: RESET_STORE_SUCCESS, token: payload.actionToken },
            });
        case SET_DEPARTMENT_RECEIPT_REQUEST: 
            return Object.assign({}, state, {
                receipt: null,
                actionResult: { type: SET_DEPARTMENT_RECEIPT_REQUEST, result: null, token: payload.actionToken },
            });
        case SET_DEPARTMENT_RECEIPT_SUCCESS: 
            return Object.assign({}, state, {
                receipt: payload.receipt,
                actionResult: { type: SET_DEPARTMENT_RECEIPT_REQUEST, result: SET_DEPARTMENT_RECEIPT_SUCCESS, token: payload.actionToken },
            });
        case SET_STORE_DEPARTMENT_REQUEST: 
            return Object.assign({}, state, {
                actionResult: { type: SET_STORE_DEPARTMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case SET_STORE_DEPARTMENT_SUCCESS: 
            return Object.assign({}, state, {
                department: payload.department,
                actionResult: { type: SET_STORE_DEPARTMENT_REQUEST, result: SET_STORE_DEPARTMENT_SUCCESS, token: payload.actionToken },
            });
        case RESET_STORE_DEPARTMENT_REQUEST: 
            return Object.assign({}, state, {
                actionResult: { type: RESET_STORE_DEPARTMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case RESET_STORE_DEPARTMENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                errorMessage: '',
                department: null,
                actionResult: { type: RESET_STORE_DEPARTMENT_REQUEST, result: RESET_STORE_DEPARTMENT_SUCCESS, token: payload.actionToken },
            });
        case SAVE_AGREEMENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_AGREEMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_AGREEMENT_SUCCESS:
            {
                const _client = Object.assign({}, state.client);
                _client.agreement = payload.agreement;

                return Object.assign({}, state, {
                    isSaving: false,
                    client: _client,
                    actionResult: {type: SAVE_AGREEMENT_REQUEST, result: SAVE_AGREEMENT_SUCCESS, token: payload.actionToken },
                });
            }
        case SAVE_AGREEMENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_AGREEMENT_REQUEST, result: SAVE_AGREEMENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        default:
            return state;
    }
}
