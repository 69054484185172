import { CellPlugin } from '@react-page/editor';
import OrderLineCart, {
    TITLE, SUBTITLE, ITEM_NAME_LABEL, HIDE_ITEM_NAME, ITEM_REFERENCE_LABEL, UNIT_PRICE_LABEL, QUANTITY_LABEL, HIDE_QUANTITY,
     ACTIONS_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING, FULL_STORY_MASK
} from './components/OrderLineCart';

type Data = {
    title: string,
    subTitle: string,
    itemNameLabel: string,
    hideItemName: boolean,
    itemReferenceLabel: string,
    hideItemReference: boolean,
    unitPriceLabel: string,
    quantityLabel: string,
    hideQuantity: boolean,
    actionsLabel: string,
    headerBackgroundColor: string,
    headerPadding: string,
    fullStoryMask: boolean,
    margin: string,
    padding: string,
}

const OrderLineCartPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <OrderLineCart
            title={data.title || TITLE}
            subTitle={data.subTitle || SUBTITLE}
            itemNameLabel={data.itemNameLabel || ITEM_NAME_LABEL}
            hideItemName={data.hideItemName}
            itemReferenceLabel={data.itemReferenceLabel || ITEM_REFERENCE_LABEL}
            hideItemReference={data.hideItemReference}
            unitPriceLabel={data.unitPriceLabel || UNIT_PRICE_LABEL}
            quantityLabel={data.quantityLabel || QUANTITY_LABEL}
            hideQuantity={data.hideQuantity}
            actionsLabel={data.actionsLabel || ACTIONS_LABEL}
            fullStoryMask={data.fullStoryMask}
            margin={data.margin || MARGIN}
            padding={data.padding || PADDING}
            headerBackgroundColor={data.headerBackgroundColor || HEADER_BACKGROUND_COLOR}
            headerPadding={data.headerPadding || HEADER_PADDING}
        />
    ),
    id: 'OrderLineCartPlugin',
    title: 'Order Line Cart',
    description: 'Adds order line to cart',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        title: {
                            type: 'string',
                            default: TITLE,
                        },
                        subTitle: {
                            type: 'string',
                            default: SUBTITLE,
                        },
                        itemNameLabel: {
                            type: 'string',
                            default: ITEM_NAME_LABEL,
                        },
                        hideItemName: {
                            type: 'boolean',
                            default: HIDE_ITEM_NAME,
                        },
                        itemReferenceLabel: {
                            type: 'string',
                            default: ITEM_REFERENCE_LABEL,
                        },
                        hideItemReference: {
                            type: 'boolean',
                            default: HIDE_ITEM_NAME,
                        },
                        unitPriceLabel: {
                            type: 'string',
                            default: UNIT_PRICE_LABEL,
                        },
                        quantityLabel: {
                            type: 'string',
                            default: QUANTITY_LABEL,
                        },
                        hideQuantity: {
                            type: 'boolean',
                            default: HIDE_QUANTITY,
                        },
                        actionsLabel: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        },
                        fullStoryMask:{
                            type: 'boolean',
                            default: FULL_STORY_MASK
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default OrderLineCartPlugin;