import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import 'react-dropdown/style.css';

const AdditionalDetails = ({ details }: any) => {

    const [metaDataFields, setMetaDataFields] = useState<any>('');

    useEffect(() => {
        if (details) {
            const supplementalFields = details?.orderSummary?.orderTransactions[0]?.clientMetadata || details?.clientMetadata || '';
            const objectKeys = Object.fromEntries(Object.entries(supplementalFields).filter(([key]) => key.includes('cmd_')));
            setMetaDataFields(objectKeys);
        }
    }, [details]);

    return (
        <div className="container-additional-details d-print-none">
            <div className="card-title">
                <h2>Additional Details</h2>
            </div>
            {metaDataFields != '' ?
                <div className='additionalTransactionDetails'>
                    {Object.entries(metaDataFields).map(
                        ([index, value]: any) => {
                            return (
                                    <div style={{display:'flex'}}>
                                        <h3>{_.upperFirst(_.lowerCase(index.replace('cmd_', '')))}:</h3>
                                        <span style={{marginLeft: '12px'}}>{value}</span>
                                    </div>
                          
                            )
                        }
                    )}
                </div>
                : <p className="mb-0">Currently there are no records to display.</p>}
        </div>
    )
}
export default AdditionalDetails