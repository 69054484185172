import axios from 'axios';
import { Dispatch } from 'react';
import store from "../store";
import { UserTypeEnum } from '../../models/User';
import { Role } from '../../models/Role';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE';

export const SAVE_ROLE_REQUEST = 'SAVE_ROLE_REQUEST';
export const SAVE_ROLE_SUCCESS = 'SAVE_ROLE_SUCCESS';
export const SAVE_ROLE_FAILURE = 'SAVE_ROLE_FAILURE';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const GET_MODULE_PERMISSIONS_REQUEST = 'GET_MODULE_PERMISSIONS_REQUEST';
export const GET_MODULE_PERMISSIONS_SUCCESS = 'GET_MODULE_PERMISSIONS_SUCCESS';
export const GET_MODULE_PERMISSIONS_FAILURE = 'GET_MODULE_PERMISSIONS_FAILURE';


export const SET_ROLES_DETAILS = 'SET_ROLES_DETAILS';
export const CANCEL_STEP = 'CANCEL_STEP';

export const RESET_ROLES_STORE_REQUEST = 'RESET_ROLES_STORE_REQUEST';
export const RESET_ROLES_STORE_SUCCESS = 'RESET_ROLES_STORE_SUCCESS'

export const getRolesAction = (userTypeEnum: UserTypeEnum, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_ROLES_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url =  `/MSB_User/api/v1/Roles/AllByType/${userTypeEnum}`;

    axios.get(_url)
        .then(function (response) {
            let roles = response.data;
            dispatch({
                type: GET_ROLES_SUCCESS,
                payload: {
                    roles,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_ROLES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const getRoleAction = (msbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_ROLE_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url =  `/MSB_User/api/v1/Roles/${msbId}`;

    axios.get(_url)
        .then(function (response) {
            let role = response.data;
            dispatch({
                type: GET_ROLE_SUCCESS,
                payload: {
                    role,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_ROLE_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const saveRole = (role: Role, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_ROLE_REQUEST,
        payload: {
            actionToken
        }
    });

    if (role.msbId === undefined) {
        let _url = "/MSB_User/api/v1/Roles";

        axios.post(_url, role)
            .then(function (response) {
                let role = response.data;
                dispatch({
                    type: SAVE_ROLE_SUCCESS,
                    payload: {
                        role,
                        actionToken
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_ROLE_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            })
            .then(function () {
            });
    } else {
        let _url =  `/MSB_User/api/v1/Roles/${role.msbId}`; 

        axios.put(_url, role)
            .then(function (response) {
                let role = response.data;
                dispatch({
                    type: SAVE_ROLE_SUCCESS,
                    payload: {
                        role,
                        actionToken
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_ROLE_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            })
            .then(function () {
            });
    }
};

export const deleteAction = (msbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_ROLE_REQUEST,
        payload: {
            msbId,
            actionToken
        }
    });

    let _url =  `/MSB_User/api/v1/Roles/${msbId}`;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_ROLE_SUCCESS,
                payload: {
                    msbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_ROLE_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const getModulePermissions = (userTypeEnum: UserTypeEnum, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_MODULE_PERMISSIONS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url =  `/MSB_User/api/v1/ModulePermissions/AllByType/${userTypeEnum}`;

    axios.get(_url)
        .then(function (response) {
            let modulePermissions = response.data;
            dispatch({
                type: GET_MODULE_PERMISSIONS_SUCCESS,
                payload: {
                    modulePermissions,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_MODULE_PERMISSIONS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
}



export const cancelAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CANCEL_STEP,
    });
};


export const setRolesAction = (roles: Role) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: SET_ROLES_DETAILS,
        payload: {
            roles
        }
    });

};

export const resetRolesStoreAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: RESET_ROLES_STORE_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: RESET_ROLES_STORE_SUCCESS,
        payload: {
            actionToken
        }
    });
};


