import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Row, Col, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { getTransactionsAction } from '../../../redux/actions/payments/paymentTransactions';
import { TransactionTypeEnum, SearchList } from '../../../models/Payment';
import { IAppState } from '../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";

// @ts-ignore
import DualListBox from 'react-dual-listbox';
import FormHeader from '../../clients/components/forms/FormHeader';
import moment from 'moment';
import { Client, ClientName, DepartmentName } from "../../../models/Client";
import { getClientsAction } from "../../../redux/actions/clients/clients";
import { CurrencyInput } from "../../../components/currency/CurrencyInput";
import { User, UserClient, UserTypeEnum } from "../../../models/User";

interface ISearch {
    defaultStartDate: string;
    defaultEndDate: string;
    searchReady: boolean;
    validateStartDate?: any;
    currentUser: User;
    clients: Array<Client>;
    clientNames: Array<ClientName>;
    departmentNames: Array<DepartmentName>;
    transactionType?: any;
}

const Search = ({ defaultStartDate, defaultEndDate, validateStartDate, searchReady, currentUser, clients, clientNames, departmentNames, transactionType }: ISearch) => {
    const actionToken = "Search";
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [searchList, setSearchList] = useState<SearchList>(Object.assign(new SearchList(), { startDate: moment(defaultStartDate).format('YYYY-MM-DD'), endDate: moment(defaultEndDate).format('YYYY-MM-DD') }));
    const [trigger, setTrigger] = useState(false);
    const [executeDynamicQuery, setExecuteDynamicQuery] = useState<boolean>(false);

    const search = () => {
        let _searchList = new SearchList();

        Object.entries(searchList).map(([key, value]) => {
            if (!["startDate", "endDate", "startTime", "endTime", "startAmount", "endAmount", "customSelectComponent", "departmentIds"].some(k => key === k)) {
                (_searchList[key as keyof SearchList] as keyof typeof key) = value as keyof typeof key;
            }
        })

        if (searchList.startAmount || searchList.endAmount) {
            _searchList.totalAmount = ((searchList.startAmount) ? `${searchList.startAmount}` : "-9999999.99") + ((searchList.endAmount) ? `<->${searchList.endAmount}` : "");
        }

        if (searchList.departmentIds && searchList.departmentIds?.length > 0) {
            _searchList.departmentId = searchList.departmentIds.map((id) => `${id}`).join("|");
        }

        _searchList.createdAt = `${moment(searchList.startDate + ((searchList.startTime) ? "T" + searchList.startTime + ":00.000" : "T00:00:00.000")).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}<->${moment(searchList.endDate + ((searchList.endTime) ? "T" + searchList.endTime + ":59.999" : "T23:59:59.999")).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}`;
        
        if (executeDynamicQuery) {
            dispatch(getTransactionsAction(1, 10, _searchList, actionToken, currentUser, transactionType));
        }
    }

    useEffect(() => {
        if (!clients) {
            dispatch(getClientsAction(1, 1000));
        }
    }, [clients]);

    useEffect(() => {
        search();
    }, [trigger, executeDynamicQuery]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (showModal) setTimeout(() => setShowModal(false), 100);
        setTrigger(!trigger);
        setExecuteDynamicQuery(true);
    };

    const clearForms = (event: any) => {
        event.preventDefault();
        setSearchList(Object.assign(new SearchList(), { startDate: moment(defaultStartDate).format('YYYY-MM-DD'), endDate: moment(defaultEndDate).format('YYYY-MM-DD') }));
        setTrigger(!trigger);
    }

    const onHideModal = () => {
        setTimeout(() => setShowModal(false), 100);
    }

    return (
        <>
            <Form className="table-search advanced-search" onSubmit={handleSubmit}>
                <Row>
                    <Col xl={3} lg={6} md={6} sm={12}>
                        <Form.Group controlId="startDate">
                            <Form.Label>Start date</Form.Label>
                            <Form.Control type="date" name="startDate" max={moment(defaultEndDate).format('YYYY-MM-DD')} value={searchList.startDate} onChange={(e: any) => (validateStartDate(e.target.value)) ? setSearchList({ ...searchList, startDate: e.target.value }) : null} />
                        </Form.Group>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12}>
                        <Form.Group controlId="endDate">
                            <Form.Label>End date</Form.Label>
                            <Form.Control type="date" name="endDate" min={moment(searchList.startDate).format('YYYY-MM-DD')} max={moment(defaultEndDate).format('YYYY-MM-DD')} value={searchList.endDate} onChange={(e: any) => setSearchList({ ...searchList, endDate: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12}>
                        <Form.Group controlId="transactionId">
                            <Form.Label>Transaction ID</Form.Label>
                            <Form.Control type="input" name="transactionId" placeholder="Enter transaction ID" value={searchList.orderIdentifier} onChange={(e) => setSearchList({ ...searchList, orderIdentifier: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col xl={3} lg={12} md={12} sm={12}>
                        <ButtonToolbar>
                            <Button variant="link" onClick={() => setShowModal(true)}>Advanced Search</Button>
                            <Button variant="outline-secondary" onClick={clearForms}>Reset</Button>
                            <Button type="submit">Search</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>
            </Form>

            <Modal show={showModal} onHide={onHideModal} size="lg" className="advanced-search-modal">
                <Modal.Header closeButton />
                <Modal.Body>

                    <FormHeader title="Advanced Search" description="Please enter the details below to narrow your search results" />

                    <Form onSubmit={handleSubmit} className="advanced-search-form">
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId="startDate">
                                    <Form.Label>Start date</Form.Label>
                                    <Form.Control type="date" name="startDate" max={moment(defaultEndDate).format('YYYY-MM-DD')} value={searchList.startDate} onChange={(e: any) => (validateStartDate(e.target.value)) ? setSearchList({ ...searchList, startDate: e.target.value }) : null} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="endDate">
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control type="date" name="endDate" min={moment(searchList.startDate).format('YYYY-MM-DD')} max={moment(defaultEndDate).format('YYYY-MM-DD')} value={searchList.endDate} onChange={(e: any) => setSearchList({ ...searchList, endDate: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId="startTime">
                                    <Form.Label>Start time</Form.Label>
                                    <Form.Control type="time" name="startTime" value={searchList.startTime} onChange={(e) => setSearchList({ ...searchList, startTime: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="endTime">
                                    <Form.Label>End time</Form.Label>
                                    <Form.Control type="time" name="endTime" value={searchList.endTime} onChange={(e) => setSearchList({ ...searchList, endTime: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId="startAmount">
                                    <Form.Label>Start amount</Form.Label>
                                    <CurrencyInput
                                        className="form-control"
                                        placeholder="$ 0.00"
                                        maxLength={10}
                                        decimalsLimit={2}
                                        prefix="$ "
                                        value={searchList.startAmount}
                                        onValueChange={(value, name) => setSearchList({ ...searchList, ["startAmount" as keyof SearchList]: value })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="endAmount">
                                    <Form.Label>End amount</Form.Label>
                                    <CurrencyInput
                                        className="form-control"
                                        placeholder="$ 0.00"
                                        maxLength={10}
                                        decimalsLimit={2}
                                        prefix="$ "
                                        value={searchList.endAmount}
                                        onValueChange={(value, name) => setSearchList({ ...searchList, ["endAmount" as keyof SearchList]: value })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId="transactionId">
                                    <Form.Label>Transaction ID</Form.Label>
                                    <Form.Control type="input" name="transactionId" value={searchList.orderIdentifier} onChange={(e) => setSearchList({ ...searchList, orderIdentifier: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="itemReference">
                                    <Form.Label>Item reference</Form.Label>
                                    <Form.Control type="input" pattern="([a-zA-z0-9\s-])*$" value={searchList.itemReferenceNumber} onChange={(e) => setSearchList({ ...searchList, itemReferenceNumber: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId="paymentType">
                                    <Form.Label>Payment type</Form.Label>
                                    <Form.Control as="select" value={searchList.paymentType} onChange={(e: any) => setSearchList({ ...searchList, paymentType: e.target.value })}>
                                        <option key={0} value="" >Select...</option>
                                        <option value={'CreditCard'}>Credit Card Payment</option>
                                        <option value={'eCheck'}>eCheck Payments</option>
                                    </Form.Control >
                                    <Form.Control.Feedback type="invalid">Payment type required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="accountNumber">
                                    <Form.Label>Last 4 of credit card or account number</Form.Label>
                                    <Form.Control type="input" maxLength={4} pattern="^([0-9])*$" value={searchList.lastFourOnCard} onChange={(e) => setSearchList({ ...searchList, lastFourOnCard: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId="initiatedBy">
                                    <Form.Label>Initiated by</Form.Label>
                                    <Form.Control type="input" pattern="^([a-zA-z\s'-])*$" value={searchList.initiatedBy} onChange={(e) => setSearchList({ ...searchList, initiatedBy: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="customerName">
                                    <Form.Label>Customer name</Form.Label>
                                    <Form.Control type="input" pattern="^([a-zA-z\s'-])*$" value={searchList.nameOnCard} onChange={(e) => setSearchList({ ...searchList, nameOnCard: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {
                                searchReady && clients &&
                                <DualListBox
                                    selected={searchList.departmentIds}
                                    options={
                                        currentUser?.userClients.map(uc => {
                                            let client = clients.find(c => c.msbId === uc.clientMSBId);
                                            return {
                                                label: client?.businessName,
                                                options: (currentUser.userTypeEnum == UserTypeEnum.Client) ? currentUser?.userDepartments.map(ud => {
                                                    let department = client?.departments.find(d => d.msbId === ud.departmentMSBId);
                                                    return {
                                                        label: department?.name, value: department?.msbId
                                                    }
                                                }) : client?.departments.map(d => { return { label: d.name, value: d.msbId } })
                                            }
                                        })
                                    }
                                    onChange={(ids: any) => setSearchList({ ...searchList, departmentIds: ids })}
                                    className="transfer"
                                    showHeaderLabels={true}
                                    lang={{
                                        availableHeader: 'All departments',
                                        selectedHeader: 'Selected departments'
                                    }}
                                    icons={{
                                        moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                                        moveAllLeft: [
                                            <FontAwesomeIcon icon={faChevronLeft} key={0} />,
                                            <FontAwesomeIcon icon={faChevronLeft} key={1} />,
                                        ],
                                        moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                                        moveAllRight: [
                                            <FontAwesomeIcon icon={faChevronRight} key={0} />,
                                            <FontAwesomeIcon icon={faChevronRight} key={1} />,
                                        ],
                                    }}
                                />
                            }
                        </Row>
                        <div className="advanced-search-footer">
                            <Button type="submit">
                                Search
                            </Button>
                            <Button form='navientUserSubmit' className="navientUserFooterCancel" variant="link" onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>
                        </div>
                    </Form >
                </Modal.Body>
            </Modal>

        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        clients: state.clients.currentPage?.data,
        currentUser: state.auth.currentUser,
        clientNames: state.clients.clientNames,
        departmentNames: state.clients.departmentNames,
    };
};

export default connect(mapStateToProps)(Search);
