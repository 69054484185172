import React, { useEffect, useState } from "react";
import { Button, Dropdown } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import Table from '../../../../components/Table';
import {
    SAVE_DEPARTMENT_CONTACT_REQUEST, SAVE_DEPARTMENT_CONTACT_SUCCESS, SAVE_DEPARTMENT_CONTACT_FAILURE,
    DELETE_DEPARTMENT_CONTACT_REQUEST, DELETE_DEPARTMENT_CONTACT_SUCCESS, DELETE_DEPARTMENT_CONTACT_FAILURE
}
    from '../../../../redux/actions/clients/contacts';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { Routes } from '../../../../routes'
import { Redirect } from 'react-router-dom';
import { IAppState, IActionResult } from '../../../../redux/storeTypes';
import { Department, ContactTypeEnum, Client, Contact, Receipt, ReceiptTypeEnum } from "../../../../models/Client";
import FormHeader from '../forms/FormHeader';
import PermissionModule, { Permissions } from '../../../../components/usePermissions';
import { setDepartmentReceiptAction } from "../../../../redux/actions/clients/departments";

interface IDepartmentReceiptsProps {
    isSaving: boolean,
    isFetching: boolean,
    department: Department,
    client: Client,
    actionResult: IActionResult
}

const DepartmentReceiptsSection = ({ isSaving, isFetching, client, department, actionResult }: IDepartmentReceiptsProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<boolean>(false);

    let actionToken = "DepartmentReceiptsSection";

    useEffect(() => {
    }, [department, client]);

    useEffect(() => {
        /*
        if (actionResult && actionResult.result) {
            if (actionResult.type === SAVE_DEPARTMENT_CONTACT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_DEPARTMENT_CONTACT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Department contact has been successfully updated."));
                } else if (actionResult.result === SAVE_DEPARTMENT_CONTACT_FAILURE) {
                    dispatch(sendErrorToastAction("Department contact could not be updated."));
                }
            }

            if (actionResult.type === DELETE_DEPARTMENT_CONTACT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === DELETE_DEPARTMENT_CONTACT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Department contact has been successfully deleted."));
                } else if (actionResult.result === DELETE_DEPARTMENT_CONTACT_FAILURE) {
                    dispatch(sendErrorToastAction("Department contact could not be deleted."));
                }
            }
        }
        */
    }, [actionResult]);

    const actionsFormatter = (cell: any, receipt: Receipt) => {
        return (
            <Dropdown >
                <Dropdown.Toggle variant="link" id="manage-contact-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item onSelect={() => { 
                            dispatch(setDepartmentReceiptAction(receipt, actionToken));
                            setRedirect(true);
                         }}>
                        Edit Receipt
                        </Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }


    const receiptTypeFormatter = (cell: any, receipt: Receipt) => {
        if (receipt.receiptType === ReceiptTypeEnum.CardNotPresent) {
            return "Card Not Present";
        } else if (receipt.receiptType === ReceiptTypeEnum.CardPresent) {
            return "Card Present";
        } else if (receipt.receiptType === ReceiptTypeEnum.eCheck) {
            return "eCheck";
        } else if (receipt.receiptType === ReceiptTypeEnum.CardNotPresentReminder) {
            return "Card Not Present - Reminder";
        } else if (receipt.receiptType === ReceiptTypeEnum.eCheckReminder) {
            return "eCheck - Reminder";
        } else if (receipt.receiptType === ReceiptTypeEnum.PaymentDeclined) {
            return "Payment Declined";
        } else if (receipt.receiptType === ReceiptTypeEnum.PaymentFailed) {
            return "Payment Failed";
        } else if (receipt.receiptType === ReceiptTypeEnum.Notification) {
            return "Notification";
        } else if (receipt.receiptType === ReceiptTypeEnum.Authorization) {
            return "Authorization";
        } else if (receipt.receiptType === ReceiptTypeEnum.Confirmation) {
            return "Confirmation";
        } else {
            return "";
        }
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        }, {
            dataField: 'name',
            text: 'Name',
            hidden: true,
            editable: false
        }, {
            dataField: 'receiptType',
            text: 'Receipt type',
            editable: false,
            formatter: receiptTypeFormatter
        }, {
            dataField: 'msbId',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
        }
    ];


    if (redirect) {
        return <Redirect push to={Routes.ReceiptEditor.path} />;
    } else {
        return (
            <>
                <FormHeader title="Department Receipts" description="Manage the department's receipts." />

                <div className="manage-card">
                  <PageSectionContainer title="Manage Receipts">
                      <Table 
                        keyField="msbId" 
                        columns={columns} 
                        data={department.receipts} 
                      />
                  </PageSectionContainer>
                </div>

                <div className="manage-footer">
                  <Button type="button" variant="link" onClick={() => setRedirect(true)} style={{ float: "right" }}>Cancel</Button>
                </div>

            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.clients.isSaving,
        isFetching: state.clients.isFetching,
        client: state.clients.client,
        department: state.clients.department,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(DepartmentReceiptsSection);