import React from 'react';
import 'react-dropdown/style.css';
import { IAppState } from '../../../redux/storeTypes';
import { connect } from "react-redux";
import { ProcessedOrder } from '../../../models/Payment';
import {  Container} from 'react-bootstrap';
import {  TransactionTypeEnum } from '../../../models/Payment';
import PageHeader from '../../../components/layout/PageHeader';
import { Crumb } from "../../../models/Crumb";
import Receipt from "../../receipts/Receipt";
interface IPaymentReceiptProps {
    processedOrder: ProcessedOrder,
}

const PaymentReceipt = ({ processedOrder }: IPaymentReceiptProps) => {

    const receiptDetails = processedOrder?.processorData?.receipt;

    const receiptTransactionTypeAction = TransactionTypeEnum[
        (receiptDetails?.transactionType === TransactionTypeEnum.Refund) ||
            (receiptDetails?.transactionType === TransactionTypeEnum.Return) ? TransactionTypeEnum.Refund
            :(receiptDetails?.transactionType === TransactionTypeEnum.Chargeback) ? TransactionTypeEnum.Chargeback
            :(receiptDetails?.transactionType === TransactionTypeEnum.ChargebackReversal) ? TransactionTypeEnum.ChargebackReversal
            : (receiptDetails?.processorType === "VantivTriPos") ? TransactionTypeEnum.Void
                : receiptDetails?.transactionType!
    ]

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb(`${receiptTransactionTypeAction} Receipt`, ''));

    return (
        <>
            <PageHeader title={`${receiptTransactionTypeAction} Receipt`} crumbs={crumbs} />
            <Container fluid>
                <Receipt orderedPage={receiptTransactionTypeAction} isPaymentReceiptComponent={true} />
            </Container>
        </>
    )
};

const mapStateToProps = (state: IAppState) => {
    return {
        processedOrder: state.orderManagement.processedOrder,
    };
};

export default connect(mapStateToProps)(PaymentReceipt);
