import { GilaSettings } from '../../models/GilaSettings';
import { Dispatch } from 'react';
import axios from 'axios';
import store from "../../redux/store";
import { GilaBankAccount } from "../../models/GilaSettings";

export const GET_GILA_SETTINGS_REQUEST = 'GET_GILA_SETTINGS_REQUEST';
export const GET_GILA_SETTINGS_SUCCESS = 'GET_GILA_SETTINGS_SUCCESS';
export const GET_GILA_SETTINGS_FAILURE = 'GET_GILA_SETTINGS_FAILURE';

export const SAVE_GILA_SETTINGS_REQUEST = 'SAVE_GILA_SETTINGS_REQUEST';
export const SAVE_GILA_SETTINGS_SUCCESS = 'SAVE_GILA_SETTINGS_SUCCESS';
export const SAVE_GILA_SETTINGS_FAILURE = 'SAVE_GILA_SETTINGS_FAILURE';

export const CLEAR_GILA_SETTINGS_ACTION_RESULT = 'CLEAR_GILA_SETTINGS_ACTION_RESULT';

export const getGilaSettings = (actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_GILA_SETTINGS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/GilaSettings";

    axios.get(_url)
        .then(function (response) {
            let gilaSettings = response.data;

            if (gilaSettings.depositBankAccount === null) {
                let depositBankAccount = new GilaBankAccount();
                depositBankAccount.bankAccountNumber = "0";
                depositBankAccount.bankRoutingNumber = "0";
                gilaSettings.depositBankAccount = depositBankAccount;
            }

            if (gilaSettings.chargebackBankAccount === null) {
                let chargebackBankAccount = new GilaBankAccount();
                chargebackBankAccount.bankAccountNumber = "0";
                chargebackBankAccount.bankRoutingNumber = "0";
                gilaSettings.chargebackBankAccount = chargebackBankAccount;
            }

            dispatch({
                type: GET_GILA_SETTINGS_SUCCESS,
                payload: {
                    gilaSettings,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_GILA_SETTINGS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const clearActionResult = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_GILA_SETTINGS_ACTION_RESULT,
        payload: {
        }
    });
}


export const saveGilaSettings = (gilaSettings: GilaSettings, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_GILA_SETTINGS_REQUEST,
        payload: {
            actionToken
        }
    });
    
    let _url = "/MSB_Client/api/v1/GilaSettings";

    axios.put(_url, gilaSettings)
        .then(function (response) {
            let gilaSettings = response.data;
            dispatch({
                type: SAVE_GILA_SETTINGS_SUCCESS,
                payload: {
                    gilaSettings,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_GILA_SETTINGS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 

};
