import React from 'react';

interface StateDropdownObj {
    disabled?: boolean,
    required?: boolean,
    id?: string,
    defaultValue?: string,
    value?: any,
    onChange?: any,
    country?: string;
}

const StateDropdown = ({ disabled, required, id = 'state', defaultValue = '', value, onChange, country = 'United States' }: StateDropdownObj) => {

    const usaStates = [
        { value: 'AL', label: 'Alabama' }, { value: 'AK', label: 'Alaska' }, { value: 'AS', label: 'American Samoa'}, { value: 'AZ', label: 'Arizona' }, { value: 'AR', label: 'Arkansas' }, { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' }, { value: 'CT', label: 'Connecticut' }, { value: 'DE', label: 'Delaware' }, { value: 'DC', label: 'District Of Columbia' }, { value: 'FM', label: 'Federated States of Micronesia' }, { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' }, { value: 'GU', label: 'Guam' }, { value: 'HI', label: 'Hawaii' }, { value: 'ID', label: 'Idaho' }, { value: 'IL', label: 'Illinois' }, { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' }, { value: 'KS', label: 'Kansas' }, { value: 'KY', label: 'Kentucky' }, { value: 'LA', label: 'Louisiana' }, { value: 'ME', label: 'Maine' }, { value: 'MH', label: 'Marshall Islands' },
        { value: 'MD', label: 'Maryland' }, { value: 'MA', label: 'Massachusetts' }, { value: 'MI', label: 'Michigan' }, { value: 'MN', label: 'Minnesota' }, { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' }, { value: 'MT', label: 'Montana' }, { value: 'NE', label: 'Nebraska' }, { value: 'NV', label: 'Nevada' }, { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' }, { value: 'NM', label: 'New Mexico' }, { value: 'NY', label: 'New York' }, { value: 'NC', label: 'North Carolina' }, { value: 'ND', label: 'North Dakota' }, { value: 'MP', label: 'Northern Mariana Islands' },
        { value: 'OH', label: 'Ohio' }, { value: 'OK', label: 'Oklahoma' }, { value: 'OR', label: 'Oregon' }, { value: 'PW', label: 'Palau' }, { value: 'PA', label: 'Pennsylvania' }, { value: 'PR', label: 'Puerto Rico' }, { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' }, { value: 'SD', label: 'South Dakota' }, { value: 'TN', label: 'Tennessee' }, { value: 'TX', label: 'Texas' }, { value: 'VI', label: 'United States Virgin Islands' }, { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' }, { value: 'VA', label: 'Virginia' }, { value: 'WA', label: 'Washington' }, { value: 'WV', label: 'West Virginia' }, { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' }
    ];
    const mexicoStates = [
        { value: 'AG', label: 'Aguascalientes' }, { value: 'BC', label: 'Baja California' }, { value: 'BS', label: 'Baja California Sur' }, { value: 'CH', label: 'Chihuahua' }, { value: 'CL', label: 'Colima' },
        { value: 'CM', label: 'Campeche' }, { value: 'CO', label: 'Coahuila' }, { value: 'CS', label: 'Chiapas' }, { value: 'DF', label: 'Federal District' }, { value: 'DG', label: 'Durango' },
        { value: 'GR', label: 'Guerrero' }, { value: 'GT', label: 'Guanajuato' }, { value: 'HG', label: 'Hidalgo' }, { value: 'JA', label: 'Jalisco' }, { value: 'ME', label: 'México State' },
        { value: 'MO', label: 'Michoacán' }, { value: 'NA', label: 'Nayarit' }, { value: 'NL', label: 'Nuevo León' }, { value: 'OA', label: 'Oaxaca' }, { value: 'PB', label: 'Puebla' },
        { value: 'QE', label: 'Querétaro' }, { value: 'QR', label: 'Quintana Roo' }, { value: 'SI', label: 'Sinaloa' }, { value: 'SL', label: 'San Luis Potosí' }, { value: 'SO', label: 'Sonora' },
        { value: 'TB', label: 'Tabasco' }, { value: 'TL', label: 'Tlaxcala' }, { value: 'TM', label: 'Tamaulipas' }, { value: 'VE', label: 'Veracruz' }, { value: 'YU', label: 'Yucatán' },
        { value: 'ZA', label: 'Zacatecas' }
    ];
    const canadaStates = [
        { value: 'AB', label: 'Alberta' }, { value: 'BC', label: 'British Columbia' }, { value: 'MB', label: 'Manitoba' }, { value: 'NB', label: 'New Brunswick' }, { value: 'NL', label: 'Newfoundland and Labrador' },
        { value: 'NT', label: 'Northwest Territories' }, { value: 'NS', label: 'Nova Scotia' }, { value: 'NU', label: 'Nunavut' }, { value: 'ON', label: 'Ontario' }, { value: 'PE', label: 'Prince Edward Island' },
        { value: 'QC', label: 'Quebec' }, { value: 'SK', label: 'Saskatchewan' }, { value: 'YT', label: 'Yukon' }
    ]

    const selectedStates = country === 'United States' ? usaStates : country === 'Mexico' ? mexicoStates : country === 'Canada' ? canadaStates : usaStates

    return (
        <select id={id} className="form-control" required={required} disabled={disabled} value={value} defaultValue={defaultValue} onChange={onChange}>
            {<option value="">Select</option>}
            {selectedStates.map((state, index): any => (
                <option value={state.value}>{state.label}</option>
            ))}
        </select>
    );
}
export { StateDropdown }