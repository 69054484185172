import { Roles } from "./Role"; 
export class User  {
    msbId?: string;
    userTypeEnum: UserTypeEnum = UserTypeEnum.Unknown;
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    title: string = "";
    phoneNumber: string = "";
    preferences: any;
    userClients: Array<UserClient> = new Array<UserClient>();
    userDepartments: Array<UserDepartment> = new Array<UserDepartment>();
    userRoles?: Array<Roles> = new Array<Roles>();
    createdAt?: string = "";
    updatedAt?: string = "";
    avatar?: Avatar;
}

export class Avatar {
    imageBase64String: string ="";
}

export enum UserTypeEnum
{
    Unknown     = 0,
    Navient     = 1,
    Client      = 2,
    Constituent = 3
}

export class UserClient {
    clientMSBId: string;
    constructor(message: string) {
      this.clientMSBId = message;
    }
}

export class UserDepartment {
    departmentMSBId: string;
    constructor(message: string) {
      this.departmentMSBId = message;
    }
}

export class UserAuthRequest {
    email: string = "";
    objectId: string = "";
}

export class LabelValue {
    value: string = "";
    label: string = ""

    constructor(label:string, value: string) {
        this.label = label;
        this.value = value;
    }
}