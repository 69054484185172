import { CellPlugin } from '@react-page/editor';

import PaymentReference, { ADD_ITEM_LABEL, AMOUNT_LABEL, MARGIN, PADDING, 
    PAYMENT_DESCRIPTION_LABEL, PAYMENT_DESCRIPTION_PLACEHOLDER, PAYMENT_REFERENCE_LABEL, 
    PAYMENT_REFERENCE_PLACEHOLDER, SUBMIT_BUTTON_BACKGROUND_COLOR, SUBMIT_BUTTON_FONT_COLOR,
     SUBMIT_BUTTON_FONT_SIZE, SUBMIT_BUTTON_FONT_STYLE, SUBMIT_BUTTON_FONT_WEIGHT, FULL_STORY_MASK } from './components/PaymentReference';

// use a type here, not an interface
type Data = {
  title: string,
  paymentDescription: string,
  paymentDescriptionPlaceholder: string,
  showDescription: boolean,
  descriptionRequired: boolean,
  paymentReference: string,
  paymentReferencePlaceholder: string,
  amount: string,
  addItem: string,
  margin: string,
  padding: string,
  submitButtonBackgroundColor: string,
  submitButtonFontColor: string,
  submitButtonFontSize: string,
  submitButtonFontStyle: string,
  submitButtonFontWeight: string,
  fullStoryMask: boolean,
}

const PaymentReferencePlugin: CellPlugin<Data> = {
  Renderer: ({data}) => (
   <PaymentReference
      paymentDescriptionLabel={data.paymentDescription}
      paymentDescriptionPlaceholder={data.paymentDescriptionPlaceholder}
      showDescription={data.showDescription}
      descriptionRequired={data.descriptionRequired}
      paymentReferenceLabel={data.paymentReference}
      paymentReferencePlaceholder={data.paymentReferencePlaceholder}
      amountLabel={data.amount}
      addItemLabel={data.addItem}
      margin={data.margin}
      padding={data.padding}
      submitButtonBackgroundColor = {data.submitButtonBackgroundColor}
      submitButtonFontColor = {data.submitButtonFontColor}
      submitButtonFontSize = {data.submitButtonFontSize}
      submitButtonFontStyle = {data.submitButtonFontStyle}
      submitButtonFontWeight = {data.submitButtonFontWeight}
      fullStoryMask={data.fullStoryMask}
      />
  ),
  id: 'paymentReferencePlugin',
  title: 'Payment Reference',
  description: 'Collects the payment reference.',
  version: 1,
  controls: [
    {
      title: 'Labels',
      controls: {
        type: 'autoform',
        schema: {
            properties: {
                paymentDescription: {
                    type: 'string',
                    default: PAYMENT_DESCRIPTION_LABEL,
                },
                paymentDescriptionPlaceholder: {
                    type: 'string',
                    default: PAYMENT_DESCRIPTION_PLACEHOLDER,
                },
                showDescription: {
                    type: 'boolean',
                    default: false,
                },
                descriptionRequired: {
                    type: 'boolean',
                    default: false,
                },
                paymentReference: {
                  type: 'string',
                  default: PAYMENT_REFERENCE_LABEL,
                },
                paymentReferencePlaceholder: {
                  type: 'string',
                  default: PAYMENT_REFERENCE_PLACEHOLDER,
                },
                amount: {
                  type: 'string',
                  default: AMOUNT_LABEL,
                },
                addItem: {
                  type: 'string',
                  default: ADD_ITEM_LABEL,
                },
                fullStoryMask:{
                    type: 'boolean',
                    default: FULL_STORY_MASK
                },
          },
          required: ['paymentReference', 'amount', 'addItem'],
        },
      },
    },
    {
      title: 'Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            margin: {
              type: 'string',
              default: MARGIN,
            },
            padding: {
              type: 'string',
              default: PADDING,
            },
            submitButtonBackgroundColor: {
              type: 'string',
              default: SUBMIT_BUTTON_BACKGROUND_COLOR,
            },
            submitButtonFontColor: {
              type: 'string',
              default: SUBMIT_BUTTON_FONT_COLOR,
            },
            submitButtonFontSize: {
              type: 'string',
              default: SUBMIT_BUTTON_FONT_SIZE,
            },
            submitButtonFontStyle: {
              type: 'string',
              default: SUBMIT_BUTTON_FONT_STYLE,
            },
            submitButtonFontWeight: {
              type: 'string',
              default: SUBMIT_BUTTON_FONT_WEIGHT,
            },
          },
          required: [],
        },
      },
    },
  ],
};

export default PaymentReferencePlugin;