import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Row, Col, Card } from 'react-bootstrap';

import PageHeader from '../../components/layout/PageHeader';
import GilaBankAccounts from './components/GilaBankAccounts';
import ChainCode from './components/ChainCode';
import { getGilaSettings } from '../../redux/actions/gilaSettings';
import { IActionResult, IAppState } from '../../redux/storeTypes';
import { GilaSettings as GilaSettingsModel } from "../../models/GilaSettings";
import { Crumb } from "../../models/Crumb";
import { Routes } from "../../routes";


interface GilaSettingsProps {
    isFetching: boolean,
    isSaving: boolean,
    gilaSettings: GilaSettingsModel,
    actionResult: IActionResult
}

const GilaSettings =  ({isFetching, isSaving, gilaSettings, actionResult}:GilaSettingsProps) => {
    const dispatch = useDispatch();
    let actionToken = "GilaSettings"

    useEffect(() => {
        dispatch(getGilaSettings(actionToken));
    }, []);

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Gila Settings", Routes.GilaSettings.path));

    return (
        <>
            <PageHeader title="Gila Settings" crumbs={crumbs} />

            <Container fluid className="gila-settings">
                <Row>
                    <Col lg={6} className="mb-lg-0 mb-sm-4">
                        <Card>
                            <Card.Body>
                                <h3>Gila banking information</h3>
                                <Card.Text>
                                    The Gila financial information will automatically be inherited by each Client. If a Client requires its own financial information, you will have the ability to turn off Gila’s financial information and enter a Client’s financial information.
                                </Card.Text>
                                <GilaBankAccounts isFetching={isFetching} isSaving={isSaving} gilaSettings={gilaSettings} actionResult={actionResult} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <Card.Body>
                                <h3>Chain Code</h3>
                                <Card.Text>
                                    Section description
                                </Card.Text>
                                <ChainCode gilaSettings={gilaSettings} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};


const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.gilaSettings.isFetching,
        isSaving: state.gilaSettings.isSaving,
        gilaSettings: state.gilaSettings.gilaSettings,
        actionResult: state.gilaSettings.actionResult
    };
};

export default connect(mapStateToProps)(GilaSettings);