import React, { useState } from 'react';
import 'react-dropdown/style.css';
import { useDispatch } from "react-redux";
import Table from '../../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../../components/Formatters';
import { Routes } from "../../../routes";
import { Redirect } from 'react-router-dom';
import { Modal, ListGroup } from 'react-bootstrap';
import TransactionDetails from './TransactionDetails';
import Summary from './Summary';
import { ClientName, DepartmentName, PaymentChannelName } from '../../../models/Client';
import { TransactionDetails as TransactionDetailsModel } from '../../../models/Payment';

interface IRelatedTransaction {
    details: TransactionDetailsModel;
    transactionType: string;
    clientNames: Array<ClientName>;
    departmentNames: Array<DepartmentName>;
    paymentChannelNames: Array<PaymentChannelName>;
}

const RelatedTransaction = ({ details, transactionType, clientNames, departmentNames, paymentChannelNames }: IRelatedTransaction) => {
    const [showModal, setModal] = useState(false);
    const [relatedDetails, setRelatedDetails] = useState<TransactionDetailsModel>();

    const requestAction = (cell: any, row: any) => {
        return (
            <a onClick={() => { setModal(true); setRelatedDetails(row) }}>View Details</a>
        );

    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'ID',
            editable: false,
            sort: false,
            hidden: true,
        }, {
            dataField: 'itemName',
            text: 'Item name',
            sort: true,
            editable: false
        }, {
            dataField: 'orderTransactionId',
            text: 'Order transaction ID',
            sort: true,
            editable: false
        }, {
            dataField: 'itemReferenceNumber',
            text: 'Item reference number',
            sort: true,
            editable: false
        }, {
            dataField: 'createdAt',
            text: 'Created at',
            formatter: DateFormatter,
            sort: true,
            editable: false
        }, {
            dataField: 'quantity',
            text: 'Quantity',
            sort: true,
            editable: false
        }, {
            dataField: 'unitPrice',
            text: 'Unit price',
            formatter: CurrencyFormatter,
            sort: true,
            editable: false
        }
    ];

    return (
        <div className="container-related-transactions d-print-none">
            <div className="card-title">
                <h2>Related Order Lines</h2>
            </div>
            {details?.orderLines &&
                <Table keyField="msbId" data={details.orderLines} columns={columns} />
            }
            <Modal show={showModal} onHide={() => setModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <TransactionDetails transactionType={transactionType} details={relatedDetails!} clientNames={clientNames} departmentNames={departmentNames} paymentChannelNames={paymentChannelNames}/>
                    <Summary details={relatedDetails} action={transactionType} closeModal={() => setModal(false)} />
                </Modal.Body>
            </Modal>
        </div>

    )

}

export default RelatedTransaction