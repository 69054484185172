import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../redux/storeTypes';
import { cancelAction } from '../../../../redux/actions/clients/clientOnboarding';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { Department } from '../../../../models/Client';
import { StepProps } from '../StepContainer';
import DepartmentDetailsForm from "../forms/department/DepartmentDetailsForm";
import { Redirect } from 'react-router-dom';
import { Routes } from '../../../../routes';
import FormHeader from "../forms/FormHeader";
import { ADD_DEPARTMENT_REQUEST, ADD_DEPARTMENT_SUCCESS, ADD_DEPARTMENT_FAILURE } from '../../../../redux/actions/clients/departments';

export interface IDepartmentDetailsStepProps extends StepProps {
    department: Department,
}

const DepartmentDetailsStep = ({ isSaving, isFetching, client, department, formProps, actionResult }: IDepartmentDetailsStepProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");

    const departmentAddActionToken = "DepartmentAdd";

    useEffect(() => {
        if (department === undefined) {
            department = new Department();
        }
    }, []);

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === ADD_DEPARTMENT_REQUEST && actionResult.token === departmentAddActionToken) {
            if (actionResult.result === ADD_DEPARTMENT_SUCCESS) {
                dispatch(sendSuccessToastAction("Department details were successfully added."));
                setRedirect(Routes.Clients.path);
            } else if (actionResult.result === ADD_DEPARTMENT_FAILURE) {
                dispatch(sendErrorToastAction("Department details could not be added."));
            }
        }
    }, [actionResult]);

    if (redirect !== "") {
        dispatch(cancelAction());
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <FormHeader title="Department Details" description="Please enter in the Department’s details below. Additional Departments can be added later." />
                <DepartmentDetailsForm
                    isSaving={isSaving}
                    isFetching={isFetching}
                    client={client}
                    department={department}
                    actionToken={departmentAddActionToken}
                    formActionProps={{
                        disabled: isSaving,
                        showSpinner: isFetching,
                        hasSubmit: true,
                        onCancel: () => setRedirect(Routes.Clients.path),
                        customProps: {
                            submitButtonDisplay: "Add"
                        }
                    }}
                />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.clients.isSaving,
        isFetching: state.clients.isFetching,
        gilaSettings: state.gilaSettings.gilaSettings,
        client: state.clients.client,
        department: state.clients.department,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(DepartmentDetailsStep);