import React  from "react";
import PageHeader from '../../../../components/layout/PageHeader';
import { Crumb } from "../../../../models/Crumb";
import { Routes } from "../../../../routes";
import RoleTabsContainer from './RoleTabsContainer';

const AddRole = () => {
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Client Roles", Routes.ClientRoles.path));
    crumbs.push(new Crumb("Add Role", Routes.AddClientRoles.path));

    return (
        <>
            <PageHeader title={"Add Role"} crumbs={crumbs} />
            <RoleTabsContainer />
        </>
    );
};

export default AddRole;
