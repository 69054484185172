import { CellPlugin } from '@react-page/editor';
import { Col, Form, Row, Button } from 'react-bootstrap';

type Data = {
    clientName: string,
    departmentName: string,
    address: string,
    phone: string,
}

const PMoFConfirmationPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <div>
            <Row>
                <Col>
                    <Form.Group style={{ textAlign: "center", margin: "10px" }}>
                        <Form.Text style={{ fontSize: "24px", fontWeight: "bold" }}>{"Payment Method on File Authorization"}</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group style={{ textAlign: "justify", margin: "5px" }}>
                        <Form.Text style={{ fontSize: "18px", fontWeight: "bold" }}>{"Your request to store and use your payment method on file for future transactions has been successfully processed."}</Form.Text><br /><br />
                        <Form.Text style={{ fontSize: "18px", fontWeight: "bold" }}>{"Should you have any questions or need assistance, please contact:"}</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group style={{ textAlign: "center", margin: "5px" }}>
                        <Form.Text style={{ fontSize: "16px" }}>{data.clientName}<br />{data.departmentName}<br />{data.address}<br />{data.phone}</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId={`pmof_${"authorization_date"}`} style={{ margin: "5px" }}>
                        <Form.Label>{"Date of Authorization"}</Form.Label>
                        <Form.Control readOnly={true} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Text style={{ fontSize: "18px", fontWeight: "bold", margin: "5px" }}><br />{"Payment Method on File Details"}</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId={`pmof_${"customer_name"}`} style={{ margin: "5px" }}>
                        <Form.Label>{"Customer Name"}</Form.Label>
                        <Form.Control readOnly={true} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId={`pmof_${"email_address"}`} style={{ margin: "5px" }}>
                        <Form.Label>{"Email Address"}</Form.Label>
                        <Form.Control readOnly={true} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId={`pmof_${"payment_method"}`} style={{ margin: "5px" }}>
                        <Form.Label>{"Payment Method"}</Form.Label>
                        <Form.Control readOnly={true} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId={`pmof_${"last_four"}`} style={{ margin: "5px" }}>
                        <Form.Label>{"Last 4 of Account"}</Form.Label>
                        <Form.Control readOnly={true} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group style={{ textAlign: "center", margin: "15px" }}>
                        <Button type="button">Email Authorization</Button>&nbsp;&nbsp;&nbsp;
                        <Button type="button">Print Authorization</Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group style={{ textAlign: "center", margin: "10px" }}>
                        <Form.Text style={{ fontSize: "12px", color: "silver" }}>Powered by MSB Payment Processing</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    ),
    id: 'formPMoFConfirmationPlugin',
    title: 'PMoF Confirmation',
    description: 'PMof Confirmation',
    version: 1,
    controls: {
        type: 'autoform',
        schema: {
            properties: {
                clientName: {
                    type: 'string',
                    default: 'Generic County'
                },
                departmentName: {
                    type: 'string',
                    default: 'Generic Department'
                },
                address: {
                    type: 'string',
                    default: '8325 Tuscany Way, Austin 78789 TX'
                },
                phone: {
                    type: 'string',
                    default: '(555) 123-4567'
                },
            },
            required: ['clientName', 'departmentName', 'address', 'phone'],
        },
    },
};

export default PMoFConfirmationPlugin;