import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, } from 'react-bootstrap';
import RequiredIcon from '../../../../components/RequiredIcon';
import FormHeader from '../../../clients/components/forms/FormHeader';
import { IAppState } from '../../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import { BusinessTypeEnum } from '../../../../models/Client';
import { getPaymentChannelAction } from '../../../../redux/actions/clients/paymentChannels';

const TerminalDetails = ({ details, paymentChannel, determineRefundMethodName }: any) => {

    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [defaultTerminal, setDefaultTerminal] = useState<any>();

    useEffect(() => {
        dispatch(getPaymentChannelAction(details.paymentChannelId));
        setDefaultTerminal(details.laneId)
    }, [details])

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <FormHeader title="" description="Please select a Terminal below to continue with the Refund transaction" />
                {determineRefundMethodName === 'Refund'
                    ?
                    <div style={{ marginBottom: '16px' }}>
                        <span style={{ color: 'Red' }}>
                            Customer card is required to process the refund. Please have the customer insert their card when prompted.
                        </span>
                    </div>
                    :
                    null
                }
                <Form.Group controlId="terminalLaneId" className="mb-4">
                    <Form.Label><RequiredIcon />Terminal</Form.Label>
                    <Form.Control as="select" type="input" required value={defaultTerminal} onChange={(e) => setDefaultTerminal(e.target.value)}>
                        {
                            paymentChannel?.processors.find((p: any) => p.merchantProcessor?.businessType === BusinessTypeEnum.Retail)?.merchantProcessor?.terminals?.map((terminal: any) => (<option value={terminal.laneId} key={terminal.msbId}>{terminal.name}</option>))
                        }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please select a Terminal.</Form.Control.Feedback>
                </Form.Group>

            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        paymentChannel: state.clients.paymentChannel
    };
};

export default connect(mapStateToProps)(TerminalDetails);
