import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import { Client, Department, Printer, Terminal } from "../../../../models/Client";
import { IAppState, IActionResult } from '../../../../redux/storeTypes';
import { Routes } from '../../../../routes';
import DepartmentPrintersSubSection from "./DepartmentPrintersSubSection";
import DepartmentTerminalsSubSection from "./DepartmentTerminalsSubSection";

const DepartmentPrintersAndTerminalsSection = ({ isSaving, isFetching, client, department, actionResult }:
    { isSaving: boolean, isFetching: boolean, client: Client, department: Department, actionResult: IActionResult }) => {
    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                <DepartmentPrintersSubSection isSaving={isSaving} isFetching={isFetching} department={department} actionResult={actionResult} />
                <hr />
                {/*
                <DepartmentTerminalsSubSection isSaving={isSaving} isFetching={isFetching} department={department} actionResult={actionResult} />
                <hr />
                */}
                <div className="manage-footer">
                  <Button type="button" variant="link" onClick={() => setRedirect(true)} style={{ float: "right" }}>Cancel</Button>
                </div>
            </>
        );
    }
};


const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        client: state.clients.client,
        department: state.clients.department,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(DepartmentPrintersAndTerminalsSection);