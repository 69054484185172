import React, { useRef } from "react";
import { Tab, Nav, Button } from 'react-bootstrap';
import { Department } from '../../../models/Client';
import { IActionResult } from '../../../redux/storeTypes';
import DepartmentDetailsSection from './manageDepartmentSections/DepartmentDetailsSection';
import DepartmentAddressSection from "./manageDepartmentSections/DepartmentAddressSection";
import DepartmentContactsSection from "./manageDepartmentSections/DepartmentContactsSection";
import DepartmentPaymentChannelsSection from "./manageDepartmentSections/DepartmentPaymentChannelsSection";
import DepartmentPrintersAndTerminalsSection from "./manageDepartmentSections/DepartmentPrintersAndTerminalsSection";
import ManageConstituentsSection from "./manageClientSections/ManageConstituentsSection";
import DepartmentAccountingSection from "./manageDepartmentSections/DepartmentAccountingSection";
import DepartmentReceiptsSection from "./manageDepartmentSections/DepartmentReceiptsSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { DepartmentManagementTabsHeaders } from "./manageDepartmentSections/DepartmentManagementTabHeaders";
import DepartmentFeesSection from "./manageDepartmentSections/DepartmentFeesSection";

export interface IDepartmentManagementnProps {
    isSaving: boolean,
    isFetching: boolean,
    department?: Department,
    actionResult?: IActionResult
}

const DepartmentManagement = ({ isSaving, isFetching, department, actionResult }: IDepartmentManagementnProps) => {
  const ref = useRef(null);
  
  const scroll = (scrollOffset: any) => {
    const node: any = ref.current!;
    node.scrollLeft += scrollOffset;
  };
  
  return (
    <Tab.Container defaultActiveKey="manage_details">
      
        {/* BEGIN: Tabs */}
        <div className="tab-nav d-flex px-0">
          <Button variant="link" onClick={() => scroll(-300)} className="btn-scroll-left">
            <FontAwesomeIcon icon={faChevronLeft} size="xs" />
          </Button>
          <div className="scrollbarContainer" ref={ref}>
            <Nav className="flex-row flex-nowrap">
              {Object.entries(DepartmentManagementTabsHeaders).map(
                ([index, value]: any) => {
                  return (
                    <Nav.Item>
                      <Nav.Link className="tabWidth" eventKey={value.key}>{value.title}</Nav.Link>
                    </Nav.Item>
                  )
                }
              )}
            </Nav>
          </div>
          <Button variant="link" onClick={() => scroll(300)} className="btn-scroll-right">
            <FontAwesomeIcon icon={faChevronRight} size="xs" />
          </Button>
        </div>
        {/* END: Tabs */}

        <Tab.Content>
            <Tab.Pane eventKey="manage_details">
                <DepartmentDetailsSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_addresses">
                <DepartmentAddressSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_contacts">
                <DepartmentContactsSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_accounting">
                <DepartmentAccountingSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_mids_fees">
                <DepartmentFeesSection />      
            </Tab.Pane>
            <Tab.Pane eventKey="manage_receipts">
                <DepartmentReceiptsSection />      
            </Tab.Pane>
            <Tab.Pane eventKey="manage_paymentchannels">
                <DepartmentPaymentChannelsSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_devices">
                <DepartmentPrintersAndTerminalsSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_constituents">
                <ManageConstituentsSection />
            </Tab.Pane>
        </Tab.Content>
    </Tab.Container>
  )
}

export default DepartmentManagement;