import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, ButtonToolbar, Card, Col, Container, Form, Row, Spinner, Modal, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
// @ts-ignore
import DualListBox from 'react-dual-listbox';
import moment from 'moment';

import FormHeader from '../clients/components/forms/FormHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import Table from '../../components/Table';
import { CurrencyFormatter, DateOnlyFormatter } from '../../components/Formatters';
import { WorldpayTransaction, WorldpaySummary } from '../../models/Reports';

import { getWorldpayTransactions, SET_WORLDPAY_TRANSACTION_DETAILS } from '../../redux/actions/reports/report';
import { IActionResult, IAppState, IClientsState } from '../../redux/storeTypes';
import { Crumb } from "../../models/Crumb";
import PageHeader from "../../components/layout/PageHeader";
import { Routes } from "../../routes";
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { 
    createWorldpayTransactionsReportExcel, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS, CLEAR_DOCUMENT
} from '../../redux/actions/document';
import { sendErrorToastAction, sendSuccessToastAction } from "../../redux/actions/toast";
import { Redirect } from "react-router-dom";
import store from "../../redux/store";
import axios from "axios";
import { BusinessTypeEnum, Client, ClientName, CollectForMids } from "../../models/Client";

export interface WorldpayTransactionsReportProps {
    isFetching: boolean,
    items: Array<WorldpayTransaction>,
    documentActionResult: IActionResult,
    actionResult: IActionResult,
}

const WorldpayTransactionsReport = ({ isFetching, items, documentActionResult }: WorldpayTransactionsReportProps) => {
    const actionToken = "getWorldpayTransactions";
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");

    const defaultStartDate = moment().subtract(1, "day").format('YYYY-MM-DD');
    const defaultEndDate = moment().subtract(1, "day").format('YYYY-MM-DD');
    const defaultMerchantNumber = "";

    const [startDate, setStartDate] = useState<string>(defaultStartDate);
    const [endDate, setEndDate] = useState<string>(defaultEndDate);
    const [merchantNumber, setMerchantNumber] = useState<string>(defaultMerchantNumber);
    const [showModal, setModal] = useState(false);
    const [summaryFromItems, setSummaryFromItems] = useState<Array<WorldpaySummary>>([]);
    const [metaData, setMetaData] = useState<Array<{ merchantNumber: string, merchantType: string, clientName: string, departmentName: string }>>([]);
    const [collectForMidsList, setCollectForMidsList] = useState({[merchantNumber] : []});
    
    const [waitingForDownload, setWaitingForDownload] = useState<boolean>(false);
    const actionTokenCreateExcel = "WorldpayTransactionsReport";

    useEffect(() => {
        dispatch(getWorldpayTransactions(startDate, endDate, actionToken, merchantNumber));
    }, []);

    const resetSearch = () => {
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setMerchantNumber(defaultMerchantNumber);
        dispatch(getWorldpayTransactions(defaultStartDate, defaultEndDate, actionToken, defaultMerchantNumber));
    };

    const handleSearch = (event: any) => {
        event.preventDefault();
        setModal(false);
        dispatch(getWorldpayTransactions(startDate, endDate, actionToken, merchantNumber));
    };

    const accumulate = (summary: WorldpaySummary, transaction: WorldpayTransaction) => {
        if (['sale'].some(s => transaction.transactionType.toLowerCase().includes(s))) {
            if (['inprocess', 'approved', 'authorized', 'authcompleted', 'success', 'queued', 'settled'].some(s => transaction.transactionStatus.toLowerCase().includes(s))) {
                summary.salesCount++;
                summary.netCount++;
                summary.salesAmount += transaction.transactionAmount;
                summary.netAmount += transaction.transactionAmount;
            }
        } else if (['refund', 'return'].some(s => transaction.transactionType.toLowerCase().includes(s))) {
            if (['inprocess', 'approved', 'authorized', 'authcompleted', 'success', 'settled', 'partialapproved'].some(s => transaction.transactionStatus.toLowerCase().includes(s))) {
                summary.creditsCount++;
                summary.netCount++;
                summary.creditsAmount -= transaction.transactionAmount;
                summary.netAmount -= transaction.transactionAmount;
            }
        }
        summary.transactionItems.push(transaction);
    };

    useEffect(() => {
        let updated = false;
        const copy = [...summaryFromItems];
        copy.forEach((item: { merchantType: string | undefined; merchantNumber: string; clientName: string | undefined; departmentName: string | undefined; }) => {
            if (!item.merchantType) {
                let row = metaData.find(element => element.merchantNumber === item.merchantNumber);
                if (row) {
                    Object.assign(item, row);
                    updated = true;
                }
            }
        });
        if (updated) setSummaryFromItems(copy);
    }, [metaData, summaryFromItems]);

    useEffect(() => {
        if(items){ 
            let Mids: Array<string> = new Array<string>();
                items.forEach((el) => {
                    if (!Mids.includes(el.acceptorID)) {
                        Mids.push(el.acceptorID);
                    }
            });
            getCollectForMids(Mids);
        }
    }, [items]);

    useEffect(() => {
        if (items) {
            const summaries = items.reduce((acc, curr) => {
                if (!acc.some((obj) => {
                    if (obj.merchantNumber === curr.acceptorID && obj.transactionDate === curr.expressTransactionDate) {
                        accumulate(obj, curr);
                        return true;
                    }
                    return false;
                })) {
                    const summary = Object.assign(new WorldpaySummary(), {
                        merchantNumber: curr.acceptorID,
                        transactionDate: curr.expressTransactionDate,
                        transactionDateTime: new Date(curr.expressTransactionDateTime)
                    });
                    accumulate(summary, curr);              
                    acc.push(summary);
                    updateMetaData(summary.merchantNumber);
                }
                return acc;
            }, new Array<WorldpaySummary>());
            setSummaryFromItems(summaries);
        }
    }, [items, collectForMidsList]);

    useEffect(() => {        
        if (documentActionResult && documentActionResult.result) {
            if (documentActionResult.type === CREATE_DOCUMENT_REQUEST && documentActionResult.token === actionTokenCreateExcel) {
                if (documentActionResult.result === CREATE_DOCUMENT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Excel creation successful"));
                } else if (documentActionResult.result === CREATE_DOCUMENT_FAILURE) {
                    dispatch(sendErrorToastAction("Excel creation failed"));
                }
                setWaitingForDownload(false);
            }
        }
        dispatch({ type: CLEAR_DOCUMENT });
    }, [documentActionResult]);

    const getCollectForMids = (Mids: Array<string>) => {
        if (Mids.length > 0){
            let _url = `${store.getState().webAppSettings.baseApiUrl}/MSB_Client/api/v1/MerchantProcessors/collectForMids`;
            
            axios
                .patch(_url, Mids)
                    .then(async response => {
                        const midsResponse = response.data;
                        setCollectForMidsList(midsResponse);
                });
            }
    };

    const updateMetaData = (merchantNumber: string) => {
       if(collectForMidsList !== null){
            if (!metaData.some(item => { return item.merchantNumber === merchantNumber})) {
            let findCollectForMid = collectForMidsList[merchantNumber];

            if(findCollectForMid !== undefined){
                const collectForMids: CollectForMids = findCollectForMid?.[0];
                setMetaData(metaData => [...metaData, { merchantNumber: merchantNumber, merchantType: BusinessTypeEnum[collectForMids.businessType], clientName: collectForMids.clientName, departmentName: collectForMids.departmentName }]);
                }
            }
        }
    };

    const doToolbar = () => {
        if (!isFetching) {
            return (
                <div className="flexContainer">
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle className="approvalAction" variant="outline-secondary" disabled={waitingForDownload}>
                                { waitingForDownload?
                                    <Spinner animation="border" />
                                    :
                                    <FontAwesomeIcon icon={faArrowToBottom} size="sm" />
                                } Download Summary
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    <>
                                        <Dropdown.Item onClick={() => {
                                            dispatch(createWorldpayTransactionsReportExcel(startDate, endDate, actionTokenCreateExcel));
                                            setWaitingForDownload(true);
                                        }}> Excel File </Dropdown.Item>
                                    </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            )
        } else {
            return (<></>);
        }
    }

    const viewDetailsFormatter = (cell: any, row: any) => {
        const detailsData = {
            worldpaySummary: row
        };

        return(
            <span>
                <a 
                    onClick={() => {
                        dispatch({ type: SET_WORLDPAY_TRANSACTION_DETAILS, payload: detailsData });
                        setRedirect(Routes.WorldpayTransactionsReportDetails.path);
                    }}
                >
                    View Details
                </a>
            </span>
        );
    }

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Worldpay Transactions Report", Routes.WorldpayTransactionsReport.path));

    const columns = [
        {
            dataField: 'clientName',
            text: 'Client',
            editable: false,
            sort: true,
            configurable: true
        },
        {
            dataField: 'departmentName',
            text: 'Department',
            editable: false,
            configurable: true
        },
        {
            dataField: 'merchantNumber',
            text: 'Merchant ID',
            editable: false,
            configurable: true
        },
        {
            dataField: 'transactionDateTime',
            text: 'Transaction date',
            editable: false,
            sort: true,
            formatter: DateOnlyFormatter,
            configurable: true
        },
        {
            dataField: 'merchantType',
            text: 'MID Type',
            editable: false,
            configurable: true
        }, 
        {
            dataField: 'salesAmount',
            text: 'Sales amount',
            editable: false,
            formatter: CurrencyFormatter,
            configurable: true
        },
        {
            dataField: 'salesCount',
            text: 'Sales count',
            editable: false,
            configurable: true
        },
        {
            dataField: 'creditsAmount',
            text: 'Credits amount',
            editable: false,
            formatter: CurrencyFormatter,
            configurable: true
        }, 
        {
            dataField: 'creditsCount',
            text: 'Credits count',
            editable: false,
            configurable: true
        },
        {
            dataField: 'netAmount',
            text: 'Net amount',
            editable: false,
            formatter: CurrencyFormatter,
            configurable: true
        }, 
        {
            dataField: 'netCount',
            text: 'Net count',
            editable: false,
            configurable: true
        }, 
        {
            dataField: 'transactionItems',
            text: 'Actions',
            editable: false,
            formatter: viewDetailsFormatter,
            configurable: false
        }
    ];
    
    if (redirect != "") return <Redirect push to={redirect} />;
    
    return (
        <>
            <PageHeader title={`Worldpay Transactions Report`} crumbs={crumbs} />

            <Container fluid className="container-table-search">
              <PageSectionContainer>
                  <Form className="table-search advanced-search" onSubmit={handleSearch}>
                      <Row>
                          <Col xl={4} lg={6} md={6} sm={12}>
                              <Form.Group controlId="startDate">
                                  <Form.Label>Start date</Form.Label>
                                  <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                              </Form.Group>
                          </Col>
                          <Col xl={4} lg={6} md={6} sm={12}>
                              <Form.Group controlId="endDate">
                                  <Form.Label>End date</Form.Label>
                                  <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                              </Form.Group>
                          </Col>
                          <Col xl={4} lg={12} md={12} sm={12}>
                              <ButtonToolbar>
                                  <Button variant="link" onClick={() => setModal(true)}>Advanced Search</Button>
                                  <Button variant="outline-secondary" onClick={resetSearch}>Reset</Button>
                                  <Button type="submit">Search</Button>
                              </ButtonToolbar>
                          </Col>
                      </Row>
                  </Form>
              </PageSectionContainer>
            </Container>

           
            <Container fluid>
                <PageSectionContainer title="Manage Worldpay Transactions Report" toolbar={doToolbar()}>
                    {
                        isFetching ? (
                            <Spinner animation="border" />
                        ) : (
                                (summaryFromItems || []).length <= 0 ?
                                    <>
                                        <h2 className="fw-bold">No records were found</h2>
                                        <span>Please do another search to find the record you are looking for.</span>
                                    </> :
                                    <Table
                                        keyField="WorldpayTransactions"
                                        columns={columns}
                                        data={summaryFromItems}
                                    />
                        )
                    }
                </PageSectionContainer>
            </Container>
            
            <Modal show={showModal} onHide={setModal} size="lg" className="advanced-search-modal">
                <Modal.Header closeButton />
                <Modal.Body>
                    <FormHeader title="Advanced Search" description="Please enter the details below to narrow your search results" />
                    <Form className="advanced-search-form" onSubmit={handleSearch}>
                      <Row className="advanced-search-form-columns">
                            <Form.Group controlId="startDate" className="mb-3">
                                <Form.Label>Start date</Form.Label>
                                <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="endDate">
                                <Form.Label>End date</Form.Label>
                                <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="merchantNumber" className="mb-0">
                                <Form.Label>Merchant number</Form.Label>
                                <Form.Control type="input" name="merchantNumber" value={merchantNumber} placeholder="Enter Merchant number" onChange={(e) => setMerchantNumber(e.target.value)} />
                            </Form.Group>
                      </Row>
                      <Row className="advanced-search-footer">
                        <Col>
                            <Button type="submit">Search</Button>
                        </Col>
                        <Col>
                            <Button form='navientUserSubmit' className="navientUserFooterCancel" variant="link" onClick={() => setModal(false)}>
                                Cancel
                            </Button>
                        </Col>
                      </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.reports.isFetching,
        items: state.reports.worldpayTransactions,
        documentActionResult: state.document.actionResult,
    };
};

export default connect(mapStateToProps)(WorldpayTransactionsReport);