export const pdfTransactionTableColumnsList = [
    { header: 'Transaction ID', dataKey: 'transactionId' },
    { header: 'Transaction Date', dataKey: 'createdAt' },
    { header: 'Payment Channel Name', dataKey: 'paymentChannelName' },
    { header: 'Transaction Reference', dataKey: 'transactionReference' },
    { header: 'Customer Name', dataKey: 'customerName' },
    { header: 'Transaction Type', dataKey: 'transactionType' },
    { header: 'Amount', dataKey: 'amount' },
    { header: 'Convenience Fee', dataKey: 'convenienceFee' },
    { header: 'Total Amount', dataKey: 'totalAmount' },
    { header: 'Card Type', dataKey: 'cardType' },
    { header: 'Fee Assessed Card Type', dataKey: 'feeAssessedCardType' },
    { header: 'Last Four On Card/Account', dataKey: 'lastFourOnCard' },
    { header: 'V/R/CB Reason', dataKey: 'reason' },
]

export const pdfCalculatedTransactionColumnsList = [
    { header: 'Transaction Type', dataKey: 'transactionType' },
    { header: 'Number Of Transactions', dataKey: 'totalTransactions', },
    { header: 'Amount', dataKey: 'amount' },
    { header: 'Convenience Fee', dataKey: 'convenienceFee' },
    { header: 'Total Amount', dataKey: 'totalAmount' },
]

export const defaultCSVTableHeaders = [
    'Client', 'Department', 'Payment Channel', 'Initiated by', 'Transaction ID',
    'Last four on card/account', 'Item reference', 'Item name', 'Reason',
    'Transaction date', 'Transaction type', 'Subtotal', 'Convenience fee', 'Total amount', 'Original amount',
    'Remaining balance', 'Customer name', 'Card type', 'Fee Assessed Card type', 'Customer phone', 'Customer email',
    'Address line 1', 'Address line 2', 'City', 'State', 'Zip code'
]

export const customizedOrderLinesCSVTableHeaders = [
    'Client', 'Department', 'Payment Channel', 'Initiated by', 'Transaction ID',
    'Last four on card/account', 'Reason', 'Item reference', 'Item name', 'Item unit price' , 'Item quantity', 'Item Total',
    'Transaction date', 'Transaction type', 'Customer name', 'Card type', 'Fee Assessed Card type', 'Customer phone', 'Customer email',
    'Address line 1', 'Address line 2', 'City', 'State', 'Zip code', 'Client MetaData Fields'
]

export const customizedCSVTableHeaders = [
    'Transaction ID','Transaction Date','Payment Channel Name','Transaction Reference',
    'Customer Name', 'Transaction Type', 'Amount', 'Convenience Fee', 'Total Amount', 'Card Type', 'Fee Assessed Card Type',
    'Last Four On Card/Account','V/R/CB Reason',
]
