import { useState } from "react";
import { Button } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { doLogOut } from "../../../../../../redux/actions/auth";

interface INavigationLink{
    text: string,
    path: string,
}

const NavigationLink = ({ text, path }: INavigationLink) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const onLogOut = () => dispatch(doLogOut());

    return (
        <nav className="navbar navbar-expand-lg navbar-light p-0 msb-portal__nav">
            <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="#">Logout</a>
                        </li>
                    </ul>
                 </div>
            </div>
        </nav>
    )
}

export default NavigationLink;