import {
    NEXT_STEP, PREVIOUS_STEP, DEPARTMENT_CANCEL_STEP,
    DEPT_ONBOARDING_STEPS,
    DEPARTMENT_DETAILS_STEP
} from '../actions/clients/clientOnboarding'

export default function deptOnboarding(state = {
    isFetching: false,
    errorMessage: '',
    nextStep: DEPARTMENT_DETAILS_STEP,
    loadingInit: true,
}, { type, payload }) {
    let stepIndex = -1;
    let nextStep = '';

    switch (type) {
        case NEXT_STEP:
            stepIndex = DEPT_ONBOARDING_STEPS.indexOf(state.nextStep);

            if (stepIndex < DEPT_ONBOARDING_STEPS.length - 1) {
                stepIndex = stepIndex + 1;
                nextStep = DEPT_ONBOARDING_STEPS[stepIndex];
            }

            return Object.assign({}, state, {
                nextStep: nextStep,
                isFetching: false
            });
        case PREVIOUS_STEP:
            stepIndex = DEPT_ONBOARDING_STEPS.indexOf(state.nextStep);

            if (stepIndex >= 1) {
                stepIndex = stepIndex - 1;
                nextStep = DEPT_ONBOARDING_STEPS[stepIndex];
            }

            return Object.assign({}, state, {
                nextStep: nextStep,
                isFetching: false
            });
        case DEPARTMENT_CANCEL_STEP:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: '',
                nextStep: DEPARTMENT_DETAILS_STEP,
                loadingInit: true,
            });
        default:
            return state;
    }
}
