import React, { useEffect, useState } from 'react';
import { EmailRequest, EmailType, TemplateType } from '../../../models/EmailRequest';
import { Form, Button, Card, Accordion, ListGroup, Spinner } from 'react-bootstrap';
import FormHeader from './forms/FormHeader';
import { sendEmailAction, SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE } from '../../../redux/actions/emails';
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../redux/storeTypes';
import { ClientName, DebitFeeMethodEnum, Department, FeeBurdenMethodEnum, FundingMethodEnum, PaymentChannelTypeEnum, PaymentTypeEnum } from '../../../models/Client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { ClientNameFormatter, DateFormatter } from '../../../components/Formatters';
import { getClientNamesAction } from "../../../redux/actions/clients/clients";


export interface IDepartmentSummaryProps {
    department: Department,
    isFetching: boolean,
    closeModal: any,
    clientNames: Array<ClientName>,

}

const DepartmentSummaryModal = ({ department, isFetching, clientNames, closeModal }: IDepartmentSummaryProps) => {

    const [departmentDetails, setDepartmentDetails] = useState<any>([]);
    const [emailAddress, setEmailAddress] = useState<any>(['msbinvoicing@gilacorp.com','bps_qa@navient.com']);

    useEffect(() => {
        if (department) {
            setDepartmentDetails(department);
        }
        else { setDepartmentDetails([]) }
    }, [department])

    useEffect(() => {
        if (!clientNames) {
            dispatch(getClientNamesAction(actionToken));
        }
    }, [clientNames]);

    let actionToken = "EmailReceipt";
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() !== false) {

            let emailRequest = new EmailRequest();
            emailRequest.emailType = EmailType.Other;
            emailRequest.subject = "Department Summary";
            emailRequest.templateType = TemplateType.DepartmentSummary;
            emailRequest.recipientAddresses = emailAddress;
            emailRequest.model = departmentDetails;
            dispatch(sendEmailAction(emailRequest, actionToken));
            closeModal();
        }
        setValidated(true);
    }

    const spinner = () => {
        return (<Spinner animation="border" />);
    }

    return (
        <>
            <FormHeader title="Department details" description={`Below are the ${departmentDetails?.name} details`} />
            <Accordion className="agreement-form">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="agreement_toggle_header" >
                        <FontAwesomeIcon icon={faChevronDown} />Payment Channel Details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {!isFetching ?
                                <ListGroup variant="flush">
                                    <>
                                        <ListGroup.Item>
                                            <div className="agreement_details_row">
                                                <h3>Client Name</h3>
                                                <span>{ClientNameFormatter(departmentDetails?.clientMsbId, clientNames)}</span>
                                            </div>
                                            <div className="agreement_details_row">
                                                <h3>Department Name</h3>
                                                <span>{departmentDetails?.name}</span>
                                            </div>
                                            <hr />
                                        </ListGroup.Item>
                                        <div style={{ 'marginBottom': '12px' }}>
                                            <p>Payment Channels</p>
                                        </div>
                                        <ListGroup.Item>
                                            <ListGroup variant="flush">
                                                <div className="agreement-department-list">
                                                    {departmentDetails?.paymentChannels?.map((paymentChannel: any, index: any) => {
                                                        return (
                                                            <ListGroup.Item key={index}>
                                                                <div>
                                                                    <div className="agreement_details_row">
                                                                        <h3>Payment Channel Name</h3>
                                                                        <span>{paymentChannel?.name}</span>
                                                                    </div>
                                                                    <div className="agreement_details_row">
                                                                        <h3>Payment Channel Type</h3>
                                                                        <span>{PaymentChannelTypeEnum[paymentChannel?.paymentChannelTypeEnum]}</span>
                                                                    </div>
                                                                    <div className="agreement_details_row">
                                                                        <h3>Created At</h3>
                                                                        <span>{DateFormatter(paymentChannel?.createdAt!)}</span>
                                                                    </div>
                                                                </div>
                                                            </ListGroup.Item>
                                                        )
                                                    })}
                                                </div>
                                            </ListGroup>
                                        </ListGroup.Item>
                                    </>
                                </ListGroup>
                                : spinner()}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1" className="agreement_toggle_header" >
                        <FontAwesomeIcon icon={faChevronDown} />Merchant Details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            {!isFetching ?
                                <ListGroup variant="flush">
                                    <div className="agreement-department-list">
                                        {departmentDetails?.merchantIDs?.map((merchantId: any, index: any) => {
                                            return (
                                                <ListGroup.Item key={index}>
                                                    <div>
                                                        <div className="agreement_details_row">
                                                            <h3>Merchant ID</h3>
                                                            <span>{merchantId?.mid}</span>
                                                        </div>
                                                        <div className="agreement_details_row">
                                                            <h3>Merchant Type</h3>
                                                            <span>{merchantId?.processorInformation?.businessType}</span>
                                                        </div>
                                                        <div className="agreement_details_row">
                                                            <h3>CC Debit Descriptor</h3>
                                                            <span>{merchantId?.ccDebitDescriptor}</span>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </div>
                                </ListGroup>
                                : spinner()}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2" className="agreement_toggle_header" >
                        <FontAwesomeIcon icon={faChevronDown} />Finance Details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            {!isFetching ?
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <div className="agreement_details_row">
                                            <h3>ACH Debit Descriptor</h3>
                                            <span>{departmentDetails?.achDebitDescriptor}</span>
                                        </div>
                                        <div className="agreement_details_row">
                                            <h3>Funding Descriptor</h3>
                                            <span>{departmentDetails?.bankFundingDescriptor}</span>
                                        </div>
                                        <div className="agreement_details_row">
                                            <h3>Fee Burden</h3>
                                            <span>{FeeBurdenMethodEnum[departmentDetails?.feeBurdenMethodEnum]}</span>
                                        </div>
                                        <div className="agreement_details_row">
                                            <h3>Funding Method</h3>
                                            <span>{FundingMethodEnum[departmentDetails?.fundingMethodEnum]}</span>
                                        </div>
                                        <div className="agreement_details_row">
                                            <h3>Debit Method</h3>
                                            <span>{DebitFeeMethodEnum[departmentDetails?.debitFeeMethodEnum]}</span>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                : spinner()}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3" className="agreement_toggle_header" >
                        <FontAwesomeIcon icon={faChevronDown} />Fees and Thresholds
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            {!isFetching ?
                                <ListGroup variant="flush">
                                    <div className="agreement-department-list">
                                        {departmentDetails?.convenienceFees?.map((convenienceFee: any, index: any) => {
                                            return (
                                                <ListGroup.Item key={index}>
                                                    <div>
                                                        <div className="agreement_details_row">
                                                            <h3>Payment Type</h3>
                                                            <span>{PaymentTypeEnum[convenienceFee?.paymentTypeEnum]}</span>
                                                        </div>
                                                        <div className="agreement_details_row">
                                                            <h3>Percentage</h3>
                                                            <span>{`${convenienceFee?.percentage} %`}</span>
                                                        </div>
                                                        <div className="agreement_details_row">
                                                            <h3>Flat Fee</h3>
                                                            <span>{`$ ${convenienceFee?.flatFee}`}</span>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </div>
                                </ListGroup>
                                : spinner()}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <FormHeader title="Email details" description="Please enter the email address below to receive a copy of the above details." />
                <Form.Group controlId="emailAddress" >
                    <Form.Control required type="input" placeholder="Enter email address" value={emailAddress} onChange={(e) => setEmailAddress([e.target.value])} />
                    <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-footer">
                    <Button variant="outline-secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={isFetching}>
                        Submit
                    </Button>
                </Form.Group>
            </Form>
        </>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        department: state.clients.department,
        isFetching: state.clients.isFetching,
        clientNames: state.clients.clientNames,
    };
};

export default connect(mapStateToProps)(DepartmentSummaryModal);
