export class TylerEagleRequest
{
    clientKey : string = "";
    transactionID : string = "";
    amount : string = "";
    description : string = "";
    redirectURL : string = "";
    postbackURL  : string = "";
    language : string = "";
    getToken : string = "";
    payorName  : string = "";
}

export class TylerEagleCancel
{
    postbackURL : string = "";
}