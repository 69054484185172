import * as React from "react";
import { Container } from 'react-bootstrap';
import PageHeader from '../../../components/layout/PageHeader';
import { Crumb } from "../../../models/Crumb";
import { Routes } from "../../../routes";
import Receipt from "../../receipts/Receipt";

const PrintReceipt = () => {
    const printReceipt = true;
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Print Receipt", Routes.PrintReceipt.path));

    return (
        <>
            <PageHeader title="Print Receipt" crumbs={crumbs} />
            <Container fluid>
                <Receipt printReceipt={printReceipt}/>
            </Container>
        </>
    )
};

export default PrintReceipt; 