import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { PaymentChannel, Department, Client } from '../../../../../models/Client';
import "react-placeholder/lib/reactPlaceholder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CurrencyFormatter } from "../../../../../components/Formatters";
import Table from '../../../../../components/Table';
import { CurrencyInput } from "../../../../../components/currency/CurrencyInput";
import { IActionResult, IAppState } from "../../../../../redux/storeTypes";
import { Item, ItemType } from "../../../../../models/Payment";
import { addItemsAction, ADD_ITEMS_FAILURE, ADD_ITEMS_REQUEST, ADD_ITEMS_SUCCESS, DEL_ITEMS_FAILURE, DEL_ITEMS_REQUEST, DEL_ITEMS_SUCCESS, getItemsAction, removeItemsAction } from "../../../../../redux/actions/payments/itemManagement";
import { sendErrorToastAction, sendSuccessToastAction } from "../../../../../redux/actions/toast";
import FormHeaderConfirmation from "../../../../../components/FormHeaderConfirmation";
//@ts-ignore
import icoWarning from "../../../../../assets/img/icons/ico-warning-outline.svg";

export interface IDepartmentProductCatalogFormProps {
    client: Client;
    department: Department,
    paymentChannelMsbId: string,
    isFetching: boolean,
    isSaving: boolean,
    actionResult: IActionResult,
    items: Array<Item>
}

const DepartmentProductCatalogForm = ({ client, department, paymentChannelMsbId, isFetching, isSaving, actionResult, items }: IDepartmentProductCatalogFormProps) => {
    const actionToken = "ProductCatalog";
    const dispatch = useDispatch();
    const [paymentChannel, setPaymentChannel] = useState((department?.paymentChannels || []).find(paymentChannel => paymentChannelMsbId && paymentChannel.msbId === paymentChannelMsbId) || new PaymentChannel());
    const [confirm, setConfirm] = useState(false);
    const [itemId, setItemId] = useState("");
    const [itemPrice, setItemPrice] = useState(0);

    useEffect(() => {
        if (client && department && paymentChannel) getItems();
    }, [client, department, paymentChannel]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_ITEMS_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === ADD_ITEMS_SUCCESS) {
                    getItems();
                    dispatch(sendSuccessToastAction("Product catalog item added"));
                } else if (actionResult.result === ADD_ITEMS_FAILURE) {
                    dispatch(sendErrorToastAction("Product catlog item not added"));
                }
            }
            if (actionResult.type === DEL_ITEMS_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === DEL_ITEMS_SUCCESS) {
                    getItems();
                    dispatch(sendSuccessToastAction("Product catalog item removed"));
                } else if (actionResult.result === DEL_ITEMS_FAILURE) {
                    dispatch(sendErrorToastAction("Product catlog item not removed"))
                }
            }
        }
    }, [actionResult]);

    const getItems = () => {
        dispatch(getItemsAction(
            client.msbId!,
            department.msbId!,
            paymentChannel.msbId!,
            actionToken
        ));
    }

    const addItem = (event: any) => {
        event.preventDefault();
        const form = event.target;
        if (form.elements.itemName?.value && form.elements.itemDescription?.value && form.elements.itemPrice?.value) {
            let _item = new Item();
            _item.itemType = ItemType.BlindPayment;
            _item.isTaxed = form.elements.preSelect.checked;
            _item.name = form.elements.itemName.value.trim();
            _item.salePrice = Number(form.elements.itemPrice.value.replaceAll(/[^\d\.]/g, ""));
            _item.description = form.elements.itemDescription.value.trim();
            _item.internalReferenceNumber = form.elements.itemReference.value.trim();
            _item.externalItemReference = paymentChannel.msbId!;
            dispatch(addItemsAction(
                client.msbId!,
                department.msbId!,
                _item,
                actionToken
            ));
            setItemPrice(itemPrice + 1);
            form.reset();
        } else {
            dispatch(sendErrorToastAction(`Enter name, description, and price to add an item`));
        }
    }

    const removeItem = () => {
        dispatch(removeItemsAction(
            client.msbId!,
            department.msbId!,
            itemId,
            actionToken
        ));
        setConfirm(false);
    }

    const deleteItem = (item: any) => {
        setItemId(item.id);
        setConfirm(true);
    }

    const actionsFormatter = (cell: any, _row: any) => {
        return (
            <Button variant="outline-secondary" onClick={() => deleteItem(_row)} style={{ marginLeft: "8px" }}>
                <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
        )
    }

    const selectFormatter = (cell: any, _row: any) => {
        return (
            <Form.Check type={'checkbox'} checked={_row.isTaxed} disabled={true} />
        )
    }

    const columns = [
        {
            dataField: 'isTaxed',
            text: 'Pre',
            editable: false,
            formatter: selectFormatter,
        }, {
            dataField: 'name',
            text: 'Name',
            editable: false,
            sort: true,
            width: '180',
        }, {
            dataField: 'description',
            text: 'Description',
            editable: false,
            width: '300',
        }, {
            dataField: 'internalReferenceNumber',
            text: 'Reference',
            editable: false,
            width: '180',
        },{
            dataField: 'salePrice',
            text: 'Price',
            editable: false,
            width: '80',
            formatter: CurrencyFormatter
        }, {
            dataField: 'msbId',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter
        }
    ];

    return (
        <>
            <Modal show={confirm} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Delete item" />
                    <div className="confirmation-body">
                        <p>Are you sure you want to delete this item?</p>
                        <Form.Group className="confirmation-footer">
                            <Button variant="outline-secondary" onClick={() => setConfirm(false)}>
                                Cancel
                            </Button>
                            <Button onClick={() => removeItem()}>
                                Confirm
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>

            <Form onSubmit={addItem}>
                <Row>
                    <Col xs={1}>
                        <Form.Group controlId="preSelect">
                            <Form.Label>Pre-Select</Form.Label>
                            <Form.Check type={'checkbox'} />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group controlId="itemName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control required type="input" placeholder="Name" maxLength={50} disabled={isSaving} />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group controlId="itemDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control required type="input" placeholder="Description" maxLength={80} disabled={isSaving} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="itemReference">
                            <Form.Label>Reference</Form.Label>
                            <Form.Control type="input" placeholder="Reference" maxLength={30} disabled={isSaving} />
                        </Form.Group>
                    </Col>
                    <Col xs={1}>
                        <Form.Group controlId="itemPrice" style={{ minWidth: "100px" }}>
                            <Form.Label>Price</Form.Label>
                            <CurrencyInput
                                key={itemPrice}
                                className="form-control"
                                id="itemPrice"
                                placeholder="$0.00"
                                maxLength={10}
                                decimalsLimit={2}
                                prefix="$"
                                defaultValue=""
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>&nbsp;</Form.Label>
                            <div style={{ float: 'right' }}>
                                <Button variant="primary" type="submit">
                                    <FontAwesomeIcon icon={faPlus} size="sm" style={{ marginRight: "0" }} />
                                </Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col>
                    {items.length > 0 ?
                        <div className="manage-card">
                            <Table
                                key={items.length}
                                keyField="msbId"
                                defaultSorted={[{ dataField: "name", order: "asc" }]}
                                columns={columns}
                                data={items}
                            />
                        </div>
                        :
                        <h1 className="text-center"><i>Product catalog is empty</i></h1>
                    }
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.itemManagement.isFetching,
        isSaving: state.itemManagement.isSaving,
        client: state.clients.client,
        items: state.itemManagement.items,
        actionResult: state.itemManagement.actionResult,
    };
};

export default connect(mapStateToProps)(DepartmentProductCatalogForm);
