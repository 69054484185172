import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ButtonToolbar, Button, Dropdown, Modal } from 'react-bootstrap';
import { IActionResult, IAppState } from '../../../../redux/storeTypes';
import { Department, AddressTypeEnum, Address, Client } from "../../../../models/Client";
import FormHeader from '../forms/FormHeader';
import DepartmentAddressForm from "../forms/department/DepartmentAddressForm";
import { Redirect } from 'react-router-dom';
import { Routes } from '../../../../routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import Table from '../../../../components/Table';
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { sendSuccessToastAction, sendErrorToastAction } from "../../../../redux/actions/toast";
import PermissionModule, { Permissions } from '../../../../components/usePermissions';
import _ from "lodash";
import {
    deleteDepartmentAddressAction,
    ADD_DEPARTMENT_ADDRESS_FAILURE, ADD_DEPARTMENT_ADDRESS_REQUEST, ADD_DEPARTMENT_ADDRESS_SUCCESS,
    SAVE_DEPARTMENT_ADDRESS_FAILURE, SAVE_DEPARTMENT_ADDRESS_REQUEST, SAVE_DEPARTMENT_ADDRESS_SUCCESS,
    DELETE_DEPARTMENT_ADDRESS_FAILURE, DELETE_DEPARTMENT_ADDRESS_REQUEST, DELETE_DEPARTMENT_ADDRESS_SUCCESS
}
    from '../../../../redux/actions/clients/address';

interface IDepartmentAddressSectionProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    department: Department,
    actionResult: IActionResult
}

const DepartmentAddressSection = ({ isSaving, isFetching, department, client, actionResult }: IDepartmentAddressSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<boolean>(false);
    const [address, setAddress] = useState<Address>();
    const [showAddressForm, setShowAddressForm] = useState<boolean>(false);
    const [modalTitle, setModalTitle] =  useState<string>(""); 
    const [addresses, setAddresses] = useState<Array<Address>>([]);

    let actionToken = "DepartmentAddressSection"

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_DEPARTMENT_ADDRESS_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === ADD_DEPARTMENT_ADDRESS_SUCCESS) {
                    setShowAddressForm(false)
                    dispatch(sendSuccessToastAction("Department address has been successfully added."));
                } else if (actionResult.result === ADD_DEPARTMENT_ADDRESS_FAILURE) {
                    dispatch(sendErrorToastAction("Department address could not be added. Reason(s) : " + actionResult.message));
                }
            }

            if (actionResult.type === SAVE_DEPARTMENT_ADDRESS_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_DEPARTMENT_ADDRESS_SUCCESS) {
                    setShowAddressForm(false)
                    dispatch(sendSuccessToastAction("Department address has been successfully updated."));
                } else if (actionResult.result === SAVE_DEPARTMENT_ADDRESS_FAILURE) {
                    dispatch(sendErrorToastAction("Department address could not be updated. Reason(s) : " + actionResult.message));
                }
            }

            if (actionResult.type === DELETE_DEPARTMENT_ADDRESS_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === DELETE_DEPARTMENT_ADDRESS_SUCCESS) {
                    dispatch(sendSuccessToastAction("Department address has been successfully deleted."));
                } else if (actionResult.result === DELETE_DEPARTMENT_ADDRESS_FAILURE) {
                    dispatch(sendErrorToastAction("Department address could not be deleted."));
                }
            }
        }
    }, [actionResult]);  

    const addressTypeFormatter = (cell: any, address: Address) => {
        return _.startCase(_.camelCase(cell));
    }

    const actionsFormatter = (cell: any, address: Address) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-address-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item onSelect={() => { setModalTitle("Edit Address"); setAddress(address); setShowAddressForm(true); }}>
                            Edit Address
                        </Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => dispatch(deleteDepartmentAddressAction(address.msbId!, actionToken))}>
                            Delete Address
                        </Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    function doToolbar() {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => { setModalTitle("Add Address"); setAddress(undefined); setShowAddressForm(true); }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Address
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        },
        {
            dataField: 'addressReferenceName',
            text: 'Address reference name',
            editable: false
        }, {
            dataField: 'addressType',
            text: 'Address type',
            editable: false,
            formatter: addressTypeFormatter
        }, {
            dataField: 'msbId',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
        }
    ];

    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                <Modal size="lg" show={showAddressForm} onHide={() => setShowAddressForm(false)}>
                    <Modal.Header closeButton />
                        <Modal.Body>
                            <h2>{modalTitle}</h2>
                            <DepartmentAddressForm
                                isSaving={isSaving}
                                isFetching={isFetching}
                                client={client}
                                department={department}
                                address={address}
                                actionToken={actionToken}
                                formActionProps={{
                                    hasSubmit: true,
                                    isEditMode: address ? true : false,
                                    onCancel: () => setShowAddressForm(false),
                                    customProps: {
                                        submitButtonDisplay: address ? "Save" : "Add"
                                    }
                                }}
                                />
                        </Modal.Body>
                </Modal>

                <FormHeader title="Department Addresses" description="Please enter the department's addresses." />

                <div className="manage-card">
                  <PageSectionContainer title="Manage Addresses" toolbar={doToolbar()}>
                      <Table 
                        keyField="msbId" 
                            columns={columns}
                            data={department.departmentAddresses}
                      />
                  </PageSectionContainer>
                </div>

                <div className="manage-footer">
                  <Button type="button" variant="link" onClick={() => setRedirect(true)} style={{ float: "right" }}>Cancel</Button>
                </div>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.clients.isSaving,
        isFetching: state.clients.isFetching,
        client: state.clients.client,
        department: state.clients.department,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(DepartmentAddressSection);