import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import RequiredIcon from "../../../components/RequiredIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BusinessTypeEnum, Client, Department, MerchantProcessor, PaymentChannel, SubMerchantAcceptedCardTypeEnum } from "../../../models/Client";
import { PaymentCardTypeEnum } from "../../../models/Payment";
import { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { PaymentCardType } from "../../../utils/binLookup";
import { sendErrorToastAction } from "../../../redux/actions/toast";
import { useDispatch } from "react-redux";
import * as React from "react";
import { isInvalidCardExpiration } from "../../../utils/globalCalculations";

interface ICreditCardPaymentManual {
    isFetching: boolean,
    isSaving: boolean,
    //client: Client,
    //department: Department|undefined,
    paymentChannel?: PaymentChannel;
    paymentCardType: PaymentCardTypeEnum|undefined,
    selectPaymentCardType: any,
    isVisible:boolean
}

const CreditCardPaymentManual = ({ isFetching, isSaving, paymentChannel, paymentCardType, selectPaymentCardType, isVisible }: ICreditCardPaymentManual) => {
    const dispatch = useDispatch();
    const [showCardNumber, setShowCardNumber] = useState(false);
    const [showCVV, setShowCVV] = useState(false);
    const [cardType, setCardType] = useState("");
    const [cardTypeEnum, setCardTypeEnum] = useState<PaymentCardTypeEnum>(0);
    const [cardTypeInvalid, setCardTypeInvalid] = useState(false);
    const [cardExpirationInvalid, setCardExpirationInvalid] = useState<boolean>(false);
    const [cardExpirationMessage, setCardExpirationMessage] = useState<string>('');
    const [invalidCardType, setInvalidCardType] = useState<string>('');
    const cardNumber = useRef<HTMLInputElement | null>(null);
    const previousCardNumber = useRef<string>("");

    useEffect(() => {
        if (!isVisible) {
            setCardType("");
            setCardTypeInvalid(false);
        }
    }, [isVisible]);

    const doBinLookup = async (val: any) => {
        if (val.length >= 8) {
            PaymentCardType(val).then(result => {
                switch (result) {
                        case PaymentCardTypeEnum.AmericanExpressCredit:
                            if (cardTypeEnum != PaymentCardTypeEnum.AmericanExpressCredit) {
                                setCardTypeEnum(PaymentCardTypeEnum.AmericanExpressCredit);
                                setCardType("American Express");
                                if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
                                    setCardTypeInvalid(false);
                                } else {
                                    setInvalidCardType("American Express is not accepted");
                                    setCardTypeInvalid(true);
                                }
                            }
                            break;
                        case PaymentCardTypeEnum.AmericanExpressDebit:
                            if (cardTypeEnum != PaymentCardTypeEnum.AmericanExpressDebit) {
                                setCardTypeEnum(PaymentCardTypeEnum.AmericanExpressDebit);
                                setCardType("American Express - Debit");
                                if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
                                    setCardTypeInvalid(false);
                                } else {
                                    setInvalidCardType("American Express - Debit is not accepted");
                                    setCardTypeInvalid(true);
                                }
                            }
                            break;
                        case PaymentCardTypeEnum.DiscoverCredit:
                            if (cardTypeEnum != PaymentCardTypeEnum.DiscoverCredit) {
                                setCardTypeEnum(PaymentCardTypeEnum.DiscoverCredit);
                                setCardType("Discover");
                                if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
                                    setCardTypeInvalid(false);
                                } else {
                                    setInvalidCardType("Discover is not accepted");
                                    setCardTypeInvalid(true);
                                }
                            }
                            break;
                        case PaymentCardTypeEnum.DiscoverDebit:
                            if (cardTypeEnum != PaymentCardTypeEnum.DiscoverDebit) {
                                setCardTypeEnum(PaymentCardTypeEnum.DiscoverDebit);
                                setCardType("Discover - Debit");
                                if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
                                    setCardTypeInvalid(false);
                                } else {
                                    setInvalidCardType("Discover - Debit is not accepted");
                                    setCardTypeInvalid(true);
                                }
                            }
                            break;
                        case PaymentCardTypeEnum.MastercardCredit:
                            if (cardTypeEnum != PaymentCardTypeEnum.MastercardCredit) {
                                setCardTypeEnum(PaymentCardTypeEnum.MastercardCredit);
                                setCardType("Mastercard");
                                if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
                                    setCardTypeInvalid(false);
                                } else {
                                    setInvalidCardType("Mastercard is not accepted");
                                    setCardTypeInvalid(true);
                                }
                            }
                            break;
                        case PaymentCardTypeEnum.MastercardDebit:
                            if (cardTypeEnum != PaymentCardTypeEnum.MastercardDebit) {
                                setCardTypeEnum(PaymentCardTypeEnum.MastercardDebit);
                                setCardType("Mastercard - Debit");
                                if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
                                    setCardTypeInvalid(false);
                                } else {
                                    setInvalidCardType("Mastercard - Debit is not accepted");
                                    setCardTypeInvalid(true);
                                }
                            }
                            break;
                        case PaymentCardTypeEnum.VisaCredit:
                            if (cardTypeEnum != PaymentCardTypeEnum.VisaCredit) {
                                setCardTypeEnum(PaymentCardTypeEnum.VisaCredit);
                                setCardType("Visa");
                                if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
                                    setCardTypeInvalid(false);
                                } else {
                                    setInvalidCardType("Visa is not accepted");
                                    setCardTypeInvalid(true);
                                }
                            }
                            break;
                        case PaymentCardTypeEnum.VisaDebit:
                            if (cardTypeEnum != PaymentCardTypeEnum.VisaDebit) {
                                setCardTypeEnum(PaymentCardTypeEnum.VisaDebit);
                                setCardType("Visa - Debit");
                                if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
                                    setCardTypeInvalid(false);
                                } else {
                                    setInvalidCardType("Visa - Debit is not accepted");
                                    setCardTypeInvalid(true);
                                }
                            }
                            break;
                        default:
                            setCardType("Other");
                            setCardTypeEnum(PaymentCardTypeEnum.Unknown);
                            setCardTypeInvalid(true);
                            break;
                    }                
            });
        } else {
            setCardType("");
            setCardTypeEnum(PaymentCardTypeEnum.Unknown);
            setCardTypeInvalid(false);
        }
    };

    const handleOnlyNumeric = (e: any) => {
        if (e.key === " " || isNaN(e.key)) {
            e.preventDefault();
        }
    };

    const handleOnChangeCardNumber = (e: any) => {
        let val = e.target.value;
        if (val.length >= 8) {
            if (!cardType || previousCardNumber.current.substring(0, 7) !== cardNumber?.current?.value?.substring(0, 7)) {
                doBinLookup(val);
                previousCardNumber.current = val;
            }
        } else {
            setCardType("");
            setCardTypeEnum(PaymentCardTypeEnum.Unknown);
            setCardTypeInvalid(false);
        }
    };

    const handleOnPasteCardNumber = (e: any) => {
        let val = e.clipboardData.getData("text");
        if (val.length >= 8) {
            doBinLookup(val);
        } else {
            setCardType("");
            setCardTypeEnum(0);
            setCardTypeInvalid(false);
        }
    };

    const handleOnBlurCardName = (e: any) => {
        if (cardNumber.current) {
            let val = cardNumber.current.value;
            if (val.length >= 8 && !cardType) doBinLookup(val);
        }
    };

    const handleCardTypeChange = (e: any) => {
        if (!e.currentTarget.contains(e.relatedTarget as Node)) {
            if (!cardTypeInvalid) {
                selectPaymentCardType(cardTypeEnum);
            } else {
                selectPaymentCardType(undefined);
            }
        }
    };

    const checkCardExpiry = (e: any) => {
        const cardExpiry = e.target.value;
        const checkDateFormat = /^[0-9\/\s]{5}$/
        const isValid = checkDateFormat.test(e.target.value)
        if (isValid) {
            const checkValidation = isInvalidCardExpiration(cardExpiry)
            if (!checkValidation) {
                setCardExpirationInvalid(false)
            } else {
                setCardExpirationInvalid(true)
                setCardExpirationMessage(checkValidation)
            }
        }
    }

    if (isVisible) {
        return (
            <div onBlur={handleCardTypeChange}>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="nameOnCard">
                            <Form.Label><RequiredIcon />Cardholder name</Form.Label>
                            <Form.Control type="input" pattern="^([a-zA-z\s'-])*$" required disabled={isSaving} maxLength={50} placeholder="Enter Cardholder name" onBlur={handleOnBlurCardName} />
                            <Form.Control.Feedback type="invalid">Please enter a valid Cardholder's name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="cardType">
                            <Form.Label><RequiredIcon />Card type</Form.Label>
                            <Form.Control type="input" required disabled={isSaving} value={cardType} placeholder="Displayed upon card number entry" isInvalid={cardTypeInvalid} readOnly tabIndex={-1}></Form.Control>
                            <Form.Control.Feedback type="invalid">Please use an accepted Card type. {invalidCardType}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="cardNumber">
                            <Form.Label><RequiredIcon />Card number</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control className="password fs-mask" pattern="^\d*$" type={showCardNumber ? "text" : "password"} placeholder="Enter Card number" onKeyPress={(e: any) => handleOnlyNumeric(e)} required disabled={isSaving} minLength={12} maxLength={16} onChange={handleOnChangeCardNumber} onPaste={handleOnPasteCardNumber} ref={cardNumber} />
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setShowCardNumber(!showCardNumber)}>
                                        <FontAwesomeIcon icon={showCardNumber ? faEyeSlash : faEye} size="sm" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">Please enter a valid 12-16 digit long Card number.</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} sm={6}>
                        <Form.Group controlId="expirationDate">
                            <Form.Label><RequiredIcon />Expiration date</Form.Label>
                            <InputMask id="expirationDate" name="expirationDate" placeholder="MM/YY" required type="input" onChange={checkCardExpiry} pattern="[0-9\/\s]{5}" mask="99/99" className={cardExpirationInvalid ? "customValidation fs-mask" :"form-control fs-mask"} disabled={isSaving} />
                            <Form.Control.Feedback type="invalid">Please enter the Expiration date</Form.Control.Feedback>
                        </Form.Group>
                        {cardExpirationInvalid &&
                            <Form.Group controlId="expirationDateMasked">
                                <Form.Control type="input" className="fs-mask" isInvalid={cardExpirationInvalid} style={{ display: 'none' }} required={cardExpirationInvalid} />
                                <Form.Control.Feedback type="invalid"> {cardExpirationMessage}</Form.Control.Feedback>
                            </Form.Group>
                        }
                    </Col>
                    <Col md={3} sm={6}>
                        <Form.Group controlId="cvv">
                            <Form.Label><RequiredIcon />CVV</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control className="password fs-mask" pattern="^\d*$" type={showCVV ? "text" : "password"} placeholder="CVV" required disabled={isSaving} minLength={cardTypeEnum == PaymentCardTypeEnum.AmericanExpressCredit || cardTypeEnum == PaymentCardTypeEnum.AmericanExpressDebit ? 4 : 3} maxLength={cardTypeEnum == PaymentCardTypeEnum.AmericanExpressCredit || cardTypeEnum == PaymentCardTypeEnum.AmericanExpressDebit ? 4 : 3} onKeyPress={(e: any) => handleOnlyNumeric(e)} />
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setShowCVV(!showCVV)}>
                                        <FontAwesomeIcon icon={showCVV ? faEyeSlash : faEye} size="sm" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">Please enter the CVV number.</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        )
    } else {
        return (<></>)
    }
}

export default CreditCardPaymentManual;

