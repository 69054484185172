import React, { useState } from "react";
import { shoppingCartFormSchema } from './components/AdvancedSearchColumns';
import Report from './components/Report'
import { DateFormatter } from '../../components/Formatters';
import { Redirect } from 'react-router-dom';
import { Routes } from "../../routes";
import PageSectionContainer from "../../components/layout/PageSectionContainer";

const ShoppingCartReport = () => {
    const [redirect, setRedirect] = useState<string>("");
    const reportTitle = 'Shopping Cart Report';
    const reportType = 'Shopping Cart';
    const transactionType = 0;

    const viewDetailsFormatter = (cell: any, row: any) => {
        return (
            <span><a onClick={() => setRedirect(Routes.ViewDetails.path)}>View Details</a></span>
        )
    }

    const totalAmountFormatter = (cell: any, row: any) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const formattedAmount = formatter.format(cell);
        return (
            row.transactionType == 'Payment' ?
                <span>{formattedAmount}</span>
                :
                <span style={{ color: '#F5222D' }}>{formattedAmount}</span>
        )
    }

    const columns = [
        {
            dataField: 'clientName',
            text: 'Client',
            editable: false,
            sort: true
        },
        {
            dataField: 'departmentName',
            text: 'Department',
            editable: false
        },
        {
            dataField: 'transactionId',
            text: 'Transaction ID',
            editable: false
        }, {
            dataField: 'transactionType',
            text: 'Transaction type',
            editable: false
        }, {
            dataField: 'transactionDate',
            text: 'Transaction date',
            editable: false,
            formatter: DateFormatter
        }, {
            dataField: 'transactionReference',
            text: 'Transaction reference',
            editable: false,
        },
        {
            dataField: 'customerName',
            text: 'Customer name',
            editable: false,
        },
        {
            dataField: 'totalAmount',
            text: 'Total amount',
            editable: false,
            formatter: totalAmountFormatter
        },
        {
            dataField: '',
            text: 'Actions',
            editable: false,
            formatter: viewDetailsFormatter
        }
    ];


    const rowStyle = (row: any) => {
        const style = { backgroundColor: '' };
        if (row.transactionType == 'Chargeback') {
            style.backgroundColor = '#E6F7FD';
        } else if (row.transactionType == 'Void') {
            style.backgroundColor = '#FEFFE6';
        }
        else if (row.transactionType == 'Refund') {
            style.backgroundColor = '#F6FFED';
        }
        return style;
    };

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <PageSectionContainer title={`Shopping cart report under construction.`} ></PageSectionContainer>
                {/*
                <Report
                    reportTitle={reportTitle}
                    reportType={reportType}
                    transactionType={transactionType}
                    columns={columns} rowStyle={rowStyle} formSchema={shoppingCartFormSchema} />
                */}
            </>
        );
    }
};

export default ShoppingCartReport;

