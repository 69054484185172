import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, ButtonToolbar, Card, Col, Container, Form, Row, Spinner, Modal, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LabelValue, User } from '../../models/User';
import moment from 'moment';

import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { Client } from '../../models/Client';

import { getClientsAction } from '../../redux/actions/clients/clients';
import { IActionResult, IAppState } from '../../redux/storeTypes';
import { Crumb } from "../../models/Crumb";
import PageHeader from "../../components/layout/PageHeader";
import { Routes } from "../../routes";
import { faArrowToBottom, faFileExport } from '@fortawesome/pro-regular-svg-icons';
import { 
    CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS, CLEAR_DOCUMENT , createInvoiceTransactionsReportExcel, createInvoiceReportExcelAllClients 
} from '../../redux/actions/document';
import { sendErrorToastAction } from "../../redux/actions/toast";
import { Redirect } from "react-router-dom";
import RequiredIcon from '../../components/RequiredIcon';

export interface InvoiceReportProps {
    isFetching: boolean,
    clients: Array<Client>,
    documentActionResult: IActionResult,
    blobObject?: Blob,
}

const InvoiceReport = ({ isFetching, clients, documentActionResult, blobObject }: InvoiceReportProps) => {
    const actionToken = "getInvoiceReport";
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
  
    const defaultStartDate = moment().subtract(1, "day").format('YYYY-MM-DD');
    const defaultEndDate = moment().subtract(1, "day").format('YYYY-MM-DD');
    const [defaultClientId, setDefaultClientId] = useState<string>("");    
    const [defaultDepartmentId, setDefaultDepartmentId] = useState<string>("");
    const [defaultClientDepartment, setDefaultClientDepartment] = useState<string>("0");

    const [startDate, setStartDate] = useState<string>(defaultStartDate);
    const [endDate, setEndDate] = useState<string>(defaultEndDate);
    const [clientId, setClientId] = useState<string>(defaultClientId);    
    const [departmentId, setDepartmentId] = useState<string>(defaultDepartmentId);
    const [clientName, setClientName] = useState<string>("");    
    const [departmentName, setDepartmentName] = useState<string>("");
    const [waitingForDownload, setWaitingForDownload] = useState<boolean>(false);
    const actionTokenCreateExcel = "InvoiceReport";
    const availableClients : Array<LabelValue> = [];

    useEffect(() => {
        if (!clients) {
            dispatch(getClientsAction(1, 100));
        }        
    }, [clients]);


    if (clients != null) {
        const availableClientDeprtments = clients.map(c => ( c.departments.map(d => ({
                    label: c.businessName + "_" + d.name,
                    value: c.msbId!! + "_" + d.msbId!!
                    }))
        )).filter(x => x.length > 0);


        availableClientDeprtments.map(x => { x.forEach((i) => { 
                    availableClients.push(new LabelValue(i.label, i.value));
                    }
                )
            }                            
        )
    }

    useEffect(() => {        
        setClientDepartment();
    }, []);

    useEffect(() => {        
        if (documentActionResult && documentActionResult.result) {
            if (documentActionResult.type === CREATE_DOCUMENT_REQUEST && documentActionResult.token === actionTokenCreateExcel) {
                if (documentActionResult.result === CREATE_DOCUMENT_SUCCESS && !!blobObject) {
                    let fileName = "InvoiceReport_" + `${startDate}-${endDate}`;
                } else if (documentActionResult.result === CREATE_DOCUMENT_FAILURE) {
                    dispatch(sendErrorToastAction("Excel creation failed"));
                }
                setWaitingForDownload(false);
            }
        }

        dispatch({ type: CLEAR_DOCUMENT });
    }, [documentActionResult]);

    const setClientDepartment = () => {
        if (clients != null) {
            if(availableClients.length > 0) {   

            setDefaultClientId(availableClients[0].value.split("_")[0]);
            setDefaultDepartmentId(availableClients[0].value.split("_")[1]);            

            setClientId(availableClients[0].value.split("_")[0]);
            setDepartmentId(availableClients[0].value.split("_")[1]);

            setClientName(availableClients[0].label.split("_")[0]);
            setDepartmentName(availableClients[0].label.split("_")[1]);

            setDefaultClientDepartment(availableClients[0].value);
            }
        }
    }

    const resetSearch = (e: any) => {
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setDefaultClientDepartment("0");

        if(availableClients.length > 0) {   
            setDefaultClientDepartment(availableClients[0].value);
        }
    };

    const createInvoiceForALLClients = (e: any) => {
        if(availableClients && availableClients.length > 0){   

            dispatch(createInvoiceReportExcelAllClients(startDate, endDate, actionTokenCreateExcel));
        }   
        else {
            dispatch(sendErrorToastAction("Excel creation failed. Failed to load client department."));
        }
    };
 
    const handleInputChange = (evt : any) => {
        if(evt !== null && evt.target !== null) {
        
            setClientId(evt.target.value.split("_")[0]);
            setDepartmentId(evt.target.value.split("_")[1]);

            setClientName(evt.target[evt.target.selectedIndex].text.split("_")[0]);
            setDepartmentName(evt.target[evt.target.selectedIndex].text.split("_")[1]);
        }
    }
    
    const createInvoiceReportExcel = (evt: any) =>{

        if(availableClients && availableClients.length > 0){ 
            
            if(clientId === "" && departmentId === "") {
                setClientDepartment();
            }

            dispatch(createInvoiceTransactionsReportExcel(startDate, endDate, clientId, departmentId, clientName, departmentName ,actionTokenCreateExcel));
            setWaitingForDownload(true);
        }
        else {
            dispatch(sendErrorToastAction("Excel creation failed. Failed to load client department."));
            setWaitingForDownload(false);
        }
    }

    const doToolbar = () => {
        if (!isFetching) {
            return (
                <div className="flexContainer">
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle className="approvalAction" variant="outline-secondary" disabled={waitingForDownload}>
                                { waitingForDownload?
                                    <Spinner animation="border" />
                                    :
                                    <FontAwesomeIcon icon={faArrowToBottom} size="sm" style={{ marginRight: '8px' }} />
                                } Download Summary
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    <>
                                    <Dropdown.Item onClick={(e) => {createInvoiceReportExcel(e)}}> Excel File </Dropdown.Item>
                                    </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            )
        } else {
            return (<></>);
        }
    }

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Invoice Report", Routes.InvoiceReport.path));

    const renderClientDepartment = () => (                          
        availableClients?.length > 0 && availableClients?.map((_row, idx) => ( 
            <>
                <option key={idx} value={availableClients[idx].value}>{availableClients[idx].label}</option>
            </>
            )
        )
    );

    if (redirect != "") return <Redirect push to={redirect} />;
    
    return (
        <>
            <PageHeader title={`Invoice Report`} crumbs={crumbs} />

            <Container fluid className="container-table-search">
              <PageSectionContainer>
                  <Form className="table-search advanced-search" onSubmit={createInvoiceReportExcel}>
                      <Row>
                          <Col xl={4} lg={6} md={6} sm={12}>
                              <Form.Group controlId="startDate">
                                  <Form.Label>Start date</Form.Label>
                                  <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                              </Form.Group>
                          </Col>
                          <Col xl={4} lg={6} md={6} sm={12}>
                              <Form.Group controlId="endDate">
                                  <Form.Label>End date</Form.Label>
                                  <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                              </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="clientDepartmentId">
                                <Form.Label>Client/Department</Form.Label>                                
                                <Form.Control required as="select" onChange={e => handleInputChange(e)} defaultValue={defaultClientDepartment}>
                                    {/* <option value="0">Select</option>   */}
                                    {renderClientDepartment()}                                    
                                </Form.Control>                                
                            </Form.Group>
                          </Col>
                      </Row>
                      <Row>     
                          <Col>
                          <Form.Group controlId="reset">   
                                <ButtonToolbar className="ms-auto">
                                    <Button variant="outline-secondary" onClick={e => resetSearch(e)}>Reset</Button>  
                                    {doToolbar()}
                                    <Button variant="outline-secondary" onClick={e => createInvoiceForALLClients(e)}><FontAwesomeIcon icon={faFileExport} size="sm" style={{ marginRight: '8px' }} />Save All</Button>                                     
                                </ButtonToolbar>  
                          </Form.Group>
                          </Col>
                      </Row>
                  </Form>
              </PageSectionContainer>
            </Container>            
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        clients: state.clients.currentPage?.data,
        isFetching: state.reports.isFetching,
        blobObject: state.document.blobObject,
        documentActionResult: state.document.actionResult
    };
};

export default connect(mapStateToProps)(InvoiceReport);