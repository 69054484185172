import React, { useEffect } from "react";
import PageHeader from '../../../components/layout/PageHeader';
import ViewDetails from '../components/ViewDetails';
import { TransactionDetails } from '../../../models/Payment';
import { IAppState } from '../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import { Crumb } from "../../../models/Crumb";
import { Routes } from "../../../routes";
import { ClientName, DepartmentName, PaymentChannelName } from "../../../models/Client";
import { getClientNamesAction } from "../../../redux/actions/clients/clients";
import { getDepartmentNamesAction } from "../../../redux/actions/clients/departments";
import { Spinner } from "react-bootstrap";
import { getAllPaymentChannelNamesAction } from "../../../redux/actions/clients/paymentChannels";

interface IVoidPaymentDetails {
    transactionDetail: TransactionDetails;
    clientNames: Array<ClientName>;
    departmentNames: Array<DepartmentName>;
    paymentChannelNames: Array<PaymentChannelName>;
}

const VoidPaymentDetails = ({ transactionDetail, clientNames, departmentNames, paymentChannelNames }: IVoidPaymentDetails) => {
    const dispatch = useDispatch();
    const actionToken = "VoidPaymentDetails";
    const action = "Void";
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Void Payment", Routes.VoidPayment.path));
    crumbs.push(new Crumb(`${transactionDetail?.orderIdentifier} Details`, Routes.VoidPaymentDetails.path));

    useEffect(() => {
        if (!clientNames) {
           dispatch(getClientNamesAction(actionToken));
        }
    }, [clientNames]);

    useEffect(() => {
        if (!departmentNames) {
            dispatch(getDepartmentNamesAction(actionToken));
        }
    }, [departmentNames]);

    useEffect(() => {
        if (!paymentChannelNames) {
            dispatch(getAllPaymentChannelNamesAction(actionToken));
        }
    }, [paymentChannelNames]);

    if (transactionDetail && clientNames && departmentNames && paymentChannelNames) {
        return (
            <>
                <PageHeader title={`${transactionDetail?.orderIdentifier} Details`} crumbs={crumbs} />
                <ViewDetails details={transactionDetail} transactionType={action} clientNames={clientNames} departmentNames={departmentNames} paymentChannelNames={paymentChannelNames}/>
            </>
        );
    }
    else {
        return (
            <>
                 <PageHeader title={`Transaction Details`} crumbs={crumbs}/>
                 <Spinner animation="border" />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        transactionDetail: state.paymentTransactions.transactionDetail,
        clientNames: state.clients.clientNames,
        departmentNames: state.clients.departmentNames,
        paymentChannelNames: state.clients.paymentChannelNames
    };
};

export default connect(mapStateToProps)(VoidPaymentDetails);