import { Row, Col, Button, Modal, Form } from "react-bootstrap"
import Table from '../../../../../../components/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

interface ILarimerShoppingCart {
    title: string,
    subTitle: string,
    citationNumberLabel: string,
    fullNameLabel: string,
    issueDateLabel: string,
    amountDueLabel: string,
    actionsLabel: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string
}

export const TITLE = 'Shopping Cart';
export const SUBTITLE = ''
export const CITATION_NUMBER_LABEL = 'Citation Number';
export const FULL_NAME_LABEL = 'Name'
export const ISSUE_DATE_LABEL = 'Issue Date';
export const AMOUNT_DUE_LABEL = 'Amount';
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';
export const CART_BUTTON_FONT_COLOR = '#414141';
export const CART_BUTTON_FONT_SIZE = '.875rem';
export const CART_BUTTON_FONT_STYLE = 'normal';
export const CART_BUTTON_FONT_WEIGHT = 'normal';
export const CART_BUTTON_BORDER_COLOR = '#D9D9D9';
export const CART_BUTTON_BACKGROUND_COLOR = '#FFFFFF';
export const CART_ICON_COLOR = '#414141';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

const LarimerShoppingCart = ({ title, subTitle, citationNumberLabel, fullNameLabel, issueDateLabel, amountDueLabel, actionsLabel,
    margin, padding, headerBackgroundColor, headerPadding, cartButtonBackgroundColor, cartButtonFontColor, cartButtonFontSize, cartButtonFontStyle, cartButtonFontWeight, cartButtonBorderColor, cartIconColor }: ILarimerShoppingCart) => {

    if (!title) { title = TITLE }
    if (!subTitle) { subTitle = SUBTITLE }
    if (!citationNumberLabel) { citationNumberLabel = CITATION_NUMBER_LABEL }
    if (!fullNameLabel) { fullNameLabel = FULL_NAME_LABEL }
    if (!issueDateLabel) { issueDateLabel = ISSUE_DATE_LABEL }
    if (!amountDueLabel) { amountDueLabel = AMOUNT_DUE_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }
    if (!cartButtonBackgroundColor) { cartButtonBackgroundColor = CART_BUTTON_BACKGROUND_COLOR }
    if (!cartButtonFontColor) { cartButtonFontColor = CART_BUTTON_FONT_COLOR }
    if (!cartButtonFontSize) { cartButtonFontSize = CART_BUTTON_FONT_SIZE }
    if (!cartButtonFontStyle) { cartButtonFontStyle = CART_BUTTON_FONT_STYLE }
    if (!cartButtonFontWeight) { cartButtonFontWeight = CART_BUTTON_FONT_WEIGHT }
    if (!cartButtonBorderColor) { cartButtonBorderColor = CART_BUTTON_BORDER_COLOR }
    if (!cartIconColor) { cartIconColor = CART_ICON_COLOR }


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const [showModal, setShowModal] = useState<boolean>(false);

    const initialColumns = [{
        dataField: 'citationNumber',
        text: citationNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: false
    }, {
        dataField: 'fullName',
        text: fullNameLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'issueDate',
        text: issueDateLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'amountDue',
        text: amountDueLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
        formatter: amountFormatter
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const renderTable = () => {
        return (
            <div style={{ marginTop: '12px' }}>
                <Table
                    keyField="msbId"
                    data={[]}
                    columns={initialColumns}
                />
            </div>);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    <Button className="brand-btn-primary" style={{
                        marginTop: '29px',
                        backgroundColor: checkStyleForDefault(cartButtonBackgroundColor, CART_BUTTON_BACKGROUND_COLOR),
                        borderColor: checkStyleForDefault(cartButtonBorderColor, CART_BUTTON_BORDER_COLOR),
                        color: checkStyleForDefault(cartButtonFontColor, CART_BUTTON_FONT_COLOR),
                        fontSize: checkStyleForDefault(cartButtonFontSize, CART_BUTTON_FONT_SIZE),
                        fontStyle: checkStyleForDefault(cartButtonFontStyle, CART_BUTTON_FONT_STYLE),
                        fontWeight: checkStyleForDefault3(cartButtonFontWeight)
                    }} onClick={() => setShowModal(true)}>
                        <FontAwesomeIcon icon={faCartPlus} style={{ color: checkStyleForDefault(cartIconColor, CART_ICON_COLOR) }} className="btn-icon" />{`View Cart (count)`}
                    </Button>
                </Col>
            </Row>
            <Modal show={showModal} size={'xl'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form.Group>
                        {renderTable()}
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default LarimerShoppingCart;