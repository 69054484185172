import {
    SEND_EMAIL_REQUEST,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAILURE,
} from '../actions/emails';

export default function emails(state = {
    isFetching: false,
    actionToken: null
}, { type, payload }) {
    switch (type) {
        case SEND_EMAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: SEND_EMAIL_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SEND_EMAIL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                gilaSettings: payload.gilaSettings,
                actionResult: { type: SEND_EMAIL_REQUEST, result: SEND_EMAIL_SUCCESS, token: payload.actionToken } ,
            });
        case SEND_EMAIL_FAILURE:
            {
                let message = payload.error.response.data.message;
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: SEND_EMAIL_REQUEST, result: SEND_EMAIL_FAILURE, token: payload.actionToken, message: message },
                    errorMessage: payload.error
                });
            }
        default:
            return state;
    }
}
