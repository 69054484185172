import axios from 'axios';
import { useEffect, useState } from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

interface GuidesNotesProps {
    onClose: () => void,
}

const GuidesAndNotes = ({ onClose }: GuidesNotesProps) => {

    const [contentValue, setContentValue] = useState() as any;
    const [loading, isLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                isLoading(true)
                const response = await axios.get('MSB_Client/api/v1/WebApplications/bySubdomain/ReleaseNotes');
                const data = response.data;

                const contentIds = data?.webPages
                    .filter((item: any) => item.webPageType === 5)
                    .map((item: any) => item.contentId);

                const detailsPromises = contentIds.map(async (contentId: any) => {
                    const detailResponse = await axios.get(`MSB_CMS/api/v1/Contents/${contentId}`);
                    return detailResponse.data;
                });

                const details = await Promise.all(detailsPromises) as any;

                setContentValue(details[0]?.revisions[0]?.value);
                isLoading(false)
            } catch (error) {
                isLoading(false)
                console.error('Error fetching release data:', error);
            }
        };

        fetchData();
    }, []);

    const releasesData = contentValue ? JSON.parse(contentValue) : null;

    const findPluginById = (data: any, pluginId: string): any => {
        if (Array.isArray(data)) {
            return data?.flatMap(item => findPluginById(item, pluginId)).find(Boolean) || null;
        }
        if (data && typeof data === 'object') {
            if (data?.plugin?.id === pluginId) return data;
            return Object.values(data).flatMap(value => findPluginById(value, pluginId)).find(Boolean) || null;
        }
        return null;
    };

    const releaseCard = findPluginById(releasesData?.rows, "releaseCardPlugin");

    const releaseDetails = releaseCard?.rows || [];

    const ReleaseCard = ({ title, description }: { title: string; description: JSX.Element }) => (
        <Card className='userGuideCard mb-3'>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
            </Card.Body>
        </Card>
    );

    const combinedReleaseDetails = () => {
        let combinedTitle = "Latest Release";
        let combinedDescription: JSX.Element[] = [];

        releaseDetails.forEach((detail: any) => {
            const slateCell = detail?.cells?.find((cell: any) => cell.plugin?.id === "ory/editor/core/content/slate");

            const slateItems = slateCell?.dataI18n?.default?.slate || [];

            slateItems.forEach((item: any) => {
                if (item.type && item.type.startsWith("HEADINGS/")) {
                    const headingText = item?.children?.[0]?.text;
                    if (headingText) {
                        combinedDescription.push(<h3 key={headingText}><strong>{headingText}</strong></h3>);
                    }
                } else if (item.type === "LISTS/UNORDERED-LIST") {
                    item?.children?.forEach((child: any) => {
                        const itemText = child?.children?.map((grandChild: any, idx: number) => {
                            const strong = grandChild["EMPHASIZE/STRONG"] ? <strong key={idx}>{grandChild?.text}</strong> : grandChild?.text;
                            return <span key={idx}>{strong}</span>;
                        }) || [];
                        combinedDescription.push(<div key={child?.id}>{itemText}</div>);
                    });
                } else {
                    const fallbackText = item?.children?.map((child: any, idx: number) => {
                        return <span key={idx}>{child?.text || 'No content available'}</span>;
                    }) || <span>No content available</span>;
            
                    combinedDescription.push(<div key={item.id || 'unknown'}>{fallbackText}</div>);
                }
            });
        });

        return (
            <ReleaseCard title={combinedTitle} description={<> {combinedDescription} </>} />
        );
    };

    const baseUrl = window.location.origin;

    return (
        <>
            <Card>
                <Card.Body>
                    <h2>Releases:</h2>
                    <div className='userGuideContainer'>
                        {loading ? <Spinner animation="border" className="mb-1" /> : combinedReleaseDetails()}
                    </div>
                    <div>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="text-right">
                                <a
                                    href="https://payments.msbpay.navient.com/ReleaseNotes/Landing"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-decoration-none"
                                >
                                    View All Releases
                                </a>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>

                    <h2>User Guide:</h2>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <a
                                href={`${baseUrl}/User_Guide.pdf`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none"
                            >
                                View/Download User Guide
                            </a>
                        </ListGroup.Item>
                    </ListGroup>

                    <h2>Supported Links:</h2>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <a href={`${baseUrl}/Privacy_Policy.pdf`} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                Privacy Policy
                            </a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={`${baseUrl}/Payment_Terms_And_Conditions.pdf`} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                Payment Terms And Conditions
                            </a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={`${baseUrl}/Terms_Of_Use.pdf`} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                Terms Of Use
                            </a>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Card>
            <div>
                <Button style={{ float: 'right' }} variant="link" onClick={onClose}>
                    Close
                </Button>
            </div>
        </>
    );
};

export default GuidesAndNotes;
