export class WebAppSettings  {
    environment: string = "";
    wsmBearerToken: string = "";
    wsmBaseUrl: string = "";
    b2cRedirectUri: string = "";
    b2cClientId: string = "";
    b2cAuthority: string = "";
    siteKey: string = "";
    wsmRefreshMinutes?: number
}
