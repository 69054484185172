import { Order } from '../../../models/Payment';

interface orderProps {
    details: any,
    methodName: string,
    amount: number,
    convenienceFee: number,
    totalAmount: number,
    reason: string,
    chargebackDueDate?: string,
    laneId?: number
}


export const handleOrders = (orderDetails: orderProps) => {
   const filteredOrderLines = orderDetails.details.orderLines.map(({createdAt, id,msbId, ...remainingOrderLinesAttrs}:any) => remainingOrderLinesAttrs)

    let order = new Order();

    order.clientMsbId = orderDetails.details.clientId;
    order.departmentMsbId = orderDetails.details.departmentId;
    order.paymentChannelMsbId = orderDetails.details.paymentChannelId;
    order.orderIdentifier = orderDetails.details.orderIdentifier;
    order.paymentType = orderDetails.details.paymentType;
    order.methodName = orderDetails.methodName
    order.amount = orderDetails.amount;
    order.convenienceFee = orderDetails.convenienceFee;
    order.totalAmount = orderDetails.totalAmount;
    order.emailAddress = orderDetails.details.email;
    order.phone = orderDetails.details.phone;
    order.addressLine1 = orderDetails.details.addressLine1;
    order.zip = orderDetails.details.zip;
    order.city = orderDetails.details.city;
    order.country = orderDetails.details.country;
    order.state = orderDetails.details.state;
    order.initiatedBy = orderDetails.details.initiatedBy;
    order.isCardPresent = (orderDetails.details.orderPayment.processorType === 'VantivTriPos');
    order.laneId = (orderDetails?.laneId) ? orderDetails.laneId : orderDetails.details.laneId;
    order.isMailOrTelephoneOrder = orderDetails.details.isMailOrTelephoneOrder;
    order.orderLines = filteredOrderLines;
    order.metadata = orderDetails.details.metadata;
    order.reason = orderDetails.reason;
    order.previousTransactionId = orderDetails.details.msbId;
    order.nameOnCard = orderDetails.details?.nameOnCard;
    order.chargebackDueDate = orderDetails.chargebackDueDate;

    return order
}






