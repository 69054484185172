import * as React from 'react';
import _ from "lodash";
import { IAppState } from '../redux/storeTypes';
import { connect } from "react-redux";

interface permissionProps {
    children?: JSX.Element,
    permission: string[],
    userPermissions?: string[]
}

export const Permissions = {
    ManageClientsDepartmentsPaymentChannelsCreate: "ManageClientsDepartmentsPaymentChannels.Create",
    ManageClientsDepartmentsPaymentChannelsRead: "ManageClientsDepartmentsPaymentChannels.Read",
    ManageClientsDepartmentsPaymentChannelsUpdate: "ManageClientsDepartmentsPaymentChannels.Update",
    ManageClientsDepartmentsPaymentChannelsDelete: "ManageClientsDepartmentsPaymentChannels.Delete",
    ManageNavientInternalUserCreate: "ManageNavientInternalUser.Create",
    ManageNavientInternalUserRead: "ManageNavientInternalUser.Read",
    ManageNavientInternalUserUpdate: "ManageNavientInternalUser.Update",
    ManageNavientInternalUserDelete: "ManageNavientInternalUser.Delete",
    ChargebacksExecute: "Chargebacks.Execute",
    DeclinedExecute: "Declined.Execute",
    DepositsExecute: "Deposits.Execute",
    EmafExecute: "Emaf.Execute",
    ExceptionsExecute: "Exceptions.Execute",
    FundingExecute: "Funding.Execute",
    AllTransactionExecute: "AllTransactions.Execute",
    AuthorizationsExecute: "Authorizations.Execute",
    PaymentsExecute: "Payments.Execute",
    ManagePMoFExecute: "ManagePaymentMethodOnFile.Execute",
    PaymentChannelsExecute: "PaymentChannels.Execute",
    PendingPaymentsExecute: "PendingPayments.Execute",
    ReconciliationsExecute: "Reconciliations.Execute",
    RefundsExecute: "Refunds.Execute",
    ShoppingCartExecute: "ShoppingCart.Execute",
    TrendingDataExecute: "TrendingData.Execute",
    UserActivityExecute: "UserActivity.Execute",
    VoidsExecute: "Voids.Execute",
    HardwareStatusExecute: "HardwareStatus.Execute",
    ChargebackExecute: "Chargeback.Execute",
    ClientUserManagementExecute: "ClientUserManagement.Execute",
    VoidAPaymentExecute: "VoidAPayment.Execute",
    RefundAPaymentExecute: "RefundAPayment.Execute",
    AddPMoFExecute: "AddPaymentMethodOnFile.Execute",
    ProcessTransactionsExecute: "ProcessTransactions.Execute",
    FinanceReportsExecute: "FinanceReports.Execute",
    AuditReportsExecute: "AuditReports.Execute",
    GilaSettingsExecute: "GilaSettings.Execute",
    ClientBankingApprovalsExecute: "ClientBankingApprovals.Execute",
    ClientBankingRead: "ClientBanking.Read",
    ClientBankingCreate: "ClientBanking.Create",
    ClientBankingUpdate: "ClientBanking.Update",
    ClientBankingDelete: "ClientBanking.Delete"    
}


const PermissionModule = ({ children, permission, userPermissions }: permissionProps) => {


    const couldShow = userPermissions?.some((userPermissionName: string) => permission.includes(userPermissionName));

    if (!couldShow) return <></>

    return <>{children}</>

}




const mapStateToProps = (state: IAppState) => {
    return {
        userPermissions: state.auth.userPermissions
    };
};


export default connect(mapStateToProps)(PermissionModule);

