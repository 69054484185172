import {
    GET_GILA_SETTINGS_REQUEST,
    GET_GILA_SETTINGS_SUCCESS,
    GET_GILA_SETTINGS_FAILURE,
    SAVE_GILA_SETTINGS_REQUEST,
    SAVE_GILA_SETTINGS_SUCCESS,
    SAVE_GILA_SETTINGS_FAILURE,
    CLEAR_GILA_SETTINGS_ACTION_RESULT

} from '../actions/gilaSettings';

export default function gilaSettings(state = {
    isFetching: false,
    isSaving: false,
    gilaSettings: null,
    actionToken: null
}, { type, payload }) {
    switch (type) {
        case GET_GILA_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_GILA_SETTINGS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_GILA_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                gilaSettings: payload.gilaSettings,
                actionResult: { type: GET_GILA_SETTINGS_REQUEST, result: GET_GILA_SETTINGS_SUCCESS, token: payload.actionToken } ,
            });
        case GET_GILA_SETTINGS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_GILA_SETTINGS_REQUEST, result: GET_GILA_SETTINGS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_GILA_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_GILA_SETTINGS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_GILA_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_GILA_SETTINGS_REQUEST, result: SAVE_GILA_SETTINGS_SUCCESS, token: payload.actionToken } ,
                gilaSettings: payload.gilaSettings
            });
        case SAVE_GILA_SETTINGS_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_GILA_SETTINGS_REQUEST, result: SAVE_GILA_SETTINGS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case CLEAR_GILA_SETTINGS_ACTION_RESULT:
            return Object.assign({}, state, {
                actionResult: null
            });
        default:
            return state;
    }
}
