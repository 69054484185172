import React, { useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import RequiredIcon from '../../../../components/RequiredIcon';
import { StateDropdown } from '../../../../components/StateDropdown';
import { AddressTypeEnum, Address, Client } from '../../../../models/Client';
import { addClientAddressAction, saveClientAddressAction } from '../../../../redux/actions/clients/address';
import FormActions, { IFormActionProps } from './FormActions';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

export interface IClientAddressFormProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    address?: Address,
    actionToken: string,
    formActionProps?: IFormActionProps,
}

const ClientAddressForm = ({ isSaving, isFetching, client, address, actionToken, formActionProps }: IClientAddressFormProps) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            let _address: Address = Object.assign({}, address!!);
            _address.addressReferenceName = form.elements.addressReferenceName.value;
            _address.addressType = AddressTypeEnum[form.elements.addressTypeEnum.value] as keyof typeof AddressTypeEnum;
            _address.addressLine1 = form.elements.addressLine1.value;
            _address.addressLine2 = form.elements.addressLine2.value;
            _address.city = form.elements.city.value;
            _address.state = form.elements.state.value;
            _address.zipCode = form.elements.zipCode.value;

            if (formActionProps?.customProps.submitButtonDisplay == 'Add') {
                dispatch(addClientAddressAction(client.msbId!, _address, actionToken));
            } else {
                dispatch(saveClientAddressAction(_address, actionToken));
            }
        }

        setValidated(true);
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="addressReferenceName">
                        <Form.Label><RequiredIcon />Address reference name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={40} defaultValue={address?.addressReferenceName} placeholder="Enter Address reference name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter an Address reference name.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="addressTypeEnum">
                        <Form.Label><RequiredIcon />Address type</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required as="select" defaultValue={AddressTypeEnum[address?.addressType as keyof typeof AddressTypeEnum]}>
                                <option value={AddressTypeEnum.Physical}>Physical</option>
                                <option value={AddressTypeEnum.Mailing}>Mailing</option>
                                <option value={AddressTypeEnum.TerminalDeployment}>Terminal Deployment</option>
                            </Form.Control>
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select an Address type.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="addressLine1">
                        <Form.Label><RequiredIcon />Address line 1</Form.Label>
                        <Form.Control required type="input" maxLength={40} defaultValue={address?.addressLine1}  placeholder="Enter Address line 1" />
                        <Form.Control.Feedback type="invalid">Please enter an Address.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="addressLine2">
                        <Form.Label>Address line 2</Form.Label>
                        <Form.Control type="input" maxLength={40} defaultValue={address?.addressLine2}  placeholder="Enter Address line 2" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="city">
                        <Form.Label><RequiredIcon />City</Form.Label>
                        <Form.Control required type="input" maxLength={28} defaultValue={address?.city} placeholder="Enter City" />
                        <Form.Control.Feedback type="invalid">Please enter a City.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group controlId="state">
                        <Form.Label><RequiredIcon />State</Form.Label>
                        <StateDropdown defaultValue={address?.state} required></StateDropdown>
                        <Form.Control.Feedback type="invalid">Please select a State.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group controlId="zipCode">
                        <Form.Label><RequiredIcon />Zip code</Form.Label>
                        <InputMask id="zipCode" name="zipCode" required type="input" pattern="[0-9]{5}" mask="99999" className="form-control" defaultValue={address?.zipCode}  placeholder="Enter Zip code" />
                        <Form.Control.Feedback type="invalid">Please enter a Zip code.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <FormActions
                    disabled={isSaving || isFetching}
                    showSpinner={isFetching}
                    {...formActionProps} />
            </Form>
        </>
    );
};

export default ClientAddressForm;
