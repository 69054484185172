import { 
    CREATE_DOCUMENT_REQUEST,
    CREATE_DOCUMENT_SUCCESS,
    CREATE_DOCUMENT_FAILURE,
    CLEAR_DOCUMENT
} from '../actions/document';

export default function document(state = {
    isCreating: false,
    blobObject: Blob,
    actionResult: null,
    errorMessage: ''
}, { type, payload }) {
    switch (type) {
        case CREATE_DOCUMENT_REQUEST:
            return Object.assign({}, state, {
                isCreating: true,
                blobObject: undefined,
                actionResult: { type: CREATE_DOCUMENT_REQUEST, token: payload.actionToken, result: null },
            });
        case CREATE_DOCUMENT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                blobObject: payload.blobObject,
                actionResult: { type: CREATE_DOCUMENT_REQUEST, token: payload.actionToken, result: CREATE_DOCUMENT_SUCCESS }
            });
        case CREATE_DOCUMENT_FAILURE:
            return Object.assign({}, state, {
                isCreating: false,
                errorMessage: payload.error,
                actionResult: { type: CREATE_DOCUMENT_REQUEST, token: payload.actionToken, result: CREATE_DOCUMENT_FAILURE }
            });
        case CLEAR_DOCUMENT:
            return Object.assign({}, state, {
                isCreating: false,
                blobObject: undefined,
                errorMessage: '',
                actionResult: null
            });
        default:
            return state;
    }
}
