import React from "react";
import { connect, useDispatch } from "react-redux";
import { IRoleTabsContainer } from '../RoleTabsContainer';
import { UserTypeEnum } from '../../../../../models/User'
import { IAppState } from '../../../../../redux/storeTypes';
import ActionTabs from '../../../accessGlobalComponents/ActionTabs'
import _ from "lodash";

const ReformatedArray = (role: any, modulePermissions: any, tag: any) => {

    const roleDetails = role?.roleModulePermissions?.map((fields: any) => fields?.modulePermission)

    const reformatedArray = _.isEmpty(roleDetails)
        ?
        modulePermissions.map((fields: any) => ({ ...fields, selected: false }))
        :
        modulePermissions.map((basePermissions: any) => ({
            ...basePermissions,
            selected: roleDetails.some((selectedPermissions: any) => selectedPermissions.description === basePermissions.description && selectedPermissions.crudPermission === basePermissions.crudPermission),
        }))

    const permissions = _.groupBy(reformatedArray.filter((permissions: any) => permissions.tag === tag), 'description')

    return permissions
}



const CatalogManagement = ({ role, modulePermissions }: IRoleTabsContainer) => {

    if (modulePermissions) {

        const reformatedArray = ReformatedArray(role, modulePermissions, 'Catalog Management')
        return (
            <>
                <div className="roleGrids">
                    {Object.entries(reformatedArray).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index}>
                                    <h3>{index}</h3>
                                    <ActionTabs navientRole={role} crudPermissions={value} roleType={UserTypeEnum.Client} />
                                </div>
                            )
                        }
                    )}
                </div>
            </>
        );
    }
    else {
        return (
            <></>
        )
    }
}



const DepositManagement = ({ role, modulePermissions }: IRoleTabsContainer) => {
    if (modulePermissions) {

        const reformatedArray = ReformatedArray(role, modulePermissions, 'Deposit Management')
        return (
            <>
                <div className="roleGrids">
                    {Object.entries(reformatedArray).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index}>
                                    <h3>{index}</h3>
                                    <ActionTabs navientRole={role} crudPermissions={value} roleType={UserTypeEnum.Client} />
                                </div>
                            )
                        }
                    )}
                </div>
            </>
        );
    }
    else {
        return (
            <></>
        )
    }
};



const HardwareManagement = ({ role, modulePermissions }: IRoleTabsContainer) => {
    if (modulePermissions) {

        const reformatedArray = ReformatedArray(role, modulePermissions, 'Hardware Management')
        return (
            <>
                <div className="roleGrids">
                    {Object.entries(reformatedArray).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index}>
                                    <h3>{index}</h3>
                                    <ActionTabs navientRole={role} crudPermissions={value} roleType={UserTypeEnum.Client} />
                                </div>
                            )
                        }
                    )}
                </div>
            </>
        );
    }
    else {
        return (
            <></>
        )
    }
};

const ReportManagement = ({ role, modulePermissions }: IRoleTabsContainer) => {
    if (modulePermissions) {

        const reformatedArray = ReformatedArray(role, modulePermissions, 'Report Management')
        return (
            <>
                <div className="roleGrids">
                    {Object.entries(reformatedArray).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index}>
                                    <h3>{index}</h3>
                                    <ActionTabs navientRole={role} crudPermissions={value} roleType={UserTypeEnum.Client} />
                                </div>
                            )
                        }
                    )}
                </div>
            </>
        );
    }
    else {
        return (
            <></>
        )
    }
};

const SiteManagement = ({ role, modulePermissions }: IRoleTabsContainer) => {
    if (modulePermissions) {

        const reformatedArray = ReformatedArray(role, modulePermissions, 'Site Management')
        return (
            <>
                <div className="roleGrids">
                    {Object.entries(reformatedArray).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index}>
                                    <h3>{index}</h3>
                                    <ActionTabs navientRole={role} crudPermissions={value} roleType={UserTypeEnum.Client}/>
                                </div>
                            )
                        }
                    )}
                </div>
            </>
        );
    }
    else {
        return (
            <></>
        )
    }
};

const TransactionManagement = ({ role, modulePermissions }: IRoleTabsContainer) => {
  
    if (modulePermissions) {
     
        const reformatedArray = ReformatedArray(role, modulePermissions, 'Payments & Transactions Management')

        return (
            <>
                <div className="roleGrids">
                    {Object.entries(reformatedArray).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index}>
                                    <h3>{index}</h3>
                                    <ActionTabs navientRole={role} crudPermissions={value} roleType={UserTypeEnum.Client} />
                                </div>
                            )
                        }
                    )}
                </div>
            </>
        );
    }
    else {
        return (
            <></>
        )
    }
};

const UserManagement = ({ role, modulePermissions }: IRoleTabsContainer) => {
    if (modulePermissions) {

        const reformatedArray = ReformatedArray(role, modulePermissions, 'User Management')
        return (
            <>
                <div className="roleGrids">
                    {Object.entries(reformatedArray).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index}>
                                    <h3>{index}</h3>
                                    <ActionTabs navientRole={role} crudPermissions={value} roleType={UserTypeEnum.Client} />
                                </div>
                            )
                        }
                    )}
                </div>
            </>
        );
    }
    else {
        return (
            <></>
        )
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        role: state.roles.role,
        modulePermissions: state.roles.modulePermissions,

    };
};

export default {
    CatalogManagement: connect(mapStateToProps)(CatalogManagement),
    DepositManagement: connect(mapStateToProps)(DepositManagement),
    HardwareManagement: connect(mapStateToProps)(HardwareManagement),
    ReportManagement: connect(mapStateToProps)(ReportManagement),
    SiteManagement: connect(mapStateToProps)(SiteManagement),
    TransactionManagement: connect(mapStateToProps)(TransactionManagement),
    UserManagement: connect(mapStateToProps)(UserManagement)
}