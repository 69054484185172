import { CellPlugin } from '@react-page/editor';
import { Col, Form, Row } from 'react-bootstrap';

type Data = {
    title: string,
    state: string,
    email: string,
}

const PMoFNotificationPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <div>
            <Row>
                <Col>
                    <Form.Group style={{ textAlign: "center", margin: "10px" }}>
                        <Form.Text style={{ fontSize: "24px", fontWeight: "bold" }}>{data.title}</Form.Text><br/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group style={{ textAlign: "justify", margin: "10px" }}>
                        <Form.Text style={{ fontSize: "18px" }}>{data.state}</Form.Text><br />
                        <Form.Text style={{ fontSize: "18px" }}>{data.email}</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId={`pmof_${"method_detail"}`} style={{ textAlign: "center", margin: "5px" }}>
                        <Form.Text style={{ fontSize: "18px" }}>PMof Details</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    ),
    id: 'formPMoFNotificationPlugin',
    title: 'PMoF Notification',
    description: 'PMof Notification',
    version: 1,
    controls: {
        type: 'autoform',
        schema: {
            properties: {
                title: {
                    type: 'string',
                    default: 'Payment Method on File Successful'
                },
                state: {
                    type: 'string',
                    default: 'The PMoF is currently in an INACTIVE state'
                },
                email: {
                    type: 'string',
                    default: "An email has been sent to the customer's provided email address to obtain authorization for using their payment method on file for future transactions."
                },
            },
            required: ['title', 'state', 'email'],
        },
    },
};

export default PMoFNotificationPlugin;