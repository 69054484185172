import * as React from "react";
import { Form, Button, Spinner } from 'react-bootstrap';
import PermissionModule, { Permissions } from '../../../../components/usePermissions';

export interface IFormActionProps {
    showSpinner?: boolean;
    onPrevious?(): void;
    hasSubmit?: boolean;
    onCancel?(): void;
    onEdit?(): void;
    onClose?(): void;
    isEditMode?: boolean;
    customProps?: any;
    disabled?: boolean;
}

const FormActions = ({ onPrevious, hasSubmit, onCancel, showSpinner, onEdit, customProps, isEditMode, disabled, onClose }: IFormActionProps) => {

    function renderSpinner() {
        if (showSpinner) {
            return (<Spinner animation="border" className="margin-left-3" />)
        } else {
            return (<></>)
        }
    }

    function renderPrevious() {
        if (onPrevious) {
            return (<Button type="button" className="margin-right-2" variant="outline-secondary" onClick={onPrevious}>Previous</Button>)
        } else {
            return (<></>)
        }
    }

    function renderSubmit() {
        const submitButtonDisplay = (customProps && customProps.submitButtonDisplay) || "Submit";
        if (hasSubmit) {

            return (
                isEditMode ?
                    <PermissionModule
                        permission={[Permissions.ManageClientsDepartmentsPaymentChannelsUpdate]}
                    >
                        <Button type="submit" disabled={disabled}>{submitButtonDisplay}</Button>
                    </PermissionModule>
                    :
                    <Button type="submit" disabled={disabled}>{submitButtonDisplay}</Button>
            )
        } else {
            return (<></>)
        }
    }

    function renderEdit() {
        if (onEdit) {
            return (<Button type="button" variant="link" style={{ marginLeft: 'auto' }} onClick={onEdit}>Edit</Button>)
        } else {
            return (<></>)
        }
    }

    function renderCancel() {
        if (onCancel) {
            return (<Button type="button" variant="link" style={{ marginLeft: 'auto' }} onClick={onCancel}>Cancel</Button>)
        } else {
            return (<></>)
        }
    }

    function renderClose() {
        if (onClose) {
            return (<Button type="button" variant="primary" style={{ marginRight: 'auto' }} onClick={onClose}>Close</Button>)
        } else {
            return (<></>)
        }
    }

    return (
        <Form.Row className="form-footer">
            {renderPrevious()}
            {renderSubmit()}
            {renderSpinner()}
            {renderEdit()}
            {renderCancel()}
            {renderClose()}
        </Form.Row>
    );
};

export default FormActions;