export const MerchantManagementTabsHeaders = [
    {
        title: 'Details',
        key: 'manage_details',
    },
    /*
    {
        title: 'Addresses',
        key: 'manage_addresses',
    },
    {
        title: 'Contacts',
        key: 'manage_contacts',
    },
    {
        title: 'Settlements',
        key: 'manage_settlements',
    },   
    {
        title: 'Accounts',
        key: 'manage_accounting',
    },
    */
    {
        title: 'Terminals',
        key: 'manage_devices',
    },
    {
        title: 'Value Added Services',
        key: 'manage_VAS',
    },
]