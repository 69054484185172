
import React from "react";
import { Navbar, Container, Button } from 'react-bootstrap';
import ToastContainer from "../../../../components/ToastContainer";
import { IAppState } from '../../../../redux/storeTypes';
import { connect } from "react-redux";

interface IEditorPageHeaderProps {
    isSaving: boolean,
    isFetching: boolean,
    onClose: any,
    onSave: any,
    cmsFetching?: boolean,
    cmsSaving?: boolean,
}


const EditorPageHeader = ({isSaving, isFetching, onClose, onSave, cmsFetching, cmsSaving}:IEditorPageHeaderProps) => {
    return (
      <Navbar variant="dark" expanded className="ps-0 p-2 margin-left-0 margin-right-0 cms-editor-nav">
        <Container fluid className="px-0 mt-2">
        <ToastContainer />
          <div className="d-flex w-100" style={{justifyContent: "flex-end"}}>
            <Button variant="outline-secondary" disabled={isSaving || isFetching|| cmsFetching|| cmsSaving} className="margin-right-2" size="sm" onClick={() => onClose()}>Close Editor</Button>
            <Button variant="primary" size="sm" disabled={isSaving || isFetching|| cmsFetching|| cmsSaving} onClick={() => onSave()}>Save Layout</Button>
          </div>
        </Container>
      </Navbar>
  );
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.clients.isSaving,
        isFetching: state.clients.isFetching,
        cmsFetching: state.cms.isFetching,
        cmsSaving: state.cms.isSaving,
    };
};

export default connect(mapStateToProps)(EditorPageHeader);

