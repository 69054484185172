import { Row, Col, Form, Table } from "react-bootstrap"
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface ITylerEaglePaymentReference {
    itemReferenceLabel: string,
    descriptionLabel: string,
    amountLabel: string,
    itemReference: string,
    description: string,
    amount: number,
    headerBackgroundColor: string
}

export const ITEM_REFERENCE_LABEL = 'Item Reference';
export const DESCRIPTION_LABEL = 'Description';
export const AMOUNT_LABEL = 'Amount';
export const HEADER_BACKGROUND_COLOR = '#FAFAFA';
export const HEADER_PADDING= '0';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
    return style && style !== ''?style:defaultStyle;
  }

const TylerEaglePaymentReference = ({ itemReferenceLabel, descriptionLabel, amountLabel, itemReference, description, amount, headerBackgroundColor }: ITylerEaglePaymentReference) => {

    if (!itemReferenceLabel) {
        itemReferenceLabel = ITEM_REFERENCE_LABEL;
    }

    if (!descriptionLabel) {
        descriptionLabel = DESCRIPTION_LABEL;
    }

    if (!amountLabel) {
        amountLabel = AMOUNT_LABEL;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <>
        <Row>
            <Col>
              <PerfectScrollbar>
                <Table responsive bordered={true} className="table-payment-method">
                    <thead>
                        <tr>
                            <th style={{backgroundColor:checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)}}>{itemReferenceLabel}</th>
                            <th style={{backgroundColor:checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)}}>{descriptionLabel}</th>
                            <th style={{backgroundColor:checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)}}>{amountLabel}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{itemReference}</td>
                            <td>{description}</td>
                            <td>{formatter.format(amount)}</td>
                        </tr>
                    </tbody>
                   
                </Table>
              </PerfectScrollbar>
            </Col>
        </Row>
      </>
    )
}

export default TylerEaglePaymentReference;