import { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import Table from "../../components/Table";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { Crumb } from "../../models/Crumb";
import PageHeader from "../../components/layout/PageHeader";
import { Button, ButtonToolbar, Col, Container, Dropdown, Form, Modal, Row, Spinner } from "react-bootstrap";
import PageSectionContainer from "../../components/layout/PageSectionContainer";
import PermissionModule, { Permissions } from "../../components/usePermissions";
import axios from "axios";
import { AccessPermissionType, AuthorizationStatus, BankAccount, Card, PermissionLink, TokenizeCard, WalletType } from "../../models/Wallets";
import { linkCardAction, linkBankAccountAction, unlinkCardAction, unlinkBankAccountAction, LINK_CARD_REQUEST, LINK_CARD_SUCCESS, LINK_CARD_FAILURE, LINK_BANKACCOUNT_REQUEST, LINK_BANKACCOUNT_SUCCESS, LINK_BANKACCOUNT_FAILURE, UNLINK_CARD_SUCCESS, UNLINK_CARD_REQUEST, UNLINK_CARD_FAILURE, UNLINK_BANKACCOUNT_REQUEST, UNLINK_BANKACCOUNT_SUCCESS, UNLINK_BANKACCOUNT_FAILURE, cancelBankAccountAction, cancelCardAction, CANCEL_CARD_REQUEST, CANCEL_CARD_SUCCESS, CANCEL_CARD_FAILURE, CANCEL_BANKACCOUNT_REQUEST, CANCEL_BANKACCOUNT_SUCCESS, CANCEL_BANKACCOUNT_FAILURE, clearAction, updateAddressCardAction, UPDATE_ADDRESS_CARD_REQUEST, UPDATE_ADDRESS_CARD_FAILURE, UPDATE_ADDRESS_CARD_SUCCESS, UPDATE_ADDRESS_BANKACCOUNT_REQUEST, UPDATE_ADDRESS_BANKACCOUNT_SUCCESS, UPDATE_ADDRESS_BANKACCOUNT_FAILURE, UPDATE_REISSUE_CARD_REQUEST, UPDATE_REISSUE_CARD_SUCCESS, UPDATE_REISSUE_CARD_FAILURE, updateReissueCardAction, RESEND_AGREEMENT_REQUEST, RESEND_AGREEMENT_SUCCESS, RESEND_AGREEMENT_FAILURE, resendAgreementAction } from "../../redux/actions/wallets";
import { sendErrorToastAction, sendSuccessToastAction } from "../../redux/actions/toast";
import { Client, PaymentChannelTypeEnum } from "../../models/Client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PaymentMethodOnFile from "../payments/PaymentMethodOnFile";
import { User } from "../../models/User";
import _ from "lodash";
import { NIL as NIL_UUID } from 'uuid';
import InputMask from "react-input-mask";
import { isInvalidCardExpiration } from "../../utils/globalCalculations";
import RequiredIcon from "../../components/RequiredIcon";
import { PaymentCardTypeEnum } from "../../models/Payment";

interface IPMoFManage {
    currentUser: User;
    client: Client;
    actionResult: IActionResult;
}

const PMoFManage = ({ currentUser, client, actionResult }: IPMoFManage) => {
    const dispatch = useDispatch();
    const actionToken = "PMoFManage";
    const [redirect, setRedirect] = useState<string>("");
    const [showModal, setShowModal] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [showUpdateAddressModal, setShowUpdateAddressModal] = useState(false);
    const [showUpdateReissueModal, setShowUpdateReissueModal] = useState(false);
    const [showAddPMoFModal, setShowAddPMoFModal] = useState(false);
    const [clients, setClients] = useState<Array<any>>(new Array<any>());
    const [accounts, setAccounts] = useState<Array<BankAccount>>(new Array<BankAccount>());
    const [accountsPreload, setAccountsPreload] = useState<Array<BankAccount>>(new Array<BankAccount>());
    const [cards, setCards] = useState<Array<Card>>(new Array<Card>());
    const [cardsPreload, setCardsPreload] = useState<Array<Card>>(new Array<Card>());
    const [links, setLinks] = useState<Array<PermissionLink>>(new Array<PermissionLink>());
    const [clientName, setClientName] = useState("");
    const [clientId, setClientId] = useState("");
    const [methodId, setMethodId] = useState("");
    const [methodMsbId, setMethodMsbId] = useState("");
    const [methodType, setMethodType] = useState("");
    const [search, setSearch] = useState("");
    const [references, setReferences] = useState<Array<any>>(new Array<any>());
    const [cardNumberKey, setCardNumberKey] = useState<any>(Math.random());
    const currentMethod = useRef<any>({});

    const title = 'Manage Payment Method on File';

    const updateCards = (paymentChannelIds: any) => {
        paymentChannelIds.forEach((paymentChannelId: string) => {
            if (paymentChannelId) {
                axios.get(`/MSB_Wallet/api/v1/Cards/cardsOnFileForPaymentChannels?paymentChannelIds=${paymentChannelId}`)
                    .then(function (response) {
                        if (response.data.length) {
                            response.data.forEach((data: Card) => {
                                if (data.accessPermission.permissionLinks.length) {
                                    const link = _.maxBy(data.accessPermission.permissionLinks, (link) => link.createdAt);
                                    if (link && link.resourceId !== NIL_UUID && link.authorizationStatus !== AuthorizationStatus[AuthorizationStatus.AuthorizationCancelled]) {
                                        data.accessPermission.permissionLinks = [...[], link];
                                    } else {
                                        data.accessPermission.permissionLinks = [...[]];
                                    }
                                }
                                if (data.accessPermission.permissionLinks.length) {
                                    setCardsPreload(previous => [...previous, data]);
                                }
                            });
                        }
                    })
                    .catch(function (error) { });
            }
        });
    }

    const updateAccounts = (paymentChannelIds: any) => {
        paymentChannelIds.forEach((paymentChannelId: string) => {
            if (paymentChannelId) {
                axios.get(`/MSB_Wallet/api/v1/BankAccounts/bankAccountsOnFileForPaymentChannels?paymentChannelIds=${paymentChannelId}`)
                    .then(function (response) {
                        if (response.data.length) {
                            response.data.forEach((data: BankAccount) => {
                                data.accessPermission.permissionLinks = data.accessPermission.permissionLinks.filter(link => (link.resourceId));
                                if (data.accessPermission.permissionLinks.length) {
                                    setAccountsPreload(previous => [...previous, data]);
                                }
                            });
                        }
                    })
                    .catch(function (error) { });
            }
        });
    }

    useEffect(() => {
        let _cards = cardsPreload.reduce((a: Array<Card>, c: Card) => {
            let e = a.find(i => i.msbId === c.msbId);
            if (e) {
                e.accessPermission.permissionLinks.push(c.accessPermission.permissionLinks[0]);
                e.accessPermission.permissionLinks = e.accessPermission.permissionLinks.filter((l, i) => i === e?.accessPermission.permissionLinks.findIndex(m => m.resourceId === l.resourceId));
            } else {
                let cardType = c.internalCardType;
                if (c.internalCardType.substring(0, 3) !== c.vantivCardType.substring(0, 3) || c.vantivCardType.includes("Debit")) {
                    cardType = c.vantivCardType.includes("Debit") ? c.vantivCardType : `${c.vantivCardType}Credit`;
                    cardType = cardType.replace("Amex", "AmericanExpress");
                }
                c.internalCardType = cardType;
                a.push(c);
            }
            return a;
        }, []);
        setCards(_cards);
    }, [cardsPreload]);

    useEffect(() => {
        let _accounts = accountsPreload.reduce((a: Array<BankAccount>, c: BankAccount) => {
            let e = a.find(i => i.msbId === c.msbId);
            if (e) {
                e.accessPermission.permissionLinks.push(c.accessPermission.permissionLinks[0]);
                e.accessPermission.permissionLinks = e.accessPermission.permissionLinks.filter((l, i) => i === e?.accessPermission.permissionLinks.findIndex(m => m.resourceId === l.resourceId));
            } else {
                a.push(c);
            }
            return a;
        }, []);
        setAccounts(_accounts);
    }, [accountsPreload]);

    useEffect(() => {
        if (client) {
            if (!clients.some(c => c.Id === client.id)) setClients(previous => [...previous, { clientId: client.id, clientMsbId: client.msbId, clientName: client.businessName }]);
            // eslint-disable-next-line array-callback-return
            client.departments.map(department => {
                if (currentUser.userDepartments.find(d => d.departmentMSBId === department.msbId)) {
                    const paymentChannelIds = department?.paymentChannels!.map(paymentChannel => {
                        if (paymentChannel.paymentMethodOnFileEnabled && paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.PointOfSale]/* || paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.QuickPay] || paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.TerminalPay]*/) {
                            if (!references.some(r => r.paymentChannelId === paymentChannel.msbId)) setReferences(previous => [...previous, { clientId: department.clientId, departmentName: department.name, paymentChannelName: paymentChannel.name, paymentChannelId: paymentChannel.msbId }])
                            return paymentChannel.msbId;
                        }
                    });
                    updateCards(paymentChannelIds);
                    //updateAccounts(paymentChannelIds);
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    /*
    useEffect(() => {
        setClients([]);
        setReferences([]);
        currentUser.userClients.map(client => {
            return dispatch(getClientAction(client.clientMSBId, actionToken));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);
    */

    const updateMethods = () => {
        setCardsPreload([]);
        setAccountsPreload([]);
        const paymentChannelIds = references.map(reference => {
            return reference.paymentChannelId;
        });
        updateCards(paymentChannelIds);
        //updateAccounts(paymentChannelIds);
    }

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === LINK_CARD_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === LINK_CARD_SUCCESS) {
                    dispatch(sendSuccessToastAction("Card link/resend successful"));
                    setShowLinkModal(false);
                    setShowModal(false);
                    updateMethods();
                }
                if (actionResult.result === LINK_CARD_FAILURE) {
                    dispatch(sendErrorToastAction("Card link/resend failed : " + actionResult.message));
                }
            }

            if (actionResult.type === LINK_BANKACCOUNT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === LINK_BANKACCOUNT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Bank Account link/resend successful"));
                    setShowLinkModal(false);
                    setShowModal(false);
                    updateMethods();
                }
                if (actionResult.result === LINK_BANKACCOUNT_FAILURE) {
                    dispatch(sendErrorToastAction("Bank Account link/resend failed : " + actionResult.message));
                }
            }

            if (actionResult.type === UNLINK_CARD_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === UNLINK_CARD_SUCCESS) {
                    dispatch(sendSuccessToastAction("Card unlink/revoke successful"));
                    setShowLinkModal(false);
                    setShowModal(false);
                    updateMethods();
                }
                if (actionResult.result === UNLINK_CARD_FAILURE) {
                    dispatch(sendErrorToastAction("Card unlink/revoke failed : " + actionResult.message));
                }
            }

            if (actionResult.type === UNLINK_BANKACCOUNT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === UNLINK_BANKACCOUNT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Bank Account unlink/revoke successful"));
                    setShowLinkModal(false);
                    setShowModal(false);
                    updateMethods();
                }
                if (actionResult.result === UNLINK_BANKACCOUNT_FAILURE) {
                    dispatch(sendErrorToastAction("Bank Account unlink/revoke failed : " + actionResult.message));
                }
            }

            if (actionResult.type === CANCEL_CARD_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === CANCEL_CARD_SUCCESS) {
                    dispatch(sendSuccessToastAction("Card cancel successful"));
                    setShowLinkModal(false);
                    setShowModal(false);
                    updateMethods();
                }
                if (actionResult.result === CANCEL_CARD_FAILURE) {
                    dispatch(sendErrorToastAction("Card cancel failed : " + actionResult.message));
                }
            }

            if (actionResult.type === CANCEL_BANKACCOUNT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === CANCEL_BANKACCOUNT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Bank Account cancel successful"));
                    setShowLinkModal(false);
                    setShowModal(false);
                    updateMethods();
                }
                if (actionResult.result === CANCEL_BANKACCOUNT_FAILURE) {
                    dispatch(sendErrorToastAction("Bank Account cancel failed : " + actionResult.message));
                }
            }

            if (actionResult.type === UPDATE_ADDRESS_CARD_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === UPDATE_ADDRESS_CARD_SUCCESS) {
                    dispatch(sendSuccessToastAction("Card address update successful"));
                    setShowUpdateAddressModal(false);
                    updateMethods();
                }
                if (actionResult.result === UPDATE_ADDRESS_CARD_FAILURE) {
                    dispatch(sendErrorToastAction("Card address update failed : " + actionResult.message));
                }
            }

            if (actionResult.type === UPDATE_ADDRESS_BANKACCOUNT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === UPDATE_ADDRESS_BANKACCOUNT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Bank Account address update successful"));
                    setShowUpdateAddressModal(false);
                    updateMethods();
                }
                if (actionResult.result === UPDATE_ADDRESS_BANKACCOUNT_FAILURE) {
                    dispatch(sendErrorToastAction("Bank Account address update failed : " + actionResult.message));
                }
            }

            if (actionResult.type === UPDATE_REISSUE_CARD_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === UPDATE_REISSUE_CARD_SUCCESS) {
                    dispatch(sendSuccessToastAction("Card reissue update successful"));
                    setShowUpdateReissueModal(false);
                    updateMethods();
                }
                if (actionResult.result === UPDATE_REISSUE_CARD_FAILURE) {
                    dispatch(sendErrorToastAction("Card reissue update failed : " + actionResult.message));
                }
            }

            if (actionResult.type === RESEND_AGREEMENT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === RESEND_AGREEMENT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Resend agreement email successful"));
                }
                if (actionResult.result === RESEND_AGREEMENT_FAILURE) {
                    dispatch(sendErrorToastAction("Resend agreement email failed : " + actionResult.message));
                }
            }

            dispatch(clearAction(actionToken));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    const doToolbar = (type: string) => {
        return (
            <PermissionModule permission={[Permissions.AddPMoFExecute, Permissions.ManageClientsDepartmentsPaymentChannelsCreate ]}>
                <ButtonToolbar>
                    <Button onClick={() => setShowAddPMoFModal(true) }>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add {type}
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    const cardTypeFormatter = (cell: any, _row: any) => {
        return _.startCase(cell);
    }

    const accountNumberFormatter = (cell: any, _row: any) => {
        return cell.slice(-4);
    }

    const actionsFormatter = (cell: any, row: any, type: any) => {
        return (
                <Dropdown>
                    <Dropdown.Toggle variant="link">Manage</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onSelect={() => {
                            setLinks(row.accessPermission.permissionLinks);
                            setMethodType(type);
                            setMethodId(row.id);
                            setMethodMsbId(row.msbId);
                            setShowModal(true);
                        }}>View</Dropdown.Item>
                        <Dropdown.Item onSelect={() => {
                            currentMethod.current = row;
                            setMethodType(type);
                            setMethodMsbId(row.msbId);
                            setShowUpdateReissueModal(true);
                        }}>Update Expired Card</Dropdown.Item>
                        <Dropdown.Item onSelect={() => {
                            currentMethod.current = row;
                            setMethodType(type);
                            setMethodMsbId(row.msbId);
                            setShowUpdateAddressModal(true);
                        }}>Update Address for Card</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
        )
    }

    const columnsCC = [
        {
            dataField: 'msbId',
            text: 'MSB ID',
            editable: false,
            sort: false,
            hidden: true,
            configurable: false
        }, {
            dataField: 'nameOnCard',
            text: 'Customer Name',
            editable: false,
            sort: true,
            configurable: true
        }, {
            dataField: 'accessPermission.userIdentifier',
            text: 'Customer Email',
            editable: false,
            configurable: true
        }, {
            dataField: 'internalCardType',
            text: 'Card Type',
            editable: false,
            formatter: cardTypeFormatter,
            configurable: true
        }, {
            dataField: 'expirationDate',
            text: 'Expiry Date',
            editable: false,
            configurable: true
        }, {
            dataField: 'lastFourOnCard',
            text: 'Last four on Card',
            editable: false,
            configurable: true
        }, {
            dataField: 'zipCode',
            text: 'Zip Code',
            editable: false,
            configurable: true
        }, {
            dataField: '',
            text: 'Actions',
            editable: false,
            formatter: (cell: string, row: string) => {
                return actionsFormatter(cell, row, "Card")
            },
            configurable: false
        }
    ];

    const columnsACH = [
        {
            dataField: 'msbId',
            text: 'MSB ID',
            editable: false,
            sort: false,
            hidden: true,
            configurable: false
        }, {
            dataField: 'accountHolderName',
            text: 'Customer Name',
            editable: false,
            sort: true,
            configurable: true
        }, {
            dataField: 'accessPermission.userIdentifier',
            text: 'Customer Email',
            editable: false,
            configurable: true
        }, {
            dataField: 'bankAccountType',
            text: 'Account Type',
            editable: false,
            configurable: true
        }, {
            dataField: 'accountNumber',
            text: 'Last four on Account',
            editable: false,
            formatter: accountNumberFormatter,
            configurable: true
        }, {
            dataField: 'zipCode',
            text: 'Zip Code',
            editable: false,
            configurable: true
        }, {
            dataField: '',
            text: 'Actions',
            editable: false,
            formatter: (cell: string, row: string) => {
                return actionsFormatter(cell, row, "Account")
            },
            configurable: false
        }
    ];

    const handleModalClose = () => {
        setShowModal(false);
    }

    const handleLinkModalCancel = () => {
        setShowLinkModal(false);
    }

    const handleAddPMoFModalClose = () => {
        setShowAddPMoFModal(false);
        updateMethods();
    }

    const handleUpdateAddressModalCancel = () => {
        currentMethod.current = {};
        setShowUpdateAddressModal(false);
    }

    const handleUpdateAddressModalSave = () => {
        if (!document.forms[0].reportValidity()) return;
        const updateBody = {
            addressLine1: currentMethod.current.addressLine1,
            addressLine2: currentMethod.current.addressLine2,
            city: currentMethod.current.city,
            state: currentMethod.current.state,
            zipCode: currentMethod.current.zipCode
        }
        if (methodType === "Card") dispatch(updateAddressCardAction(methodMsbId, updateBody, actionToken));
        if (methodType === "Account") dispatch(updateAddressBankAccountAction(methodMsbId, updateBody, actionToken));
    }

    const handleUpdateReissueModalCancel = () => {
        currentMethod.current = {};
        setShowUpdateReissueModal(false);
    }

    const handleUpdateReissueModalSave = () => {
        if (!document.forms[0].reportValidity()) return;
        const _cardNumber = currentMethod?.current?.cardNumber?.replace(/\D/g, "") || "";
        if (!_cardNumber) return;
        const _card = new TokenizeCard();
        var fields = (currentMethod.current.expiryDate).split('/')
        _card.nameOnCard = currentMethod.current.nameOnCard;
        _card.cardNumber = _cardNumber;
        _card.expirationMonth = fields[0];
        _card.expirationYear = fields[1];
        _card.cvv = currentMethod.current.cvvNumber;
        _card.addressLine1 = currentMethod.current.addressLine1;
        _card.addressLine2 = currentMethod.current.addressLine2;
        _card.city = currentMethod.current.city;
        _card.state = currentMethod.current.state;
        _card.zipCode = currentMethod.current.zipCode;
        _card.cardHolderEmailAddress = currentMethod.current.emailAddress;
        _card.paymentChannelId = currentMethod.current.accessPermission.permissionLinks[0].resourceId;
        _card.walletType = WalletType.Personal;
        _card.internalCardType = currentMethod.current.internalCardType as PaymentCardTypeEnum;
        if (methodType === "Card") dispatch(updateReissueCardAction(methodMsbId, _card, currentMethod.current.lastFourOnCard, actionToken));
    }

    const revokePaymentChannel = (link: any) => {
        if (methodType === "Card") dispatch(unlinkCardAction(methodMsbId, link.paymentChannelId, actionToken));
        if (methodType === "Account") dispatch(unlinkBankAccountAction(methodMsbId, link.paymentChannelId, actionToken));
    }

    const cancelPaymentChannel = (link: any) => {
        if (methodType === "Card") dispatch(cancelCardAction(methodMsbId, link.paymentChannelId, actionToken));
        if (methodType === "Account") dispatch(cancelBankAccountAction(methodMsbId, link.paymentChannelId, actionToken));
    }

    const resendAuthorization = (link: any) => {
        if (methodType === "Card") dispatch(linkCardAction(methodId, link.paymentChannelId, AccessPermissionType.PaymentChannel, actionToken));
        if (methodType === "Account") dispatch(linkBankAccountAction(methodId, link.paymentChannelId, actionToken));
    }

    const resendAgreement = (link: any) => {
        dispatch(resendAgreementAction(methodMsbId, link.paymentChannelId, actionToken));
    }

    const linkPaymentChannel = (link: any) => {
        if (methodType === "Card") dispatch(linkCardAction(methodId, link.paymentChannelId, AccessPermissionType.PaymentChannel, actionToken));
        if (methodType === "Account") dispatch(linkBankAccountAction(methodId, link.paymentChannelId, actionToken));
    }

    const ListPaymentChannels = () => {
        const paymentChannels = references.filter(reference => reference.clientId === clientId && !links.some(link => link.resourceId === reference.paymentChannelId));
        const columns = [
            {
                dataField: 'paymentChannelId',
                text: 'Id',
                editable: false,
                sort: false,
                hidden: true,
                configurable: false
            }, {
                dataField: 'departmentName',
                text: 'Department',
                editable: false,
                configurable: true
            }, {
                dataField: 'paymentChannelName',
                text: 'Payment Channel',
                editable: false,
                configurable: true
            }, {
                dataField: '',
                text: 'Actions',
                editable: false,
                formatter: (cell: any, row: any) => {
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <span><a onClick={() => {
                            linkPaymentChannel(row)
                        }}>Link</a></span>
                    )
                },
                configurable: false
            }
        ];
        return (
            <>
                <Row>
                    <Col>
                        <div style={{ marginBottom: "10px" }}><b>Client : </b>{clientName}</div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table
                            keyField="Math.random()"
                            columns={columns}
                            data={paymentChannels}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    const Details = () => {
        const authorizationFormatter = (cell: any, _row: any) => {
            return cell.replace("Authorization", "");
        }
        const confirmed = links.some(link => !(link.authorizationStatus !== AuthorizationStatus[AuthorizationStatus.AuthorizationConfirmed] as keyof typeof AuthorizationStatus));
        const associations = links.map(link => {
            const reference = references.find(reference => link.resourceId === reference.paymentChannelId);
            setClientName(clients.find(c => reference.clientId === c.clientId).clientName);
            setClientId(reference.clientId);
            return { paymentChannelId: link.resourceId, departmentName: reference.departmentName, paymentChannelName: reference.paymentChannelName, authorization: link.authorizationStatus }
        });
        const linksFormatter = (cell: any, _row: any) => {
            return (
                <Dropdown>
                    <Dropdown.Toggle variant="link">Manage</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {_row.authorization === "AuthorizationConfirmed" &&
                            <PermissionModule permission={[Permissions.AddPMoFExecute, Permissions.ManageClientsDepartmentsPaymentChannelsCreate]}>
                                <Dropdown.Item onSelect={() => { revokePaymentChannel(_row) }}>Revoke Payment Channel</Dropdown.Item>
                            </PermissionModule>}
                        {_row.authorization === "PendingAuthorization" && <Dropdown.Item onSelect={() => { cancelPaymentChannel(_row) }}>Cancel Payment Channel</Dropdown.Item>}
                        {_row.authorization === "PendingAuthorization" && <Dropdown.Item onSelect={() => { resendAuthorization(_row) }}>Resend Authorization</Dropdown.Item>}
                        {_row.authorization === "AuthorizationConfirmed" && <Dropdown.Item onSelect={() => { resendAgreement(_row) }}>Resend Agreement</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
        const columns = [
            {
                dataField: 'paymentChannelId',
                text: 'Id',
                editable: false,
                sort: false,
                hidden: true,
                configurable: false
            }, {
                dataField: 'departmentName',
                text: 'Department',
                editable: false,
                configurable: true
            }, {
                dataField: 'paymentChannelName',
                text: 'Payment Channel',
                editable: false,
                configurable: true
            }, {
                dataField: 'authorization',
                text: 'Authorization',
                editable: false,
                formatter: authorizationFormatter,
                configurable: true
            },{
                dataField: '',
                text: 'Actions',
                editable: false,
                formatter: linksFormatter,
                configurable: false
            }
        ];
        return (
            <>
                <Modal show={showLinkModal} onHide={() => setShowLinkModal(false)} backdrop="static" size="lg">
                    <Modal.Header closeButton onHide={() => { }}>
                        <Modal.Title>Link Payment Channels</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showLinkModal ? <ListPaymentChannels /> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleLinkModalCancel}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col>
                        <div><b>Client : </b>{clientName}</div>
                    </Col>
                    <Col>
                        {confirmed &&
                        <PermissionModule permission={[Permissions.AddPMoFExecute, Permissions.ManageClientsDepartmentsPaymentChannelsCreate]}>
                            <div style={{ textAlign: "right", marginBottom: "10px" }}>
                                <Button variant="primary" size="sm" onClick={() => setShowLinkModal(true) }>
                                    Link Payment Channel
                                </Button>
                            </div>
                        </PermissionModule>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table
                            keyField="Math.random()"
                            columns={columns}
                            data={associations}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    const UpdateAddress = () => {
        return (
            <Form id="update-address">
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control placeholder="Customer Name" defaultValue={currentMethod.current.nameOnCard} disabled={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Card Type</Form.Label>
                            <Form.Control placeholder="Card Type" defaultValue={_.startCase(currentMethod.current.internalCardType)} disabled={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Last Four on Card</Form.Label>
                            <Form.Control placeholder="Last Four on Card" defaultValue={currentMethod.current.lastFourOnCard} disabled={true} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Expiration Date</Form.Label>
                            <Form.Control className="fs-mask" placeholder="Expiration Date" defaultValue={currentMethod.current.expirationDate} disabled={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Address Line 1</Form.Label>
                            <Form.Control placeholder="Address Line 1" defaultValue={currentMethod.current.addressLine1} onChange={(e) => currentMethod.current.addressLine1 = e.target.value} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Address Line 2</Form.Label>
                            <Form.Control placeholder="Address Line 2" defaultValue={currentMethod.current.addressLine2} onChange={(e) => currentMethod.current.addressLine2 = e.target.value} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control placeholder="City" style={{ textTransform: "capitalize" }} defaultValue={currentMethod.current.city} onChange={(e) => currentMethod.current.city = e.target.value} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>State</Form.Label>
                            <Form.Control placeholder="ST" style={{ textTransform: "uppercase" }} maxLength={2} minLength={2} defaultValue={currentMethod.current.state} onChange={(e) => currentMethod.current.state = e.target.value} onKeyPress={(e: any) => {
                                if (e.key === " " || !isNaN(e.key)) {
                                    e.preventDefault();
                                }
                            }} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label><RequiredIcon />Zip Code</Form.Label>
                            <Form.Control required={true} placeholder="Zip Code" maxLength={5} minLength={5} defaultValue={currentMethod.current.zipCode} onChange={(e) => currentMethod.current.zipCode = e.target.value} onKeyPress={(e: any) => {
                                if (e.key === " " || isNaN(e.key)) {
                                    e.preventDefault();
                                }
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        );
    }

    const UpdateReissue = () => {
        const reissueCardNumber = useRef<HTMLInputElement | null>(null);
        useEffect(() => {
            reissueCardNumber?.current?.setSelectionRange(0, 0);
            setTimeout(() => {
                reissueCardNumber?.current?.focus();
            }, 10);
        }, []);
        return (
            <Form id="update-reissue">
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control placeholder="Customer Name" defaultValue={currentMethod.current.nameOnCard} disabled={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Card Type</Form.Label>
                            <Form.Control placeholder="Card Type" defaultValue={_.startCase(currentMethod.current.internalCardType)} disabled={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><RequiredIcon />Existing Card Number&nbsp;&nbsp;<i style={{ fontSize: "9px" }}> with last four digits as shown</i></Form.Label>
                            <InputMask className={"form-control fs-mask"} type="input" required key={cardNumberKey} inputRef={input => reissueCardNumber.current = input}
                                mask={currentMethod.current.internalCardType.includes("American") ? "9999-999999-99999" : "9999-9999-9999-9999"}
                                defaultValue={(currentMethod.current.internalCardType.includes("American") ? "____-______-_" : "____-____-____-") + currentMethod.current.lastFourOnCard}
                                onChange={(e) => {
                                    if (e.target.value.endsWith(currentMethod.current.lastFourOnCard)) {
                                        currentMethod.current.cardNumber = e.target.value;
                                    } else {
                                        currentMethod.current.cardNumber = "";
                                        setCardNumberKey(Math.random());
                                    }
                                }}
                                onKeyPress={(e: any) => {
                                    if (e.key === " " || isNaN(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onBlur={(e) => {
                                    const _cardNumber = currentMethod?.current?.cardNumber?.replace(/\D/g, "") || "";
                                    if ((currentMethod.current.internalCardType.includes("American") && _cardNumber.length < 15) || _cardNumber.length < 16) {
                                        e.target.setCustomValidity("Enter a valid card number with matching last 4 digits");
                                    } else {
                                        e.target.setCustomValidity("");
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><RequiredIcon />New Expiration Date</Form.Label>
                            <InputMask className={"form-control fs-mask"} type="input" required={true} placeholder="MM/YY" pattern="(0[1-9]|1[0-2])\/([0-9]{2})" mask="99/99" defaultValue={currentMethod.current.expiryDate}
                                onChange={(e) => {
                                    currentMethod.current.expiryDate = e.target.value;
                                    if (e.target.validity.valid) {
                                        const expiryDate = isInvalidCardExpiration(currentMethod.current.expiryDate);
                                        if (expiryDate) {
                                            e.target.setCustomValidity(expiryDate);
                                        } else {
                                            e.target.setCustomValidity("");
                                        }
                                    } else {
                                        e.target.setCustomValidity("");
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label><RequiredIcon />New CVV Number</Form.Label>
                            <Form.Control className="fs-mask" required={true} placeholder="CVV Number" minLength={currentMethod.current.internalCardType.includes("American") ? 4 : 3} maxLength={currentMethod.current.internalCardType.includes("American") ? 4 : 3} defaultValue={currentMethod.current.cvvNumber}
                                onChange={(e) => {
                                    currentMethod.current.cvvNumber = e.target.value;
                                }}
                                onKeyPress={(e: any) => {
                                    if (e.key === " " || isNaN(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        );
    }

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" size="lg">
                    <Modal.Header closeButton onHide={() => { }}>
                        <Modal.Title>Linked Payment Channels</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showModal ? <Details /> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showAddPMoFModal} onHide={handleAddPMoFModalClose} backdrop="static" size="xl">
                    <Modal.Header closeButton onHide={() => { }}>
                        <Modal.Title>Add Payment Method on File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showAddPMoFModal && <PaymentMethodOnFile closeParentModal={handleAddPMoFModalClose} />}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAddPMoFModalClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showUpdateAddressModal} onHide={() => setShowUpdateAddressModal(false)} backdrop="static">
                    <Modal.Header closeButton onHide={() => { }}>
                        <Modal.Title>Update {methodType} Address on File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showUpdateAddressModal ? <UpdateAddress /> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleUpdateAddressModalSave} style={{ marginRight: "auto" }}>
                            Save
                        </Button>
                        <Button variant="secondary" onClick={handleUpdateAddressModalCancel}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showUpdateReissueModal} onHide={() => setShowUpdateReissueModal(false)} backdrop="static">
                    <Modal.Header closeButton onHide={() => { }}>
                        <Modal.Title>Update Expired {methodType} on File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showUpdateReissueModal ? <UpdateReissue /> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleUpdateReissueModalSave} style={{ marginRight: "auto" }}>
                            Save
                        </Button>
                        <Button variant="secondary" onClick={handleUpdateReissueModalCancel}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <PageHeader title={title} crumbs={new Array<Crumb>()} />
                <Container fluid>
                    <Row>
                        <Col>
                            <PageSectionContainer title={`Manage ${"Credit Cards"}`} toolbar={doToolbar("Card")}>
                                <Form.Group className='d-flex'>
                                    <Form.Control placeholder="Search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    <Button variant="secondary" onClick={(e) => setSearch("")} style={{marginLeft: "10px"}}>
                                        Clear
                                    </Button>
                                </Form.Group>
                                <hr/>
                                <Table
                                    keyField="Math.random()"
                                    data={cards.filter(card => {
                                        const s = search.toLowerCase();
                                        return card.nameOnCard.toLowerCase().includes(s) ||
                                            card.accessPermission.userIdentifier.toLowerCase().includes(s) ||
                                            card.lastFourOnCard.includes(s) ||
                                            card.internalCardType.toLowerCase().includes(s) ||
                                            card.zipCode.includes(s);
                                    })}
                                    columns={columnsCC}
                                    defaultSorted={[{ dataField: "nameOnCard", order: 'asc'}]}
                                />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                    {/*
                    <Row>
                        <Col>
                        <PageSectionContainer title={`Manage ${"Bank Accounts"}`} toolbar={doToolbar("Bank Account")}>
                                <Table
                                    keyField="Math.random()"
                                    data={accounts.filter(account => {
                                        const s = search.toLowerCase();
                                        return account.accountHolderName.toLowerCase().includes(s) ||
                                            account.accessPermission.userIdentifier.toLowerCase().includes(s) ||
                                            account.accountNumber.includes(s) ||
                                            account.bankAccountType.toLowerCase().includes(s) ||
                                            account.zipCode.includes(s);
                                    })}
                                    columns={columnsACH}
                                    defaultSorted={[{ dataField: "accountHolderName", order: 'asc'}]}
                                />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                    */}
                </Container>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client,
        currentUser: state.auth.currentUser,
        actionResult: state.wallets.actionResult,
    };
};

export default connect(mapStateToProps)(PMoFManage);

function updateAddressBankAccountAction(methodMsbId: string, updateBody: { addressLine1: any; addressLine2: any; city: any; state: any; zipCode: any; }, actionToken: string): any {
    throw new Error("Function not implemented.");
}
