import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
  label: string,
  showLabel: boolean,
  value: string
  justifyContent: string,
}

const getValue = (value: string) => {
  if (value && value.length > 0) {
    return value;
  } else {
    return "Acme Dept";
  }
}

const JUSTIFY_CONTENT = 'left';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
  return style && style !== ''?style:defaultStyle;
}

const DepartmentNamePropertyPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <Row>
            {data.showLabel ?
                <Col><strong>{data.label}</strong></Col>
                :
                <></>
            }
            <Col>
              <span style={{ 
                  display: 'flex',
                  justifyContent: checkStyleForDefault(data.justifyContent, JUSTIFY_CONTENT),
                }}>
                {getValue(data.value)}
              </span>  
            </Col>
        </Row>
  ),
  id: 'departmentNamePropertyPlugin',
  title: 'Department Name Property',
  description: 'Department Name Property.',
  version: 1,
  controls: [
    {
      title: 'Labels',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            label: {
              type: 'string',
              default: 'Department Name',
            },
            showLabel: {
              type: 'boolean',
              default: true
            }
          },
          required: ['label'],
        },
      },
    },
    {
      title: 'Align Data',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            justifyContent: {
              type: 'string',
              default: JUSTIFY_CONTENT
            }
          },
          required: ['justifyContent'],
        },
      },
    } 
  ]
};

export default DepartmentNamePropertyPlugin;