import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../redux/storeTypes';
import { cancelAction } from '../../../../redux/actions/clients/clientOnboarding';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { MerchantProcessor as Merchant } from '../../../../models/Client';
import { StepProps } from '../StepContainer';
import MerchantDetailsForm from "../forms/merchant/MerchantDetailsForm";
import { Redirect } from 'react-router-dom';
import { Routes } from '../../../../routes';
import FormHeader from "../forms/FormHeader";
import { ADD_MERCHANT_REQUEST, ADD_MERCHANT_SUCCESS, ADD_MERCHANT_FAILURE } from '../../../../redux/actions/clients/merchants';

export interface IMerchantDetailsStepProps extends StepProps {
    merchant: Merchant,
}

const MerchantDetailsStep = ({ isSaving, isFetching, client, merchant, formProps, actionResult }: IMerchantDetailsStepProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");

    const merchantAddActionToken = "MerchantAdd";

    if (!merchant) {
        merchant = new Merchant();
    }

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === ADD_MERCHANT_REQUEST && actionResult.token === merchantAddActionToken) {
            if (actionResult.result === ADD_MERCHANT_SUCCESS) {
                dispatch(sendSuccessToastAction("Merchant details were successfully added."));
                setRedirect(Routes.Clients.path);
            } else if (actionResult.result === ADD_MERCHANT_FAILURE) {
                dispatch(sendErrorToastAction("Merchant details could not be added. Reason(s) : " + actionResult.message));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    if (redirect !== "") {
        dispatch(cancelAction());
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <FormHeader title="Merchant Details" description="Please enter in the Merchant’s details below. Additional Merchants can be added later." />
                <MerchantDetailsForm
                    isSaving={isSaving}
                    isFetching={isFetching}
                    client={client}
                    merchant={merchant}
                    actionToken={merchantAddActionToken}
                    formActionProps={{
                        disabled: isSaving,
                        showSpinner: isFetching,
                        hasSubmit: true,
                        onCancel: () => setRedirect(Routes.Clients.path),
                        customProps: {
                            submitButtonDisplay: "Add"
                        }
                    }}
                />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.clients.isSaving,
        isFetching: state.clients.isFetching,
        gilaSettings: state.gilaSettings.gilaSettings,
        client: state.clients.client,
        merchant: state.clients.merchant,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(MerchantDetailsStep);