import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { Dropdown } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import moment  from 'moment';
import 'moment-timezone';
import { Routes } from "../../../routes";
import { getDynamicQueryReportPayments } from "../../../redux/actions/reports/report";
import { IAppState } from "../../../redux/storeTypes";
import { Client } from "../../../models/Client";
import { MsbReportItem } from "../../../models/Reports";
import Chart from 'react-apexcharts';
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";

interface IAllTransactionsWidget {
    client?: Client,
    summaryAllTransactions: Array<MsbReportItem>
}

export const AllTransactionsWidget = ({client, summaryAllTransactions}:IAllTransactionsWidget) => {
    const dispatch = useDispatch();
    const actionToken = "AllTransactionsWidget";
	const [redirect, setRedirect] = useState<string>("");
	
    const [series, setSeries] = useState<Array<any>>([]);
    const [options, setOptions] = useState<any>({});

	const today = moment();
	const previousWeeK = Array(7).fill(7).map(
		() => today.subtract(1, 'd').format('YYYY-MM-DD')
	);
	let fromDate = moment(previousWeeK[6]).format('YYYY-MM-DD HH:mm:ss');
	let endDate = moment(previousWeeK[0]).format('YYYY-MM-DD HH:mm:ss');

    let paymentsDays = new Array<string>();
    let payments = new Array<number>();
    let chargeBacks = new Array<number>();
    let refunds = new Array<number>();
    let voids = new Array<number>();

    useEffect(() => {
        doSetOptions();
        doSetSeries();

        if (!summaryAllTransactions || summaryAllTransactions.length === 0) {
            let createdAt = `${fromDate}<->${endDate}`;
            dispatch(getDynamicQueryReportPayments(createdAt, actionToken));
        }
    }, []);

    useEffect(() => {
        if (summaryAllTransactions?.length > 0) {
            const sortedItems = summaryAllTransactions.slice().sort((a, b) => Date.parse(b.transactionDate) - Date.parse(a.transactionDate));
            const today = moment();
            const previousWeeK = Array(7).fill(7).map(
                () => today.subtract(1, 'd').format('YYYY-MM-DDT00:00:00')
            );
        
            let paymentsChartData = [];
            let chargebacksChartData = [];
            let refundsChartData = [];
            let voidsChartData = [];

            for (let day = previousWeeK.length - 1; day >= 0; day--){
                let previousDate = new Date(previousWeeK[day]);
                let colChartDay = moment(previousWeeK[day]).format('ddd') + " " + moment(previousWeeK[day]).format('MM') + "-" + previousDate.getDate();
                let filteredTrxData = sortedItems.filter( a => a.transactionDate === previousWeeK[day]);

                //fileter by Sale/Chargeback/Refunds/Voids
                //Sale
                let paymentsTypeTxn = filteredTrxData.filter( a => a.transactionType === 1);
                var groupByPymentsTrxdata = _.groupBy(paymentsTypeTxn, 'TransactionDate');
                let groupByPaymentsTrxdataResult = _.map(groupByPymentsTrxdata, function(value, key){
                    return{
                        TotalAmount: _.reduce(value, function(total, o){
                            return total + o.totalAmount;
                        }, 0)
                    };
                });

                if (groupByPaymentsTrxdataResult && groupByPaymentsTrxdataResult.length > 0  && groupByPaymentsTrxdataResult[0].TotalAmount) {
                    paymentsChartData.push({colChartDay,TotalAmount:groupByPaymentsTrxdataResult[0].TotalAmount});
                } else {
                    paymentsChartData.push({colChartDay,TotalAmount:0});
                }

                //Chargebacks
                let chargebacksData = filteredTrxData.filter( a => a.transactionType === 9);
                var groupByChargebacksTrxdata = _.groupBy(chargebacksData, 'TransactionDate');
                let groupByChargebacksTrxdataResult = _.map(groupByChargebacksTrxdata, function(value, key){
                    return{
                        TotalAmount: _.reduce(value, function(total, o){
                            return total + o.totalAmount;
                        }, 0)
                    };
                });

                if (groupByChargebacksTrxdataResult[0]?.TotalAmount !== undefined)
                    chargebacksChartData.push({colChartDay,TotalAmount:groupByChargebacksTrxdataResult[0]?.TotalAmount});
                else if (groupByChargebacksTrxdataResult[0]?.TotalAmount === undefined)
                    chargebacksChartData.push({colChartDay,TotalAmount:0});

                //Refunds
                let refundsData = filteredTrxData.filter( a => a.transactionType === 3);         
                var groupByRefundsTrxdata = _.groupBy(refundsData, 'TransactionDate');
                let groupByRefundsTrxdataResult = _.map(groupByRefundsTrxdata, function(value, key){
                    return{
                        TotalAmount: _.reduce(value, function(total, o){
                            return total + o.totalAmount;
                        }, 0)
                    };
                });

                if (groupByRefundsTrxdataResult[0]?.TotalAmount !== undefined)
                    refundsChartData.push({colChartDay,TotalAmount:groupByRefundsTrxdataResult[0]?.TotalAmount});
                else if (groupByRefundsTrxdataResult[0]?.TotalAmount === undefined)
                refundsChartData.push({colChartDay,TotalAmount:0});

                //Voids
                let voidsData = filteredTrxData.filter( a => a.transactionType === 2);
                var groupByVoidsTrxdata = _.groupBy(voidsData, 'TransactionDate');
                let groupByVoidsTrxdataResult = _.map(groupByVoidsTrxdata, function(value, key){
                    return{
                        TotalAmount: _.reduce(value, function(total, o){
                            return total + o.totalAmount;
                        }, 0)
                    };
                });    

                if (groupByVoidsTrxdataResult[0]?.TotalAmount !== undefined)
                    voidsChartData.push({colChartDay,TotalAmount:groupByVoidsTrxdataResult[0]?.TotalAmount});
                else if (groupByVoidsTrxdataResult[0]?.TotalAmount === undefined)
                    voidsChartData.push({colChartDay,TotalAmount:0});
            }

            for (let x = 0; x < paymentsChartData.length; x++){            
                payments.push(+paymentsChartData[x].TotalAmount.toFixed(2));
                paymentsDays.push(paymentsChartData[x].colChartDay);
            }
            for (let x = 0; x < chargebacksChartData.length; x++){            
                chargeBacks.push(+chargebacksChartData[x].TotalAmount.toFixed(2));            
            }

            for (let x = 0; x < refundsChartData.length; x++){            
                refunds.push(+refundsChartData[x].TotalAmount.toFixed(2));            
            }

            for (let x = 0; x < voidsChartData.length; x++){            
                voids.push(+voidsChartData[x].TotalAmount.toFixed(2));            
            }

            doSetOptions();
            doSetSeries();
        }
    }, [summaryAllTransactions]);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const doSetOptions = () => {
        let _options = {
            options: {
                fill: {
                    opacity: 1,
                },
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: Math.min(window.innerWidth, 480),
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 1
                },
            },
            chart: {
                id: "basic-columnchart",
                toolbar:{
                    show: false,
                    tools:{
                        download : false,
                        Selection: false    
                    }
                }
            },        
            xaxis: {
                categories: paymentsDays,
                labels: {
                  rotate:-45,
                  rotateAlways : true,
                  style: {
                    colors: ['#414141', '#414141', '#414141', '#414141', '#414141', '#414141', '#414141'],
                    fontFamily: 'Lato, sans-serif',
                  },
                }
            },
            yaxis:{
                decimalsInFloat : 0,
                labels:{
                    formatter: (value:number) => { return formatter.format(value); },
                    style: {
                      colors: ['#414141', '#414141', '#414141', '#414141', '#414141', '#414141', '#414141'],
                      fontFamily: 'Lato, sans-serif',
                    },
                }
            },
            tooltip: {
              enabled: true,
              style: {
                fontFamily: 'Lato, sans-serif',
              }
            },
            grid: {
              show: true,
              borderColor: '#F5F5F5',
            },
            fill: {
              colors: ['#52C41A', '#00AEEF', '#F5222D', '#FADB14']
            },
            legend: {
              show: true,
              position: 'bottom',
              horizontalAlign: 'center',
              fontFamily: 'Lato, sans-serif',
              labels: {
                colors: '#414141'
              },
              markers: {
                width: 8,
                height: 8,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: ['#52C41A', '#00AEEF', '#F5222D', '#FADB14'],
                radius: 8,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
              },
            },
            states: {
              normal: {
                filter: {
                  type: 'none',
                  value: 0,
                }
              },
              hover: {
                filter: {
                  type: 'darken',
                  value: 0.6,
                }
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: 'darken',
                  value: 0.6,
                }
              },
            }
        };

        setOptions(_options);
    }

    const doSetSeries = () => {
        let series = [
            {
                name: 'Payments',
                data: payments,
            }, {
                name: 'Chargebacks',
                data: chargeBacks
            }, {
                name: 'Refunds',
                data: refunds
            }, {
                name: 'Voids',
                data: voids
            }
        ];

        setSeries(series);
    }

	if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <Card className="dashboard-widget">
                <Card.Header>
                    <h2>All Transactions</h2>
                    <Dropdown>
                        <Dropdown.Toggle variant="link" id="view-report-dropdown">
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onSelect={() => {
                                        setRedirect(Routes.TransactionsReport.path);
                                    }}
                                >
                                    View Report
                                </Dropdown.Item>
                            </Dropdown.Menu>
                    </Dropdown>
                </Card.Header>
                <Card.Body style={{ overflow: 'auto' }}>
                    <small>Previous 7 days</small>
                    <Chart
                        options={options}
                        series={series}
                        type="bar"
                        />
                </Card.Body>
            </Card>
        );
	}
};

const mapStateToProps = (state: IAppState) => {
    return { 
        client: state.clients.client,
        summaryAllTransactions: state.reports.summaryAllTransactions
    };
};

export default connect(mapStateToProps)(AllTransactionsWidget);