import { useEffect, useState } from "react";
import { Col, Row, Form, Container, Spinner, ListGroup, Button, Modal } from 'react-bootstrap';
import PageHeader from '../../components/layout/PageHeader';
import RequiredIcon from '../../components/RequiredIcon';
import { PaymentTypeEnum, PaymentCardTypeEnum } from '../../models/Payment';
import { connect, useDispatch } from "react-redux";
import { IActionResult, IAppState } from '../../redux/storeTypes';
import { sendErrorToastAction } from '../../redux/actions/toast';
import { BusinessTypeEnum, Client, Department, PaymentChannel, PaymentChannelTypeEnum, Terminal } from "../../models/Client";
import { User, UserTypeEnum } from "../../models/User";
import { Routes } from "../../routes";
import { Redirect } from 'react-router-dom';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { Crumb } from "../../models/Crumb";
import BillingDetails from "./components/BillingDetails";
import ECheckPayment from "./components/ECheckPayment";
import CreditCardPaymentManual from "./components/CreditCardPaymentManual";
import { getDepartmentAction, GET_DEPARTMENT_FAILURE, GET_DEPARTMENT_REQUEST, GET_DEPARTMENT_SUCCESS } from "../../redux/actions/clients/departments";
import { getPaymentChannelAction, GET_PAYMENTCHANNEL_FAILURE, GET_PAYMENTCHANNEL_REQUEST, GET_PAYMENTCHANNEL_SUCCESS } from "../../redux/actions/clients/paymentChannels";
import ErrorCard from "./components/ErrorCard";
import { TokenizeBankAccount, BankAccountType, TokenizeCard, WalletType } from "../../models/Wallets";
import { addBankAccountAction, addCardAction, ADD_BANKACCOUNT_FAILURE, ADD_BANKACCOUNT_REQUEST, ADD_BANKACCOUNT_SUCCESS, ADD_CARD_FAILURE, ADD_CARD_REQUEST, ADD_CARD_SUCCESS, clearAction } from "../../redux/actions/wallets";
import FormHeaderConfirmation from "../../components/FormHeaderConfirmation";
import icoWarning from "../../assets/img/icons/ico-warning.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import _ from "lodash";
import CreditCardPaymentTerminal from "./components/CreditCardPaymentTerminal";

interface IPMoFProps {
    closeParentModal: any;
    isSaving: boolean;
    client: Client;
    department: Department;
    paymentChannel: PaymentChannel;
    currentUser: User;
    errorMessage: string;
    actionResult: IActionResult;
    actionClient: IActionResult;
    siteKey: string;
}

const PMoF = ({ closeParentModal, isSaving, client, department, paymentChannel, currentUser, errorMessage, actionResult, actionClient, siteKey }: IPMoFProps) => {
    const dispatch = useDispatch();
    const actionToken = "PMoF";
    const [redirect, setRedirect] = useState<string>("");
    const [validated, setValidated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [methodSummary, setMethodSummary] = useState("");
    const [isFetchingDepartment, setIsFetchingDepartment] = useState(false);
    const [isFetchingPaymentChannel, setIsFetchingPaymentChannel] = useState(false);
    const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(PaymentTypeEnum.Unknown);
    const [paymentCardType, setPaymentCardType] = useState<PaymentCardTypeEnum>(PaymentCardTypeEnum.Unknown);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [userPaymentChannels, setUserPaymentChannels] = useState<Array<PaymentChannel>>([]);
    const [paymentMethods, setPaymentMethods] = useState<Array<{ value: PaymentTypeEnum, name: string }>>([]);
    const [unconfirmedEmailAddress, setUnconfirmedEmailAddress] = useState(false);
    const [terminals, setTerminals] = useState<Array<Terminal> | undefined>();

    useEffect(() => {
        if (client) {
            var _userPaymentChannels = client.departments.reduce((allPaymentChannels, department) => {
                let posChannels: any;
                if (currentUser?.userTypeEnum === UserTypeEnum.Client && currentUser?.userDepartments.some(userDepartments => department.msbId === userDepartments.departmentMSBId)) {
                    posChannels = (department.paymentChannels || []).filter(channel => channel.isActive && channel.paymentMethodOnFileEnabled && (channel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.PointOfSale]/* || channel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.QuickPay] ||  channel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.TerminalPay]*/));
                //} else if (currentUser?.userTypeEnum === UserTypeEnum.Navient) {
                //    posChannels = (department.paymentChannels || []).filter(channel => channel.isActive && channel.paymentMethodOnFileEnabled && (channel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.PointOfSale]/* || channel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.QuickPay] ||  channel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.TerminalPay]*/));
                } else {
                    posChannels = [];
                }
                return allPaymentChannels.concat(posChannels);
            }, new Array<PaymentChannel>());

            if (_userPaymentChannels.length === 1) {
                let _paymentChannel = _userPaymentChannels[0];
                setIsFetchingDepartment(true);
                setIsFetchingPaymentChannel(true);
                dispatch(getPaymentChannelAction(_paymentChannel.msbId!, actionToken));
                dispatch(getDepartmentAction(client.departments.filter(_ => _.id === _paymentChannel.departmentId)[0].msbId!, actionToken));
            }

            setUserPaymentChannels(_userPaymentChannels);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client])

    const selectPaymentChannel = (e: any) => {
        setPaymentType(PaymentTypeEnum.Unknown);

        let selectedChannel = userPaymentChannels.find(channel => channel.msbId === e.target.value);
        if (selectedChannel) {
            setIsFetchingDepartment(true);
            setIsFetchingPaymentChannel(true);
            let _departmentId = client.departments.filter(_ => _.id === selectedChannel?.departmentId)[0].msbId!;
            dispatch(getDepartmentAction(_departmentId, actionToken));
            dispatch(getPaymentChannelAction(selectedChannel.msbId!, actionToken));
        } else {
        }
    }

    useEffect(() => {
        let _paymentMethods = new Array<{ value: PaymentTypeEnum, name: string }>();
        if (paymentChannel?.eCheckPMoFEnabled) {
            _paymentMethods.push({
                value: PaymentTypeEnum.ECheck,
                name: 'eCheck Payment'
            });
        }

        if (paymentChannel?.creditCardPMoFEnabled) {
            _paymentMethods.push({
                value: PaymentTypeEnum.CreditCardManual,
                name: 'Credit Card Payment - Manual'
            });
        }

        if (!paymentChannel?.terminalPMoFEnabled) { // Disallow ad-hoc TriPOS
            _paymentMethods.push({
                value: PaymentTypeEnum.CreditCardTerminal,
                name: 'Credit Card Payment - Terminal'
            });
        }
        setPaymentMethods(_paymentMethods);
    }, [paymentChannel])

    useEffect(() => {
        if (paymentMethods.length === 1 && paymentChannel) {
            setPaymentType(paymentMethods[0].value);
        } else {
            setPaymentType(PaymentTypeEnum.Unknown);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethods, paymentChannel]);

    useEffect(() => {
        if (!isFetchingDepartment && !isFetchingPaymentChannel && department && paymentChannel) {
            let _terminals = paymentChannel.processors.find(p => p.merchantProcessor?.businessType === BusinessTypeEnum.Retail)?.merchantProcessor?.terminals;
            setTerminals(_terminals);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingDepartment, isFetchingPaymentChannel]);

    const selectPaymentType = (e: any) => {
        setPaymentCardType(PaymentCardTypeEnum.Unknown);
        setPaymentType(Number(e.target.value));
    }

    const selectPaymentCardType = (e: any) => {
        setPaymentCardType((e !== undefined) ? Number(e.target.value) : PaymentCardTypeEnum.Unknown);
    }

    const selectPaymentCardTypeByValue = (e: any) => {
        setPaymentCardType((e !== undefined) ? Number(e) : PaymentCardTypeEnum.Unknown);
    }

    const handleCancelPayment = (e: any) => {
        e.preventDefault();
        window.document.forms[0].reset();
        setValidated(false);
        setPaymentType(PaymentTypeEnum.Unknown);
        setPaymentCardType(PaymentCardTypeEnum.Unknown);
        setMethodSummary("");
        setShowModal(false); 
        dispatch(clearAction(actionToken));
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        setUnconfirmedEmailAddress(false);
        if (form.elements.emailAddress.value !== form.elements.confirmEmailAddress.value) {
            setUnconfirmedEmailAddress(true);
            return;
        }
        if (form.checkValidity() && ((paymentType === PaymentTypeEnum.ECheck && form.elements.eCheckRoutingNumber.value.length === 9 && form.elements.eCheckBankName.value) || (paymentType === PaymentTypeEnum.CreditCardManual && paymentCardType) || (paymentType === PaymentTypeEnum.CreditCardTerminal && paymentCardType))) {
            setShowConfirmationModal(true);
            if (paymentType === PaymentTypeEnum.ECheck) {
                let bankAccount = new TokenizeBankAccount();
                bankAccount.accountHolderName = form.elements.eCheckAccountHolderName.value;
                bankAccount.routingNumber = form.elements.eCheckRoutingNumber.value;
                bankAccount.accountNumber = form.elements.eCheckAccountNumber.value;
                bankAccount.addressLine1 = form.elements.addressLine1.value;
                bankAccount.addressLine2 = form.elements.addressLine2.value;
                bankAccount.city = form.elements.city.value;
                bankAccount.state = form.elements.state.value;
                bankAccount.zipCode = form.elements.zip.value;
                bankAccount.accountHolderEmailAddress = form.elements.emailAddress.value;
                bankAccount.paymentChannelId = paymentChannel.msbId;
                bankAccount.bankAccountType = BankAccountType.Checking;

                setMethodSummary(bankAccount.accountHolderName + " - BANK ACCOUNT xxxx" + bankAccount.accountNumber.slice(-4));

                dispatch(addBankAccountAction(bankAccount, actionToken));
            }
            else if (paymentType === PaymentTypeEnum.CreditCardManual) {
                let card = new TokenizeCard();
                card.nameOnCard = form.elements.nameOnCard.value;
                card.cardNumber = form.elements.cardNumber.value;
                var fields = (form.elements.expirationDate.value).split('/')
                card.expirationMonth = fields[0];
                card.expirationYear = fields[1];
                card.cvv = form.elements.cvv.value;
                card.addressLine1 = form.elements.addressLine1.value;
                card.addressLine2 = form.elements.addressLine2.value;
                card.city = form.elements.city.value;
                card.state = form.elements.state.value;
                card.zipCode = form.elements.zip.value;
                card.cardHolderEmailAddress = form.elements.emailAddress.value;
                card.paymentChannelId = paymentChannel.msbId;
                card.walletType = WalletType.Personal;
                card.internalCardType = paymentCardType?.valueOf()!;

                setMethodSummary(card.nameOnCard + " - " + _.startCase(PaymentCardTypeEnum[card.internalCardType]).toUpperCase() + " xxxx" + card.cardNumber.slice(-4) + " -  Exp " + card.expirationMonth + "/" + card.expirationYear);

                dispatch(addCardAction(card, actionToken));
            }
            else if (paymentType === PaymentTypeEnum.CreditCardTerminal) {
                let card = new TokenizeCard();
                const terminal = terminals?.find(terminal => terminal.msbId === form.elements.terminal.value);
                card.terminalId = terminal!.msbId!;
                card.laneId = terminal!.laneId!;
                card.isCardPresent = true;
                card.addressLine1 = form.elements.addressLine1.value;
                card.addressLine2 = form.elements.addressLine2.value;
                card.city = form.elements.city.value;
                card.state = form.elements.state.value;
                card.zipCode = form.elements.zip.value;
                card.cardHolderEmailAddress = form.elements.emailAddress.value;
                card.paymentChannelId = paymentChannel.msbId;
                card.walletType = WalletType.Personal;
                card.internalCardType = paymentCardType?.valueOf()!;

                setMethodSummary("TERMINAL - " + _.startCase(PaymentCardTypeEnum[card.internalCardType]).toUpperCase());

                dispatch(addCardAction(card, actionToken));
            }
        }
        setValidated(true);
    }

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_CARD_REQUEST && actionResult.token === actionToken) {
                setValidated(false);
                setShowConfirmationModal(false);
                if (actionResult.result === ADD_CARD_SUCCESS) {
                    setShowModal(true);
                    dispatch(clearAction(actionToken));
                } else if (actionResult.result === ADD_CARD_FAILURE) {
                    dispatch(sendErrorToastAction("Payment Method on File - Card failed"));
                }
            }
            if (actionResult.type === ADD_BANKACCOUNT_REQUEST && actionResult.token === actionToken) {
                setValidated(false);
                setShowConfirmationModal(false);
                if (actionResult.result === ADD_BANKACCOUNT_SUCCESS) {
                    setShowModal(true);
                    dispatch(clearAction(actionToken));
                } else if (actionResult.result === ADD_BANKACCOUNT_FAILURE) {
                    dispatch(sendErrorToastAction("Payment Method on File for eCheck failed"));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    useEffect(() => {
        if (actionClient && actionClient.result) {
            if (actionClient.type === GET_DEPARTMENT_REQUEST && actionClient.token === actionToken) {
                if (actionClient.result === GET_DEPARTMENT_SUCCESS) {
                    setIsFetchingDepartment(false);
                } else if (actionClient.result === GET_DEPARTMENT_FAILURE) {
                    setIsFetchingDepartment(false);
                }
            }

            if (actionClient.type === GET_PAYMENTCHANNEL_REQUEST && actionClient.token === actionToken) {
                if (actionClient.result === GET_PAYMENTCHANNEL_SUCCESS) {
                    setIsFetchingPaymentChannel(false);
                } else if (actionClient.result === GET_PAYMENTCHANNEL_FAILURE) {
                    setIsFetchingPaymentChannel(false);
                }
            }
        }
    }, [actionClient]);

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Payment Method on File", Routes.AddPMoF.path));
        return (
            <>
                <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static">
                    <Modal.Header closeButton onHide={() => { }} style={{ margin: "0px" }} />
                    <Modal.Body>
                        <Container fluid style={{ margin: "0px" }}>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: 'green' }} />
                                        <h1 style={{ margin: "10px 0px", fontSize: "20px", fontWeight: "bold" }}>Payment Method on File Successful</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <p>The PMoF is currently in the PENDING state.</p>
                                        <p style={{ textAlign: "justify" }}>An email has been sent to the address provided by the customer inorder to obtain authorization for their payment method on file to be used for future payments.</p>
                                        <br />
                                        <p style={{ fontSize: "12px", fontFamily: "Courier New", color: "black" }}>{methodSummary}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container fluid style={{ margin: "5px 0px" }}>
                            <Row>
                                <Col>                                                                
                                    <Button style={{ float: "left" }} onClick={handleCancelPayment}>
                                        Add Another PMoF
                                    </Button>
                                    <Button style={{ float: "right" }} onClick={closeParentModal}>
                                        OK
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
                <PageHeader title="Payment Method on File" crumbs={crumbs} />
                <Container fluid className="container-payment">
                    <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} backdrop="static" className="modal-confirmation">
                        <Modal.Body>
                            <FormHeaderConfirmation iconImg={icoWarning} title="Payment Method on File submission in progress" />
                            <div className="confirmation-body">
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Col xl={8} lg={8} md={12} sm={12} className="mb-4">
                                <PageSectionContainer>
                                    <div className="container-payment-form">
                                        <div className="transactionTitle">
                                            <h2>Payment details</h2>
                                            <p>Fill out the form below to file a payment method</p>
                                        </div>
                                        <div className="payment-channel">
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <Form.Group controlId="paymentChannel">
                                                        <Form.Label><RequiredIcon />Payment Channel</Form.Label>
                                                        <Form.Control as="select" type="input" required disabled={isSaving || isFetchingDepartment || isFetchingPaymentChannel || userPaymentChannels.length === 1} onChange={selectPaymentChannel}>
                                                            {userPaymentChannels.length > 1 && <option key={"channel_default"} value="">Select Payment Channel</option>}
                                                            {
                                                                userPaymentChannels.map((channel, index) => (
                                                                    <option key={`channel_${channel.msbId}`} value={channel.msbId}>{channel.name}</option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">Please select a Payment Channel.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                    <Col md={6} sm={12}>
                                                        {(isFetchingDepartment || isFetchingPaymentChannel) ?
                                                            <Spinner animation="border" size="sm" style={{ marginTop: 35 }} />
                                                            :
                                                        <Form.Group controlId="paymentType">
                                                            <Form.Label><RequiredIcon />Payment type</Form.Label>
                                                            <Form.Control as="select" type="input" required disabled={!paymentChannel || isSaving || paymentMethods.length === 1} onChange={selectPaymentType} key={paymentChannel?.msbId}>
                                                                {(!paymentChannel || paymentMethods.length > 1) && <option key={0} value="">Select Payment type</option>}
                                                                {
                                                                    paymentMethods.map((method, index) => <option key={index + 1} value={method.value}>{method.name}</option>)
                                                                }
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">Please select a Payment Type.</Form.Control.Feedback>
                                                        </Form.Group>
                                                        }
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="payment-type">
                                            {paymentType === PaymentTypeEnum.ECheck && <ECheckPayment 
                                                isFetching={false}
                                                isSaving={isSaving}
                                                isVisible={paymentType === PaymentTypeEnum.ECheck ? true : false}
                                            />}

                                            {paymentType === PaymentTypeEnum.CreditCardTerminal && <CreditCardPaymentTerminal
                                                isFetching={false}
                                                isSaving={isSaving}
                                                terminals={terminals}
                                                paymentCardType={paymentCardType}
                                                selectPaymentCardType={selectPaymentCardType}
                                                isVisible={paymentType === PaymentTypeEnum.CreditCardTerminal ? true : false}
                                                terminalId={currentUser?.preferences?.terminals?.terminalId}
                                            />}

                                            {paymentType === PaymentTypeEnum.CreditCardManual && <CreditCardPaymentManual
                                                isFetching={false}
                                                isSaving={isSaving}
                                                paymentChannel={paymentChannel}
                                                paymentCardType={paymentCardType}
                                                selectPaymentCardType={selectPaymentCardTypeByValue}
                                                isVisible={paymentType === PaymentTypeEnum.CreditCardManual ? true : false}
                                            />}
                                        </div>

                                        <BillingDetails 
                                            isFetching={false}
                                            isSaving={isSaving}
                                            isEmailRequired={true}
                                            forceZipRequired={true}
                                            overrideAvsChecks={true}
                                            paymentType={paymentType}
                                            paymentChannel={paymentChannel}
                                        />

                                        <Row>
                                            <Col md={6} sm={12}>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group controlId="confirmEmailAddress">
                                                    <Form.Label><RequiredIcon visible={true} />Confirm Email address</Form.Label>
                                                    <Form.Control type="email" placeholder="email@address.com" required={true} disabled={!paymentType || isSaving} isInvalid={unconfirmedEmailAddress} />
                                                    <Form.Control.Feedback type="invalid">Confirm email address.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                </PageSectionContainer>
                            </Col>
                            <Col xl={4} lg={4} md={12} sm={12}>
                                <PageSectionContainer>
                                    <div className="container-transaction-summary">
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <h2>Payment Method on File</h2>
                                                <div className="actionBox">
                                                    <Form.Group className="summaryButtons">
                                                        <Button type="submit" disabled={isSaving}>Submit</Button>
                                                        {/*<Button variant="link" onClick={handleCancelPayment}>Cancel</Button>*/}
                                                        <ErrorCard errorMessage={errorMessage} />
                                                    </Form.Group>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                </PageSectionContainer>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </>
        )
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        client: state.clients.client,
        department: state.clients.department,
        paymentChannel: state.clients.paymentChannel,
        currentUser: state.auth.currentUser,
        errorMessage: state.wallets.errorMessage,
        actionResult: state.wallets.actionResult,
        actionClient: state.clients.actionResult,
        siteKey: state.webAppSettings.siteKey,
    };
};

export default connect(mapStateToProps)(PMoF);