import { useRef } from 'react';
import { Container, Col, Nav, Row, Tab, Button } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import PageHeader from '../../components/layout/PageHeader';
import BasicSettings from './components/BasicSettings';
import NotificationSettings from './components/NotificationSettings';
import { IActionResult, IAppState } from '../../redux/storeTypes';
import { NotificationType, UserNotificationSetting } from '../../models/AccountSettings';
import { User } from '../../models/User';

import './settings.css';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { Crumb } from '../../models/Crumb';
import { Routes } from '../../routes';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
export interface IAccountSettings{
    isFetching: boolean,
    isSaving: boolean,
    currentUser: User,
    actionResult: IActionResult,
    notificationTypes: Array<NotificationType>,
    userSettingsActionResult: IActionResult    
}

const AccountSettings = ({ isFetching, isSaving, currentUser, actionResult, notificationTypes, userSettingsActionResult }: IAccountSettings) => {
    const [useNotificationTypes, setUseNotificationTypes] = useState([new NotificationType()]);
    const [usernotificationSettings, setUserNotificationSettings] = useState([new UserNotificationSetting()]);
    const [usernotificationSettingsOne, setusernotificationSettingsOne] = useState<Array<UserNotificationSetting>>([]);

    const dispatch = useDispatch();
    let actionToken = "AccountSettings";


    const ref = useRef(null);

    const scroll = (scrollOffset: any) => {
      const node: any = ref.current!;
      node.scrollLeft += scrollOffset;
    };

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("My Profile", Routes.AccountSettings.path));
    return (
        <>
            <PageHeader title="My Profile" crumbs={crumbs} />

            <Container fluid className="manage-container">
                <Row>
                    <Col>
                        <PageSectionContainer>
                            <Tab.Container id="account-settings" defaultActiveKey="basicSettings">

                                {/* BEGIN: Tabs */}
                                <div className="tab-nav d-flex px-0">
                                  <Button variant="link" onClick={() => scroll(-300)} className="btn-scroll-left">
                                    <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                                  </Button>
                                  <div className="scrollbarContainer" ref={ref}>
                                    <Nav className="flex-row flex-nowrap">
                                        <Nav.Item>
                                            <Nav.Link eventKey="basicSettings">
                                                Basic settings
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                  </div>
                                  <Button variant="link" onClick={() => scroll(300)} className="btn-scroll-right">
                                    <FontAwesomeIcon icon={faChevronRight} size="xs" />
                                  </Button>
                                </div>
                                {/* END: Tabs */}

                                <Tab.Content>
                                    <Tab.Pane eventKey="basicSettings">
                                        <BasicSettings />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </PageSectionContainer>
                    </Col>
                </Row>
            </Container>
            
        </>
    );
//}
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.auth.isFetching,
        isSaving: state.auth.isSaving,
        currentUser: state.auth.currentUser,
        actionResult: state.auth.actionResult,
        notificationTypes: state.accountSettings.notificationTypes,
        userSettingsActionResult: state.accountSettings.actionResult
    };
};

export default connect(mapStateToProps)(AccountSettings);