import { AccountDDATypeEnum } from '../../../models/Client';
import { ReportsPaymentType } from '../../../models/Payment';

export const allPaymentsFormSchema = {
    startTime: {
        type: "time",
        label: "Start time",
        selected: false
    },
    endTime: {
        type: "time",
        label: "End time",
        selected: false
    },
    orderIdentifier: {
        type: "text",
        label: "Transaction ID",
        selected: false
    },
    itemReferenceNumber: {
        type: "text",
        label: "Item reference",
        selected: false
    },
    startAmount: {
        type: "currency",
        label: "Start amount",
        selected: false
    },
    endAmount: {
        type: "currency",
        label: "End amount",
        selected: false
    },
    paymentTypeSelect: {
        label: "Payment type",
        selected: false,
        options: [
            {
                label: "Credit Card Payments",
                value: "CreditCard"
            },
            {
                label: "eCheck Payments",
                value: "eCheck"
            },
            {
                label: "PayPal Payments",
                value: "PayPal"
            },
            {
                label: "Wire Transfers",
                value: "WireTransfer"
            },
        ]
    },
    lastFourOnCard: {
        type: "text",
        label: "Last 4 of credit card or account number",
        selected: false
    },
    initiatedBy: {
        type: "text",
        label: "Initiated by",
        selected: false
    },
    nameOnCard: {
        type: "text",
        label: "Customer name",
        selected: false
    },
    customSelectComponent: {
        label: "Transaction type",
        selected: false,
        options:[
            {
                label: "Chargebacks",
                value: "Chargeback"
            },
            {
                label: "Payments",
                value: "Sale"
            },
            {
                label: "Refunds/Returns",
                value: "Refund"
            },
            {
                label: "Voids/Reversals",
                value: "Void"
            },
            {
                label: "Authorization Commit",
                value: "AuthorizationCommit"
            },
            {
                label: "Chargeback Reversal",
                value: "ChargebackReversal"
            }
        ]
    }
}

export const achFinanceFormSchema = {

    initiatedBy: {
        type: "text",
        label: "Initiated by",
        selected: false
    },
    customSelectComponent: {
        label: "Transaction type",
        selected: false,
        options:[
            {
                label: "Payments",
                value: "Sale"
            },
            {
                label: "Refunds/Returns",
                value: "Refund"
            }
        ]
    }

}

export const paymentsFormSchema = {
    startTime: {
        type: "time",
        label: "Start time",
    },
    endTime: {
        type: "time",
        label: "End time",
    },
    orderIdentifier: {
        type: "text",
        label: "Transaction ID",
    },
    itemReferenceNumber: {
        type: "text",
        label: "Item reference",
    },
    startAmount: {
        type: "currency",
        label: "Start amount",
    },
    endAmount: {
        type: "currency",
        label: "End amount",
    },
    paymentTypeSelect: {
        label: "Payment type",
        selected: false,
        options: [
            {
                label: "Credit Card Payments",
                value: "CreditCard"
            },
            {
                label: "eCheck Payments",
                value: "eCheck"
            },
            {
                label: "PayPal Payments",
                value: "PayPal"
            },
            {
                label: "Wire Transfers",
                value: "WireTransfer"
            }
        ]
    },
    lastFourOnCard: {
        type: "text",
        label: "Last 4 of credit card or account number",
        selected: false
    },
    initiatedBy: {
        type: "text",
        label: "Initiated by",
        selected: false
    },
    nameOnCard: {
        type: "text",
        label: "Customer name",
        selected: false
    }
}

export const shoppingCartFormSchema = {
    startTime: {
        type: "time",
        label: "Start time",
    },
    endTime: {
        type: "time",
        label: "End time",
    },
    startAmount: {
        type: "number",
        label: "Start amount",
    },
    endAmount: {
        type: "number",
        label: "End amount",
    },
    orderIdentifier: {
        type: "text",
        label: "Transaction ID",
    },
    transactionType: {
        type: "select",
        label: "Transaction type",
        options: [
            {
                label: "All Transactions",
                value: "alltransactions",
            },
            {
                label: "Successful/Payment",
                value: "successful"
            },
            {
                label: "Void",
                value: "void"
            },
            {
                label: "Chargeback",
                value: "chargeback"
            },
            {
                label: "Refund",
                value: "refund"
            }
        ]
    },
    paymentType: {
        type: "select",
        label: "Payment type",
        options: [
            {
                label: "All Payments",
                value: ReportsPaymentType.allPayments
            },
            {
                label: "Credit Card Payments",
                value: ReportsPaymentType.creditCard
            },
            {
                label: "eCheck Payments",
                value: ReportsPaymentType.achPayments
            },
        ]
    },
    cardType: {
        type: "select",
        label: "Card type",
        options: [
            {
                label: "Visa",
                value: "visa"
            },
            {
                label: "American Express",
                value: "americanExpress"
            },
            {
                label: "Discover",
                value: "discover"
            },
            {
                label: "Mastercard",
                value: "masterCard"
            },
        ]
    },
    lastFourOnCard: {
        type: "text",
        label: "Last 4 of credit card or account number",
    },
    terminalName: {
        type: "text",
        label: "Terminal name",
    },
    initiatedBy: {
        type: "text",
        label: "Initiated by",
    },
}

export const fundingFormSchema = {
    batchType: {
        type: "select",
        label: "Batch Type",
        options: [
            {
                label: "All Payments",
                value: ReportsPaymentType.allPayments
            },
            {
                label: "Credit Card Payments",
                value: ReportsPaymentType.creditCard
            },
            {
                label: "eCheck Payments",
                value: ReportsPaymentType.achPayments
            },
        ]
    },
    stateAbbrevation: {
        type: "text",
        label: "State abbreviation",
    },
}

export const hardwareFormSchema = {
    status: {
        type: "select",
        label: "Status",
        options: [
            {
                label: "All Status",
                value: "allStatus"
            },
            {
                label: "Online",
                value: "online"
            },
            {
                label: "Warning",
                value: "warning"
            },
            {
                label: "Error",
                value: "error"
            },
            {
                label: "Offline",
                value: "offline"
            },
        ]
    }
}

export const userActivityFormSchema = {
    userId: {
        type: "text",
        label: "User ID",
    },
    status: {
        type: "select",
        label: "Status",
        options: [
            {
                label: "All Status",
                value: "allStatus"
            },
            {
                label: "Online",
                value: "online"
            },
            {
                label: "Warning",
                value: "warning"
            },
            {
                label: "Error",
                value: "error"
            },
            {
                label: "Offline",
                value: "offline"
            },
        ]
    },
    firstName: {
        type: "text",
        label: "First name",
    },
    lastName: {
        type: "text",
        label: "Last name",
    }
}

export const convFeeFormSchema = {
    paymentTypeSelect: {
        label: "Payment type",
        options: [
            {
                label: "Credit Card Payments",
                value: "CreditCard"
            },
            {
                label: "eCheck Payments",
                value: "eCheck"
            },
            {
                label: "PayPal Payments",
                value: "PayPal"
            },
            {
                label: "Wire Transfers",
                value: "WireTransfer"
            }
        ]
    },
    paymentAccount: {
        type: "text",
        label: "Payment account",
    },
    transactionIdentifier: {
        type: "text",
        label: "Transaction ID",
    },
    transactionType: {
        type: "select",
        label: "Transaction type",
        options: [
            {
                label: "All Transactions",
                value: "alltransactions",
            },
            {
                label: "Successful/Payment",
                value: "successful"
            },
            {
                label: "Void",
                value: "void"
            },
            {
                label: "Chargeback",
                value: "chargeback"
            },
            {
                label: "Refund",
                value: "refund"
            }
        ]
    },
    firstName: {
        type: "text",
        label: "First name",
    },
    lastName: {
        type: "text",
        label: "Last name",
    }
}


export const clientBanking = {

    accountDDAType: {
        type: "select",
        label: "Account type",
        options: [
            {
                label: "Checking",
                value: AccountDDATypeEnum.Checking
            },
            {
                label: "Savings",
                value: AccountDDATypeEnum.Savings
            },
        ]
    },
    bankAccountNumber: {
        type: "text",
        label: "Account number",
    },
    bankRoutingNumber: {
        type: "text",
        label: "Routing Number",
    }

}