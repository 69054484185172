import React from "react";
import { Container, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import Pending from './components/Pending';
import History from './components/History'
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { getPendingApprovalsAction } from '../../redux/actions/pendingApprovals';
import { getHistoryApprovalsAction } from '../../redux/actions/historyApprovals';

const Approvals: React.FC = () => {
    const actionToken = "Approvals";
    const dispatch = useDispatch();
    return (
        <>

        <Container fluid>
            <Row>
                <Col>
                    <PageSectionContainer title="Approvals">
                        <Tab.Container id="approvalworkflow" defaultActiveKey="pending">
                            <Nav className="tab-nav">
                                <Nav.Item>
                                    <Nav.Link eventKey="pending" onClick={() => { dispatch(getPendingApprovalsAction(1,5,actionToken)) }}>Pending</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="history" onClick={() => { dispatch(getHistoryApprovalsAction(1,5)) }}>History</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="pending">
                                    <Pending />
                                </Tab.Pane>
                                <Tab.Pane eventKey="history">
                                    <History />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </PageSectionContainer>
                </Col>
            </Row>
        </Container>
            
        </>
    )
}


export default Approvals