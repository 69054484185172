import axios from 'axios';
import { ApprovalChecklist } from '../../../models/Client';
import { Dispatch } from 'react';
import store from "../../store";

export const SAVE_APPROVAL_CHECKLIST_REQUEST = 'SAVE_APPROVAL_CHECKLIST_REQUEST';
export const SAVE_APPROVAL_CHECKLIST_SUCCESS = 'SAVE_APPROVAL_CHECKLIST_SUCCESS';
export const SAVE_APPROVAL_CHECKLIST_FAILURE = 'SAVE_APPROVAL_CHECKLIST_FAILURE';

export const saveApprovalChecklistAction = (approvalChecklist: ApprovalChecklist, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_APPROVAL_CHECKLIST_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/ApprovalChecklist"

    if (approvalChecklist.msbId === undefined) {
        axios.post(_url, approvalChecklist)
            .then(function (response) {
                approvalChecklist = response.data;
                dispatch({
                    type: SAVE_APPROVAL_CHECKLIST_SUCCESS,
                    payload: {
                        approvalChecklist,
                        actionToken
                    }
                });

            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_APPROVAL_CHECKLIST_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    } else {
        _url = _url + "/" + approvalChecklist.msbId;

        axios.put(_url, approvalChecklist)
            .then(function (response) {
                approvalChecklist = response.data;
                dispatch({
                    type: SAVE_APPROVAL_CHECKLIST_SUCCESS,
                    payload: {
                        approvalChecklist,
                        actionToken
                    }
                });

            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_APPROVAL_CHECKLIST_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    }
};
