import axios from 'axios';

import {
    POST_AUTH_REQUEST, POST_AUTH_SUCCESS, POST_AUTH_FAILURE,
    LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_SUCCESS, RESET_REQUEST, RESET_SUCCESS,
    PASSWORD_RESET_EMAIL_REQUEST, PASSWORD_RESET_EMAIL_SUCCESS,
    SAVE_CURRENT_USER_REQUEST, SAVE_CURRENT_USER_SUCCESS, SAVE_CURRENT_USER_FAILURE,
    GET_USER_PAYMENT_PREFERENCES_REQUEST,GET_USER_PAYMENT_PREFERENCES_SUCCESS,GET_USER_PAYMENT_PREFERENCES_FAILURE,
    UPDATE_USER_PAYMENT_PREFERENCES_REQUEST, UPDATE_USER_PAYMENT_PREFERENCES_SUCCESS,UPDATE_USER_PAYMENT_PREFERENCES_FAILURE,
    SAVE_CURRENT_USER_PREFERENCES_REQUEST, SAVE_CURRENT_USER_PREFERENCES_SUCCESS, SAVE_CURRENT_USER_PREFERENCES_FAILURE,
    SAVE_AVATAR_REQUEST, SAVE_AVATAR_SUCCESS, SAVE_AVATAR_FAILURE,
    GET_AVATAR_REQUEST, GET_AVATAR_SUCCESS, GET_AVATAR_FAILURE,
    CLEAR_AUTH_ACTION_RESULT
} from '../actions/auth';

export default function auth(state = {
    isFetching: false,
    isSaving: false,
    errorMessage: '',
    currentUser: null,
    userPermissions: null,
    actionResult: null,
    isAuthenticated: false,
    msbBearerToken: null,
    msalInstance: {},
    loadingInit: true,
    paymentPreferences: null
}, { type, payload }) {
    switch (type) {
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated: false,
                currentUser: null,
                loadingInit: false,
            });
        case POST_AUTH_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: POST_AUTH_REQUEST, result: null, token: payload.actionToken } ,
            });
        case POST_AUTH_SUCCESS:
            let user = payload.currentUser;
            let userPermissions = payload.userPermissions;
            let avatar = null;

            if (user.hasOwnProperty('avatar'))
            {
                let imgB64 = user.avatar.imageData;
                const bstr = atob(imgB64);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                                
                avatar = new File([u8arr], "avatar.jpg", {
                    type: "image/jpeg",
                });
            }

            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: true,
                actionResult: { type: POST_AUTH_REQUEST, result: POST_AUTH_SUCCESS, token: payload.actionToken } ,
                userPermissions: payload.userPermissions,
                currentUser: payload.currentUser,
                msbBearerToken: payload.token,
                avatar: avatar,
                msalInstance: payload.msalInstance
            });
        case POST_AUTH_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                actionResult: { type: POST_AUTH_REQUEST, result: POST_AUTH_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_CURRENT_USER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_CURRENT_USER_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_CURRENT_USER_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CURRENT_USER_REQUEST, result: SAVE_CURRENT_USER_SUCCESS, token: payload.actionToken } ,
                currentUser: payload.currentUser,
            });
        case SAVE_CURRENT_USER_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CURRENT_USER_REQUEST, result: SAVE_CURRENT_USER_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case GET_USER_PAYMENT_PREFERENCES_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: GET_USER_PAYMENT_PREFERENCES_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_USER_PAYMENT_PREFERENCES_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                paymentPreferences: payload.paymentPreferences,
                actionResult: { type: GET_USER_PAYMENT_PREFERENCES_REQUEST, result: GET_USER_PAYMENT_PREFERENCES_SUCCESS, token: payload.actionToken } ,
            });
        case GET_USER_PAYMENT_PREFERENCES_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: GET_USER_PAYMENT_PREFERENCES_REQUEST, result: GET_USER_PAYMENT_PREFERENCES_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case UPDATE_USER_PAYMENT_PREFERENCES_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: UPDATE_USER_PAYMENT_PREFERENCES_REQUEST, result: null, token: payload.actionToken } ,
            });
        case UPDATE_USER_PAYMENT_PREFERENCES_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: UPDATE_USER_PAYMENT_PREFERENCES_REQUEST, result: UPDATE_USER_PAYMENT_PREFERENCES_SUCCESS, token: payload.actionToken } ,
            });
        case UPDATE_USER_PAYMENT_PREFERENCES_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: UPDATE_USER_PAYMENT_PREFERENCES_REQUEST, result: UPDATE_USER_PAYMENT_PREFERENCES_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_AVATAR_REQUEST:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_AVATAR_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_AVATAR_SUCCESS:
            if (true) {
                let user = Object.assign({}, state.currentUser);
                user.avatar = {};    
                user.avatar.imageBase64String = payload.avatar;

                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: SAVE_AVATAR_REQUEST, result: SAVE_AVATAR_SUCCESS, token: payload.actionToken } ,
                    currentUser: user
                });
            }
        case SAVE_AVATAR_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_AVATAR_REQUEST, result: SAVE_AVATAR_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case GET_AVATAR_REQUEST:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: GET_AVATAR_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_AVATAR_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: GET_AVATAR_REQUEST, result: GET_AVATAR_SUCCESS, token: payload.actionToken } ,
                avatar: payload.avatar,
            });
        case GET_AVATAR_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: GET_AVATAR_REQUEST, result: GET_AVATAR_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case CLEAR_AUTH_ACTION_RESULT:
            return Object.assign({}, state, {
                actionResult: null
            });
        default:
            return state;
    }
}
