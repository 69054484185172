import React, { useState } from "react";
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import DepartmentManagement from './components/DepartmentManagement';
import { Container, Row, Col, Spinner, Breadcrumb } from 'react-bootstrap';
import { IAppState } from '../../redux/storeTypes';
import { Client, Department as DepartmentModel } from '../../models/Client';
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { Routes } from "../../routes";
import { resetStoreDepartmentAction } from "../../redux/actions/clients/departments";
import { Crumb } from "../../models/Crumb";

export interface IDepartmentProps {
    client: Client,
    department: DepartmentModel,
    isFetching: boolean,
    isSaving: boolean
}

const Department = ({ client, department, isFetching, isSaving }: IDepartmentProps) => {
    if (department) {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path));
        crumbs.push(new Crumb(client.businessName, Routes.Client.path, resetStoreDepartmentAction()));
        crumbs.push(new Crumb(department.name, Routes.DepartmentEdit.path));

        return (
            <>
                <PageHeader crumbs={crumbs} title={"Manage Department"} />

                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <DepartmentManagement department={department} isFetching={isFetching} isSaving={isSaving} />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path));

        return (
            <>
                <PageHeader crumbs={crumbs} title={"Manage Department"} />
                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Spinner animation="border" className="mb-1" />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client,
        department: state.clients.department,
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving
    };
};

export default connect(mapStateToProps)(Department);
