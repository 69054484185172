import { CellPlugin } from '@react-page/editor';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

type Data = {
    titleLabel: string,
    subTitleLabel: string,
    infoLabel: string,
    sessionLimit: number;
    margin: string,
    padding: string,
}

const MARGIN = '0px';
const PADDING = '0px';

const SessionLimiterPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => {
        const dispatch = useDispatch();
        const [delay, setDelay] = useState(-1);
        const [show, setShow] = useState(false);
        const minutes = Math.floor(delay / 60);
        const seconds = Math.floor(delay % 60);
        useEffect(() => {
            if (data?.sessionLimit > 0) {
                setDelay(data.sessionLimit);
            }
        }, [data]);
        useEffect(() => {
            if (delay >= 0) {
                const timer = setInterval(() => {
                    setDelay(delay - 1);
                }, 1000);
                if (delay === 0) {
                    clearInterval(timer);
                }
                return () => {
                    clearInterval(timer);
                };
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [delay]);
        return (
            <>
                <Modal show={show} onHide={() => setShow(false)} backdrop="static" className="modal-confirmation">
                    <Modal.Body>
                        <div className="confirmation-body">
                            <p>
                                Ending payment session! Please wait...
                            </p>
                        </div>
                    </Modal.Body>
                </Modal>
                {delay >= 0 && <div style={{ margin: data.margin, padding: data.padding, alignItems: 'flex-end' }}>
                        <div className="text-center">
                            <div>{data.titleLabel}</div>
                            <div></div><small className="text-muted">{data.subTitleLabel}</small>
                            <div><big style={{ color: 'red' }}><b>{(minutes > 0) ? minutes + ' minute' + ((minutes > 1) ? 's' : '') : ''} {seconds} seconds</b></big></div>
                            <em><small className="text-muted">{data.infoLabel}</small></em>
                        </div>
                </div>}
            </>
        );
    },
    id: 'SessionLimiterPlugin',
    title: 'Session Limiter',
    description: 'Add a session limiter component and define its items.',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        titleLabel: {
                            type: 'string',
                            default: 'Payment Session Timer',
                        },
                        subTitleLabel: {
                            type: 'string',
                            default: 'This payment session ends in ...',
                        },
                        infoLabel: {
                            type: 'string',
                            default: 'Submit payment as soon as possible',
                        },
                        sessionLimit: {
                            type: 'number',
                            default: 900,
                        },
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                    },
                    required: [],
                },
            },
        },
    ]
};

export default SessionLimiterPlugin;