import React, { useState, useRef, useEffect, useCallback } from "react";
import { Form, ListGroup, Button } from 'react-bootstrap';
import TerminalDetails from './modals/TerminalDetails'
import { FeeCalculation } from '../../../models/Payment';
import RequiredIcon from '../../../components/RequiredIcon';
import { useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { calculateConvenienceFee, clearConveninceFee } from '../../../redux/actions/payments/paymentTransactions';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../redux/actions/toast';
import { Routes } from "../../../routes";
import { CurrencyFormatter } from '../../../components/Formatters';
import CurrencyInput from "../../../components/currency/CurrencyInput";
import _ from "lodash";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { IAppState, IActionResult } from '../../../redux/storeTypes';
import { connect } from "react-redux";
import { Order } from '../../../models/Payment';
import { handleOrders } from './handleProcessOrders'
import { User } from "../../../models/User";
import { daysDifference } from "../../../utils/globalCalculations";

import { getPaymentChannelAction } from "../../../redux/actions/clients/paymentChannels";
import { getDepartmentAction } from "../../../redux/actions/clients/departments";
import {
    processOrderAction,
    SAVE_ORDER_FAILURE, SAVE_ORDER_REQUEST, SAVE_ORDER_SUCCESS,
    CHARGEBACK_PAYMENT_REQUEST, CHARGEBACK_PAYMENT_SUCCESS, CHARGEBACK_PAYMENT_FAILURE,
    processChargebackAction,
    processChargebackReversalAction,
    CHARGEBACK_REVERSAL_SUCCESS,
    CHARGEBACK_REVERSAL_FAILURE,
    CHARGEBACK_REVERSAL_REQUEST
} from "../../../redux/actions/payments/orderManagement";
import ErrorCard from "./ErrorCard";
import { CustomerFundingType } from "../../../models/Client";
import moment from "moment";

interface SummaryProps {
    action: string;
    actionResult?: IActionResult;
    closeModal?: any;
    details: any;
    feeCalculation: FeeCalculation;
    errorMessage: string;
    isSaving?: boolean;
    paymentChannel?: any;
    isClientLoading?: boolean;
    isOrderSaving?: boolean;
    order?: Order;
    currentUser: User;
    siteKey: string;
}

const Summary = ({ details, closeModal, action, feeCalculation, errorMessage, isSaving, paymentChannel, isClientLoading, isOrderSaving, currentUser, actionResult, siteKey }: SummaryProps) => {

    const dispatch = useDispatch();
    const clientAbsorbed = details?.orderSummary?.convenienceFeeIsClientAbsorbed;
    const [redirect, setRedirect] = useState<string>("");
    const [validated, setValidated] = useState<boolean>(false);
    const [formEvent, setFormEvent] = useState<any>();
    const initialFieldsState = { amount: '', convenienceFee: '', totalAmount: '' }
    const [currencyFields, setCurrencyFields] = useState<FeeCalculation>(initialFieldsState)
    const [error, showError] = useState<string>("");
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState("");

    const [orderResult, setOrderResult] = useState<Order>(new Order());
    const [isLocalSaving, setIsLocalSaving] = useState<boolean>(false);
    const [backFillDate, setBackFillDate] = useState<any>('');

    useEffect(() => {
        if (details) {
            if (action === "Void" || action === "Chargeback Reversal" || paymentChannel?.customerFundingType === CustomerFundingType[3]) {
                setCurrencyFields(
                    {
                        amount: details.amount,
                        convenienceFee: details.convenienceFee,
                        totalAmount: details.totalAmount
                    })
            } else {
                setIsSearching(true);
                dispatch(calculateConvenienceFee(details.remainingAmount, action, details));

            }
        }
    }, [details]);

    useEffect(() => {
        if (feeCalculation) {
            setIsSearching(false);
            setCurrencyFields({
                amount: feeCalculation.amount.toString(),
                convenienceFee: feeCalculation.convenienceFee.toString(),
                totalAmount: feeCalculation.totalAmount.toString()
            })
        }
    }, [feeCalculation]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === CHARGEBACK_PAYMENT_REQUEST) {
                if (actionResult.result === CHARGEBACK_PAYMENT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Chargeback successful"));
                    setRedirect(Routes.PaymentReceipt.path);
                } else if (actionResult.result === CHARGEBACK_PAYMENT_FAILURE) {
                    dispatch(sendErrorToastAction("Chargeback failed"));
                    setIsLocalSaving(false)
                }
            }
            if (actionResult.type === CHARGEBACK_REVERSAL_REQUEST) {
                if (actionResult.result === CHARGEBACK_REVERSAL_SUCCESS) {
                    dispatch(sendSuccessToastAction("Chargeback reversal successful"));
                    setRedirect(Routes.PaymentReceipt.path);
                } else if (actionResult.result === CHARGEBACK_REVERSAL_FAILURE) {
                    dispatch(sendErrorToastAction("Chargeback reversal failed"));
                    setIsLocalSaving(false)
                }
            }
            if (actionResult.type === SAVE_ORDER_REQUEST) {
                if (actionResult.result === SAVE_ORDER_SUCCESS) {
                    dispatch(sendSuccessToastAction("Refund/Void successful"));
                    setRedirect(Routes.PaymentReceipt.path);
                } else if (actionResult.result === SAVE_ORDER_FAILURE) {
                    dispatch(sendErrorToastAction("Refund/Void failed"));
                    setPaymentErrorMessage(errorMessage);
                    setIsLocalSaving(false)
                }
            }
        }
    }, [actionResult]);

    const determineRefundMethodName = (details: any) => {
        return (details?.orderPayment?.processorType === 'VantivTriPos' && details?.orderPayment?.vantivPaymentType == 'Credit' && daysDifference(details?.createdAt) < 45) || (details?.orderPayment?.processorType === 'VantivExpress') ? 'Return' : 'Refund';
    }
    const checkRefundScenarios = (details: any) => {
        if (action === "Refund") {
            if (details?.orderPayment?.processorType === 'VantivTriPos') {
                return (
                    <TerminalDetails details={details} determineRefundMethodName={determineRefundMethodName(details)} />
                )
            }
        }
    }
    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        setIsLocalSaving(true);
        setFormEvent(form);
        if (form.checkValidity() !== false) {
            const reason = form?.elements?.reason?.value;
            const laneId = form?.elements?.terminalLaneId?.value;
            details.initiatedBy = `${currentUser.firstName} ${currentUser.lastName}`;

            const transactionDetails = {
                details: details,
                methodName: action,
                amount: parseFloat(currencyFields.amount),
                convenienceFee: parseFloat(currencyFields.convenienceFee),
                totalAmount: parseFloat(currencyFields.totalAmount),
                reason: reason,
                laneId: laneId
            }

            if (action === "Refund") {
                const refundMethodName = determineRefundMethodName(details);
                const refundOrder = { ...transactionDetails, methodName: refundMethodName };
                const _orderResult = handleOrders(refundOrder);
                setTimeout(() => dispatch(processOrderAction(_orderResult, action)), 2000);
            }
            if (action === "Chargeback") {
                const chargebackDueDate = form.elements.chargebackduedate.value;
                const chargebackOrder = { ...transactionDetails, chargebackDueDate: chargebackDueDate };
                const _orderResult = handleOrders(chargebackOrder);
                setTimeout(() => dispatch(processChargebackAction(_orderResult, action)), 2000);
            }
            if (action === "Void") {
                const voidMethodName = (details?.orderPayment?.processorType === 'VantivTriPos' || details?.transactionType === "Authorization" || details?.transactionType === "AuthorizationCommit") ? 'Reversal' : 'Void';
                const refundOrder = { ...transactionDetails, methodName: voidMethodName };
                const _orderResult = handleOrders(refundOrder);
                setTimeout(() => dispatch(processOrderAction(_orderResult, action)), 2000);
            }
            if (action === "Chargeback Reversal") {
                const backfillDate = form.elements.backfillDate.value;
                const backFilleDateUTC = moment(backfillDate).utc().format('YYYY-MM-DDTHH:mm:ss.SS');
                const _orderResult = { transactionDetails : transactionDetails?.details, transactionId: transactionDetails?.details?.msbId, postedAt: backFilleDateUTC }
                setTimeout(() => dispatch(processChargebackReversalAction(_orderResult, action)), 2000);
            }
            setValidated(false);
        }
        if (form.checkValidity() === false) {
            setValidated(true);
            setIsLocalSaving(false)
        }
    }

    const amountChange = (value: string | undefined) => {

        if (value && currencyFields.amount !== value) {
            setCurrencyFields((prevState) => ({ ...prevState, amount: value }));
            setIsSearching(true);
            debounceSearch.current(value);
        }
        if (!value) {
            setIsSearching(true);
            setCurrencyFields(initialFieldsState);
        }
    }

    const debounceSearch = useRef(
        _.debounce(searchTerm => {
            if (searchTerm > 0 && searchTerm <= details.remainingAmount) {
                dispatch(calculateConvenienceFee(searchTerm, action, details));
                showError('');
            }
            if (searchTerm > details.remainingAmount) {
                showError(`Amount should be less than or equal to $${details.remainingAmount}`)
            }
        }, 1000)
    );

    const handleCancel = (action: any) => {
        dispatch(clearConveninceFee());
        setPaymentErrorMessage("");
        if (closeModal) {
            closeModal();
        }
        else {
            if (action == 'Refund') {
                setRedirect(Routes.RefundPayment.path)
            }
            else if (action == 'Chargeback') {
                setRedirect(Routes.CreateChargeback.path)
            }
            else if (action == 'Void') {
                setRedirect(Routes.VoidPayment.path)
            }
            else if (action == 'Chargeback Reversal') {
                setRedirect(Routes.ChargebackReversals.path)
            }
        }
    }

    const validateBackFillDate = (startDateString: string) => {
        const allowedDate = moment(details?.chargeBackDueDate).format('YYYY-MM-DD');
        if (Date.parse(startDateString) <= Date.parse(allowedDate)) {
            dispatch(sendErrorToastAction(`Backfill date must be after chargeback due date i.e., ${moment(allowedDate).format('MM/DD/YYYY')}`));
            return false;
        }
        return true;
    }

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <Form id='transactionSubmit' noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="container-transaction-summary">
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <h2>{action} summary</h2>
                            <p className="mb-4">Below are the transaction details</p>
                            <div className="errorDetail">{error}</div>

                            <div className="summaryDetail">
                                <h3>Transaction ID</h3>
                                <span>{details?.orderIdentifier}</span>
                            </div>
                            <div className="summaryDetail">
                                <h3>Subtotal</h3>
                                {(action === 'Void' || action === 'Chargeback Reversal' || paymentChannel?.customerFundingType === CustomerFundingType[3])
                                    ?
                                    <ReactPlaceholder className="summaryLoading" type='text' rows={1} ready={!isClientLoading} showLoadingAnimation={true}>
                                        <>{CurrencyFormatter(details?.amount)}</>
                                    </ReactPlaceholder>
                                    :
                                    <Form.Group controlId="amount">
                                        <CurrencyInput
                                            required
                                            className="form-control"
                                            disabled={isSaving}
                                            id="summaryAmount"
                                            name="summaryAmount"
                                            placeholder="$ 0.00"
                                            maxLength={10}
                                            decimalsLimit={2}
                                            prefix="$ "
                                            value={currencyFields.amount}
                                            onValueChange={(value) => amountChange(value)}
                                        />
                                    </Form.Group>
                                }
                            </div>
                            {!clientAbsorbed &&
                                <div className="summaryDetail">
                                    <h3>Convenience fee</h3>
                                    {action === 'Void' || action === 'Chargeback Reversal'
                                        ?
                                        <>{CurrencyFormatter(details?.convenienceFee)}</>
                                        :
                                        <ReactPlaceholder className="summaryLoading" type='text' rows={1} ready={!isSearching} showLoadingAnimation={true}>
                                            <>{CurrencyFormatter(currencyFields.convenienceFee)}</>
                                        </ReactPlaceholder>
                                    }
                                </div>
                            }
                            <div className="summaryDetail">
                                {action === 'Chargeback'
                                    ?
                                    <>
                                        <h3><RequiredIcon />{`${action} due date`}</h3>
                                        <Form.Group controlId="chargebackduedate">
                                            <Form.Control required type="date" name="chargebackduedate" disabled={isSaving || isOrderSaving} />
                                            <Form.Control.Feedback type="invalid">Please select Chargeback due date.</Form.Control.Feedback>
                                        </Form.Group>
                                    </>
                                    :
                                    action === 'Chargeback Reversal' ?
                                    <>
                                    <h3><RequiredIcon />{`Backfill date`}</h3>
                                        <Form.Group controlId="backfillDate">
                                            <Form.Control required type="date" name="backfillDate" value={backFillDate} onChange={(e: any) => (validateBackFillDate(e.target.value)) ? setBackFillDate(e.target.value) : null} disabled={isSaving || isOrderSaving} />
                                            <Form.Control.Feedback type="invalid">Please select backfill date.</Form.Control.Feedback>
                                        </Form.Group>
                                    </>
                                    : null
                                    }
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div className="summaryDetail">
                                <h3>Total amount</h3>
                                {action === 'Void' || action === 'Chargeback Reversal'
                                    ?
                                    <>{CurrencyFormatter(details?.totalAmount)}</>
                                    :
                                    <ReactPlaceholder className="summaryLoading" type='text' rows={1} ready={!isSearching} showLoadingAnimation={true}>
                                        <>{CurrencyFormatter(currencyFields.totalAmount)}</>
                                    </ReactPlaceholder>
                                }
                            </div>
                            {checkRefundScenarios(details)}
                            <div className="actionBox">
                                {action != 'Chargeback Reversal' &&
                                    <Form.Group controlId="reason">
                                        <Form.Label><RequiredIcon />{`${action} reason`} </Form.Label>
                                        <Form.Control required as="textarea" rows={2} maxLength={100} placeholder={`Please enter a ${action} reason`} disabled={isSaving || isClientLoading} />
                                        <Form.Control.Feedback type="invalid">Please enter a {action} reason.</Form.Control.Feedback>
                                    </Form.Group>
                                }
                                <Form.Group className="summaryButtons">
                                    <Button type="submit" disabled={isSaving || isSearching || isOrderSaving || isLocalSaving || isClientLoading}>
                                        {`Submit ${action}`}
                                    </Button>
                                    <Button variant="link" disabled={isSaving || isOrderSaving || isLocalSaving || isClientLoading} onClick={() => handleCancel(action)}>
                                        {`Cancel ${action}`}
                                    </Button>
                                </Form.Group>
                                <ErrorCard errorMessage={paymentErrorMessage} />
                            </div>
                        </ListGroup.Item>
                    </ListGroup>

                </div>
            </Form>
        );
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        actionResult: state.orderManagement.actionResult,
        feeCalculation: state.paymentTransactions.feeCalculation,
        errorMessage: state.orderManagement.errorMessage,
        isClientLoading: state.clients.isSaving,
        paymentChannel: state.clients.paymentChannel,
        isSaving: state.paymentTransactions.isSaving,
        isOrderSaving: state.orderManagement.isSaving,
        currentUser: state.auth.currentUser,
        siteKey: state.webAppSettings.siteKey
    };
};

export default connect(mapStateToProps)(Summary);
