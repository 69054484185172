export class GilaSettings {
    depositBankAccount: GilaBankAccount = new GilaBankAccount();
    chargebackBankAccount: GilaBankAccount = new GilaBankAccount();
    chainCode: string = "";
}

export class GilaBankAccount {
    msbId?: string;
    bankAccountNumber: string = "";
    bankRoutingNumber: string = "";
}