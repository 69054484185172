import * as React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { GilaSettings } from '../../../models/GilaSettings';

type Props = {
    gilaSettings: GilaSettings;
}

const ChainCode: React.FC<Props> = ({ gilaSettings }) => {
    return (
        <Form>
            <Row>
                <Col>
                    <Form.Group controlId="chainCode">
                        <Form.Label>Chain Code</Form.Label>
                        <Form.Control type="input" disabled defaultValue={gilaSettings?.chainCode} />
                    </Form.Group>
                </Col>
                <Col></Col>
            </Row>
        </Form>
    );
};

export default ChainCode;