import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Row } from 'react-bootstrap';
import RequiredIcon from '../../../../../components/RequiredIcon';
import { MerchantProcessor, Terminal } from '../../../../../models/Client';
import { addTerminalAction, saveTerminalAction, updateTerminalAction } from '../../../../../redux/actions/clients/terminals';
import FormActions, { IFormActionProps } from '../FormActions';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import Switch from "react-switch";

export interface ITerminalFormProps {
    isSaving: boolean,
    merchant: MerchantProcessor,
    terminalMsbId?: string,
    actionToken?: string,
    formActionProps?: IFormActionProps,
    postSubmit?(): void;
}

export const ViewTerminalForm = ({ merchant, terminalMsbId, formActionProps, postSubmit }: ITerminalFormProps) => {
    let terminal = (merchant.terminals || []).find(terminal => terminalMsbId && terminal.msbId === terminalMsbId) || new Terminal();

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (postSubmit) postSubmit();
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="details-grid">
                    <strong>Terminal name</strong>
                    <span>{terminal.name}</span>
                </div>
                <div className="details-grid">
                    <strong>Terminal idle message</strong>
                    <span>{terminal.idleMessage}</span>
                </div>
                <FormActions {...formActionProps} />
            </Form>
        </>
    );
};

export const ManageTerminalForm = ({ isSaving, merchant, terminalMsbId, actionToken, formActionProps }: ITerminalFormProps) => {
    let terminal = (merchant.terminals || []).find(terminal => terminalMsbId && terminal.msbId === terminalMsbId) || new Terminal();

    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formAddAction, setFormAddAction] = useState(formActionProps?.customProps.submitButtonDisplay === 'Add');

    const [enableContactlessMagstripe, setEnableContactlessMagstripe] = useState<boolean>(terminal?.contactlessMsdEnabled ? terminal?.contactlessMsdEnabled : false);
    const [enableContactlessEMV, setEnableContactlessEMV] = useState<boolean>(terminal?.contactlessEmvEnabled ? terminal?.contactlessEmvEnabled : false);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            terminal.quickChipEnabled = false;
            terminal.name = form.elements.name.value;
            terminal.idleMessage = form.elements.name.value;
            if (formAddAction) {
                delete terminal.contactlessMsdEnabled;
                delete terminal.contactlessEmvEnabled;
                let merchantProcessorId = merchant.msbId!;
                let activationCode = form.elements.activationCode.value;
                dispatch(addTerminalAction(terminal, merchantProcessorId, activationCode, actionToken!!));
            } else {
                terminal.contactlessMsdEnabled = enableContactlessMagstripe;
                terminal.contactlessEmvEnabled = enableContactlessEMV;
                dispatch(saveTerminalAction(terminal, actionToken!!));
            }
        }
        setValidated(true);
    };

    const handleUpdateIdleMessage = (event: any) => {
        event.preventDefault();
        const form = event.target.form;

        terminal.idleMessage = form.elements.idleMessage.value;

        dispatch(updateTerminalAction(terminal, actionToken!!));
    };

    const handleUpdateContactlessSettings = (event: any) => {
        event.preventDefault();
        const form = event.target.form;

        terminal.contactlessMsdEnabled = enableContactlessMagstripe;
        terminal.contactlessEmvEnabled = enableContactlessEMV;

        dispatch(saveTerminalAction(terminal, actionToken!!));
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="name">
                            <Form.Label><RequiredIcon />Terminal name</Form.Label>
                            <Form.Control required type="input" disabled={isSaving || terminalMsbId !== ""} maxLength={40} defaultValue={terminal.name} placeholder="Enter Terminal name" />
                            <Form.Control.Feedback type="invalid">Please enter the Terminal name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                {!formAddAction && <Row>
                    <Col>
                        <Form.Group controlId="idleMessage">
                            <Form.Label><RequiredIcon />Terminal idle message</Form.Label>
                            <Form.Control required type="input" disabled={isSaving} maxLength={40} defaultValue={terminal.idleMessage} placeholder="Enter Terminal idle message" />
                            <Form.Control.Feedback type="invalid">Please enter the Terminal idle message.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>}
                {!formAddAction && <><Button type="button" onClick={handleUpdateIdleMessage} style={{ width: '250px', marginBottom: "20px" }}>Update Idle Message</Button></>}
                {!terminalMsbId ?
                    <Row>
                        <Col>
                            <Form.Group controlId="activationCode">
                                <Form.Label><RequiredIcon />Activation code</Form.Label>
                                <Form.Control required type="input" disabled={isSaving} maxLength={40} placeholder="Enter Activation code" />
                                <Form.Control.Feedback type="invalid">Please enter a valid Activation code.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    :
                    <></>
                }
                {!formAddAction ?
                    <>
                        <Row>
                            <Col>
                                <ReactPlaceholder type='text' rows={1} ready={true} delay={200} showLoadingAnimation={true}>
                                    <div className="toggle-switch">
                                        <Switch
                                            disabled={isSaving}
                                            onChange={(value) => setEnableContactlessMagstripe(value)}
                                            checked={enableContactlessMagstripe}
                                            onColor={'#0057B6'}
                                            offColor={'#BEBEBE'}
                                            handleDiameter={12}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={16}
                                            width={28}
                                            activeBoxShadow={'0 0 0 1px #0057B6'}
                                        />
                                        <span className="toggle-label">Enable Contactless Magstripe</span>
                                    </div>
                                </ReactPlaceholder>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ReactPlaceholder type='text' rows={1} ready={true} delay={200} showLoadingAnimation={true}>
                                    <div className="toggle-switch">
                                        <Switch
                                            disabled={isSaving}
                                            onChange={(value) => setEnableContactlessEMV(value)}
                                            checked={enableContactlessEMV}
                                            onColor={'#0057B6'}
                                            offColor={'#BEBEBE'}
                                            handleDiameter={12}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={16}
                                            width={28}
                                            activeBoxShadow={'0 0 0 1px #0057B6'}
                                        />
                                        <span className="toggle-label">Enable Contactless EMV</span>
                                    </div>
                                </ReactPlaceholder>
                            </Col>
                        </Row>
                        <Button type="button" onClick={handleUpdateContactlessSettings} style={{ width: '250px', marginTop: '5px', marginBottom: "20px" }}>Update Contactless Settings</Button>
                    </>
                    :
                    <></>
                }
                {formAddAction && <FormActions
                    disabled={isSaving}
                    showSpinner={isSaving}
                    {...formActionProps} />}
            </Form>
        </>
    );
};
