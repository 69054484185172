import {
    GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GET_ROLES_FAILURE,
    GET_ROLE_REQUEST, GET_ROLE_SUCCESS, GET_ROLE_FAILURE, SET_ROLES_DETAILS,
    SAVE_ROLE_REQUEST, SAVE_ROLE_SUCCESS, SAVE_ROLE_FAILURE, 
    DELETE_ROLE_REQUEST, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAILURE, 
    GET_MODULE_PERMISSIONS_REQUEST, GET_MODULE_PERMISSIONS_SUCCESS, GET_MODULE_PERMISSIONS_FAILURE,
    CANCEL_STEP,RESET_ROLES_STORE_REQUEST,RESET_ROLES_STORE_SUCCESS
} from '../actions/roles';

export default function roles(state = {
    isFetching: false,
    isSaving: false,
    errorMessage: '',
    roles: null,
    role: null,
    modulePermissions: null,
    actionResult: null
}, { type, payload }) {

    switch (type) {
        case GET_ROLES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                roles: null,
                actionResult: { type: GET_ROLES_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_ROLES_SUCCESS:
            return Object.assign({}, state, {
                roles: payload.roles,
                actionResult: { type: GET_ROLES_REQUEST, result: GET_ROLES_SUCCESS, token: payload.actionToken },
                isFetching: false
            });
        case GET_ROLES_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                actionResult: { type: GET_ROLES_REQUEST, result: GET_ROLES_FAILURE, token: payload.actionToken },
                isFetching: false
            });
        case GET_ROLE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                role: null,
                actionResult: { type: GET_ROLE_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_ROLE_SUCCESS:
            return Object.assign({}, state, {
                role: payload.role,
                isFetching: false,
                actionResult: { type: GET_ROLE_REQUEST, result: GET_ROLE_SUCCESS, token: payload.actionToken },
            });
        case GET_ROLE_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                isFetching: false,
                actionResult: { type: GET_ROLE_REQUEST, result: GET_ROLE_FAILURE, token: payload.actionToken },
            });
        case DELETE_ROLE_REQUEST:
            console.log('entered');
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_ROLE_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_ROLE_SUCCESS:
            if (true) {  //used for variable scoping
                let _role = Object.assign({}, state.roles);
                _role = state.roles.filter(role => role.msbId !== payload.msbId);
                return Object.assign({}, state, {
                    isFetching: false,
                    actionResult: { type: DELETE_ROLE_REQUEST, result: DELETE_ROLE_SUCCESS, token: payload.actionToken },
                    roles: _role
                });
            }
        case DELETE_ROLE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_ROLE_REQUEST, result: DELETE_ROLE_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case GET_MODULE_PERMISSIONS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                modulePermissions: null,
                actionResult: { type: GET_MODULE_PERMISSIONS_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_MODULE_PERMISSIONS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                modulePermissions: payload.modulePermissions,
                actionResult: { type: GET_MODULE_PERMISSIONS_REQUEST, result: GET_MODULE_PERMISSIONS_SUCCESS, token: payload.actionToken },
            });
        case GET_ROLE_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                isFetching: false,
                actionResult: { type: GET_MODULE_PERMISSIONS_REQUEST, result: GET_MODULE_PERMISSIONS_FAILURE, token: payload.actionToken },
            });
        case SET_ROLES_DETAILS:
            return Object.assign({}, state, {
                role: payload.roles
            });
        case SAVE_ROLE_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_ROLE_REQUEST, result: null, token: payload.actionToken }
            });
        case SAVE_ROLE_SUCCESS:
            return Object.assign({}, state, {
                role: payload.role,
                isSaving: false,
                actionResult: { type: SAVE_ROLE_REQUEST, result: SAVE_ROLE_SUCCESS, token: payload.actionToken }
            });
        case SAVE_ROLE_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                isSaving: false,
                actionResult: { type: SAVE_ROLE_REQUEST, result: SAVE_ROLE_FAILURE, token: payload.actionToken }
            });
        case CANCEL_STEP:
            return Object.assign({}, state, {
                isFetching: false,
                role: null
            });
        case RESET_ROLES_STORE_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: RESET_ROLES_STORE_REQUEST, result: RESET_ROLES_STORE_SUCCESS, token: payload.actionToken },
                isFetching: false,
                isSaving: false,
                errorMessage: '',
                roles: null,
                role: null,
                modulePermissions: null,
                actionResult: null
            });
        default:
            return state;
    }
}
