import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Button, ButtonToolbar, Dropdown, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { Client, ClientStatusEnum, Department, DepartmentStatusEnum } from '../../models/Client';
import { IFormActionProps } from './components/forms/FormActions';
import FormHeader from './components/forms/FormHeader';
import { Routes } from "../../routes";
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { IActionResult } from "../../redux/storeTypes";
import Table from '../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../components/Formatters';
import PermissionModule, { Permissions } from '../../components/usePermissions';
import DepartmentSummaryModal from "./components/DepartmentSummaryModal";
import { sendErrorToastAction, sendSuccessToastAction } from "../../redux/actions/toast";
import FormHeaderConfirmation from "../../components/FormHeaderConfirmation";
import icoWarning from "../../assets/img/icons/ico-warning-outline.svg";
import {
    getDepartmentAction,
    GET_DEPARTMENT_FAILURE, GET_DEPARTMENT_REQUEST, GET_DEPARTMENT_SUCCESS,
    resetStoreDepartmentAction,
    RESET_STORE_DEPARTMENT_REQUEST, RESET_STORE_DEPARTMENT_SUCCESS,
    setStoreDepartmentAction,
    DELETE_DEPARTMENT_REQUEST, DELETE_DEPARTMENT_SUCCESS, DELETE_DEPARTMENT_FAILURE,
    deleteDepartmentAction
}
    from '../../redux/actions/clients/departments';

export interface IDepartmentsProps {
    client: Client,
    formActionProps?: IFormActionProps,
    sizePerPage?: number,
    actionResult: IActionResult,
    mode: string,
}

const Departments = ({ client, sizePerPage, actionResult, mode }: IDepartmentsProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [department, setDepartment] = useState<Department>(new Department());
    const [departmentSummaryModal, setDepartmentSummaryModal] = useState<boolean>(false);
    const [departmentToDelete, setDepartmentToDelete] = useState<Department>();
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const actionToken = "Departments";
    const departmentDeleteActionToken = "DepartmentDelete";

    const onImpersonate = (departmentMsbId: string) => {
        var url = `${window.location.protocol}//${window.location.hostname}`;

        var clientDepartment: any = {};
        clientDepartment.clientMsbId = client.msbId!;
        clientDepartment.departmentMsbId = departmentMsbId;

        localStorage.setItem("impersonateDepartment", JSON.stringify(clientDepartment));
        window.open(url, '_blank');
    }

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === RESET_STORE_DEPARTMENT_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === RESET_STORE_DEPARTMENT_SUCCESS) {
                setRedirect(Routes.DepartmentAdd.path);
            }
        }
        if (actionResult && actionResult.result && actionResult.type === GET_DEPARTMENT_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === GET_DEPARTMENT_SUCCESS) {
                setRedirect(Routes.DepartmentEdit.path);
            } else if (actionResult.result === GET_DEPARTMENT_FAILURE) {

            }
        }
        if (actionResult && actionResult.result && actionResult.type === DELETE_DEPARTMENT_REQUEST && actionResult.token === departmentDeleteActionToken) {
            if (actionResult.result === DELETE_DEPARTMENT_SUCCESS) {
                dispatch(sendSuccessToastAction("Department details were successfully deleted."));
            } else if (actionResult.result === DELETE_DEPARTMENT_FAILURE) {
                dispatch(sendErrorToastAction("Department details could not be deleted."));
            }
        }
    }, [actionResult]);

    const dropDownOptions = (cell: any, department: Department) => {
        return (
            <Dropdown className="req-action-dropdown">
                <Dropdown.Toggle className="approvalAction" variant="link">
                    Manage
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <>
                            <Dropdown.Item onSelect={() => {
                                dispatch(getDepartmentAction(department.msbId!, actionToken));
                                setDepartment(department);
                            }}
                            >
                                Manage Department
                            </Dropdown.Item>
                            {/*
                            <Dropdown.Item
                                onSelect={() => {
                                    dispatch(getDepartmentAction(department.msbId!, 'departmentSummary'));
                                    setDepartmentSummaryModal(true);
                                }}
                            >
                                Department Summary
                            </Dropdown.Item>
                            */}
                            <Dropdown.Item
                                onSelect={() => { doDeleteDepartment(department) }}
                            >
                                Delete Department
                            </Dropdown.Item>
                        </>
                    </PermissionModule>
                    <Dropdown.Item onClick={() => onImpersonate(department.msbId!)} style={{ borderTop: '1px solid #F0F0F0', marginTop: '4px', paddingTop: '12px', boxShadow: 'inset 0px 4px 0px 0px #fff' }}>
                        Impersonate Department User
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const doDeleteDepartment = (department: Department) => {
        setDepartmentToDelete(department);
        setShowConfirmationModal(true);
    }

    const deleteDepartment = (e: any) => {
        dispatch(deleteDepartmentAction(departmentToDelete!.msbId!, departmentDeleteActionToken))
        setShowConfirmationModal(false);
    }

    const renderConfirmationModal = () => (
        <Modal show={showConfirmationModal} className="modal-confirmation">
            <Modal.Body>
                <FormHeaderConfirmation iconImg={icoWarning} title="Delete client" />
                <div className="confirmation-body">
                    <p>Are you sure you want to delete this client?</p>
                    <Form.Group className="confirmation-footer">
                        <Button variant="outline-secondary" onClick={() => setShowConfirmationModal(false)}>
                            Cancel
                        </Button>
                        <Button onClick={(e) => deleteDepartment(e)}>
                            Confirm
                        </Button>
                    </Form.Group>
                </div>
            </Modal.Body>
        </Modal>
    );

    const cellRouteFormatter = (cell: any, department: Department) => {
        return (
            <a tabIndex={0} onClick={() => {
                dispatch(getDepartmentAction(department.msbId!, actionToken));
                setDepartment(department);
            }}>{cell}</a>
        );
    }

    const cellStatusFormatter = (cell: number, row: Department) => {
        let value = "Unknown"
        let color: string = "#D9D9D9";

        if (row.onBoardingStatus == DepartmentStatusEnum[DepartmentStatusEnum.Initialized]) {
            value = "Initial setup";
            color = "#FADB14";
        } else if (row.onBoardingStatus == DepartmentStatusEnum[DepartmentStatusEnum.ApprovalPending]) {
            value = "Setup approval pending";
            color = "#FAAD14";
        } else if (row.onBoardingStatus == DepartmentStatusEnum[DepartmentStatusEnum.ApprovalRejected]) {
            value = "Setup approval rejected";
            color = "#F5222D";
        } else if (row.onBoardingStatus == DepartmentStatusEnum[DepartmentStatusEnum.Complete]) {
            value = "Complete";
            color = "#52C41A";
        } else {
            value = "Setup in progress";
            color = "#0057B6";
        }

        return (
            <span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: `${color}` }} />{value}</span>
        );
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        }, {
            dataField: 'name',
            text: 'Department',
            editable: false,
            formatter: cellRouteFormatter
        }, {
            dataField: 'updatedAt',
            text: 'Last updated',
            formatter: DateFormatter,
            editable: false
        }, {
            dataField: 'onBoardingStatus',
            text: 'Status',
            editable: false,
            sort: true,
            formatter: cellStatusFormatter
        }, {
            dataField: 'id', //Editable?
            text: 'Actions',
            editable: false,
            formatter: dropDownOptions
        }
    ];

    function addDepartmentAction() {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => {
                        dispatch(resetStoreDepartmentAction(actionToken));
                    }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Department
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                {renderConfirmationModal()}
                {mode !== 'tableView' ? <FormHeader title="Client Departments" description="Below are the client's departments." /> : null}

                <Modal size={'lg'} show={departmentSummaryModal} onHide={() => setDepartmentSummaryModal(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <DepartmentSummaryModal closeModal={() => setDepartmentSummaryModal(false)} />
                    </Modal.Body>
                </Modal>

                <div className="manage-card">
                    {client.departments &&
                        <ToolkitProvider keyField="id" data={client.departments} columns={columns} search>
                            {
                                (props: any) => (
                                    <PageSectionContainer title={`${client.businessName} Departments`} toolbar={addDepartmentAction()}>
                                        <Table
                                            keyField="msbId"
                                            data={client.departments}
                                            columns={columns}
                                        />
                                    </PageSectionContainer>
                                )
                            }
                        </ToolkitProvider>
                    }
                </div>

                {mode !== 'tableView' ?
                    <div className="manage-footer">
                        <Button type="button" variant="link" onClick={() => setRedirect(Routes.Clients.path)} style={{ float: "right" }}>Cancel</Button>
                    </div>
                    : null}
            </>
        );
    }
};

export default Departments;