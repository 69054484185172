import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import PageHeader from '../../components/layout/PageHeader';
import Optics from '../optics/Optics'
import Approvals from '../approvals/Approvals';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import AdGroup from './components/adGroup/AdGroup';
import { Container, Col, Row } from 'react-bootstrap';
import { IAppState } from '../../redux/storeTypes';
import { User, UserTypeEnum } from "../../models/User";
import { Crumb } from '../../models/Crumb';
import { Routes } from '../../routes';
import AllTransactionsWidget from './components/AllTransactionsWidget';
import PaymentsWidget from './components/PaymentsWidget';
import DeclinedCardsWidget from './components/DeclinedCardsWidget';
import PermissionModule, { Permissions } from '../../components/usePermissions';
import moment from 'moment';
import { getClientTimezoneAction } from '../../redux/actions/clients/clients';
interface IDashboardOverview {
    currentUser: User
}

const DashboardOverview = ({ currentUser }: IDashboardOverview) => {
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Dashboard", Routes.DashboardOverview.path));
    const dispatch = useDispatch();
    const timeZone = moment.tz.guess();
    console.log(`${timeZone}`, moment.tz(timeZone).zoneAbbr());

    useEffect(() => {
        dispatch(getClientTimezoneAction('Dashboard'));
    }, []);

    if (currentUser.userTypeEnum === UserTypeEnum.Navient) {
        return (
            <>
                <PageHeader title="Dashboard" crumbs={crumbs} />

                <Container fluid className="container-adGroup">
                    <PageSectionContainer>
                        <AdGroup />
                    </PageSectionContainer>
                </Container>

                {/* <Optics /> */}
                <Approvals />

                <Container fluid>
                    <Row>
                        <Col xl={4} md={6} className="mb-4">
                            <AllTransactionsWidget/>
                        </Col>
                        <Col xl={4} md={6} className="mb-4">
                            <PaymentsWidget/>
                        </Col>
                        <Col xl={4} md={6} className="mb-4">
                            <DeclinedCardsWidget />
                        </Col>
                    </Row>
                </Container>

            </>
        )
    } else {
        return (
            <>
                <PageHeader title="Dashboard" crumbs={crumbs} />

                <Container fluid className="container-adGroup">
                    <PageSectionContainer>
                        <AdGroup />
                    </PageSectionContainer>
                </Container>

                {/* <Optics /> */}

                <Container fluid>
                    <Row>
                        <PermissionModule permission={[Permissions.AllTransactionExecute]}>
                            <Col xl={4} md={6} className="mb-4">
                                <AllTransactionsWidget />
                            </Col>
                        </PermissionModule>
                        <PermissionModule permission={[Permissions.PaymentsExecute]}>
                            <Col xl={4} md={6} className="mb-4">
                                <PaymentsWidget />
                            </Col>
                        </PermissionModule>
                        <PermissionModule permission={[Permissions.DeclinedExecute]}>
                            <Col xl={4} md={6} className="mb-4">
                                <DeclinedCardsWidget />
                            </Col>
                        </PermissionModule>
                    </Row>
                </Container>

            </>
        )
    }
}


const mapStateToProps = (state: IAppState) => {
    return {
        currentUser: state.auth.currentUser
    };
};

export default connect(mapStateToProps)(DashboardOverview);