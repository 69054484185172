import React from "react";
import { ExportToCsv } from 'export-to-csv';
import _ from "lodash";
import moment from "moment";
import { ConvenienceFeeFormatter, CurrencyAmountFormatter } from "../../../components/Formatters";
import { customizedOrderLinesCSVTableHeaders } from "./ExportTableColumns";
import { dateCalculations, removeCommas } from "./Export";

export const OrderLinesExport = (data: Array<any>, fileName: any, clientNames?: any, departmentNames?: any, paymentChannelNames?: any, searchCriteria?: any) => {
    return new Promise(resolve => {
        const formattedData = getNameFormatters(data, clientNames, departmentNames, paymentChannelNames).flat(3);
        const dateRange = dateCalculations(searchCriteria);
        const options = {
            filename: `${fileName} Items Report_${dateRange}`,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Payment Transactions',
            useTextFile: false,
            useBom: true,
        };

        const csvExporter = new ExportToCsv(options);
        setTimeout(() => {
            csvExporter.generateCsv(formattedData);
            resolve('resolved');
        }, 1000)

    });
};

const getNameFormatters = (data: any, clientNames: any, departmentNames: any, paymentChannelNames: any) => {

    const nameFormatters = data.map((items: any) => {
        const clientName = clientNames.find((cn: any) => cn.msbId === items.clientId);
        const departmentName = departmentNames.find((dn: any) => dn.msbId === items.departmentId);
        const paymentChannelName = paymentChannelNames.find((dn: any) => dn.msbId === items.paymentChannelId);
        return {
            ...items,
            clientName: clientName?.businessName || items.clientId,
            departmentName: departmentName?.name || items.departmentId,
            paymentChannelName: paymentChannelName?.name || items.paymentChannelId,
        }
    })

    const formattedOrderLines = nameFormatters.map((orderDetail: any) => {
        const innerOrderLine = orderDetail.orderLines.map((orderLine: any) => {
            return { ...orderDetail, orderLine }
        })
        return innerOrderLine
    })

    const groupByFormat =
        _.chain(_.flatten(formattedOrderLines))
            .groupBy((item: any) => `${item.clientName} - ${item.departmentName} - ${moment.utc(item.createdAt).local().format('MM-DD-YYYY')} - ${item.orderLine.itemName}`)
            .map((value: any, key): any => (
                [
                    [[[key]]],
                    [[[customizedOrderLinesCSVTableHeaders]]],
                    value.map((transactionDetail: any) => {
                        return [[[
                            removeCommas(transactionDetail?.clientName) || '',
                            removeCommas(transactionDetail?.departmentName) || '',
                            removeCommas(transactionDetail?.paymentChannelName) || '',
                            removeCommas(transactionDetail?.initiatedBy) || '',
                            removeCommas(transactionDetail?.orderIdentifier) || '',
                            removeCommas(transactionDetail?.lastFourOnCard) || '',
                            removeCommas(transactionDetail?.orderStatus == 'Declined' ? transactionDetail?.processorMessage : ''),
                            removeCommas(transactionDetail?.orderLine?.itemReferenceNumber) || '',
                            removeCommas(transactionDetail?.orderLine?.itemName) || '',
                            removeCommas(negateOrderLineColumnFields(transactionDetail, transactionDetail?.orderLine?.unitPrice, true)) || '',
                            negateOrderLineColumnFields(transactionDetail, transactionDetail?.orderLine?.quantity,false) || '',
                            removeCommas(calculatedItemTotal(transactionDetail)) || '',
                            removeCommas(moment.utc(transactionDetail?.createdAt).local().format('MM-DD-YYYY h:mm a')) || '',
                            removeCommas(transactionDetail?.transactionType) || '',
                            removeCommas(transactionDetail?.nameOnCard) || '',
                            removeCommas(transactionDetail?.orderPayment?.cardLogo) || '',
                            removeCommas(transactionDetail?.orderSummary?.internalCardType.replace(/([a-z])([A-Z])/g, '$1 $2')) || '',
                            removeCommas(transactionDetail?.phone) || '',
                            removeCommas(transactionDetail?.email) || '',
                            removeCommas(transactionDetail?.addressLine1) || '',
                            removeCommas(transactionDetail?.addressLine2) || '',
                            removeCommas(transactionDetail?.city) || '',
                            removeCommas(transactionDetail?.state) || '',
                            removeCommas(transactionDetail?.zip) || '',
                            removeCommas(JSON.stringify(transactionDetail?.orderLine?.itemMetadata) || '')
                        ]]]
                    }),
                    [totalTransactionsCalculation(value)]
                ])).value().reverse();

    return groupByFormat
}

const negateOrderLineColumnFields = (transactionDetail: any, value: any, isCurrency?: boolean) => {

    const adjustedOrderLine = (transactionDetail?.transactionType != "Sale" && transactionDetail?.transactionType != "AuthorizationCommit" && transactionDetail?.transactionType != "ChargebackReversal")
        ? -Math.abs(value) : value

    return isCurrency ? CurrencyAmountFormatter.format(adjustedOrderLine) : adjustedOrderLine as any

}

const calculatedItemTotal = (transactionDetail: any) => {

    const adjustedOrderLine = CurrencyAmountFormatter.format((transactionDetail?.transactionType != "Sale" && transactionDetail?.transactionType != "AuthorizationCommit" && transactionDetail?.transactionType != "ChargebackReversal")
        ? -Math.abs(transactionDetail?.orderLine?.unitPrice * transactionDetail?.orderLine?.quantity) : (transactionDetail?.orderLine?.unitPrice * transactionDetail?.orderLine?.quantity))

    return adjustedOrderLine as any

}


const totalTransactionsCalculation = (transactionDetails: any): any => {

    const transactionTypeCalculation = transactionDetails.map((details: any) => {
        return {
            'itemAmount': (details?.transactionType != "Sale" && details?.transactionType != "AuthorizationCommit" && details?.transactionType != "ChargebackReversal") ?
                -Math.abs(details?.orderLine?.unitPrice * details?.orderLine?.quantity)
                : (details?.orderLine?.unitPrice * details?.orderLine?.quantity),
            'quantityTotal': (details?.transactionType != "Sale" && details?.transactionType != "AuthorizationCommit" && details?.transactionType != "ChargebackReversal") ?
                -Math.abs(details?.orderLine?.quantity) : (details?.orderLine?.quantity)
        }
    });

    const orderLineUnitPriceCalculation = [
        {
            'calculatedItemAmount': _.sumBy(transactionTypeCalculation, 'itemAmount'),
            'calculatedItemQuantity': _.sumBy(transactionTypeCalculation, 'quantityTotal')

        }
    ]

    const orderLineCSVTotalsFormatter = orderLineUnitPriceCalculation.map((value: any, index: any) => {

        return [[
            `${index === 0 ? 'Totals' : ''}`, '', '', '', '', '', '', '', '', '',
            removeCommas(value.calculatedItemQuantity),
            removeCommas(CurrencyAmountFormatter.format(value.calculatedItemAmount))
        ]]
    })

    return (orderLineCSVTotalsFormatter)
}

