import {
    GET_CLIENT_ROLES_REQUEST, GET_CLIENT_ROLES_SUCCESS,
    CANCEL_STEP,SET_CLIENT_ROLES_DETAILS
    
} from '../actions/clientRoles';

export default function clientRoles(state = {
    isFetching: false,
    errorMessage: '',
    currentPage: null,
    clientRole: null

}, { type, payload }) {
   
    switch (type) {
        case GET_CLIENT_ROLES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case GET_CLIENT_ROLES_SUCCESS:
            return Object.assign({}, state, {
                currentPage: payload.results,
                isFetching: false
            });
        case SET_CLIENT_ROLES_DETAILS:
            return Object.assign({}, state, {
                clientRole: payload.roles
            });
        case CANCEL_STEP:
            return Object.assign({}, state, {
                isFetching: false,
                clientRole: null
            });
        default:
            return state;
    }
}
