import React,{ useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { paymentsFormSchema } from './components/AdvancedSearchColumns';
import Report, { AlignRightFormatter, getContentUrl } from './components/Report';
import { DateFormatter, CurrencyFormatter } from '../../components/Formatters';
import { Redirect } from 'react-router-dom';
import { Routes } from "../../routes";
import { PaymentTransactions, TransactionTypeEnum } from '../../models/Payment';
import { transactionDetailAction } from '../../redux/actions/payments/paymentTransactions';
import { ReportType } from "../../models/Reports";
import { setReportColumns } from "../../redux/actions/reports/report";
import { IAppState } from "../../redux/storeTypes";

export interface IPaymentReportProps {
    reportColumns: Map<ReportType, Array<any>>;
}

const FeeAuditReport = ({ reportColumns }: IPaymentReportProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const reportTitle = 'Fee Audit Report';
    const reportType =  ReportType.FeeAudit;
    const reportRoute = Routes.FeeAudit.path;
    const transactionType = TransactionTypeEnum.Unknown;

    useEffect(() => {
        if (reportColumns && !reportColumns.has(reportType)) {
            const columns = [
                {
                    dataField: 'msbId',
                    text: 'ID',
                    editable: false,
                    sort: false,
                    hidden: true,
                    default: true,
                    configurable: false
                },
                {
                    dataField: 'clientId',
                    text: 'Client',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'departmentId',
                    text: 'Department',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'paymentChannelId',
                    text: 'Payment Channel',
                    editable: false,
                    sort: true,
                    default: false,
                    configurable: true
                }, {
                    dataField: 'transactionType',
                    text: 'Transaction type',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'createdAt',
                    text: 'Transaction date',
                    editable: false,
                    formatter: DateFormatter,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'nameOnCard',
                    text: 'Customer name',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'totalAmount',
                    text: 'Total amount',
                    editable: false,
                    sort: true,
                    formatter: CurrencyFormatter,
                    default: true,
                    configurable: true
                }
            ];

            dispatch(setReportColumns(reportType, columns));
        }
    }, []);

    const rowStyle = { backgroundColor: '#E6F7FD' };

    if (redirect != "") {
        return <Redirect push to={redirect} />;
    } else {
        return (
            <Report
                reportTitle={reportTitle} 
                reportType={reportType} 
                breadCrumbRoute={reportRoute}
                transactionType={transactionType}
                formSchema={paymentsFormSchema} 
                rowStyle={rowStyle}
            />
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        reportColumns: state.reports.reportColumns
    };
};

export default connect(mapStateToProps)(FeeAuditReport);
