import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Routes } from "../../routes";
import Sidebar from "../../components/Sidebar";
import Header from "../Header";
import Footer from "../../components/Footer";
import IdleTimer from "../../components/IdleTimer";
import ToastContainer from "../../components/ToastContainer";

const RouteWithSidebar = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }

    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    if (rest.isAuthenticated) {
        return (
            <Route {...rest} render={props => (
                <>
                    {/* <Preloader show={loaded ? false : true} /> */}
                    <Sidebar />

                    <main className="content">
                        <Header />
                        <IdleTimer />
                        <ToastContainer />
                        <Component {...props} />
                        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
                    </main>
                </>
            )}
            />
        );
    } else {
        return (
            <Redirect to={Routes.SignIn.path} />
        )
    }
};

const mapStateToProps = state => {
    return { isAuthenticated: state.auth.isAuthenticated };
};

export default connect(mapStateToProps)(RouteWithSidebar);