import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import RequiredIcon from '../../../components/RequiredIcon';
import { IActionResult } from '../../../redux/storeTypes';
import { clearActionResult, saveGilaSettings, SAVE_GILA_SETTINGS_REQUEST, SAVE_GILA_SETTINGS_SUCCESS, SAVE_GILA_SETTINGS_FAILURE } from '../../../redux/actions/gilaSettings';
import { sendSuccessToastAction, sendErrorToastAction } from '../../../redux/actions/toast';
import { GilaSettings } from '../../../models/GilaSettings';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

type Props = {
    isFetching: boolean,
    isSaving: boolean,
    gilaSettings: GilaSettings,
    actionResult: IActionResult
}

const GilaBankAccounts: React.FC<Props> = ({ isFetching, isSaving, gilaSettings, actionResult }) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    let actionToken = "GilaBankAccounts";

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() !== false) {
            gilaSettings.depositBankAccount.bankAccountNumber = form.elements.depositBankAccountNumber.value;
            gilaSettings.depositBankAccount.bankRoutingNumber = form.elements.depositBankRoutingNumber.value;
            gilaSettings.chargebackBankAccount.bankAccountNumber = form.elements.chargebackBankAccountNumber.value;
            gilaSettings.chargebackBankAccount.bankRoutingNumber = form.elements.chargebackBankRoutingNumber.value;
            dispatch(saveGilaSettings(gilaSettings, actionToken));
        }
        setValidated(true);
    };

    useEffect(() => {
        const depositBankAccountNumberInput = (document.getElementById('depositBankAccountNumber') as HTMLInputElement);
        const confirmDepositBankAccountNumberInput = (document.getElementById('confirmDepositBankAccountNumber') as HTMLInputElement);
        confirmDepositBankAccountNumberInput?.addEventListener('paste', function (event) {
            event.preventDefault();
        })
        confirmDepositBankAccountNumberInput?.addEventListener('blur', function (event) {
            if (depositBankAccountNumberInput?.value !== confirmDepositBankAccountNumberInput?.value) {
                confirmDepositBankAccountNumberInput.setCustomValidity('Account number mismatch');
            } else {
                confirmDepositBankAccountNumberInput.setCustomValidity('');
            }
        });

        const depositBankRoutingNumberInput = (document.getElementById('depositBankRoutingNumber') as HTMLInputElement);
        const confirmDepositBankRoutingNumberInput = (document.getElementById('confirmDepositBankRoutingNumber') as HTMLInputElement);
        confirmDepositBankRoutingNumberInput?.addEventListener('paste', function (event) {
            event.preventDefault();
        })
        confirmDepositBankRoutingNumberInput?.addEventListener('blur', function (event) {
            if (depositBankRoutingNumberInput?.value !== confirmDepositBankRoutingNumberInput?.value) {
                confirmDepositBankRoutingNumberInput.setCustomValidity('Routing number mismatch');
            } else {
                confirmDepositBankRoutingNumberInput.setCustomValidity('');
            }
        });

        const chargebackBankAccountNumberInput = (document.getElementById('chargebackBankAccountNumber') as HTMLInputElement);
        const confirmChargebackBankAccountNumberInput = (document.getElementById('confirmChargebackBankAccountNumber') as HTMLInputElement);
        confirmChargebackBankAccountNumberInput?.addEventListener('paste', function (event) {
            event.preventDefault();
        })
        confirmChargebackBankAccountNumberInput?.addEventListener('blur', function (event) {
            if (chargebackBankAccountNumberInput?.value !== confirmChargebackBankAccountNumberInput?.value) {
                confirmChargebackBankAccountNumberInput.setCustomValidity('Charge account number mismatch');
            } else {
                confirmChargebackBankAccountNumberInput.setCustomValidity('');
            }
        });

        const chargebackBankRoutingNumberInput = (document.getElementById('chargebackBankRoutingNumber') as HTMLInputElement);
        const confirmChargebackBankRoutingNumberInput = (document.getElementById('confirmChargebackBankRoutingNumber') as HTMLInputElement);
        confirmChargebackBankRoutingNumberInput?.addEventListener('paste', function (event) {
            event.preventDefault();
        })
        confirmChargebackBankRoutingNumberInput?.addEventListener('blur', function (event) {
            if (chargebackBankRoutingNumberInput?.value !== confirmChargebackBankRoutingNumberInput?.value) {
                confirmChargebackBankRoutingNumberInput.setCustomValidity('Charge routing number mismatch');
            } else {
                confirmChargebackBankRoutingNumberInput.setCustomValidity('');
            }
        });


    }, []);

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_GILA_SETTINGS_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_GILA_SETTINGS_SUCCESS) {
                dispatch(sendSuccessToastAction("Gila settings were successfully saved."));
            } else if (actionResult.result === SAVE_GILA_SETTINGS_FAILURE) {
                dispatch(sendErrorToastAction("Gila settings could not be saved."));
            }
            dispatch(clearActionResult());
        }
    }, [actionResult]);

    const renderSpinner = (isSaving: boolean) => {
        if (isSaving) {
            return (<Spinner as="span" animation="border" size="sm" className={"button-spinner"} />);
        } else {
            return (<></>);
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <h3>Deposit account</h3>
                </Col>
            </Row>
            <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                    <Form.Group controlId="depositBankAccountNumber">
                        <Form.Label><RequiredIcon />Gila bank account number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control type="number" maxLength={32} required defaultValue={gilaSettings?.depositBankAccount.bankAccountNumber} disabled={isSaving} className="fs-mask" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Gila bank account number required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="confirmDepositBankAccountNumber">
                        <Form.Label><RequiredIcon />Confirm Gila bank account number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control maxLength={32} type='number' required disabled={isSaving} className="fs-mask" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Gila bank account number must match</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                    <Form.Group controlId="depositBankRoutingNumber">
                        <Form.Label><RequiredIcon />Gila bank routing number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control type="number" maxLength={32} required defaultValue={gilaSettings?.depositBankAccount.bankRoutingNumber} disabled={isSaving} className="fs-mask" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Gila bank routing number required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="confirmDepositBankRoutingNumber">
                        <Form.Label><RequiredIcon />Confirm Gila bank routing number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required maxLength={32} type='number' disabled={isSaving} className="fs-mask" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Gila bank routing number must match</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col>
                    <h3>Chargeback account</h3>
                </Col>
            </Row>
            <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                    <Form.Group controlId="chargebackBankAccountNumber">
                        <Form.Label><RequiredIcon />Gila bank account number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control type="input" pattern="^\d*$" maxLength={10} required defaultValue={gilaSettings?.chargebackBankAccount.bankAccountNumber} disabled={isSaving} className="fs-mask" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Gila bank routing number required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="confirmChargebackBankAccountNumber">
                        <Form.Label><RequiredIcon />Confirm Gila bank account number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control type="input" pattern="^\d*$" maxLength={10} required disabled={isSaving} className="fs-mask" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Gila bank account number must match</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                    <Form.Group controlId="chargebackBankRoutingNumber">
                        <Form.Label><RequiredIcon />Gila bank routing number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control type="input" pattern="^\d*$" maxLength={9} required defaultValue={gilaSettings?.chargebackBankAccount.bankRoutingNumber} disabled={isSaving} className="fs-mask" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Gila bank routing number required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="confirmChargebackBankRoutingNumber">
                        <Form.Label><RequiredIcon />Confirm Gila bank routing number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" pattern="^\d*$" maxLength={9} disabled={isSaving} className="fs-mask" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Gila bank routing number must match</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button type="submit" disabled={isSaving}>
                        Save
                    </Button>
                    {renderSpinner(isSaving)}
                </Col>
            </Row>
        </Form >
    );
}

export default GilaBankAccounts;