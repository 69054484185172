import { Row, Col, Button, Modal, Form } from "react-bootstrap"
import Table from '../../../../../../components/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

interface IMathewsShoppingCart {
    title: string,
    subTitle: string,
    typeLabel: string,
    accountYearLabel: string,
    accountNumberLabel: string,
    ticketNumberFirst7Label: string,
    ticketNumberLast4Label: string,
    parcelIdLabel: string,
    nameLabel: string,
    addressLabel: string,
    modelLabel: string,
    totalAmountDueLabel: string,
    classLabel: string,
    valueLabel: string,
    actionsLabel: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string
}

export const TITLE = 'Shopping Cart';
export const SUBTITLE = ''
export const TYPE_LABEL = 'Type';
export const ACCOUNT_YEAR_LABEL = 'Account Year';
export const ACCOUNT_NUMBER_LABEL = 'Account Number';
export const PARCEL_ID_LABEL = 'Parcel ID';
export const TICKET_NUMBER_FIRST_7 = 'Ticket Number First7';
export const TICKET_NUMBER_LAST_4 = 'Ticket Number Last4';
export const NAME_LABEL = 'Name';
export const ADDRESS_LABEL = 'Address';
export const MODEL_LABEL = 'Model';
export const CLASS_LABEL = 'Class';
export const VALUE_LABEL = 'Value';
export const TOTAL_AMOUNT_DUE_LABEL = 'Total Amount Due';
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';
export const CART_BUTTON_FONT_COLOR = '#414141';
export const CART_BUTTON_FONT_SIZE = '.875rem';
export const CART_BUTTON_FONT_STYLE = 'normal';
export const CART_BUTTON_FONT_WEIGHT = 'normal';
export const CART_BUTTON_BORDER_COLOR = '#D9D9D9';
export const CART_BUTTON_BACKGROUND_COLOR = '#FFFFFF';
export const CART_ICON_COLOR = '#414141';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

const MathewsShoppingCart = ({ title, subTitle, accountNumberLabel, typeLabel, accountYearLabel, ticketNumberFirst7Label, ticketNumberLast4Label, parcelIdLabel, nameLabel, addressLabel, modelLabel, classLabel, valueLabel, totalAmountDueLabel, actionsLabel,
    margin, padding, headerBackgroundColor, headerPadding, cartButtonBackgroundColor, cartButtonFontColor, cartButtonFontSize, cartButtonFontStyle, cartButtonFontWeight, cartButtonBorderColor, cartIconColor }: IMathewsShoppingCart) => {

    if (!title) { title = TITLE }
    if (!subTitle) { subTitle = SUBTITLE }
    if (!typeLabel) { typeLabel = TYPE_LABEL }
    if (!accountYearLabel) { accountYearLabel = ACCOUNT_YEAR_LABEL }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!parcelIdLabel) { parcelIdLabel = PARCEL_ID_LABEL }
    if (!nameLabel) { nameLabel = NAME_LABEL }
    if (!ticketNumberFirst7Label) { ticketNumberFirst7Label = TICKET_NUMBER_FIRST_7 }
    if (!ticketNumberLast4Label) { ticketNumberLast4Label = TICKET_NUMBER_LAST_4 }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL }
    if (!modelLabel) { modelLabel = MODEL_LABEL }
    if (!classLabel) { classLabel = CLASS_LABEL }
    if (!valueLabel) { valueLabel = VALUE_LABEL }
    if (!totalAmountDueLabel) { totalAmountDueLabel = TOTAL_AMOUNT_DUE_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }
    if (!cartButtonBackgroundColor) { cartButtonBackgroundColor = CART_BUTTON_BACKGROUND_COLOR }
    if (!cartButtonFontColor) { cartButtonFontColor = CART_BUTTON_FONT_COLOR }
    if (!cartButtonFontSize) { cartButtonFontSize = CART_BUTTON_FONT_SIZE }
    if (!cartButtonFontStyle) { cartButtonFontStyle = CART_BUTTON_FONT_STYLE }
    if (!cartButtonFontWeight) { cartButtonFontWeight = CART_BUTTON_FONT_WEIGHT }
    if (!cartButtonBorderColor) { cartButtonBorderColor = CART_BUTTON_BORDER_COLOR }
    if (!cartIconColor) { cartIconColor = CART_ICON_COLOR }


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const [showModal, setShowModal] = useState<boolean>(false);

    const initialColumns = [{
        dataField: 'type',
        text: typeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'accountYear',
        text: accountYearLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'accountNumber',
        text: accountNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'ticketNumberFirst7',
        text: ticketNumberFirst7Label,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'ticketNumberLast4',
        text: ticketNumberLast4Label,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'parcelId',
        text: parcelIdLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'name',
        text: nameLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'address',
        text: addressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'model',
        text: modelLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'class',
        text: classLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'value',
        text: valueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'totalAmountDue',
        text: totalAmountDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,
        sort: true,
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const renderTable = () => {
        return (
            <div style={{ marginTop: '12px' }}>
                <Table
                    keyField="msbId"
                    data={[]}
                    columns={initialColumns}
                />
            </div>);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    <Button className="brand-btn-primary" style={{
                        marginTop: '29px',
                        backgroundColor: checkStyleForDefault(cartButtonBackgroundColor, CART_BUTTON_BACKGROUND_COLOR),
                        borderColor: checkStyleForDefault(cartButtonBorderColor, CART_BUTTON_BORDER_COLOR),
                        color: checkStyleForDefault(cartButtonFontColor, CART_BUTTON_FONT_COLOR),
                        fontSize: checkStyleForDefault(cartButtonFontSize, CART_BUTTON_FONT_SIZE),
                        fontStyle: checkStyleForDefault(cartButtonFontStyle, CART_BUTTON_FONT_STYLE),
                        fontWeight: checkStyleForDefault3(cartButtonFontWeight)
                    }} onClick={() => setShowModal(true)}>
                        <FontAwesomeIcon icon={faCartPlus} style={{ color: checkStyleForDefault(cartIconColor, CART_ICON_COLOR) }} className="btn-icon" />{`View Cart (count)`}
                    </Button>
                </Col>
            </Row>
            <Modal show={showModal} size={'xl'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form.Group>
                        {renderTable()}
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default MathewsShoppingCart;