import { NIL } from "uuid";

export enum TemplateType
{
    Unknown            = "Unknown",
    AutoPaymentAlert   = "AutoPaymentAlert",
    FailedPaymentAlert = "FailedPaymentAlert",
    StandardReceipt    = "StandardReceipt",
    DepartmentSummary = "DepartmentSummary"
}

export enum EmailType
{
    Unknown = "Unknown",
    Alert   = "Alert",
    Receipt = "Receipt",
    Other   = "Other"
}

export class EmailRequest {
    emailType: EmailType = EmailType.Unknown;
    templateType: TemplateType = TemplateType.Unknown;
    recipientAddresses: Array<string> = [];
    transactionId: string = "";
    subject: string = "";
    model: any;
    renderedBody: string = "";
}