export enum AuthorizationStatus {
    Unknown = 0,
    PendingAuthorization = 1,
    AuthorizationConfirmed = 2,
    AuthorizationRevoked = 3,
    AuthorizationCancelled = 4
}

export enum AuthorizationEmailStatus {
    Unknown = 0,
    Dispatched = 1,
    Success = 2,
    Errored = 3
}

export enum AccountInformationType {
    Unknown = 0,
    Personal = 1,
    Corporate = 2
}

export enum AccessPermissionType {
    Unknown = 0,
    User = 1,
    Client = 2,
    Department = 3,
    PaymentChannel = 4
}

export enum BankAccountType {
    Unknown = 0,
    Checking = 1,
    Savings = 2,
}

export class PermissionLink {
    accessPermissionType: keyof typeof AccessPermissionType = AccessPermissionType[AccessPermissionType.Unknown] as keyof typeof AccessPermissionType;
    authorizationStatus: keyof typeof AuthorizationStatus = AccessPermissionType[AuthorizationStatus.Unknown] as keyof typeof AuthorizationStatus;
    resourceId: string = "";
    createdAt: string = "";
}

export class AccessPermission {
    accessPermissionType: keyof typeof AccessPermissionType = AccessPermissionType[AccessPermissionType.Unknown] as keyof typeof AccessPermissionType;
    userIdentifier: string = "";
    permissionLinks: Array<PermissionLink> = new Array<PermissionLink>();
}

export class Wallet {
    
}

export enum WalletType {
    Unknown = 0,
    Personal = 1,
    PersonalShared = 2,
    Corporate = 3,
    CorporateShared = 4
}

export enum WalletAccessType {
    Unknown = 0,
    WalletContent = 1,
    PerAccount = 2
}

export enum OwnershipType {
    Unknown = 0,
    User = 1,
    UserCollection = 2,
    Department = 3,
    PaymentChannel = 4
}

export class BankAccount {
    id: number = 0;
    msbId: string = "";
    accessPermission: AccessPermission = new AccessPermission();
    accountInformationType: AccountInformationType = AccountInformationType.Unknown;
    accountHolderName: string = "";
    accountNumber: string = "";
    bankAccountType: string = "";
    isArchived: boolean = false;
    isPrimaryAccount: boolean = false;
    routingNumber: string = "";
}

export class Card {
    id: number = 0;
    msbId: string = "";
    accessPermission: AccessPermission = new AccessPermission();
    accountInformationType: AccountInformationType = AccountInformationType.Unknown;
    token: string = "";
    expirationDate: string = "";
    vantivCardType: string = "";
    lastFourOnCard: string = "";
    isPrimaryCard: boolean = false;
    nameOnCard: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    zipCode: string = "";
    internalCardType: string = "";
    isArchived: boolean = false;
}

export class TokenizeBankAccount {
    msbId?: string;
    msbWalletIdentifier: number = 0;
    walletType: WalletType = WalletType.CorporateShared;
    bankAccountType: BankAccountType = BankAccountType.Unknown;
    paymentChannelId?: string;
    accountHolderEmailAddress: string = "";
    isUserInitiated: boolean = false;
    accountHolderName: string = "";
    accountNumber: string = "";
    routingNumber: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    zipCode: string = "";
}

export class TokenizeCard {
    msbId?: string;
    expirationMonth: string = "";
    expirationYear: string = "";
    cardNumber: string = "";
    cvv: string = "";
    cardLogo: string = "";
    avsResponseCode: string = "";
    cvvResponseCode: string = "";
    cardNumberMasked: string = "";
    bin: string = "";
    cardLevelResults: string = "";
    msbWalletIdentifier: number = 0;
    walletOwnerEmailAddress: string = "";
    walletType: number = 0;
    internalCardType: number = 0;
    paymentChannelId?: string;
    cardHolderEmailAddress: string = "";
    isUserInitiated: boolean = false;
    nameOnCard: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    zipCode: string = "";
    terminalId: string = "";
    laneId: number = 0;
    isCardPresent: boolean = false;
}