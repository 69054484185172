export default class Approval  {
    msbId: string = "";
    referenceMsbId: string = "";
    referenceName: string = "";
    approvalTypeEnum: ApprovalTypeEnum = ApprovalTypeEnum.SetupApproval;
    comments: string = "";
    createdAt: Date = new Date();
    updatedAt: Date = new Date();
}

export enum StatusEnum
{
    Pending = 1,
    Approved = 2,
    Rejected = 3
}

export enum ApprovalTypeEnum
{
    SetupApproval = 1,
    ClientBankAccount = 2
}

export class ApprovalRejectedRequest
{
    approvalMsbId: string = "";
    comments: string = "";
}