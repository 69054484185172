import React, { useState } from "react";
import Switch from "react-switch";
import { Col, Row, Form, Button } from 'react-bootstrap';

interface ConfigureEmailProps {
    closeModal?: any;
}

const ConfigureEmail = ({ closeModal }: ConfigureEmailProps) => {

    const [active, setActive] = useState(true);

    const handleActiveOnChange = (value: boolean) => {
        setActive(value);
    }

    return (
        <>
            <Form>
                <Row className="emailRecurrence mt-0">
                    <h2>Configure report email recurrence</h2>
                    <p>Select the options below to customize your emailed report recurrence</p>
                </Row>
                <Row className="emailRecurrence">
                    <Form.Group as={Col} xs="4" controlId="calendarFormat">
                        <Form.Label>Format</Form.Label>
                        <Form.Control required as="select">
                            <option value="CSV">CSV</option>
                            <option value="PDF">PDF</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="calendarRecurrence">
                        <Form.Label>Recurrence</Form.Label>
                        <Form.Control required as="select">
                            <option value="Day">Daily for previous day</option>
                            <option value="Month">Every Monday for previous week</option>
                            <option value="Week">First of each month for previous month</option>
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row className="emailRecurrence" style={{ marginTop: '8px' }}>
                    <Col>
                        <div className="toggle-switch">
                            <Switch
                                onChange={handleActiveOnChange}
                                checked={active}
                                onColor={'#0057B6'}
                                offColor={'#BEBEBE'}
                                handleDiameter={12}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={16}
                                width={28}
                                activeBoxShadow={'0 0 0 1px #0057B6'}
                            />
                            <span className="toggle-label">Recurrence Active</span>
                        </div>
                    </Col>
                </Row>
                <div className="emailRecurrenceButtons">
                    <Button type="submit">
                        Save
                    </Button>
                    <Button form='emailRecurrenceCancel' className="emailRecurrenceCancel" variant="link" onClick={closeModal}>
                        Cancel
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default ConfigureEmail;