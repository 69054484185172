import moment from "moment";
import { ClientName, ClientTimezone, DepartmentName, PaymentChannelName } from "../models/Client";
import { CurrencyAmountFormatter, timeZonesMap } from "./Formatters";

export const CSVCurrencyFormatter = (amount: any) => {
    if (!isNaN(amount)) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const formattedAmount = formatter.format(Number(amount));
        return formattedAmount.toString()
    }
    return ('');
}


export const CSVConvenienceFeeFormatter = (cell: any, row: any, forExports?: boolean) => {
    const isClientAbsorbed = row?.convenienceFeeIsClientAbsorbed || row?.orderSummary?.convenienceFeeIsClientAbsorbed;

    return (isClientAbsorbed ? '$0.00' : forExports ? CurrencyAmountFormatter.format(cell) : CSVCurrencyFormatter(cell))

}

export const CSVDateFormatter = (date: string) => {
    if (date) {
        const dateString = moment.utc(date).local().format('MM-DD-YYYY h:mm a');
        return (
            dateString.toString()
        );
    }
    else { return ('') }
}


export const CSVClientNameFormatter = (clientMsbId:string, clientNames: Array<ClientName>) => {
    if (clientNames) {
        var clientName = clientNames.find(cn => cn.msbId === clientMsbId);
        if (clientName) {
            return (clientName.businessName.toString());
        } 
    }
    return (clientMsbId.toString());
}

export const CSVClientTimeZoneCellFormatter = (clientMsbId:string, row:any, rowIndex:number, timeZones: Array<ClientTimezone>) => {
    return (CSVClientTimeZoneFormatter(row, timeZones));
}

export const CSVClientTimeZoneFormatter = (row:any, timeZones: Array<ClientTimezone>) => {
    if (timeZones) {
        var clientTimezone = timeZones.find(tz=> tz.clientId === row?.eCheckPayment?.clientId);
        if (clientTimezone) {
            return (clientTimezone.timeZone.toString());
        } 
    }
    return ('Unknown Timezone');
}

export const CSVClientTimeZoneDateCellFormatter = (createdAt:string, row:any, rowIndex:number, timeZones: Array<ClientTimezone>) => {
    return (CSVClientTimeZoneDateFormatter(createdAt,row,timeZones));
}

export const CSVClientTimeZoneDateFormatter = (createdAt: string, row: any, timeZones: Array<ClientTimezone>) => {
    if (timeZones) {
        var clientTimezone = timeZones.find(tz => tz.clientId === row?.eCheckPayment?.clientId)!;
        const formattedTimeZone = timeZonesMap[clientTimezone?.timeZone] ? `${timeZonesMap[clientTimezone?.timeZone]}` : null
        if (formattedTimeZone) {
            return (moment.utc(row?.createdAt).tz(formattedTimeZone).format('MM-DD-YYYY h:mm a').toString());
        }
    }
    return ('Unknown Timezone');
}

export const CSVCentralTimeZoneDateCellFormatter = (cell:any, row:any) =>{
    return (moment.utc(row?.createdAt).tz('America/Chicago').format('MM-DD-YYYY h:mm a').toString());
}

export const CSVClientNameCellFormatter = (clientMsbId:string, row:any, rowIndex:number, clientNames: Array<ClientName>) => {
    return (CSVClientNameFormatter(clientMsbId, clientNames));
}

export const CSVDepartmentNameFormatter = (departmentMsbId:string, departmentNames: Array<DepartmentName>) => {
    if (departmentNames) {
        var departmentName = departmentNames.find(cn => cn.msbId === departmentMsbId);
        if (departmentName) {
            return (departmentName.name.toString());
        } 
    }
    return (departmentMsbId.toString());
}

export const CSVPaymentChannelNameCellFormatter = (paymentChannelMsbId:string, row:any, rowIndex:number, paymentChannelNames: Array<PaymentChannelName>) => {
    return (CSVPaymentChannelFormatter(paymentChannelMsbId, paymentChannelNames));
}

export const CSVPaymentChannelFormatter = (paymentChannelMsbId:string, paymentChannelNames: Array<PaymentChannelName>) => {
    if (paymentChannelNames) {
        var paymentChannelName = paymentChannelNames.find(cn => cn.msbId === paymentChannelMsbId);
        if (paymentChannelName) {
            return (paymentChannelName.name.toString());
        } 
    }
    return (paymentChannelMsbId.toString());
}

export const CSVDepartmentNameCellFormatter = (departmentMsbId:string, row:any, rowIndex:number, departmentNames: Array<DepartmentName>) => {
    return (CSVDepartmentNameFormatter(departmentMsbId, departmentNames));
}
