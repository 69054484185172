import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { Department, AutoReceiptEmailAddress, PaymentChannel } from '../../../../../models/Client';
import "react-placeholder/lib/reactPlaceholder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Table from '../../../../../components/Table';
import FormHeaderConfirmation from "../../../../../components/FormHeaderConfirmation";
//@ts-ignore
import icoWarning from "../../../../../assets/img/icons/ico-warning-outline.svg";
import { getPaymentChannelAction, savePaymentChannelAction } from "../../../../../redux/actions/clients/paymentChannels";
import { IActionResult, IAppState } from "../../../../../redux/storeTypes";

export interface IDepartmentAutoReceiptsFormProps {
    department: Department,
    paymentChannel: PaymentChannel,
    paymentChannelMsbId?: string,
    actionResult: IActionResult,
}

const DepartmentAutoReceiptsForm = ({ department, paymentChannel, paymentChannelMsbId, actionResult }: IDepartmentAutoReceiptsFormProps) => {
    const actionToken = "DepartmentPaymentChannelsSection";
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const [updateEmailAddresses, setUpdateEmailAddresses] = useState(false);
    const [removeEmailAddress, setRemoveEmailAddress] = useState("");
    const [emailAddresses, setEmailAddresses] = useState<Array<AutoReceiptEmailAddress>>([]);


    React.useEffect(() => {
        if (paymentChannelMsbId) dispatch(getPaymentChannelAction(paymentChannelMsbId, actionToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (paymentChannel) {
            setEmailAddresses(paymentChannel?.autoReceiptEmailAddresses);
        }
    }, [paymentChannel])

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    useEffect(() => {
        if (updateEmailAddresses) {
            let _paymentChannel: PaymentChannel = Object.assign(new PaymentChannel(), paymentChannel);
            _paymentChannel.autoReceiptEmailAddresses = emailAddresses;
            _paymentChannel.processors = _paymentChannel.processors.map(p => { return { merchantProcessorId: p.merchantProcessorId, paymentChannelId: p.paymentChannelId } });
            dispatch(savePaymentChannelAction(_paymentChannel, actionToken));
            setUpdateEmailAddresses(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateEmailAddresses]);

    const addEmail = (event: any) => {
        event.preventDefault();
        const form = event.target;
        const _emailAddress = form.elements.email?.value;
        if (!emailAddresses.find(e => e.emailAddress === _emailAddress)) {
            setEmailAddresses([...emailAddresses, { emailAddress: _emailAddress }]);
            setUpdateEmailAddresses(true);
        }
        form.reset();
    }

    const removeEmail = () => {
        setConfirm(false);
        setEmailAddresses(emailAddresses.filter(e => e.emailAddress !== removeEmailAddress));
        setUpdateEmailAddresses(true);
    }

    const deleteEmail = (row: any) => {
        setRemoveEmailAddress(row.emailAddress);
        setConfirm(true);
    }

    const actionsFormatter = (cell: any, _row: any) => {
        return (
            <Button variant="outline-secondary" onClick={() => deleteEmail(_row)} style={{ marginLeft: "8px" }}>
                <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
        )
    }

    const columns = [
        {
            dataField: 'emailAddress',
            text: 'Email Address',
            editable: false,
            sort: true,
            width: '180',
        }, {
            dataField: 'index',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter
        }
    ];

    return (
        <>
            <Modal show={confirm} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Delete Email" />
                    <div className="confirmation-body">
                        <p>Are you sure you want to delete this Email?</p>
                        <Form.Group className="confirmation-footer">
                            <Button variant="outline-secondary" onClick={() => setConfirm(false)}>
                                Cancel
                            </Button>
                            <Button onClick={() => removeEmail()}>
                                Confirm
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>

            <Form onSubmit={addEmail}>
                <Row>
                    <Col>
                        <Form.Group controlId="email">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control required type="email" placeholder="Email Address" maxLength={80} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>&nbsp;</Form.Label>
                            <div style={{ float: 'right' }}>
                                <Button variant="primary" type="submit">
                                    <FontAwesomeIcon icon={faPlus} size="sm" style={{ marginRight: "0" }} />
                                </Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col>
                    {emailAddresses.length > 0 ?
                        <div className="manage-card">
                            <Table
                                keyField="emailAddress"
                                defaultSorted={[{ dataField: "emailAddress", order: "asc" }]}
                                columns={columns}
                                data={emailAddresses}
                            />
                        </div>
                        :
                        <h2 className="text-center"><i>Auto Receipts is empty</i></h2>
                    }
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        actionResult: state.clients.actionResult,
        paymentChannel: state.clients.paymentChannel,
    };
};

export default connect(mapStateToProps)(DepartmentAutoReceiptsForm);
