export class Crumb {
    name: string;
    route: string;
    dispatch?: any;

    constructor(name: string, route: string, dispatch?: any) {
        this.name = name;
        this.route = route;
        this.dispatch = dispatch;
    }
}