import { useState } from "react";
import { Button } from "react-bootstrap"
import { Redirect } from "react-router-dom";

interface INavigationLink{
    text: string,
    path: string,
    align: string,
    backgroundColor: string,
    fontColor: string,
    fontSize: string,
    fontStyle: string,
    fontWeight: string,
    textAlign: string,
    textDecoration: string,
    textTransform: string,
    margin: string,
    padding: string,
}

export const ALIGN ='left';
export const BACKGROUND_COLOR = '#0057b6';
export const FONT_COLOR = '#FFFFFF';
export const FONT_SIZE = '.875rem';
export const FONT_STYLE = 'normal';
export const FONT_WEIGHT = 'normal';
export const TEXT_ALIGN = 'left';
export const TEXT_DECORATION = 'none';
export const TEXT_TRANSFORM = 'none';
export const MARGIN = '0px';
export const PADDING = '0.563rem 1rem';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
    return style && style !== ''?style:defaultStyle;
}
  
const checkStyleForDefault2 = (style:number|undefined, defaultStyle:number) => {
    return style?style:defaultStyle;
}
  
const checkStyleForDefault3 = (defaultStyle:string) => {
    return defaultStyle as any;
}

const NavigationLink = ({ text, path, align, backgroundColor, fontColor, fontSize, fontStyle, fontWeight, textAlign, textDecoration, textTransform, margin, padding }: INavigationLink) => {
    const [redirect, setRedirect] = useState<string>("");
  
    const doOnClick = () => {
        setRedirect(path);
    }

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (<div style={{textAlign:checkStyleForDefault3(align)}}><Button onClick={doOnClick}
            style={{
                backgroundColor:checkStyleForDefault(backgroundColor, BACKGROUND_COLOR),
                borderColor:checkStyleForDefault(backgroundColor, BACKGROUND_COLOR),
                color:checkStyleForDefault(fontColor, FONT_COLOR),
                fontSize:checkStyleForDefault(fontSize, FONT_SIZE),
                fontStyle:checkStyleForDefault(fontStyle, FONT_STYLE),
                fontWeight:checkStyleForDefault3(fontWeight),
                textAlign:checkStyleForDefault3(textAlign),
                textDecoration:checkStyleForDefault3(textDecoration),
                textTransform:checkStyleForDefault3(textTransform),
                margin:checkStyleForDefault(margin, MARGIN),
                padding:checkStyleForDefault(padding, PADDING),
              }}
        
        >{text}</Button></div>)
    }
}

export default NavigationLink;