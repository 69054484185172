import React, { useState } from "react";
import { Breadcrumb } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { Crumb } from "../../models/Crumb";
import { Routes } from "../../routes";

interface PageHeaderProps {
    crumbs?: Array<Crumb>;
    title?: string;
}

export default ({ crumbs, title} : PageHeaderProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");

    const handleClick = (evt:any, crumb: Crumb) => {
        evt.preventDefault();

        if (crumb.dispatch) {
            dispatch(crumb.dispatch);
        }

        if (window.location.pathname !== crumb.route) {
            setRedirect(evt.target.href.substring(window.location.origin.length));
        }
    }

    if (!crumbs) {
        crumbs = new Array<Crumb>();
    }

    if (redirect !== "") {
        return(<Redirect push to={redirect} />);
    }

    return (
        <>
            <div className="page-header d-print-none">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item key={0} href={Routes.DashboardOverview.path} onClick={(e:any) => handleClick(e, new Crumb("Home", Routes.DashboardOverview.path))} >Home</Breadcrumb.Item>
                    {crumbs.map((crumb, index) => 
                         <Breadcrumb.Item key={index+1} href={crumb.route} onClick={(e:any) => handleClick(e, crumb)} >{crumb.name}</Breadcrumb.Item>
                    )}
                </Breadcrumb>
                <h1>{title}</h1>
            </div>
        </>
    );
};
