import React, { useState, useEffect } from "react";
import { Form, FormControl, Row, Button, Col } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { Client, ApprovalChecklist, ClientStatusEnum } from '../../../../models/Client';
import FormActions from './FormActions';
import { saveApprovalChecklistAction } from '../../../../redux/actions/clients/approvalChecklist';

const getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key];

export interface IApprovalChecklistFormProps {
    client: Client,
    actionToken: string
}

export default ({ client, actionToken }: IApprovalChecklistFormProps) => {
    const [validated, setValidated] = useState(false);
    const [submitButtonLabel, setSubmitButtonLabel] = useState('Save');
    const dispatch = useDispatch();
    const approvalChecklist = /*client.approvalChecklist ||*/ new ApprovalChecklist();

    const approvalList = [
        { label: "Client Application", id: "merchantApplication", value: false },
        { label: "W9 signed by Client", id: "w9Signed", value: false },
        { label: "Client signed ACH form", id: "achFormSigned", value: false },
        { label: "Client verbally confirmed ACH information", id: "verballyConfirmedACH", value:false },
        { label: "Client Payment Processing contract", id: "paymentProcessingContract", value: false },
        { label: "Completion of OFAC Sanctions check", id: "ofacSanctions", value: false },
        { label: "Completion of a FINCEN check", id: "fincen", value: false },
        { label: "Government entity exists at the location on the forms provided", id: "governmentLocationExists", value: false },
        { label: "Audit Reviewed and Confirmed Convenience Fees", id: "convenienceFees", value: false }
    ]

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            /*
            approvalChecklist.clientMsbId = client.msbId!!;
            approvalChecklist.w9Signed = form.elements.w9Signed.checked;
            approvalChecklist.merchantApplication = form.elements.merchantApplication.checked;
            approvalChecklist.verballyConfirmedACH = form.elements.verballyConfirmedACH.checked;
            approvalChecklist.achFormSigned = form.elements.achFormSigned.checked;
            approvalChecklist.paymentProcessingContract = form.elements.paymentProcessingContract.checked;
            approvalChecklist.ofacSanctions = form.elements.ofacSanctions.checked;
            approvalChecklist.fincen = form.elements.fincen.checked;
            approvalChecklist.governmentLocationExists = form.elements.governmentLocationExists.checked;
            approvalChecklist.convenienceFees = form.elements.convenienceFees.checked;
            approvalChecklist.comments = form.elements.comments.value;
            dispatch(saveApprovalChecklistAction(approvalChecklist, actionToken));
            */
        }

        setValidated(true);
    };

    const handleFormChange = (event: any) => {
        const form = event.currentTarget;
        let selectedCheckboxCount = 0;
        for (const { id, label } of approvalList) {
            if (form.elements[id].checked) {
                selectedCheckboxCount += 1;
            }
        }
        if (selectedCheckboxCount === approvalList.length) {
            setSubmitButtonLabel('Submit for approval');
        } else {
            setSubmitButtonLabel('Save');
        }
    }

    const isDisabled = () => {
        /*
        if (client.onBoardingStatus === ClientStatusEnum.OnboardingWizardMerchantId || client.onBoardingStatus === ClientStatusEnum.ApprovalRejected) {
            return false;
        } else {
            return true;
        }
        */
        return false;
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} onChange={handleFormChange}>
            {approvalList.map((item, index) => (
                <Row key={index}>
                    <Col>
                        <Form.Group controlId={item.id}>
                            <Form.Check label={item.label} defaultChecked={getKeyValue(item.id)(approvalChecklist)} disabled={isDisabled()} />
                        </Form.Group>
                    </Col>
                </Row>
            ))}
            <Row className="mb-0">
                <Col md={6} sm={12}>
                    <Form.Group controlId="comments" className="approval-comments">
                        <Form.Label>Comments</Form.Label>
                        <FormControl as="textarea" rows={3} placeholder="Add any additional details" disabled={isDisabled()} />                        
                    </Form.Group>
                </Col>
            </Row>

            <div className="manage-footer">
                <FormActions hasSubmit disabled={isDisabled()} customProps={{ submitButtonDisplay: submitButtonLabel }} />
            </div>
        </Form>
    );
};
