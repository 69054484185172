import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { IAppState } from '../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import { Role } from '../../../models/Role'
import { setRolesAction } from '../../../redux/actions/roles';
import _ from "lodash";


const ActionTabs = ({ crudPermissions, role, roleType }: any) => {

    const [rolePermissions, setRolePermissions] = useState<any[]>([]);
    const [permissionUpdate, setPermissionUpdate] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (crudPermissions) {
            setRolePermissions(crudPermissions);
            const selectedList = crudPermissions.filter((permissions: any) => permissions.selected === true).length;
            if (selectedList > 1) {
                setRolePermissions([...crudPermissions.map((list: any) =>
                ({
                    ...list,
                    disable: (list.crudPermission === "Read" ? true : false)
                }))
                ])
            }
        }
    }, [crudPermissions]);


    useEffect(() => {

        if (permissionUpdate) {

            if (role === null) {
                role = new Role();
            }

            const selectedPermission = rolePermissions[0].selectedPermission;
            const selectedValues = rolePermissions.filter(item => item.selected === true).map((operations) =>
            ({
                modulePermission: {
                    crudPermission: operations.crudPermission,
                    name: operations.name,
                    tag: operations.tag,
                    description: operations.description,
                    id: operations.id,
                    userTypeEnum: roleType
                }
            }));

            const roleDetails = role?.roleModulePermissions?.map((fields: any) => fields?.modulePermission);
            const index = roleDetails.findIndex((e: any) => e.description == selectedPermission?.description);
            if (index === -1) {
                role.roleModulePermissions.push(selectedValues);
            } else {
                role.roleModulePermissions = [...role.roleModulePermissions.filter((item: any) => item.modulePermission.description != selectedPermission?.description), ...selectedValues];
            }
            role.roleModulePermissions = [...new Set([].concat(...role.roleModulePermissions))];
            dispatch(setRolesAction(role));
        }
    }, [permissionUpdate,rolePermissions])


    const rolesAction = (event: any, permission: any) => {

        permission.selected = event;
        setRolePermissions([...rolePermissions.map((permissionsList: any) =>
        ({
            ...permissionsList,
            selectedPermission: permission
        })
        )]);
        const checkCrudPermission = _.includes(["Read", "Execute"], permission?.crudPermission);
        if (!checkCrudPermission && event) {
            setRolePermissions([...rolePermissions.map((permissionsList: any) =>
            ({
                ...permissionsList,
                selected: (permissionsList.crudPermission === "Read" ? true : permissionsList.selected),
                disable: (permissionsList.crudPermission === "Read" ? true : false),
                selectedPermission: permission
            }))
            ]);
        }
        if (!event) {
            const enableRead = rolePermissions.filter(item => item.selected === true).length;
            if (enableRead === 1) {
                setRolePermissions([...rolePermissions.map((list: any) => ({ ...list, disable: false, selectedPermission: permission }))]);
            }
        }
        setPermissionUpdate(true);
    }


    return (
        <>
            {rolePermissions != null ?
                <div className="roleSwitch">
                    {Object.entries(rolePermissions).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index} className="toggle-switch">
                                    <Switch
                                        onChange={(event) => rolesAction(event, value)}
                                        checked={rolePermissions[index].selected}
                                        onColor={'#0057B6'}
                                        offColor={'#BEBEBE'}
                                        handleDiameter={12}
                                        disabled={rolePermissions[index].disable}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={16}
                                        width={28}
                                        activeBoxShadow={'0 0 0 1px #0057B6'}
                                    />
                                    <span className="roleType toggle-label">{value.crudPermission === 'Execute' ? 'Yes' : value.crudPermission}</span>
                                </div>
                            )
                        })
                    }
                </div> : null}
        </>
    );

};

const mapStateToProps = (state: IAppState) => {
    return {
        role: state.roles.role

    };
};

export default connect(mapStateToProps)(ActionTabs);


