import { CellPlugin } from '@react-page/editor';
import { Button } from 'react-bootstrap';

type Data = {
  text: string,
}

const LABEL = 'View Balance and Detailed Receipt';

const TylerEagleRedirectPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <div style={{ margin: 'auto' }}>
        <Button variant="outline-secondary">{data.text}</Button>
      </div>
  ),
  id: 'tylerEagleRedirectPlugin',
  title: 'Tyler Eagle Redirect',
  description: 'Tyler Eagle Redirect button.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        text: {
          type: 'string',
          default: LABEL,
        },
      },
      required: ['text'],
    },
  },
};

export default TylerEagleRedirectPlugin;