import React from "react";
import { Container } from 'react-bootstrap';
import PageHeader from '../../components/layout/PageHeader';
import { ReceiptTypeEnum } from "../../models/Client";
import { Crumb } from "../../models/Crumb";
import { Routes } from "../../routes";
import Receipt from "../receipts/Receipt";
import OrderReceipt from "./components/OrderReceipt";

const QuickPayReceipt = () => {
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Quick Pay Receipt", Routes.QuickPayReceipt.path));

    return (
        <>
            <PageHeader title="Quick Pay Receipt" crumbs={crumbs} />
            <Container fluid>
                <Receipt orderedPage="QuickPay"  />
            </Container>
        </>
    )
};

export default QuickPayReceipt; 