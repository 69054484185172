import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, useHistory } from "react-router-dom";
import { Provider, useDispatch } from 'react-redux'

import store from "./redux/store";
import { createHashHistory, createBrowserHistory } from 'history';

// core styles
import "./scss/nexus.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import { PublicClientApplication, EventType } from "@azure/msal-browser";

let _url = window.location.protocol + "//" + window.location.host + "/nexus/settings";

var  msalInstance;

axios.post(_url)
.then(function (response) {
    let webAppSettings = response.data;
    let b2cRedirectUri = webAppSettings.b2CRedirectUri;
    let b2cClientId = webAppSettings.b2CClientId;
    let b2cAuthority =  webAppSettings.b2CAuthority;
    let b2cAuthorityAD =  webAppSettings.b2CAuthorityAD;
    let environment = webAppSettings.environment;

    let pathname = window.location.pathname;

    if (pathname.toLowerCase() === '/navient') {
      b2cAuthority = b2cAuthorityAD;
    } else {
      const urlSearchParams = new URLSearchParams(window.location.search);
      if (urlSearchParams.has("tylereagle")) {
       
        var tylereagle = urlSearchParams.get("tylereagle");
        localStorage.setItem('tylereagle', tylereagle);
      }
    }

    let msalConfig = {
      auth: {
          clientId: b2cClientId,
          authority: b2cAuthority,
          knownAuthorities: [b2cAuthority],
          redirectUri: b2cRedirectUri,
          postLogoutRedirectUri: "/"
      }};

    if (environment != 'DEV') {
      msalInstance = new PublicClientApplication(msalConfig);
      const accounts = msalInstance.getAllAccounts();

      if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
      } 

      msalInstance.addEventCallback((event) => {
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
              const payload = event.payload;
              const account = payload.account;
              msalInstance.setActiveAccount(account);

              window.location.reload();
          }
      });

      msalInstance.handleRedirectPromise().then((authResult) => {
          const account = msalInstance.getActiveAccount();
          if(!account){
            msalInstance.loginRedirect();
          }
        }).catch(err=>{
          console.log(err);
        }
      );
    }

})
.catch(function (error) {
    console.log(error);
})
.then(function () {
    ReactDOM.render(
      <Provider store={store}>
          <Router>
              <ScrollToTop />
              <HomePage msalInstance={msalInstance} />
          </Router>
      </Provider>,
    document.getElementById("root")
  );
}); 


