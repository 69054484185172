import axios from 'axios';
import { Dispatch } from 'react';
import { WebApplication, WebPage } from '../../../models/PaymentChannelWebApplication';
import store from "../../store";

export const GET_WEB_APPLICATION_REQUEST = 'GET_WEB_APPLICATION_REQUEST';
export const GET_WEB_APPLICATION_SUCCESS = 'GET_WEB_APPLICATION_SUCCESS';
export const GET_WEB_APPLICATION_FAILURE = 'GET_WEB_APPLICATION_FAILURE';

export const SAVE_WEB_APPLICATION_REQUEST = 'SAVE_WEB_APPLICATION_REQUEST';
export const SAVE_WEB_APPLICATION_SUCCESS = 'SAVE_WEB_APPLICATION_SUCCESS';
export const SAVE_WEB_APPLICATION_FAILURE = 'SAVE_WEB_APPLICATION_FAILURE';

export const SAVE_WEB_PAGE_REQUEST = 'SAVE_WEB_PAGE_REQUEST';
export const SAVE_WEB_PAGE_SUCCESS = 'SAVE_WEB_PAGE_SUCCESS';
export const SAVE_WEB_PAGE_FAILURE = 'SAVE_WEB_PAGE_FAILURE';

export const GET_WEB_PAGE_REQUEST = 'GET_WEB_PAGE_REQUEST';
export const GET_WEB_PAGE_SUCCESS = 'GET_WEB_PAGE_SUCCESS';
export const GET_WEB_PAGE_FAILURE = 'GET_WEB_PAGE_FAILURE';

export const RESET_WEB_APPLICATION_REQUEST = 'RESET_WEB_APPLICATION_REQUEST';

export const getWebApplicationForPaymentChannelAction = (paymentChannelmsbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_WEB_APPLICATION_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/WebApplications/ForPaymentChannel/" + paymentChannelmsbId;

    axios.get(_url)
        .then(function (response) {
            let webApplication = response.data;
            dispatch({
                type: GET_WEB_APPLICATION_SUCCESS,
                payload: {
                    webApplication,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_WEB_APPLICATION_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getWebPageAction = (msbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_WEB_PAGE_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Client/api/v1/WebPages/" + msbId;

    axios.get(_url)
        .then(function (response) {
            let webPage = response.data;
            dispatch({
                type: GET_WEB_PAGE_SUCCESS,
                payload: {
                    webPage,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_WEB_PAGE_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const saveWebPageAction = (webPage:WebPage, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_WEB_PAGE_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Client/api/v1/WebPages"

    _url = _url + "/" + webPage.msbId;

    axios.put(_url, webPage)
        .then(function (response) {
            webPage = response.data;
            dispatch({
                type: SAVE_WEB_PAGE_SUCCESS,
                payload: {
                    webPage,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_WEB_PAGE_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveWebApplicationAction = (webApplication:WebApplication, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_WEB_APPLICATION_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Client/api/v1/WebApplications"

    _url = _url + "/" + webApplication.msbId;

    axios.put(_url, webApplication)
        .then(function (response) {
            webApplication = response.data;
            dispatch({
                type: SAVE_WEB_APPLICATION_SUCCESS,
                payload: {
                    webApplication,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_WEB_APPLICATION_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const resetWebApplicationStoreAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: RESET_WEB_APPLICATION_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: RESET_WEB_APPLICATION_REQUEST,
        payload: {
            actionToken
        }
    });
};
