import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Routes } from "../../routes";
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { IAppState } from "../../redux/storeTypes";
import { Crumb } from "../../models/Crumb";
import { Client } from '../../models/Client';
import { resetClientsStoreAction } from "../../redux/actions/clients/clients";
import ClientDetailsStep from "./components/addClientSteps/ClientDetailsStep";

export interface IClientAddProps {
    client: Client
}

const ClientAdd = ({ client }: IClientAddProps) => {
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Clients", Routes.Clients.path, resetClientsStoreAction));
    crumbs.push(new Crumb("Add Client", Routes.ClientAdd.path));
    return (
        <>
            <PageHeader title="Add Client" crumbs={crumbs} />            
                <Container fluid>
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <ClientDetailsStep client={client} />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>            
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client
    };
};

export default connect(mapStateToProps)(ClientAdd);
