export class Content  {
    msbId?: string;
    title: string = "";
    value: string = "";
    revisions: Array<Revision> = new Array<Revision>();
    clientId: string = "";
    groupId?:string;
}

export class Revision {
    msbId?: string;
    statusEnum: StatusEnum = StatusEnum.Draft;
    value: string = "";
}

export enum StatusEnum
{
    Draft = 0,
    Published = 1,
    History = 2
}