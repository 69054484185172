
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Routes } from "../routes";
import { IAppState } from '../redux/storeTypes';
import { User, UserClient, UserTypeEnum } from '../models/User';
import { TylerEagleRequest } from '../models/TylerEagleIntegration';
import { setTylerEagleRequestAction } from '../redux/actions/tylerEagleIntegration';

interface SignInProps {
    user: User
}

const SignIn = ({user}:SignInProps) => {
    const dispatch = useDispatch();  

    if (user) {
        if (user.userTypeEnum == UserTypeEnum.Navient) {
            return (<Redirect push to={Routes.DashboardOverview.path} />);
        } else if (user.userTypeEnum == UserTypeEnum.Client) {
            var tylereagle = localStorage.getItem('tylereagle');
            localStorage.removeItem('tylereagle');
            if (tylereagle) {
                var tylerEagleRequest = JSON.parse(tylereagle) as TylerEagleRequest;
                dispatch(setTylerEagleRequestAction(tylerEagleRequest));
            }
            return (<Redirect push to={Routes.GetUserClient.path} />);
        } else {
            return (<><div className="authorizing">Not authorized</div></>);
        }
    } else {    
        return (<><div className="authorizing">Authorizing...</div></>);
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        user : state.auth.currentUser
    };
};

export default connect(mapStateToProps)(SignIn);
