import React  from "react";
import PageHeader from '../../../../components/layout/PageHeader';
import RoleTabsContainer from './RoleTabsContainer';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import { Spinner } from 'react-bootstrap';
import { Role } from '../../../../models/Role'
import { IAppState } from '../../../../redux/storeTypes';
import { connect } from "react-redux";
import { Crumb } from "../../../../models/Crumb";
import { Routes } from "../../../../routes";

interface IRoleProps {
    role: Role;
}

const EditRole = ({ role }: IRoleProps) => {
    if (role) {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Navient Roles", Routes.NavientRoles.path));
        crumbs.push(new Crumb(role.name, Routes.EditNavientRoles.path));
        return (
            <>
                <PageHeader title={role.name} crumbs={crumbs} />
                <RoleTabsContainer />
            </>
        );
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Navient Roles", Routes.NavientRoles.path));
        crumbs.push(new Crumb("Edit Role", Routes.EditNavientRoles.path));
        return (
            <>
                <PageHeader title={`Edit Role`} crumbs={crumbs} />
                <RoleTabsContainer />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        role: state.roles.role
    };
};

export default connect(mapStateToProps)(EditRole);
