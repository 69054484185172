import { useDispatch } from "react-redux";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PageSectionContainer from "../../../components/layout/PageSectionContainer";
import { Client, Department, PaymentChannel } from "../../../models/Client";
import { OrderLine, OrderReceipt } from "../../../models/Payment";
import { printTerminalAction } from '../../../redux/actions/clients/terminals';

interface ITriPOSPrinter {
    client: Client;
    department: Department;
    paymentChannel: PaymentChannel;
    orderReceipt: OrderReceipt
}
 
const TriPOSPrinter = ({ client, department, paymentChannel, orderReceipt }: ITriPOSPrinter) => {
    const dispatch = useDispatch();
    const terminalPayment: any = orderReceipt;
    const [autoTriPOSPrint, setAutoTriPOSPrint] = useState(sessionStorage.getItem("autoTriPOSPrint") || 'false');
    const [isTerminalPayment, setIsTerminalPayment] = useState(terminalPayment?.orderTransaction?.orderPayment?.terminalIdentifier || terminalPayment?.terminalIdentifier);
    const [printed, setPrinted] = useState(false);

    useEffect(() => {
        sessionStorage.setItem("autoTriPOSPrint", autoTriPOSPrint);
    }, [autoTriPOSPrint]);

    useEffect(() => {
        if (autoTriPOSPrint === 'true' && !printed) {
            setPrinted(true);
            handleTriPOSThermalPrinter();
        }
    }, []);

    const handleTriPOSThermalPrinter = () => {
        let orderLines: string[] = [];

        orderReceipt?.orderTransaction?.orderLines.forEach((line: OrderLine) => {
            let cost = line.quantity + " x " + line.unitPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            let price = (line.quantity * line.unitPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            if (orderLines.length == 0) orderLines.push("Order Details");
            orderLines.push(`${line.itemName} ${line.itemReferenceNumber.trim().padStart(40 - line.itemName.length)}`)
            orderLines.push(`   ${cost} ${price.trim().padStart(40 - cost.length - 3)}`)
        });

        let data = {
            "accountNumber": terminalPayment?.orderTransaction?.orderPayment?.lastFourOnCard || terminalPayment?.lastFourOnCard,
            "cardLogo": terminalPayment?.orderTransaction?.orderPayment?.cardLogo || terminalPayment?.cardLogo,
            "emv": {
                "tags": [],
                "applicationIdentifier": terminalPayment?.orderTransaction?.orderPayment?.applicationIdentifier || terminalPayment?.applicationIdentifier,
                "applicationLabel": terminalPayment?.orderTransaction?.orderPayment?.applicationLabel || terminalPayment?.applicationLabel,
                "applicationPreferredName": terminalPayment?.orderTransaction?.orderPayment?.applicationPreferredName || terminalPayment?.applicationPreferredName,
                "cryptogram": terminalPayment?.orderTransaction?.orderPayment?.cryptogram || terminalPayment?.cryptogram,
                "issuerCodeTableIndex": "01"
            },
            "entryMode": terminalPayment?.orderTransaction?.orderPayment?.entry || terminalPayment?.entry,
            "isApproved": true,
            "laneId": terminalPayment?.orderTransaction?.laneId,
            "merchantId": terminalPayment?.merchantIdentifier,
            "pinVerified": terminalPayment?.orderTransaction?.orderPayment?.pinVerified || terminalPayment?.pinVerified,
            "terminalPaymentType": "Sale",
            "referenceNumber": terminalPayment?.orderId,
            "subTotalAmount": terminalPayment?.amount.toFixed(2),
            "terminalId": terminalPayment?.orderTransaction?.orderPayment?.terminalIdentifier || terminalPayment?.terminalIdentifier,
            "totalAmount": terminalPayment?.totalAmount.toFixed(2),
            "transactionDateTime": moment.utc(terminalPayment?.orderTransaction?.orderPayment?.createdAt || terminalPayment?.createdAt).local().format("MM-DD-YYYY h:mm a"),
            "transactionId": terminalPayment?.orderTransaction?.orderPayment?.transactionIdentifier || terminalPayment?.transactionIdentifier,
            "hostResponseCode": terminalPayment?.orderTransaction?.orderPayment?.hostResponseCode || terminalPayment?.hostResponseCode,
            "cashbackAmount": "0.00",
            "tipAmount": terminalPayment?.convenienceFee.toFixed(2),
            "approvalNumber": terminalPayment?.orderTransaction?.orderPayment?.approvalNumber || terminalPayment?.approvalNumber,
            "copyType": "Customer",
            "header": [`${client?.businessName}`, `${department?.name}`, department?.departmentAddresses ? `${department.departmentAddresses[0].addressLine1} ${department.departmentAddresses[0].city}, ${department.departmentAddresses[0].state} ${department.departmentAddresses[0].zipCode}` : ""],
            "footer": orderLines.concat(["", "Thank you", "MSB Payment Processing"]),
            "customTemplate": "" +
                "@{Center}{{Header}}\r\n" +
                "@{Center}{{TransactionDateTime}}\r\n" +
                "\r\n" +
                "@{Center}{{ApplicationLabel}}\r\n" +
                "@{Center}{{ApplicationIdentifier}}\r\n" +
                "@{Center}{{ApplicationPreferredName}}\r\n" +
                "@{Label}Merchant ID@{Right}{{MerchantId}}\r\n" +
                "@{Label}Terminal ID\r\n" +
                "@{Right}{{TerminalId}}\r\n" +
                "@{Label}Reference@{Right}{{ReferenceNumber}}\r\n" +
                "@{Label}Account@{Right}{{AccountNumber}}\r\n" +
                "@{Label}Card@{Right}{{CardLogo}}\r\n" +
                "@{Label}Entry@{Right}{{EntryMode}}\r\n" +
                "@{Label}Transaction ID@{Right}{{TransactionId}}\r\n" +
                "@{Label}Approval Code@{Right}{{ApprovalNumber}}\r\n" +
                "@{Label}Response Code@{Right}{{HostResponseCode}}\r\n" +
                "\r\n" +
                "@{Center}{{IsApproved}}\r\n" +
                "@{Center}{{Cryptogram}}\r\n" +
                "\r\n" +
                "@{Label}{{ReceiptType}}@{Right}@{CurrencyCode}{{SubTotalAmount}}\r\n" +
                //"@{Label}Cashback@{Right}@{CurrencyCode}{{CashbackAmount}}\r\n" +
                "@{Label}Convenience Fee@{Right}@{CurrencyCode}{{TipAmount}}\r\n" +
                "                   ______________________\r\n" +
                "@{Label}Total@{Right}@{CurrencyCode}{{TotalAmount}}\r\n" +
                "\r\n" +
                //"x\r\n" +
                //"_________________________________________\r\n" +
                "@{Center}{{PinVerified}}\r\n" +
                "{{Tags}}\r\n" +
                "I agree to pay the above total amount acc-ording to the card issuer agreement\r\n" +
                "\r\n" +
                "@{Center}{{Footer}}\r\n" +
                "@{Center}{{CopyType}}\r\n"
        }
        dispatch(printTerminalAction(data, paymentChannel?.processors[0]?.merchantProcessor?.vantivCredential));
    }

    return (
        <>
        {isTerminalPayment?
            <PageSectionContainer>
                < div className = "container-receipt-details d-print-none" >
                    <Row className="align-items-center">
                        <Col md="auto">
                            <Button variant="secondary" onClick={handleTriPOSThermalPrinter}>Print</Button>
                        </Col>
                        <Col>
                            <i>Use this button only if a <b>MSB Terminal with Thermal Printer</b> is being used
                                <Form.Check type="checkbox" label="Enable automatic printing for the duration of the current session" checked={autoTriPOSPrint === 'true'} onChange={(e) => setAutoTriPOSPrint((e.target.checked) ? 'true' : 'false')} />
                            </i>
                        </Col>
                    </Row>
                </div >
            </PageSectionContainer >
            :
            ""
        }
        </>
    );
}

export default TriPOSPrinter;

