import { FeeBurdenMethodEnum, PaymentTypeEnum } from "./Client";

export class NotificationType {
    groupTypeEnum : GroupTypeEnum = GroupTypeEnum.Services;
    notificationTypeEnum : NotificationTypeEnum = NotificationTypeEnum.UserNotifications;
    name : string = "";
    description : string = "";
    template : string = "";
    roleMSBId?: string;
}

export class UserNotificationSetting {
    userMSBId? : string;
    notificationTypeEnum : NotificationTypeEnum = NotificationTypeEnum.UserNotifications;
    priorityTypeEnum : PriorityTypeEnum = PriorityTypeEnum.Critical;
    email : boolean = false;
    nexus : boolean = false;
    emailAddress : string = "";
}

export class Notification {
message : string = "";
description : string = "";
notificationTypeEnum : NotificationTypeEnum = NotificationTypeEnum.UserNotifications;
}

export class UserNotification {
    msbId: string = "";
    userMSBId: string = "";
    notificationId : number = 0;    
    notification: Notification = new Notification();
    priorityTypeEnum : PriorityTypeEnum = PriorityTypeEnum.Notice;
    sentEmail : boolean = false;
    sentNexus : boolean = false;
    isRead : boolean = false;
    isDeleted : boolean = false;
}

export class DisputeNotificationMessage {
msbId: string = "";
clientMsbId: string = "";
departmentMsbId: string = "";
chargeBackStatus: string = "";
transactionIdentifier: string = "";
orderIdentifier: string = "";
}

export class NotificationDescriptionMessage {
    msbId?: string;
    original: Original = new Original();
    updated: Updated = new Updated();
}

export class Original{
    msbId: string = "";
    Name: string = "";
    LegalName: string = "";
    dbaName: string = "";
    federalTaxId: string = "";
    useClientAddress: boolean = false;
    useClientFederalTaxId: boolean = false;
    useGilaSettings: boolean = false;
    bankFundingDescriptor: string = "";
    achDebitDescriptor: string = "";
    debitFeeMethodEnum: DebitFeeMethodEnum = DebitFeeMethodEnum.Unknown;
    feeBurdenMethodEnum: FeeBurdenMethodEnum =  FeeBurdenMethodEnum.ConvenienceFee;
    fundingMethodEnum: FundingMethodEnum = FundingMethodEnum.Unknown;
    chargebackFee: number = 0;
    nsfFee: number = 0;
    creditChargebacksSettlementType : SettlementTypeEnum = SettlementTypeEnum.Unknown;
    creditDepositsSettlementType : SettlementTypeEnum = SettlementTypeEnum.Unknown
    creditProcessingFeesSettlementType : SettlementTypeEnum = SettlementTypeEnum.Unknown
    debitChargeBacksSettlementType : SettlementTypeEnum = SettlementTypeEnum.Unknown
    debitConvenienceFeesSettlementType : SettlementTypeEnum = SettlementTypeEnum.Unknown
    debitDepositsSettlementType : SettlementTypeEnum = SettlementTypeEnum.Unknown
    debitProcessingFeesSettlementType : SettlementTypeEnum = SettlementTypeEnum.Unknown
}

export class Updated{
    msbId: string = "";
    Name: string = "";
    LegalName: string = "";
    dbaName: string = "";
    federalTaxId: string = "";
    useClientAddress: boolean = false;
    useClientFederalTaxId: boolean = false;
    useGilaSettings: boolean = false;
    bankFundingDescriptor: string = "";
    achDebitDescriptor: string = "";
    debitFeeMethodEnum: DebitFeeMethodEnum = DebitFeeMethodEnum.Unknown;
    feeBurdenMethodEnum: FeeBurdenMethodEnum =  FeeBurdenMethodEnum.ConvenienceFee;
    fundingMethodEnum: FundingMethodEnum = FundingMethodEnum.Unknown;
    chargebackFee: number = 0;
    nsfFee: number = 0;
    creditChargebacksSettlementType : number = 0;
    creditDepositsSettlementType: number = 0;
    creditProcessingFeesSettlementType: number = 0;
    debitChargeBacksSettlementType: number = 0;
    debitConvenienceFeesSettlementType: number = 0;
    debitDepositsSettlementType : number = 0;
    debitProcessingFeesSettlementType : number = 0;
}

export enum DebitFeeMethodEnum
    {
        Unknown = 0,
        DirectDebit, 
        Invalid, 
        BillOut, 
        Net
    }

export enum FreeBurdenMethodEnum
{
    Unknown = 0,
    ConvenienceFee, 
    Invalid,
    Absorbed
}

export enum FundingMethodEnum
{
    Unknown = 0,
    CInvalid, 
    SameDay, 
    NextDay,
    TwoDay, 
    Other
}

export enum PriorityTypeEnum {
    Critical = 1,
    Urgent = 2,
    Notice = 3
}

export enum GroupTypeEnum {
    Unknown = 0,
    Funding = 1,
    Payments= 2,
    Services= 3
}

export enum NotificationTypeEnum {
    ApprovalNotifications = 1,
    TransactionNotificationsVoids = 2,
    TransactionNotificationsRefunds = 3,
    ChargebackReceived = 4,
    ChargebackUpdate = 5,
    ChargebackDaysRemaining = 6,
    TransactionRetrievalRequest = 7,
    TransactionRetrievalRequestUpdated = 8,
    TransactionRetrievalRequestDaysRemaining = 9,
    ClientChanges = 10,
    DepartmentChanges = 11,
    DeclinedPayments = 12,
    TerminalStatus = 13,
    UserNotifications = 14
}

export enum NotificationAlertPriorityEnum {
    critical = 1,
    urgent = 2,
    notice = 3
}

export enum SettlementTypeEnum
{
    Unknown = 0,
    Deposit = 1,
    Chargeback = 2,
}