import axios from 'axios';
import { WebAppSettings } from '../../models/WebAppSettings';
import { Dispatch } from 'react';
import store from "../store";
import axiosRetry from 'axios-retry';

export const SET_CONFIG = 'SET_CONFIG';

export const GET_WEB_APP_SETTINGS_REQUEST = 'GET_WEB_APP_SETTINGS_REQUEST';
export const GET_WEB_APP_SETTINGS_SUCCESS = 'GET_WEB_APP_SETTINGS_SUCCESS';
export const GET_WEB_APP_SETTINGS_FAILURE = 'GET_WEB_APP_SETTINGS_FAILURE';

export const GET_WSM_TOKEN_REQUEST= 'GET_WSM_TOKEN_REQUEST';
export const GET_WSM_TOKEN_SUCCESS = 'GET_WSM_TOKEN_SUCCESS';
export const GET_WSM_TOKEN_FAILURE = 'GET_WSM_TOKEN_FAILURE';

export const TOKEN_ERROR = 'TOKEN_ERROR'

export const setConfigAction = (environment:string, baseApiUrl:string, clientApiMock?:boolean) => (dispatch: Dispatch<any>) => {
    
    if (environment !== "LOCAL") {
        clientApiMock = false;
    }

    dispatch({
        type: SET_CONFIG,
        payload: {
            environment,
            baseApiUrl,
            clientApiMock,
        }
    });
};

export const getWsmTokenAction = (actionToken?: string) => async (dispatch: Dispatch<any>) => {

    dispatch({
        type: GET_WSM_TOKEN_REQUEST,
        payload: {
            actionToken
        }
    });

    const userToken = axios.create({ baseURL: window.location.protocol + "//" + window.location.host })
    axiosRetry(userToken, { 
        retries: 3, 
        retryDelay: (retryCount) => Math.pow(2, retryCount) * 3000,
        retryCondition: (error) => {
            return axiosRetry.isNetworkError(error) || (error?.response?.status != 200)
        }
     });

    try {
        const response = await userToken.get('/getWsmToken');
        let token = response.data.token;
        dispatch({
            type: GET_WSM_TOKEN_SUCCESS,
            payload: {
                token,
                actionToken
            }
        });
    } catch (error: any) {
        if (axiosRetry.isRetryableError(error)  && error?.config?.['axios-retry']?.retryCount >= 3) {
            dispatch({
                type: GET_WSM_TOKEN_FAILURE,
                payload: {
                    error,
                    TOKEN_ERROR,
                    actionToken
                }
            });
            if (window.confirm(`Something went wrong while retrieving WSM token. Please refresh the page to login. ${error}`)) {
                window.location.reload()
            }
        }
    }
}

export const getWebAppSettingsAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_WEB_APP_SETTINGS_REQUEST,
        payload: {
            actionToken
        }
    });

   /* 
    let webAppSettings = new WebAppSettings();
    webAppSettings.environment = "DEV";
    webAppSettings.wsmBaseUrl = "http://nwnifimsb010";
    webAppSettings.wsmBearerToken = "123";

    dispatch({
         type: GET_WEB_APP_SETTINGS_SUCCESS,
         payload: {
             webAppSettings,
             actionToken
         }
    });
   */ 

    let _url = window.location.protocol + "//" + window.location.host + "/nexus/settings";
    let webAppSettings = new WebAppSettings();

    axios.post(_url)
        .then(function (response) {
            webAppSettings = response.data;
            dispatch({
                type: GET_WEB_APP_SETTINGS_SUCCESS,
                payload: {
                    webAppSettings,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            console.log(error);
            dispatch({
                type: GET_WEB_APP_SETTINGS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
         

};