import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../redux/storeTypes';
import { Spinner } from 'react-bootstrap';
import Table from '../../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../../components/Formatters';
import { IPageableResults, PageableResults } from "../../../models/PageableResults";
import { getHistoryApprovalsAction } from '../../../redux/actions/historyApprovals';
import { Redirect } from 'react-router-dom';
import Approval, { ApprovalTypeEnum } from '../../../models/Approval';


interface HistoryProps {
    currentPage: IPageableResults<Approval>,
    isFetching: boolean
}

const History = ({ currentPage, isFetching }: HistoryProps) => {
    const [redirect, setRedirect] = useState<string>("");
    const dispatch = useDispatch();

    const [sizePerPage, setSizePerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(1);
    const [data, setData] = useState<Array<any>>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);

    useEffect(() => {
        if (currentPage != null) {
            setData(currentPage.data);
            setPage(currentPage.page);
            setTotalRecords(currentPage.totalRecords);
        }
    }, [currentPage]);

    useEffect(() => {
        dispatch(getHistoryApprovalsAction(page, sizePerPage));

    }, []);

    const onTableChange = (type: string, { page, sizePerPage }: any) => {
        setSizePerPage(sizePerPage);
        setPage(page);
        dispatch(getHistoryApprovalsAction(page, sizePerPage));
        
    }

    const statusFormatter = (cell: any, row: any) => {
        return (
            cell === 2 ?
                <div>
                    <svg height="10" width="10">
                        <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#52C41A"></circle>
                    </svg> Approved
                </div> :
                <div>
                    <svg height="10" width="10">
                        <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#F5222D"></circle>
                    </svg> Denied
                </div>
        )
    }


    const ApprovalTypeEnumFormatter = (cell: any, approval: Approval) => {
        var value = "";
        if (approval.approvalTypeEnum == ApprovalTypeEnum.SetupApproval) {
            value = "Client Setup";
        } else if (approval.approvalTypeEnum == ApprovalTypeEnum.ClientBankAccount) {
            value = "Client Bank Account"
        }

        return (
            <>{value}</>
        )
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        }, 
        {
            dataField: 'approvalTypeEnum',
            text: 'Type',
            editable: false,
            formatter: ApprovalTypeEnumFormatter
        },
        {
            dataField: 'referenceName',
            text: 'Request',
            editable: false,
        },
        {
            dataField: 'createdByUser',
            text: 'Submitted by',
            editable: false
        },
        {
            dataField: 'createdAt',
            text: 'Submitted date',
            editable: false,
            formatter: DateFormatter
        },
        {
            dataField: 'approvedByUser',
            text: 'Approver',
            editable: false
        },
        {
            dataField: 'updatedAt',
            text: 'Completed date',
            editable: false,
            formatter: DateFormatter
        },
        {
            dataField: 'statusEnum',
            text: 'Status',
            formatter: statusFormatter,
            editable: false
        },
    ];

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>{isFetching ?
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner> :
                <div>
                    {data ?
                        <Table
                          keyField="msbId"
                          data={data}
                          columns={columns}
                          onTableChange={onTableChange}
                          defaultSorted={[ { dataField: 'createdAt', order: 'desc'}]}
                          sizePerPage={sizePerPage}
                          page={page}
                          totalSize={totalRecords}
                        /> : null}
                </div>}
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        currentPage: state.historyApprovals.currentPage,
        isFetching: state.historyApprovals.isFetching
    };
};

export default connect(mapStateToProps)(History);