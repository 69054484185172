import React, { useEffect } from 'react';
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap"
import RequiredIcon from "../../../../../../components/RequiredIcon";
import InputMask from "react-input-mask";
import { IActionResult, IAppState } from '../../../../../../redux/storeTypes';
import { connect, useDispatch } from 'react-redux';
// import { PaymentTypeEnum } from '../../../../../../models/Client';
import { Order, PaymentCardTypeEnum, PaymentType, PaymentTypeEnum } from '../../../../../../models/Payment';
import { calculateOrderAction, /*updateOrderDetailsAction, UPDATE_ORDER_DETAILS*/ } from '../../../../../../redux/actions/payments/orderManagement';
import ECheckHelpIcon from '../../../../../../components/ECheckHelpIcon';
import { ErrorSharp } from '@material-ui/icons';
import { isInvalidCardExpiration } from "../../../../../../utils/globalCalculations";

export interface IPaymentDetails {
    paymentTypeLabel: string,
    selectPaymentTypeOptionLabel: string,
    cardholderNameLabel: string,
    cardholderNamePlaceholder: string,
    cardholderNameRequired: string,
    cardTypeLabel: string,
    cardTypeRequired: string,
    cardNumberLabel: string,
    cardNumberRequired: string,
    expirationDateLabel: string,
    expirationDateRequired: string,
    cvvLabel: string,
    cvvRequired: string,
    nameOnAccountLabel: string,
    nameOnAccountPlaceholder: string,
    nameOnAccountRequired: string,
    bankAccountNumberLabel: string,
    bankAccountNumberRequired: string,
    confirmBankAccountNumberLabel: string,
    confirmBankAccountNumberRequired: string,
    bankRoutingNumberLabel: string,
    bankRoutingNumberRequired: string,
    eCheckDisclaimer: string,
    creditCardPaymentType: string,
    eCheckPaymentType: string,
    paypalPaymentType: string,
    terminalPaymentType: string,
    order: Order,
    isSaving: boolean,
    validationErrors: any,
    actionResult: IActionResult,
    fullStoryMask: boolean,
    margin: string,
    padding: string,
}

export const PAYMENT_TYPE_LABEL = 'Payment type';
export const SELECT_PAYMENT_TYPE_OPTION_LABEL = 'Select Payment type';
export const CARDHOLDER_NAME_LABEL = 'Cardholder name';
export const CARDHOLDER_NAME_PLACEHOLDER = 'Enter Cardholder name';
export const CARDHOLDER_NAME_REQUIRED = 'Please enter the Cardholder’s name.';
export const CARD_TYPE_LABEL = 'Card type';
export const CARD_TYPE_REQUIRED = 'Please select the Card type.';
export const CARD_NUMBER_LABEL = 'Card number';
export const CARD_NUMBER_REQUIRED = 'Please enter a valid 12-16 digit Card number.';
export const EXPIRATION_DATE_LABEL = 'Expiration date';
export const EXPIRATION_DATE_REQUIRED = 'Please enter the Expiration date.';
export const CVV_LABEL = 'CVV';
export const CVV_REQUIRED = 'Please enter the CVV number.';
export const NAME_ON_ACCOUNT_LABEL = 'Name on account';
export const NAME_ON_ACCOUNT_PLACEHOLDER = 'Enter Name on account';
export const NAME_ON_ACCOUNT_REQUIRED = 'Please enter the Name on account.';
export const BANK_ACCOUNT_NUMBER_LABEL = 'Bank account number';
export const BANK_ACCOUNT_NUMBER_REQUIRED = 'Please enter the Bank account number.';
export const CONFIRM_BANK_ACCOUNT_NUMBER_LABEL = 'Confirm bank account number';
export const CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED = 'Please enter the Bank account number.';
export const BANK_ROUTING_NUMBER_LABEL = 'Bank routing number';
export const BANK_ROUTING_NUMBER_REQUIRED = 'Please enter the Bank routing number.';
export const ECHECK_DISCLAIMER = '';
export const CREDITCARDPAYMENTTYPE = 'Credit/Debit Card';
export const ECHECKPAYMENTTYPE = 'eCheck';
export const PAYPALPAYMENTTYPE = 'PayPal';
export const TERMINALPAYMENTTYPE = 'Terminal';
export const MARGIN = '0px';
export const PADDING = '0px';
export const FULL_STORY_MASK = false;

const PaymentDetails = ({ paymentTypeLabel, selectPaymentTypeOptionLabel, fullStoryMask,
    cardholderNameLabel, cardholderNamePlaceholder, cardholderNameRequired, cardTypeLabel, cardTypeRequired, cardNumberLabel, cardNumberRequired, expirationDateLabel, expirationDateRequired, cvvLabel, cvvRequired,
    nameOnAccountLabel, nameOnAccountPlaceholder, nameOnAccountRequired, bankAccountNumberLabel, bankAccountNumberRequired, confirmBankAccountNumberLabel, confirmBankAccountNumberRequired, bankRoutingNumberLabel, bankRoutingNumberRequired,
    eCheckDisclaimer, creditCardPaymentType, eCheckPaymentType, paypalPaymentType, terminalPaymentType, order, isSaving, validationErrors, actionResult, margin, padding
    }: IPaymentDetails) => {
    const actionToken = "PaymentDetails";
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.Unknown);
    const [showCVV, setShowCVV] = useState<boolean>(false);
    const [showCardNumber, setShowCardNumber] = useState<boolean>(false);
    const [cardExpirationInvalid, setCardExpirationInvalid] = useState<boolean>(false);
    const [cardExpirationMessage, setCardExpirationMessage] = useState<string>('');
   
    const dispatch = useDispatch();
    
    if (!paymentTypeLabel) {
        paymentTypeLabel = PAYMENT_TYPE_LABEL;
    }

    if (!selectPaymentTypeOptionLabel) {
        selectPaymentTypeOptionLabel = SELECT_PAYMENT_TYPE_OPTION_LABEL;
    }

    if (!cardholderNameLabel) {
        cardholderNameLabel = CARDHOLDER_NAME_LABEL;
    }

    if (!cardholderNamePlaceholder) {
        cardholderNamePlaceholder = CARDHOLDER_NAME_PLACEHOLDER;
    }

    if (!cardholderNameRequired) {
        cardholderNameRequired = CARDHOLDER_NAME_REQUIRED;
    }

    if (!cardTypeLabel) {
        cardTypeLabel = CARD_TYPE_LABEL;
    }
    
    if (!cardTypeRequired) {
        cardTypeRequired = CARD_TYPE_REQUIRED;
    }

    if (!cardNumberLabel) {
        cardNumberLabel = CARD_NUMBER_LABEL;
    }

    if (!cardNumberRequired) {
        cardNumberRequired = CARD_NUMBER_REQUIRED;
    }

    if (!expirationDateLabel) {
        expirationDateLabel = EXPIRATION_DATE_LABEL;
    }

    if (!expirationDateRequired) {
        expirationDateRequired = EXPIRATION_DATE_REQUIRED;
    }

    if (!cvvLabel) {
        cvvLabel = CVV_LABEL;
    }

    if (!cvvRequired) {
        cvvRequired = CVV_REQUIRED;
    }

    if (!nameOnAccountLabel) {
        nameOnAccountLabel = NAME_ON_ACCOUNT_LABEL;
    }

    if (!nameOnAccountPlaceholder) {
        nameOnAccountPlaceholder = NAME_ON_ACCOUNT_PLACEHOLDER;
    }

    if (!nameOnAccountRequired) {
        nameOnAccountRequired = NAME_ON_ACCOUNT_REQUIRED;
    }

    if (!bankAccountNumberLabel) {
        bankAccountNumberLabel = BANK_ACCOUNT_NUMBER_LABEL;
    }

    if (!bankAccountNumberRequired) {
        bankAccountNumberRequired = BANK_ACCOUNT_NUMBER_REQUIRED;
    }

    if (!confirmBankAccountNumberLabel) {
        confirmBankAccountNumberLabel = CONFIRM_BANK_ACCOUNT_NUMBER_LABEL;
    }

    if (!confirmBankAccountNumberRequired) {
        confirmBankAccountNumberRequired = CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED;
    }

    if (!bankRoutingNumberLabel) {
        bankRoutingNumberLabel = BANK_ROUTING_NUMBER_LABEL;
    }

    if (!bankRoutingNumberRequired) {
        bankRoutingNumberRequired = BANK_ROUTING_NUMBER_REQUIRED;
    }

    if (!eCheckDisclaimer) {
        eCheckDisclaimer = ECHECK_DISCLAIMER;
    }

    if (!creditCardPaymentType) {
        creditCardPaymentType = CREDITCARDPAYMENTTYPE;
    }

    if (!eCheckPaymentType) {
        eCheckPaymentType = ECHECKPAYMENTTYPE;
    }

    if (!paypalPaymentType) {
       paypalPaymentType = PAYPALPAYMENTTYPE;
    }

    if (!terminalPaymentType) {
        terminalPaymentType = TERMINALPAYMENTTYPE;
    }

    fullStoryMask = fullStoryMask || FULL_STORY_MASK;

    if (!margin) {
        margin = MARGIN;
    }

    if (!padding) {
        padding = PADDING;
    }
    
    const bankAccountValidationMessage = () => {
        if (validationErrors?.eCheckAccountNumber_match) {
            return validationErrors.eCheckAccountNumber_match;
        } else if (validationErrors?.eCheckAccountNumber_required) {
            return bankAccountNumberRequired;
        } else {
            return '';
        }
    }

    const confirmBankAccountValidationMessage = () => {
        if (validationErrors?.confirm_eCheckAccountNumber_match) {
            return validationErrors.confirm_eCheckAccountNumber_match;
        } else if (validationErrors?.eCheckAccountNumber_required) {
            return confirmBankAccountNumberRequired;
        } else {
            return '';
        }
    }

    const checkCardExpiry = (e: any) => {
        const cardExpiry = e.target.value; const checkDateFormat = /^[0-9\/\s]{5}$/
        const isValid = checkDateFormat.test(e.target.value)
        if (isValid) {
            const checkValidation = isInvalidCardExpiration(cardExpiry)
            if (!checkValidation) {
                setCardExpirationInvalid(false)
            } else {
                setCardExpirationInvalid(true)
                setCardExpirationMessage(checkValidation)
            }
        }
    }

    const renderECheck = () => {
        return (
            <>
            <Row>
                <Col sm={12}>
                    <Form.Group controlId="eCheckAccountHolderName">
                        <Form.Label><RequiredIcon />{nameOnAccountLabel}</Form.Label>
                        <Form.Control type="input" required placeholder={nameOnAccountPlaceholder} disabled={isSaving} />
                        <Form.Control.Feedback type="invalid">{nameOnAccountRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="eCheckAccountNumber">
                        <Form.Label><RequiredIcon />{bankAccountNumberLabel}<ECheckHelpIcon /></Form.Label>
                        <Form.Control type="input" disabled={isSaving} pattern="^\d*$" maxLength={10} isInvalid={!!validationErrors?.eCheckAccountNumber_match || !!validationErrors?.eCheckAccountNumber_required}  />
                        <Form.Control.Feedback type="invalid">{bankAccountValidationMessage()}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="confirm_eCheckAccountNumber">
                        <Form.Label><RequiredIcon />{confirmBankAccountNumberLabel}</Form.Label>
                        <Form.Control type="input" disabled={isSaving} pattern="^\d*$" maxLength={10} isInvalid={!!validationErrors?.confirm_eCheckAccountNumber_match || !!validationErrors?.confirm_eCheckAccountNumber_required} />
                        <Form.Control.Feedback type="invalid">{confirmBankAccountValidationMessage()}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="eCheckRoutingNumber">
                        <Form.Label><RequiredIcon />{bankRoutingNumberLabel}<ECheckHelpIcon /></Form.Label>
                        <Form.Control type="input" required disabled={isSaving} pattern="^\d*$" maxLength={9} />
                        <Form.Control.Feedback type="invalid">{bankRoutingNumberRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>

                </Col>
            </Row>
            {eCheckDisclaimer && eCheckDisclaimer.length > 0?
                <p className="mt-3 mb-0" style={{ color: '#8C8C8C' }}>{eCheckDisclaimer}</p>
            :
                <></>
            }
            </>
        );
    }

    const handleCardTypeChange = (e:any) => {
        if (order) {
            //order.paymentType = PaymentTypeEnum.CreditCardManual;
            // order.paymentType = PaymentType.CreditCard;
            order.cardType = e.target.value;
            // dispatch(updateOrderDetailsAction(order, actionToken));
        }
    }

    useEffect(() => {
        // if (actionResult && actionResult.type === UPDATE_ORDER_DETAILS) {
        //     dispatch(calculateOrderAction(actionToken));
        // }
    }, [actionResult]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderCreditCard = () => {
        return (
            <>
            <Row>
                <Col md={12}>
                    <Form.Group controlId="nameOnCard">
                        <Form.Label><RequiredIcon />{cardholderNameLabel}</Form.Label>
                        <Form.Control type="input" required maxLength={50} placeholder={cardholderNamePlaceholder} disabled={isSaving} />
                        <Form.Control.Feedback type="invalid">{cardholderNameRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="cardType">
                        <Form.Label><RequiredIcon />{cardTypeLabel}</Form.Label>
                        <Form.Control as="select" required defaultValue={PaymentCardTypeEnum.Unknown} onChange={handleCardTypeChange} disabled={isSaving}>
                            <option value={PaymentCardTypeEnum.Unknown}>Select</option>
                            <option value={PaymentCardTypeEnum.AmericanExpressCredit}>American Express</option>
                            <option value={PaymentCardTypeEnum.AmericanExpressDebit}>American Express - Debit</option>
                            <option value={PaymentCardTypeEnum.DiscoverCredit}>Discover</option>
                            <option value={PaymentCardTypeEnum.DiscoverDebit}>Discover - Debit</option>
                            <option value={PaymentCardTypeEnum.MastercardCredit}>Mastercard</option>
                            <option value={PaymentCardTypeEnum.MastercardDebit}>Mastercard - Debit</option>
                            <option value={PaymentCardTypeEnum.VisaCredit}>Visa</option>
                            <option value={PaymentCardTypeEnum.VisaDebit}>Visa - Debit</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{cardTypeRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="cardNumber">
                        <Form.Label><RequiredIcon />{cardNumberLabel}</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control type={showCardNumber ? "text" : "password"} required minLength={12} maxLength={16} disabled={isSaving} className="password" />
                            <InputGroup.Append>
                                <InputGroup.Text onClick={() => setShowCardNumber(!showCardNumber)}>
                                    <FontAwesomeIcon icon={showCardNumber ? faEyeSlash : faEye} size="sm" />
                                </InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control.Feedback type="invalid">{cardNumberRequired}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={3} sm={6}>
                    <Form.Group controlId="expirationDate">
                        <Form.Label><RequiredIcon />{expirationDateLabel}</Form.Label>
                        <InputMask id="expirationDate" name="expirationDate" required type="input" pattern="[0-9\/\s]{5}" mask="99/99" className={cardExpirationInvalid ? "customValidation" :"form-control"} onChange={checkCardExpiry} disabled={isSaving} />
                        <Form.Control.Feedback type="invalid">{expirationDateRequired}.</Form.Control.Feedback>
                    </Form.Group>
                    {cardExpirationInvalid &&
                            <Form.Group controlId="expirationDateMasked">
                                <Form.Control type="input" isInvalid={cardExpirationInvalid} style={{ display: 'none' }} required={cardExpirationInvalid} />
                                <Form.Control.Feedback type="invalid"> {cardExpirationMessage}</Form.Control.Feedback>
                            </Form.Group>
                        }
                </Col>
                <Col md={3} sm={6}>
                    <Form.Group controlId="cvv">
                        <Form.Label><RequiredIcon />{cvvLabel}</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control type={showCVV ? "text" : "password"} required minLength={3} maxLength={4} disabled={isSaving} className="password" />
                            <InputGroup.Append>
                                <InputGroup.Text onClick={() => setShowCVV(!showCVV)}>
                                    <FontAwesomeIcon icon={showCVV ? faEyeSlash : faEye} size="sm" />
                                </InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control.Feedback type="invalid">{cvvRequired}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            </>
        );
    }

    const renderPaymentMethod = () => {
        if (paymentType === PaymentType.CreditCard) {
            return (renderCreditCard());
        } else if (paymentType === PaymentType.ECheck) {
            return (renderECheck());
        } else {
            return (<></>)
        }
    }

    const handlePaymentTypeChange = (e:any) => {
        let _paymentType = parseInt(e.target.value) as PaymentType;
        setPaymentType(_paymentType);

        if (order && _paymentType === PaymentType.ECheck) {
            // order.paymentType = _paymentType;
            order.cardType = PaymentCardTypeEnum.ElectronicCheck;
            // dispatch(updateOrderDetailsAction(order, actionToken));
        }
    }

    return (
        <div style={{margin:margin, padding:padding}} className={fullStoryMask ? "fs-mask" : ""}>
            <Row>
                <Form.Group controlId="paymentType">
                    <Form.Label>{paymentTypeLabel}</Form.Label>
                    <Form.Control as='select' required value={paymentType} onChange={handlePaymentTypeChange} disabled={isSaving}>
                        <option value={PaymentTypeEnum.Unknown}>{selectPaymentTypeOptionLabel}</option>
                        <option value={PaymentTypeEnum.CreditCardManual}>{creditCardPaymentType}</option>
                        <option value={PaymentTypeEnum.ECheck}>{eCheckPaymentType}</option>
                    </Form.Control>
                </Form.Group>
            </Row>
            {
                renderPaymentMethod()
            }             
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        order: state.orderManagement.order,
        validationErrors: state.orderManagement.validationErrors,
        actionResult: state.orderManagement.actionResult,
        errorMessage: state.orderManagement.errorMessage
    };
};

export default connect(mapStateToProps)(PaymentDetails);