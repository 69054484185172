import { Row, Col } from "react-bootstrap"
import Table from '../../../../../../components/Table';

interface ILoganSearchResults {
    taxPayerNameLabel: string,
    parcelNumberLabel: string,
    streetAddressLabel: string,
    propertyTypeCodeLabel: string,
    propertyTypeDescriptionLabel: string,
    taxYearLabel: string,
    balanceLabel: string,
    statementNumberLabel: string,
    statusLabel: string,
    actionsLabel: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    overlayText: string,
    overlayBackground: string,
    overlayTextColor: string,
    overlayIconColor: string,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
}

export const TAXPAYER_NAME_LABEL = 'Taxpayer Name';
export const PARCEL_NUMBER_LABEL = 'Parcel Number';
export const STREET_ADDRESS_LABEL = 'Street Address';
export const PROPERTY_TYPE_LABEL = 'Property Type';
export const PROPERTY_TYPE_DESCRIPTION = 'Property Type Description';
export const TAX_YEAR_LABEL = 'Tax Year';
export const BALANCE_LABEL = 'Balance';
export const STATEMENT_NUMBER_LABEL = 'Statement Number';
export const STATUS_LABEL = 'Status';
export const ACTIONS_LABEL = 'Actions';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const OVERLAY_TEXT = 'I’m sorry. You will need to contact the Logan County Treasurer at 970-522-2462 to retrieve the balance owed for this account.'; 
export const OVERLAY_BACKGROUND = '#fef1b5'; 
export const OVERLAY_TEXT_COLOR = '#1e1e1e';
export const OVERLAY_ICON_COLOR = 'orange';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const LoganSearchResults = ({ taxPayerNameLabel, parcelNumberLabel, streetAddressLabel, propertyTypeCodeLabel, balanceLabel,
    propertyTypeDescriptionLabel,taxYearLabel,statementNumberLabel, statusLabel, actionsLabel,  overlayText, overlayBackground, overlayTextColor, overlayIconColor,
    durationOfPendingHours, observesWeekends, margin, padding, headerBackgroundColor, headerPadding }: ILoganSearchResults) => {

    if (!taxPayerNameLabel) { taxPayerNameLabel = TAXPAYER_NAME_LABEL }
    if (!parcelNumberLabel) { parcelNumberLabel = PARCEL_NUMBER_LABEL }
    if (!streetAddressLabel) { streetAddressLabel = STREET_ADDRESS_LABEL }
    if (!propertyTypeCodeLabel) { propertyTypeCodeLabel = PROPERTY_TYPE_LABEL }
    if (!propertyTypeDescriptionLabel) { propertyTypeDescriptionLabel = PROPERTY_TYPE_DESCRIPTION }
    if (!statementNumberLabel) { statementNumberLabel = STATEMENT_NUMBER_LABEL }
    if (!taxYearLabel) { taxYearLabel = TAX_YEAR_LABEL }
    if (!balanceLabel) { balanceLabel = BALANCE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!overlayText) { overlayText = OVERLAY_TEXT }
    if (!overlayBackground) { overlayBackground = OVERLAY_BACKGROUND }
    if (!overlayTextColor) { overlayTextColor = OVERLAY_TEXT_COLOR }
    if (!overlayIconColor) { overlayIconColor = OVERLAY_ICON_COLOR }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const initialColumns = [{
        dataField: 'taxpayerName',
        text: taxPayerNameLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'parcelNumber',
        text: parcelNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'streetAddress',
        text: streetAddressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true
    }, {
        dataField: 'propertyTypeCode',
        text: propertyTypeCodeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'taxYear',
        text: taxYearLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'grandTotal',
        text: balanceLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: amountFormatter,
        editable: false,
    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const renderTable = () => {
        return (
            <Table
                keyField="msbId"
                data={[]}
                columns={initialColumns}
            />);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </div>

    )
}

export default LoganSearchResults;