import React  from "react";
import PageHeader from '../../../../components/layout/PageHeader';
import UserTabsContainer from './UserTabsContainer';
import { User } from '../../../../models/User'
import { IAppState } from '../../../../redux/storeTypes';
import { connect } from "react-redux";
import { Crumb } from "../../../../models/Crumb";
import { Routes } from "../../../../routes";

interface IUserProps {
    user: User;
}

const EditUser = ({ user }: IUserProps) => {
    if (user) {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Navient Users", Routes.NavientUsers.path));
        crumbs.push(new Crumb(`${user.firstName} ${user.lastName}`, Routes.EditNavientUsers.path));
        return (
            <>
                <PageHeader title={`${user.firstName} ${user.lastName}`} crumbs={crumbs}/>
                <UserTabsContainer />
            </>
        );
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Navient Users", Routes.NavientUsers.path));
        crumbs.push(new Crumb("Edit User", Routes.EditNavientUsers.path));
        return (
            <>
                <PageHeader title={"Edit User"} crumbs={crumbs}/>
                <UserTabsContainer />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.users.user
    };
};

export default connect(mapStateToProps)(EditUser);
