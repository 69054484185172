import React, { useState, useEffect, useRef } from "react";
import { Tab, Nav, Form, Button } from 'react-bootstrap';
import { Client, MerchantProcessor as Merchant } from '../../../models/Client';
import { IAppState, IActionResult } from '../../../redux/storeTypes';
import MerchantDetailsSection from './manageMerchantSections/MerchantDetailsSection';
import MerchantSettlementsSection from "./manageMerchantSections/MerchantSettlementsSection";
import MerchantProcessorSection from "./manageMerchantSections/MerchantProcessorSection";
import MerchantTerminalsSection from "./manageMerchantSections/MerchantTerminalsSection";
import MerchantAccountingSection from "./manageMerchantSections/MerchantAccountingSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { MerchantManagementTabsHeaders } from "./manageMerchantSections/MerchantManagementTabHeaders";
import MerchantAddressesSection from "./manageMerchantSections/MerchantAddressesSection";
import MerchantContactsSection from "./manageMerchantSections/MerchantContactsSection";
import MerchantVASSection from "./manageMerchantSections/MerchantVASSection";

export interface IMerchantManagementnProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    merchant: Merchant,
    actionResult?: IActionResult
}

const MerchantManagement = ({ isSaving, isFetching, client, merchant, actionResult }: IMerchantManagementnProps) => {
  const ref = useRef(null);
  
  const scroll = (scrollOffset: any) => {
    const node: any = ref.current!;
    node.scrollLeft += scrollOffset;
    };

  return (
    <Tab.Container defaultActiveKey="manage_details">
      
        {/* BEGIN: Tabs */}
        <div className="tab-nav d-flex px-0">
          <Button variant="link" onClick={() => scroll(-300)} className="btn-scroll-left">
            <FontAwesomeIcon icon={faChevronLeft} size="xs" />
          </Button>
          <div className="scrollbarContainer" ref={ref}>
            <Nav className="flex-row flex-nowrap">
              {Object.entries(MerchantManagementTabsHeaders).map(
                ([index, value]: any) => {
                  return (
                    <Nav.Item>
                      <Nav.Link className="tabWidth" eventKey={value.key}>{value.title}</Nav.Link>
                    </Nav.Item>
                  )
                }
              )}
            </Nav>
          </div>
          <Button variant="link" onClick={() => scroll(300)} className="btn-scroll-right">
            <FontAwesomeIcon icon={faChevronRight} size="xs" />
          </Button>
        </div>
        {/* END: Tabs */}

        <Tab.Content>
            <Tab.Pane eventKey="manage_details">
                <MerchantDetailsSection />
            </Tab.Pane>
            {/*
            <Tab.Pane eventKey="manage_addresses">
                <MerchantAddressesSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_contacts">
                <MerchantContactsSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_settlements">
                <MerchantSettlementsSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_accounting">
                <MerchantAccountingSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_mids_fees">
                <MerchantProcessorSection />
            </Tab.Pane>
            */}
            <Tab.Pane eventKey="manage_devices">
                <MerchantTerminalsSection />
            </Tab.Pane>
            <Tab.Pane eventKey="manage_VAS">
                <MerchantVASSection />
            </Tab.Pane>
        </Tab.Content>
    </Tab.Container>
  )
}

export default MerchantManagement;