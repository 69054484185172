import axios from 'axios';
import { ChargeBackStatusEnum, OrderTransaction } from '../../models/Order';
import { Dispatch } from 'react';
import store from "../store";

export const GET_ORDER_TRANSACTION_REQUEST = 'GET_ORDER_TRANSACTION_REQUEST';
export const GET_ORDER_TRANSACTION_SUCCESS = 'GET_ORDER_TRANSACTION_SUCCESS';
export const GET_ORDER_TRANSACTION_FAILURE = 'GET_ORDER_TRANSACTION_FAILURE';

export const SAVE_ORDER_TRANSACTION_COMMENT_REQUEST = "SAVE_ORDER_TRANSACTION_COMMENT_REQUEST";
export const SAVE_ORDER_TRANSACTION_COMMENT_SUCCESS = "SAVE_ORDER_TRANSACTION_COMMENT_SUCCESS";
export const SAVE_ORDER_TRANSACTION_COMMENT_FAILURE = "SAVE_ORDER_TRANSACTION_COMMENT_FAILURE";

export const SAVE_ORDER_TRANSACTION_DOCUMENTATION_REQUEST = "SAVE_ORDER_TRANSACTION_DOCUMENTATION_REQUEST";
export const SAVE_ORDER_TRANSACTION_DOCUMENTATION_SUCCESS = "SAVE_ORDER_TRANSACTION_DOCUMENTATION_SUCCESS";
export const SAVE_ORDER_TRANSACTION_DOCUMENTATION_FAILURE = "SAVE_ORDER_TRANSACTION_DOCUMENTATION_FAILURE";

export const UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_REQUEST = "UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_REQUEST";
export const UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_SUCCESS = "UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_SUCCESS";
export const UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_FAILURE = "UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_FAILURE";

export const CHARGEBACK_REVERSAL_REQUEST = "CHARGEBACK_REVERSAL_REQUEST";
export const CHARGEBACK_REVERSAL_SUCCESS = "CHARGEBACK_REVERSAL_SUCCESS";
export const CHARGEBACK_REVERSAL_FAILURE = "CHARGEBACK_REVERSAL_FAILURE"

export const getOrderTransactionCommentsFilesAction = (actionToken:string, msbId: string, orderIdentifier: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: GET_ORDER_TRANSACTION_REQUEST,
        payload: {
            actionToken
        }
    });

    let results: any = [];

    let _url = `/MSB_Order/api/v1/OrderTransactions/${msbId}/CommentsAndFiles`;

    axios.get(_url)
        .then(function (response) {
            let orderTransaction = response.data;

            dispatch({
                type: GET_ORDER_TRANSACTION_SUCCESS,
                payload: {
                    orderTransaction,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_ORDER_TRANSACTION_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
    });

};

export const saveOrderTransactionCommentAction = (actionToken:string, msbId: string, notificationDisputeComment: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_ORDER_TRANSACTION_COMMENT_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_Order/api/v1/TransactionComments/ForTransaction/${msbId}`;

    axios.post(_url, notificationDisputeComment)
        .then(function (response) {
            let orderTransaction = response.data;
            dispatch({
                type: SAVE_ORDER_TRANSACTION_COMMENT_SUCCESS,
                payload: {
                    orderTransaction,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_ORDER_TRANSACTION_COMMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};

export const saveOrderTransactionDocumentationAction = (actionToken:string, msbId: string, saveFile:File) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_ORDER_TRANSACTION_DOCUMENTATION_REQUEST,
        payload: { actionToken }
    });


    let _url = `/MSB_Order/api/v1/TransactionFiles/${msbId}/Add`;

    var formData = new FormData();
    formData.append("File", saveFile);

        axios.post(_url, formData)
            .then(function (response) {
                let orderTransaction = response.data;
                dispatch({
                    type: SAVE_ORDER_TRANSACTION_DOCUMENTATION_SUCCESS,
                    payload: {
                        orderTransaction,
                        actionToken
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_ORDER_TRANSACTION_DOCUMENTATION_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });

        });
};

export const updateOrderTransactionChargeBackStatusAction = (actionToken:string, msbId: string, chargeBackStatus:number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_Order/api/v1/ProcessOrder/UpdateChargebackStatus/${msbId}/${chargeBackStatus}`;

        axios.put(_url)
            .then(function (response) {
                let chargebackStatus = response.data;
                dispatch({
                    type: UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_SUCCESS,
                    payload: {
                        chargebackStatus,
                        actionToken
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: UPDATE_ORDER_TRANSACTION_CHARGEBACK_STATUS_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });

        });
};

export const chargebackReversalAction = (actionToken:string, msbId: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CHARGEBACK_REVERSAL_REQUEST,
        payload: { actionToken }
    });

    let _url = `/MSB_Order/api/v1/ProcessOrder/ChargebackReversal/${msbId}`;

        axios.put(_url)
            .then(function (response) {
                let chargebackStatus = response.data;
                dispatch({
                    type: CHARGEBACK_REVERSAL_SUCCESS,
                    payload: {
                        chargebackStatus,
                        actionToken
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: CHARGEBACK_REVERSAL_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });

        });
};