import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../redux/storeTypes';
import { SAVE_CLIENT_REQUEST, SAVE_CLIENT_SUCCESS, SAVE_CLIENT_FAILURE } from '../../../../redux/actions/clients/clients';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { IClientManagementSectionProps } from '../ClientManagement';
import { Routes } from '../../../../routes';
import { Redirect } from 'react-router-dom';
import FormHeader from '../forms/FormHeader';
import ClientDetailsForm from "../forms/ClientDetailsForm";

const ClientDetailsSection = ({ isFetching, isSaving, client, actionResult }: IClientManagementSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<boolean>(false);

    const clientSaveActionToken = "ClientSave";

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_CLIENT_REQUEST && actionResult.token === clientSaveActionToken) {
            if (actionResult.result === SAVE_CLIENT_SUCCESS) {
                dispatch(sendSuccessToastAction("Client details were successfully updated."));
            } else if (actionResult.result === SAVE_CLIENT_FAILURE) {
                dispatch(sendErrorToastAction("Client details could not be updated."));
            }
        }
    }, [actionResult]);


    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
        return (
            <>
                <FormHeader title="Client Details" description="Please enter the client's details." />
                <ClientDetailsForm
                    isFetching={isFetching}
                    isSaving={isSaving}
                    client={client}
                    actionToken={clientSaveActionToken}
                    formActionProps={{
                        disabled: isSaving,
                        showSpinner: isFetching,
                        hasSubmit: true,
                        //isEditMode: client ? true : false,
                        onCancel: () => setRedirect(true),
                        customProps: {
                            submitButtonDisplay: "Save"
                        }
                    }}
                />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(ClientDetailsSection);