import React, { useState, useEffect, Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Popover, OverlayTrigger, Form } from 'react-bootstrap';
import Switch from "react-switch";
import { IAppState } from '../../../../../redux/storeTypes';
import { User, UserTypeEnum } from '../../../../../models/User';
import { Role } from '../../../../../models/Role';
import { setUserAction } from '../../../../../redux/actions/users';
import { connect, useDispatch } from "react-redux";
import { getRolesAction } from '../../../../../redux/actions/roles';

interface IUserRolesProps {
    isFetching: boolean;
    isSaving: boolean;
    user: any;
    roles: Array<Role>;
}

const UserRoles = ({ isFetching, isSaving, user, roles }: IUserRolesProps) => {

    const dispatch = useDispatch();
    let actionToken = "UserRoles";
    const [switchUser, setSwitch] = useState<any>([]);

    useEffect(() => {
        if (roles != null) {
            if (user != null) {
                const userRoles = user?.userRoles?.map((fields: any) => fields?.role)
                const selectedRoles = roles.map((baseRoles: any) => ({
                    ...baseRoles,
                    selected: userRoles.some((roleFields: any) => roleFields.name === baseRoles.name),
                }))
                setSwitch(selectedRoles);
            }
            else {
                setSwitch(roles);
            }
        }
    }, [user,roles]);

    useEffect(() => {
        dispatch(getRolesAction(UserTypeEnum.Navient, actionToken));
    }, []);


    const rolesAction = (event: any, value: any) => {

        value.selected = event;
        setSwitch([...switchUser]);
        
        if (user === null) {
            user = new User();
        }
        const selectedRoles = switchUser.filter(({ selected }: any) => selected == true).map((roleDetails: any) =>
        ({
            role: {
                name: roleDetails.name,
                description: roleDetails.description,
                id: roleDetails.id,
                userTypeEnum: UserTypeEnum.Navient,
            }
        }));
        user.userClients = user.userClients;
        user.userRoles = selectedRoles;
        dispatch(setUserAction(user));

    }


    return (
        <>
            <h3 className="fw-bold">Roles</h3>
            {switchUser != null ?
                <div className="userRoleGrid">
                    <input type="hidden" id="selectedRoles" name="selectedRoles" />
                    {Object.entries(switchUser).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index} className="toggle-switch">
                                    <Switch
                                        onChange={(event) => rolesAction(event, value)}
                                        checked={value.selected}
                                        onColor={'#0057B6'} 
                                        offColor={'#BEBEBE'}
                                        handleDiameter={12}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={16}
                                        width={28}
                                        activeBoxShadow={'0 0 0 1px #0057B6'}
                                    />
                                    <span className="roleType toggle-label">{value.name}</span>
                                    <OverlayTrigger trigger="click" rootClose placement="top" overlay={(
                                        <Popover id="popover-positioned-top">
                                            <Popover.Content>
                                                {value.description}
                                            </Popover.Content>
                                        </Popover>
                                    )}>
                                        <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#8C8C8C", cursor: 'pointer' }} />
                                    </OverlayTrigger>
                                </div>
                            )
                        }
                    )}
                </div>
                : null}
        </>
    );

};

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.users.user,
        clients: state.clients.clients,
        isFetching: state.users.isFetching,
        isSaving: state.users.isSaving,
        roles: state.roles.roles
    };
};

export default connect(mapStateToProps)(UserRoles);


