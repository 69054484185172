import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Spinner } from 'react-bootstrap';
import PageSectionContainer from "../../../components/layout/PageSectionContainer";
import { TransactionDetails as TransactionDetailsModel, BreadCrumbDetails } from '../../../models/Payment';
import { IAppState } from '../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import PageHeader from '../../../components/layout/PageHeader';
import TransactionDetails from '../../payments/components/TransactionDetails';
import RelatedTransaction from '../../payments/components/RelatedTransaction';
import AdditionalDetails from '../../payments/components/AdditionalDetails';
import { Crumb } from '../../../models/Crumb';
import { ClientName, DepartmentName, PaymentChannelName } from '../../../models/Client';
import { resetActionResult } from '../../../redux/actions/payments/paymentTransactions';
import { Routes } from "../../../routes";

interface IViewDetails {
    transactionDetail: TransactionDetailsModel;
    clientNames: Array<ClientName>;
    departmentNames: Array<DepartmentName>;
    paymentChannelNames: Array<PaymentChannelName>;
    breadCrumbDetails: BreadCrumbDetails;
}

const ViewDetails = ({ transactionDetail, clientNames, departmentNames, breadCrumbDetails, paymentChannelNames }: IViewDetails) => {
    const [showModal, setModal] = useState(false);
    const [details, setDetails] = useState<TransactionDetailsModel>();
    const dispatch = useDispatch();

    var crumbs = new Array<Crumb>();
    
    useEffect(() => {
      if (transactionDetail) {
        setDetails(transactionDetail);
        dispatch(resetActionResult());
      }
    }, [transactionDetail]);
    
    
    if (details && clientNames && departmentNames && paymentChannelNames) {
      crumbs.push(new Crumb(`${breadCrumbDetails.reportTitle}`, breadCrumbDetails.reportRoute));
        crumbs.push(new Crumb(`${details?.orderIdentifier} Details`, Routes.ViewDetails.path));
        return (
            <>
                <PageHeader title={`${details.orderIdentifier} Details`} crumbs={crumbs}/>

                <Container fluid className="container-payment-details">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <TransactionDetails details={details} clientNames={clientNames} departmentNames={departmentNames} paymentChannelNames={paymentChannelNames} transactionType={details.transactionType} />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <RelatedTransaction details={details} clientNames={clientNames} departmentNames={departmentNames} paymentChannelNames={paymentChannelNames} transactionType={details.transactionType} />
                            </PageSectionContainer>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <AdditionalDetails details={details} action={details.transactionType}/>
                            </PageSectionContainer>                            
                        </Col>
                    </Row>
                </Container>
                <Modal show={showModal} onHide={() => setModal(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    else {
        return (
            <>
                 <PageHeader title={`Transaction Details`} crumbs={crumbs}/>
                 <Spinner animation="border" />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        transactionDetail: state.paymentTransactions.transactionDetail,
        clientNames: state.clients.clientNames,
        departmentNames: state.clients.departmentNames,
        paymentChannelNames: state.clients.paymentChannelNames,
        breadCrumbDetails: state.paymentTransactions.breadCrumbDetails,
    };
};

export default connect(mapStateToProps)(ViewDetails);

