import axios from 'axios';
import { EmailRequest } from '../../models/EmailRequest';
import { Dispatch } from 'react';
import store from "../store";

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const sendEmailAction = (emailRequest:EmailRequest, actionToken?:string) => (dispatch : Dispatch<any>) => {
    dispatch({
        type: SEND_EMAIL_REQUEST,
        payload: {
            actionToken
        }
    });
    
    let _url = "/MSB_Email/api/v1/Email";

    axios.post(_url, emailRequest)
    .then(function (response) {
        let emailResponse = response.data;
        dispatch({
            type: SEND_EMAIL_SUCCESS,
            payload: {
                emailResponse,
                actionToken
            }
        });
    })
    .catch(function (error) {
        dispatch({
            type: SEND_EMAIL_FAILURE,
            payload: {
                error,
                actionToken
            }
        });
    });
};

