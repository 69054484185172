import { CellPlugin } from '@react-page/editor';
import TylerEaglePaymentReference, { AMOUNT_LABEL, DESCRIPTION_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, ITEM_REFERENCE_LABEL } from './components/TylerEaglePaymentReference';

// use a type here, not an interface
type Data = {
  title: string;
  itemReference: string,
  description: string,
  amount: string,
  value: any,
  headerBackgroundColor: string
}

const TylerEaglePaymentReferencePlugin: CellPlugin<Data> = {
  Renderer: ({data}) => (
   <TylerEaglePaymentReference
      itemReferenceLabel={data.itemReference}
      descriptionLabel={data.description}
      amountLabel={data.amount}
      amount={50}
      description={"Property taxes"}
      itemReference={"PT00001"}
      headerBackgroundColor={data.headerBackgroundColor}
      />
  ),
  id: 'tylerEaglePaymentReferencePlugin',
  title: 'Payment Reference',
  description: 'Collects the payment reference.',
  version: 1,
  controls: [
    {
      title: 'Labels',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            itemReference: {
              type: 'string',
              default: ITEM_REFERENCE_LABEL,
            },
            description: {
              type: 'string',
              default: DESCRIPTION_LABEL,
            },
            amount: {
              type: 'string',
              default: AMOUNT_LABEL,
            },
          },
          required: ['itemReference', 'description', 'amount'],
        },
      },
    },
    {
      title: 'Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            headerBackgroundColor: {
              type: 'string',
              default: HEADER_BACKGROUND_COLOR,
            }
          },
          required: [],
        },
      },
    }
  ],
};

export default TylerEaglePaymentReferencePlugin;