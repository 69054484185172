import axios from 'axios';
import { Dispatch } from 'react';

import store from "../../store";

export const GET_BATCH_REPORT_REQUEST = 'GET_BATCH_REPORT_REQUEST';
export const GET_BATCH_REPORT_SUCCESS = 'GET_BATCH_REPORT_SUCCESS';
export const GET_BATCH_REPORT_FAILURE = 'GET_BATCH_REPORT_FAILURE';

export const GET_BATCH_REPORT_DETAILS_REQUEST = 'GET_BATCH_REPORT_DETAILS_REQUEST';
export const GET_BATCH_REPORT_DETAILS_SUCCESS = 'GET_BATCH_REPORT_DETAILS_SUCCESS';
export const GET_BATCH_REPORT_DETAILS_FAILURE = 'GET_BATCH_REPORT_DETAILS_FAILURE';

export const getBatchReport = (
    fromDate: string,
    toDate: string,
    batchId: string,
    merchantNumber: string,
    batchType: string,
    actionToken: string
) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_BATCH_REPORT_REQUEST,
        payload: {
            actionToken
        }
    });

    const url = store.getState().webAppSettings.baseApiUrl + "/MSB_Emaf/api/v1/Emaf/GetBatchReport";

    const config = {
        headers: {
            Authorization: `Bearer ${store.getState().webAppSettings.bearerToken}`
        },
        params: {
            ...(!!fromDate && { fromDate }),
            ...(!!toDate && { toDate }),
            ...(!!batchId && { batchId }),
            ...(!!merchantNumber && { merchantNumber })
        }
    };

    axios.get(url, config)
        .then(function (response) {
            let items = response.data || [];
            if (!!batchType) {
                items = items.filter((item: { batchType: string }) => item.batchType?.toLowerCase() === batchType);
            }
            dispatch({
                type: GET_BATCH_REPORT_SUCCESS,
                payload: {
                    items,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_BATCH_REPORT_FAILURE,
                payload: {
                    error: error,
                    actionToken
                }
            });
        });
};

export const getBatchReportDetails = (
    processDate: string,
    merchantNumber: string,
    batchId: string,
    actionToken: string
) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_BATCH_REPORT_DETAILS_REQUEST,
        payload: {
            actionToken
        }
    });

    const url = store.getState().webAppSettings.baseApiUrl + "/MSB_Emaf/api/v1/Emaf/GetBatchReportDetails";

    const config = {
        headers: {
            Authorization: `Bearer ${store.getState().webAppSettings.bearerToken}`
        },
        params: {
            ...(!!processDate && { processDate }),
            ...(!!merchantNumber && { merchantNumber }),
            ...(!!batchId && { batchId })
        }
    };

    axios.get(url, config)
        .then(function (response) {
            dispatch({
                type: GET_BATCH_REPORT_DETAILS_SUCCESS,
                payload: {
                    items: response.data,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_BATCH_REPORT_DETAILS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};



