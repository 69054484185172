import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button } from "react-bootstrap"

interface IPaymentReference {
    paymentDescriptionLabel: string,
    paymentDescriptionPlaceholder: string,
    showDescription: boolean,
    descriptionRequired: boolean,
    paymentReferenceLabel: string,
    paymentReferencePlaceholder: string,
    amountLabel: string,
    addItemLabel: string,
    margin: string,
    padding: string,
    submitButtonBackgroundColor: string,
    submitButtonFontColor: string,
    submitButtonFontSize: string,
    submitButtonFontStyle: string,
    submitButtonFontWeight: string,
    fullStoryMask: boolean,
}

export const PAYMENT_DESCRIPTION_LABEL = 'Payment Description';
export const PAYMENT_DESCRIPTION_PLACEHOLDER = 'Enter Payment Description';
export const PAYMENT_REFERENCE_LABEL = 'Payment Reference';
export const PAYMENT_REFERENCE_PLACEHOLDER = 'Enter Payment Reference';
export const AMOUNT_LABEL = 'Amount';
export const ADD_ITEM_LABEL = 'Add Item';
export const MARGIN = '0px';
export const PADDING = '0px';
export const SUBMIT_BUTTON_FONT_COLOR = '#FFFFFF';
export const SUBMIT_BUTTON_FONT_SIZE = '.875rem';
export const SUBMIT_BUTTON_FONT_STYLE = 'normal';
export const SUBMIT_BUTTON_FONT_WEIGHT = 'normal';
export const SUBMIT_BUTTON_BACKGROUND_COLOR = '#0057b6';
export const FULL_STORY_MASK = false;

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

const PaymentReference = ({ paymentDescriptionLabel, paymentDescriptionPlaceholder, showDescription, descriptionRequired, paymentReferenceLabel, paymentReferencePlaceholder, amountLabel, addItemLabel, margin, padding,
    submitButtonBackgroundColor, submitButtonFontColor, submitButtonFontSize, submitButtonFontStyle, submitButtonFontWeight, fullStoryMask }: IPaymentReference) => {

    if (!paymentDescriptionLabel) {
        paymentDescriptionLabel = PAYMENT_DESCRIPTION_LABEL;
    }

    if (!paymentDescriptionPlaceholder) {
        paymentDescriptionPlaceholder = PAYMENT_DESCRIPTION_PLACEHOLDER;
    }

    if (!paymentReferenceLabel) {
        paymentReferenceLabel = PAYMENT_REFERENCE_LABEL;
    }

    if (!paymentReferencePlaceholder) {
        paymentReferencePlaceholder = PAYMENT_REFERENCE_PLACEHOLDER;
    }

    if (!amountLabel) {
        amountLabel = AMOUNT_LABEL;
    }

    if (!addItemLabel) {
        addItemLabel = ADD_ITEM_LABEL;
    }

    if (!margin) {
        margin = MARGIN;
    }

    if (!padding) {
        padding = PADDING;
    }

    if (!submitButtonBackgroundColor) {
        submitButtonBackgroundColor = SUBMIT_BUTTON_BACKGROUND_COLOR;
    }

    if (!submitButtonFontColor) {
        submitButtonFontColor = SUBMIT_BUTTON_FONT_COLOR;
    }

    if (!submitButtonFontSize) {
        submitButtonFontSize = SUBMIT_BUTTON_FONT_SIZE;
    }

    if (!submitButtonFontStyle) {
        submitButtonFontStyle = SUBMIT_BUTTON_FONT_STYLE;
    }

    if (!submitButtonFontWeight) {
        submitButtonFontWeight = SUBMIT_BUTTON_FONT_WEIGHT;
    }

    fullStoryMask = fullStoryMask || FULL_STORY_MASK;

    return (
        <div className={fullStoryMask ? "fs-mask" : ""}>
          <Row style={{ margin: margin, padding: padding, alignItems: 'flex-end' }}>
            {showDescription && <Col>
                <Form.Group controlId="">
                    <Form.Label>{paymentDescriptionLabel}</Form.Label>
                    <Form.Control type="input" className="fs-mask" placeholder={paymentDescriptionPlaceholder} />
                </Form.Group>
            </Col>}
            <Col>
                <Form.Group controlId="">
                    <Form.Label>{paymentReferenceLabel}</Form.Label>
                    <Form.Control type="input" className="fs-mask" placeholder={paymentReferencePlaceholder} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="">
                    <Form.Label>{amountLabel}</Form.Label>
                    <Form.Control type="input" placeholder="0.00" />
                </Form.Group>
            </Col>
            <Col>
                <Button className="brand-btn-primary" 
                    style={{marginTop: '29px',
                        backgroundColor:checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                        borderColor:checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                        color:checkStyleForDefault(submitButtonFontColor, SUBMIT_BUTTON_FONT_COLOR),
                        fontSize:checkStyleForDefault(submitButtonFontSize, SUBMIT_BUTTON_FONT_SIZE),
                        fontStyle:checkStyleForDefault(submitButtonFontStyle, SUBMIT_BUTTON_FONT_STYLE),
                        fontWeight:checkStyleForDefault3(submitButtonFontWeight)
                    }}>
                    <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> {addItemLabel}
                </Button>
            </Col>
        </Row>
      </div>
    )
}

export default PaymentReference;