import { Row, Col, Form, ButtonToolbar, Button, Modal, ListGroup, Dropdown } from "react-bootstrap"
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useState } from "react";
import FormHeaderConfirmation from "../../../../../../components/FormHeaderConfirmation";
import icoWarning from "../../../../../../assets/img/icons/ico-warning-outline.svg";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface IUserScheduledPayments {
    scheduledDateLabel: string,
    accountIdLabel: string,
    paymentTypeLabel: string,
    convenienceFeeLabel: string,
    totalAmountScheduledLabel: string,
    scheduledPaymentOptionLabel: string,
    amountDueLabel: string,
    actionLabel: string,
    scheduledDate: string,
    accountId: string,
    paymentOption: string,
    paymentType: string,
    amountDue: string,
    convenienceFee: string,
    totalAmountScheduled: string,
    action: any,
    ownersLabel: string,
    addressLabel: string,
    paymentDateLabel: string,
    paymentOptionLabel:string,
    paymentMethodLabel: string,
    headerBackgroundColor: string
}

export const SCHEDULED_DATE_LABEL = 'Scheduled Date';
export const ACCOUNT_ID_LABEL = 'Account ID';
export const PAYMENT_OPTION_LABEL = 'Payment Option'
export const PAYMENT_TYPE_LABEL = 'Payment Type';
export const CONVENIENCE_FEE_LABEL = 'Convenience Fee';
export const AMOUNT_DUE_LABEL = 'Amount Due';
export const TOTAL_AMOUNT_SCHEDULED_LABEL = 'Total Amount Scheduled';
export const ACTIONS_LABEL = 'Actions';
export const OWNERS_LABEL = 'Owners';
export const ADDRESS_LABEL = 'Address';
export const PAYMENT_DATE_LABEL = 'Select Future Payment Date';
export const SCHEDULED_PAYMENT_OPTION_LABEL = 'Select Payment Option';
export const PAYMENT_METHOD_LABEL = 'Select Payment Method';
export const HEADER_BACKGROUND_COLOR = '#FAFAFA';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const UserScheduledPayments = ({ scheduledDateLabel, accountIdLabel, paymentTypeLabel, convenienceFeeLabel, totalAmountScheduledLabel,scheduledPaymentOptionLabel, 
    amountDueLabel, actionLabel, accountId,scheduledDate, paymentOption, paymentType, amountDue,convenienceFee,totalAmountScheduled, action,
    ownersLabel, addressLabel,paymentDateLabel, paymentOptionLabel, paymentMethodLabel, headerBackgroundColor }: IUserScheduledPayments) => {

    const [scheduleModal, showScheduleModal] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>([]);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    if (!scheduledDateLabel) {
        scheduledDateLabel = SCHEDULED_DATE_LABEL;
    }

    if (!accountIdLabel) {
        accountIdLabel = ACCOUNT_ID_LABEL;
    }

    if (!paymentOptionLabel) {
        paymentOptionLabel = PAYMENT_OPTION_LABEL;
    }

    if (!paymentTypeLabel) {
        paymentTypeLabel = PAYMENT_TYPE_LABEL;
    }

    if (!convenienceFeeLabel) {
        convenienceFeeLabel = CONVENIENCE_FEE_LABEL;
    }

    if (!amountDueLabel) {
        amountDueLabel = AMOUNT_DUE_LABEL;
    }

    if(!totalAmountScheduledLabel){
        totalAmountScheduledLabel= TOTAL_AMOUNT_SCHEDULED_LABEL
    }

    if (!actionLabel) {
        actionLabel = ACTIONS_LABEL;
    }

    if (!ownersLabel) {
        ownersLabel = OWNERS_LABEL;
    }

    if (!addressLabel) {
        addressLabel = ADDRESS_LABEL;
    }

    if (!paymentDateLabel) {
        paymentDateLabel = PAYMENT_DATE_LABEL;
    }

    if(!scheduledPaymentOptionLabel){
        scheduledPaymentOptionLabel = SCHEDULED_PAYMENT_OPTION_LABEL;
    }

    if (!paymentMethodLabel) {
        paymentMethodLabel = PAYMENT_METHOD_LABEL;
    }


    const actionsFormatter = (cell: any, row:any) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-client-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                        <Dropdown.Item onSelect={() => {showScheduleModal(true);setRowData(row)}}>Edit Schedule</Dropdown.Item>
                        <Dropdown.Item onSelect={() => {setDeleteModal(true)}}>Delete Schedule</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const columns = [{
        dataField: 'scheduledDate',
        text: scheduledDateLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'accountId',
        text: accountIdLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'paymentOption',
        text: paymentOptionLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'paymentType',
        text: paymentTypeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'convenienceFee',
        text: convenienceFeeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'amountDue',
        text: amountDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'totalAmountScheduled',
        text: totalAmountScheduledLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'id',
        text: actionLabel,
        formatter: actionsFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const renderpPaymentSchedule = () => {
        return (
            <Form id='schedulePayments' className="mt-0">
                <ListGroup variant="flush">
                    <ListGroup.Item className="p-0">
                        <div>
                            <h2>{`Edit ${rowData.accountId} Payment Schedule`}</h2>
                            <p className="mb-4">Below are the details for this account.</p>
                            <div className="notification_details_description">
                                <h3>{accountIdLabel}</h3>
                                <span>{rowData.accountId}</span>
                            </div>
                            <div className="notification_details_description">
                                <h3>{ownersLabel}</h3>
                                <span>{rowData.owners}</span>
                            </div>
                            <div className="notification_details_description">
                                <h3>{addressLabel}</h3>
                                <span>{rowData.address}</span>
                            </div>
                            <div className="notification_details_description">
                                <h3>{amountDueLabel}</h3>
                                <span>{rowData.amountDue}</span>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <hr />
                    <ListGroup.Item className="p-0">
                        <div className="details_title">
                            <h2>Payment Schedule</h2>
                            <p>Please select the future payment date, payment method and payment option.</p>
                        </div>
                        <div>
                            <Form.Group as={Row} controlId="paymentDate" className="mb-3">
                                <Form.Label column>{paymentDateLabel}</Form.Label>
                                <Col>
                                    <Form.Control type="Date" required />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="paymentOption" className="mb-3">
                                <Form.Label column>{paymentOptionLabel}</Form.Label>
                                <Col>
                                    <Form.Control as="select" required>
                                        <option>First Half</option>
                                        <option>Full Payment</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="paymentType" className="mb-3">
                                <Form.Label column>{paymentMethodLabel}</Form.Label>
                                <Col>
                                    <Form.Control as="select" required>
                                        <option>Credit Card</option>
                                        <option>eCheck</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <p style={{ color: '#141414' }}><strong>Returned payments will be charged a $20 fee. Late payments will incur late interest of 1% per month.</strong></p>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Form>
        )
    }

    
    const data = [{ scheduledDate:scheduledDate,accountId: accountId, paymentOption: paymentOption, paymentType: paymentType, amountDue: amountDue,
        convenienceFee:convenienceFee,totalAmountScheduled:totalAmountScheduled }]
    return (
        <>
            <Modal show={scheduleModal} onHide={() => showScheduleModal(false)} backdrop="static">
                <Modal.Header closeButton />
                <Modal.Body>
                    {renderpPaymentSchedule()}
                </Modal.Body>
            </Modal>

            <Modal show={deleteModal} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Delete scheduled payment" />
                    <div className="confirmation-body">
                        <p>Are you sure you would like to delete this scheduled payment?</p>
                        <Form.Group className="confirmation-footer">
                            <Button variant="outline-secondary" onClick={() => setDeleteModal(false)}>
                                Cancel
                            </Button>
                            <Button onClick={() => setDeleteModal(false)}>
                                Confirm
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>

            <Row>
                <Col>
                    <ToolkitProvider keyField="id" data={data} columns={columns}>
                        {
                            (props: any) => (
                                <>
                                    <Row>
                                        <Col>
                                          <PerfectScrollbar>
                                            <BootstrapTable
                                                paginationTotalRenderer={''}
                                                pagination={paginationFactory({
                                                    page: 1,
                                                    sizePerPage: 10,
                                                    totalSize: data.length,
                                                    alwaysShowAllBtns: true,
                                                    withFirstAndLast: true,
                                                    hideSizePerPage: false,
                                                    firstPageText: '\u27E8\u27E8',
                                                    prePageText: '\u27E8',
                                                    nextPageText: '\u27E9',
                                                    lastPageText: '\u27E9\u27E9',
                                                    nextPageTitle: 'Next page',
                                                    prePageTitle: 'Previous page',
                                                    firstPageTitle: 'First page',
                                                    lastPageTitle: 'Last page',
                                                    showTotal: true,
                                                })}
                                                {...props.baseProps}
                                            />
                                          </PerfectScrollbar>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </ToolkitProvider>
                </Col>
            </Row>
        </>
    )
}

export default UserScheduledPayments;