import { CellPlugin } from '@react-page/editor';
import UserScheduledPayments, { SCHEDULED_DATE_LABEL, ACCOUNT_ID_LABEL, PAYMENT_OPTION_LABEL, PAYMENT_TYPE_LABEL, AMOUNT_DUE_LABEL, ACTIONS_LABEL,
    CONVENIENCE_FEE_LABEL, TOTAL_AMOUNT_SCHEDULED_LABEL, HEADER_BACKGROUND_COLOR, 
    OWNERS_LABEL, ADDRESS_LABEL,PAYMENT_DATE_LABEL, PAYMENT_METHOD_LABEL, SCHEDULED_PAYMENT_OPTION_LABEL, HEADER_PADDING } from './components/UserScheduledPayments';

// use a type here, not an interface
type Data = {
    title: string;
    scheduledDate:string,
    accountId: string,
    paymentOption: string,
    paymentType: string,
    amountDue: string,
    convenienceFee: string,
    totalAmountScheduled: string,
    action: any,
    owners: string,
    address: string,
    paymentDate: string,
    scheduledPaymentOption: string,
    paymentMethod: string,
    headerBackgroundColor: string
}

const UserScheduledPaymentsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <UserScheduledPayments
            scheduledDateLabel={data.scheduledDate}
            accountIdLabel={data.accountId}
            paymentOptionLabel={data.paymentOption}
            paymentTypeLabel={data.paymentType}
            amountDueLabel={data.amountDue}
            convenienceFeeLabel={data.convenienceFee}
            totalAmountScheduledLabel={data.totalAmountScheduled}
            actionLabel={data.action}
            scheduledDate={'2/28/22'}
            accountId={'R0350590'}
            paymentOption={'First half'}
            paymentType={'Credit Card'}
            amountDue={'$1,811.31'}
            convenienceFee={'$1.19'}
            totalAmountScheduled={'$1,812.50'}
            action={''}
            ownersLabel={data.owners}
            addressLabel={data.address}
            paymentDateLabel={data.paymentDate}
            paymentMethodLabel={data.paymentMethod}
            scheduledPaymentOptionLabel={data.scheduledPaymentOption}
            headerBackgroundColor={data.headerBackgroundColor}
        />
    ),
    id: 'userScheduledPaymentsPlugin',
    title: 'User Scheduled Payments',
    description: 'User Scheduled Payments',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        scheduledDate:{
                            type: 'string',
                            default: SCHEDULED_DATE_LABEL,
                        },
                        accountId: {
                            type: 'string',
                            default: ACCOUNT_ID_LABEL,
                        },
                        paymentOption: {
                            type: 'string',
                            default: PAYMENT_OPTION_LABEL,
                        },
                        paymentType: {
                            type: 'string',
                            default: PAYMENT_TYPE_LABEL,
                        },
                        amountDue: {
                            type: 'string',
                            default: AMOUNT_DUE_LABEL,
                        },
                        convenienceFee:{
                            type: 'string',
                            default: CONVENIENCE_FEE_LABEL,
                        },
                        totalAmountScheduled:{
                            type: 'string',
                            default: TOTAL_AMOUNT_SCHEDULED_LABEL, 
                        },
                        action: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: ['accountId', 'paymentOption', 'amountDue'],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Modal Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        paymentDate: {
                            type: 'string',
                            default: PAYMENT_DATE_LABEL,
                        },
                        owners: {
                            type: 'string',
                            default: OWNERS_LABEL,
                        },
                        address: {
                            type: 'string',
                            default: ADDRESS_LABEL,
                        },
                        scheduledPaymentOption: {
                            type: 'string',
                            default: SCHEDULED_PAYMENT_OPTION_LABEL,
                        },
                        paymentMethod: {
                            type: 'string',
                            default: PAYMENT_METHOD_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default UserScheduledPaymentsPlugin;