import {
    GET_BATCH_REPORT_REQUEST, GET_BATCH_REPORT_SUCCESS, GET_BATCH_REPORT_FAILURE,
    GET_BATCH_REPORT_DETAILS_REQUEST, GET_BATCH_REPORT_DETAILS_SUCCESS, GET_BATCH_REPORT_DETAILS_FAILURE
} from '../actions/reports/eMaf';

export default function eMafTransactions(state = {
    isFetching: false,
    isFetchingDetails: false,
    items: [],
    itemDetails: [],
    actionResult: null,
    errorMessage: ''
}, { type, payload }) {
    switch (type) {
        // GetEmafBatchReport
        case GET_BATCH_REPORT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                items: [],
                actionResult: { type: GET_BATCH_REPORT_REQUEST, token: payload.actionToken, result: null },
            });
        case GET_BATCH_REPORT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                items: payload.items,
                actionResult: { type: GET_BATCH_REPORT_REQUEST, token: payload.actionToken, result: GET_BATCH_REPORT_SUCCESS }
            });
        case GET_BATCH_REPORT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_BATCH_REPORT_REQUEST, token: payload.actionToken, result: GET_BATCH_REPORT_FAILURE }
            });

        // GetEmafBatchReportDetails
        case GET_BATCH_REPORT_DETAILS_REQUEST:
            return Object.assign({}, state, {
                isFetchingDetails: true,
                itemDetails: [],
                actionResult: { type: GET_BATCH_REPORT_DETAILS_REQUEST, token: payload.actionToken, result: null },
            });
        case GET_BATCH_REPORT_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                isFetchingDetails: false,
                itemDetails: payload.items,
                actionResult: { type: GET_BATCH_REPORT_DETAILS_REQUEST, token: payload.actionToken, result: GET_BATCH_REPORT_DETAILS_SUCCESS }
            });
        case GET_BATCH_REPORT_DETAILS_FAILURE:
            return Object.assign({}, state, {
                isFetchingDetails: false,
                errorMessage: payload.error,
                actionResult: { type: GET_BATCH_REPORT_DETAILS_REQUEST, token: payload.actionToken, result: GET_BATCH_REPORT_DETAILS_FAILURE }
            });
        default:
            return state;
    }
}
