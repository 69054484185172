import React, { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import RequiredIcon from "../../../../../components/RequiredIcon";
import { StateDropdown } from "../../../../../components/StateDropdown";
import { AddressTypeEnum, Client, Address, Department } from "../../../../../models/Client";
import Inputmask from "react-input-mask";
import FormActions, { IFormActionProps } from '../FormActions';
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { addDepartmentAddressAction, saveDepartmentAddressAction } from "../../../../../redux/actions/clients/address";

export interface IDepartmentAddressFormProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    department: Department,
    address?: Address,
    actionToken: string,
    formActionProps?: IFormActionProps,
}

const DepartmentAddressForm = ({ isSaving, isFetching, client, department, address, formActionProps, actionToken }: IDepartmentAddressFormProps) => { 
    const dispatch = useDispatch();
    const [validated, setValidated] = useState<boolean>(false);

    const [formAddAction, setFormAddAction] = useState(formActionProps?.customProps.submitButtonDisplay == 'Add');

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            let _address: Address = Object.assign({}, address!!);
            _address.addressReferenceName = form.elements.addressReferenceName.value;
            _address.addressType = AddressTypeEnum[form.elements.addressTypeEnum.value] as keyof typeof AddressTypeEnum;
            _address.addressLine1 = form.elements.addressLine1.value;
            _address.addressLine2 = form.elements.addressLine2.value;
            _address.city = form.elements.city.value;
            _address.state = form.elements.state.value;
            _address.zipCode = form.elements.zipCode.value;

            if (formAddAction) {
                dispatch(addDepartmentAddressAction(department.msbId!,_address, actionToken));
            } else {
                dispatch(saveDepartmentAddressAction(_address, actionToken));
            }
        }
        setValidated(true);
    };

    return (
        <Form id="department-address-form" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} sm={12}>
                <Form.Group controlId="addressReferenceName">
                    <Form.Label><RequiredIcon />Address reference name</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                        <Form.Control required type="input" disabled={isSaving} defaultValue={address?.addressReferenceName} maxLength={40} placeholder="Enter Address reference name" />
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please enter an Address reference name.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group controlId="addressTypeEnum">
                    <Form.Label><RequiredIcon />Address type</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required as="select" defaultValue={AddressTypeEnum[address?.addressType as keyof typeof AddressTypeEnum]}>
                                <option value={AddressTypeEnum.Physical}>Physical</option>
                                <option value={AddressTypeEnum.Mailing}>Mailing</option>
                                <option value={AddressTypeEnum.TerminalDeployment}>Terminal Deployment</option>
                        </Form.Control>
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please select an Address type.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} sm={12}>
                <Form.Group controlId="addressLine1">
                    <Form.Label><RequiredIcon />Address line 1</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                        <Form.Control required type="input" disabled={isSaving} defaultValue={address?.addressLine1} maxLength={40} placeholder="Enter Address line 1" />
                        <Form.Control.Feedback type="invalid">Please enter an Address.</Form.Control.Feedback>
                    </ReactPlaceholder>
                </Form.Group>
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group controlId="addressLine2">
                    <Form.Label>Address line 2</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                        <Form.Control type="input" disabled={isSaving} defaultValue={address?.addressLine2} maxLength={40} placeholder="Enter Address line 2" />
                    </ReactPlaceholder>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} sm={12}>
                <Form.Group controlId="city">
                    <Form.Label><RequiredIcon />City</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                      <Form.Control required type="input" disabled={isSaving} defaultValue={address?.city} maxLength={40} placeholder="Enter City" />
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please enter a City.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={4} sm={12}>
                <Form.Group controlId="state">
                    <Form.Label><RequiredIcon />State</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                        <StateDropdown required={true} disabled={isSaving} defaultValue={address?.state}></StateDropdown>
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please select a State.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={2} sm={12}>
                <Form.Group controlId="zipCode">
                    <Form.Label><RequiredIcon />Zip code</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                        <Inputmask id="zipCode" name="zipCode" required type="input" disabled={isSaving} defaultValue={address?.zipCode} pattern="[0-9]{5}" mask="99999" className="form-control" placeholder="Enter Zip code" />
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please enter a Zip code.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <FormActions
                disabled={isSaving || isFetching}
                showSpinner={isSaving}
                {...formActionProps} />
        </Form>
    );
};

export default DepartmentAddressForm;