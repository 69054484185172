import { useState } from "react";
import { Row, Col, Form, Table, Modal, Button, InputGroup } from "react-bootstrap"
import FormHeaderConfirmation from "../../../../../../components/FormHeaderConfirmation";
import { StateDropdown } from "../../../../../../components/StateDropdown";
import icoWarning from "../../../../../../assets/img/icons/ico-warning-outline.svg";
import RequiredIcon from "../../../../../../components/RequiredIcon";
import InputMask from "react-input-mask";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { isInvalidCardExpiration } from "../../../../../../utils/globalCalculations";

interface IUserPaymentMethods {
    paymentTypeLabel: string,
    cardTypeLabel: string,
    accountNumberLabel: string,
    expirationDateLabel: string,
    actionsLabel: string,
    cardType: string,
    paymentType: string,
    accountNumber: string,
    expirationDate: string,
    actions: any,
    cardholderNameLabel: string,
    cardholderNamePlaceholder: string,
    cardholderNameRequired: string,
    modalCardTypeLabel: string,
    cardTypeRequired: string,
    cardNumberLabel: string,
    cardNumberRequired: string,
    modalExpirationDateLabel: string,
    expirationDateRequired: string,
    cvvLabel: string,
    cvvRequired: string,
    addressLine1Label: string,
    addressLine1Placeholder: string,
    addressLine2Label: string,
    addressLine2Placeholder: string,
    cityLabel: string,
    cityPlaceholder: string,
    stateLabel: string,
    zipCodeLabel: string,
    zipCodeRequired: string,
    nameOnAccountLabel: string,
    nameOnAccountPlaceholder: string,
    nameOnAccountRequired: string,
    bankAccountNumberLabel: string,
    bankAccountNumberRequired: string,
    confirmBankAccountNumberLabel: string,
    confirmBankAccountNumberRequired: string,
    bankRoutingNumberLabel: string,
    bankRoutingNumberRequired: string,
    confirmBankRoutingNumberLabel: string,
    confirmBankRoutingNumberRequired: string,
    headerBackgroundColor: string
}

export const PAYMENT_TYPE_LABEL = 'Payment Type';
export const CARD_TYPE_LABEL = 'Card Type';
export const ACCOUNT_NUMBER_LABEL = 'Account Number';
export const EXPIRATION_DATE_LABEL = 'Expiration Date';
export const ACTIONS_LABEL = 'Actions';

export const ADDRESS_LINE_1_LABEL = 'Address line 1';
export const ADDRESS_LINE_1_PLACEHOLDER = 'Enter address line 1';
export const ADDRESS_LINE_2_LABEL = 'Address line 2';
export const ADDRESS_LINE_2_PLACEHOLDER = 'Enter address line 2';
export const CITY_LABEL = 'City';
export const CITY_PLACEHOLDER = 'Enter city';
export const STATE_LABEL = 'State';
export const ZIP_CODE_LABEL = 'Zip code';
export const ZIP_CODE_REQUIRED = 'Zip code required';

export const CARDHOLDER_NAME_LABEL = 'Cardholder name';
export const CARDHOLDER_NAME_PLACEHOLDER = 'Enter Cardholder name';
export const CARDHOLDER_NAME_REQUIRED = 'Please enter the Cardholder’s name.';
export const MODAL_CARD_TYPE_LABEL = 'Card Type'
export const CARD_TYPE_REQUIRED = 'Please select the Card type.';
export const CARD_NUMBER_LABEL = 'Card number';
export const CARD_NUMBER_REQUIRED = 'Please enter a valid 12-16 digit Card number.';
export const MODAL_EXPIRATION_DATE_LABEL = 'Expiration date';
export const EXPIRATION_DATE_REQUIRED = 'Please enter the Expiration date.';
export const CVV_LABEL = 'CVV';
export const CVV_REQUIRED = 'Please enter the CVV number.';


export const NAME_ON_ACCOUNT_LABEL = 'Name on account';
export const NAME_ON_ACCOUNT_PLACEHOLDER = 'Enter Name on account';
export const NAME_ON_ACCOUNT_REQUIRED = 'Please enter the Name on account.';
export const BANK_ACCOUNT_NUMBER_LABEL = 'Bank account number';
export const BANK_ACCOUNT_NUMBER_REQUIRED = 'Please enter the Bank account number.';
export const CONFIRM_BANK_ACCOUNT_NUMBER_LABEL = 'Confirm bank account number';
export const CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED = 'Please enter the Bank account number.';
export const BANK_ROUTING_NUMBER_LABEL = 'Bank routing number';
export const BANK_ROUTING_NUMBER_REQUIRED = 'Please enter the Bank routing number.';
export const CONFIRM_BANK_ROUTING_NUMBER_LABEL = 'Confirm bank routing number';
export const CONFIRM_BANK_ROUTING_NUMBER_REQUIRED = 'Please enter the Bank routing number.';


export const HEADER_BACKGROUND_COLOR = '#FAFAFA';
export const HEADER_PADDING = '0';


const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const UserPaymentMethods = ({
    paymentTypeLabel, cardTypeLabel, accountNumberLabel, expirationDateLabel, actionsLabel, cardType, paymentType, accountNumber, expirationDate, headerBackgroundColor,
    cardholderNameLabel, cardholderNamePlaceholder, cardholderNameRequired, modalCardTypeLabel, cardTypeRequired, cardNumberLabel, cardNumberRequired, modalExpirationDateLabel, expirationDateRequired, cvvLabel, cvvRequired,
    addressLine1Label, addressLine1Placeholder, addressLine2Label, addressLine2Placeholder, cityLabel, cityPlaceholder, stateLabel, zipCodeLabel, zipCodeRequired,
    nameOnAccountLabel, nameOnAccountPlaceholder, nameOnAccountRequired, bankAccountNumberLabel, bankAccountNumberRequired, confirmBankAccountNumberLabel,
    confirmBankAccountNumberRequired, bankRoutingNumberLabel, bankRoutingNumberRequired, confirmBankRoutingNumberLabel, confirmBankRoutingNumberRequired
}
    : IUserPaymentMethods) => {

    const [creditCardModal, showCreditCardModal] = useState<boolean>(false);
    const [eCheckModal, showEcheckModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const [showCVV, setShowCVV] = useState<boolean>(false);
    const [showCardNumber, setShowCardNumber] = useState<boolean>(false);
    const [cardExpirationInvalid, setCardExpirationInvalid] = useState<boolean>(false);
    const [cardExpirationMessage, setCardExpirationMessage] = useState<string>('');
    

    if (!paymentTypeLabel) {
        paymentTypeLabel = PAYMENT_TYPE_LABEL;
    }

    if (!cardTypeLabel) {
        cardTypeLabel = CARD_TYPE_LABEL;
    }

    if (!accountNumberLabel) {
        accountNumberLabel = ACCOUNT_NUMBER_LABEL;
    }

    if (!expirationDateLabel) {
        expirationDateLabel = EXPIRATION_DATE_LABEL;
    }

    if (!actionsLabel) {
        actionsLabel = ACTIONS_LABEL;
    }

    if (!cardholderNameLabel) {
        cardholderNameLabel = CARDHOLDER_NAME_LABEL;
    }

    if (!cardholderNamePlaceholder) {
        cardholderNamePlaceholder = CARDHOLDER_NAME_PLACEHOLDER;
    }

    if (!cardholderNameRequired) {
        cardholderNameRequired = CARDHOLDER_NAME_REQUIRED;
    }

    if (!modalCardTypeLabel) {
        modalCardTypeLabel = MODAL_CARD_TYPE_LABEL;
    }

    if (!cardTypeRequired) {
        cardTypeRequired = CARD_TYPE_REQUIRED;
    }

    if (!cardNumberLabel) {
        cardNumberLabel = CARD_NUMBER_LABEL;
    }

    if (!cardNumberRequired) {
        cardNumberRequired = CARD_NUMBER_REQUIRED;
    }

    if (!modalExpirationDateLabel) {
        modalExpirationDateLabel = MODAL_EXPIRATION_DATE_LABEL;
    }

    if (!expirationDateRequired) {
        expirationDateRequired = EXPIRATION_DATE_REQUIRED;
    }

    if (!cvvLabel) {
        cvvLabel = CVV_LABEL;
    }

    if (!cvvRequired) {
        cvvRequired = CVV_REQUIRED;
    }

    if (!nameOnAccountLabel) {
        nameOnAccountLabel = NAME_ON_ACCOUNT_LABEL;
    }

    if (!nameOnAccountPlaceholder) {
        nameOnAccountPlaceholder = NAME_ON_ACCOUNT_PLACEHOLDER;
    }

    if (!nameOnAccountRequired) {
        nameOnAccountRequired = NAME_ON_ACCOUNT_REQUIRED;
    }

    if (!bankAccountNumberLabel) {
        bankAccountNumberLabel = BANK_ACCOUNT_NUMBER_LABEL;
    }

    if (!bankAccountNumberRequired) {
        bankAccountNumberRequired = BANK_ACCOUNT_NUMBER_REQUIRED;
    }

    if (!confirmBankAccountNumberLabel) {
        confirmBankAccountNumberLabel = CONFIRM_BANK_ACCOUNT_NUMBER_LABEL;
    }

    if (!confirmBankAccountNumberRequired) {
        confirmBankAccountNumberRequired = CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED;
    }

    if (!bankRoutingNumberLabel) {
        bankRoutingNumberLabel = BANK_ROUTING_NUMBER_LABEL;
    }

    if (!bankRoutingNumberRequired) {
        bankRoutingNumberRequired = BANK_ROUTING_NUMBER_REQUIRED;
    }

    if (!confirmBankRoutingNumberLabel) {
        confirmBankRoutingNumberLabel = CONFIRM_BANK_ROUTING_NUMBER_LABEL;
    }

    if (!confirmBankRoutingNumberRequired) {
        confirmBankRoutingNumberRequired = CONFIRM_BANK_ROUTING_NUMBER_REQUIRED;
    }

    if (!addressLine1Label) {
        addressLine1Label = ADDRESS_LINE_1_LABEL;
    }

    if (!addressLine1Placeholder) {
        addressLine1Placeholder = ADDRESS_LINE_1_PLACEHOLDER;
    }

    if (!addressLine2Label) {
        addressLine2Label = ADDRESS_LINE_2_LABEL;
    }

    if (!addressLine2Placeholder) {
        addressLine2Placeholder = ADDRESS_LINE_2_PLACEHOLDER;
    }

    if (!cityLabel) {
        cityLabel = CITY_LABEL;
    }

    if (!cityPlaceholder) {
        cityPlaceholder = CITY_PLACEHOLDER;
    }

    if (!stateLabel) {
        stateLabel = STATE_LABEL;
    }

    if (!zipCodeLabel) {
        zipCodeLabel = ZIP_CODE_LABEL;
    }

    if (!zipCodeRequired) {
        zipCodeRequired = ZIP_CODE_REQUIRED;
    }

    const actionFormatter = (accountNumber: string, paymentType: string) => {
        if (accountNumber) {
            return <a onClick={() => setDeleteModal(true)}>Delete</a>
        }
        if (!accountNumber && paymentType === 'eCheck') {
            return <a onClick={() => showEcheckModal(true)}>Add</a>
        }
        if (!accountNumber && paymentType === 'Credit Card') {
            return <a onClick={() => showCreditCardModal(true)}>Add</a>
        }
    }

    const checkCardExpiry = (e: any) => {
        const cardExpiry = e.target.value;
        const checkDateFormat = /^[0-9\/\s]{5}$/
        const isValid = checkDateFormat.test(e.target.value)
        if (isValid) {
            const checkValidation = isInvalidCardExpiration(cardExpiry)
            if (!checkValidation) {
                setCardExpirationInvalid(false)
            } else {
                setCardExpirationInvalid(true)
                setCardExpirationMessage(checkValidation)
            }
        }
    }


    const billingDetails = () => {
        return (
            <>
                <h2>Billing Details</h2>
                <Row>
                    <Col>
                        <Form.Group controlId="addressLine1">
                            <Form.Label>{addressLine1Label}</Form.Label>
                            <Form.Control maxLength={40} type="input" placeholder={addressLine1Placeholder} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="addressLine2">
                            <Form.Label>{addressLine2Label}</Form.Label>
                            <Form.Control maxLength={40} type="input" placeholder={addressLine2Placeholder} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="city">
                            <Form.Label>{cityLabel}</Form.Label>
                            <Form.Control maxLength={28} type="input" placeholder={cityPlaceholder} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="state">
                            <Form.Label>{stateLabel}</Form.Label>
                            <StateDropdown ></StateDropdown>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="zipCode">
                            <Form.Label><RequiredIcon />{zipCodeLabel}</Form.Label>
                            <Form.Control required type="input" placeholder="Enter Zip Code" maxLength={5} />
                            <Form.Control.Feedback type="invalid">{zipCodeRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    }

    const renderECheck = () => {
        return (
            <>
                <div style={{ marginBottom: '10px' }}>
                    <h2>Add eCheck Payment Method</h2>
                    <p>Please enter the required information below for this payment method.</p>
                </div>
                <Row>
                    <Form.Group controlId="nameOnAccount">
                        <Form.Label><RequiredIcon />{nameOnAccountLabel}</Form.Label>
                        <Form.Control type="input" required placeholder={nameOnAccountPlaceholder} />
                        <Form.Control.Feedback type="invalid">{nameOnAccountRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="bankAccountNumber">
                            <Form.Label><RequiredIcon />{bankAccountNumberLabel}</Form.Label>
                            <Form.Control type="input" required />
                            <Form.Control.Feedback type="invalid">{bankAccountNumberRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="confirmBankAccountNumber">
                            <Form.Label><RequiredIcon />{confirmBankAccountNumberLabel}</Form.Label>
                            <Form.Control type="input" required />
                            <Form.Control.Feedback type="invalid">{confirmBankAccountNumberRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="bankRoutingNumber">
                            <Form.Label><RequiredIcon />{bankRoutingNumberLabel}</Form.Label>
                            <Form.Control type="input" required />
                            <Form.Control.Feedback type="invalid">{bankRoutingNumberRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="confirmBankRoutingNumber">
                            <Form.Label><RequiredIcon />{confirmBankRoutingNumberLabel}</Form.Label>
                            <Form.Control type="input" required />
                            <Form.Control.Feedback type="invalid">{confirmBankRoutingNumberRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }

    const renderCreditCard = () => {
        return (
            <>
                <div style={{ marginBottom: '10px' }}>
                    <h2>Add Credit Card Payment Method</h2>
                    <p>Please enter the required information below for this payment method.</p>
                </div>
                <Row>
                    <Col>
                        <Form.Group controlId="cardType">
                            <Form.Label><RequiredIcon />{modalCardTypeLabel}</Form.Label>
                            <Form.Control as="select" required>
                                <option>American Express</option>
                                <option>American Express - Debit</option>
                                <option>Discover</option>
                                <option>Discover - Debit</option>
                                <option>Mastercard</option>
                                <option>Mastercard - Debit</option>
                                <option>Visa</option>
                                <option>Visa - Debit</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{cardTypeRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <Form.Group controlId="nameOnCard">
                            <Form.Label><RequiredIcon />{cardholderNameLabel}</Form.Label>
                            <Form.Control type="input" required maxLength={50} placeholder={cardholderNamePlaceholder} />
                            <Form.Control.Feedback type="invalid">{cardholderNameRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="cardNumber">
                            <Form.Label><RequiredIcon />{cardNumberLabel}</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type={showCardNumber ? "text" : "password"} required minLength={12} maxLength={16} className="password" />
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setShowCardNumber(!showCardNumber)}>
                                        <FontAwesomeIcon icon={showCardNumber ? faEyeSlash : faEye} size="sm" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">{cardNumberRequired}</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="expirationDate">
                            <Form.Label><RequiredIcon />{modalExpirationDateLabel}</Form.Label>
                            <InputMask id="expirationDate" name="expirationDate" required type="input" pattern="[0-9\/\s]{5}" onChange={checkCardExpiry} mask="99/99" className={cardExpirationInvalid ? "customValidation" :"form-control"} />
                            <Form.Control.Feedback type="invalid">{expirationDateRequired}.</Form.Control.Feedback>
                        </Form.Group>
                        {cardExpirationInvalid &&
                            <Form.Group controlId="expirationDateMasked">
                                <Form.Control type="input" isInvalid={cardExpirationInvalid} style={{ display: 'none' }} required={cardExpirationInvalid} />
                                <Form.Control.Feedback type="invalid"> {cardExpirationMessage}</Form.Control.Feedback>
                            </Form.Group>
                        }
                    </Col>
                    <Col>
                        <Form.Group controlId="cvv">
                            <Form.Label><RequiredIcon />{cvvLabel}</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type={showCVV ? "text" : "password"} required minLength={3} maxLength={4} className="password" />
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setShowCVV(!showCVV)}>
                                        <FontAwesomeIcon icon={showCVV ? faEyeSlash : faEye} size="sm" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">{cvvRequired}</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <>
            <Modal show={creditCardModal} onHide={() => showCreditCardModal(false)} backdrop="static">
                <Modal.Header closeButton />
                <Modal.Body>
                    {renderCreditCard()}
                    <hr />
                    {billingDetails()}
                </Modal.Body>
            </Modal>

            <Modal show={eCheckModal} onHide={() => showEcheckModal(false)} backdrop="static">
                <Modal.Header closeButton />
                <Modal.Body>
                    {renderECheck()}
                    <hr />
                    {billingDetails()}
                </Modal.Body>
            </Modal>

            <Modal show={deleteModal} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Delete payment method" />
                    <div className="confirmation-body">
                        <p>Are you sure you want to delete this payment method?</p>
                        <Form.Group className="confirmation-footer">
                            <Button variant="outline-secondary" onClick={() => setDeleteModal(false)}>
                                Cancel
                            </Button>
                            <Button onClick={() => setDeleteModal(false)}>
                                Confirm
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
            <Row>
                <Col>
                  <PerfectScrollbar>
                    <Table responsive bordered={true} className="table-payment-method">
                        <thead>
                            <tr>
                                <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{paymentTypeLabel}</th>
                                <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{cardTypeLabel}</th>
                                <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{accountNumberLabel}</th>
                                <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{expirationDateLabel}</th>
                                <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{actionsLabel}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{paymentType}</td>
                                <td>{cardType}</td>
                                <td>{accountNumber}</td>
                                <td>{expirationDate}</td>
                                <td>{actionFormatter(accountNumber, paymentType)}</td>
                            </tr>
                        </tbody>
                    </Table>
                  </PerfectScrollbar>
                </Col>
            </Row>
        </>
    )
}

export default UserPaymentMethods;