import {
    GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE,
    GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE,
    SAVE_USER_REQUEST, SAVE_USER_SUCCESS, SAVE_USER_FAILURE,SET_USER,
    CANCEL_STEP, CLEAR_USER, CLEAR_USER_REQUEST, CLEAR_USER_SUCCESS,
    DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE
} from '../actions/users';

export default function users(state = {
    isFetching: false,
    isSaving: false,
    errorMessage: '',
    users: null,
    currentPage: null,
    user: null,
    actionResult: null,
}, { type, payload }) {
   
    switch (type) {
        case GET_USERS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                users: null,
                actionResult: { type: GET_USERS_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_USERS_SUCCESS:
            return Object.assign({}, state, {
                users: payload.users,
                actionResult: { type: GET_USERS_REQUEST, result: GET_USERS_SUCCESS, token: payload.actionToken } ,
                isFetching: false
            });
        case GET_USERS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                actionResult: { type: GET_USERS_REQUEST, result: GET_USERS_FAILURE, token: payload.actionToken } ,
                isFetching: false
            });
        case GET_USER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                user: null,
                actionResult: { type: GET_USER_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_USER_SUCCESS:
            return Object.assign({}, state, {
                user: payload.user,
                isFetching: false,
                actionResult: { type: GET_USER_REQUEST, result: GET_USER_SUCCESS, token: payload.actionToken },
            });
        case GET_USER_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                isFetching: false,
                actionResult: { type: GET_USER_REQUEST, result: GET_USER_FAILURE, token: payload.actionToken },
            });
        case SAVE_USER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_USER_REQUEST, result: null, token: payload.actionToken }
            });
        case SAVE_USER_SUCCESS:
            if (true) {
                let _users = [];

                if (state.users !== null) {
                    _users = [...state.users];
                }

                let index = -1;
                if (_users.length > 0) {
                    for (let x = 0; x < _users.length; x++) {
                        if (_users[x].msbId === payload.user.msbId) {
                            index = x;
                            break;
                        }
                    }
                }

                if (index === -1) {
                    _users.push(payload.user);
                } else {
                    _users[index] = payload.user;
                }

                return Object.assign({}, state, {
                    user: payload.user,
                    users: _users,
                    isSaving: false,
                    actionResult: { type: SAVE_USER_REQUEST, result: SAVE_USER_SUCCESS, token: payload.actionToken }
                });         
            }   
        case SAVE_USER_FAILURE:
            {
                let message = payload.error.response.data.message;
                return Object.assign({}, state, {
                    errorMessage: payload.error,
                    isSaving: false,
                    actionResult: { type: SAVE_USER_REQUEST, result: SAVE_USER_FAILURE, token: payload.actionToken, message: message }
                });
            }
        case SET_USER:
            return Object.assign({}, state, {
                user: payload.user
            });
        case DELETE_USER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: DELETE_USER_REQUEST, result: null, token: payload.actionToken }
            });
        case DELETE_USER_SUCCESS:
            if (true) {
                let _users = [];

                if (state.users.length > 0) {
                    for (let x = 0; x < state.users.length; x++) {
                        if (state.users[x].msbId !== payload.user.msbId) {
                            _users.push(state.users[x]);

                        }
                    }
                }

                return Object.assign({}, state, {
                    users: _users,
                    isSaving: false,
                    actionResult: { type: DELETE_USER_REQUEST, result: DELETE_USER_SUCCESS, token: payload.actionToken }
                });         
            }   
        case DELETE_USER_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                isSaving: false,
                actionResult: { type: DELETE_USER_REQUEST, result: DELETE_USER_FAILURE, token: payload.actionToken }
            });
        case CANCEL_STEP:
            return Object.assign({}, state, {
                isFetching: false,
                user: null
            });
        case CLEAR_USER_REQUEST:
            return Object.assign({}, state, {
                isFetching: false,
                user: null,
                actionResult: { type: CLEAR_USER_REQUEST, result: CLEAR_USER_SUCCESS, token: payload.actionToken }
            });
        default:
            return state;
    }
}
