import React, { useState } from "react";
import { userActivityFormSchema } from './components/AdvancedSearchColumns';
import Report from './components/Report'
import { Redirect } from 'react-router-dom';
import { Routes } from "../../routes";
import PageSectionContainer from "../../components/layout/PageSectionContainer";
import PageHeader from "../../components/layout/PageHeader";
import { Crumb } from "../../models/Crumb";
import { Col, Container, Row } from "react-bootstrap";
import AdvancedSearch from "./components/AdvancedSearch";
import Table from "../../components/Table";
import { ReportType } from "../../models/Reports";

const ExceptionsReport = () => {
  const [redirect, setRedirect] = useState<string>("");

  const reportType = ReportType.Exception;
  const reportTitle = 'Exceptions Report';
  const transactionType = 0;


  const StatusFormatter = (cell: any, row: any) => {

    if (cell === 'Active') {
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#52C41A"></circle>
          </svg> Active
        </div>
      )
    }
    else if (cell === 'Locked') {
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#F5222D"></circle>
          </svg> Locked
        </div>
      )
    }
    else {
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#D9D9D9"></circle>
          </svg> Deleted
        </div>
      )
    }

  }

  const viewDetailsFormatter = (cell: any, row: any) => {
    return (
      <span><a onClick={() => console.log("View Details Exceptions")}>View Details</a></span>
    )
  }

  const columns = [
    {
      dataField: 'msbId',
      text: 'ID',
      editable: false,
      sort: false,
      hidden: true,
      configurable: false
  },
    {
      dataField: 'department',
      text: 'Department',
      editable: false,
      configurable: true
  }, {
    dataField: 'paymentChannel',
    text: 'Transaction date',
    editable: false,
    configurable: true
  }, {
      dataField: 'firstName',
      text: 'First name',
      editable: false,
      configurable: true
    }, {
      dataField: 'lastName',
      text: 'Last name',
      editable: false,
      configurable: true
    },
    {
      dataField: 'status',
      text: 'Status',
      editable: false,
      formatter: StatusFormatter,
      configurable: true
    },
    {
      dataField: '',
      text: 'Actions',
      editable: false,
      formatter: viewDetailsFormatter,
      configurable: false
    }
  ];
  if (redirect != "") {
    return (<Redirect push to={redirect} />)
  } else {
    return (
      <>
      {/*
      <PageSectionContainer title={`Exceptions report under construction.`} ></PageSectionContainer>
      */}
      {/*
        <Report
          reportType={reportType}
          reportTitle={reportTitle}
          transactionType={transactionType}
          columns={columns} formSchema={userActivityFormSchema} />
      */}


      
          <PageHeader title={reportTitle} crumbs={new Array<Crumb>()}/>
            <Container fluid className="container-table-search">
                <Row>
                    <Col>
                        <PageSectionContainer>
                            <AdvancedSearch formSchema={columns} transactionType={transactionType} reportType={reportType} isFetching={false} searchReady={false} />
                        </PageSectionContainer>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col>
                        <PageSectionContainer title={`Manage ${reportTitle}`}>
                        <Table
                            keyField="msbId"
                            data={[]}
                            columns={columns}
                            //rowStyle={rowStyle}
                            //defaultSorted = { defaultSorted }
                          />
                        </PageSectionContainer>
                    </Col>
                </Row>
            </Container>
      </>
    );
  }
};

export default ExceptionsReport;
