import { CellPlugin } from '@react-page/editor';
import MathewsShoppingCart, { TITLE, SUBTITLE, ACCOUNT_NUMBER_LABEL, TYPE_LABEL, ACCOUNT_YEAR_LABEL, 
    PARCEL_ID_LABEL, NAME_LABEL, ADDRESS_LABEL, MODEL_LABEL, TOTAL_AMOUNT_DUE_LABEL, CLASS_LABEL, VALUE_LABEL,
    ACTIONS_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING,  
    CART_BUTTON_BACKGROUND_COLOR, CART_BUTTON_FONT_COLOR, CART_BUTTON_FONT_SIZE, 
    CART_BUTTON_FONT_STYLE, CART_BUTTON_FONT_WEIGHT, CART_BUTTON_BORDER_COLOR, CART_ICON_COLOR,
    TICKET_NUMBER_FIRST_7, TICKET_NUMBER_LAST_4
} from './components/MathewsShoppingCart';

type Data = {
    title: string,
    subTitle: string,
    type: string,
    accountNumber: string,
    accountYear: string,
    ticketNumberFirst7: string,
    ticketNumberLast4: string,
    parcelId: string,
    name: string,
    address: string,
    model: string,
    totalAmountDue: string,
    class: string,
    value: string,
    actions: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string
}

const MathewsShoppingCartPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <MathewsShoppingCart
            title = {data.title}
            subTitle={data.subTitle}
            typeLabel={data.type}
            accountYearLabel={data.accountYear}
            accountNumberLabel={data.accountNumber}
            parcelIdLabel={data.parcelId}
            ticketNumberFirst7Label={data.ticketNumberFirst7}
            ticketNumberLast4Label={data.ticketNumberLast4}
            nameLabel={data.name}
            addressLabel={data.address}
            modelLabel={data.model}
            totalAmountDueLabel={data.totalAmountDue}
            classLabel={data.class}
            valueLabel={data.value}
            actionsLabel={data.actions}
            margin={data.margin}
            padding={data.padding}
            cartButtonBackgroundColor={data.cartButtonBackgroundColor}
            cartButtonFontColor={data.cartButtonFontColor}
            cartButtonFontSize={data.cartButtonFontSize}
            cartButtonFontStyle={data.cartButtonFontStyle}
            cartButtonFontWeight={data.cartButtonFontWeight}
            cartButtonBorderColor={data.cartButtonBorderColor}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
            cartIconColor={data.cartIconColor}
        />
    ),
    id: 'mathewsShoppingCartPlugin',
    title: 'Mathews Shopping Cart ',
    description: 'Adds items to shopping cart',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        title: {
                            type: 'string',
                            default: TITLE,
                        },
                        subTitle: {
                            type: 'string',
                            default: SUBTITLE,
                        },
                        accountNumber: {
                            type: 'string',
                            default: ACCOUNT_NUMBER_LABEL,
                        },
                        ticketNumberFirst7: {
                            type: 'string',
                            default: TICKET_NUMBER_FIRST_7,
                        },
                        ticketNumberLast4:{
                            type: 'string',
                            default: TICKET_NUMBER_LAST_4,
                        },
                        type: {
                            type: 'string',
                            default: TYPE_LABEL,
                        },
                        accountYear: {
                            type: 'string',
                            default: ACCOUNT_YEAR_LABEL,
                        },
                        parcelId: {
                            type: 'string',
                            default: PARCEL_ID_LABEL,
                        },
                        name: {
                            type: 'string',
                            default: NAME_LABEL,
                        },
                        address: {
                            type: 'string',
                            default: ADDRESS_LABEL,
                        },
                        model: {
                            type: 'string',
                            default: MODEL_LABEL,
                        },
                        totalAmountDue: {
                            type: 'string',
                            default: TOTAL_AMOUNT_DUE_LABEL,
                        },
                        class: {
                            type: 'string',
                            default: CLASS_LABEL,
                        },
                        value: {
                            type: 'string',
                            default: VALUE_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        },
                        cartButtonBackgroundColor: {
                            type: 'string',
                            default: CART_BUTTON_BACKGROUND_COLOR,
                        },
                        cartButtonFontColor: {
                            type: 'string',
                            default: CART_BUTTON_FONT_COLOR,
                        },
                        cartButtonFontSize: {
                            type: 'string',
                            default: CART_BUTTON_FONT_SIZE,
                        },
                        cartButtonFontStyle: {
                            type: 'string',
                            default: CART_BUTTON_FONT_STYLE,
                        },
                        cartButtonFontWeight: {
                            type: 'string',
                            default: CART_BUTTON_FONT_WEIGHT,
                        },
                        cartButtonBorderColor: {
                            type: 'string',
                            default: CART_BUTTON_BORDER_COLOR,
                        },
                        cartIconColor: {
                            type: 'string',
                            default: CART_ICON_COLOR
                        }
                        
                    },
                    required: [],
                },
            },
        },
    ],
};

export default MathewsShoppingCartPlugin;