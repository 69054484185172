import { CurrencyFormatter } from "../../../../../../components/Formatters";
import Table from "../../../../../../components/Table";

interface IInvoicePayment {
    itemsHeaderLabel: string,
    itemReferenceLabel: string,
    itemNameLabel: string,
    itemAmountLabel: string,
    showItemNameOption: boolean,
    fullStoryMask: boolean,
    allowLesserAmount: boolean,
    allowGreaterAmount: boolean,
    minimumAmount: string,
}

export const ITEMS_HEADER_LABEL = 'Invoice';
export const ITEM_REFERENCE_LABEL = 'Item Reference';
export const ITEM_NAME_LABEL = 'Item Name';
export const ITEM_AMOUNT_LABEL = 'Item Amount';
export const FULL_STORY_MASK = false;
export const ALLOW_LESSER_AMOUNT = false;
export const ALLOW_GREATER_AMOUNT = false;
export const MINIMUM_AMOUNT = "5.00"

const InvoicePayment = ({ itemsHeaderLabel, itemReferenceLabel, itemNameLabel, itemAmountLabel, showItemNameOption, fullStoryMask, allowLesserAmount, allowGreaterAmount, minimumAmount }: IInvoicePayment) => {

    if (!itemsHeaderLabel) itemsHeaderLabel = ITEMS_HEADER_LABEL;
    if (!itemReferenceLabel) itemReferenceLabel = ITEM_REFERENCE_LABEL;
    if (!itemNameLabel) itemNameLabel = ITEM_NAME_LABEL;
    if (!itemAmountLabel) itemAmountLabel = ITEM_AMOUNT_LABEL;
    fullStoryMask = fullStoryMask || FULL_STORY_MASK;
    allowLesserAmount = allowLesserAmount || ALLOW_LESSER_AMOUNT;
    allowGreaterAmount = allowGreaterAmount || ALLOW_GREATER_AMOUNT;
    minimumAmount = minimumAmount || MINIMUM_AMOUNT;

    const columns = [
        {
            dataField: 'reference',
            editable: false,
            text: itemReferenceLabel,
        },
        {
            dataField: 'name',
            editable: false,
            text: itemNameLabel,
        },
        {
            dataField: 'amount',
            editable: allowLesserAmount || allowGreaterAmount,
            text: itemAmountLabel,
            formatter: CurrencyFormatter,
        },
    ];

    if (!showItemNameOption) columns.splice(1, 1);

    const dummyData = [
        {
            reference: "A12345",
            name: "Item A12345",
            amount: "100.00"
        },
        {
            reference: "B12345",
            name: "Item B12345",
            amount: "101.00"
        },
        {
            reference: "C12345",
            name: "Item C12345",
            amount: "102.00"
        },
        {
            reference: "D12345",
            name: "Item D12345",
            amount: "103.00"
        },
        {
            reference: "E12345",
            name: "Item E12345",
            amount: "104.00"
        },
        {
            reference: "F12345",
            name: "Item F12345",
            amount: "105.00"
        },
        {
            reference: "G12345",
            name: "Item G12345",
            amount: "106.00"
        },
        {
            reference: "H12345",
            name: "Item H12345",
            amount: "107.00"
        },
        {
            reference: "I12345",
            name: "Item I12345",
            amount: "108.00"
        },
        {
            reference: "J12345",
            name: "Item J12345",
            amount: "109.00"
        },
        {
            reference: "K12345",
            name: "Item K12345",
            amount: "110.00"
        },
    ];

    return (
        <div className={fullStoryMask ? "fs-mask" : ""}>
            <h2 className="fw-bold">{itemsHeaderLabel}</h2>
            <Table
                keyField="InvoicePayment"
                columns={columns}
                data={dummyData}
            />                
      </div>
    )
}

export default InvoicePayment;