import { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Card, Container } from 'react-bootstrap';
import { MsbReportItem } from '../../models/Reports';
import { TransactionTypeEnum, PaymentTypeEnum } from "../../models/Payment";

import { IAppState } from '../../redux/storeTypes';
import { Crumb } from "../../models/Crumb";
import PageHeader from "../../components/layout/PageHeader";
import { Routes } from "../../routes";
import DetailsSummary from './components/reconciliations/DetailsSummary';
import DetailsByTransactionType from './components/reconciliations/DetailsByTransactionType';
import PageSectionContainer from "../../components/layout/PageSectionContainer";

export interface IReconciliationDetailsProps {
    clientName: string,
    departmentName: string,
    deptMsbReports: Array<MsbReportItem>,
}

const ReconciliationDetails = ({ clientName, departmentName, deptMsbReports }: IReconciliationDetailsProps) => {
    const [redirect, setRedirect] = useState<string>("");

    const creditCardPayments = deptMsbReports.filter((item) => item.transactionType == TransactionTypeEnum.Sale && item.paymentType == PaymentTypeEnum.CreditCardManual);
    const eCheckPayments = deptMsbReports.filter((item) => item.transactionType == TransactionTypeEnum.Sale && item.paymentType == PaymentTypeEnum.ECheck);
    const chargebacks = deptMsbReports.filter((item) => item.transactionType == TransactionTypeEnum.Chargeback);
    const chargebackReversals = deptMsbReports.filter((item) => item.transactionType == TransactionTypeEnum.ChargebackReversal);
    const refunds = deptMsbReports.filter((item) => item.transactionType == TransactionTypeEnum.Refund || item.transactionType == TransactionTypeEnum.Return);
    
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Reconciliations Report", Routes.ReconciliationReport.path));
    crumbs.push(new Crumb(`${clientName} ${departmentName} Details`, Routes.ReconciliationReportDetails.path)); 

    if (redirect != "") return <Redirect push to={redirect} />;
    
    return (
      <>
        <PageHeader title={`${clientName} ${departmentName} Details`} crumbs={crumbs} />

        <Container fluid className="container-payment-details">
          <PageSectionContainer>
            <div className="container-transaction-details">
              <div className="transactionTitle">
                <h2>{`${clientName} ${departmentName} Details`}</h2>
                <p>Below are the reconciliation details.</p>
              </div>

              <DetailsSummary
                  creditCardPayments={creditCardPayments}
                  eCheckPayments={eCheckPayments}
                  chargebacks={chargebacks}
                  chargebackReversals={chargebackReversals}
                  refunds={refunds}
              />

              <hr />

              <div className="manage-card">
                <Card className="cf-card">
                    <Card.Header>
                      <h2>Credit Card</h2>
                    </Card.Header>
                    <Card.Body>
                      <DetailsByTransactionType transactionType={TransactionTypeEnum.Sale} paymentType={PaymentTypeEnum.CreditCardManual} msbReportItems={creditCardPayments} setRedirect={setRedirect} />
                    </Card.Body>
                </Card>
              </div>

              <div className="manage-card">
                <Card className="cf-card">
                    <Card.Header>
                      <h2>eCheck</h2>
                    </Card.Header>
                    <Card.Body>
                      <DetailsByTransactionType transactionType={TransactionTypeEnum.Sale} paymentType={PaymentTypeEnum.ECheck} msbReportItems={eCheckPayments} setRedirect={setRedirect} />
                    </Card.Body>
                </Card>
              </div>

              <div className="manage-card">
                <Card className="cf-card">
                  <Card.Header>
                    <h2>Chargebacks</h2>
                  </Card.Header>
                  <Card.Body>
                    <DetailsByTransactionType transactionType={TransactionTypeEnum.Chargeback} msbReportItems={chargebacks} setRedirect={setRedirect} />
                  </Card.Body>
                </Card>
              </div>

              <div className="manage-card">
                <Card className="cf-card">
                  <Card.Header>
                    <h2>Chargeback Reversals</h2>
                  </Card.Header>
                  <Card.Body>
                    <DetailsByTransactionType transactionType={TransactionTypeEnum.ChargebackReversal} msbReportItems={chargebackReversals} setRedirect={setRedirect} />
                  </Card.Body>
                </Card>
              </div>

              <div className="manage-card">
                <Card className="cf-card">
                  <Card.Header>
                    <h2>Refunds</h2>
                  </Card.Header>
                  <Card.Body>
                    <DetailsByTransactionType transactionType={TransactionTypeEnum.Refund} msbReportItems={refunds} setRedirect={setRedirect} />
                  </Card.Body>
                </Card>
              </div>

            </div>
          </PageSectionContainer>
        </Container>
      </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        clientName: state.reports.searchState.clientName,
        departmentName: state.reports.searchState.departmentName,
        deptMsbReports: state.reports.searchState.deptMsbReports,
    };
};

export default connect(mapStateToProps)(ReconciliationDetails);