import { Col, Form, Row } from "react-bootstrap";
import RequiredIcon from "../../../components/RequiredIcon";
import { StateDropdown } from "../../../components/StateDropdown";
import InputMask from "react-input-mask";
import { PaymentTypeEnum } from "../../../models/Payment";
import { PaymentChannel } from "../../../models/Client";
import { useState } from "react";
import TextMask  from "react-text-mask";
import { isValid } from "postcode";
import _ from "lodash";
import { ProcessorTypeEnum } from "../../../models/Order";

interface IBillingDetails {
    isFetching: boolean;
    isSaving: boolean;
    isEmailRequired: boolean;
    forceZipRequired: boolean;
    overrideAvsChecks: boolean;
    paymentType: PaymentTypeEnum | undefined;
    paymentChannel: PaymentChannel | undefined;
}

const BillingDetails = ({ isFetching, isSaving, isEmailRequired, forceZipRequired, overrideAvsChecks, paymentType, paymentChannel }: IBillingDetails) => {

    const [country, setCountry] = useState<string>('United States');
    const [ukZipCode, setUkZipCode] = useState<string>('');
    const [ukZipCodeInvalid, isUkZipCodeInvalid] = useState<boolean>(false);

    const isAVSRequired = () => {
        if (paymentChannel) {
            return paymentChannel.enableAvsChecks && !overrideAvsChecks;
        }
        return false;
    }

    const isZipRequired = () => {
        if (paymentType) {
            return (paymentType === PaymentTypeEnum.CreditCardManual ? true : false) || forceZipRequired;
        }
        return false;
    }

    const checkZipCode = (e: any) => {
        const stringValue = e.target.value;
        setUkZipCode(stringValue)
        const zipCheck = !isValid(stringValue);
        isUkZipCodeInvalid(zipCheck);
    }

    const requiredFormFields = (propertyName: any) => {
        const enumMapping = {
            [PaymentTypeEnum.CreditCardTerminal]: ProcessorTypeEnum.VantivTriPos || ProcessorTypeEnum[1],
            [PaymentTypeEnum.CreditCardManual]: ProcessorTypeEnum.VantivExpress || ProcessorTypeEnum[2],
            [PaymentTypeEnum.ECheck]: ProcessorTypeEnum.ElectronicCheck || ProcessorTypeEnum[3]
        } as any

        if (paymentType) {
            const processorType = ProcessorTypeEnum[enumMapping[paymentType]]
            const isRequired = (_.find(paymentChannel?.requiredFieldDefinitions, { processorType })?.properties
                .find((property: any) => property.propertyName == propertyName)?.isRequired)
                ?? false;

            return isRequired
        }
        return false
    }

    return (
        <div className="payment-address">
            <div className="transactionTitle">
                <h2>Billing details</h2>
            </div>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="addressLine1">
                        <Form.Label><RequiredIcon visible={isAVSRequired()} />Address line 1</Form.Label>
                        <Form.Control placeholder="Enter Address line 1" disabled={isSaving} required={isAVSRequired()} />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="addressLine2">
                        <Form.Label>Address line 2</Form.Label>
                        <Form.Control type="input" placeholder="Enter Address line 2" disabled={isSaving} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="country">
                        <Form.Label><RequiredIcon visible={isAVSRequired()} />Country</Form.Label>
                        <Form.Control as="select" value={country} onChange={(e) => {setCountry(e.target.value)}} disabled={isSaving} required={isAVSRequired()}>
                            <option>United States</option>
                            <option>Mexico</option>
                            <option>Canada</option>
                            <option>United Kingdom</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="city">
                        <Form.Label><RequiredIcon visible={isAVSRequired()} />City</Form.Label>
                        <Form.Control type="input" disabled={isSaving} required={isAVSRequired()} placeholder="Enter City" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                {country !== 'United Kingdom' &&
                    <Col md={4}>
                        <Form.Group controlId="state">
                            <Form.Label><RequiredIcon visible={isAVSRequired()} />State/Province/Territory</Form.Label>
                            <StateDropdown country={country} disabled={isSaving} required={isAVSRequired()} />
                        </Form.Group>
                    </Col>
                }
                <Col md={country === 'United Kingdom' ? 4 : 2}>
                    <Form.Group controlId="zip">
                        <Form.Label><RequiredIcon visible={isZipRequired()} />Zip code</Form.Label>
                        {country === 'Canada' ?
                            <TextMask
                                name="zip"
                                required={isZipRequired()}
                                type="text"
                                pattern="^[A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$"
                                mask={[/[A-Za-z]/, /\d/, /[A-Za-z]/, ' ', /\d/, /[A-Za-z]/, /\d/]}
                                className="form-control"
                                placeholder="K1A 0T6"
                                disabled={isSaving}
                            />
                            :
                            country === 'United Kingdom' ?
                                <Form.Control type="input" className="form-control" required={isZipRequired()} isInvalid={ukZipCodeInvalid && ukZipCode!= ''} value={ukZipCode} onChange={checkZipCode}  placeholder="AA9A 9AA" maxLength={8} disabled={isSaving} />
                                :
                                <InputMask id="zip" name="zip" required={isZipRequired()} type="input" pattern="[0-9]{5}" mask="99999" className="form-control" placeholder="12345" disabled={isSaving} />
                        }
                        <Form.Control.Feedback type="invalid">{ukZipCodeInvalid ? 'Please enter a valid zip code' : 'Zip code required.'}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="phoneNumber">
                        <Form.Label><RequiredIcon visible={requiredFormFields("phoneNumber")} />Phone number</Form.Label>
                        <InputMask type="input" id="phoneNumber" required={requiredFormFields("phoneNumber")} pattern="[0-9\-\(\)\s]{14}" name="phoneNumber" className="form-control" mask="(999) 999-9999" placeholder="(123) 456-7890" disabled={!paymentType || isSaving} />
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="emailAddress">
                        <Form.Label><RequiredIcon visible={isEmailRequired || requiredFormFields("emailAddress")}/>Email address</Form.Label>
                        <Form.Control type="email" placeholder="email@address.com" required={isEmailRequired || requiredFormFields("emailAddress")} disabled={!paymentType || isSaving} />
                        <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}

export default BillingDetails;   