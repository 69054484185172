import ManageUsersForm from '../forms/ManageUsersForm';
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Col, Row, Form, Button, Dropdown, ButtonToolbar, Modal } from 'react-bootstrap';
import { IActionResult, IAppState } from '../../../../redux/storeTypes';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import { DateFormatter } from '../../../../components/Formatters';
import { Routes } from "../../../../routes";
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { sendErrorToastAction, sendSuccessToastAction, sendWarningToastAction } from '../../../../redux/actions/toast';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { User, UserTypeEnum } from '../../../../models/User';
import FormHeader from '../forms/FormHeader';
import { clearUserAction, deleteUserAction, CLEAR_USER_REQUEST, CLEAR_USER_SUCCESS, getUserAction, getUsersAction, GET_USER_FAILURE, GET_USER_REQUEST, GET_USER_SUCCESS, DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE } from '../../../../redux/actions/users';
import { getRolesAction } from '../../../../redux/actions/roles';
import { Client } from '../../../../models/Client';
import PermissionModule, { Permissions } from '../../../../components/usePermissions';
import Table from '../../../../components/Table';

interface IManageUsersSection {
    client: Client,
    users: Array<User>,
    actionResult: IActionResult
}

const ManageUsersSection = ({ users, client, actionResult }: IManageUsersSection) => {
    const [redirect, setRedirect] = useState<string>("");
    const [showForm, setShowForm] = useState(false);
    const [deleteUser, setDeleteUser] = useState<User>();
    const [showModal, setModal] = useState(false);
    const dispatch = useDispatch();
    const actionToken = 'ManageUsersSection';
    const [data, setData] = useState<Array<User>>(users);

    useEffect(() => {
        setData(users)
    }, [users]);

    useEffect(() => {
        dispatch(getUsersAction(UserTypeEnum.Client, actionToken, client.msbId));
        dispatch(getRolesAction(UserTypeEnum.Client, actionToken));
    }, [client]);

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === GET_USER_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === GET_USER_SUCCESS) {
            } else if (actionResult.result === GET_USER_FAILURE) {
            }
        } else if (actionResult && actionResult.result && actionResult.type === CLEAR_USER_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === CLEAR_USER_SUCCESS) {               
            }
        } else if (actionResult && actionResult.result && actionResult.type === DELETE_USER_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === DELETE_USER_SUCCESS) {
                dispatch(sendSuccessToastAction("User was successfully deleted."));
                setDeleteUser(undefined);
                setModal(false);
            } else if (actionResult.result === DELETE_USER_FAILURE) {
                dispatch(sendErrorToastAction("User could not be deleted."));
            }
        }
    }, [actionResult]);


    const actionsOperations = (user: User, operation: string) => {
        if (operation === "Delete") {
            setDeleteUser(user);
            setModal(true);
        } else if (operation === "Edit") {
            dispatch(getUserAction(user.msbId!, actionToken));
            setShowForm(true);
        }
    }

    const ActionsFormatter = (cell: any, user: User) => {
        return (
            <Dropdown className="req-action-dropdown">
                <Dropdown.Toggle className="approvalAction" variant="link">
                    Manage
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item onSelect={() => actionsOperations(user, "Edit")}>Edit User</Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => actionsOperations(user, "Delete")}>Delete User</Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const CellRouteFormatter = (cell: any, user: User) => {
        return (
            <a tabIndex={0} onClick={() => {
                dispatch(getUserAction(user.msbId!, actionToken));
            }}>{cell}</a>
        );
    }

    const columns = [{
        dataField: 'firstName',
        text: 'First name',
        editable: false,
        sort: true,
        formatter: CellRouteFormatter
    }, {
        dataField: 'lastName',
        text: 'Last name',
        editable: false,
        sort: true,
        formatter: CellRouteFormatter
    }, {
        dataField: 'email',
        text: 'Email address',
        editable: false
    }, {
        dataField: 'updatedAt',
        text: 'Last updated',
        formatter: DateFormatter,
        editable: false
    }, {
        dataField: 'id',
        text: 'Actions',
        editable: false,
        formatter: ActionsFormatter,
    }
    ];

    const addUserAction = () => {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => { dispatch(clearUserAction(actionToken)); setShowForm(true); }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add User
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    const deleteAction = (event: any) => {
        event.preventDefault();
        dispatch(deleteUserAction(deleteUser!, actionToken));
    }

    const SearchFilter = (props: any) => {
        let input: any;
        const handleSubmit = (event: any) => {
            event.preventDefault();
            props.onSearch(input.value);
        };
        const handleReset = () => {
            props.onSearch(input.value = '');
        }
        return (
            <>
                <div className="manage-search">
                    <Form onSubmit={handleSubmit} className="table-search">
                        <Row>
                            <Col xl={9} lg={12} md={12} sm={12}>
                                <Form.Group>
                                    <Form.Label>User</Form.Label>
                                    <Form.Control ref={(filterValue: any) => input = filterValue} type="text" placeholder="Enter User's name" />
                                </Form.Group>
                            </Col>
                            <Col xl={3} lg={12} md={12} sm={12}>
                                <ButtonToolbar>
                                    <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                                    <Button onClick={handleSubmit}>Search</Button>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </>
        );
    };


    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <ManageUsersForm showForm={showForm} setShowForm={setShowForm} />

                <Modal show={showModal}>
                    <Modal.Header>
                        <Modal.Title>Delete User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="deleteClientUser">
                            <Form.Label>Are you sure you want to delete this user?</Form.Label>
                        </Form.Group>
                        <Form.Group className="clientUserDialogButtons">
                            <Button variant="outline-secondary" onClick={() => setModal(false)}>
                                Cancel
                            </Button>
                            <Button className="clientUserDeleteConfirm" onClick={deleteAction}>
                                Confirm
                            </Button>
                        </Form.Group>
                    </Modal.Body>
                </Modal>

                <FormHeader title="Client Users" description="Please enter the client's users." />

                <div className="manage-card">
                    <ToolkitProvider keyField="id" data={data} columns={columns} search>
                        {
                            (props: any) => (
                                <PageSectionContainer title="Manage Users" toolbar={addUserAction()}>
                                    <>
                                        <SearchFilter {...props.searchProps} />

                                        <Table
                                            keyField="msbId"
                                            columns={columns}
                                            data={data?.length}
                                            {...props.baseProps}
                                        />
                                    </>
                                </PageSectionContainer>
                            )
                        }
                    </ToolkitProvider>
                </div>

                <div className="manage-footer">
                    <Button type="button" variant="link" onClick={() => setRedirect(Routes.Clients.path)} style={{ float: "right" }}>Cancel</Button>
                </div>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        users: state.users.users,
        client: state.clients.client,
        actionResult: state.users.actionResult
    };
};

export default connect(mapStateToProps)(ManageUsersSection);