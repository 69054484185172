import { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import RequiredIcon from "../../../components/RequiredIcon";
import { PaymentChannel, SupplementalField, SupplementalFieldType } from "../../../models/Client";

interface ISupplementalFields {
    paymentChannel: PaymentChannel
}

const SupplementalFields = ({ paymentChannel }: ISupplementalFields) => {
    return (
        <>
            {paymentChannel?.channelSupplementalFields?.length > 0 ?
                <div className="container-additional-details d-print-none">
                    <div className="transactionTitle">
                        <h2>Supplemental Information</h2>
                    </div>
                    <Row>
                        {paymentChannel?.channelSupplementalFields.map((field) => {
                            switch (SupplementalFieldType[field.supplementalFieldType as keyof typeof SupplementalFieldType]) {
                                case SupplementalFieldType.TextBox:
                                    return TextboxField(field);
                                case SupplementalFieldType.Date:
                                    return DateField(field)
                                case SupplementalFieldType.List:
                                    return ListField(field);
                                case SupplementalFieldType.Radio:
                                    return RadioField(field);
                                case SupplementalFieldType.Checkbox:
                                    return CheckboxField(field);
                                default:
                                    return (<></>);
                            }
                        })}
                    </Row>
                </div>
                :
                <div></div>
            }
        </>
    );
}

export default SupplementalFields;

const TextboxField = (field: SupplementalField) => {
    return (
        <Col md={3}>
            <Form.Group>
                <Form.Label>{(field.fieldIsRequired) ? <RequiredIcon /> : ''}{field.fieldLabel}</Form.Label>
                <Form.Control id={field.fieldIdentifier} required={field.fieldIsRequired} />
            </Form.Group>
        </Col>
    );
}

const DateField = (field: SupplementalField) => {
    return (
        <Col md={3}>
            <Form.Group style={{ margin: "0px", padding: "0px", alignItems: 'flex-end' }}>
                <Form.Label>{(field.fieldIsRequired) ? <RequiredIcon /> : ''}{field.fieldLabel}</Form.Label>
                <Form.Control type="date" id={field.fieldIdentifier} required={field.fieldIsRequired} />
                <Form.Control.Feedback type="invalid">Please enter a valid {field.fieldLabel}.</Form.Control.Feedback>
            </Form.Group>
        </Col>
    );
}

const ListField = (field: SupplementalField) => {
    const [selected, setSelected] = useState<any>();
    return (
        <Col md={3}>
            <Form.Group>
                <Form.Label>{(field.fieldIsRequired) ? <RequiredIcon /> : ''}{field.fieldLabel}</Form.Label>
                <Form.Control as='select' id={field.fieldIdentifier} required={field.fieldIsRequired} onChange={(e: any) => setSelected(e.target.value)} value={selected} >
                    <option value={""}>{`Select item`}</option>
                    {field.fieldProperties?.options && field.fieldProperties?.options.map((item: any, index: any) => { return (<option value={item.value} key={index}>{item.label}</option>); })}
                </Form.Control>
            </Form.Group>
        </Col>
    );
}

const CheckboxField = (field : SupplementalField) => {
    const [state, setState] = useState({});
    const [selected, setSelected] = useState(false);
    const handleChange = (e: any) => {
        e.persist();
        setState(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
    }
    useEffect(() => {
        let _selected = false;
        Object.keys(state).map(function (key, index) {
            if (state[key as keyof typeof state]) _selected = true;
        });
        if (selected != _selected) setSelected(_selected);
    }, [state]);
    return (
        <Col md={3}>
            <Form.Group style={{ margin: "0px", padding: "0px", alignItems: 'flex-end' }}>
                <Form.Label>{(field.fieldIsRequired) ? <RequiredIcon /> : ''}{field.fieldLabel}</Form.Label>
                {field.fieldProperties?.options && field.fieldProperties?.options.map((option: any, index: any) => {
                    return (
                        <Form.Check
                            id={`cmd_${field.fieldProperties.name}${option.value}`}
                            key={index}
                            type={'checkbox'}
                            inline={field.fieldProperties.inline ?? true}
                            name={option.value}
                            value={state[option.value as keyof typeof state] ? 'yes' : 'no'}
                            label={option.label}
                            onClick={handleChange}
                            required={field.fieldIsRequired && !selected} />
                    );
                })}
                <Form.Control hidden={true} required={field.fieldIsRequired && !selected} />
                <Form.Control.Feedback type="invalid">Please choose a {field.fieldLabel}.</Form.Control.Feedback>
            </Form.Group>
        </Col>
    );
}

const RadioField = (field: SupplementalField) => {
    const size = { width: '16px', height: '16px' };
    const [state, setState] = useState({});
    const [selected, setSelected] = useState(false);
    const handleChange = (e: any, name: string) => {
        e.persist();
        let _state: any = Object.assign({}, state);
        Object.keys(_state).map(function (key) {
            _state[key] = false;
        });
        _state[name] = e.target.checked;
        setState(_state);
    }
    useEffect(() => {
        let _selected = false;
        Object.keys(state).map(function (key) {
            if (state[key as keyof typeof state]) _selected = true;
        });
        if (selected != _selected) setSelected(_selected);
    }, [state]);
    return (
        <Col md={3}>
            <Form.Group style={{ margin: "0px", padding: "0px", alignItems: 'flex-end' }}>
                <Form.Label>{(field.fieldIsRequired) ? <RequiredIcon /> : ''}{field.fieldLabel}</Form.Label>
                {field.fieldProperties?.options && field.fieldProperties?.options.map((option: any, index: any) => {
                    return (
                        <Form.Check inline={field.fieldProperties.inline ?? true}>
                            <Form.Check.Input
                                id={`cmd_${field.fieldProperties.name}${option.value}`}
                                key={index}
                                type={'radio'}
                                name={field.fieldProperties.name}
                                value={state[option.value as keyof typeof state] ? 'yes' : 'no'}
                                onClick={(e: any) => handleChange(e, option.value)}
                                required={field.fieldIsRequired && !selected}
                                style={size} />
                            <Form.Check.Label>{option.label}</Form.Check.Label>
                        </Form.Check>
                    );
                })}
                <Form.Control hidden={true} required={field.fieldIsRequired && !selected} />
                <Form.Control.Feedback type="invalid">Please choose a {field.fieldLabel}.</Form.Control.Feedback>
            </Form.Group>
        </Col>
    );
}