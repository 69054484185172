import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import RequiredIcon from "../../../components/RequiredIcon";
import { BusinessTypeEnum, Client, Department, MerchantProcessor as Merchant, Terminal } from "../../../models/Client";
import { PaymentCardTypeEnum } from "../../../models/Payment";

interface ICreditCardPaymentTerminal {
    isFetching: boolean,
    isSaving: boolean,
    terminals: Array<Terminal>|undefined,
    paymentCardType: PaymentCardTypeEnum|undefined,
    selectPaymentCardType: any,
    isVisible: boolean,
    terminalId: string
}

const CreditCardPaymentTerminal = ({ isFetching, isSaving, terminals, paymentCardType, selectPaymentCardType, isVisible, terminalId }: ICreditCardPaymentTerminal) => {
    
    const renderCardTypes = () => {
        let options = [];
        options.push(<option key={1} value={PaymentCardTypeEnum.AmericanExpressCredit}>American Express</option>);
        options.push(<option key={2} value={PaymentCardTypeEnum.AmericanExpressDebit}>American Express - Debit</option>);
        options.push(<option key={3} value={PaymentCardTypeEnum.DiscoverCredit}>Discover</option>);
        options.push(<option key={4} value={PaymentCardTypeEnum.DiscoverDebit}>Discover - Debit</option>);
        options.push(<option key={5} value={PaymentCardTypeEnum.MastercardCredit}>Mastercard</option>);
        options.push(<option key={6} value={PaymentCardTypeEnum.MastercardDebit}>Mastercard - Debit</option>);
        options.push(<option key={7} value={PaymentCardTypeEnum.VisaCredit}>Visa</option>);
        options.push(<option key={8} value={PaymentCardTypeEnum.VisaDebit}>Visa - Debit</option>);

        return (
            <>
                {options}
            </>
        );
    }

    const handleOnlyNumeric = (e:any) => {
        if (e.key === " " || isNaN(e.key)) {
            e.preventDefault();
        }
    };

    if (isVisible) {
        return (
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="cardType">
                        <Form.Label><RequiredIcon />Card type</Form.Label>
                        <Form.Control as="select" required disabled={isSaving} onChange={selectPaymentCardType}>
                            <option key={0} value="" selected={paymentCardType === undefined}>Select card type</option>
                            {renderCardTypes()}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select the Card type.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="terminal">
                        <Form.Label><RequiredIcon />Terminal</Form.Label>
                        <Form.Control as="select" type="input" required disabled={isSaving} defaultValue={terminalId}>
                            {
                                terminals?.map((terminal) => (<option value={terminal.msbId} key={terminal.msbId}>{terminal.name}</option>))
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select a Terminal.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        )
    } else {
        return (<></>)
    }

}

export default CreditCardPaymentTerminal;