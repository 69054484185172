import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Form, Row, Modal } from 'react-bootstrap';

import RequiredIcon from '../../../../../components/RequiredIcon';
import { Department, Printer } from '../../../../../models/Client';
import { savePrinterAction } from '../../../../../redux/actions/clients/printers';
import FormActions, { IFormActionProps } from '../FormActions';
import FormHeader from '../FormHeader';

export interface IPrinterFormProps {
    department: Department,
    printerMsbId?: string,
    actionToken?: string,
    formActionProps?: IFormActionProps,
    postSubmit?(): void;
}

export const ViewPrinterForm = ({ department, printerMsbId, formActionProps, postSubmit }: IPrinterFormProps) => {
    let printer = (department.departmentsPrinters || []).find(printer => printerMsbId && printer.msbId === printerMsbId) || new Printer();

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (postSubmit) {
            postSubmit();
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit} className="form-details">
              <div className="details-grid">
                <strong>Printer name</strong>
                <span>{printer.name}</span>
              </div>
              <div className="details-grid">
                <strong>Printer IP address</strong>
                <span>{printer.ipAddress}</span>
              </div>
              <div className="details-grid">
                <strong>Username</strong>
                <span>{printer.userName}</span>
              </div>
              <div className="details-grid">
                <strong>Password</strong>
                <span>{printer.password}</span>
              </div>
              <FormActions {...formActionProps} />
            </Form>
        </>
    );
};

export const ManagePrinterForm = ({ department, printerMsbId, actionToken, formActionProps }: IPrinterFormProps) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);

    let printer = (department.departmentsPrinters || []).find(printer => printerMsbId && printer.msbId === printerMsbId) || new Printer();

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            let _printer: Printer = new Printer();
            if (printer !== null) {
                _printer = Object.assign({}, printer!!);
            }
            _printer.departmentMsbId = department.msbId!;
            _printer.name = form.elements.name.value;
            _printer.ipAddress = form.elements.ipAddress.value;
            _printer.userName = form.elements.userName.value;
            _printer.password = form.elements.password.value;

            dispatch(savePrinterAction(_printer, actionToken!!));
        }

        setValidated(true);
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="form-add">
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="name" >
                        <Form.Label><RequiredIcon />Printer name</Form.Label>
                        <Form.Control required type="input" maxLength={40} defaultValue={printer.name}placeholder="Enter Printer name" />
                        <Form.Control.Feedback type="invalid">Please enter the Printer's name.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="ipAddress">
                        <Form.Label><RequiredIcon />Printer IP address</Form.Label>
                        <Form.Control
                            required type="input" minLength={7} maxLength={15} defaultValue={printer.ipAddress}
                            pattern="[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}" placeholder="Enter Printer IP address"
                        />
                        <Form.Control.Feedback type="invalid">Please enter the Printer's IP address using only numbers and dots.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="userName">
                        <Form.Label><RequiredIcon />Username</Form.Label>
                        <Form.Control required type="input" maxLength={40} defaultValue={printer.userName} placeholder="Enter Username"/>
                        <Form.Control.Feedback type="invalid">Please enter the Printer's username.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group controlId="password">
                        <Form.Label><RequiredIcon />Password</Form.Label>
                        <Form.Control required type="password" maxLength={40} defaultValue={printer.password}placeholder="Enter Password" />
                        <Form.Control.Feedback type="invalid">Please enter the Printer's password .</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <FormActions {...formActionProps} />
            </Form>
        </>
    );
};
