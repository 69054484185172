import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState,IActionResult } from '../../../../redux/storeTypes';
import { Client, MerchantProcessor as Merchant } from "../../../../models/Client";
import { SAVE_MERCHANT_REQUEST, SAVE_MERCHANT_SUCCESS, SAVE_MERCHANT_FAILURE, UPDATE_MERCHANT_REQUEST, UPDATE_MERCHANT_SUCCESS, UPDATE_MERCHANT_FAILURE, getMerchantAction } from '../../../../redux/actions/clients/merchants'
import FormHeader from '../forms/FormHeader';
import MerchantDetailsForm from '../forms/merchant/MerchantDetailsForm';
import { Redirect } from 'react-router-dom';
import { Routes } from '../../../../routes'
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';

 interface IMerchantDetailsSectionProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    merchant: Merchant,
    actionResult: IActionResult
}

const MerchantDetailsSection = ({ isSaving, isFetching, client, merchant, actionResult }: IMerchantDetailsSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);

    const merchantSaveActionToken = "MerchantSave";

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_MERCHANT_REQUEST && actionResult.token === merchantSaveActionToken) {
            if (actionResult.result === SAVE_MERCHANT_SUCCESS) {
                //setRedirect(true);
                dispatch(sendSuccessToastAction("Merchant details were successfully updated."));
            } else if (actionResult.result === SAVE_MERCHANT_FAILURE) {
                dispatch(sendErrorToastAction("Merchant details could not be updated. Reason(s) : " + actionResult.message));
            }
        }
        if (actionResult && actionResult.result && actionResult.type === UPDATE_MERCHANT_REQUEST && actionResult.token === merchantSaveActionToken) {
            if (actionResult.result === UPDATE_MERCHANT_SUCCESS) {
                //setRedirect(true);
                dispatch(sendSuccessToastAction("Merchant details were successfully updated."));
                dispatch(getMerchantAction(merchant.msbId!, ""));
            } else if (actionResult.result === UPDATE_MERCHANT_FAILURE) {
                dispatch(sendErrorToastAction("Merchant details could not be updated. Reason(s) : " + actionResult.message));
                dispatch(getMerchantAction(merchant.msbId!, ""));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);
    
    if (redirect) {
        return <Redirect push to={Routes.Clients.path} />;
    } else {
    return (
        <>
            <FormHeader title="Merchant Details" description="Please enter the merchant's details." />
                <MerchantDetailsForm
                    isSaving={isSaving}
                    isFetching={isFetching}
                    client={client}
                    merchant={merchant}
                    actionToken={merchantSaveActionToken}
                    formActionProps={{
                        disabled: isSaving || isFetching,
                        showSpinner: isSaving,
                        hasSubmit: true,
                        isEditMode: merchant ? true : false,
                        //onCancel: () => setRedirect(true),
                        customProps: {
                            submitButtonDisplay: "Save"
                        }
                    }} 
                />
        </>
    );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        client: state.clients.client,
        merchant: state.clients.merchant,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(MerchantDetailsSection);