import * as React from 'react';
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Form, Row, Col, Spinner, Image, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import RequiredIcon from '../../../components/RequiredIcon';
import { IActionResult, IAppState } from '../../../redux/storeTypes';
import { sendSuccessToastAction, sendErrorToastAction } from '../../../redux/actions/toast';
import { saveCurrentUserAction, saveAvatarAction, clearActionResult, SAVE_CURRENT_USER_REQUEST, SAVE_CURRENT_USER_SUCCESS, SAVE_CURRENT_USER_FAILURE,
    SAVE_AVATAR_REQUEST, SAVE_AVATAR_SUCCESS, SAVE_AVATAR_FAILURE } from '../../../redux/actions/auth';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import '../settings.css';
import { User } from '../../../models/User';
import FormHeader from '../../clients/components/forms/FormHeader';
import { Routes } from "../../../routes";
import FormHeaderConfirmation from '../../../components/FormHeaderConfirmation';
import icoWarning from "../../../assets/img/icons/ico-warning-outline.svg";
import  Avatar  from '../../../assets/img/Avatar.png';
import { faArrowToTop } from '@fortawesome/pro-regular-svg-icons';

type IAccountBasicSettings = {
    isFetching: boolean,
    isSaving: boolean,
    currentUser: User,
    actionResult: IActionResult
}

const AccountBasicSettings = ({ isFetching, isSaving, currentUser, actionResult }:IAccountBasicSettings) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    let actionToken = "AccountBasicSettings";

    const [showModal, setShowModal] = useState(false);
    const [redirect, setRedirect] = useState<string>("");

    useEffect(() => {
    }, []);

    const handlemodal = () => {
        setRedirect(Routes.AccountSettings.path)
    }

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() !== false) {
            currentUser.firstName = form.elements.firstName.value;
            currentUser.lastName = form.elements.lastName.value;
            currentUser.title = form.elements.title.value;
            const { userRoles, ...currentUserRest } = currentUser;
            dispatch(saveCurrentUserAction(currentUserRest, actionToken));
        }
        setValidated(true);

    };

    const avatarFileChange = (event: any) => {
        let imageFile = event.target.files[0];
        dispatch(saveAvatarAction(currentUser.msbId!!, imageFile, actionToken));
    };

    const handleCancelConfirm = (event: any) => {
        event.preventDefault();
        setShowModal(false);
    };

    const imageSrc = (base64:string) => {
        if (base64) {
            if (base64.startsWith('data')) {
                return base64;
            } else {
                return `data:image/jpeg;base64, ${base64}`;
            }
        }
      };

    const renderAvatarImage = () => {
        if (currentUser.avatar) {
            return (
                <Image roundedCircle src={imageSrc(currentUser.avatar.imageBase64String)} className="user-avatar" />
            )
        } else {
            return (
                <Image roundedCircle src={Avatar} className="user-avatar" />
            )
        }
    };

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_CURRENT_USER_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_CURRENT_USER_SUCCESS) {
                dispatch(sendSuccessToastAction("User was successfully saved."));
            } else if (actionResult.result === SAVE_CURRENT_USER_FAILURE) {
                dispatch(sendErrorToastAction("User could not be saved."));
            }
            dispatch(clearActionResult());
        } else if (actionResult && actionResult.result && actionResult.type === SAVE_AVATAR_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_AVATAR_SUCCESS) {
                dispatch(sendSuccessToastAction("Avatar was successfully saved."));
            } else if (actionResult.result === SAVE_AVATAR_FAILURE) {
                dispatch(sendErrorToastAction("Avatar could not be saved."));
            }
            dispatch(clearActionResult());
        }
    }, [actionResult]);

    const renderSpinner = (isSaving:boolean) => {
        if (isSaving) {
            return(<Spinner animation="border" style={{ marginLeft: "10px" }} />);
        } else {
            return(<></>);
        }
    }

    return (
        <>
            <Modal show={showModal} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Unsaved changes" />
                        <div className="confirmation-body">
                            <p>You have unsaved changes to Basic settings. Are you sure you would like to cancel?</p>
                            <Form.Group className="confirmation-footer">
                                <Button onClick={() => setShowModal(false)} variant="outline-secondary">
                                    Cancel
                                </Button>
                                <Button onClick={(e) => handleCancelConfirm(e)}>
                                    Confirm
                                </Button>
                            </Form.Group>
                        </div>
                </Modal.Body>
            </Modal>

            <FormHeader title="Basic settings" description="Below are the basic settings for your account." />

            <Form id="userBasicSettings" noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col lg={6} md={12} sm={12}>
                        <Row>
                            <Col>
                                <Form.Group controlId="email">
                                    <Form.Label>Email address</Form.Label>
                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                        <Form.Control type="email" defaultValue={currentUser.email} disabled />
                                    </ReactPlaceholder>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="firstName">
                                    <Form.Label><RequiredIcon />First name</Form.Label>
                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                        <Form.Control maxLength={25} type="text" required defaultValue={currentUser.firstName} disabled={isSaving} />
                                    </ReactPlaceholder>
                                    <Form.Control.Feedback type="invalid">First name required</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="lastName">
                                    <Form.Label><RequiredIcon />Last name</Form.Label>
                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                        <Form.Control maxLength={25} type="text" required defaultValue={currentUser.lastName} disabled={isSaving} />
                                    </ReactPlaceholder>
                                    <Form.Control.Feedback type="invalid">Last name required</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="title">
                                    <Form.Label>Title</Form.Label>
                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                        <Form.Control maxLength={50} type="text" defaultValue={currentUser.title} disabled={isSaving}  />
                                    </ReactPlaceholder>
                                </Form.Group>
                            </Col>
                        </Row>
                        
                    </Col>
                    <Col lg={6} md={12} sm={12} title="Avatar">
                        <Form.Group controlId="avatar" className="container-avatar mb-0">
                            <Form.Label>Avatar</Form.Label>
                            {renderAvatarImage()}
                            <Form.File id="avatar" custom>
                                <Form.File.Input style={{ display: 'none' }} onChange={avatarFileChange} />
                                <Form.File.Label as="button" className="btn btn-outline-secondary">
                                    <FontAwesomeIcon icon={faArrowToTop} /> Change avatar
                                </Form.File.Label>
                            </Form.File>
                        </Form.Group>
                    </Col>
                </Row>

                <div className="manage-footer">
                    <Form.Group controlId="saveUserAction" className="form-footer">
                        <Button form="userBasicSettings" type="submit" disabled={isSaving}>
                            Save
                        </Button>
                        {renderSpinner(isSaving)}
                        <Button form='userBasicSettings' variant="link" onClick={() => setShowModal(true)}>
                            Cancel
                        </Button>
                    </Form.Group>
                </div>
            </Form >
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.auth.isFetching,
        isSaving: state.auth.isSaving,
        currentUser: state.auth.currentUser,
        actionResult: state.auth.actionResult,
    };
};

export default connect(mapStateToProps)(AccountBasicSettings);