import React from "react";

export default (props) => {
  const { show } = props;

  return (
    <div
      className={`preloader bg-soft flex-column justify-content-center align-items-center ${
        show ? "" : "show"
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="140"
        viewBox="0 0 108.8 112.3"
      >
        <path
          className="path"
          d="M91.3 4.9V84L72.6 65.4l9.3-9.3H54.4v27.6l9.2-9.3L91.3 102l5.3 5.4h7.4V4.9H91.3z"
          fill="none"
          stroke="#2C9942"
          strokeWidth="1"
          strokeDashoffset="500"
          strokeDasharray="500"
          strokeMiterlimit="10"
          strokeLinejoin="square"
          strokeLinecap="square"
        />

        <path
          className="path"
          d="M45.1 37.9L17.5 10.2l-5.4-5.3H4.7v102.5h12.8V28.3l18.6 18.6-9.3 9.2h27.6V28.6l-9.3 9.3z"
          fill="none"
          stroke="#490E67"
          strokeWidth="1"
          strokeDashoffset="500"
          strokeDasharray="500"
          strokeMiterlimit="10"
          strokeLinejoin="square"
          strokeLinecap="square"
        />
      </svg>
    </div>
  );
};
