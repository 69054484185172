
export const transformEmailData = (details: any, clientDetails: any) => {
    const departmentDetails = clientDetails?.departments?.find((departmentDetails: any) => departmentDetails?.msbId == details?.departmentId);
    const paymentChannelDetails = departmentDetails?.paymentChannels?.find((paymentChannelDetails: any) => paymentChannelDetails?.msbId == details?.paymentChannelId)
    const isClientAbsorbed =  details?.convenienceFeeIsClientAbsorbed || details?.orderSummary?.convenienceFeeIsClientAbsorbed
    
    const emailReceiptData =
    {
        clientName: clientDetails?.businessName,
        departmentName: departmentDetails?.name,
        merchantIdentifier: details?.orderPayment?.merchantIdentifier,
        orderId: details?.orderPayment?.orderId,
        orderStatus: details?.orderStatus,
        paymentChannelName: paymentChannelDetails?.name,
        processorData: {
            "address": {
                "ClientMSBId": details?.clientId,
                "addressReferenceName": "",
                "addressTypeEnum": 1,
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "zipCode": "",
                "msbId": "",
                "createdAt": "",
                "updatedAt": ""
            },
            "receipt": {
                "orderTransactionId": details?.id,
                "orderTransaction":  details,
                "orderId": details?.orderPayment?.orderId,
                "clientId": details?.clientId,
                "departmentId": details?.departmentId,
                "paymentChannelId": details?.paymentChannelId,
                "isMailOrTelephoneOrder": details?.orderPayment?.isMailOrTelephoneOrder,
                "paymentType": details?.orderPayment?.paymentType,
                "transactionType": details?.orderPayment?.transactionType,
                "amount": details?.orderPayment?.amount,
                "convenienceFee": isClientAbsorbed ? 0 : details?.orderPayment?.convenienceFee,
                "totalAmount": details?.orderPayment?.totalAmount,
                "processorType": details?.orderPayment?.processorType,
                "referenceNumber": details?.orderPayment?.referenceNumber,
                "merchantIdentifier": details?.orderPayment?.merchantIdentifier,
                "nameOnCard": details?.orderPayment?.nameOnCard,
                "lastFourOnCard": details?.orderPayment?.lastFourOnCard,
                "cardLogo": details?.orderPayment?.cardLogo,
                "hostResponseCode": details?.orderPayment?.hostResponseCode,
                "hostResponseMessage": details?.orderPayment?.hostResponseMessage,
                "expressResponseCode": details?.orderPayment?.expressResponseCode,
                "accountNumbers": details?.orderPayment?.accountNumbers,
                "transactionIdentifier": details?.orderPayment?.transactionIdentifier,
                "approvalNumber": details?.orderPayment?.approvalNumber,
                "accountHolderName": details?.orderPayment?.accountHolderName,
                "signatureDataLength": details?.orderPayment?.signatureDataLength,
                "id": details?.orderPayment?.id,
                "msbId": details?.orderPayment?.msbId,
                "createdAt": details?.orderPayment?.createdAt
            }
        },
        processorMessage: details?.processorMessage,
        processorType: details?.orderPayment?.processorType,
        responseCode: details?.orderPayment?.expressResponseCode,
        transactionIdentifier: details?.transactionIdentifier,
        transactionType: details?.transactionType,
    }
    return emailReceiptData;
}



