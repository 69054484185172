import axios from 'axios';
import { Address, Client, Department } from '../../../models/Client';
import { Dispatch } from 'react';

export const ADD_CLIENT_ADDRESS_REQUEST = 'ADD_CLIENT_ADDRESS_REQUEST';
export const ADD_CLIENT_ADDRESS_SUCCESS = 'ADD_CLIENT_ADDRESS_SUCCESS';
export const ADD_CLIENT_ADDRESS_FAILURE = 'ADD_CLIENT_ADDRESS_FAILURE';
export const SAVE_CLIENT_ADDRESS_REQUEST = 'SAVE_CLIENT_ADDRESS_REQUEST';
export const SAVE_CLIENT_ADDRESS_SUCCESS = 'SAVE_CLIENT_ADDRESS_SUCCESS';
export const SAVE_CLIENT_ADDRESS_FAILURE = 'SAVE_CLIENT_ADDRESS_FAILURE';
export const DELETE_CLIENT_ADDRESS_REQUEST = 'DELETE_CLIENT_ADDRESS_REQUEST';
export const DELETE_CLIENT_ADDRESS_SUCCESS = 'DELETE_CLIENT_ADDRESS_SUCCESS';
export const DELETE_CLIENT_ADDRESS_FAILURE = 'DELETE_CLIENT_ADDRESS_FAILURE';

export const ADD_DEPARTMENT_ADDRESS_REQUEST = 'ADD_DEPARTMENT_ADDRESS_REQUEST';
export const ADD_DEPARTMENT_ADDRESS_SUCCESS = 'ADD_DEPARTMENT_ADDRESS_SUCCESS';
export const ADD_DEPARTMENT_ADDRESS_FAILURE = 'ADD_DEPARTMENT_ADDRESS_FAILURE';
export const SAVE_DEPARTMENT_ADDRESS_REQUEST = 'SAVE_DEPARTMENT_ADDRESS_REQUEST';
export const SAVE_DEPARTMENT_ADDRESS_SUCCESS = 'SAVE_DEPARTMENT_ADDRESS_SUCCESS';
export const SAVE_DEPARTMENT_ADDRESS_FAILURE = 'SAVE_DEPARTMENT_ADDRESS_FAILURE';
export const DELETE_DEPARTMENT_ADDRESS_REQUEST = 'DELETE_DEPARTMENT_ADDRESS_REQUEST';
export const DELETE_DEPARTMENT_ADDRESS_SUCCESS = 'DELETE_DEPARTMENT_ADDRESS_SUCCESS';
export const DELETE_DEPARTMENT_ADDRESS_FAILURE = 'DELETE_DEPARTMENT_ADDRESS_FAILURE';

export const ADD_MERCHANT_ADDRESS_REQUEST = 'ADD_MERCHANT_ADDRESS_REQUEST';
export const ADD_MERCHANT_ADDRESS_SUCCESS = 'ADD_MERCHANT_ADDRESS_SUCCESS';
export const ADD_MERCHANT_ADDRESS_FAILURE = 'ADD_MERCHANT_ADDRESS_FAILURE';
export const SAVE_MERCHANT_ADDRESS_REQUEST = 'SAVE_MERCHANT_ADDRESS_REQUEST';
export const SAVE_MERCHANT_ADDRESS_SUCCESS = 'SAVE_MERCHANT_ADDRESS_SUCCESS';
export const SAVE_MERCHANT_ADDRESS_FAILURE = 'SAVE_MERCHANT_ADDRESS_FAILURE';
export const DELETE_MERCHANT_ADDRESS_REQUEST = 'DELETE_MERCHANT_ADDRESS_REQUEST';
export const DELETE_MERCHANT_ADDRESS_SUCCESS = 'DELETE_MERCHANT_ADDRESS_SUCCESS';
export const DELETE_MERCHANT_ADDRESS_FAILURE = 'DELETE_MERCHANT_ADDRESS_FAILURE';

export const addClientAddressAction = (clientMsbId: string, address: Address, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_CLIENT_ADDRESS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/ClientAddresses/addToClient?clientId=" + clientMsbId;
    let client = new Client();

    axios.post(_url, address)
        .then(function (response) {
            client = response.data;
            dispatch({
                type: ADD_CLIENT_ADDRESS_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: ADD_CLIENT_ADDRESS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
}

export const saveClientAddressAction = (address: Address, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CLIENT_ADDRESS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/ClientAddresses/" + address.msbId;

    axios.put(_url, address)
        .then(function (response) {
            address = response.data;
            dispatch({
                type: SAVE_CLIENT_ADDRESS_SUCCESS,
                payload: {
                    address,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_CLIENT_ADDRESS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
}


export const deleteClientAddressAction = (addressMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_CLIENT_ADDRESS_REQUEST,
        payload: {
            addressMsbId,
            actionToken
        }
    });

    let _url =  "/MSB_Client/api/v1/ClientAddresses/" + addressMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_CLIENT_ADDRESS_SUCCESS,
                payload: {
                    addressMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_CLIENT_ADDRESS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const addDepartmentAddressAction = (departmentMsbId: string, address: Address, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_DEPARTMENT_ADDRESS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/DepartmentAddresses/addToDepartment?departmentId=" + departmentMsbId;
    let department = new Department();

    axios.post(_url, address)
        .then(function (response) {
            department = response.data;
            dispatch({
                type: ADD_DEPARTMENT_ADDRESS_SUCCESS,
                payload: {
                    department,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: ADD_DEPARTMENT_ADDRESS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
}

export const saveDepartmentAddressAction = (address: Address, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_DEPARTMENT_ADDRESS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/DepartmentAddresses/" + address.msbId;

    axios.put(_url, address)
        .then(function (response) {
            address = response.data;
            dispatch({
                type: SAVE_DEPARTMENT_ADDRESS_SUCCESS,
                payload: {
                    address,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_DEPARTMENT_ADDRESS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
}

export const deleteDepartmentAddressAction = (addressMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_DEPARTMENT_ADDRESS_REQUEST,
        payload: {
            addressMsbId,
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/DepartmentAddresses/" + addressMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_DEPARTMENT_ADDRESS_SUCCESS,
                payload: {
                    addressMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_DEPARTMENT_ADDRESS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const addMerchantAddressAction = (address: Address, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_MERCHANT_ADDRESS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/MerchantAddresses"
    let client = new Client();

    axios.post(_url, address)
        .then(function (response) {
            client = response.data;
            dispatch({
                type: ADD_MERCHANT_ADDRESS_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: ADD_MERCHANT_ADDRESS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
}

export const saveMerchantAddressAction = (address: Address, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_MERCHANT_ADDRESS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/MerchantAddresses/" + address.msbId;

    axios.put(_url, address)
        .then(function (response) {
            address = response.data;
            dispatch({
                type: SAVE_MERCHANT_ADDRESS_SUCCESS,
                payload: {
                    address,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_MERCHANT_ADDRESS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
}

export const deleteMerchantAddressAction = (addressMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_MERCHANT_ADDRESS_REQUEST,
        payload: {
            addressMsbId,
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/MerchantAddresses/" + addressMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_MERCHANT_ADDRESS_SUCCESS,
                payload: {
                    addressMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_MERCHANT_ADDRESS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};
