import React from "react";
import { Container } from 'react-bootstrap';
import PageHeader from '../../components/layout/PageHeader';
import { ReceiptTypeEnum } from "../../models/Client";
import { Crumb } from "../../models/Crumb";
import { Routes } from "../../routes";
import Receipt from "../receipts/Receipt";

const TerminalPayReceipt = () => {
    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Terminal Pay Receipt", Routes.TerminalPayReceipt.path));
    return (
        <>
            <PageHeader title="Terminal Pay Receipt" crumbs={crumbs} />
            <Container fluid>
                <Receipt orderedPage="TerminalPay"  />
            </Container>
        </>
    )
};

export default TerminalPayReceipt; 