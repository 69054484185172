import { CellPlugin } from '@react-page/editor';
import { Card } from 'react-bootstrap';

import './../editor.css';

// use a type here, not an interface
type Data = {
  title: string,
  subtitle: string,
  headerBackgroundColor: string,
  headerPadding: string,
  headerBorderBottom: string,

  containerBackgroundColor: string,
  containerBorder: string,
  containerBorderRadius: string,
  containerMargin: string,
  containerPadding: string,
  containerBoxShadow: string,
  containerWidth: string,
  containerZIndex: string,
}


const renderTitle = (title:string) => {
  if (title && title.length > 0) {
    return (<Card.Title style={{marginBottom:"0"}}>{title}</Card.Title>);
  }
}

const renderSubtitle = (subtitle:string) => {
  if (subtitle && subtitle.length > 0) {
    return (<Card.Subtitle style={{marginTop:"0.25rem"}} className="mb-2 text-muted">{subtitle}</Card.Subtitle>);
  }
}

const HEADER_BACKGROUND_COLOR = 'rgba(255, 255, 255, 1)';
const HEADER_PADDING = '20px 24px';
const HEADER_BORDER_BOTTOM = '1px solid rgba(217, 217, 217, 1)';

const CONTAINER_BACKGROUND_COLOR = '#FFFFFF';
const CONTAINER_BORDER = '0px solid rgba(217, 217, 217, 1)';
const CONTAINER_BORDER_RADIUS = '4px';
const CONTAINER_MARGIN = '12px 12px 12px 12px';
const CONTAINER_PADDING = '0px 0px 0px 0px';
const CONTAINER_BOX_SHADOW = '0px 1px 2px rgba(31, 41, 55, 0.08)';
const CONTAINER_WIDTH = 'auto';
const CONTAINER_ZINDEX = '0';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
  return style && style !== ''?style:defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle:string) => {
  return defaultStyle as any;
}

const renderHeader = (title:string, subtitle:string, headerBackgroundColor:string, headerPadding:string, headerBorderBottom:string, containerBorderRadius:string) => {
  if ((title && title.length > 0) || (subtitle && subtitle.length > 0))  {
    return (
    <Card.Header
      style={{
          display: "block",
          height: "auto",
          padding: checkStyleForDefault(headerPadding, HEADER_PADDING),
          marginBottom: "0",
          backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR),
          borderTopLeftRadius: checkStyleForDefault(containerBorderRadius, CONTAINER_BORDER_RADIUS),
          borderTopRightRadius: checkStyleForDefault(containerBorderRadius, CONTAINER_BORDER_RADIUS),
          borderBottom: checkStyleForDefault(headerBorderBottom, HEADER_BORDER_BOTTOM)}}
      >
      {renderTitle(title)}
      {renderSubtitle(subtitle)}
    </Card.Header>
    )
  } else {
    return (<></>)
  }
}

const ReleaseCardPlugin: CellPlugin<Data> = {
  Renderer: (props) => (
    <Card
      className="cms-card"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        minWidth: "0",
        wordWrap: "break-word",
        backgroundColor: checkStyleForDefault(props.data.containerBackgroundColor, CONTAINER_BACKGROUND_COLOR),
        backgroundClip: "border-box",
        border: checkStyleForDefault(props.data.containerBorder, CONTAINER_BORDER),
        borderRadius: checkStyleForDefault(props.data.containerBorderRadius, CONTAINER_BORDER_RADIUS),
        margin: checkStyleForDefault(props.data.containerMargin, CONTAINER_MARGIN),
        padding: checkStyleForDefault(props.data.containerPadding, CONTAINER_PADDING),
        boxShadow: checkStyleForDefault(props.data.containerBoxShadow, CONTAINER_BORDER_RADIUS),
        width: checkStyleForDefault(props.data.containerWidth, CONTAINER_WIDTH),
        zIndex: checkStyleForDefault3(props.data.containerZIndex),
      }}
      >
      {renderHeader(props.data.title, props.data.subtitle, props.data.headerBackgroundColor, props.data.headerPadding, props.data.headerBorderBottom, props.data.containerBorderRadius)}
      <Card.Body>
        {props.children}
      </Card.Body>
    </Card>
  ),
  id: 'releaseCardPlugin',
  title: 'Release Card',
  description: 'A card container.',
  version: 1,
  controls: [
    {
      title: 'Labels',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            title: {
              type: 'string',
              default: '',
            },
            subtitle: {
              type: 'string',
              default: '',
            },
          },
          required: [],
        },
      },
    },
    {
      title: 'Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            containerBackgroundColor: {
              type: 'string',
              default: CONTAINER_BACKGROUND_COLOR,
            },
            containerBorder: {
              type: 'string',
              default: CONTAINER_BORDER,
            },
            containerBorderRadius: {
              type: 'string',
              default: CONTAINER_BORDER_RADIUS,
            },
            containerMargin: {
              type: 'string',
              default: CONTAINER_MARGIN,
            },
            containerPadding: {
              type: 'string',
              default: CONTAINER_PADDING,
            },
            containerBoxShadow: {
              type: 'string',
              default: CONTAINER_BOX_SHADOW,
            },
            containerWidth: {
              type: 'string',
              default: CONTAINER_WIDTH,
            },
            containerZIndex: {
              type: 'string',
              default: CONTAINER_ZINDEX,
            },
          },
          required: [],
        },
      },
    },
    {
      title: 'Header Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            headerBackgroundColor: {
              type: 'string',
              default: HEADER_BACKGROUND_COLOR,
            },
            headerPadding: {
              type: 'string',
              default: HEADER_PADDING,
            },
            headerBorderBottom: {
              type: 'string',
              default: HEADER_BORDER_BOTTOM,
            }
          },
          required: [],
        },
      },
    },
  ]
};

export default ReleaseCardPlugin;