import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
    label: string,
    tag: string,
    value: string
}

const getValue = (value: any) => {
    if (value && value.length > 0) {
        let v = value.map((line: any) => { return line.itemReferenceNumber }).join(", ");
        return v.replace(", ", "") !== "" ? v : "";
    } else {
        return "R1234567";
    }
}

const ItemReferencePropertyPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => {
        return (
            (getValue(data.value) !== "") ?
                <Row>
                    <Col><strong>{data.label}</strong></Col>
                    <Col>{getValue(data.value)}</Col>
                </Row>
                :
                <></>
        );
    },
    id: 'itemReferencePropertyPlugin',
    title: 'Item Reference Property',
    description: 'Item Reference Property.',
    version: 1,
    controls: {
        type: 'autoform',
        schema: {
            properties: {
                label: {
                    type: 'string',
                    default: 'Item Reference',
                },
                tag: {
                    type: 'string',
                    default: 'Item Reference',
                    readOnly: true
                },
            },
            required: ['label'],
        },
    },
};

export default ItemReferencePropertyPlugin;