import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import InputMask from "react-input-mask";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { useDispatch } from "react-redux";
import Select from 'react-select';
import Switch from "react-switch";
import RequiredIcon from '../../../../../components/RequiredIcon';
import { StateDropdown } from "../../../../../components/StateDropdown";
import { Address, AddressTypeEnum, BatchCloseMethodEnum, BusinessTypeEnum, Client, Contact, ContactTypeEnum, MerchantProcessor as Merchant, OwnershipTypeEnum, SubMerchantAcceptedCardTypeEnum, SubMerchantTransactionTypeEnum } from '../../../../../models/Client';
import { addMerchantAction, saveMerchantAction, updateMerchantAddressAction, updateMerchantContactAction, updateAcceptedCardTypeAction, updateMerchantSubaccountAction } from '../../../../../redux/actions/clients/merchants';
import FormActions, { IFormActionProps } from '../FormActions';
import FormHeader from "../FormHeader";

export interface IMerchantDetailsFormProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    merchant: Merchant,
    actionToken?: any,
    formActionProps?: IFormActionProps
}

const MerchantDetailsForm = ({ isSaving, isFetching, client, merchant, formActionProps, actionToken }: IMerchantDetailsFormProps) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState<boolean>(false);
    const [formAddAction, setFormAddAction] = useState(formActionProps?.customProps.submitButtonDisplay === 'Add');
    const [onboarded, setOnboarded] = useState(merchant.merchantIdentifier !== undefined && !formAddAction);

    const [batchCloseMethod, setBatchCloseMethod] = useState<BatchCloseMethodEnum>(merchant?.vantivConfiguration.batchCloseMethodType ? BatchCloseMethodEnum[merchant?.vantivConfiguration.batchCloseMethodType as keyof typeof BatchCloseMethodEnum] : BatchCloseMethodEnum.MerchantInitiated);

    const [acceptedCards, setAcceptedCards] = useState<any>([]);
    const [acceptedCardTypes, setAcceptedCardTypes] = useState<any>([]);

    const [enableCommericalCardBINQuery, setEnableCommericalCardBINQuery] = useState<boolean>(merchant?.vantivConfiguration.isCommercialCardBinQueryEnabled);

    const [subMerchantTransactionType, setSubMerchantTransactionType] = useState<SubMerchantTransactionTypeEnum>(SubMerchantTransactionTypeEnum[merchant?.vantivConfiguration.subMerchantTransactionType]);

    useEffect(() => {
        const _options = [
            { value: SubMerchantAcceptedCardTypeEnum.Visa, label: "Visa" },
            { value: SubMerchantAcceptedCardTypeEnum.MasterCard, label: "Mastercard" },
            { value: SubMerchantAcceptedCardTypeEnum.AmericanExpress, label: "American Express" },
            { value: SubMerchantAcceptedCardTypeEnum.Discover, label: "Discover" },
            { value: SubMerchantAcceptedCardTypeEnum.Debit, label: "Debit" },
        ];
        setAcceptedCards(_options);

        if (formAddAction) {
            setAcceptedCardTypes(_options);
        } else {
            if (merchant?.vantivConfiguration?.acceptedCardTypes) {
                const _selections = merchant?.vantivConfiguration?.acceptedCardTypes.filter(c => c !== SubMerchantAcceptedCardTypeEnum.None).map(c => { return _options.find(v => v.value === c) });
                setAcceptedCardTypes(_selections);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNoSpaceChange = (e: any) => {
        if (e.currentTarget.value.includes(" ")) { e.currentTarget.value = e.currentTarget.value.replace(/\s/g, ""); }
    };

    const handleNoSpaceKeyDown = (e: any) => {
        if (e.key === " ") { e.preventDefault(); }
    };

    const removeLeadingMSB = (descriptor: any) => {
        if (descriptor && descriptor.startsWith("MSB*")) {
            return descriptor.substring(4);
        } else {
            return descriptor;
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        
        if (form.checkValidity() !== false) {
            let _merchant: Merchant = Object.assign({}, merchant!);
            _merchant.businessType = form.elements.businessTypeEnum?.value;
            _merchant.vantivConfiguration.businessType = BusinessTypeEnum[form.elements.businessTypeEnum?.value] as keyof typeof BusinessTypeEnum;
            _merchant.vantivConfiguration.subMerchantTransactionType = SubMerchantTransactionTypeEnum[form.elements.subMerchantTransactionTypeEnum?.value] as keyof typeof SubMerchantTransactionTypeEnum;
            _merchant.vantivConfiguration.isCommercialCardBinQueryEnabled = enableCommericalCardBINQuery;
            _merchant.vantivConfiguration.legalBusinessName = form.elements.legalBusinessName?.value;
            _merchant.vantivConfiguration.businessWebsite = SubMerchantTransactionTypeEnum[subMerchantTransactionType] === SubMerchantTransactionTypeEnum[SubMerchantTransactionTypeEnum.OnlineStore] ? form.elements.businessWebsite?.value : undefined;
            _merchant.vantivConfiguration.federalTaxId = form.elements.federalTaxId?.value.replace(/-/g, "");
            _merchant.vantivConfiguration.dbaName = form.elements.dbaName?.value;
            _merchant.vantivConfiguration.creditCardDebitDescriptor = "MSB*" + form.elements.ccDebitDescriptor?.value;
            _merchant.vantivConfiguration.mccCode = form.elements.mccCode?.value;
            _merchant.vantivConfiguration.ownershipType = form.elements.ownershipTypeEnum?.value;
            _merchant.vantivConfiguration.batchCloseMethodType = BatchCloseMethodEnum[form.elements.batchCloseMethodEnum?.value] as keyof typeof BatchCloseMethodEnum;
            _merchant.vantivConfiguration.batchCloseTime = (form.elements.batchCloseTime?.value);
            _merchant.vantivConfiguration.acceptedCardTypes = acceptedCardTypes.map((c: any) => { return c.value });
            _merchant.merchantAddress.addressType = AddressTypeEnum[form.elements.addressTypeEnum?.value] as keyof typeof AddressTypeEnum;
            _merchant.merchantAddress.addressReferenceName = form.elements.addressReferenceName?.value;
            _merchant.merchantAddress.addressLine1 = form.elements.addressLine1?.value;
            _merchant.merchantAddress.addressLine2 = form.elements.addressLine2?.value;
            _merchant.merchantAddress.city = form.elements.city?.value;
            _merchant.merchantAddress.state = form.elements.state?.value;
            _merchant.merchantAddress.zipCode = form.elements.zipCode?.value;
            _merchant.merchantContact.contactType = ContactTypeEnum[form.elements.contactTypeEnum?.value] as keyof typeof ContactTypeEnum;
            _merchant.merchantContact.contactReferenceName = form.elements.contactReferenceName?.value;
            _merchant.merchantContact.firstName = form.elements.firstName?.value;
            _merchant.merchantContact.lastName = form.elements.lastName?.value;
            _merchant.merchantContact.phoneNumber = form.elements.phoneNumber?.value.replace(/\D/g, "");
            _merchant.merchantContact.faxNumber = form.elements.faxNumber?.value.replace(/\D/g, "");
            _merchant.merchantContact.emailAddress = form.elements.emailAddress?.value;

            delete _merchant.vantivCredential;
            delete _merchant.payPalCredential;
            delete _merchant.authorizeDotNetCredential;

            if (formAddAction) {
                dispatch(addMerchantAction(client.msbId!, _merchant, actionToken));
            } else {
                dispatch(saveMerchantAction(_merchant, actionToken));
            }
        }
        setValidated(true);
    };

    const handleUpdateAddress = (event: any) => {
        event.preventDefault();
        const form = event.target.form;

        let _merchantAddress: Address = new Address();
        _merchantAddress.addressType = AddressTypeEnum[form.elements.addressTypeEnum?.value] as keyof typeof AddressTypeEnum;
        _merchantAddress.addressReferenceName = form.elements.addressReferenceName?.value;
        _merchantAddress.addressLine1 = form.elements.addressLine1?.value;
        _merchantAddress.addressLine2 = form.elements.addressLine2?.value;
        _merchantAddress.city = form.elements.city?.value;
        _merchantAddress.state = form.elements.state?.value;
        _merchantAddress.zipCode = form.elements.zipCode?.value;

        dispatch(updateMerchantAddressAction(merchant?.msbId!, _merchantAddress, actionToken));
    };

    const handleUpdateContact = (event: any) => {
        event.preventDefault();
        const form = event.target.form;

        let _merchantContact: Contact = new Contact();
        _merchantContact.contactType = ContactTypeEnum[form.elements.contactTypeEnum?.value] as keyof typeof ContactTypeEnum;
        _merchantContact.contactReferenceName = form.elements.contactReferenceName?.value;
        _merchantContact.firstName = form.elements.firstName?.value;
        _merchantContact.lastName = form.elements.lastName?.value;
        _merchantContact.phoneNumber = form.elements.phoneNumber?.value.replace(/\D/g, "");
        _merchantContact.faxNumber = form.elements.faxNumber?.value.replace(/\D/g, "");
        _merchantContact.emailAddress = form.elements.emailAddress?.value;

        dispatch(updateMerchantContactAction(merchant?.msbId!, _merchantContact, actionToken));
    };

    const handleUpdateAcceptedCardTypes = () => {
        let _acceptedCardTypes: Array<SubMerchantAcceptedCardTypeEnum> = new Array<SubMerchantAcceptedCardTypeEnum>();
        _acceptedCardTypes = acceptedCardTypes.map((c: any) => { return c.value });

        dispatch(updateAcceptedCardTypeAction(merchant?.msbId!, _acceptedCardTypes, actionToken));
    };      

    const handleUpdateSubaccount = (event: any) => {
        event.preventDefault();
        const form = event.target.form;

        let _subaccount: any = {
            //"expressGatewayId": 0,
            "batchCloseMethod": BatchCloseMethodEnum[form.elements.batchCloseMethodEnum?.value] as keyof typeof BatchCloseMethodEnum,
            "batchCloseTime": (form.elements.batchCloseTime?.value),
            "checkForDuplicateTransactions": "Yes",
            "enableCommercialCardBINQuery": enableCommericalCardBINQuery ? "Yes" : "No"
        };

        dispatch(updateMerchantSubaccountAction(merchant?.msbId!, _subaccount, actionToken));
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="legalBusinessName">
                        <Form.Label><RequiredIcon />Legal business name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" disabled={isSaving || onboarded} maxLength={40} defaultValue={merchant?.vantivConfiguration.legalBusinessName} placeholder="Enter Legal name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a Legal business name.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="businessTypeEnum">
                        <Form.Label><RequiredIcon />Business type</Form.Label>
                        <Form.Control required as="select" disabled={isSaving || onboarded} defaultValue={merchant.businessType} >
                            <option value={BusinessTypeEnum.Retail}>Retail</option>
                            <option value={BusinessTypeEnum.ECommerce}>ECommerce</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Business type required.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="subMerchantTransactionTypeEnum">
                        <Form.Label><RequiredIcon />Transaction type</Form.Label>
                        <Form.Control required as="select" disabled={isSaving || onboarded} defaultValue={merchant?.vantivConfiguration.subMerchantTransactionType} onChange={e => setSubMerchantTransactionType(e.target.value as unknown as SubMerchantTransactionTypeEnum) }>
                            <option value={SubMerchantTransactionTypeEnum.NoOnlineStore}>No Online Store</option>
                            <option value={SubMerchantTransactionTypeEnum.OnlineStore}>Online Store</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Transaction type required.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                {SubMerchantTransactionTypeEnum[subMerchantTransactionType] === SubMerchantTransactionTypeEnum[SubMerchantTransactionTypeEnum.OnlineStore] &&
                    <Col lg={6} sm={12}>
                        <Form.Group controlId="businessWebsite">
                            <Form.Label><RequiredIcon />Business website</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control type="input" required maxLength={99} defaultValue={merchant?.vantivConfiguration.businessWebsite} disabled={isSaving || onboarded} placeholder="www.website.com" />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a Business website.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>}
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="ccDebitDescriptor">
                        <Form.Label><RequiredIcon />Credit card debit descriptor</Form.Label>
                        <InputGroup>
                            <InputGroup.Text>
                                MSB*
                            </InputGroup.Text>
                            <Form.Control required type="input" disabled={isSaving || onboarded} maxLength={21} onKeyDown={handleNoSpaceKeyDown} onChange={handleNoSpaceChange} defaultValue={removeLeadingMSB(merchant?.vantivConfiguration.creditCardDebitDescriptor)} />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">Credit card debit descriptor required.</Form.Control.Feedback>
                        <span className="helper-text">Maximum of 21 characters</span>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="dbaName">
                        <Form.Label><RequiredIcon />DBA name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" disabled={isSaving || onboarded} maxLength={40} defaultValue={merchant?.vantivConfiguration.dbaName} placeholder="Enter DBA name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a DBA name.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="mccCode">
                        <Form.Label><RequiredIcon />MCC Code</Form.Label>
                        <Form.Control required as="select" defaultValue={merchant?.vantivConfiguration.mccCode} disabled={onboarded}>
                            <option value="9399">9399 - Government Services not elsewhere classified</option>
                            <option value="9311">9311 - Tax Payments</option>
                            <option value="9211">9211 - Court Costs including Alimony and Child Support</option>
                            <option value="9222">9222 - Fines</option>
                            <option value="9223">9223 - Bail and Bond Payments</option>
                            <option value="8099">8099 - Health Practitioners, Medical Services not elsewhere classified</option>
                            <option value="8062">8062 - Hospitals</option>
                            <option value="4900">4900 - Utilities - Electric, Gas, Heating Oil, Sanitary, Water</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">MCC Code required.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
              <Col lg={6} sm={12}>
                    <Form.Group controlId="federalTaxId">
                        <Form.Label><RequiredIcon />Federal tax ID</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <InputMask required type="input" mask="99-9999999" className="form-control fs-mask" id="federalTaxId" defaultValue={merchant?.vantivConfiguration.federalTaxId} disabled={isSaving || onboarded} placeholder="99-9999999" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a valid Federal tax ID.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="ownershipTypeEnum">
                        <Form.Label><RequiredIcon />Ownership type</Form.Label>
                        <Form.Control required as="select" disabled={isSaving || onboarded} defaultValue={OwnershipTypeEnum[merchant?.vantivConfiguration.ownershipType as keyof typeof OwnershipTypeEnum]}>
                            <option value={OwnershipTypeEnum.Government}>Government</option>
                            <option value={OwnershipTypeEnum.PublicCorporation}>Public Corporation</option>
                            <option value={OwnershipTypeEnum.PrivateCorporation}>Private Corporation</option>
                            <option value={OwnershipTypeEnum.SECRegulatedCorporation}>SEC Regulated Corporation</option>
                            <option value={OwnershipTypeEnum.SoleProprietorship}>Sole Proprietorship</option>
                            <option value={OwnershipTypeEnum.LLC}>LLC</option>
                            <option value={OwnershipTypeEnum.Partnership}>Partnership</option>
                            <option value={OwnershipTypeEnum.NonProfit}>Non-Profit</option>
                            <option value={OwnershipTypeEnum.FinancialInstitution}>Financial Institution</option>
                            <option value={OwnershipTypeEnum.Trust}>Trust</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select an Ownership type.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <hr />
            <FormHeader title="Merchant Subaccount" description="Please enter the merchant's subaccount info." />
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="batchCloseMethodEnum">
                        <Form.Label><RequiredIcon />Batch close method</Form.Label>
                        <Form.Control required as="select" defaultValue={BatchCloseMethodEnum[merchant?.vantivConfiguration.batchCloseMethodType as keyof typeof BatchCloseMethodEnum]} disabled={isSaving} onChange={e => setBatchCloseMethod(e.target.value as unknown as BatchCloseMethodEnum)} >
                            <option value={BatchCloseMethodEnum.MerchantInitiated}>Merchant Initiated</option>
                            <option value={BatchCloseMethodEnum.TimeInitiated}>Time Initiated</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Batch close method required.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="batchCloseTime">
                        <Form.Label><RequiredIcon />Batch close time</Form.Label>
                        <Form.Control required type="time" disabled={isSaving} readOnly={(batchCloseMethod === BatchCloseMethodEnum.MerchantInitiated)} defaultValue={merchant?.vantivConfiguration.batchCloseTime} step={1} />
                        <Form.Control.Feedback type="invalid">Batch close time required.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            {onboarded &&
                <Row>
                    <Col>
                        <ReactPlaceholder type='text' rows={1} ready={true} delay={200} showLoadingAnimation={true}>
                            <div className="toggle-switch">
                                <Switch
                                    disabled={isSaving}
                                    onChange={(value) => setEnableCommericalCardBINQuery(value)}
                                    checked={enableCommericalCardBINQuery}
                                    onColor={'#0057B6'}
                                    offColor={'#BEBEBE'}
                                    handleDiameter={12}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={16}
                                    width={28}
                                    activeBoxShadow={'0 0 0 1px #0057B6'}
                                />
                                <span className="toggle-label">Enable Commerical Card BINQuery</span>
                            </div>
                        </ReactPlaceholder>
                    </Col>
                </Row>}
            {onboarded && <Button type="button" onClick={handleUpdateSubaccount} style={{ width: '250px', marginTop: '5px'}}>Update Subaccount</Button>}
            <hr />
            <FormHeader title="Merchant Accepted Card Types" description="Please enter the merchant's accepted card types." />
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="acceptedCardTypes">
                        <Form.Label><RequiredIcon />Accepted card types</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                            <Select
                                required
                                disabled={isSaving}
                                isMulti
                                closeMenuOnSelect={false}
                                value={acceptedCardTypes}
                                onChange={(value: any) => setAcceptedCardTypes(value)}
                                options={acceptedCards}
                                className="react-select-container"
                                classNamePrefix="react-select"
                            />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Accepted card types required.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            {onboarded && <Button type="button" onClick={handleUpdateAcceptedCardTypes} style={{ width: '250px' }}>Update Accepted Card Types</Button>}
            <hr />
            <FormHeader title="Merchant Addresses" description="Please enter the merchant's address." />
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="addressReferenceName">
                        <Form.Label><RequiredIcon />Address reference name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={40} defaultValue={merchant?.merchantAddress?.addressReferenceName} placeholder="Enter Address reference name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter an Address reference name.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="addressTypeEnum">
                        <Form.Label><RequiredIcon />Address type</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required as="select" defaultValue={AddressTypeEnum[merchant?.merchantAddress?.addressType as keyof typeof AddressTypeEnum]} disabled={true}>
                                <option value={AddressTypeEnum.Physical}>Physical</option>
                                <option value={AddressTypeEnum.Mailing}>Mailing</option>
                                <option value={AddressTypeEnum.TerminalDeployment}>Terminal Deployment</option>
                            </Form.Control>
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select an Address type.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="addressLine1">
                        <Form.Label><RequiredIcon />Address line 1</Form.Label>
                        <Form.Control required type="input" maxLength={40} defaultValue={merchant?.merchantAddress?.addressLine1} placeholder="Enter Address line 1" />
                        <Form.Control.Feedback type="invalid">Please enter an Address.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="addressLine2">
                        <Form.Label>Address line 2</Form.Label>
                        <Form.Control type="input" maxLength={40} defaultValue={merchant?.merchantAddress?.addressLine2} placeholder="Enter Address line 2" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Form.Group controlId="city">
                        <Form.Label><RequiredIcon />City</Form.Label>
                        <Form.Control required type="input" maxLength={28} defaultValue={merchant?.merchantAddress?.city} placeholder="Enter City" />
                        <Form.Control.Feedback type="invalid">Please enter a City.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={4}>
                    <Form.Group controlId="state">
                        <Form.Label><RequiredIcon />State</Form.Label>
                        <StateDropdown defaultValue={merchant?.merchantAddress?.state} required></StateDropdown>
                        <Form.Control.Feedback type="invalid">Please select a State.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={2}>
                    <Form.Group controlId="zipCode">
                        <Form.Label><RequiredIcon />Zip code</Form.Label>
                        <InputMask id="zipCode" name="zipCode" required type="input" pattern="[0-9]{5}" mask="99999" className="form-control" defaultValue={merchant?.merchantAddress?.zipCode} placeholder="Enter Zip code" />
                        <Form.Control.Feedback type="invalid">Please enter a Zip code.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            {onboarded && <Button type="button" onClick={handleUpdateAddress} style={{ width: '250px' }}>Update Address</Button>}
            <hr />
            <FormHeader title="Merchant Contacts" description="Please enter the merchant's contact." />
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="contactReferenceName">
                        <Form.Label><RequiredIcon />Contact reference name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={40} defaultValue={merchant?.merchantContact?.contactReferenceName} placeholder="Enter Contact reference name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a Contact reference name.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="contactTypeEnum">
                        <Form.Label><RequiredIcon />Contact type</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required as="select" defaultValue={ContactTypeEnum[merchant?.merchantContact?.contactType as keyof typeof ContactTypeEnum]} disabled={true}>
                                <option value={ContactTypeEnum.CustomerService}>Customer Service</option>
                                <option value={ContactTypeEnum.TerminalDeployment}>Terminal Deployment</option>
                                <option value={ContactTypeEnum.Primary}>Primary</option>
                            </Form.Control>
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select a Contact type.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="firstName">
                        <Form.Label><RequiredIcon />First name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={40} defaultValue={merchant?.merchantContact?.firstName} placeholder="Enter First name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a First name.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="lastName">
                        <Form.Label><RequiredIcon />Last name</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" maxLength={40} defaultValue={merchant?.merchantContact?.lastName} placeholder="Enter Last name" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a Last name.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="phoneNumber">
                        <Form.Label><RequiredIcon />Phone number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <InputMask required type="input" id="phoneNumber" pattern="[0-9\-\(\)\s]{14}" name="phoneNumber" className="form-control" mask="(999) 999-9999" defaultValue={merchant?.merchantContact?.phoneNumber} placeholder="(999) 999-9999" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a Phone number.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="faxNumber">
                        <Form.Label>Fax number</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <InputMask id="faxNumber" name="faxNumber" className="form-control" type="input" pattern="[0-9\-\(\)\s]{14}" mask="(999) 999-9999" defaultValue={merchant?.merchantContact?.faxNumber} placeholder="(999) 999-9999" />
                        </ReactPlaceholder>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="emailAddress">
                        <Form.Label><RequiredIcon />Email address</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="email" placeholder="email@address.com" maxLength={100} defaultValue={merchant?.merchantContact?.emailAddress} />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter an Email address.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            {onboarded && <Button type="button" onClick={handleUpdateContact} style={{ width: '250px', marginBottom: '30px', marginTop: '20px' }}>Update Contact</Button>}
            {!onboarded && < div className="manage-footer">
                <FormActions
                    disabled={isSaving || isFetching}
                    showSpinner={isSaving}
                    {...formActionProps} />
            </div>}
        </Form>
    );
};

export default MerchantDetailsForm;