import React from "react";
import PageHeader from '../../../../components/layout/PageHeader';
import UserTabsContainer from './UserTabsContainer';
import { IAppState } from '../../../../redux/storeTypes';
import { connect } from "react-redux";
import { User } from "../../../../models/User";
import { Crumb } from "../../../../models/Crumb";
import { Routes } from "../../../../routes";

interface IEditUser {
    user: User;
}

const EditUser = ({ user }: IEditUser) => {
    if (user) {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Client Users", Routes.ClientUsers.path));
        crumbs.push(new Crumb(`${user.firstName} ${user.lastName}`, Routes.EditClientUser.path));

        return (
            <>
                <PageHeader title={`${user.firstName} ${user.lastName}`} crumbs={crumbs} />
                <UserTabsContainer {...user} />
            </>
        );
    }
    else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Client Users", Routes.ClientUsers.path));
        crumbs.push(new Crumb("Edit User", Routes.EditClientUser.path));
        return (
            <>
                <PageHeader title={"Edit User"} crumbs={crumbs} />
                <UserTabsContainer />
            </>
        );

    };
};

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.users.user
    };
};

export default connect(mapStateToProps)(EditUser);
