import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Nav, Row, Form, Button, Tab, Modal } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import UserDetails from './clientUserTabs/UserDetails';
import UserRoles from './clientUserTabs/UserRole';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import { IAppState, IActionResult } from '../../../../redux/storeTypes';
import { Routes } from "../../../../routes";
import {
    saveUserAction, SAVE_USER_REQUEST, SAVE_USER_SUCCESS, SAVE_USER_FAILURE,
} from '../../../../redux/actions/users';
import { sendSuccessToastAction, sendErrorToastAction, sendWarningToastAction } from '../../../../redux/actions/toast';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import { Redirect } from 'react-router-dom';
import { User, UserClient,UserDepartment, UserTypeEnum } from '../../../../models/User';
import { Client } from '../../../../models/Client';

interface IUserTabsContainer {
    isFetching: boolean;
    client: Client;
    isSaving: boolean;
    user: User;
    actionResult: IActionResult;
}

const UserTabsContainer = ({ isFetching, isSaving, actionResult, client, user }: IUserTabsContainer) => {
    const [redirect, setRedirect] = useState<string>("");
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);

    const [showModal, setModal] = useState(false);
    let actionToken = "ClientUserTabsContainer";

    const ref = useRef(null);
  
    const scroll = (scrollOffset: any) => {
      const node: any = ref.current!;
      node.scrollLeft += scrollOffset;
    };

    const handlemodal = () => {
        setRedirect(Routes.ClientUsers.path)
    }

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_USER_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_USER_SUCCESS) {
                dispatch(sendSuccessToastAction("User was successfully saved."));
                setRedirect(Routes.ClientUsers.path);
            } else if (actionResult.result === SAVE_USER_FAILURE) {
                dispatch(sendErrorToastAction("User could not be saved. Reason(s) : " + actionResult.message));
            }
        }

    }, [actionResult]);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() !== false) {
            if (user === null) {
                user = new User();
            }
            user.userTypeEnum = UserTypeEnum.Client;
            user.email = form.elements.emailAddress.value;
            user.firstName = form.elements.firstName.value;
            user.lastName = form.elements.lastName.value;
            user.phoneNumber = form.elements.phoneNumber.value;

            user.userClients = [];
            let clientId = client.msbId as any;
            user.userClients.push(new UserClient(clientId));

            user.userDepartments = [];

            let selectedDepartments = form.elements.selectedDepartments.value as string;
            let departmentIds = selectedDepartments.split(",") as Array<string>;
            departmentIds.forEach(x =>  user.userDepartments.push(new UserDepartment(x)));
            const { updatedAt,createdAt, ...modifiedUser } = user;

            dispatch(saveUserAction(modifiedUser, actionToken));
        }
        else {
            dispatch(sendErrorToastAction("Please Check User Details"));
        }
        setValidated(true);
    };

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <Container fluid className="manage-container">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Form id='clientUserSubmit' noValidate validated={validated} onSubmit={handleSubmit} className="tab-form">
                                    <Tab.Container id="clientUsers" defaultActiveKey="clientUsers">
                                      
                                        {/* BEGIN: Tabs */}
                                        <div className="tab-nav d-flex px-0">
                                          <Button variant="link" onClick={() => scroll(-300)} className="btn-scroll-left">
                                            <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                                          </Button>
                                          <div className="scrollbarContainer" ref={ref}>
                                            <Nav className="flex-row flex-nowrap">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="clientUsers">User Details</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="clientUserRole">Roles</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                          </div>
                                          <Button variant="link" onClick={() => scroll(300)} className="btn-scroll-right">
                                            <FontAwesomeIcon icon={faChevronRight} size="xs" />
                                          </Button>
                                        </div>
                                        {/* END: Tabs */}

                                        <Tab.Content>
                                            <Tab.Pane eventKey="clientUsers">
                                                <UserDetails />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="clientUserRole">
                                                <UserRoles {...user}/>
                                            </Tab.Pane>
                                            <div className="manage-footer">
                                                <Form.Group controlId="saveUserAction" className="form-footer mb-0">
                                                    <Button form='clientUserSubmit' type="submit" disabled={isSaving}>
                                                        Save
                                                    </Button>
                                                    <Button form='clientUserSubmit' className="navientUserFooterCancel" variant="link" onClick={handlemodal}>
                                                        Cancel
                                                    </Button>
                                                </Form.Group>
                                            </div>
                                        </Tab.Content>

                                    </Tab.Container>
                                </Form>
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>

                <Modal show={showModal}>
                    <Modal.Dialog className="internalUserDialog">
                        <Modal.Body>
                            <Form.Group controlId="deleteClientUser">
                                <Form.Label>Cancel user changes</Form.Label>
                            </Form.Group>
                            <Form.Group controlId="deleteClientUser">
                                <Form.Label>Are you sure you want to cancel the changes to this user?</Form.Label>
                            </Form.Group>
                            <Form.Group className="clientUserDialogButtons">
                                <Button variant="outline-secondary" onClick={() => setModal(false)}>
                                    Cancel
                                </Button>
                                <Button className="clientUserDeleteConfirm"
                                    onClick={() => { setRedirect(Routes.ClientUsers.path) }}
                                >
                                    Confirm
                                </Button>
                            </Form.Group>
                        </Modal.Body>
                    </Modal.Dialog>
                </Modal>

            </>
        )
    }
}


const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.users.isFetching,
        isSaving: state.users.isSaving,
        client: state.clients.client,
        user: state.users.user,
        actionResult: state.users.actionResult,
    };
};

export default connect(mapStateToProps)(UserTabsContainer);