import React from "react";
import moment from "moment-timezone";
import { Row, Col } from "react-bootstrap";

export default (props) => {
  const currentYear = moment().get("year");

  return (
    <footer className="footer section py-5 d-print-none">
      <Row>
        <Col lg={12}>
          <p className="mb-0 text-center">
            Copyright &copy; {`${currentYear} `} MSB Payment System
          </p>
        </Col>
      </Row>
    </footer>
  );
};
