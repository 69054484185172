import { Row, Col } from "react-bootstrap"
import { useEffect, useState } from "react";
import Table from '../../../../../../components/Table';

interface IMultiSearchResults {
    taxTypeLabel: string,
    hideTaxType: boolean,
    accountNumberLabel: string,
    ownerLabel: string,
    paymentAmountLabel: string,
    balanceDueLabel: string,
    staticPaymentAmount: boolean,
    isSchoolTaxPartial: boolean,
    isTaxPartial: boolean,
    isPerCapitaTaxPartial: boolean,
    statusLabel: string,
    actionsLabel: string,
    margin: string,
    padding: string,
    headerBackgroundColor : string,
    headerPadding: string,
}

export const TAX_TYPE_LABEL = 'Tax type';
export const HIDE_TAX_TYPE = true;
export const ACCOUNT_NUMBER_LABEL = 'Account number';
export const OWNER_LABEL = 'Owner';
export const BALANCE_DUE_LABEL = 'Balance Due';
export const PAYMENT_AMOUNT_LABEL = 'Payment Amount';
export const STATUS_LABEL = 'Status';
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const MultiSearchResults = ({ taxTypeLabel, hideTaxType, accountNumberLabel, ownerLabel, balanceDueLabel, statusLabel, actionsLabel, 
    margin, padding, headerBackgroundColor ,headerPadding, paymentAmountLabel }: IMultiSearchResults) => {


    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!ownerLabel) { ownerLabel = OWNER_LABEL }
    if (!balanceDueLabel) { balanceDueLabel = BALANCE_DUE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!margin) { margin = MARGIN }
    if (!paymentAmountLabel) { paymentAmountLabel = PAYMENT_AMOUNT_LABEL }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }
    
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const initalColumns = [{
        dataField: 'taxType',
        text: taxTypeLabel,
        hidden: true,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'accountNumber',
        text: accountNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'owner',
        text: ownerLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
     
    }, {
        dataField: 'balanceDue',
        text: balanceDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,

    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
     
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const [columns, setColumns] = useState<any>(initalColumns);
   

    useEffect(() => {
        const newColumns = columns.map((column:any) =>{
            if(column.dataField !== 'taxType') return column;
            return{
                ...column,
                hidden: hideTaxType
            }
        })
        setColumns(newColumns)
    }, [hideTaxType]);

    const renderTable = () => {
            return (
                <Table
                    keyField="msbId"
                    data={[]}
                    columns={columns}
                />);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </div>

    )
}

export default MultiSearchResults;