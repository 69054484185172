import React, { useState, useEffect, Component } from "react";
import { Col, Row, Form } from 'react-bootstrap';
import RequiredIcon from '../../../../../components/RequiredIcon';
import { Client, Department } from '../../../../../models/Client';
import { addDepartmentAction, saveDepartmentAction } from '../../../../../redux/actions/clients/departments';
import { useDispatch } from "react-redux";
import FormActions, { IFormActionProps } from '../FormActions';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

export interface IDepartmentDetailsFormProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    department: Department,
    actionToken?: any,
    formActionProps?: IFormActionProps
}

const DepartmentDetailsForm = ({ isSaving, isFetching, client, department, formActionProps,actionToken }: IDepartmentDetailsFormProps) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState<boolean>(false);

    if (!department) {
        department = new Department();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        
        if (form.checkValidity() !== false) {
            let _department = Object.assign({}, department);
            _department.name = form.elements.departmentName.value;
            _department.legalBusinessName = form.elements.legalName.value;
            _department.businessWebsite = form.elements.businessWebsite.value;

            delete _department.onBoardingStatus;
            delete _department.clientBankAccounts;
            delete _department.convenienceFees;
            delete _department.departmentAddresses;
            delete _department.departmentContacts;
            delete _department.departmentsPrinters;
            delete _department.paymentChannels;
            delete _department.receipts;

            if (formActionProps?.customProps.submitButtonDisplay == 'Add') {
                dispatch(addDepartmentAction(client.msbId!, _department, actionToken));
            } else {
                dispatch(saveDepartmentAction(_department, actionToken));
            }
        }
        setValidated(true);
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} sm={12}>
                <Form.Group controlId="departmentName">
                    <Form.Label><RequiredIcon />Department name</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                        <Form.Control required type="input" disabled={isSaving} maxLength={40} defaultValue={department?.name} placeholder="Enter Department name" />
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please enter a Department name.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group controlId="legalName">
                    <Form.Label><RequiredIcon />Legal business name</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control required type="input" disabled={isSaving} maxLength={40} defaultValue={department?.legalBusinessName} placeholder="Enter Legal name" />
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please enter a Legal name.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="businessWebsite">
                        <Form.Label><RequiredIcon />Business website</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control type="input" required maxLength={99} defaultValue={department?.businessWebsite} disabled={isSaving} placeholder="www.website.com" />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please enter a Business website.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    {/*
                    <Form.Group controlId="businessEstablishedDate">
                        <Form.Label><RequiredIcon />Business established date</Form.Label>
                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                            <Form.Control type="date" required defaultValue={getDate(client?.businessEstablishedDate)} disabled={isSaving} />
                        </ReactPlaceholder>
                        <Form.Control.Feedback type="invalid">Please select a Business established date.</Form.Control.Feedback>
                    </Form.Group>
                    */}
                </Col>
            </Row>
            {/*
            <Row>
              <Col lg={6} sm={12}>
                <Form.Group controlId="dbaName">
                    <Form.Label><RequiredIcon />DBA name</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                        <Form.Control required type="input" disabled={isSaving} maxLength={40} defaultValue={department?.dbaName} placeholder="Enter DBA name" />
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please enter a DBA name.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group controlId="chainCode">
                    <Form.Label>Chain code</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                        <Form.Control required type="input" maxLength={6} defaultValue={gilaSettings?.chainCode} disabled={true}/>
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please enter a Chain code.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} sm={12}>
                <Form.Group controlId="federalTaxId">
                    <Form.Label><RequiredIcon visible={!useClientFederalTaxId}/>Federal tax ID</Form.Label>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                        {useClientFederalTaxId?
                        <Form.Control type="input" readOnly value={client?.federalTaxId}></Form.Control>
                        :
                        <InputMask
                            disabled={isSaving} 
                            required
                            type="input"
                            pattern="[0-9\-]{10}"
                            mask="99-9999999"
                            id="federalTaxId"
                            name="federalTaxId"
                            className="form-control"
                            value={federalTaxId}
                            onChange={(event) => setFederalTaxId(event.target.value?.replace(/-/g, '')) }
                            placeholder="99-9999999"
                        />
                        }
                    </ReactPlaceholder>
                    <Form.Control.Feedback type="invalid">Please enter a valid Federal tax ID.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                    <div className="toggle-switch">
                        <Switch 
                            disabled={isSaving} 
                            onChange={handleUseClientFederalTaxIdOnChange} 
                            checked={useClientFederalTaxId} 
                            onColor={'#0057B6'}
                            offColor={'#BEBEBE'}
                            handleDiameter={12}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={16}
                            width={28}
                            activeBoxShadow={'0 0 0 1px #0057B6'}
                        />
                        <span className="toggle-label">Use the same Client Federal tax ID for the Department Federal tax ID</span>
                    </div>
                    </ReactPlaceholder>
                </Col>
            </Row>
            */}
            <div className="manage-footer">
              <FormActions
                  disabled={isSaving || isFetching}
                  showSpinner={isSaving}
                  {...formActionProps} />
            </div>
        </Form>
    );
};

export default DepartmentDetailsForm;