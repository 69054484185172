import { CellPlugin } from '@react-page/editor';
import PaymentDetails, { BANK_ACCOUNT_NUMBER_LABEL, BANK_ACCOUNT_NUMBER_REQUIRED, BANK_ROUTING_NUMBER_LABEL, 
    BANK_ROUTING_NUMBER_REQUIRED, CARDHOLDER_NAME_LABEL, CARDHOLDER_NAME_PLACEHOLDER, CARDHOLDER_NAME_REQUIRED, 
    CARD_NUMBER_LABEL, CARD_NUMBER_REQUIRED, CARD_TYPE_LABEL, CARD_TYPE_REQUIRED, CONFIRM_BANK_ACCOUNT_NUMBER_LABEL, 
    CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED, CVV_LABEL, CVV_REQUIRED, ECHECK_DISCLAIMER, EXPIRATION_DATE_LABEL, 
    EXPIRATION_DATE_REQUIRED, MARGIN, NAME_ON_ACCOUNT_LABEL, NAME_ON_ACCOUNT_PLACEHOLDER, NAME_ON_ACCOUNT_REQUIRED, 
    PADDING, PAYMENT_TYPE_LABEL, SELECT_PAYMENT_TYPE_OPTION_LABEL, CREDITCARDPAYMENTTYPE, ECHECKPAYMENTTYPE, PAYPALPAYMENTTYPE, 
    TERMINALPAYMENTTYPE, FULL_STORY_MASK } from './components/PaymentDetails';

type Data = {
  title: string,
  paymentType: string,
  selectPaymentTypeOption: string,
  cardholderName: string,
  cardholderNamePlaceholder: string,
  cardholderNameRequired: string,
  cardType: string,
  cardTypeRequired: string,
  cardNumber: string,
  cardNumberRequired: string,
  expirationDate: string,
  expirationDateRequired: string,
  cvv: string,
  cvvRequired: string,
  nameOnAccount: string,
  nameOnAccountPlaceholder: string,
  nameOnAccountRequired: string,
  bankAccountNumber: string,
  bankAccountNumberRequired: string,
  confirmBankAccountNumber: string,
  confirmBankAccountNumberRequired: string,
  bankRoutingNumber: string,
  bankRoutingNumberRequired: string,
  eCheckDisclaimer: string,
  creditCardPaymentType: string,
  eCheckPaymentType: string,
  paypalPaymentType: string,
  terminalPaymentType: string,
  fullStoryMask: boolean,
  margin: string,
  padding: string
}


const PaymentDetailsPlugin: CellPlugin<Data> = {

  Renderer: ({data}) => (
      <PaymentDetails
        paymentTypeLabel = {data.paymentType}
        selectPaymentTypeOptionLabel = {data.selectPaymentTypeOption}
        cardholderNameLabel = {data.cardholderName}
        cardholderNamePlaceholder = {data.cardholderNamePlaceholder}
        cardholderNameRequired = {data.cardholderNameRequired}
        cardTypeLabel = {data.cardType}
        cardTypeRequired = {data.cardTypeRequired}
        cardNumberLabel = {data.cardNumber}
        cardNumberRequired = {data.cardNumberRequired}
        expirationDateLabel = {data.expirationDate}
        expirationDateRequired = {data.expirationDateRequired}
        cvvLabel = {data.cvv}
        cvvRequired = {data.cvvRequired}
        nameOnAccountLabel = {data.nameOnAccount}
        nameOnAccountPlaceholder = {data.nameOnAccountPlaceholder}
        nameOnAccountRequired = {data.nameOnAccountRequired}
        bankAccountNumberLabel = {data.bankAccountNumber}
        bankAccountNumberRequired = {data.bankAccountNumberRequired}
        confirmBankAccountNumberLabel = {data.confirmBankAccountNumber}
        confirmBankAccountNumberRequired = {data.confirmBankAccountNumberRequired}
        bankRoutingNumberLabel = {data.bankRoutingNumber}
        bankRoutingNumberRequired = {data.bankRoutingNumberRequired}
        eCheckDisclaimer = {data.eCheckDisclaimer}
        creditCardPaymentType = {data.creditCardPaymentType}
        eCheckPaymentType = {data.eCheckPaymentType}
        paypalPaymentType = {data.paypalPaymentType}
        terminalPaymentType = {data.terminalPaymentType}
        fullStoryMask={data.fullStoryMask}
        margin = {data.margin}
        padding = {data.padding}
      />
  ),
  id: 'paymentDetailsPlugin',
  title: 'Payment Details',
  description: 'Collects payment details.',
  version: 1,
  controls: [
    {
      title: 'Labels',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            paymentType: {
              type: 'string',
              default: PAYMENT_TYPE_LABEL,
            },
            selectPaymentTypeOption: {
              type: 'string',
              default: SELECT_PAYMENT_TYPE_OPTION_LABEL,
            },
            cardholderName: {
              type: 'string',
              default: CARDHOLDER_NAME_LABEL,
            },
            cardholderNamePlaceholder: {
              type: 'string',
              default: CARDHOLDER_NAME_PLACEHOLDER,
            },
            cardholderNameRequired: {
              type: 'string',
              default: CARDHOLDER_NAME_REQUIRED,
            },
            cardType: {
              type: 'string',
              default: CARD_TYPE_LABEL,
            },
            cardTypeRequired: {
              type: 'string',
              default: CARD_TYPE_REQUIRED,
            },
            cardNumber: {
              type: 'string',
              default: CARD_NUMBER_LABEL,
            },
            cardNumberRequired: {
              type: 'string',
              default: CARD_NUMBER_REQUIRED,
            },
            expirationDate: {
              type: 'string',
              default: EXPIRATION_DATE_LABEL,
            },
            expirationDateRequired: {
              type: 'string',
              default: EXPIRATION_DATE_REQUIRED,
            },
            cvv: {
              type: 'string',
              default: CVV_LABEL,
            },
            cvvRequired: {
              type: 'string',
              default: CVV_REQUIRED,
            },
            nameOnAccount: {
              type: 'string',
              default: NAME_ON_ACCOUNT_LABEL,
            },
            nameOnAccountPlaceholder: {
              type: 'string',
              default: NAME_ON_ACCOUNT_PLACEHOLDER,
            },
            nameOnAccountRequired: {
              type: 'string',
              default: NAME_ON_ACCOUNT_REQUIRED,
            },
            bankAccountNumber: {
              type: 'string',
              default: BANK_ACCOUNT_NUMBER_LABEL,
            },
            bankAccountNumberRequired: {
              type: 'string',
              default: BANK_ACCOUNT_NUMBER_REQUIRED,
            },
            confirmBankAccountNumber: {
              type: 'string',
              default: CONFIRM_BANK_ACCOUNT_NUMBER_LABEL,
            },
            confirmBankAccountNumberRequired: {
              type: 'string',
              default: CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED,
            },
            bankRoutingNumber: {
              type: 'string',
              default: BANK_ROUTING_NUMBER_LABEL,
            },
            bankRoutingNumberRequired: {
              type: 'string',
              default: BANK_ROUTING_NUMBER_REQUIRED,
            },
            eCheckDisclaimer: {
              type: 'string',
              default: ECHECK_DISCLAIMER,
            },
            creditCardPaymentType: {
                type: 'string',
                default: CREDITCARDPAYMENTTYPE,
            },
            eCheckPaymentType: {
                type: 'string',
                default: ECHECKPAYMENTTYPE,
            },
            paypalPaymentType: {
                type: 'string',
                default: PAYPALPAYMENTTYPE,
            },
            terminalPaymentType: {
                type: 'string',
                default: TERMINALPAYMENTTYPE,
            },
            fullStoryMask:{
                type: 'boolean',
                default: FULL_STORY_MASK
            },
          },
          required: ['paymentType', 'selectPaymentTypeOption', 'cardholderName', 'cardholderNamePlaceholder', 'cardholderNameRequired', 'cardType', 'cardTypeRequired', 'cardNumber', 'cardNumberRequired', 'expirationDate',
              'expirationDateRequired', 'cvv', 'cvvRequired', 'nameOnAccount', 'nameOnAccountPlaceholder', 'nameOnAccountRequired', 'bankAccountNumber', 'bankAccountNumberRequired', 'confirmBankAccountNumber', 'confirmBankAccountNumberRequired', 
              'bankRoutingNumber', 'bankRoutingNumberRequired'
            ],
        },
      },
    },
    {
      title: 'Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            margin: {
              type: 'string',
              default: MARGIN,
            },
            padding: {
              type: 'string',
              default: PADDING,
            },
          },
          required: [],
        },
      },
    },
  ],
};

export default PaymentDetailsPlugin;