import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { AuthorizeNetCredentials, Department } from '../../../../../models/Client';
import "react-placeholder/lib/reactPlaceholder.css";
import { IActionResult, IAppState } from "../../../../../redux/storeTypes";
import "react-placeholder/lib/reactPlaceholder.css";
import { sendErrorToastAction, sendSuccessToastAction } from "../../../../../redux/actions/toast";
import { Routes } from "../../../../../routes";
import { Redirect } from "react-router-dom";
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ReactPlaceholder from 'react-placeholder';
import { addAuthorizeNetCredInfoAction, ADD_AUTHORIZENETCREDINFO_FAILURE, ADD_AUTHORIZENETCREDINFO_REQUEST, ADD_AUTHORIZENETCREDINFO_SUCCESS, DELETE_AUTHORIZENETCREDINFO_FAILURE, DELETE_AUTHORIZENETCREDINFO_REQUEST, DELETE_AUTHORIZENETCREDINFO_SUCCESS, getAuthorizeNetCredInfoAction, saveAuthorizeNetCredInfoAction, SAVE_AUTHORIZENETCREDINFO_FAILURE, SAVE_AUTHORIZENETCREDINFO_REQUEST, SAVE_AUTHORIZENETCREDINFO_SUCCESS } from "../../../../../redux/actions/clients/paymentChannels";
import Switch from "react-switch";

export interface IDepartmentAuthorizeNetFormProps {
    department: Department,
    paymentChannelMsbId: string,
    authorizeNetCredential: AuthorizeNetCredentials,
    actionResult: IActionResult,
    isFetching: boolean,
    isSaving: boolean,
    onClose: any
}

const DepartmentAuthorizeNetForm = ({ department, paymentChannelMsbId, authorizeNetCredential, actionResult, isFetching, isSaving }: IDepartmentAuthorizeNetFormProps) => {
    const actionToken = "AuthorizeNetCredInfo";
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [backfill, setBackfill] = useState(authorizeNetCredential?.backFill || false);

    React.useEffect(() => {
        let paymentChannel = (department?.paymentChannels || []).find(pc => pc.msbId === paymentChannelMsbId);
        if (paymentChannel !== undefined) {
            let processor = paymentChannel.processors.find(p => p.merchantProcessor?.processor === "AuthorizeDotNet");
            if (processor !== undefined) {
                let msbId = processor.merchantProcessor?.authorizeDotNetCredential?.msbId;
                dispatch(getAuthorizeNetCredInfoAction(msbId || "", actionToken));
            } else {
                dispatch(getAuthorizeNetCredInfoAction("", actionToken));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_AUTHORIZENETCREDINFO_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === ADD_AUTHORIZENETCREDINFO_SUCCESS) {
                    dispatch(sendSuccessToastAction("Authorize.Net Credential Information has been successfully added."));
                } else if (actionResult.result === ADD_AUTHORIZENETCREDINFO_FAILURE) {
                    dispatch(sendErrorToastAction("Authorize.Net Credential Information could not be added."));
                }
            }

            if (actionResult.type === SAVE_AUTHORIZENETCREDINFO_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_AUTHORIZENETCREDINFO_SUCCESS) {
                    dispatch(sendSuccessToastAction("Authorize.Net Credential Information has been successfully updated."));
                } else if (actionResult.result === SAVE_AUTHORIZENETCREDINFO_FAILURE) {
                    dispatch(sendErrorToastAction("Authorize.Net Credential Information could not be updated."));
                }
            }

            if (actionResult.type === DELETE_AUTHORIZENETCREDINFO_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === DELETE_AUTHORIZENETCREDINFO_SUCCESS) {
                    dispatch(sendSuccessToastAction("Authorize.Net Credential Information has been successfully deleted."));
                } else if (actionResult.result === DELETE_AUTHORIZENETCREDINFO_FAILURE) {
                    dispatch(sendErrorToastAction("Authorize.Net Credential Information could not be deleted."));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    const submitAuthorizeNetCredentials = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.reportValidity() !== false) {
            let _AuthorizeNetCredential: AuthorizeNetCredentials = Object.assign(new AuthorizeNetCredentials(), authorizeNetCredential!);

            _AuthorizeNetCredential.name = form.elements.authorizeNetName.value;
            _AuthorizeNetCredential.transactionKey = form.elements.authorizeNetTransactionKey.value;
            _AuthorizeNetCredential.backFill = backfill;
            _AuthorizeNetCredential.numberOfDaysToBackFill = form.elements.authorizeNetNumberDaysBackfill.value;

            if (authorizeNetCredential?.msbId) {
                dispatch(saveAuthorizeNetCredInfoAction(authorizeNetCredential.msbId, _AuthorizeNetCredential, actionToken));
            } else {
                dispatch(addAuthorizeNetCredInfoAction(paymentChannelMsbId, _AuthorizeNetCredential, actionToken));
                setRedirect(Routes.Client.path);
            }
        }
    }

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <Form onSubmit={submitAuthorizeNetCredentials} key={authorizeNetCredential?.msbId}>
                    <div>
                        <Row>
                            <Col>
                                <Form.Group controlId="authorizeNetName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="input" placeholder="Name" defaultValue={authorizeNetCredential?.name} maxLength={80} required={true} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="authorizeNetTransactionKey">
                                    <Form.Label>Transaction Key</Form.Label>
                                    <Form.Control type="input" placeholder="Transaction key" defaultValue={authorizeNetCredential?.transactionKey} maxLength={80} required={true} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <div className="toggle-switch">
                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                        <Switch
                                            onChange={e => setBackfill(e)}
                                            checked={backfill}
                                            onColor={'#0057B6'}
                                            offColor={'#BEBEBE'}
                                            handleDiameter={12}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={16}
                                            width={28}
                                            disabled={isSaving}
                                            activeBoxShadow={'0 0 0 1px #0057B6'}
                                        />
                                    </ReactPlaceholder>
                                    <span className="toggle-label">Backfill</span>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <Form.Group controlId="authorizeNetNumberDaysBackfill">
                                    <Form.Label>Number of days to backfill</Form.Label>
                                    <Form.Control type="number" placeholder="Days" defaultValue={authorizeNetCredential?.numberOfDaysToBackFill} max={backfill ? 7 : 0} min={backfill ? 1 : 0} maxLength={1} required={true} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>&nbsp;</Form.Label>
                                <div>
                                    <Button variant="primary" type="submit">
                                        {(authorizeNetCredential?.msbId) ? "Save" : "Add"}
                                    </Button>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        actionResult: state.clients.actionResult,
        authorizeNetCredential: state.clients.authorizeNetCredential,
    };
};

export default connect(mapStateToProps)(DepartmentAuthorizeNetForm);

