import { Row, Col } from "react-bootstrap"
import Table from '../../../../../../components/Table';

interface IMathewsSearchResults {
    typeLabel: string,
    accountYearLabel: string,
    accountNumberLabel: string,
    ticketNumberFirst7Label: string,
    ticketNumberLast4Label: string,
    monthsTaxedLabel: string,
    acreLabel: string,
    landValueLabel: string,
    improvementValueLabel: string,
    principalDueLabel: string,
    penaltyDueLabel: string,
    interestDueLabel: string,
    parcelIdLabel: string,
    nameLabel: string,
    addressLabel: string,
    modelLabel: string,
    totalAmountDueLabel: string,
    vehicleTypeLabel: string,
    classLabel: string,
    valueLabel: string,
    statusLabel: string,
    paymentOptionsLabel: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    overlayText: string,
    overlayBackground: string,
    overlayTextColor: string,
    overlayIconColor: string,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
}

export const TYPE_LABEL = 'Type';
export const ACCOUNT_YEAR_LABEL = 'Account Year';
export const ACCOUNT_NUMBER_LABEL = 'Account Number';
export const PARCEL_ID_LABEL = 'Parcel ID'
export const NAME_LABEL = 'Name';
export const ADDRESS_LABEL = 'Address';
export const MODEL_LABEL = 'Model';
export const CLASS_LABEL = 'Class';
export const VALUE_LABEL = 'Value';
export const TOTAL_AMOUNT_DUE_LABEL = 'Total Amount Due';
export const STATUS_LABEL = 'Status';
export const PAYMENT_OPTIONS_LABEL = 'Payment Options';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const OVERLAY_TEXT = 'There are no available online payment options on this parcel at this time';
export const OVERLAY_BACKGROUND = '#fef1b5';
export const OVERLAY_TEXT_COLOR = '#1e1e1e';
export const OVERLAY_ICON_COLOR = 'orange';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';
export const TICKET_NUMBER_FIRST_7 = 'Ticket Number First7';
export const TICKET_NUMBER_LAST_4 = 'Ticket Number Last4';
export const MONTHS_TAXED_LABEL = 'Months Taxed';
export const ACRE_LABEL = 'Acre';
export const LAND_VALUE_LABEL = 'Land Value';
export const IMPROVEMENT_VALUE_LABEL = 'Improvement Value';
export const PRINCIPAL_DUE_LABEL = 'Principal Due';
export const PENALTY_DUE_LABEL = 'Penalty Due';
export const INTEREST_DUE_LABEL = 'Interest Due'
export const VEHICLE_TYPE_LABEL = 'Vehicle Type'

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const MathewsSearchResults = ({ typeLabel, accountYearLabel, accountNumberLabel, ticketNumberFirst7Label, ticketNumberLast4Label, parcelIdLabel, nameLabel, addressLabel, modelLabel, classLabel,
    totalAmountDueLabel, statusLabel, valueLabel, paymentOptionsLabel, durationOfPendingHours, observesWeekends, overlayText, overlayBackground, overlayTextColor,
    overlayIconColor, monthsTaxedLabel, acreLabel, landValueLabel, improvementValueLabel, principalDueLabel, vehicleTypeLabel, penaltyDueLabel, interestDueLabel, margin, padding, headerBackgroundColor, headerPadding }: IMathewsSearchResults) => {

    if (!typeLabel) { typeLabel = TYPE_LABEL }
    if (!accountYearLabel) { accountYearLabel = ACCOUNT_YEAR_LABEL }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!parcelIdLabel) { parcelIdLabel = PARCEL_ID_LABEL }
    if (!nameLabel) { nameLabel = NAME_LABEL }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL }
    if (!modelLabel) { modelLabel = MODEL_LABEL }
    if (!classLabel) { classLabel = CLASS_LABEL }
    if (!valueLabel) { valueLabel = VALUE_LABEL }
    if (!totalAmountDueLabel) { totalAmountDueLabel = TOTAL_AMOUNT_DUE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!vehicleTypeLabel) { vehicleTypeLabel = VEHICLE_TYPE_LABEL }
    if (!paymentOptionsLabel) { paymentOptionsLabel = PAYMENT_OPTIONS_LABEL }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!ticketNumberFirst7Label) { ticketNumberFirst7Label = TICKET_NUMBER_FIRST_7 }
    if (!ticketNumberLast4Label) { ticketNumberLast4Label = TICKET_NUMBER_LAST_4 }
    if (!monthsTaxedLabel) { monthsTaxedLabel = MONTHS_TAXED_LABEL }
    if (!acreLabel) { acreLabel = ACRE_LABEL }
    if (!landValueLabel) { landValueLabel = LAND_VALUE_LABEL }
    if (!improvementValueLabel) { improvementValueLabel = IMPROVEMENT_VALUE_LABEL }
    if (!principalDueLabel) { principalDueLabel = PRINCIPAL_DUE_LABEL }
    if (!penaltyDueLabel) { penaltyDueLabel = PENALTY_DUE_LABEL }
    if (!interestDueLabel) { interestDueLabel = INTEREST_DUE_LABEL }
    if (!overlayText) { overlayText = OVERLAY_TEXT }
    if (!overlayBackground) { overlayBackground = OVERLAY_BACKGROUND }
    if (!overlayTextColor) { overlayTextColor = OVERLAY_TEXT_COLOR }
    if (!overlayIconColor) { overlayIconColor = OVERLAY_ICON_COLOR }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }
    
    const initialColumns = [{
        dataField: 'type',
        text: typeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'accountYear',
        text: accountYearLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'accountNumber',
        text: accountNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'ticketNumberFirst7',
        text: ticketNumberFirst7Label,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'ticketNumberLast4',
        text: ticketNumberLast4Label,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'parcelId',
        text: parcelIdLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'name',
        text: nameLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'address',
        text: addressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'model',
        text: modelLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    },{
        dataField: 'metaData.VehType',
        text: vehicleTypeLabel,
        staticKey: 'vehicleType',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'class',
        text: classLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'value',
        text: valueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'totalAmountDue',
        text: totalAmountDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,
        sort: true,
    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'id',
        text: paymentOptionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];


    const renderTable = () => {
        return (
            <Table
                keyField="msbId"
                data={[]}
                columns={initialColumns}
            />);
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </div>

    )
}

export default MathewsSearchResults;