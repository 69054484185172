import React, { useState } from "react";

export interface StepHeaderProps {
    title: string;
    description: string;
}

const StepHeader = ({ title, description }: StepHeaderProps) => {
    return (
        <div className="mb-4">
            <h4>{title}</h4>
            <span >{description}</span>
        </div>
    );
};

export default StepHeader;