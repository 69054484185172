import { CellPlugin } from '@react-page/editor';
import MathewsSearchResults, {
    TYPE_LABEL, ACCOUNT_YEAR_LABEL,ACCOUNT_NUMBER_LABEL, PARCEL_ID_LABEL, NAME_LABEL, ADDRESS_LABEL, MODEL_LABEL, TOTAL_AMOUNT_DUE_LABEL, CLASS_LABEL, 
    VALUE_LABEL, STATUS_LABEL, PAYMENT_OPTIONS_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING,
    OBSERVES_WEEKENDS, DURATION_OF_PENDING_HOURS, OVERLAY_TEXT, OVERLAY_BACKGROUND, OVERLAY_TEXT_COLOR, OVERLAY_ICON_COLOR,
    TICKET_NUMBER_FIRST_7, TICKET_NUMBER_LAST_4, MONTHS_TAXED_LABEL, ACRE_LABEL, LAND_VALUE_LABEL, IMPROVEMENT_VALUE_LABEL,
    PRINCIPAL_DUE_LABEL, PENALTY_DUE_LABEL, INTEREST_DUE_LABEL, VEHICLE_TYPE_LABEL
} from './components/MathewsSearchResults';

type Data = {
    title: string,
    type: string,
    accountYear: string,
    parcelId: string,
    ticketNumberFirst7: string,
    ticketNumberLast4: string,
    monthsTaxed: string,
    acre: string,
    landValue: string,
    improvementValue: string,
    principalDue: string,
    penaltyDue: string,
    interestDue: string,
    accountNumber: string,
    name: string,
    address: string,
    model: string,
    vehicleType: string,
    totalAmountDue: string,
    class: string,
    value: string,
    status: string,
    paymentOptions: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    overlayText: string,
    overlayBackground: string,
    overlayTextColor: string,
    overlayIconColor: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
}

const MathewsSearchResultsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <MathewsSearchResults
            typeLabel={data.type}
            accountYearLabel={data.accountYear}
            accountNumberLabel={data.accountNumber}
            parcelIdLabel={data.parcelId}
            ticketNumberFirst7Label={data.ticketNumberFirst7}
            ticketNumberLast4Label={data.ticketNumberLast4}
            monthsTaxedLabel={data.monthsTaxed}
            acreLabel={data.acre}
            landValueLabel={data.landValue}
            improvementValueLabel={data.improvementValue}
            principalDueLabel={data.principalDue}
            penaltyDueLabel={data.penaltyDue}
            interestDueLabel={data.interestDue}
            nameLabel={data.name}
            addressLabel={data.address}
            modelLabel={data.model}
            vehicleTypeLabel={data.vehicleType}
            totalAmountDueLabel={data.totalAmountDue}
            classLabel={data.class}
            valueLabel={data.value}
            statusLabel={data.status}
            paymentOptionsLabel={data.paymentOptions}
            observesWeekends={data.observesWeekends}
            durationOfPendingHours={data.durationOfPendingHours}
            overlayText={data.overlayText}
            overlayBackground={data.overlayBackground}
            overlayTextColor={data.overlayTextColor}
            overlayIconColor={data.overlayIconColor}
            margin={data.margin}
            padding={data.padding}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
        />
    ),
    id: 'mathewsResultsPlugin',
    title: 'Mathews Search Results',
    description: 'Mathews table results',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        type:{
                            type: 'string',
                            default: TYPE_LABEL,
                        },
                        accountYear:{
                            type: 'string',
                            default: ACCOUNT_YEAR_LABEL,
                        },
                        accountNumber: {
                            type: 'string',
                            default: ACCOUNT_NUMBER_LABEL,
                        },
                        parcelId: {
                            type: 'string',
                            default: PARCEL_ID_LABEL,
                        },
                        ticketNumberFirst7: {
                            type: 'string',
                            default: TICKET_NUMBER_FIRST_7,
                        },
                        ticketNumberLast4:{
                            type: 'string',
                            default: TICKET_NUMBER_LAST_4,
                        },
                        vehicleType: {
                            type: 'string',
                            default: VEHICLE_TYPE_LABEL,
                        },
                        monthsTaxed:{
                            type: 'string',
                            default: MONTHS_TAXED_LABEL,
                        },
                        acre:{
                            type: 'string',
                            default: ACRE_LABEL,
                        },
                        landValue:{
                            type: 'string',
                            default: LAND_VALUE_LABEL,
                        },
                        improvementValue:{
                            type: 'string',
                            default: IMPROVEMENT_VALUE_LABEL,
                        },
                        principalDue:{
                            type: 'string',
                            default: PRINCIPAL_DUE_LABEL,
                        },
                        penaltyDue:{
                            type: 'string',
                            default: PENALTY_DUE_LABEL,
                        },
                        interestDue:{
                            type: 'string',
                            default: INTEREST_DUE_LABEL,
                        },
                        name: {
                            type: 'string',
                            default: NAME_LABEL,
                        },
                        address: {
                            type: 'string',
                            default: ADDRESS_LABEL,
                        },
                        model: {
                            type: 'string',
                            default: MODEL_LABEL,
                        },
                        totalAmountDue: {
                            type: 'string',
                            default: TOTAL_AMOUNT_DUE_LABEL,
                        },
                        class:{
                            type: 'string',
                            default: CLASS_LABEL,
                        },
                        value:{
                            type: 'string',
                            default: VALUE_LABEL,
                        },
                        status:{
                            type:'string',
                            default: STATUS_LABEL,
                        },
                        paymentOptions: {
                            type: 'string',
                            default: PAYMENT_OPTIONS_LABEL,
                        },
                        observesWeekends:{
                            type: 'boolean',
                            default: OBSERVES_WEEKENDS
                        },
                        durationOfPendingHours:{
                            type: 'number',
                            default: DURATION_OF_PENDING_HOURS
                        },
                        overlayText:{
                            type: 'string',
                            default: OVERLAY_TEXT
                        },
                        overlayBackground:{
                            type:'string',
                            default: OVERLAY_BACKGROUND
                        },
                        overlayTextColor:{
                            type:'string',
                            default: OVERLAY_TEXT_COLOR
                        },
                        overlayIconColor:{
                            type:'string',
                            default: OVERLAY_ICON_COLOR
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default MathewsSearchResultsPlugin;