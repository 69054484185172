
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { getClientAction, GET_CLIENT_REQUEST, GET_CLIENT_SUCCESS, GET_CLIENT_FAILURE } from '../redux/actions/clients/clients';
import { Routes } from "../routes";
import { IAppState } from '../redux/storeTypes';
import { User } from '../models/User';
import { Client, ClientStatusEnum } from '../models/Client';
import Preloader from "../components/Preloader";
import { TylerEagleRequest } from '../models/TylerEagleIntegration';

interface GetUserClientProps {
    user: User,
    client: Client,
    isFetching: boolean,
    tylerEagleRequest: TylerEagleRequest
}

const GetUserClient = ({ user, client, isFetching, tylerEagleRequest }: GetUserClientProps) => {
    const dispatch = useDispatch();
    let actionToken = "GetUserClient";

    useEffect(() => {
        if (user !== null) {
            dispatch(getClientAction(user.userClients[0].clientMSBId, actionToken));
        }
    }, [user]);

    if (client) {
        if (tylerEagleRequest) {
            return (<Redirect push to={Routes.PointOfSale.path} />);
        } else {
            return (<Redirect push to={Routes.DashboardOverview.path} />);
        }
    }

    /*
    if (client && client.onBoardingStatus === ClientStatusEnum.Deactive) {
        return (<><div className="authorizing">Not authorized. Client not active.</div></>);
    }
    else {
        return (<><Preloader show={isFetching ? true : false} /></>);
    }
    */

    return (<><Preloader show={isFetching ? true : false} /></>);
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client,
        isFetching: state.clients.isFetching,
        user: state.auth.currentUser,
        tylerEagleRequest: state.tylerEagleIntegration.tylerEagleRequest
    };
};

export default connect(mapStateToProps)(GetUserClient);