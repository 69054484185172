import axios from 'axios';
import  Approval, { ApprovalRejectedRequest, ApprovalTypeEnum, StatusEnum } from '../../models/Approval';
import { Dispatch } from 'react';
import { IPageableResults, PageableResults } from "../../models/PageableResults"
import store from "../../redux/store";

export const GET_PENDING_APPROVALS_REQUEST = 'GET_PENDING_APPROVALS_REQUEST';
export const GET_PENDING_APPROVALS_SUCCESS = 'GET_PENDING_APPROVALS_SUCCESS';
export const GET_PENDING_APPROVALS_FAILURE = 'GET_PENDING_APPROVALS_FAILURE';

export const APPROVE_PENDING_APPROVAL_REQUEST = 'APPROVE_PENDING_APPROVAL_REQUEST';
export const APPROVE_PENDING_APPROVAL_SUCCESS = 'APPROVE_PENDING_APPROVAL_SUCCESS';
export const APPROVE_PENDING_APPROVAL_FAILURE = 'APPROVE_PENDING_APPROVAL_FAILURE';

export const REJECT_PENDING_APPROVAL_REQUEST = 'REJECT_PENDING_APPROVAL_REQUEST';
export const REJECT_PENDING_APPROVAL_SUCCESS = 'REJECT_PENDING_APPROVAL_SUCCESS';
export const REJECT_PENDING_APPROVAL_FAILURE = 'REJECT_PENDING_APPROVAL_FAILURE';

export const GET_REJECTED_APPROVAL_REQUEST = 'GET_REJECTED_APPROVAL_REQUEST';
export const GET_REJECTED_APPROVAL_SUCCESS = 'GET_REJECTED_APPROVAL_SUCCESS';
export const GET_REJECTED_APPROVAL_FAILURE = 'GET_REJECTED_APPROVAL_FAILURE';

export const getPendingApprovalsAction = (page:number, pageSize:number, actionToken:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_PENDING_APPROVALS_REQUEST,
        payload: {
            actionToken
        }
    });

    let results: IPageableResults<Approval> = new PageableResults<Approval>();
    results.page = page;
    results.totalRecords = 0;
    let start:number = 0;
    let end: number = pageSize;

    if (page > 1) {
        start = (page - 1) * pageSize;
        end = start + pageSize; 
    }

    let _url = `/MSB_Approval/api/v1/Approvals?page=${page}&approvalType=1&pageSize=${pageSize}`;
    axios.get(_url)
        .then(function (response) {
            results.data = response.data.data;
            results.totalRecords = response.data.total;
            dispatch({
                type: GET_PENDING_APPROVALS_SUCCESS,
                payload: {
                    results,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_PENDING_APPROVALS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const rejectApprovalAction = (approvalRejectedRequest: ApprovalRejectedRequest, actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: REJECT_PENDING_APPROVAL_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Approval/api/v1/Approvals/Reject/${approvalRejectedRequest.approvalMsbId}`;

    axios.post(_url, approvalRejectedRequest)
        .then(function (response) {
            let approval = response.data;
            dispatch({
                type: REJECT_PENDING_APPROVAL_SUCCESS,
                payload: {
                    approval,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: REJECT_PENDING_APPROVAL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const approveApprovalAction = (approvalMsbId: string, actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: APPROVE_PENDING_APPROVAL_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Approval/api/v1/Approvals/Approve/${approvalMsbId}`;

    axios.get(_url)
        .then(function (response) {
            let approval = response.data;

            dispatch({
                type: APPROVE_PENDING_APPROVAL_SUCCESS,
                payload: {
                    approval,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: APPROVE_PENDING_APPROVAL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const getRejectedApprovalAction = (msbId:string, approvalTypeEnum: ApprovalTypeEnum, actionToken:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_REJECTED_APPROVAL_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Approval/api/v1/Approvals/ForReference/${msbId}?approvalTypeEnum=${approvalTypeEnum}&statusEnum=${StatusEnum.Rejected}&limit=1`;
    axios.get(_url)
        .then(function (response) {
            let approvals:Array<Approval> = response.data;
            if (approvals.length == 1) {
                let approval: Approval = approvals[0];
                dispatch({
                    type: GET_REJECTED_APPROVAL_SUCCESS,
                    payload: {
                        approval,
                        actionToken
                    }
                });
            } else {
                var approval = null;
                dispatch({
                    type: GET_REJECTED_APPROVAL_SUCCESS,
                    payload: {
                        approval,
                        actionToken
                    }
                });
            }
        })
        .catch(function (error) {
            dispatch({
                type: GET_REJECTED_APPROVAL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};