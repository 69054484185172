import axios from 'axios';
import { ClientRole } from '../../models/ClientRole';
import { mockClientRoles } from '../mocks/mockClientRoles';
import { Dispatch } from 'react';
import store from "../../redux/store";
import { IPageableResults, PageableResults } from "../../models/PageableResults"
export const GET_CLIENT_ROLES_REQUEST = 'GET_CLIENT_ROLES_REQUEST';
export const GET_CLIENT_ROLES_SUCCESS = 'GET_CLIENT_ROLES_SUCCESS';
export const GET_CLIENT_ROLES_FAILURE = 'GET_CLIENT_ROLES_FAILURE';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS'
export const CANCEL_STEP = 'CANCEL_STEP';
export const POST_CLIENT_ROLES_REQUEST = 'POST_CLIENT_USER_REQUEST';
export const POST_CLIENT_ROLES_SUCCESS = 'POST_CLIENT_USER_SUCCESS';
export const POST_CLIENT_ROLES_FAILURE = 'POST_CLIENT_USER_FAILURE';
export const SET_CLIENT_ROLES_DETAILS = 'SET_CLIENT_USER_DETAILS';


export const getClientRolesAction = () => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_CLIENT_ROLES_REQUEST,

    });

    let results: IPageableResults<ClientRole> = new PageableResults<ClientRole>();
    results.data = new Array<ClientRole>();

    let _url = "/Role/api/Roles";

    axios.get(_url)
        .then(function (response) {
            results.data = response.data.data;
            results.totalRecords = response.data.total;
            dispatch({
                type: GET_CLIENT_ROLES_SUCCESS,
                payload: {
                    results
                    
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CLIENT_ROLES_FAILURE,
                payload: {
                    error
                }
            });
        })
        .then(function () {
        }); 
    
};

export const cancelAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CANCEL_STEP,
    });
};

export const setRolesAction = (roles:ClientRole) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: SET_CLIENT_ROLES_DETAILS,
        payload:{
            roles
        }
    });

};