import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IActionResult, IAppState } from '../../../../redux/storeTypes';
import { Col, Row, Form, Modal, Popover, OverlayTrigger, Spinner } from 'react-bootstrap';
import RequiredIcon from '../../../../components/RequiredIcon';
import Switch from "react-switch";
// @ts-ignore
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import FormActions from './FormActions';
import { useEffect } from "react";
import { Client } from "../../../../models/Client";
import { Role } from "../../../../models/Role";
import InputMask from "react-input-mask";
import { LabelValue, User, UserClient, UserDepartment, UserTypeEnum } from "../../../../models/User";
import { saveUserAction, SAVE_USER_FAILURE, SAVE_USER_REQUEST, SAVE_USER_SUCCESS } from "../../../../redux/actions/users";
import { sendErrorToastAction, sendSuccessToastAction } from "../../../../redux/actions/toast";
import { faChevronLeft, faChevronRight, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IManageUsersForm {
    client: Client;
    isSaving: boolean;
    isFetching: boolean,
    user: User;
    roles: Array<Role>
    showForm: boolean;
    setShowForm: (value: boolean) => void;
    actionResult: IActionResult;
}

const ManageUsersForm = ({ isSaving, isFetching, client, roles, user, showForm, setShowForm, actionResult }: IManageUsersForm) => {

    const actionToken = "ManageUsersForm";
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [userRoles, setUserRoles] = useState<Array<Role>>([])
    const [selectedDepartments, setSelectedDepartments] = useState<Array<string>>([]);
    const [availableDepartments, setAvailableDepartments] = useState<Array<LabelValue>>([]);

    useEffect(() => {
        let _departments = client.departments.map(department => new LabelValue(department.name, department.msbId!));
        setAvailableDepartments(_departments);
    }, []);

    useEffect(() => {
        if (user) {
            let _selectedDepartments: Array<string> = [];
            user.userDepartments.forEach(x => _selectedDepartments.push(x.departmentMSBId));
            setSelectedDepartments(_selectedDepartments);
            for (var x = 0; x < document.forms.length; x++) {
                if (document.forms[x].id === 'clientUserForm') {
                    document.forms[x]["selectedDepartments"].value = _selectedDepartments;
                }
                if (roles) {
                    const userRoles = user?.userRoles?.map((fields: any) => fields?.role)
                    const selectedRoles = roles.map((baseRoles: Role) => ({
                        ...baseRoles,
                        selected: userRoles!.some((roleFields: Role) => roleFields.name === baseRoles.name),
                    }))
                    setUserRoles(selectedRoles);
                }
            }
        } else {
            setSelectedDepartments([]);
            setUserRoles(roles);
        }
    }, [user, roles]);

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === SAVE_USER_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === SAVE_USER_SUCCESS) {
                dispatch(sendSuccessToastAction("User was successfully saved."));
                setShowForm(false);
            } else if (actionResult.result === SAVE_USER_FAILURE) {
                dispatch(sendErrorToastAction("User could not be saved."));
            }
        }
    }, [actionResult]);

    const closeForm = () => {
        setShowForm(false);
        setValidated(false);
    }

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            if (user === null) {
                user = new User();
                user.userTypeEnum = UserTypeEnum.Client;
                let userClient = new UserClient(client.msbId!);
                user.userClients.push(userClient);
            }

            user.email = form.elements.emailAddress.value;
            user.firstName = form.elements.firstName.value;
            user.lastName = form.elements.lastName.value;
            user.phoneNumber = form.elements.phoneNumber.value.replace(/\D/g, "");
            user.userDepartments = [];

            let selectedDepartments = form.elements.selectedDepartments.value as string;
            let departmentIds = selectedDepartments ? selectedDepartments.split(",") as Array<string> : [];
            departmentIds.forEach(x => user.userDepartments.push(new UserDepartment(x)));

            if (user.userDepartments.length == 0) {
                dispatch(sendErrorToastAction("User must be associated with at least one department."));
                return;
            }

            const selectedRoles = userRoles.filter(({ selected }: Role) => selected === true).map((roleDetails: Role) =>
            ({
                role: {
                    name: roleDetails.name,
                    description: roleDetails.description,
                    userTypeEnum: UserTypeEnum.Client,
                }
            }));
            user.userRoles = selectedRoles;
            const { updatedAt,createdAt, ...modifiedUser } = user;
            dispatch(saveUserAction(modifiedUser, actionToken));
        } else {
            setValidated(true);
        }
    };

    const dualListChange = (event: Array<string>) => {
        setSelectedDepartments(event);
        for (var x = 0; x < document.forms.length; x++) {
            if (document.forms[x].id === 'clientUserForm') {
                document.forms[x]["selectedDepartments"].value = event;
            }
        }
    }

    const rolesAction = (event: boolean, value: Role) => {
        value.selected = event;
        setUserRoles([...userRoles]);
    }

    return (
        <Modal show={showForm} size="lg" onHide={closeForm} backdrop="static">
            <Modal.Header closeButton />
            <Modal.Body>
                <>
                    <h2 style={{ marginBottom: '4px' }}>{user ? 'Edit User' : 'Add User'}</h2>
                    <p className="mb-4">{user ? "Edit the user's details and permissions" : "Add a new user's details and select their role permissions"}</p>

                    <h2>User Details</h2>
                    <Form id='clientUserForm' noValidate validated={validated} onSubmit={handleSubmit} key={user?.msbId}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="firstName">
                                    <Form.Label><RequiredIcon />First name</Form.Label>
                                    <Form.Control required type="input" placeholder="Enter First name" defaultValue={user?.firstName} />
                                    <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="lastName">
                                    <Form.Label><RequiredIcon />Last name</Form.Label>
                                    <Form.Control required type="input" placeholder="Enter Last name" defaultValue={user?.lastName} />
                                    <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="emailAddress">
                                    <Form.Label><RequiredIcon />Email address</Form.Label>
                                    <Form.Control required type="email" placeholder="email@address.com" defaultValue={user?.email} disabled={user? user?.email!='':false} />
                                    <Form.Control.Feedback type="invalid">Please enter an email address.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="phoneNumber">
                                    <Form.Label><RequiredIcon />Phone number</Form.Label>
                                    <InputMask required type="input" id="phoneNumber" pattern="[0-9\-\(\)\s]{14}" className="form-control" name="phoneNumber" mask="(999) 999-9999" defaultValue={user?.phoneNumber} placeholder="(999) 999-9999" />
                                    <Form.Control.Feedback type="invalid">Please enter a phone number.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="departments">
                                    <Form.Label><RequiredIcon />Departments</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <input type="hidden" id="selectedDepartments" name="selectedDepartments" />
                                <DualListBox
                                    disabled={isSaving}
                                    selected={selectedDepartments}
                                    options={availableDepartments}
                                    onChange={dualListChange}
                                    className="transfer"
                                    showHeaderLabels={true}
                                    lang={{
                                        availableHeader: 'Available departments',
                                        selectedHeader: 'User\'s departments'
                                    }}
                                    icons={{
                                        moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                                        moveAllLeft: [
                                            <FontAwesomeIcon icon={faChevronLeft} key={0} />,
                                            <FontAwesomeIcon icon={faChevronLeft} key={1} />,
                                        ],
                                        moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                                        moveAllRight: [
                                            <FontAwesomeIcon icon={faChevronRight} key={0} />,
                                            <FontAwesomeIcon icon={faChevronRight} key={1} />,
                                        ],
                                    }}
                                />
                            </Col>
                        </Row>
                        <hr />
                        {userRoles ?
                            <Row>
                              <h2>User Roles</h2>
                                <div className="userRoleGrid">
                                    {Object.entries(userRoles).map(
                                        ([index, value]: any) => {
                                            return (
                                                <div key={index} className="toggle-switch">
                                                    <Switch
                                                        onChange={(event) => rolesAction(event, value)}
                                                        checked={value.selected}
                                                        onColor={'#0057B6'}
                                                        offColor={'#BEBEBE'}
                                                        handleDiameter={12}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        height={16}
                                                        width={28}
                                                        activeBoxShadow={'0 0 0 1px #0057B6'}
                                                    />
                                                    <span className="roleType toggle-label">{value.name}</span>
                                                    <OverlayTrigger trigger="click" rootClose placement="top" overlay={(
                                                        <Popover id="popover-positioned-top">
                                                            <Popover.Content>
                                                                {value.description}
                                                            </Popover.Content>
                                                        </Popover>
                                                    )}>
                                                        <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#8C8C8C" }} />
                                                    </OverlayTrigger>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            </Row>
                            :
                            <Spinner animation="border" />
                        }

                        <FormActions hasSubmit customProps={{ submitButtonDisplay: user ? 'Update' : 'Add' }} onCancel={closeForm} />
                    </Form>
                </>
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client,
        isSaving: state.users.isSaving,
        isFetching: state.users.isFetching,
        user: state.users.user,
        actionResult: state.users.actionResult,
        roles: state.roles.roles,
    };
};

export default connect(mapStateToProps)(ManageUsersForm);