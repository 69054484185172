import {
    GET_PENDING_APPROVALS_REQUEST, GET_PENDING_APPROVALS_SUCCESS,GET_PENDING_APPROVALS_FAILURE,
    APPROVE_PENDING_APPROVAL_REQUEST, APPROVE_PENDING_APPROVAL_SUCCESS, APPROVE_PENDING_APPROVAL_FAILURE,
    REJECT_PENDING_APPROVAL_REQUEST, REJECT_PENDING_APPROVAL_SUCCESS, REJECT_PENDING_APPROVAL_FAILURE,
    GET_REJECTED_APPROVAL_REQUEST, GET_REJECTED_APPROVAL_SUCCESS, GET_REJECTED_APPROVAL_FAILURE
} from '../actions/pendingApprovals';

export default function pendingApprovals(state = {
    isFetching: false,
    isSvaing: false,
    errorMessage: '',
    currentPage: null,
    approval: null,
    actionResult: null
}, { type, payload }) {
    switch (type) {
        case GET_PENDING_APPROVALS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_PENDING_APPROVALS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_PENDING_APPROVALS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                currentPage: payload.results,
                actionResult: { type: GET_PENDING_APPROVALS_REQUEST, result: GET_PENDING_APPROVALS_SUCCESS, token: payload.actionToken } ,
            });
        case GET_PENDING_APPROVALS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: APPROVE_PENDING_APPROVAL_REQUEST, result: GET_PENDING_APPROVALS_FAILURE, token: payload.actionToken } ,
            });
        case APPROVE_PENDING_APPROVAL_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: APPROVE_PENDING_APPROVAL_REQUEST, result: null, token: payload.actionToken } ,
            });
        case APPROVE_PENDING_APPROVAL_SUCCESS:
            if (true) {
                let approvals = [];
                if (state.currentPage.data.length > 0) {
                    for (let x = 0; x < state.currentPage.data.length; x++) {
                        if (state.currentPage.data[x].msbId !== payload.approval.msbId) {
                            approvals.push(state.currentPage.data[x]);
                        }
                    }
                }

                let _currentPage = Object.assign({}, state.currentPage);
                _currentPage.data = approvals;
                _currentPage.totalRecords = approvals.length;

                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: APPROVE_PENDING_APPROVAL_REQUEST, result: APPROVE_PENDING_APPROVAL_SUCCESS, token: payload.actionToken } ,
                    approval: payload.approval,
                    currentPage: _currentPage
                });
            }
        case APPROVE_PENDING_APPROVAL_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: APPROVE_PENDING_APPROVAL_REQUEST, result: APPROVE_PENDING_APPROVAL_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case REJECT_PENDING_APPROVAL_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: REJECT_PENDING_APPROVAL_REQUEST, result: null, token: payload.actionToken } ,
            });
        case REJECT_PENDING_APPROVAL_SUCCESS:
            if (true) {
                let approvals = [];
                if (state.currentPage.data.length > 0) {
                    for (let x = 0; x < state.currentPage.data.length; x++) {
                        if (state.currentPage.data[x].msbId !== payload.approval.msbId) {
                            approvals.push(state.currentPage.data[x]);
                        }
                    }
                }

                let _currentPage = Object.assign({}, state.currentPage);
                _currentPage.data = approvals;
                _currentPage.totalRecords = approvals.length;

                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: REJECT_PENDING_APPROVAL_REQUEST, result: REJECT_PENDING_APPROVAL_SUCCESS, token: payload.actionToken } ,
                    approval: payload.approval,
                    currentPage: _currentPage
                });
            }
        case REJECT_PENDING_APPROVAL_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: REJECT_PENDING_APPROVAL_REQUEST, result: REJECT_PENDING_APPROVAL_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case GET_REJECTED_APPROVAL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                approval: null,
                actionResult: { type: GET_REJECTED_APPROVAL_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_REJECTED_APPROVAL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                approval: payload.approval,
                actionResult: { type: GET_REJECTED_APPROVAL_REQUEST, result: GET_REJECTED_APPROVAL_SUCCESS, token: payload.actionToken } ,
            });
        case GET_REJECTED_APPROVAL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                approval: null,
                errorMessage: payload.error,
                actionResult: { type: GET_REJECTED_APPROVAL_REQUEST, result: GET_REJECTED_APPROVAL_FAILURE, token: payload.actionToken } ,
            });
        default:
            return state;
    }
}


