import axios from 'axios';
import { Printer, Address } from '../../../models/Client';
import { Dispatch } from 'react';
import store from "../../../redux/store";
import { OrderPayment } from '../../../models/Order';
import { ReceiptWrapper } from '../../../models/Payment';

export const SAVE_PRINTER_REQUEST = 'SAVE_PRINTER_REQUEST';
export const SAVE_PRINTER_SUCCESS = 'SAVE_PRINTER_SUCCESS';
export const SAVE_PRINTER_FAILURE = 'SAVE_PRINTER_FAILURE';

export const DELETE_PRINTER_REQUEST = 'DELETE_PRINTER_REQUEST';
export const DELETE_PRINTER_SUCCESS = 'DELETE_PRINTER_SUCCESS';
export const DELETE_PRINTER_FAILURE = 'DELETE_PRINTER_FAILURE';

export const PRINT_PRINTER_REQUEST = 'PRINT_PRINTER_REQUEST';
export const PRINT_PRINTER_SUCCESS = 'PRINT_PRINTER_SUCCESS';
export const PRINT_PRINTER_FAILURE = 'PRINT_PRINTER_FAILURE';

export const savePrinterAction = (printer: Printer, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_PRINTER_REQUEST,
        payload: {
            actionToken
        }
    });

    connectPrinter(printer, actionToken, dispatch);

    //username: epson
    //pwd: X6WY171168
    //ip: 172.23.20.178 
    //
    //let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    //let bearerToken = store.getState().webAppSettings.bearerToken;
    //const config = {
    //    headers: { Authorization: `Bearer ${bearerToken}` }
    //};

    //let _url = baseApiUrl + "/MSB_Client/api/v1/Printers"

    //if (printer.msbId === undefined) {
    //    axios.post(_url, printer, config)
    //        .then(function (response) {
    //            printer = response.data;
    //            dispatch({
    //                type: SAVE_PRINTER_SUCCESS,
    //                payload: {
    //                    printer,
    //                    actionToken
    //                }
    //            });
    //        })
    //        .catch(function (error) {
    //            console.error("Error adding printer: " + error.name + ': ' + error.message);
    //            dispatch({
    //                type: SAVE_PRINTER_FAILURE,
    //                payload: {
    //                    error,
    //                    actionToken
    //                }
    //            });
    //        });
    //} else {
    //    _url = _url + "/" + printer.msbId;

    //    axios.put(_url, printer, config)
    //        .then(function (response) {
    //            printer = response.data;
    //            dispatch({
    //                type: SAVE_PRINTER_SUCCESS,
    //                payload: {
    //                    printer,
    //                    actionToken
    //                }
    //            });

    //        })
    //        .catch(function (error) {
    //            console.error("Error updating printer: " + error.name + ': ' + error.message);
    //            dispatch({
    //                type: SAVE_PRINTER_FAILURE,
    //                payload: {
    //                    error,
    //                    actionToken
    //                }
    //            });
    //        });
    //}
};

export const deletePrinterAction = (printerMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_PRINTER_REQUEST,
        payload: {
            printerMsbId,
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/Printers/" + printerMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_PRINTER_SUCCESS,
                payload: {
                    printerMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            console.error("Error deleting printer: " + error.name + ': ' + error.message);
            dispatch({
                type: DELETE_PRINTER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
    
};

const connectPrinter = (printer: Printer, actionToken: string, dispatch: Dispatch<any>) => {
    const url = "https://" + printer.ipAddress + "/webconfig-i/cgi/a_cpssio.php";

    var data = new FormData();
    data.append("method", "get");
    data.append("json", '{"cpssio":{"ServerDirectPrint":{},"StatusNotification":{}}}');
    
    // const data = {
    //     method: "get",
    //     json: {
    //         cpssio: {
    //             ServerDirectPrint: {},
    //             StatusNotification: {}
    //         }
    //     }
    // };

    const credentials = btoa(printer.userName + ":" + printer.password);
    const config = {
        auth: {
            username: printer.userName,
            password: printer.password
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };

    axios.post(url, data, config)
        .then(function (response) {
            inspectPrinter(response.data, printer, actionToken, dispatch);
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_PRINTER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

const inspectPrinter = (connResponse: any, printer: Printer, actionToken: string, dispatch: Dispatch<any>) => {
    const printerId = connResponse?.cpssio?.ServerDirectPrint?.serverDirectPrint?.ID;
    const printerName = connResponse?.cpssio?.ServerDirectPrint?.serverDirectPrint?.Name;

    const url = "/MSB_Client/api/v1/Printers";
    printer.serial = printer.password;
    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;

    axios.post(url, printer)
        .then(function (response) {
            // Printer has already been provisioned with this username!
            let resPrinterId = response.data.printerReference; //get printer id
            configurePrinter(resPrinterId, response.data, actionToken, dispatch);
            dispatch({
                type: SAVE_PRINTER_SUCCESS,
                payload: {
                    printer,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            configurePrinter(printerId, printer, actionToken, dispatch);
        });
};

const configurePrinter = (printerId: string, printer: Printer, actionToken: string, dispatch: Dispatch<any>) => {
    const url = "http://" + printer.ipAddress + "/webconfig-i/cgi/a_cpssio.php";
    let printerName = Printer.name;
    var data = new FormData();
    data.append("method", "set");
    data.append("json", '{"cpssio": {"ServerDirectPrint": {"serverDirectPrint": {"Active": "1", "CutReserveTimeout": "2", "ErrorType": "Warning", "ID":"' +  printerId + '", "Interval1": "5", "Interval2": "5", "Interval3": "5", "Name": "' + printerName + '", "Password": "", "Url1": "' + store.getState().webAppSettings.baseApiUrl + '/api/Printer/SpoolPrintJob", "Url2": "", "Url3": "", "UrlEncode": "1", "UseProxy": "0", "UseServerAuthentication": "0" }}, "StatusNotification": {"statusNotification": { "Active": "1", "ErrorType": "Warning", "ID": "' + printerId + '", "Interval": "15", "Name": "' + printerName + '", "Password": "", "Url": "' + store.getState().webAppSettings.baseApiUrl + '/api/Printer/UpdatePrinterStatus", "UrlEncode": "1", "UseProxy": "0", "UseServerAuthentication": "0" }}}}');

    // const data = {
    //     "method": "set",
    //     "json": {
    //         cpssio: {
    //             ServerDirectPrint: {
    //                 serverDirectPrint: {
    //                     Active: 1,
    //                     CutReserveTimeout: 2,
    //                     ErrorType: "Warning",
    //                     ID: printerId,
    //                     Interval1: 5,
    //                     Interval2: 5,
    //                     Interval3: 5,
    //                     Name: "prnName",
    //                     Password: "",
    //                     Url1: store.getState().webAppSettings.baseApiUrl + "/MSB_Print/api/v1/Printer/SpoolPrintJob",
    //                     Url2: "",
    //                     Url3: "",
    //                     UrlEncode: 1,
    //                     UseProxy: 0,
    //                     UseServerAuthentication: 0
    //                 }
    //             },
    //             StatusNotification: {
    //                 statusNotification: {
    //                     Active: 1,
    //                     ErrorType: "Warning",
    //                     ID: printerId,
    //                     Interval: 15,
    //                     Name: "prnName",
    //                     Password: "",
    //                     Url: "",
    //                     UrlEncode: 1,
    //                     UseProxy: 0,
    //                     UseServerAuthentication: 0
    //                 }
    //             }
    //         }
    //     }
    // };

    const credentials = btoa(printer.userName + ":" + printer.password);
    const config = {
        headers: {
            Authorization: `Basic ${credentials}`,
            "Content-Type": "application/x-www-form-urlencoded",
            "cache-control": "no-cache"
        }
    };

    axios.post(url, data, config)
        .then(function (response) {
            printer = response.data;
            cyclePrinter(printerId, response.data, actionToken, dispatch);
            dispatch({
                type: SAVE_PRINTER_SUCCESS,
                payload: {
                    printer,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_PRINTER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

const cyclePrinter = (printerId: string, printer: Printer, actionToken: string, dispatch: Dispatch<any>) => {

    const url = "http://" + printer.ipAddress + "/webconfig-i/cgi/a_request_to_core0.php";

    var data = new FormData();
    data.append("cmd", "MODEIN");

    const credentials = btoa(printer.userName + ":" + printer.password);
    const config = {
        headers: {
            Authorization: `Basic ${credentials}`,
            "Content-Type": "application/x-www-form-urlencoded",
            "cache-control": "no-cache"
        }
    };

    axios.post(url, data, config)
        .then(function (response) {
            printer = response.data;
            recyclePrinter(printerId, response.data, actionToken, dispatch);
            dispatch({
                type: SAVE_PRINTER_SUCCESS,
                payload: {
                    printer,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_PRINTER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

const recyclePrinter = (printerId: string, printer: Printer, actionToken: string, dispatch: Dispatch<any>) => {

    const url = "http://" + printer.ipAddress + "/webconfig-i/cgi/a_request_to_core0.php";

    var data = new FormData();
    data.append("cmd", "MODEOUT");

    const credentials = btoa(printer.userName + ":" + printer.password);
    const config = {
        headers: {
            Authorization: `Basic ${credentials}`,
            "Content-Type": "application/x-www-form-urlencoded",
            "cache-control": "no-cache"
        }
    };
    
    axios.post(url, data, config)
        .then(function (response) {
            printer = response.data;            
            dispatch({
                type: SAVE_PRINTER_SUCCESS,
                payload: {
                    printer,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_PRINTER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};


export const printPrinterReceipt = (printer: Printer, receiptWrapper: ReceiptWrapper, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: PRINT_PRINTER_REQUEST,
        payload: { actionToken }
    });
    
    const url = "http://" + printer.ipAddress + "/api/v1/Printer/AddPrinterJob";

    let printName = Printer.name;
   
    axios.post(url, receiptWrapper)
        .then(function (response) {
            let printer = response.data;
            dispatch({
                type: PRINT_PRINTER_SUCCESS,
                payload: {
                    printer,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: PRINT_PRINTER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};