import axios from 'axios';
import Approval from '../../models/Approval'
import { Dispatch } from 'react';
import { IPageableResults, PageableResults } from "../../models/PageableResults"
import store from "../../redux/store";

export const GET_HISTORY_APPROVALS_REQUEST = 'GET_HISTORY_APPROVALS_REQUEST';
export const GET_HISTORY_APPROVALS_SUCCESS = 'GET_HISTORY_APPROVALS_SUCCESS'
export const GET_HISTORY_APPROVALS_FAILURE = 'GET_HISTORY_APPROVALS_FAILURE';

export const getHistoryApprovalsAction = (page:number, pageSize:number) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_HISTORY_APPROVALS_REQUEST
    });

    let results: IPageableResults<Approval> = new PageableResults<Approval>();
    results.page = page;
    results.totalRecords = 0;
    let start:number = 0;
    let end: number = pageSize;

    if (page > 1) {
        start = (page - 1) * pageSize;
        end = start + pageSize; 
    }

    let _url = "/MSB_Approval/api/v1/Approvals?page=" + page + "&pageSize=" + pageSize;
    axios.get(_url)
        .then(function (response) {
            results.data = response.data.data;
            results.totalRecords = response.data.total;
            dispatch({
                type: GET_HISTORY_APPROVALS_SUCCESS,
                payload: {
                    results
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_HISTORY_APPROVALS_FAILURE,
                payload: {
                    error
                }
            });
        })
        .then(function () {
        }); 

}
