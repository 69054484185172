export const ClientRoles = [
    {
        title: 'Report Management',
        key: 'reportManagementRole',
    },
    {
        title: 'Payments & Transactions',
        key: 'transactionManagementRole',
    },
    {
        title: 'User Management',
        key: 'userManagementRole',
    },   
]