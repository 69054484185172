import { PaymentTypeEnum } from "./Client";
import { PaymentTypeEnum as PaymentType } from "./Payment";

//OrderPayment / Order
export class OrderPayment {
    msbId?: string;
    createdAt : string = "";
    orderTransactionId: number = 0;
    //orderTransaction?: OrderTransaction;
    
    orderId: string = "";               //k-r
    clientId?: string;                  //k-r
    departmentId?: string;              //k-r
    paymentChannelId?: string;          //k-r
    paymentType?: PaymentType = PaymentType.Unknown;          //k-r
    transactionType?: TransactionTypeEnum = TransactionTypeEnum.Unknown;          //k-r
    amount: number = 0;                 //k-r
    convenienceFee: number = 0;         //k-r
    totalAmount: number = 0;            //k-r    

    processorType: ProcessorTypeEnum = ProcessorTypeEnum.Unknown;       //k-r

    pinVerified: boolean = false;

    referenceNumber: string = "";
    merchantIdentifier: string = "";
    nameOnCard: string = "";
    lastFourOnCard: string = "";
    cardLogo: string = "";
    applicationIdentifier: string = "";
    applicationPreferredName: string = "";
    applicationLabel: string = "";
    tags: string = "";
    hostResponseCode: string = "";
    hostResponseMessage: string = "";
    expressResponseCode: string = "";
    terminalIdentifier: string = "";
    accountNumbers: string = "";
    vantivPaymentType: string = "";
    entry: string = "";
    transactionIdentifier: string = "";
    approvalNumber: string = "";
    routingNumber: string = "";
    accountHolderName: string = "";
    accountNumberLastFour: string = "";

    signatureStatus: string = "";    //k-r
    signatureFormat: string = "";    //k-r
    signatureDataLength: number = 0;     //k-r


    // updatedAt : string = "";
    paymentMethod: PaymentTypeEnum = PaymentTypeEnum.Unknown;
    paymentReferenceId: number = 0;
    orderLineId: number = 0;
    orderLine?: OrderLine;
}

export class OrderTransaction {
    msbId?: string;
    createdAt : string = "";
    transactionIdentifier: string = "";
    orderIdentifier: string = "";
    nameOnCard: string = "";
    lastFourOnCard: string = "";
    originalAmount: number = 0;
    remainingBalance: number = 0;
    orderSummaryId: number = 0;
    orderSummary: OrderSummary = new OrderSummary();
    orderStatus: OrderStatusEnum = OrderStatusEnum.Unknown;
    paymentType: PaymentTypeEnum = PaymentTypeEnum.Unknown;
    transactionType: TransactionTypeEnum = TransactionTypeEnum.Unknown;
    chargeBackStatus: ChargeBackStatusEnum = ChargeBackStatusEnum.Pending;
    amount: number = 0;
    totalAmount: number = 0;
    convenienceFee: number = 0;
    convenienceFeeIsClientAbsorbed: boolean = false;
    orderNotes: string = "";
    initiatedBy: string = "";
    email: string = "";
    phone: string = "";
    addressLine1: string = "";
    addressLine2: string = ""; 
    city: string = "";
    state: string = "";
    zip: string = "";
    clientMetadata: string = "";  
    processorMessage: string = ""; // K-R
    reason: string = "";
    vantivTransactionReferenceNumber: string = ""; //K-R
    orderPayment: OrderPayment = new OrderPayment();
    orderLines: Array<OrderLine> = new Array<OrderLine>();
    //localCreatedAt: string= "";
    isNotChargeBackTransaction: boolean = false;
    isChargeBackTransaction: boolean = false;
    comments : Array<TransactionComment> = new Array<TransactionComment>();
    files : Array<TransactionFile> = new Array<TransactionFile>();
    chargeBackDueDate: string = "";
}

export class OrderLine {
    msbId?: string;
    createdAt : string = "";
    orderTransactionId: number = 0;
    //orderTransaction: OrderTransaction = new OrderTransaction(); //K - R
    itemId?: string;
    itemName: string = "";
    itemReferenceNumber: string = "";
    unitPrice: number = 0;
    quantity: number = 0;
}

export class OrderSummary {
    msbId?: string;
    createdAt : string = "";
    clientId?: string;
    departmentId?: string;
    paymentChannelId?: string;
    orderIdentifier: string = "";
    isImmutable: boolean = false;
    originalTransactionDate: string = ""; //K-R
    originalAmount: number = 0; //K-R
    remainingBalance: number = 0; //k-r
    orderTransactions?: Array<OrderTransaction>;
    isDebitCardTransaction?: boolean = false;
    internalCardType: string = "";
    laneId?: number = 0;
    paymentMethodOnFileReference?: string;
    //orderTransactions: Array<OrderTransaction> = new Array<OrderTransaction>(); //k-r
}

export class TransactionComment {
    msbId?: string;
    createdAt : string = "";
    //orderTransactionId: number = 0; //k-r
    //orderTransaction?: OrderTransaction = new OrderTransaction(); //k-r
    description : string = "";    
}

export class TransactionFile {
    msbId?: string;
    createdAt : string = "";
    //orderTransactionId: number = 0; //k-r
    //orderTransaction?: OrderTransaction = new OrderTransaction(); //k-r
    fileName : string = "";
    //fileData? : Blob;   
}

export class DisputeComment {    
    description : string = "";    
}

//name PaymentMethodTypeEnum / PaymentTypeEnum
// export enum PaymentTypeEnum {
//     Unknown = 0,
//     CreditCard = 1,
//     ECheck = 2
// }

/*
export enum PaymentTypeEnum {
    Unknown = "Unknown",
    CreditCard = "CreditCard",
    ECheck = "ECheck"
}
*/

export enum TransactionTypeEnum {
    Unknown = 0,
    Sale = 1,
    Void = 2,
    Refund = 3,
    Chargeback = 4,
    Return = 5,
    Authorization = 6,
    AuthorizationCommit = 7,
    CreateToken = 8
}

export enum ProcessorTypeEnum {
    Unknown         = 0, // Default error case
    VantivTriPos    = 1,
    VantivExpress   = 2,
    ElectronicCheck = 3,
    PayPal          = 4,
    AuthorizeDotNet = 5
}

//name OrderStatus / OrderStatusEnum
// export enum OrderStatusEnum {
//     Unknown = 0, 
//     Pending = 1,
//     Success = 2,
//     Declined = 3,
//     Error = 4,
//     Overridden = 5 //K-r
// }

export enum OrderStatusEnum {
    Unknown = "Unknown", 
    Pending = "Pending",
    Success = "Success",
    Declined = "Declined",
    Error = "Error",
    Overridden = "Overridden" //K-r
}

//ChargeBackStatusEnum / ChargeBackStatus
// export enum ChargeBackStatusEnum {
//     Undisputed       = 0,
//     Pending          = 1,
//     NavientFavor     = 2,
//     ConstituentFavor = 3
// // Undisputed       = "Undisputed",
// // Pending          = "Pending",
// // NavientFavor     = "NavientFavor",
// // ConstituentFavor = "ConstituentFavor"
// }

export enum ChargeBackStatusEnum {
    Undisputed = "Undisputed",
    Pending = "Pending",
    Disputed = "Disputed",
    NavientFavor = "NavientFavor",
    ClientFavor = "ClientFavor"
}