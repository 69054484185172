import React from "react";
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import MerchantDetailsStep from "./components/addClientSteps/MerchantDetailsStep";
import { Client } from '../../models/Client';
import { IAppState } from '../../redux/storeTypes';
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Crumb } from "../../models/Crumb";
import { Routes } from "../../routes";
import { resetClientsStoreAction } from "../../redux/actions/clients/clients";

export interface IMerchantAddProps {
    client: Client
}

const MerchantAdd = ({ client }: IMerchantAddProps) => {
    if (client) {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path, resetClientsStoreAction("MerchantAdd")));
        crumbs.push(new Crumb(client.businessName, Routes.Client.path));
        crumbs.push(new Crumb("Add Merchant", Routes.MerchantAdd.path));
        return (
            <>
                <PageHeader title={"Add Merchant"} crumbs={crumbs} />
                <Container fluid>
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <MerchantDetailsStep />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>                
            </>
        );
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path, resetClientsStoreAction("MerchantAdd")));
        crumbs.push(new Crumb("Add Merchant", Routes.MerchantAdd.path));
        return (
            <>
                <PageHeader title={"Add Merchant"} />
                <Container>
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <MerchantDetailsStep />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>                
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client
    };
};

export default connect(mapStateToProps)(MerchantAdd);