import React from "react";
import PageSectionContainer from "../../components/layout/PageSectionContainer";
import { paymentsFormSchema } from './components/AdvancedSearchColumns';
import Report from './components/Report'


const TrendingDataReport = () => {
    const reportTitle = 'Trending Data Report';
    const reportType = 'Trending Data';
    const transactionType = 0;
    return (
        <>
            <PageSectionContainer title={`Trending data report under construction.`} ></PageSectionContainer>
            {/*
           <Report
                transactionType={transactionType}
                reportTitle={reportTitle}
                reportType={reportType}
                formSchema={paymentsFormSchema}/>
            */}
        </>
    );
};

export default TrendingDataReport;

