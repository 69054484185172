import axios from 'axios';
import { Client, Contact, Department } from '../../../models/Client';
import { Dispatch } from 'react';

export const ADD_CLIENT_CONTACT_REQUEST = 'ADD_CLIENT_CONTACT_REQUEST';
export const ADD_CLIENT_CONTACT_SUCCESS = 'ADD_CLIENT_CONTACT_SUCCESS';
export const ADD_CLIENT_CONTACT_FAILURE = 'ADD_CLIENT_CONTACT_FAILURE';
export const SAVE_CLIENT_CONTACT_REQUEST = 'SAVE_CLIENT_CONTACT_REQUEST';
export const SAVE_CLIENT_CONTACT_SUCCESS = 'SAVE_CLIENT_CONTACT_SUCCESS';
export const SAVE_CLIENT_CONTACT_FAILURE = 'SAVE_CLIENT_CONTACT_FAILURE';
export const DELETE_CLIENT_CONTACT_REQUEST = 'DELETE_CLIENT_CONTACT_REQUEST';
export const DELETE_CLIENT_CONTACT_SUCCESS = 'DELETE_CLIENT_CONTACT_SUCCESS';
export const DELETE_CLIENT_CONTACT_FAILURE = 'DELETE_CLIENT_CONTACT_FAILURE';

export const ADD_DEPARTMENT_CONTACT_REQUEST = 'ADD_DEPARTMENT_CONTACT_REQUEST';
export const ADD_DEPARTMENT_CONTACT_SUCCESS = 'ADD_DEPARTMENT_CONTACT_SUCCESS';
export const ADD_DEPARTMENT_CONTACT_FAILURE = 'ADD_DEPARTMENT_CONTACT_FAILURE';
export const SAVE_DEPARTMENT_CONTACT_REQUEST = 'SAVE_DEPARTMENT_CONTACT_REQUEST';
export const SAVE_DEPARTMENT_CONTACT_SUCCESS = 'SAVE_DEPARTMENT_CONTACT_SUCCESS';
export const SAVE_DEPARTMENT_CONTACT_FAILURE = 'SAVE_DEPARTMENT_CONTACT_FAILURE';
export const DELETE_DEPARTMENT_CONTACT_REQUEST = 'DELETE_DEPARTMENT_CONTACT_REQUEST';
export const DELETE_DEPARTMENT_CONTACT_SUCCESS = 'DELETE_DEPARTMENT_CONTACT_SUCCESS';
export const DELETE_DEPARTMENT_CONTACT_FAILURE = 'DELETE_DEPARTMENT_CONTACT_FAILURE';

export const ADD_MERCHANT_CONTACT_REQUEST = 'ADD_MERCHANT_CONTACT_REQUEST';
export const ADD_MERCHANT_CONTACT_SUCCESS = 'ADD_MERCHANT_CONTACT_SUCCESS';
export const ADD_MERCHANT_CONTACT_FAILURE = 'ADD_MERCHANT_CONTACT_FAILURE';
export const SAVE_MERCHANT_CONTACT_REQUEST = 'SAVE_MERCHANT_CONTACT_REQUEST';
export const SAVE_MERCHANT_CONTACT_SUCCESS = 'SAVE_MERCHANT_CONTACT_SUCCESS';
export const SAVE_MERCHANT_CONTACT_FAILURE = 'SAVE_MERCHANT_CONTACT_FAILURE';
export const DELETE_MERCHANT_CONTACT_REQUEST = 'DELETE_MERCHANT_CONTACT_REQUEST';
export const DELETE_MERCHANT_CONTACT_SUCCESS = 'DELETE_MERCHANT_CONTACT_SUCCESS';
export const DELETE_MERCHANT_CONTACT_FAILURE = 'DELETE_MERCHANT_CONTACT_FAILURE';

export const addClientContactAction = (clientMsbId: string, contact: Contact, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_CLIENT_CONTACT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/ClientContacts/addToClient?clientId=" + clientMsbId;
    let client = new Client();

    axios.post(_url, contact)
        .then(function (response) {
            client = response.data;
            dispatch({
                type: ADD_CLIENT_CONTACT_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: ADD_CLIENT_CONTACT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveClientContactAction = (contact:Contact, actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CLIENT_CONTACT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/ClientContacts/" + contact.msbId;

    axios.put(_url, contact)
        .then(function (response) {
            contact = response.data;
            dispatch({
                type: SAVE_CLIENT_CONTACT_SUCCESS,
                payload: {
                    contact,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_CLIENT_CONTACT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const deleteClientContactAction = (contactMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_CLIENT_CONTACT_REQUEST,
        payload: {
            contactMsbId,
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/ClientContacts/" + contactMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_CLIENT_CONTACT_SUCCESS,
                payload: {
                    contactMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_CLIENT_CONTACT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const addDepartmentContactAction = (departmentMsbId: string, contact: Contact, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_DEPARTMENT_CONTACT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/DepartmentContacts/addToDepartment?departmentId=" + departmentMsbId;
    let department = new Department();

    axios.post(_url, contact)
        .then(function (response) {
            department = response.data;
            dispatch({
                type: ADD_DEPARTMENT_CONTACT_SUCCESS,
                payload: {
                    department,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: ADD_DEPARTMENT_CONTACT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveDepartmentContactAction = (contact: Contact, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_DEPARTMENT_CONTACT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/DepartmentContacts/" + contact.msbId;

    axios.put(_url, contact)
        .then(function (response) {
            contact = response.data;
            dispatch({
                type: SAVE_DEPARTMENT_CONTACT_SUCCESS,
                payload: {
                    contact,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_DEPARTMENT_CONTACT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const deleteDepartmentContactAction = (contactMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_DEPARTMENT_CONTACT_REQUEST,
        payload: {
            contactMsbId,
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/DepartmentContacts/" + contactMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_DEPARTMENT_CONTACT_SUCCESS,
                payload: {
                    contactMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_DEPARTMENT_CONTACT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const addMerchantContactAction = (contact: Contact, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_MERCHANT_CONTACT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/MerchantContacts"
    let client = new Client();

    axios.post(_url, contact)
        .then(function (response) {
            client = response.data;
            dispatch({
                type: ADD_MERCHANT_CONTACT_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: ADD_MERCHANT_CONTACT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveMerchantContactAction = (contact: Contact, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_MERCHANT_CONTACT_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/MerchantContacts/" + contact.msbId;

    axios.put(_url, contact)
        .then(function (response) {
            contact = response.data;
            dispatch({
                type: SAVE_MERCHANT_CONTACT_SUCCESS,
                payload: {
                    contact,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_MERCHANT_CONTACT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const deleteMerchantContactAction = (contactMsbId: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_MERCHANT_CONTACT_REQUEST,
        payload: {
            contactMsbId,
            actionToken
        }
    });

    let _url = "/MSB_Client/api/v1/MerchantContacts/" + contactMsbId;

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_MERCHANT_CONTACT_SUCCESS,
                payload: {
                    contactMsbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_MERCHANT_CONTACT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};