import _ from 'lodash';
import { PaymentCardTypeEnum } from '../models/Payment';
import store from "../redux/store";

export const PaymentCardType = (bin: string) => {
    const DerivedIssuer = (bin: any) => {
        if (bin.substr(0, 1) == '4') {
            return 'VisaCredit';
        } else if (bin.substr(0, 2) == '34' || bin.substr(0, 2) == '37') {
            return 'AmericanExpressCredit';
        } else if (bin.substr(0, 4) == '6011' || bin.substr(0, 2) == '64' || bin.substr(0, 2) == '65') {
            return 'DiscoverCredit';
        } else if ((bin.substr(0, 2) >= 51 && bin.substr(0, 2) <= 55) || (bin.substr(0, 6) >= 222100 && bin.substr(0, 6) <= 272099)) {
            return 'MastercardCredit';
        } else {
            return 'Unknown';
        }
    }

    const Issuer = (type: any, scheme: any) => {
        type = (type && (type.toUpperCase() === "DEFERRED_DEBIT" || type.toUpperCase() === "CREDIT" || type.toUpperCase() === "CHARGE")) ? "Credit" : "Debit";
        let word = _.camelCase(scheme) + _.startCase(_.toLower(type || 'Debit'));
        let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalized;
    }

    const cardOrder = [ "debit", "prepaid", "prepaid_reloadable", "credit", "deferred_debit", "charge" ];

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let bearerToken = store.getState().webAppSettings.bearerToken;
    let url = `${baseApiUrl}/MSB_BinLookup/api/v1/BinLookup?iin=${bin.substring(0, 8)}`

    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${bearerToken}`
        },
    })
    .then(response => {
        if (response.ok) return response.json();
        throw new Error(response.status.toString());
    })
    .then(data => {
        console.log("INN OK", data);
        if (data?.count) {
            let cards = [...data._embedded.cards].sort((a: any, b: any) => { return cardOrder.indexOf(a.account.funding) - cardOrder.indexOf(b.account.funding) });
            return PaymentCardTypeEnum[Issuer(cards[0].account.funding, cards[0].scheme.name) as keyof typeof PaymentCardTypeEnum];
        } else {
            return PaymentCardTypeEnum[DerivedIssuer(bin) as keyof typeof PaymentCardTypeEnum];
        }
    })
    .catch((error) => {
        console.log("BIN ERROR", error);
        return PaymentCardTypeEnum[DerivedIssuer(bin) as keyof typeof PaymentCardTypeEnum];
    });
};

