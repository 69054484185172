import React, { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import RequiredIcon from "../../../../../components/RequiredIcon";
import { Client, Department, Contact, ContactTypeEnum } from "../../../../../models/Client";
import Inputmask from "react-input-mask";
import FormActions, { IFormActionProps } from '../FormActions';
import { useDispatch } from "react-redux";
import { addDepartmentContactAction, saveDepartmentContactAction } from '../../../../../redux/actions/clients/contacts';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

export interface IDepartmentContactsFormProps {
    isSaving: boolean,
    isFetching: boolean,
    client: Client,
    department: Department,
    contact?: Contact,
    actionToken: string,
    formActionProps?: IFormActionProps
}

const DepartmentContactForm = ({ isSaving, isFetching, client, department, contact, actionToken, formActionProps }: IDepartmentContactsFormProps) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState<boolean>(false);

    const [formAddAction, setFormAddAction] = useState(formActionProps?.customProps.submitButtonDisplay == 'Add');

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            let _contact: Contact = Object.assign({}, contact!!);
            _contact.contactReferenceName = form.elements.contactReferenceName.value;
            _contact.contactType = ContactTypeEnum[form.elements.contactTypeEnum.value] as keyof typeof ContactTypeEnum;
            _contact.firstName = form.elements.firstName.value;
            _contact.lastName = form.elements.lastName.value;
            _contact.phoneNumber = form.elements.phoneNumber.value.replace(/\D/g, "");
            _contact.faxNumber = form.elements.faxNumber.value.replace(/\D/g, "");
            _contact.emailAddress = form.elements.emailAddress.value;

            if (formAddAction) {
                dispatch(addDepartmentContactAction(department.msbId!, _contact, actionToken));
            } else {
                dispatch(saveDepartmentContactAction(_contact, actionToken));
            }
        }
        setValidated(true);
    };

    return (
        <>
            <Form id="department-contact-form" noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col lg={6} sm={12}>
                        <Form.Group controlId="contactReferenceName">
                            <Form.Label><RequiredIcon />Contact reference name</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="input" disabled={isSaving} defaultValue={contact?.contactReferenceName} maxLength={40} placeholder="Enter Contact reference name" />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a Contact reference name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Group controlId="contactTypeEnum">
                            <Form.Label><RequiredIcon />Contact type</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required as="select" defaultValue={ContactTypeEnum[contact?.contactType as keyof typeof ContactTypeEnum]}>
                                    <option value={ContactTypeEnum.CustomerService}>Customer Service</option>
                                    <option value={ContactTypeEnum.TerminalDeployment}>Terminal Deployment</option>
                                    <option value={ContactTypeEnum.Primary}>Primary</option>
                                </Form.Control>
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please select a Contact type.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12}>
                        <Form.Group controlId="firstName">
                            <Form.Label><RequiredIcon />First name</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="input" disabled={isSaving} defaultValue={contact?.firstName} maxLength={40} placeholder="Enter First name" />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a First name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Group controlId="lastName">
                            <Form.Label><RequiredIcon />Last name</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="input" disabled={isSaving} defaultValue={contact?.lastName} maxLength={40} placeholder="Enter Last name" />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a Last name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12}>
                        <Form.Group controlId="phoneNumber">
                            <Form.Label><RequiredIcon />Phone number</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Inputmask disabled={isSaving} required type="input" defaultValue={contact?.phoneNumber} id="phoneNumber" pattern="[0-9\-\(\)\s]{14}" name="phoneNumber" className="form-control" mask="(999) 999-9999" placeholder="(999) 999-9999" />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a Phone number.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Group controlId="emailAddress">
                            <Form.Label><RequiredIcon />Email address</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="email" disabled={isSaving} defaultValue={contact?.emailAddress} placeholder="email@address.com" maxLength={100} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter an Email address.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12}>
                        <Form.Group controlId="faxNumber">
                            <Form.Label>Fax number</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Inputmask disabled={isSaving} id="faxNumber" name="faxNumber" defaultValue={contact?.faxNumber} className="form-control" type="input" pattern="[0-9\-\(\)\s]{14}" mask="(999) 999-9999" placeholder="(999) 999-9999" />
                            </ReactPlaceholder>
                        </Form.Group>
                    </Col>
                </Row>
                <FormActions
                    disabled={isSaving || isFetching}
                    showSpinner={isSaving}
                    {...formActionProps} />
            </Form>
        </>
    );
};

export default DepartmentContactForm;
