import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { Dropdown } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import moment  from 'moment';
import 'moment-timezone';
import { Routes } from "../../../routes";
import { getDynamicQueryReportDeclinedPayments } from "../../../redux/actions/reports/report";
import { IAppState } from "../../../redux/storeTypes";
import { Client } from "../../../models/Client";
import { ReportSummaryDeclinedPayments } from "../../../models/Reports";
import Chart from 'react-apexcharts';
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";

interface IDeclinedCardsWidget {
    client?: Client,
    summaryDeclinedTransactions: Array<ReportSummaryDeclinedPayments>
}

export const DeclinedCardsWidget = ({client, summaryDeclinedTransactions}:IDeclinedCardsWidget) => {
  const dispatch = useDispatch();
  const actionToken = "DeclinedCardsWidget";
	const [redirect, setRedirect] = useState<string>("");

  const [series, setSeries] = useState<Array<any>>([]);
  const [options, setOptions] = useState<any>({});

  const today = moment();
	const previousWeeK = Array(7).fill(7).map(
		() => today.subtract(1, 'd').format('YYYY-MM-DD')
	);
	let fromDate = moment(previousWeeK[6]).format('YYYY-MM-DD HH:mm:ss');
	let endDate = moment(previousWeeK[0]).format('YYYY-MM-DD HH:mm:ss');

    useEffect(() => {
        doSetOptions();
        doSetSeries();

        if (summaryDeclinedTransactions.length === 0) {
            let createdAt = `${fromDate}<->${endDate}`;
            dispatch(getDynamicQueryReportDeclinedPayments(createdAt,actionToken));
        }
    }, []);
    	
    let countVisa : number = 0;
    let countMasterCard : number = 0;
    let countAmericanExpress : number = 0;
    let countDiscover : number = 0;

    useEffect(() => {
        if (summaryDeclinedTransactions) {
            const sortedItems = summaryDeclinedTransactions.slice().sort((a, b) => Date.parse(b.transactionDate) - Date.parse(a.transactionDate));

            for (let x=0; x < sortedItems.length;x++) {
                countVisa += sortedItems[x].visa;
                countMasterCard += sortedItems[x].masterCard;
                countAmericanExpress += sortedItems[x].americanExpress;
                countDiscover += sortedItems[x].discover;
            }   

            doSetOptions();
            doSetSeries();
        }
    }, [summaryDeclinedTransactions]);


    const doSetOptions = () => {
        let _options = {
            labels: ['Visa', 'Amex', 'Discover', 'Mastercard'],
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom'
                    },
                }
            }],
            theme: {
              mode: 'light'
            },
            dataLabels: {
              enabled: true,
              dropShadow: {
                enabled: false,
              },
              style: {
                fontSize: '12px',
                fontFamily: 'Lato, sans-serif',
                fontWeight: '400',
                color: '#414141',
              },
              background: {
                enabled: true,
                foreColor: '#414141',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 1,
                dropShadow: {
                  enabled: false,
                }
              },
            },
            tooltip: {
              enabled: true,
              style: {
                fontFamily: 'Lato, sans-serif',
              }
            },
            fill: {
              colors: ['#52C41A', '#00AEEF', '#F5222D', '#FADB14']
            },
            legend: {
              show: true,
              position: 'bottom',
              horizontalAlign: 'center',
              fontFamily: 'Lato, sans-serif',
              labels: {
                colors: '#414141'
              },
              markers: {
                width: 8,
                height: 8,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: ['#52C41A', '#00AEEF', '#F5222D', '#FADB14'],
                radius: 8,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
              },
            },
            states: {
              normal: {
                filter: {
                  type: 'none',
                  value: 0,
                }
              },
              hover: {
                filter: {
                  type: 'darken',
                  value: 0.9,
                }
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: 'darken',
                  value: 0.9,
                }
              },
            }
        }

        setOptions(_options);
    }

    const doSetSeries = () => {
        let _series = [countVisa, countAmericanExpress, countDiscover, countMasterCard];
        setSeries(_series);
    }

	if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
	return (
		<Card className="dashboard-widget">
			<Card.Header>
				<h2>Declined Payments</h2>
				<Dropdown>
					<Dropdown.Toggle variant="link" id="view-report-dropdown">
            <FontAwesomeIcon icon={faEllipsisH} />
          </Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onSelect={() => {
									setRedirect(Routes.DeclinedReport.path);
								}}
							>
								View Report
							</Dropdown.Item>
						</Dropdown.Menu>
				</Dropdown>
			</Card.Header>
			<Card.Body style={{ overflow: 'auto' }}>
				<small>Previous 7 days</small>
                <Chart
                    options={options}
                    series={series}
                    type="donut"
                />
			</Card.Body>
		</Card>
	);
	}
}

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client,
        summaryDeclinedTransactions: state.reports.summaryDeclinedTransactions
    };
};

export default connect(mapStateToProps)(DeclinedCardsWidget);