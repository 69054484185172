import React from "react";
import ActionTabs from '../../../accessGlobalComponents/ActionTabs'
import { connect } from "react-redux";
import { UserTypeEnum } from '../../../../../models/User'
import { IAppState } from '../../../../../redux/storeTypes';
import _ from "lodash";

const Clients = ({ role, modulePermissions }: any) => {

    if (modulePermissions) {

  
        const roleDetails = role?.roleModulePermissions?.map((fields: any) => fields?.modulePermission)
       
        const reformatedArray = _.isEmpty(roleDetails)
            ?
            modulePermissions.map((fields: any) => ({ ...fields, selected: false }))
            :
            modulePermissions.map((basePermissions: any) => ({
                ...basePermissions,
                selected: roleDetails.some((selectedPermissions: any) => selectedPermissions.description === basePermissions.description && selectedPermissions.crudPermission === basePermissions.crudPermission),
            }))

        const ClientPermissions = _.groupBy(reformatedArray.filter((permissions: any) => permissions.tag === 'Client Management'), 'description')

        return (
            <>
                <div className="roleGrids">
                    {Object.entries(ClientPermissions).map(
                        ([index, value]: any) => {
                            return (
                                <div key={index}>
                                    <h3>{index}</h3>
                                    <ActionTabs navientRole={role} crudPermissions={value} roleType={UserTypeEnum.Navient} />
                                </div>
                            )
                        }
                    )}
                </div>
            </>
        );
    }
    else {
        return (
            <></>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        role: state.roles.role,
        modulePermissions: state.roles.modulePermissions
    };
};

export default connect(mapStateToProps)(Clients);