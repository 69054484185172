import { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import RequiredIcon from "../../../components/RequiredIcon";
import { connect, useDispatch } from "react-redux";
import { IAppState } from "../../../redux/storeTypes";
import { BankAccount, Card } from "../../../models/Wallets";
import { PaymentChannel } from "../../../models/Client";
import { getBankAccountAction, getCardAction } from "../../../redux/actions/wallets";
import Select from 'react-select';
import lodash from "lodash";

interface IPMoFPayment {
    isFetching: boolean;
    isSaving: boolean;
    isVisible: boolean;
    setReferencePMoF: any;
    paymentChannel: PaymentChannel;
    cards?: Array<Card>;
    accounts?: Array<BankAccount>;
}

const PMoFPayment = ({ isFetching, isSaving, isVisible, setReferencePMoF, paymentChannel, cards, accounts }: IPMoFPayment) => {
    const dispatch = useDispatch();
    const actionToken = "PMoFReport";
    const [options, setOptions] = useState<any>([])

    useEffect(() => {
        if (isVisible && paymentChannel) {
            if (paymentChannel.creditCardPMoFEnabled) dispatch(getCardAction(paymentChannel?.msbId!, actionToken));
            if (paymentChannel.eCheckPMoFEnabled) dispatch(getBankAccountAction(paymentChannel?.msbId!, actionToken));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentChannel, isVisible]);

    useEffect(() => {
        setOptions([]);
        console.log(cards);
        if (cards && cards.length > 0) {
            // eslint-disable-next-line array-callback-return
            cards.map((card, index) => {
                let cardType = card.internalCardType;
                if (card.internalCardType.substring(0, 3) !== card.vantivCardType.substring(0, 3) || card.vantivCardType.includes("Debit")) {
                    cardType = card.vantivCardType.includes("Debit") ? card.vantivCardType : `${card.vantivCardType}Credit`;
                    cardType = cardType.replace("Amex", "AmericanExpress");
                }
                setOptions((previous: any) => [...previous, { value: `${card.msbId}:${cardType}`, label: `${card.nameOnCard.toUpperCase()} - ${lodash.startCase(cardType).toUpperCase()} - xxxx-${card.lastFourOnCard} - ${card.expirationDate}` }])
            });
        }
        if (accounts && accounts.length > 0) {
            // eslint-disable-next-line array-callback-return
            accounts.map((account, index) => {
                setOptions((previous: any) => [...previous, { value: `${account.msbId}:${account.bankAccountType}`, label: `${account.accountHolderName.toUpperCase()} - ${account.bankAccountType.toUpperCase()} - xxxx${account.accountNumber.slice(-4)}` }])
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cards, accounts]);

    if (isVisible) {
        return (
            <>
                <Row>
                    <Form.Group controlId="pmofPayment">
                        <Form.Label><RequiredIcon />Payment Method on File Payment</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            onChange={(selected: any) => setReferencePMoF(selected.value)}
                            disabled={isFetching || isSaving}
                            options={options}
                            isSearchable={true}
                            className="react-select-container"
                            classNamePrefix="react-select"
                        />
                        <Form.Control.Feedback type="invalid">Please select a Payment Method on File.</Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </>
        )
    } else {
        return (<></>)
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        paymentChannel: state.clients.paymentChannel,
        cards: state.wallets.cards,
        accounts: state.wallets.bankAccounts,
    };
};

export default connect(mapStateToProps)(PMoFPayment);
