import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Department, PayPalCredentials } from '../../../../../models/Client';
import "react-placeholder/lib/reactPlaceholder.css";
import { IActionResult, IAppState } from "../../../../../redux/storeTypes";
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Switch from "react-switch";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { addPayPalCredInfoAction, ADD_PAYPALCREDINFO_FAILURE, ADD_PAYPALCREDINFO_REQUEST, ADD_PAYPALCREDINFO_SUCCESS, DELETE_PAYPALCREDINFO_FAILURE, DELETE_PAYPALCREDINFO_REQUEST, DELETE_PAYPALCREDINFO_SUCCESS, getPayPalCredInfoAction, savePayPalCredInfoAction, SAVE_PAYPALCREDINFO_FAILURE, SAVE_PAYPALCREDINFO_REQUEST, SAVE_PAYPALCREDINFO_SUCCESS } from "../../../../../redux/actions/clients/paymentChannels";
import { sendErrorToastAction, sendSuccessToastAction } from "../../../../../redux/actions/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import CurrencyInput from "../../../../../components/currency/CurrencyInput";
import { Routes } from "../../../../../routes";
import { Redirect } from "react-router-dom";
import { faExclamationTriangle, faSpinner } from "@fortawesome/pro-solid-svg-icons";

export interface IDepartmentPayPalFormProps {
    department: Department,
    paymentChannelMsbId: string,
    payPalCredential: PayPalCredentials,
    actionResult: IActionResult,
    isFetching: boolean,
    isSaving: boolean,
    onClose: any
}

const DepartmentPayPalForm = ({ department, paymentChannelMsbId, payPalCredential, actionResult, isFetching, isSaving }: IDepartmentPayPalFormProps) => {
    const actionToken = "PayPalCredInfo";
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [allowCard, setAllowCard] = useState(false);
    const [allowCredit, setAllowCredit] = useState(false);
    const [allowVenmo, setAllowVenmo] = useState(false);
    const [commission, setCommission] = useState<any>(0.00);
    const [getStatus, setGetStatus] = useState(false);
    const [constituentUrl, setConstituentUrl] = useState("");

    React.useEffect(() => {
        let paymentChannel = (department?.paymentChannels || []).find(pc => pc.msbId === paymentChannelMsbId);
        if (paymentChannel !== undefined) {
            let processor = paymentChannel.processors.find(p => p.merchantProcessor?.processor === "PayPal");
            if (processor !== undefined) {
                let msbId = processor.merchantProcessor?.payPalCredential?.msbId;
                dispatch(getPayPalCredInfoAction(msbId || "", actionToken));
            } else {
                dispatch(getPayPalCredInfoAction("", actionToken));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (payPalCredential) {
            setAllowCard(payPalCredential?.allowCard);
            setAllowCredit(payPalCredential?.allowCredit);
            setAllowVenmo(payPalCredential?.allowVenmo);
            setCommission(payPalCredential?.navientCommissionPercentage.toFixed(4) || 0.00);

            fetch(`${window.location.protocol}//${window.location.host}/PayPal/ConstituentUrl`)
            .then(response => response.text())
            .then(url => { setConstituentUrl(`${url}/${payPalCredential?.msbId}/PartnerReferral`)});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payPalCredential]);

    React.useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_PAYPALCREDINFO_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === ADD_PAYPALCREDINFO_SUCCESS) {
                    dispatch(sendSuccessToastAction("PayPal Credential Information has been successfully added."));
                } else if (actionResult.result === ADD_PAYPALCREDINFO_FAILURE) {
                    dispatch(sendErrorToastAction("PayPal Credential Information could not be added."));
                }
            }

            if (actionResult.type === SAVE_PAYPALCREDINFO_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_PAYPALCREDINFO_SUCCESS) {
                    dispatch(sendSuccessToastAction("PayPal Credential Information has been successfully updated."));
                } else if (actionResult.result === SAVE_PAYPALCREDINFO_FAILURE) {
                    dispatch(sendErrorToastAction("PayPal Credential Information could not be updated."));
                }
            }

            if (actionResult.type === DELETE_PAYPALCREDINFO_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === DELETE_PAYPALCREDINFO_SUCCESS) {
                    dispatch(sendSuccessToastAction("PayPal Credential Information has been successfully deleted."));
                } else if (actionResult.result === DELETE_PAYPALCREDINFO_FAILURE) {
                    dispatch(sendErrorToastAction("PayPal Credential Information could not be deleted."));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    const submitPayPalCredentials = (e: any) => {
        e.preventDefault();
        let _payPalCredential: PayPalCredentials = Object.assign(new PayPalCredentials(), payPalCredential!);
        _payPalCredential.allowCard = allowCard;
        _payPalCredential.allowCredit = allowCredit;
        _payPalCredential.allowVenmo = allowVenmo;
        _payPalCredential.navientCommissionPercentage = commission;

        if (payPalCredential?.msbId) {
            dispatch(savePayPalCredInfoAction(payPalCredential.msbId, _payPalCredential, actionToken));
        } else {
            dispatch(addPayPalCredInfoAction(paymentChannelMsbId, _payPalCredential, actionToken));
            setRedirect(Routes.Client.path);
        }
    }

    const handlerCopyToClipboard = (evt: any) => {
        navigator.clipboard.writeText(constituentUrl);
        dispatch(sendSuccessToastAction(`Copied!`));
    };

    const handlePercentageOnChange = (value: string | undefined) => {
        if (value) {
            value = value.replace("% ", "");
            if (!isNaN(Number(value))) {
                setCommission(Number(value));
            }
        }
    }

    const getSellerStatus = (e: any) => {
        e.preventDefault();
        setGetStatus(true);
        const url = `${window.location.protocol}//${window.location.host}/PayPal/SellerStatus`;
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                trackingId: payPalCredential.msbId,
                merchantId: payPalCredential.payPalMerchantIdentifier
            }),
        })
        .then(response => {
            if (response.ok) return response.json();
            throw new Error(response.status.toString());            
        })
        .then(data => {
            let _payPalCredential: PayPalCredentials = Object.assign(new PayPalCredentials(), payPalCredential!);
            _payPalCredential.isPaymentReceivable = data?.payments_receivable;
            _payPalCredential.isEmailConfirmed = data?.primary_email_confirmed;
            _payPalCredential.permissionGranted = data?.oauth_integrations.length > 0;
            _payPalCredential.consentStatus = data?.merchant_id.length > 0;
            _payPalCredential.payPalMerchantIdentifier = data?.merchant_id;
            dispatch(savePayPalCredInfoAction(payPalCredential.msbId, _payPalCredential, actionToken));
            setGetStatus(false);
        })
        .catch((error) => {
            dispatch(sendErrorToastAction("PayPal seller onboarding may have not been started... " + error));
            setGetStatus(false);
        });
    }

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <Form onSubmit={submitPayPalCredentials} key={payPalCredential?.msbId}>
                    {(payPalCredential?.msbId) ?
                        <div>
                            <Row>
                                <Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Partner Referral URL</Form.Label>
                                            <InputGroup>
                                                <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                                    <Form.Control type="input" placeholder="Auto generated after addition is saved" value={constituentUrl} maxLength={80} disabled={true} />
                                                    <OverlayTrigger placement={'top'} overlay={<Tooltip id={""}> Copy </Tooltip>} >
                                                        <Button variant="outline-secondary" id="btnCopyClipboard" onClick={(e) => handlerCopyToClipboard(e)}><FontAwesomeIcon icon={faCopy} /></Button>
                                                    </OverlayTrigger>
                                                </ReactPlaceholder>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="toggle-switch">
                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                            <Switch
                                                onChange={() => void 0}
                                                checked={payPalCredential?.permissionGranted}
                                                onColor={'#0057B6'}
                                                offColor={'#BEBEBE'}
                                                handleDiameter={12}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={16}
                                                width={28}
                                                disabled={true}
                                                activeBoxShadow={'0 0 0 1px #0057B6'}
                                            />
                                        </ReactPlaceholder>
                                        <span className="toggle-label">Permission Granted</span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="toggle-switch">
                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                            <Switch
                                                onChange={() => void 0}
                                                checked={payPalCredential?.consentStatus}
                                                onColor={'#0057B6'}
                                                offColor={'#BEBEBE'}
                                                handleDiameter={12}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={16}
                                                width={28}
                                                disabled={true}
                                                activeBoxShadow={'0 0 0 1px #0057B6'}
                                            />
                                        </ReactPlaceholder>
                                        <span className="toggle-label">Consent Status</span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="toggle-switch">
                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                            <Switch
                                                onChange={() => void 0}
                                                checked={payPalCredential?.isEmailConfirmed}
                                                onColor={'#0057B6'}
                                                offColor={'#BEBEBE'}
                                                handleDiameter={12}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={16}
                                                width={28}
                                                disabled={true}
                                                activeBoxShadow={'0 0 0 1px #0057B6'}
                                            />
                                        </ReactPlaceholder>
                                        <span className="toggle-label">Email Confirmed</span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="toggle-switch">
                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                            <Switch
                                                onChange={() => void 0}
                                                checked={payPalCredential?.isPaymentReceivable}
                                                onColor={'#0057B6'}
                                                offColor={'#BEBEBE'}
                                                handleDiameter={12}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={16}
                                                width={28}
                                                disabled={true}
                                                activeBoxShadow={'0 0 0 1px #0057B6'}
                                            />
                                        </ReactPlaceholder>
                                        <span className="toggle-label">Receivable</span>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>PayPal Merchant ID</Form.Label>
                                        <Form.Control type="input" placeholder="PayPal Merchant ID" defaultValue={payPalCredential?.payPalMerchantIdentifier} maxLength={80} disabled={true} />
                                    </Form.Group>
                                </Col>
                                <Col md="auto">
                                    <Form.Group>
                                        <Form.Label>&nbsp;</Form.Label>
                                        <div>
                                            <Button variant="secondary" type="button" onClick={getSellerStatus} disabled={getStatus} style={{ "width": "120px" }}>
                                                {!getStatus ? "Update Status" : <FontAwesomeIcon icon={faSpinner} className={"fa-spin"} />}
                                            </Button>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {!payPalCredential?.payPalMerchantIdentifier ?
                                <div>
                                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ "color": "gold", "fontSize": "16px" }} />
                                    <span style={{ "marginLeft": "10px", "color": "red" }}>Seller onboarding needs to be completed.</span>
                                </div>
                                :
                                <>
                                    {!payPalCredential?.permissionGranted || !payPalCredential?.isEmailConfirmed || !payPalCredential?.consentStatus || !payPalCredential?.isPaymentReceivable ?
                                        <div>
                                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ "color": "gold", "fontSize": "16px" }} />
                                            <span style={{ "marginLeft": "10px", "color": "red" }}>Seller onboarding status is pending.</span>
                                        </div>
                                        :
                                        <></>
                                    }
                                </>
                            }
                        </div>
                        :
                        <></>
                    }
                    <hr />
                    <Row>
                        <Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>MSB Commission</Form.Label>
                                    <CurrencyInput
                                        required
                                        className="form-control"
                                        placeholder="% 0.0000"
                                        maxLength={7}
                                        decimalsLimit={4}
                                        prefix="% "
                                        defaultValue={payPalCredential?.navientCommissionPercentage}
                                        onValueChange={(value, name) => handlePercentageOnChange(value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="toggle-switch">
                                <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                    <Switch
                                        onChange={e => setAllowCard(e)}
                                        checked={allowCard}
                                        onColor={'#0057B6'}
                                        offColor={'#BEBEBE'}
                                        handleDiameter={12}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={16}
                                        width={28}
                                        disabled={isSaving}
                                        activeBoxShadow={'0 0 0 1px #0057B6'}
                                    />
                                </ReactPlaceholder>
                                <span className="toggle-label">Enable PayPal Card</span>
                            </div>
                        </Col>
                        <Col>
                            <div className="toggle-switch">
                                <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                    <Switch
                                        onChange={e => setAllowCredit(e)}
                                        checked={allowCredit}
                                        onColor={'#0057B6'}
                                        offColor={'#BEBEBE'}
                                        handleDiameter={12}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={16}
                                        width={28}
                                        disabled={isSaving}
                                        activeBoxShadow={'0 0 0 1px #0057B6'}
                                    />
                                </ReactPlaceholder>
                                <span className="toggle-label">Enable PayPal Credit</span>
                            </div>
                        </Col>
                        <Col>
                            <div className="toggle-switch">
                                <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                    <Switch
                                        onChange={e => setAllowVenmo(e)}
                                        checked={allowVenmo}
                                        onColor={'#0057B6'}
                                        offColor={'#BEBEBE'}
                                        handleDiameter={12}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={16}
                                        width={28}
                                        disabled={isSaving}
                                        activeBoxShadow={'0 0 0 1px #0057B6'}
                                    />
                                </ReactPlaceholder>
                                <span className="toggle-label">Enable PayPal Venmo</span>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>&nbsp;</Form.Label>
                                <div>
                                    <Button variant="primary" type="submit">
                                        {(payPalCredential?.msbId) ? "Save" : "Add"}
                                    </Button>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        actionResult: state.clients.actionResult,
        payPalCredential: state.clients.payPalCredential,
    };
};

export default connect(mapStateToProps)(DepartmentPayPalForm);
